import ExcelJS from 'exceljs';

import { autoResizeColumns, setCell } from '../operations';
import { workbookStyles } from '../styles';
import { ValuesSectionProps } from '../types';

export function writeValuesSection({
  worksheet,
  startRow,
  startColumn,
  props: { rows },
}: {
  worksheet: ExcelJS.Worksheet;
  startRow: number;
  startColumn: number;
  props: ValuesSectionProps;
}): number {
  if (rows.length === 0) {
    return startRow;
  }
  let colCount = 0;
  rows.forEach(({ cells }, rowNum) => {
    cells.forEach(({ value, style, formatter, numberFormat }, colNum) => {
      const row = startRow + rowNum;
      const col = startColumn + colNum;
      setCell(worksheet, row, col, formatter ? formatter(value) : value, {
        ...(style ?? workbookStyles.tableValue),
        numberFormat,
      });
      if (col > colCount) {
        colCount = col;
      }
    });
  });
  const endRow = startRow + rows.length - 1;
  autoResizeColumns(worksheet, {
    from: {
      row: startRow,
      column: startColumn,
    },
    to: {
      row: endRow,
      column: colCount,
    },
  });
  return endRow;
}
