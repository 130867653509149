export async function uploadFile(uploadUrl: string, file: File) {
  const data = await file.arrayBuffer();

  const resp = await fetch(uploadUrl, {
    method: 'PUT',
    body: data,
  });

  if (resp.status !== 200) {
    throw new Error('Erro ao enviar arquivo');
  }

  return resp;
}

function convertBytesToMB(bytes: number): string {
  return (bytes / 1000000).toFixed(1);
}

export function getDefaultFileUploadDescription(maxFileSize: number): string {
  return `São aceitos arquivos no formato PDF, JPG e PNG, com tamanho máximo de ${convertBytesToMB(maxFileSize)}MB.`;
}
