import { useQuery } from '@tanstack/react-query';

import { Box, Skeleton } from '@mui/material';

import { PayrollPayslipSummary, fetchListSentPayslips } from '@octopus/api';
import { formatMoney, formatPeriodDate } from '@octopus/formatters';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { formatPayslipType } from '../../modules/formatters';

export type ReceiptsTableProps = {
  organizationId: string;
  contractId: string | undefined;
};

export function ReceiptsTable({
  organizationId,
  contractId,
}: ReceiptsTableProps) {
  const { sortingProps, paginationProps } = useDataGrid({
    key: `receipts-${organizationId}`,
  });
  const { isLoading, data } = useQuery({
    queryKey: ['listSentPayslips', organizationId, contractId],
    queryFn: () =>
      fetchListSentPayslips({
        pathParams: {
          organizationId,
          contractId,
        },
        queryParams: {
          page: `${paginationProps.page}`,
        },
      }),
    enabled: !!contractId,
  });
  if (isLoading) {
    return (
      <Box>
        <Skeleton variant="rounded" height="75vh" />
      </Box>
    );
  }
  return (
    <Box>
      <DataGrid
        sortingProps={sortingProps}
        paginationProps={paginationProps}
        totalRowCount={data.total}
        rows={data.data}
        getRowId={(row) => row.payrollId}
        getRowLink={(row) => {
          return `/receipts/${row.companyId}/${row.contractId}/${row.payrollId}`;
        }}
        columns={columns}
        emptyMessage="Você ainda não possui holerites ou recibos."
        getRowSx={() => ({
          height: '60px',
        })}
      />
    </Box>
  );
}

const columns: GridColDef<PayrollPayslipSummary>[] = [
  {
    field: 'period',
    headerName: 'Mês de competência',
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return formatPeriodDate(params.row.period);
    },
  },
  {
    field: 'type',
    headerName: 'Tipo',
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return formatPayslipType(params.row.type);
    },
  },
  {
    field: 'netPay',
    headerName: 'Valor líquido',
    sortable: false,
    valueGetter: (params) => formatMoney(params.row.netPay) || '--',
  },
];
