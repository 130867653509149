import { useState } from 'react';

import { IconFileExport } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';

import { LoadingButton } from '@mui/lab';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';

import {
  ContractEntry,
  TemplateSummary,
  fetchDownloadTemplateAttachments,
  fetchPostTemplateAttachment,
  fetchSearchTemplates,
} from '@octopus/api';

import { MultipleContractsSelector } from '../../../modules/components/contracts/MultipleContractsSelector';
import { PageContainer } from '../../../modules/components/page/PageContainer';
import { PageTitle } from '../../../modules/components/page/PageTitle';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { downloadFile } from '../../../utils/files';

import { templateCategoryLabels } from './types';

export type Props = {
  organizationId: string;
};

export function GenerateDocument({ organizationId }: Props) {
  const [selectedContracts, setSelectedContracts] = useState<ContractEntry[]>(
    [],
  );
  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplateSummary | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [notFoundTags, setNotFoundTags] = useState<string[]>([]);
  const { showSnackbar } = useSnackbar();

  const handleDownloadDocument = () => {
    setIsLoadingDocument(true);
    setNotFoundTags([]);
    showSnackbar({
      Message: 'Gerando documentos...',
      isOpen: true,
    });

    fetchPostTemplateAttachment({
      pathParams: { organizationId, templateId: selectedTemplate?.templateId },
      body: {
        contractIds: selectedContracts.map((contract) => contract.contractId),
      },
    })
      .then((responses) => {
        showSnackbar({
          Message: 'O download dos documentos começará em breve',
          isOpen: true,
        });

        setTimeout(() => {
          const documentIds = responses
            .filter((response) => response.documentId)
            .map((response) => response.documentId);

          if (documentIds.length > 0) {
            fetchDownloadTemplateAttachments({
              pathParams: {
                organizationId,
                templateId: selectedTemplate?.templateId,
              },
              body: {
                attachmentIds: documentIds,
              },
            }).then((response) => {
              downloadFile(response.downloadUrl);
              setIsLoadingDocument(false);
            });
          }

          const allNotFoundTags = responses.flatMap(
            (response) => response.notFoundTags || [],
          );
          if (allNotFoundTags.length > 0) {
            setNotFoundTags(allNotFoundTags);
            showSnackbar({
              Message: `Alguns campos não foram preenchidos. Verifique detalhes do erro acima.`,
              isOpen: true,
              variant: 'warning',
              hasCloseAction: true,
              autoHideDuration: 30000,
            });
          } else {
            showSnackbar({
              Message: 'Todos os campos foram preenchidos com sucesso',
              isOpen: true,
              hasCloseAction: true,
            });
          }
        }, 2000);
      })
      .catch((error) => {
        console.error('error', error);
        showSnackbar({
          Message: 'Erro ao gerar documentos',
          isOpen: true,
          variant: 'error',
        });
        setIsLoadingDocument(false);
      });
  };

  const templatesQuery = useQuery({
    queryKey: ['activeTemplates', organizationId],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return fetchSearchTemplates({
        pathParams: {
          organizationId: organizationId ?? '',
        },
        body: {
          filtering: {
            elements: {
              active: ['true'],
            },
          },
        },
      });
    },
    enabled: !!organizationId,
  });

  return (
    <Box display="flex" width="100%">
      <PageContainer>
        <PageTitle title="Gerar documento" icon={IconFileExport} />
        <Box
          sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography variant="body1">
            Selecione o template desejado e os contratos a serem utilizados para
            preencher o template:{' '}
          </Typography>
          <SelectTemplate
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            templates={templatesQuery?.data?.data ?? []}
            isLoading={templatesQuery.isLoading}
          />
          <SelectContract
            organizationId={organizationId}
            selectedContracts={selectedContracts}
            setSelectedContracts={setSelectedContracts}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
          {notFoundTags.length > 0 && (
            <Box px={1} display={'flex'} flexDirection={'column'} gap={1}>
              <Typography variant="caption" color="error">
                Atenção: Alguns campos não foram preenchidos:{' '}
                {notFoundTags.join(', ')}.
              </Typography>
              <Typography variant="caption" color="primaryAlt">
                Por favor verifique os documentos gerados.
              </Typography>
              <Typography variant="caption" color="primaryAlt">
                Caso seja necessário, você pode editar os contratos e gerar
                novamente, ou editar diretamente os documentos gerados.
              </Typography>
            </Box>
          )}
          <LoadingButton
            loading={isLoadingDocument}
            disabled={selectedContracts.length === 0 || !selectedTemplate}
            onClick={handleDownloadDocument}
            color="primary"
            sx={{ mx: '6px' }}
          >
            Gerar {selectedContracts.length > 1 ? 'documentos' : 'documento'}
          </LoadingButton>
        </Box>
      </PageContainer>
    </Box>
  );
}

const SelectContract = ({
  organizationId,
  selectedContracts,
  setSelectedContracts,
  errorMessage,
}: {
  organizationId: string;
  selectedContracts: ContractEntry[];
  setSelectedContracts: (contracts: ContractEntry[]) => void;
  errorMessage: string | null;
  setErrorMessage: (errorMessage: string) => void;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <MultipleContractsSelector
        organizationId={organizationId}
        selectedContracts={selectedContracts}
        setSelectedContracts={setSelectedContracts}
        searchBarProps={{
          withStartAdornment: false,
          placeholder: 'Selecione uma ou mais pessoas',
        }}
        error={!!errorMessage}
      />
      {errorMessage && (
        <Box pl={1}>
          <Typography variant="caption" color="red">
            {errorMessage}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const SelectTemplate = ({
  selectedTemplate,
  setSelectedTemplate,
  templates,
  isLoading,
}: {
  selectedTemplate: TemplateSummary | null;
  setSelectedTemplate: (template: TemplateSummary) => void;
  templates: TemplateSummary[];
  isLoading: boolean;
}) => {
  const [option, setOption] = useState<TemplateSummary | null>(
    selectedTemplate,
  );

  const getOptionLabel = (option: TemplateSummary) => {
    return !option
      ? ''
      : `[${templateCategoryLabels[option.category]}] ${option.name}`;
  };

  const options = templates.sort((a, b) => {
    return templateCategoryLabels[a.category].localeCompare(
      templateCategoryLabels[b.category],
    );
  });

  return (
    <Autocomplete
      sx={{ px: '6px' }}
      forcePopupIcon
      disablePortal
      fullWidth
      clearOnBlur={false}
      noOptionsText={
        isLoading ? 'Carregando templates...' : 'Nenhuma opção encontrada.'
      }
      value={option}
      onChange={(_, newValue) => {
        setOption(newValue);
        setSelectedTemplate(newValue);
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          fullWidth
          placeholder="Selecione um template"
          InputProps={{
            ...params.InputProps,
            inputProps: {
              ...params.inputProps,
              autoComplete: 'off',
              defaultValue: null,
              value: getOptionLabel(option),
            },
          }}
        />
      )}
      isOptionEqualToValue={(option, value) =>
        option?.templateId === value?.templateId
      }
      getOptionKey={(option) => (!option ? '' : option.templateId)}
      getOptionLabel={getOptionLabel}
    />
  );
};
