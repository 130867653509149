import React, { useContext } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography } from '@mui/material';

import { CostCenterSummary } from '@octopus/api';
import { GridValueGetterParams } from '@octopus/ui/data-grid';

import { AppContext } from '../../../../../app/context';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { ExpandableTypography } from '../../../ExpandableTypography';
import { MultiEditDialog } from '../../../MultiEdit';
import UserAvatar from '../../../UserAvatar';

import { changeCostCenterForContracts } from './changeCostCenterForContracts';
import { AddContractEntry } from './types';

export type CostCenterAddContractsDialogProps = {
  open: boolean;
  onClose: () => void;
  newCostCenter: CostCenterSummary;
  contractsToAdd: AddContractEntry[];
  onContractsAdded: () => void;
};

export function CostCenterAddContractsDialog({
  open,
  onClose,
  newCostCenter,
  contractsToAdd,
  onContractsAdded,
}: CostCenterAddContractsDialogProps) {
  const { appContext } = useContext(AppContext);
  const { showSnackbar } = useSnackbar();

  return (
    <MultiEditDialog
      open={open}
      onCancel={onClose}
      onSuccess={(shouldShowSnackbar) => {
        onClose();
        onContractsAdded();
        if (shouldShowSnackbar) {
          showSnackbar({
            isOpen: true,
            variant: 'default',
            Message: 'Contratos adicionados ao centro de custo',
            StartAdornment: <CheckCircleIcon />,
          });
        }
      }}
      newParameters={newCostCenter}
      itemsProvider={() => ({
        isLoading: false,
        items: contractsToAdd,
      })}
      execute={changeCostCenterForContracts}
      loadingParams={{
        phrases: [
          'Atribuindo pessoas a novo centro de custo',
          'Atribuindo pessoas a novo centro de custo',
          'Consultando permissões para mudança',
          'Verificando novo centro de custo',
          'Criando a fonte da verdade',
          'Atribuindo pessoas a novo centro de custo',
          'Concluindo mudanças',
          'Lutando contra a correnteza',
          'Colocando tinta na caneta',
          'Lutando contra piratas',
          'Desviando do Kraken',
          'Mergulhando para águas profundas',
        ],
      }}
      reviewParams={{
        title: 'Adicionar ao centro de custo',
        heading: (
          <Typography variant="body2" fontWeight="400">
            Abaixo você vê um resumo dos colaboradores selecionados para
            inclusão no centro de custo{' '}
            <Typography variant="body2" component="span" fontWeight="700">
              {newCostCenter.name}
            </Typography>
            .
          </Typography>
        ),
        getReviewColumnsDef: () => [
          {
            field: 'worker',
            headerName: 'Colaborador',
            renderHeader: (params) => {
              return <Box ml={2}>{params.field}</Box>;
            },
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
              return params.row.name;
            },
            renderCell: ({ value, row }) => {
              return (
                <UserAvatar
                  name={value}
                  pictureUrl={row.profileUrl}
                  expandNameOnHover={true}
                  sx={{
                    '--UserAvatar-name-max-width': '12.5em',
                  }}
                />
              );
            },
          },
          {
            field: 'oldCostCenter',
            headerName: 'Centro de custo atual',
            valueGetter: (params: GridValueGetterParams) => {
              return params.row.oldCostCenterId;
            },
            renderCell: ({ value }) => {
              return value ? (
                <ExpandableTypography fontWeight="500">
                  {appContext?.company?.costCenters?.find(
                    (cc) => cc.id === value,
                  )?.summary?.name ?? value}
                </ExpandableTypography>
              ) : (
                <ExpandableTypography fontWeight="400">
                  Sem centro de custo
                </ExpandableTypography>
              );
            },
          },
          {
            field: 'newCostCenter',
            headerName: 'Novo centro de custo',
            valueGetter: () => newCostCenter.name,
            renderCell: ({ value }) => {
              return <ExpandableTypography>{value}</ExpandableTypography>;
            },
          },
        ],
      }}
      statusParams={{
        failureMessage: {
          title: 'Alterações não concluídas',
          description:
            'Alguns colaboradores não foram migrados para o novo centro de custo. ' +
            'Veja abaixo os detalhes do erro. Enquanto isso, eles permanecem no ' +
            'centro de custo atual.',
        },
        getSuccessColumnsDef: (_, effectiveDate) => [
          {
            field: 'worker',
            headerName: 'Colaborador',
            sortable: false,
            valueGetter: (params: GridValueGetterParams) => {
              return params.row.item.name;
            },
            renderCell: ({ value }) => {
              return (
                <ExpandableTypography
                  fontWeight="500"
                  sx={{ maxWidth: '200px' }}
                >
                  {value}
                </ExpandableTypography>
              );
            },
          },
          {
            field: 'oldCostCenter',
            headerName: 'Centro de custo atual',
            sortable: false,
            valueGetter: (params: GridValueGetterParams) => {
              return params.row.item.oldCostCenterId;
            },
            renderCell: ({ value }) => {
              return value ? (
                <ExpandableTypography fontWeight="500">
                  {appContext?.company?.costCenters?.find(
                    (cc) => cc.id === value,
                  )?.summary?.name ?? value}
                </ExpandableTypography>
              ) : (
                <ExpandableTypography fontWeight="400">
                  Sem centro de custo
                </ExpandableTypography>
              );
            },
          },
          {
            field: 'newCostCenter',
            headerName: 'Novo centro de custo',
            valueGetter: () => newCostCenter.name,
            renderCell: ({ value }) => {
              return (
                <ExpandableTypography maxWidth="190px">
                  {value}
                </ExpandableTypography>
              );
            },
          },
          {
            field: 'effectiveDate',
            headerName: 'Início da vigência',
            sortable: false,
            valueGetter: () => effectiveDate,
            renderCell: ({ value }) => {
              return <ExpandableTypography>{value}</ExpandableTypography>;
            },
          },
        ],
        getFailureColumnsDef: () => [
          {
            field: 'worker',
            headerName: 'Colaborador',
            sortable: false,
            valueGetter: (params: GridValueGetterParams) => {
              return params.row.item.name;
            },
            renderCell: ({ value }) => {
              return (
                <ExpandableTypography
                  fontWeight="500"
                  sx={{ maxWidth: '210px' }}
                >
                  {value}
                </ExpandableTypography>
              );
            },
          },
          {
            field: 'oldCostCenter',
            headerName: 'Centro de custo atual',
            sortable: false,
            valueGetter: (params: GridValueGetterParams) => {
              return params.row.item.oldCostCenterId ?? 'Sem centro de custo';
            },
            renderCell: ({ value }) => {
              return (
                <ExpandableTypography
                  fontWeight="500"
                  sx={{ maxWidth: '180px' }}
                >
                  {appContext?.company?.costCenters?.find(
                    (cc) => cc.id === value,
                  )?.summary?.name ?? value}
                </ExpandableTypography>
              );
            },
          },
          {
            field: 'newCostCenter',
            headerName: 'Novo centro de custo',
            sortable: false,
            valueGetter: () => 'Inalterado',
            renderCell: ({ value }) => {
              return <ExpandableTypography>{value}</ExpandableTypography>;
            },
          },
          {
            field: 'error',
            headerName: 'Motivo da falha',
            sortable: false,
            valueGetter: (params: GridValueGetterParams) => params.row.error,
            renderCell: ({ value }) => {
              return (
                <Typography variant="body2" maxWidth="220px">
                  {value}
                </Typography>
              );
            },
          },
        ],
      }}
    />
  );
}
