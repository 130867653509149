import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { Box } from '@mui/material';

import {
  ContractDocumentEntry,
  ContractEntry,
  CustomFieldEntry,
  SearchInput,
  useGetContract,
  useListCustomFields,
  useSearchContractDocuments,
} from '@octopus/api';

import { BackButton } from '../../../modules/components/BackButton';
import { PageAlert } from '../../../modules/components/PageAlert';
import { QueryResult } from '../../../modules/types';

import { ContractDetailsContent } from './ContractDetailsContent';
import { ContractReportsContent } from './ContractReportsContent';
import { ContractDocumentsContent } from './documents/ContractDocumentsContent';
import { PersonSidePanel } from './PersonSidePanel';
import { PageContent, PageRefs, pageContents } from './types';

export type PersonProps = {
  organizationId: string | undefined;
};

export default function Person({ organizationId }: PersonProps) {
  const { contractId } = useParams<{
    contractId: string;
  }>();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [pageContent, setPageContent] = useState<PageContent>(
    (hash?.replace('#', '') as PageContent) || pageContents.details,
  );
  const [contractDocs, setContractDocs] = useState<ContractDocumentEntry[]>([]);

  const refs: PageRefs = {
    pageRef: useRef<Element>(null),
    personalDetailsRef: useRef<Element>(null),
    contractDetailsRef: useRef<Element>(null),
    customFieldsDetailsRef: useRef<Element>(null),
    paymentDetailsRef: useRef<Element>(null),
  };
  useEffect(() => {
    if (refs.pageRef.current) {
      refs.pageRef.current.scrollIntoView();
    }
    navigate(`#${pageContent}`);
  }, [refs.pageRef, pageContent]);

  const contractQuery = useGetContract(
    {
      pathParams: {
        organizationId: organizationId ?? '',
        contractId: contractId ?? '',
      },
    },
    {
      enabled: !!organizationId && !!contractId,
    },
  );

  const contractDocumentsQuery = useSearchContractDocuments({
    onSuccess: (data) => setContractDocs(data.data),
  });

  const refetchDocuments = useCallback(async () => {
    return await contractDocumentsQuery.mutate({
      pathParams: {
        organizationId: organizationId ?? '',
        contractId: contractId ?? '',
      },
      body: {
        query: '',
        filtering: {},
        sorting: [
          {
            field: 'type',
            order: 'asc',
          },
        ],
        pagination: {
          page: 0,
          size: 100,
        },
      } as SearchInput,
    });
  }, [contractDocumentsQuery, organizationId, contractId]);

  useMemo(() => {
    if (organizationId && contractId && !contractDocs) {
      refetchDocuments();
    }
  }, [organizationId, contractId, contractDocs]);

  const customFieldsQuery = useListCustomFields(
    {
      pathParams: {
        organizationId: organizationId ?? '',
      },
      queryParams: {
        'app-visibility': 'contract',
        'user-visibility': 'admin',
        'contract-types':
          contractQuery.data?.contractType === 'br:pj'
            ? 'pj'
            : `${contractQuery.data?.br?.trabalho?.codCateg}`,
      },
    },
    {
      enabled: !contractQuery.isLoading && !!contractQuery.data?.contractType,
    },
  );

  if (!contractId) {
    return <Navigate to="/people" replace />;
  }

  if (contractQuery.isError) {
    console.error(
      `Failed to load contract: ${JSON.stringify(contractQuery.error)}`,
    );
    return (
      <>
        <BackButton destination="/people" />
        <PageAlert
          message="Erro ao carregar dados do contrato"
          severity="error"
          showRetryMessage={true}
        />
      </>
    );
  }

  return (
    <Box display="flex">
      <BackButton destination="/people" />
      <PersonSidePanel
        refs={refs}
        contractQuery={contractQuery}
        pageContent={pageContent}
        setPageContent={setPageContent}
        customFieldsQuery={customFieldsQuery}
      />
      <Page
        refs={refs}
        contractQuery={contractQuery}
        contractDocuments={contractDocs}
        refetchDocuments={refetchDocuments}
        pageContent={pageContent}
        customFieldsQuery={customFieldsQuery}
      />
    </Box>
  );
}

function Page({
  refs,
  contractQuery,
  contractDocuments,
  refetchDocuments,
  pageContent,
  customFieldsQuery,
}: {
  refs: PageRefs;
  contractQuery: QueryResult<ContractEntry>;
  contractDocuments: ContractDocumentEntry[];
  refetchDocuments?: () => void;
  pageContent: PageContent;
  customFieldsQuery: QueryResult<CustomFieldEntry[]>;
}) {
  const { pageRef } = refs;
  return (
    <Box
      ref={pageRef}
      display="flex"
      flexDirection="column"
      flexGrow={1}
      alignItems="center"
      width="100%"
      py={15}
      mx={4}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth="740px"
        minWidth="400px"
        gap={2}
      >
        <Box display={pageContent === pageContents.details ? 'block' : 'none'}>
          <ContractDetailsContent
            refs={refs}
            contractQuery={contractQuery}
            customFieldsQuery={customFieldsQuery}
            contractDocuments={contractDocuments}
            refetchDocuments={refetchDocuments}
          />
        </Box>
        <Box
          display={pageContent === pageContents.documents ? 'block' : 'none'}
        >
          <ContractDocumentsContent
            contractQuery={contractQuery}
            customFieldsQuery={customFieldsQuery}
          />
        </Box>
        <Box display={pageContent === pageContents.reports ? 'block' : 'none'}>
          <ContractReportsContent contractQuery={contractQuery} />
        </Box>
      </Box>
    </Box>
  );
}
