import { CredentialsProvider } from '@octopus/api';

export type AppCredentialsProvider = CredentialsProvider & {
  setToken: (token: string) => void;
};

export const credentialsProvider: AppCredentialsProvider =
  newCredentialsProvider();

function newCredentialsProvider(): AppCredentialsProvider {
  let token: string | undefined = undefined;
  let expiration = getTokenExpiration(token);
  let refreshPromise: Promise<string> | undefined = undefined;
  const setToken = (newToken: string) => {
    token = newToken;
    expiration = getTokenExpiration(token);
    refreshPromise = undefined;
  };
  const onUnauthorized = () => {
    window.location.href = '/login';
  };
  const getCredentials = async () => {
    if (refreshPromise) {
      return refreshPromise;
    }
    if (expiration < Date.now() / 1000) {
      refreshPromise = refreshToken().catch(onUnauthorized);
      setToken(await refreshPromise);
    }
    return token;
  };
  return {
    getCredentials,
    onUnauthorized,
    setToken,
  };
}

async function refreshToken() {
  const response = await window.fetch('/api/user-auth/login/token', {
    credentials: 'include',
  });
  if (response.ok) {
    const { token } = await response.json();
    return token;
  }
  throw new Error('Failed to refresh token');
}

function getTokenExpiration(token: string | undefined): number {
  if (!token) {
    return 0;
  }
  try {
    const tokenContent = token.split('.')[1];
    const { exp } = JSON.parse(atob(tokenContent));
    //Set token expiration 1 minute before actual exp, just to make sure
    //tokens won't expire while in flight.
    return exp - 60;
  } catch (err) {
    console.error('Failed to parse token expiration date', err);
  }
  return 0;
}
