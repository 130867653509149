import { QueryClient } from '@tanstack/react-query';

import { Challenge, Token, useGetUserIdentity } from '@octopus/api';

import { SSOUtils } from '../../utils/sso';
import { clearAllLocalStorage } from '../hooks/useLocalStorage';
import { clearAllSessionStorage } from '../hooks/useSessionStorage';
export async function loginUser(
  username: string,
  password: string,
): Promise<Token | Challenge> {
  const response = await window.fetch('/api/user-auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
    credentials: 'include',
  });
  const responseBody = await response.json();
  if (response.ok) {
    return responseBody;
  } else if (checkIsExpiredPasswordError(response.status, responseBody)) {
    throw new Error(
      'Senha temporária expirada, reenviamos um email com a nova senha para seu email cadastrado.',
    );
  }
  throw new Error('Email ou senha inválidos');
}
export function checkIsExpiredPasswordError(
  statusCode: number,
  responseBody: { message: string },
): boolean {
  return (
    statusCode === 401 &&
    responseBody.message ===
      'Temporary password has expired and must be reset by an administrator.'
  );
}

export async function answerNewPasswordChallenge(
  username: string,
  { challenge }: Challenge,
  newPassword: string,
): Promise<Token> {
  const response = await window.fetch('/api/user-auth/login/challenge', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      answer: {
        name: challenge?.name,
        session: challenge?.session,
        responses: {
          NEW_PASSWORD: newPassword,
        },
      },
    }),
    credentials: 'include',
  });
  if (response.ok) {
    return await response.json();
  }
  throw new Error('Falha ao criar nova senha');
}

export async function startPasswordRecovery(username: string): Promise<void> {
  const response = await window.fetch('/api/user-auth/password/recovery', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
    }),
  });
  if (!response.ok) {
    const errorMessage = await parsePasswordRecoveryError(response);
    throw new Error(errorMessage);
  }
}

async function parsePasswordRecoveryError(response: Response): Promise<string> {
  const defaultMessage = 'Erro ao enviar link de recuperação de senha';
  try {
    const { details } = await response.json();
    const ssoMessage = SSOUtils.Errors.getMessage(details?.code);
    return ssoMessage ?? defaultMessage;
  } catch {
    return defaultMessage;
  }
}

export async function confirmPasswordRecovery(
  sub: string,
  code: string,
  newPassword: string,
): Promise<void> {
  const response = await window.fetch(
    '/api/user-auth/password/recovery/confirm',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: sub,
        code,
        newPassword,
      }),
    },
  );
  if (!response.ok) {
    throw new Error('Falha ao criar nova senha');
  }
}

export async function requestAccess(
  email: string,
  organization: string,
  additionalInfo: string,
): Promise<void> {
  const response = await window.fetch('/api/user-auth/request-access', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      organization,
      additionalInfo,
    }),
  });
  if (!response.ok) {
    throw new Error('Falha ao submeter pedido');
  }
}

export async function logoutUser(queryClient: QueryClient): Promise<void> {
  clearAllLocalStorage();
  clearAllSessionStorage();
  queryClient.clear();
  await window.fetch('/api/user-auth/logout', {
    method: 'POST',
    credentials: 'include',
  });
}

export function useCachedIdentity() {
  return useGetUserIdentity({}, { staleTime: Infinity });
}

export function useOrganizationId() {
  const { data: user } = useCachedIdentity();
  const organizationId = user?.organization?.toLowerCase();
  return organizationId!;
}
