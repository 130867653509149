import { useState } from 'react';

export const getContextFromSessionStorage = (key: string) => {
  try {
    if (!sessionStorage) {
      return undefined;
    }

    const value = sessionStorage.getItem(key);

    if (!value) {
      return undefined;
    }

    return JSON.parse(value);
  } catch (error) {
    return undefined;
  }
};

export const clearContextFromSessionStorage = (key: string) => {
  if (!sessionStorage) {
    return;
  }

  sessionStorage.removeItem(key);
};

export const clearAllSessionStorage = (): void => {
  if (!sessionStorage) {
    return;
  }

  sessionStorage.clear();
};

export const setContextOnSessionStorage = <T>(key: string, value: T) => {
  if (!sessionStorage) {
    return;
  }

  sessionStorage.setItem(key, JSON.stringify(value));
};

export const useSessionStorage = <T>(key: string) => {
  const [sessionStorageContext, setContext] = useState<T | undefined>(
    getContextFromSessionStorage(key),
  );

  const setSessionStorageContext = (context: T) => {
    setContext(context);
    setContextOnSessionStorage(key, context);
  };

  const clearSessionStorageContext = () => {
    clearContextFromSessionStorage(key);
    setContext(undefined);
  };

  return {
    sessionStorageContext,
    setSessionStorageContext,
    clearSessionStorageContext,
  };
};
