import {
  FilterOptions,
  makeElementListFilter,
  makeMoneyRangeFilter,
  makeYearMonthPickerFilter,
} from '@octopus/ui/data-grid';

export function useFilters(): FilterOptions {
  return [
    makeYearMonthPickerFilter({
      label: 'Competência',
      propertyToFilter: 'period',
    }),
    makeElementListFilter({
      label: 'Tipo de Folha',
      propertyToFilter: 'type',
      elements: [
        'monthly',
        'thirteenthFirst',
        'thirteenthSecond',
        'advance',
        'complementary',
        'rpa',
      ],
      labels: {
        monthly: 'Mensal',
        thirteenthFirst: '13o Salário (1/2)',
        thirteenthSecond: '13o Salário (2/2)',
        advance: 'Adiantamento',
        complementary: 'Complementar',
        rpa: 'RPA',
      },
      sortElements: false,
      disableSearch: true,
    }),
    makeMoneyRangeFilter({
      label: 'Valor líquido',
      propertyToFilter: 'calculationTotals.netPay',
      getRangeMin: () => 0,
      getRangeMax: () => 1_000_000,
    }),
    makeMoneyRangeFilter({
      label: 'Custo Total',
      propertyToFilter: 'calculationTotals.totalCost',
      getRangeMin: () => 0,
      getRangeMax: () => 1_000_000,
    }),
  ].filter(Boolean);
}
