import React from 'react';

import dayjs from 'dayjs';

import { Box, Divider, Typography } from '@mui/material';

import {
  ContractEntry,
  ContractVacationsAccrualPeriodsEntry,
  VacationsConfigurationEntry,
} from '@octopus/api';
import { getDepartmentId, getName, getWorkerId } from '@octopus/contract-types';

import UserAvatar from '../../../../../modules/components/UserAvatar';
import { formatOptionalDayjsDate } from '../../../../../modules/formatters';
import { ExplodedDate } from '../../../../terminations/local-components/ExplodedDate';
import { calculateReturnDate, getCurrentAccrualPeriods } from '../utils';

export function VacationFormOverview(props: {
  contract: ContractEntry;
  contractAccrualPeriods: ContractVacationsAccrualPeriodsEntry;
  configurations: VacationsConfigurationEntry;
  inputs: {
    startDate: string;
    vacationDays: number;
    daysSold: number;
  };
}) {
  const { contractAccrualPeriods, contract, inputs, configurations } = props;
  const vacations = getCurrentAccrualPeriods(
    contractAccrualPeriods.accrualPeriods,
  );

  if (!vacations) {
    return null;
  }

  const selectedDate = dayjs(inputs?.startDate);
  const selectedStartDate =
    inputs?.startDate &&
    formatOptionalDayjsDate({
      date: selectedDate,
    });

  const endVacationsDate = calculateReturnDate(
    selectedDate,
    inputs.vacationDays,
    configurations,
  );

  const selectedEndDate =
    inputs?.startDate &&
    inputs?.vacationDays &&
    formatOptionalDayjsDate({
      date: endVacationsDate,
    });

  const startDate = formatOptionalDayjsDate({
    date: dayjs(vacations.startDate),
  });

  const endDate = formatOptionalDayjsDate({
    date: dayjs(vacations.endDate),
  });

  const concessionPeriodStartDate = formatOptionalDayjsDate({
    date: dayjs(vacations.concessionPeriod.startDate),
  });

  const concessionPeriodEndDate = formatOptionalDayjsDate({
    date: dayjs(vacations.concessionPeriod.endDate),
  });

  const limitDateToStartVacations = formatOptionalDayjsDate({
    date: dayjs(vacations.concessionPeriod.limitDateToStartVacations),
  });

  const remainingDays =
    vacations.daysAvailable -
    (inputs.vacationDays ?? 0) -
    (inputs.daysSold ?? 0);

  const daysAvailable = inputs.daysSold
    ? vacations.daysAvailable - inputs.daysSold
    : vacations.daysAvailable;

  const employee = [
    getDepartmentId(contract),
    `Matrícula ${getWorkerId(contract)}`,
  ];
  return (
    <Box
      component={'section'}
      data-test-id="container"
      p={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '22px',
        border: '1px solid #EDEDED',
        borderRadius: '8px',
        backgroundColor: '#FFF',
      }}
    >
      <Box
        component="header"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: '12px',
          alignSelf: 'stretch',
          background: '#FFF',
        }}
      >
        <Box
          p={1.5}
          sx={{
            display: 'flex',
            gap: '8px',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignSelf: 'stretch',
            borderRadius: '8px',
            background: '#F7F7F8',
          }}
        >
          <UserAvatar
            avatarProps={{
              ml: 0,
              mr: 0,
              my: 0,
            }}
            name={getName(contract)}
            pictureUrl={contract.profilePicture?.pictureUrl}
            showFullName={false}
          />

          <Box>
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
              }}
            >
              {getName(contract)}
            </Typography>
            {employee.map((detail, i) => (
              <Typography
                key={detail}
                variant="caption"
                color="#616161"
                sx={{
                  display: 'inline-flex',
                }}
              >
                {detail}
                {i === 0 && (
                  <Typography
                    component="span"
                    variant="caption"
                    color="#BABABF"
                    sx={{
                      marginX: '4px',
                    }}
                  >
                    |
                  </Typography>
                )}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box>
          <DefinitionList>
            <DefinitionList.Item>
              Data de Início
              <ExplodedDate
                ddmmyyyy={selectedStartDate}
                placeholder="Informe a data de início"
              />
            </DefinitionList.Item>
            <DefinitionList.Item>
              Data de fim
              <ExplodedDate
                ddmmyyyy={selectedEndDate}
                placeholder="Informe os dias de férias"
              />
            </DefinitionList.Item>
          </DefinitionList>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '22px',
        }}
      >
        <Box>
          <Typography
            variant="caption"
            sx={{
              fontWeight: 'bold',
              display: 'inline-flex',
              marginBottom: '8px',
            }}
          >
            Período disponível
          </Typography>

          <DefinitionList>
            <DefinitionList.Item>
              Período aquisitivo
              <ExplodedDate ddmmyyyy={startDate} />
              <Typography
                variant="body2"
                sx={{ display: 'inline-flex', px: '4px' }}
              >
                -
              </Typography>
              <ExplodedDate ddmmyyyy={endDate} />
            </DefinitionList.Item>
            <DefinitionList.Item>
              Período concessivo
              <ExplodedDate ddmmyyyy={concessionPeriodStartDate} />
              <Typography
                variant="body2"
                sx={{ display: 'inline-flex', px: '4px' }}
              >
                -
              </Typography>
              <ExplodedDate ddmmyyyy={concessionPeriodEndDate} />
            </DefinitionList.Item>
            <DefinitionList.Item>
              Data limite para concessão
              <ExplodedDate ddmmyyyy={limitDateToStartVacations} />
            </DefinitionList.Item>
            <DefinitionList.Item>
              Dias de gozo disponíveis
              {daysAvailable}
            </DefinitionList.Item>
            <DefinitionList.Item>
              Faltas no período aquisitivo
              {vacations.absences}
            </DefinitionList.Item>
            <DefinitionList.Item highlight>
              Dias disponíveis após o gozo
              {remainingDays}
            </DefinitionList.Item>
          </DefinitionList>
        </Box>
        <Box>
          <Typography
            variant="caption"
            sx={{
              fontWeight: 'bold',
              display: 'inline-flex',
              marginBottom: '8px',
            }}
          >
            Outras informações
          </Typography>
          <DefinitionList>
            <DefinitionList.Item>
              {'Já adiantou o 13º?'}
              {vacations?.thirteenthAdvanceByYear.length > 0 ? 'Sim' : 'Não'}
            </DefinitionList.Item>
          </DefinitionList>
        </Box>
      </Box>
    </Box>
  );
}

type IDefinitionListItemElement = React.ReactElement<
  IDefinitionListItemProps,
  typeof DefinitionList.Item
>;
type IDefinitionListProps = {
  children: IDefinitionListItemElement | IDefinitionListItemElement[];
};

function DefinitionList(props: IDefinitionListProps) {
  return (
    <Box
      component="dl"
      sx={{
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}
    >
      {props.children}
    </Box>
  );
}

type IDefinitionListItemProps = {
  children: [React.ReactNode, ...React.ReactNode[]];
  highlight?: boolean;
};

DefinitionList.Item = function DefinitionListItem(
  props: IDefinitionListItemProps,
) {
  const [dtContent, ...ddContent] = props.children;

  return (
    <Box display="flex">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
        width="50%"
      >
        <Typography
          component={'dt'}
          variant="caption"
          sx={{
            ...(Boolean(props.highlight) && {
              color: '#0058DB',
              fontWeight: 700,
            }),
          }}
        >
          {dtContent}
        </Typography>
      </Box>
      <Box width="50%">
        <Typography
          component={'dd'}
          variant="caption"
          sx={{
            ...(Boolean(props.highlight) && {
              color: '#0058DB',
              fontWeight: 700,
            }),
          }}
        >
          {ddContent}
        </Typography>
      </Box>
    </Box>
  );
};
