import {
  IconCircleCheck,
  IconEye,
  IconEyeOff,
  IconSend2,
} from '@tabler/icons-react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { PayrollPayslipEntry } from '@octopus/api';
import { formatDateTimeBR } from '@octopus/formatters';

export const StatusIndicator = (props: { payslip: PayrollPayslipEntry }) => {
  const { approvedAt, metadata, workerData } = props?.payslip || {};
  const { sentAt, viewedAt } = metadata || {};
  const { name } = workerData || {};
  const styles = { color: '#BABABF' };

  return (
    <Box mt={6} alignItems="end" display="flex" flexDirection="column" gap={1}>
      {approvedAt && (
        <Text icon={<IconCircleCheck size={16} style={styles} />}>
          Documento gerado dia {formatDateTimeBR(approvedAt)}.
        </Text>
      )}

      {sentAt ? (
        <Text icon={<IconSend2 size={16} style={styles} />}>
          Documento enviado dia {formatDateTimeBR(sentAt)}.
        </Text>
      ) : (
        <Text icon={<IconSend2 size={16} style={styles} />}>
          Documento ainda não enviado para o colaborador(a).
        </Text>
      )}

      {sentAt &&
        (viewedAt ? (
          <Text icon={<IconEye size={16} style={styles} />}>
            Documento visualizado dia {formatDateTimeBR(viewedAt)}, por {name}.
          </Text>
        ) : (
          <Text icon={<IconEyeOff size={16} style={styles} />}>
            Não visualizado pelo colaborador(a).
          </Text>
        ))}
    </Box>
  );
};

const Text = (props: { children: React.ReactNode; icon: React.ReactNode }) => {
  return (
    <Box display="flex" alignItems="center" gap={0.5}>
      <Typography variant="caption" color="#616161">
        {props.children}
      </Typography>
      {props.icon}
    </Box>
  );
};
