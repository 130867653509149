import { AuditableVersionedEntry } from '@octopus/api';

import { VacationsType } from './common';
import { DoesNotHaveAvailableDaysPolicy } from './group';

export type VacationsScheduleKeyParameters = {
  organizationId: string;
  contractId: string;
  sequence: number;
};

export type VacationsScheduleKeys = {
  partitionKey: {
    organizationId: string;
    contractId: string;
  };

  sortKey: {
    sequence: number;
  };
};

export type VacationsSchedule = AuditableVersionedEntry & {
  // organizationId/contractId
  partitionKey: string;

  // sequence
  sortKey: number;

  // for GSI SK
  accrualPeriodStart: string;

  organizationId: string;
  contractId: string;
  companyId: string;
  legalEntityId: string;

  sequence: number;

  startDate: string;
  endDate: string;

  // the number of vacations days that will be actually paid. May have .5 days
  paidVacationsDays: number;
  // the number of paid leave days. May have .5 days
  paidLeaveDays: number;

  // the number of days that employee will be subtracted from employees accrual period. May not have .5 days.
  daysTaken: number;
  daysSold: number;
  thirteenthAdvance: boolean;

  accrualPeriod: {
    startDate: string;
    endDate: string;
    concessionPeriod: {
      startDate: string;
      endDate: string;
    };
  };

  status: VacationsScheduleStatus;

  workerData: WorkerData;

  approvalDeadline?: string;
  approvalWorkflowId?: string;

  /**
   * the id of the payroll that was created by this schedule
   */
  payrollId?: string;

  paymentDate: string;

  type: VacationsType;

  /**
   * only exists if the schedule was created by a group vacation
   */
  groupVacationsId?: string;
  doesNotHaveAvailableDaysPolicy?: DoesNotHaveAvailableDaysPolicy;
  payrollCreationScheduledAt?: string;

  vacationsBreakdown: VacationsBreakDownByDay[];

  payslipStatus: VacationsPayslipStatus;

  stateTransitions?: {
    transition: string;
    at: string;
    principal: string;
  }[];
};

export type VacationsBreakDownByDay = {
  date: string;
  ratios: {
    vacations: string;
    paidLeave: string;
  };
};

export type WorkerData = {
  name: string;
  departmentId?: string;
  location: {
    city: string;
    state: string;
  };
};

export type VacationsScheduleCreatePayload = Omit<
  VacationsSchedule,
  keyof VacationsScheduleKeys | keyof AuditableVersionedEntry | 'review'
>;

export type VacationsScheduleUpdatePayload =
  Partial<VacationsScheduleCreatePayload> & {
    payrollId?: string;
  };

export const vacationsPayslipStatuses = {
  pending: 'pending',
  sent: 'sent',
  viewed: 'viewed',
} as const;

export type VacationsPayslipStatus = keyof typeof vacationsPayslipStatuses;

export const vacationsScheduleStatuses = {
  created: 'created',
  waitingApproval: 'waitingApproval',
  approved: 'approved',
  rejected: 'rejected',
  canceled: 'canceled',
  payrollCreationScheduled: 'payrollCreationScheduled',
  payrollCreated: 'payrollCreated',
  payrollApproved: 'payrollApproved',
  contractTerminated: 'contractTerminated',
  payrollArchived: 'payrollArchived',
} as const;

export const vacationsScheduleCommands = {
  createApprovalWorkflow: 'createApprovalWorkflow',
  approve: 'approve',
  reject: 'reject',
  cancel: 'cancel',
  schedulePayroll: 'schedulePayroll',
  createPayroll: 'createPayroll',
  approvePayroll: 'approvePayroll',
  removePayrollApproval: 'removePayrollApproval',
  notifyContracts: 'notifyContracts',
  delete: 'delete',
  create: 'create',
  addReview: 'addReview',
  terminate: 'terminate',
  restore: 'restore',
  archive: 'archive',
} as const;

export type VacationsScheduleStatus = keyof typeof vacationsScheduleStatuses;
export type VacationsScheduleCommand = keyof typeof vacationsScheduleCommands;

export const vacationsScheduleResourceType = 'schedules';
export const accrualPeriodsResourceType = 'accrual-periods';

export type VacationScheduleNotificationContext = {
  resourceId: string;
  resourceType: typeof vacationsScheduleResourceType;
  organizationId: string;
  contractId: string;
  keys: VacationsScheduleKeyParameters;

  approvalDeadline: VacationsSchedule['approvalDeadline'];
  status: VacationsSchedule['status'];
  startDate: VacationsSchedule['startDate'];
  endDate: VacationsSchedule['endDate'];
  daysTaken: VacationsSchedule['daysTaken'];

  accrualPeriodStart: VacationsSchedule['accrualPeriod']['startDate'];
  accrualPeriodEnd: VacationsSchedule['accrualPeriod']['endDate'];
  thirteenthAdvance: VacationsSchedule['thirteenthAdvance'];
  daysSold: VacationsSchedule['daysSold'];

  daysUntilStart: number;

  workerName: string;

  rejection?: {
    reason: string;
    rejectedBy: string;
  };
};

export type ContractAccrualPeriodNotificationContext = {
  resourceId: string;
  resourceType: typeof accrualPeriodsResourceType;
  organizationId: string;
  contractId: string;

  // notificar colaboradores perto do limite de férias
  daysUntilLimitDate: number;

  limitDateToStartVacations?: string;

  workerName: string;
};

export const vacationsScheduleStatusIndex: {
  [key in VacationsScheduleStatus]: number;
} = {
  created: 10,
  waitingApproval: 50,
  approved: 100,
  payrollCreationScheduled: 125,
  payrollCreated: 150,
  payrollApproved: 200,
  rejected: 9999,
  payrollArchived: 9999,
  canceled: 9999,
  contractTerminated: 9999,
} as const;
