import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper, { Area, Point } from 'react-easy-crop';

import CloseIcon from '@mui/icons-material/Close';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

export type PictureUploadModalProps = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (img: File) => void;
  processedImageSize?: number;
  cropShape?: 'round' | 'rect';
};

export function PictureInputModal({
  open,
  onCancel,
  onSubmit,
  processedImageSize = 100,
  cropShape = 'round',
}: PictureUploadModalProps) {
  const [error, setError] = useState<string | null>(null);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [croppedArea, setCroppedArea] = useState<Area | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setFile(undefined);
    setCroppedArea(undefined);
    setIsLoading(false);
    setError(null);
  }, [open]);

  return (
    <Dialog open={open} onClose={onCancel} maxWidth={false}>
      <DialogTitle>
        <Typography variant="h3">Editar foto</Typography>
      </DialogTitle>
      <DialogContent
        dividers={true}
        sx={{
          pb: 3,
          pt: error ? 1.5 : 3,
          px: 3,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
          alignItems="center"
          gap={1.5}
        >
          {error && (
            <Box width={'100%'}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
          {!file && (
            <SelectImage
              setFile={(file) => {
                setError(null);
                setFile(file);
              }}
            />
          )}
          {file && (
            <CropImage
              image={file}
              clearImage={() => setFile(undefined)}
              setCroppedArea={setCroppedArea}
              cropShape={cropShape}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          size="large"
          color="secondary"
          variant="contained"
        >
          Cancelar
        </Button>
        <LoadingButton
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            processImage(file, croppedArea, processedImageSize)
              .then(onSubmit)
              .catch(() => {
                setIsLoading(false);
                setError('Falha ao processar imagem');
                setFile(undefined);
                setCroppedArea(undefined);
              });
          }}
          size="large"
          color="primaryAlt"
          variant="contained"
          disabled={!croppedArea}
        >
          Salvar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

function SelectImage({ setFile }: { setFile: (file: File) => void }) {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => setFile(acceptedFiles[0]),
    [setFile],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png'],
    },
  });

  const bgColor = isDragActive ? '#EDEDED' : '#FFF';

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={1}
      borderRadius={1}
      bgcolor={bgColor}
      height="200px"
      width="500px"
      alignItems={'center'}
      justifyContent={'center'}
      {...getRootProps()}
      boxSizing={'border-box'}
      sx={(theme) => ({
        padding: 3,
        border: `1px dashed ${theme.palette.strokes.heavy}`,
      })}
    >
      <Box display={'flex'} flexDirection={'row'} bgcolor={bgColor}>
        <FileUploadOutlinedIcon
          sx={(theme) => ({
            width: '24px',
            height: '24px',
            [theme.breakpoints.up('md')]: {
              color: theme.palette.text.secondary,
            },
            [theme.breakpoints.down('md')]: {
              color: theme.palette.primary.main,
            },
          })}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        bgcolor={bgColor}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
      >
        <Typography
          bgcolor={bgColor}
          textAlign={'center'}
          variant="button"
          color="text.secondary"
          sx={{ whiteSpace: 'pre-line' }}
        >
          Para enviar sua foto{' '}
          <Typography
            variant="button"
            sx={{
              color: (theme) => theme.palette.primary.main,
              cursor: 'pointer',
              fontWeight: 'bold',
            }}
          >
            clique aqui <input type={'file'} {...getInputProps()} />
          </Typography>
          ou arraste-a para cá.{'\n'}
          São aceitos arquivos no formato JPG e PNG.
        </Typography>
      </Box>
    </Box>
  );
}

function CropImage({
  image,
  clearImage,
  setCroppedArea,
  cropShape,
}: {
  image: File;
  clearImage: () => void;
  setCroppedArea: (area: Area) => void;
  cropShape: 'round' | 'rect';
}) {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const objectUrl = URL.createObjectURL(image);
  useEffect(() => {
    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [objectUrl]);

  return (
    <Box position="relative" width="800px" height="600px">
      <IconButton
        color="default"
        sx={{
          position: 'absolute',
          right: 2,
          top: 2,
          cursor: 'pointer',
          zIndex: 100000,
          bgcolor: 'grey',
          '&:hover': {
            bgcolor: 'lightgrey',
          },
        }}
        onClick={clearImage}
      >
        <CloseIcon />
      </IconButton>
      <Cropper
        image={objectUrl}
        crop={crop}
        zoom={zoom}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropComplete={(_, croppedAreaPixels) =>
          setCroppedArea(croppedAreaPixels)
        }
        cropShape={cropShape}
        objectFit="vertical-cover"
        aspect={1}
        showGrid={false}
      />
    </Box>
  );
}

function processImage(
  file: File,
  croppedArea: Area,
  processedImageSize: number,
): Promise<File> {
  return new Promise<File>((resolve, reject) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    img.onload = () => {
      try {
        const canvas = document.createElement('canvas');
        canvas.width = processedImageSize;
        canvas.height = processedImageSize;
        const context = canvas.getContext('2d');
        context.drawImage(
          img,
          croppedArea.x,
          croppedArea.y,
          croppedArea.width,
          croppedArea.height,
          0,
          0,
          processedImageSize,
          processedImageSize,
        );
        canvas.toBlob(
          (blob) => {
            resolve(
              new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              }),
            );
          },
          'image/jpeg',
          0.85,
        );
        URL.revokeObjectURL(objectUrl);
      } catch (err) {
        URL.revokeObjectURL(objectUrl);
        console.error('Failed to process image', err);
        reject(err);
      }
    };

    img.onerror = (_1, _2, _3, _4, err) => {
      URL.revokeObjectURL(objectUrl);
      console.error('Failed to load image', err);
      reject(new Error('Failed to load image'));
    };

    img.src = objectUrl;
  });
}
