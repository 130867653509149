import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useInputControl } from '@conform-to/react';

import { Autocomplete, SxProps, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Theme } from '@mui/material/styles';

import {
  TFieldRenderProps,
  TFieldSelectRenderProps,
} from '../parseField/types';

import { FieldWrapper } from './commons/FieldWrapper';

type FieldSelectProps = {
  field: TFieldRenderProps & TFieldSelectRenderProps;
  sx?: SxProps<Theme>;
  formId: string;
};

type TOption = {
  textContent: string;
  defaultSelected: boolean;
  props: {
    key: string;
  };
};

const filterOptions = createFilterOptions({
  limit: 10,
  ignoreCase: true,
  ignoreAccents: true,
});

export function FieldSelectWithAutoComplete(props: FieldSelectProps) {
  const [focused, setFocused] = useState(false);
  const setDefaultOnce = useRef(false);
  const startedTyping = useRef(false);

  const { field } = props;

  const options = useMemo(
    () =>
      field.select.options.map((opt) => ({
        textContent: opt.textContent,
        defaultSelected: opt.defaultSelected,
        props: {
          key: opt.props.key,
        },
      })),
    [field.select.options],
  );

  const optionDict: Record<string, string> = useMemo(
    () =>
      options.reduce(
        (acc, opt) => ({
          ...acc,
          [opt.props.key]: opt.textContent,
        }),
        {},
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options.length], // this is a workaround to avoid re-calculating the optionDict on every change
  );

  const defaultOption = useMemo(
    () => options.find((opt: TOption) => opt.defaultSelected),
    [options],
  );

  const option = useInputControl({
    key: field.select.props.key,
    name: `${field.select.props.name}`,
    formId: props.formId,
  });

  const defaultKey = defaultOption?.props.key;
  const onKeyDown = useCallback(() => {
    if (!startedTyping.current) {
      startedTyping.current = true;
    }
  }, []);

  const isOptionEqualToValue = useCallback((option: any, value: any) => {
    return (
      (option as TOption).props.key === (value as TOption)?.props?.key ||
      (option as TOption).props.key === (value as string)
    );
  }, []);

  const getOptionKey = useCallback(
    (option: any) => (option as TOption).props?.key,
    [],
  );

  const getOptionLabel = useCallback(
    (option: any) => {
      if (option as TOption) {
        return option.textContent;
      }

      return optionDict[option as string] || '-';
    },
    [optionDict],
  );

  const onChange = useCallback(
    (_event: React.SyntheticEvent, value: any) => {
      const newKey = (value as TOption)?.props?.key;
      if (newKey) {
        option.change(newKey);
      } else {
        option.change(null);
      }
    },
    [option],
  );

  useEffect(
    () => {
      if (setDefaultOnce.current) return;
      if (!option.value && defaultKey) {
        setDefaultOnce.current = true;
        option.change(defaultKey);
      }
    },
    // we don't want every "option" change to trigger this effect, only the "option.value" changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultKey, option.value, setDefaultOnce],
  );

  const hasError = field.errors?.length > 0;
  const { defaultValue: _, ...selectProps } = field.select.props;
  return (
    <FieldWrapper field={field} sx={field.props.sx} focused={focused}>
      <Autocomplete
        forcePopupIcon
        disablePortal
        fullWidth
        freeSolo
        clearOnBlur={false}
        noOptionsText="Nenhuma opção encontrada."
        filterOptions={filterOptions}
        value={option.value}
        onFocus={() => {
          setFocused(true);
          option.focus();
        }}
        onBlur={() => {
          setFocused(false);
          option.blur();
        }}
        onChange={onChange}
        onKeyDown={onKeyDown}
        options={options}
        renderInput={(params) => (
          <TextField
            error={hasError}
            fullWidth
            {...params}
            {...field.label.props}
            {...selectProps}
            InputProps={{
              ...params.InputProps,
              ...selectProps,
              name: `${selectProps.name}-autocomplete`,
              inputProps: {
                ...params.inputProps,
                autoComplete: 'off',
                defaultValue: null,
                value:
                  (params.inputProps.value === '' &&
                    option.value === defaultKey &&
                    !startedTyping.current) ||
                  params.inputProps.value === optionDict[option.value as string]
                    ? optionDict[option.value as string]
                    : params.inputProps.value,
              },
            }}
          />
        )}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionKey={getOptionKey}
        getOptionLabel={getOptionLabel}
        slotProps={{
          popper: {
            ...selectProps,
            disablePortal: true,
          },
        }}
        ListboxProps={{
          ...field.props,
          sx: { ...field.props.sx, overflowY: 'scroll', maxHeight: '180px' },
        }}
      />
    </FieldWrapper>
  );
}
