import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { Submission } from '@conform-to/react';

import CloseRoundedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Box, IconButton, Skeleton, Typography } from '@mui/material';

import {
  ContractBRPjEntry,
  ContractEntry,
  PjPaymentAttachmentAllowedContentTypes,
  PjPaymentRequestInput,
  fetchPostContractPaymentRequests,
  fetchPostContractPaymentRequestsAttachment,
  useGetContract,
} from '@octopus/api';
import {
  brEnquadramentosTributarios,
  isBRPjContract,
} from '@octopus/contract-types';

import { BackButton } from '../../../../modules/components/BackButton';
import { uploadFile } from '../../../../modules/components/file/upload';
import { MissingContractIdAlert } from '../../../../modules/components/MissingContractIdAlert';
import {
  ConfirmationDialog,
  ErrorDialog,
  LoadingDialog,
} from '../../../../modules/components/pj/dialogs';
import {
  LucroPresumidoInputForm,
  SimplesNacionalInputForm,
} from '../../../../modules/components/pj/inputForms';
import { poolOpenSearch } from '../../../../modules/components/pj/pjComponents';
import {
  FileComponent,
  UploadComponent,
} from '../../../payment-requests/[payment-request]/fileComponents';

export function ContractorCreatePaymentRequestPage({
  organizationId,
  companyId,
  contractId,
}: {
  organizationId: string;
  companyId: string;
  contractId: string;
}) {
  return (
    <NewPaymentRequestComponent
      organizationId={organizationId}
      companyId={companyId}
      contractId={contractId}
    />
  );
}

function NewPaymentRequestComponent({
  contractId,
  companyId,
  organizationId,
}: {
  contractId: string;
  organizationId: string;
  companyId: string;
}) {
  const [file, setFile] = useState<File | undefined>(undefined);

  const { data, isLoading } = useGetContract({
    pathParams: {
      organizationId,
      contractId,
    },
  });

  if (isLoading) {
    return (
      <Box
        display={'flex'}
        width={'100vw'}
        height={'100vh'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Skeleton variant={'rounded'} width={'100%'} height={'100%'} />
      </Box>
    );
  }

  if (!data) {
    return <MissingContractIdAlert />;
  }

  return (
    <>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            display: 'none',
          },
        })}
      >
        <BackButton />
      </Box>
      <Box
        display={'flex'}
        width={'100%'}
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            height: '100vh',
          },
        })}
      >
        <FileComponent
          file={file}
          showDeleteButton={true}
          isLoading={false}
          setFile={setFile}
        />
        <CreatePaymentRequestComponent
          companyId={companyId}
          organizationId={organizationId}
          file={file}
          setFile={setFile}
          contract={data}
        />
      </Box>
    </>
  );
}

function CreatePaymentRequestComponent({
  contract,
  companyId,
  organizationId,
  file,
  setFile,
}: {
  contract: ContractEntry;
  organizationId: string;
  companyId: string;
  file?: File;
  setFile: (file: File) => void;
}) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [payload, setPayload] = useState({} as Record<string, unknown>);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const submitAction = async (payload: Record<string, unknown>) => {
    setLoading(true);
    try {
      const paymentRequestId = await postPaymentRequest({
        organizationId,
        companyId,
        contract: contract,
        period: payload.period as string,
        grossAmount: payload.grossAmount as string,
        description: payload.description as string,
        invoice: payload.invoiceNumber
          ? ({
              number: parseInt(payload.invoiceNumber as string),
              taxes: {
                br: {
                  inss: payload.inss as string,
                  irrf: payload.irrf as string,
                  csll: payload.csll as string,
                  cofins: payload.cofins as string,
                  pisPasep: payload.pisPasep as string,
                  others: payload.others as string,
                  iss: payload.iss as string,
                },
              },
            } as PjPaymentRequestInput['invoice'])
          : undefined,
      });

      if (!file) {
        await poolOpenSearch({
          organizationId,
          companyId,
          contractId: contract.contractId,
          paymentRequestId,
        });

        navigate('/contractor/payment-requests');
        return;
      }

      const attachmentEntry = await fetchPostContractPaymentRequestsAttachment({
        pathParams: {
          organizationId,
          companyId,
          paymentRequestId: paymentRequestId,
          contractId: contract.contractId,
        },
        body: {
          type: 'invoice',
          contentType: file.type as PjPaymentAttachmentAllowedContentTypes,
          contentLength: file.size,
          fileName: file.name,
        },
      });

      await uploadFile(attachmentEntry.uploadUrl, file);

      await poolOpenSearch({
        organizationId,
        companyId,
        contractId: contract.contractId,
        paymentRequestId,
        filters: {
          invoiceUploaded: ['true'],
        },
      });

      navigate('/contractor/payment-requests');
    } catch (e) {
      console.log('error in submit', e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitForm = useCallback(
    (
      _event: React.FormEvent<HTMLFormElement>,
      submission?: Submission<unknown>,
    ) => {
      if (!('value' in submission)) {
        return;
      }

      const value = submission.value as Record<string, unknown>;

      if (!file) {
        setPayload(value);
        setShowConfirmationDialog(true);
      } else {
        submitAction(value).catch(() => {
          setError(true);
          setLoading(false);
        });
      }
    },
    [organizationId, file],
  );

  if (!isBRPjContract(contract)) {
    return null;
  }

  return (
    <>
      <Box
        display={'flex'}
        boxSizing={'border-box'}
        overflow={'auto'}
        flexDirection={'column'}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            width: '100%',
            px: 2.5,
            mt: -1,
            pb: 12,
          },
          [theme.breakpoints.up('sm')]: {
            width: '50%',
            px: 7,
            pt: 5,
            maxHeight: '950px',
          },
        })}
      >
        <Box
          display={'flex'}
          pt={2}
          gap={1.5}
          flexDirection={'column'}
          boxSizing={'border-box'}
          width={'100%'}
          pb={2}
        >
          <Box
            pb={1}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box
              display="flex"
              flexDirection="row"
              data-testid="new-payment-request-header"
              gap={1.5}
              px={0.5}
            >
              <Typography variant="h2" fontWeight={700}>
                Nova solicitação de pagamento
              </Typography>
            </Box>
            <Box
              display={'flex'}
              justifyContent={'end'}
              alignItems={'start'}
              sx={(theme) => ({
                [theme.breakpoints.down('sm')]: {
                  display: 'none',
                },
              })}
            >
              <IconButton
                onClick={() => {
                  navigate('/contractor/payment-requests');
                }}
              >
                <CloseRoundedIcon
                  sx={{
                    width: '32px',
                    height: '32px',
                    color: '#25252D',
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            p={1}
            width={'100%'}
            boxSizing={'border-box'}
          >
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
              })}
            >
              {!file ? (
                <Box
                  border={'1px dashed'}
                  borderColor={'strokes.light'}
                  borderRadius={'8px'}
                  width={'100%'}
                  mb={3}
                >
                  <UploadComponent setFile={setFile} />
                </Box>
              ) : (
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={1}
                  justifyContent={'center'}
                  py={2}
                  boxSizing={'border-box'}
                  width={'100%'}
                >
                  <Typography variant={'caption'} fontWeight={700}>
                    Arquivo
                  </Typography>

                  <Box
                    display={'flex'}
                    gap={1}
                    p={1.5}
                    bgcolor={'strokes.secondary'}
                    borderRadius={'8px'}
                    width={'100%'}
                    boxSizing={'border-box'}
                    alignItems={'center'}
                  >
                    <Box
                      sx={{
                        bgcolor: '#1E78FF',
                        borderRadius: 0.5,
                        color: '#FFF',
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="32px"
                      height="32px"
                    >
                      <InsertDriveFileOutlinedIcon width={3} />
                    </Box>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'center'}
                      flexGrow={1}
                    >
                      <Typography variant={'caption'} fontWeight={500}>
                        {file.name}
                      </Typography>
                      <Typography
                        variant={'caption'}
                        color={'text.secondary'}
                        fontWeight={500}
                      >
                        {parseInt(`${file.size / 1000}`)} kb
                      </Typography>
                    </Box>
                    <Box display={'flex'} gap={2} px={0.5}>
                      <Box border={'1px solid'} borderColor={'strokes.light'} />
                      <IconButton
                        onClick={() => {
                          setFile(undefined);
                        }}
                      >
                        <DeleteOutlinedIcon
                          sx={{
                            width: '24px',
                            height: '24px',
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>

            {contract.br.empresa.enquadramentoTributario ===
              brEnquadramentosTributarios.simples && (
              <SimplesNacionalInputForm onSubmit={onSubmitForm} />
            )}
            {contract.br.empresa.enquadramentoTributario ===
              brEnquadramentosTributarios.lucroPresumido && (
              <LucroPresumidoInputForm onSubmit={onSubmitForm} />
            )}
          </Box>
        </Box>
      </Box>

      {showConfirmationDialog && (
        <ConfirmationDialog
          open={showConfirmationDialog}
          action={() => submitAction(payload)}
          setOpen={setShowConfirmationDialog}
          setError={setError}
        />
      )}
      {loading && (
        <LoadingDialog
          open={loading}
          message={'Criando a solicitação, aguarde alguns segundos...'}
        />
      )}
      {error && <ErrorDialog open={error} setOpen={setError} />}
    </>
  );
}

async function postPaymentRequest({
  organizationId,
  companyId,
  contract,
  period,
  grossAmount,
  description,
  invoice,
}: {
  organizationId: string;
  companyId: string;
  contract: ContractEntry;
  period: string;
  grossAmount: string;
  description: string;
  invoice?: PjPaymentRequestInput['invoice'];
}): Promise<string> {
  const hasTaxes =
    (contract.br as ContractBRPjEntry).empresa.enquadramentoTributario ===
    brEnquadramentosTributarios.lucroPresumido;

  const convertEmptyToZero = (value: string | undefined) => {
    return value === '' ? '0' : value;
  };

  const response = await fetchPostContractPaymentRequests({
    pathParams: {
      organizationId,
      companyId,
      contractId: contract.contractId,
    },
    body: {
      contractId: contract.contractId,
      period: period,
      grossAmount: grossAmount,
      description: description,
      ...(invoice !== undefined && {
        invoice: {
          number: invoice.number,
          ...(hasTaxes && {
            taxes: {
              br: {
                inss: convertEmptyToZero(invoice.taxes.br.inss),
                irrf: convertEmptyToZero(invoice.taxes.br.irrf),
                csll: convertEmptyToZero(invoice.taxes.br.csll),
                cofins: convertEmptyToZero(invoice.taxes.br.cofins),
                pisPasep: convertEmptyToZero(invoice.taxes.br.pisPasep),
                others: convertEmptyToZero(invoice.taxes.br.others),
                iss: convertEmptyToZero(invoice.taxes.br.iss),
              },
            },
          }),
        },
      }),
    } as PjPaymentRequestInput,
  });

  return response.id;
}
