import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { credentialsProvider } from '../../credentialsProvider';
import { download } from '../../spreadsheets/downloader';

type DownloadModalState = {
  open: {
    state: boolean;
    setState: (state: boolean) => void;
  };
  error: {
    state: boolean;
    setState: (state: boolean) => void;
  };
  loading: {
    state: boolean;
    setState: (state: boolean) => void;
  };
};

export type SupportedProvisionTypes = 'decimoTerceiro' | 'ferias';
export type SupportedOperationTypes = 'saldoInicial' | 'saldoFinal';
export type SupportedSystems = 'dominio' | 'senior';

const fileTypes: Record<
  SupportedSystems,
  Record<SupportedProvisionTypes, Record<SupportedOperationTypes, string>>
> = {
  dominio: {
    decimoTerceiro: {
      saldoInicial: 'saldoInicialDominioDecimoTerceiro',
      saldoFinal: 'saldoFinalDominioDecimoTerceiro',
    },
    ferias: {
      saldoInicial: 'saldoInicialDominioFerias',
      saldoFinal: 'saldoFinalDominioFerias',
    },
  },
  senior: {
    decimoTerceiro: {
      saldoInicial: 'saldoInicialSeniorDecimoTerceiro',
      saldoFinal: 'saldoFinalSeniorDecimoTerceiro',
    },
    ferias: {
      saldoInicial: 'saldoInicialSeniorFerias',
      saldoFinal: 'saldoFinalSeniorFerias',
    },
  },
};

type ProvisionDownloadModalProps = {
  organizationId: string;
  label: string;
  states: DownloadModalState;
};

export default function ProvisionDownloadModal({
  organizationId,
  label,
  states,
}: ProvisionDownloadModalProps) {
  const [system, setSystem] = React.useState<SupportedSystems>();
  const [provisionType, setProvisionType] =
    React.useState<SupportedProvisionTypes>();
  const [operationType, setOperationType] =
    React.useState<SupportedOperationTypes>();

  const handleSubmit = async () => {
    states.loading.setState(true);
    states.error.setState(false);
    states.open.setState(false);

    const type = fileTypes[system][provisionType][operationType];

    const credentials = await credentialsProvider.getCredentials();

    download(credentials, organizationId, type)
      .then(() => {
        states.loading.setState(false);
      })
      .catch(() => {
        states.loading.setState(false);
        states.error.setState(true);
      });
  };

  const handleClose = () => {
    states.open.setState(false);
  };

  const handleSystemChange = (event: SelectChangeEvent) => {
    setSystem(event.target.value as SupportedSystems);
  };

  const handleProvisionTypeChange = (event: SelectChangeEvent) => {
    setProvisionType(event.target.value as SupportedProvisionTypes);
  };

  const handleOperationTypeChange = (event: SelectChangeEvent) => {
    setOperationType(event.target.value as SupportedOperationTypes);
  };

  return (
    <Dialog open={states.open.state} onClose={handleClose} disableEscapeKeyDown>
      <DialogTitle>{label}</DialogTitle>
      <DialogContent>
        <div>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="system-select-label">Sistema</InputLabel>

            <Select
              labelId="system-select-label"
              id="system-select"
              label="Sistema"
              onChange={handleSystemChange}
              autoWidth
              sx={{ minWidth: 300 }}
            >
              <MenuItem value="dominio">Domínio</MenuItem>
              <MenuItem value="senior">Senior</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="provision-type-select-label">
              Tipo de provisão
            </InputLabel>

            <Select
              labelId="provision-type-select-label"
              id="provision-type-select"
              label="Tipo de provisão"
              onChange={handleProvisionTypeChange}
              autoWidth
              sx={{ minWidth: 300 }}
            >
              <MenuItem value="decimoTerceiro">Décimo terceiro</MenuItem>
              <MenuItem value="ferias">Férias</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="operation-type-select-label">
              Tipo de operação
            </InputLabel>

            <Select
              labelId="operation-type-select-label"
              id="operation-type-select"
              label="Tipo de operação"
              onChange={handleOperationTypeChange}
              autoWidth
              sx={{ minWidth: 300 }}
            >
              <MenuItem value="saldoInicial">
                Adicionar transferência de entrada na competência seguinte
              </MenuItem>
              <MenuItem value="saldoFinal">
                Substituir saldo final da mesma competência
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!system || !provisionType || !operationType}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
}
