import { useEffect, useState } from 'react';

import { IconX } from '@tabler/icons-react';

import { Box, Drawer, IconButton, Skeleton, Typography } from '@mui/material';

import { PayrollInputsPayload } from '@octopus/api';

import { ConfirmDialog } from '../../../../../../modules/components/ConfirmDialog';
import { EmployeesState } from '../useSubmissionState';

import { TimesheetLoadFileStep, TimesheetValidationResultStep } from './steps';
import { TimesheetLoading } from './steps/feedback/TimesheetLoading';
import {
  TimesheetEntry,
  TimesheetStepError,
  TimesheetStepWarning,
} from './steps/types';
import { TimesheetDownloadTemplate } from './TimesheetDownloadTemplate';
import { TimesheetOverrideInputDialog } from './TimesheetOverrideInputDialog';
import { useTimesheetFileDefinition } from './useTimesheetFileDefinition';
import { useTimesheetValidation } from './useTimesheetValidation';

export type TimesheetLoaderProps = {
  organizationId: string;
  companyId: string;
  employees: EmployeesState;
  period: string;
  open: boolean;
  payrollInputs: PayrollInputsPayload;
  onClose: () => void;
};

export function TimesheetDrawer({
  organizationId,
  companyId,
  employees,
  period,
  open,
  onClose,
  payrollInputs,
}: TimesheetLoaderProps) {
  const [file, setFile] = useState<File | null>(null);
  const [step, setStep] = useState<'load-file' | 'result'>('load-file');
  const [entries, setEntries] = useState<TimesheetEntry[]>([]);
  const [warnings, setWarnings] = useState<TimesheetStepWarning[]>([]);
  const [errors, setErrors] = useState<TimesheetStepError[]>([]);
  const [overrideDialogOpen, setOverrideDialogOpen] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const { isValidating, processTimesheetValidation } = useTimesheetValidation();
  const {
    fileDefinition,
    isLoading: isLoadingConfig,
    loadTimesheetFileDefinition,
  } = useTimesheetFileDefinition({ companyId, organizationId });

  async function onFileSet(file?: File) {
    if (file) {
      try {
        const { entries, errors, warnings } = await processTimesheetValidation({
          organizationId,
          companyId,
          period,
          employees,
          fileDefinition,
          file,
          payrollInputs,
        });

        setFile(file);
        setEntries(entries);
        setWarnings(warnings);
        setErrors(errors);
        setStep('result');
      } catch (error) {
        console.error(error);
        reset();
      }
    }
  }

  function handleConfirmation() {
    if (!entries.length) {
      console.warn('No entries to insert');
      return;
    }

    const hasEntriesToOverride = entries.some(
      ({ employee, timesheet }) => employee.inputs[timesheet.type.value],
    );

    if (hasEntriesToOverride) {
      return setOverrideDialogOpen(true);
    }

    return setEntryValuesToPayroll(entries);
  }

  function handleOnlyNewEntriesConfirmation() {
    const newEntries = getOnlyNewEntries();

    return setEntryValuesToPayroll(newEntries);
  }

  function getOnlyNewEntries() {
    return entries.filter(({ employee, timesheet }) => {
      const hasEntry = employee.inputs[timesheet.type.value];

      return !hasEntry;
    });
  }

  function setEntryValuesToPayroll(entriesToInsert: TimesheetEntry[]) {
    try {
      entriesToInsert.forEach(({ employee, timesheet }) =>
        employees.edit.set(
          employee.payrollId,
          timesheet.type.value,
          timesheet.value.value,
        ),
      );

      return onCloseWrapper();
    } catch (error) {
      console.error(error);
    }
  }

  function reset() {
    setFile(null);
    setStep('load-file');
    setEntries([]);
    setWarnings([]);
    setErrors([]);
    setOverrideDialogOpen(false);
    setDiscardDialogOpen(false);
  }

  function onCloseWrapper() {
    reset();
    return onClose();
  }

  function handlePendingChangesToClose() {
    if (!file || !entries.length) {
      return onCloseWrapper();
    }

    return setDiscardDialogOpen(true);
  }

  useEffect(() => {
    if (open) {
      loadTimesheetFileDefinition();
    }
  }, [open, loadTimesheetFileDefinition]);

  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        variant="temporary"
        PaperProps={{
          sx: {
            maxWidth: {
              xs: '100%',
              sm: '70%',
              md: '50%',
            },
            height: '100%',
            width: '100%',
          },
        }}
      >
        <Box
          data-testid="timesheet-drawer"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            paddingTop: (theme) => theme.spacing(5),
            paddingX: (theme) => theme.spacing(5),
            gap: (theme) => theme.spacing(2),
          }}
        >
          <Box
            data-testid="timesheet-drawer-header"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              data-testid="timesheet-drawer-title"
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography color="textSecondary" variant="caption">
                Importar lançamentos
              </Typography>
              <Typography variant="h3">Lançamentos de ponto</Typography>
            </Box>
            <IconButton
              size="small"
              data-testid="timesheet-drawer-close-button"
              onClick={handlePendingChangesToClose}
            >
              <IconX />
            </IconButton>
          </Box>
          {isLoadingConfig && (
            <Box
              data-testid="timesheet-stepper-skeleton"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Skeleton
                sx={{
                  height: '100%',
                  width: '100%',
                }}
              />
              <Skeleton
                sx={{
                  height: '30%',
                  width: '100%',
                }}
              />
            </Box>
          )}
          {!isLoadingConfig && (
            <Box
              data-testid="timesheet-steps"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                gap: (theme) => theme.spacing(1.5),
                paddingTop: (theme) => theme.spacing(2),
                paddingBottom: (theme) => theme.spacing(4),
              }}
            >
              {isValidating && (
                <TimesheetLoading
                  isLoading={isValidating}
                  text="Validando lançamentos..."
                />
              )}
              {!isValidating && step === 'load-file' && (
                <TimesheetLoadFileStep onFileSet={onFileSet} />
              )}
              {!isValidating && step === 'result' && (
                <TimesheetValidationResultStep
                  errors={errors}
                  warnings={warnings}
                  entries={entries}
                  onCancel={reset}
                  fileDefinition={fileDefinition}
                  onConfirm={handleConfirmation}
                />
              )}
              {step !== 'result' && (
                <TimesheetDownloadTemplate fileDefinition={fileDefinition} />
              )}
            </Box>
          )}
        </Box>
      </Drawer>
      <TimesheetOverrideInputDialog
        open={overrideDialogOpen}
        setOpen={setOverrideDialogOpen}
        onOverride={() => setEntryValuesToPayroll(entries)}
        onNotOverride={handleOnlyNewEntriesConfirmation}
      />
      <ConfirmDialog
        open={discardDialogOpen}
        onClose={() => setDiscardDialogOpen(false)}
        onConfirm={onCloseWrapper}
        title="Descartar importação"
        description="Deseja sair e descartar os dados importados?"
      />
    </>
  );
}
