import pako from 'pako';

import {
  ReportWriterPayload,
  newWorkbookWriter,
  writeReportContent,
} from '@octopus/reports-writer';

export function downloadBlobFile(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob);
  const linkElement = document.createElement('a');
  linkElement.href = url;
  linkElement.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      linkElement.removeEventListener('click', clickHandler);
    }, 150);
  };
  linkElement.addEventListener('click', clickHandler, false);
  linkElement.click();
}

function downloadBase64File(
  base64Data: string,
  fileName: string,
  contentType: string,
): void {
  const binaryData = atob(base64Data);

  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], { type: contentType || 'text/plain' });

  downloadBlobFile(blob, fileName);
}

function base64ToBinary(base64: string): Uint8Array {
  const raw = atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));
  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

function decompressData(compressedBase64: string): Uint8Array {
  const compressedData = base64ToBinary(compressedBase64);
  const decompressedData = pako.inflate(compressedData);
  return decompressedData;
}

function getMimeType(extension?: string): string {
  switch (extension?.toLowerCase()) {
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'csv':
      return 'text/csv';
    case 'pdf':
      return 'application/pdf';
    case 'json':
      return 'application/json';
    default:
      return 'application/octet-stream';
  }
}

export async function handleReportDownloadResponse(response: {
  fileName: string;
  fileContent?: string;
  rawData?: string;
}): Promise<void> {
  const { fileName, fileContent, rawData } = response;

  console.log(response);

  if (!rawData && !fileContent) {
    console.error('Invalid report response: No fileContent or rawData found.');
    throw new Error('Could not download file: Invalid response from server.');
  }

  // Client-side generation (report's rawData)
  if (rawData) {
    console.log('Handling rawData for client-side generation...');

    const decompressedBytes = decompressData(rawData);
    const jsonPayload = new TextDecoder().decode(decompressedBytes);
    const payload: ReportWriterPayload = JSON.parse(jsonPayload);

    console.log('Payload received:', payload);

    const writer = newWorkbookWriter();
    writeReportContent({
      writer,
      header: payload.header,
      details: payload.details,
      content: payload.content,
    });

    const buffer = await writer.toBuffer();
    const blob = new Blob([buffer], {
      type: getMimeType(payload.fileExtension),
    });

    downloadBlobFile(blob, fileName);
    console.log('Client-side report generated and downloaded.');
    return;
  }

  // Handle fileContent (Server-side generation)
  if (fileContent) {
    console.log('Handling fileContent for server-side generated report...');
    const extension = fileName?.split('.').pop();
    const mimeType = getMimeType(extension);
    downloadBase64File(fileContent, fileName, mimeType);
    return;
  }
}

export function downloadBase64XLSXFile(base64Data: string, fileName: string) {
  // Consider calling handleReportDownloadResponse if this is for reports
  downloadBase64File(
    base64Data,
    fileName,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  );
}

export function downloadBase64PdfFile(base64Data: string, fileName: string) {
  // Consider calling handleReportDownloadResponse if this is for reports
  downloadBase64File(base64Data, fileName, 'application/pdf');
}
