import { identity } from 'lodash';

import { banksList } from '@octopus/contract-types';
import {
  CBOs,
  Categorias,
  CondicaoIngresso,
  ContratoRegimeParcial,
  EstadoCivil,
  Estados,
  GrauInstrucao,
  IndicativoAdmissao,
  Mapper,
  Municipios,
  Nacionalidades,
  NaturezaAtividade,
  NaturezaEstagio,
  NivelEstagio,
  Paises,
  RacaCor,
  Sexo,
  TempoResidencia,
  TipoAdmissao,
  TipoContrato,
  TipoDependente,
  TipoJornada,
  TipoLogradouro,
  TipoRegimeJornada,
  TipoRegimePrevidenciario,
  UnidadeSalarioFixo,
} from '@octopus/esocial/mapper';

import {
  capitalize,
  formatBooleanBR,
  formatCBO,
  formatCEP,
  formatCNAE,
  formatCNPJ,
  formatCPF,
  formatDateBR,
  formatMoney,
  formatMonthBR,
  formatPhoneBR,
} from '../../baseTypes';

import { unary, whenDefined } from './functionHelpers';
import { IAppContext } from './types';

export type ContractFieldChange = {
  path: string;
  oldData: unknown;
  newData: unknown;
};

type SyncValueDataFormatter = (
  value: string,
  appContext?: IAppContext,
) => string | undefined;
export type TFieldChangeFormatter =
  | {
      label: string | ((path: string) => string);
      data?: SyncValueDataFormatter;
    }
  | { hidden: true };

function dependenteLabel(label: string) {
  return (path: string) =>
    `Dependente ${parseInt(getIndexFromPath(path), 10) + 1}: ${label}`;
}

function getIndexFromPath(path: string) {
  return path.match(/\/\d+\//g)?.map((s) => s.replace(/[^\d]+/g, ''))[0] ?? '';
}

function byCode(
  mapper: Mapper,
): (value: string | number | undefined) => string | undefined {
  return whenDefined(mapper.getByCode.bind(mapper));
}

export const FieldChangeFormatters: Record<string, TFieldChangeFormatter> = {
  default: {
    label: identity,
  },
  'br/situacao/tipo': {
    hidden: true,
  },
  'br/pessoa/nmTrab': {
    label: 'Nome completo',
  },
  'br/pessoa/nmSoc': {
    label: 'Nome social',
  },
  'br/pessoa/nmMae': {
    label: 'Nome da mãe',
  },
  'br/pessoa/cpfTrab': {
    label: 'CPF',
    data: whenDefined(formatCPF),
  },
  'br/pessoa/sexo': {
    label: 'Sexo',
    data: byCode(Sexo),
  },
  'br/pessoa/racaCor': {
    label: 'Raça e cor',
    data: byCode(RacaCor),
  },
  'br/pessoa/estCiv': {
    label: 'Estado civil',
    data: byCode(EstadoCivil),
  },
  'br/pessoa/grauInstr': {
    label: 'Grau de instrução',
    data: byCode(GrauInstrucao),
  },
  'br/nascimento/dtNascto': {
    label: 'Data de nascimento',
    data: whenDefined(formatDateBR),
  },
  'br/nascimento/paisNascto': {
    label: 'País de nascimento',
    data: byCode(Paises),
  },
  'br/nascimento/paisNac': {
    label: 'Nacionalidade',
    data: byCode(Nacionalidades),
  },
  'br/nascimento/tmpResid': {
    label: 'Tempo de residência',
    data: byCode(TempoResidencia),
  },
  'br/nascimento/condIng': {
    label: 'Condição de ingresso no país',
    data: byCode(CondicaoIngresso),
  },
  'br/endereco/tipo': {
    label: 'Tipo de endereço',
    data: whenDefined(capitalize),
  },
  'br/endereco/cep': {
    label: 'CEP',
    data: unary(formatCEP),
  },
  'br/empresa/endereco/tpLograd': {
    label: 'Tipo logradouro',
    data: byCode(TipoLogradouro),
  },
  'br/empresa/endereco/dscLograd': {
    label: 'Logradouro',
  },
  'br/endereco/tpLograd': {
    label: 'Tipo de Logradouro',
    data: byCode(TipoLogradouro),
  },
  'br/endereco/dscLograd': {
    label: 'Logradouro',
  },
  'br/endereco/nrLograd': {
    label: 'Número',
  },
  'br/endereco/complemento': {
    label: 'Complemento',
  },
  'br/endereco/bairro': {
    label: 'Bairro',
  },
  'br/endereco/uf': {
    label: 'Unidade Federal',
    data: byCode(Estados),
  },
  'br/endereco/codMunic': {
    label: 'Cidade / Município',
    data: byCode(Municipios),
  },
  'br/contato/fonePrinc': {
    label: 'Telefone',
    data: whenDefined(formatPhoneBR),
  },
  'br/contato/emailPrinc': {
    label: 'Email pessoal',
  },
  'br/contatoDeEmergencia/nome': {
    label: 'Contato de emergência: Nome do contato',
  },
  'br/contatoDeEmergencia/telefone': {
    label: 'Contato de emergência: Telefone',
    data: whenDefined(formatPhoneBR),
  },
  'br/contatoDeEmergencia/relacao': {
    label: 'Contato de emergência: Relação',
  },
  'br/deficiencia/defFisica': {
    label: 'Possui Deficiência Física ?',
    data: formatBooleanBR,
  },
  'br/deficiencia/defAuditiva': {
    label: 'Possui Deficiência Auditiva ?',
    data: formatBooleanBR,
  },
  'br/deficiencia/defIntelectual': {
    label: 'Possui Deficiência Intelectual ?',
    data: formatBooleanBR,
  },
  'br/deficiencia/defVisual': {
    label: 'Possui Deficiência Visual ?',
    data: formatBooleanBR,
  },
  'br/deficiencia/defMental': {
    label: 'Possui Deficiência Mental ?',
    data: formatBooleanBR,
  },
  'br/deficiencia/observacao': {
    label: 'Deficiências: Descrição',
  },
  'br/deficiencia/reabReadap': {
    label: 'Deficiências: Pessoa reabilitada ou readaptada?',
    data: formatBooleanBR,
  },
  'br/deficiencia/infoCota': {
    label: 'Deficiências: Contabilizado para preenchimento de cota?',
    data: formatBooleanBR,
  },
  'br/dependentes/*/id': {
    hidden: true,
  },
  'br/dependentes/*/nmDep': {
    label: dependenteLabel('Nome completo'),
  },
  'br/dependentes/*/tpDep': {
    label: dependenteLabel('Parentesco'),
    data: byCode(TipoDependente),
  },
  'br/dependentes/*/descrDep': {
    label: dependenteLabel('Descrição do parentesco'),
  },
  'br/dependentes/*/dtNascto': {
    label: dependenteLabel('Data de nascimento'),
    data: whenDefined(formatDateBR),
  },
  'br/dependentes/*/cpfDep': {
    label: dependenteLabel('CPF'),
    data: formatCPF,
  },
  'br/dependentes/*/sexoDep': {
    label: dependenteLabel('Sexo'),
    data: byCode(Sexo),
  },
  'br/dependentes/*/incTrab': {
    label: dependenteLabel(
      'Possui incapacidade física ou mental para trabalho?',
    ),
    data: formatBooleanBR,
  },
  'br/dependentes/*/depIRRF': {
    label: dependenteLabel('Considerado(a) nos cálculos do Imposto de renda'),
    data: formatBooleanBR,
  },
  'br/dependentes/*/depSF': {
    label: dependenteLabel('Considerado(a) nos cálculos do Salário família'),
    data: formatBooleanBR,
  },
  workerId: {
    label: 'Matrícula na empresa',
  },
  'br/trabalho/jobTitleId': {
    label: 'Cargo',
    data: (v, appContext) =>
      appContext?.company?.jobTitles?.find(({ id }) => v === id)?.summary?.name,
  },
  'br/trabalho/CBOFuncao': {
    label: 'CBO Função',
    data: whenDefined((d) => `${formatCBO(d)} - ${CBOs.getByCode(d)}`),
  },
  'br/trabalho/nmFuncao': {
    label: 'Nome Função',
  },
  'br/trabalho/departmentId': {
    label: 'Departamento',
    data: (d, appContext) =>
      appContext?.company?.departments?.find(({ id }) => d === id)?.name,
  },
  //This is a legacy field, keeping it as hidden for old contracts.
  'br/trabalho/departamento': {
    hidden: true,
  },
  'br/gestao/costCenterId': {
    label: 'Centro de custo',
    data: (d, appContext) =>
      appContext?.company?.costCenters?.find(({ id }) => d === id)?.name,
  },
  'br/gestao/codigoCentroCusto': {
    hidden: true,
  },
  'br/gestao/nomeCentroCusto': {
    hidden: true,
  },
  'br/vinculo/emailCorp': {
    label: 'Email corporativo',
  },
  'br/regime/dtAdm': {
    label: 'Data de admissão',
    data: whenDefined(formatDateBR),
  },
  'br/vinculo/matricula': {
    label: 'Matrícula eSocial',
  },
  'br/vinculo/tpRegTrab': {
    label: 'Tipo de regime trabalhista',
    data: whenDefined((d) => (d.toString() === '1' ? 'CLT' : 'Estatutário')),
  },
  'br/regime/tpAdmissao': {
    label: 'Tipo de admissão',
    data: byCode(TipoAdmissao),
  },
  'br/regime/tpRegJor': {
    label: 'Regime jornada',
    data: byCode(TipoRegimeJornada),
  },
  'br/regime/indAdmissao': {
    label: 'Indicativo de admissão',
    data: byCode(IndicativoAdmissao),
  },
  'br/trabalho/codCateg': {
    label: 'Código da categoria',
    data: byCode(Categorias),
  },
  'br/regime/nrProcTrab': {
    label: 'Número do processo trabalhista',
  },
  'br/regime/natAtividade': {
    label: 'Natureza da atividade',
    data: byCode(NaturezaAtividade),
  },
  'br/regime/dtOpcFGTS': {
    label: 'Data de opção do FGTS',
    data: whenDefined(formatDateBR),
  },
  'br/vinculo/tpRegPrev': {
    label: 'Tipo de regime previdenciario',
    data: byCode(TipoRegimePrevidenciario),
  },
  'br/vinculo/nrProcJud': {
    label: 'Alvará judicial relativo à contratação de menor de idade',
  },
  'br/jornada/qtdHrsSem': {
    label: 'Jornada em horas',
    data: whenDefined((d) => `${d} horas`),
  },
  'br/jornada/tpJornada': {
    label: 'Tipo de jornada',
    data: byCode(TipoJornada),
  },
  'br/jornada/tmpParc': {
    label: 'Tempo parcial',
    data: byCode(ContratoRegimeParcial),
  },
  'br/jornada/horNoturno': {
    label: 'Possui horário noturno?',
    data: formatBooleanBR,
  },
  'br/jornada/descJorn': {
    label: 'Descrição da jornada semanal',
  },
  'br/duracao/tpContr': {
    label: 'Tipo de contrato',
    data: byCode(TipoContrato),
  },
  'br/duracao/dtTerm': {
    label: 'Data do término',
    data: whenDefined(formatDateBR),
  },
  'br/duracao/dtPerExpTerm': {
    label: 'Período de experiência',
    data: whenDefined(formatDateBR),
  },
  'br/duracao/dtPerExpProrTerm': {
    label: 'Prorrogação do período de experiência',
    data: whenDefined(formatDateBR),
  },
  'br/duracao/clauAssec': {
    label: 'Contém cláusula assecuratória?',
    data: formatBooleanBR,
  },
  'br/duracao/objDet': {
    label: 'Objeto razão da contratação temporária',
  },
  'br/regime/cnpjSindCategProf': {
    label: 'CNPJ do sindicato',
    data: whenDefined((d) =>
      d === '37115367003500' ? 'Não filiado' : formatCNPJ(d),
    ),
  },
  'br/regime/dtBase': {
    label: 'Mês base da categoria',
    data: formatMonthBR,
  },
  'br/estagio/natEstagio': {
    label: 'Estágio: Natureza',
    data: byCode(NaturezaEstagio),
  },
  'br/estagio/nivEstagio': {
    label: 'Estágio: Nível',
    data: byCode(NivelEstagio),
  },
  'br/estagio/areaAtuacao': {
    label: 'Estágio: Área de atuação',
  },
  'br/estagio/dtPrevTerm': {
    label: 'Estágio: Data de termino',
    data: whenDefined(formatDateBR),
  },
  'br/estagio/nrApol': {
    label: 'Estágio: Número da apólice do seguro',
    data: whenDefined((d) => d.match(/.{5}/g)?.join(' ')),
  },
  'br/estagio/instEnsino/cnpjInstEnsino': {
    label: 'Estágio: Instituição de ensino',
    data: formatCNPJ,
  },
  'br/estagio/cnpjAgntInteg': {
    label: 'Estágio: CNPJ do agente de integração',
    data: formatCNPJ,
  },
  'br/estagio/cpfSupervisor': {
    label: 'Estágio: CPF da pessoa supervisora',
    data: formatCPF,
  },
  'br/observacao': {
    label: 'Obervações',
  },
  'br/remuneracao/vrSalFx': {
    label: 'Salário base',
    data: whenDefined(formatMoney),
  },
  newSalary: {
    label: 'Salário base',
    data: whenDefined(formatMoney),
  },
  'br/remuneracao/undSalFixo': {
    label: 'Unidade para base de cálculo',
    data: byCode(UnidadeSalarioFixo),
  },
  'br/remuneracao/dscSalVar': {
    label: 'Descrição da remuneração variável',
  },
  'br/pagamento/tipoPix': {
    label: 'Tipo Pix',
  },
  'br/pagamento/chavePix': {
    label: 'Chave Pix',
  },
  'br/pagamento/codigoBanco': {
    label: 'Banco: código',
  },
  'br/pagamento/nomeBanco': {
    label: 'Banco: nome',
    data: whenDefined((d) => banksList[d]),
  },
  'br/pagamento/agencia': {
    label: 'Banco: Agência',
  },
  'br/pagamento/conta': {
    label: 'Banco: conta',
  },
  'br/prestador/posicao/jobTitleId': {
    label: 'Cargo',
    data: (d, appContext) =>
      appContext?.company?.jobTitles?.find(({ id }) => d === id)?.summary?.name,
  },
  'br/prestador/posicao/departamento': {
    label: 'Departamento',
  },
  'br/prestador/gestao/costCenterId': {
    label: 'Centro de custo',
    data: (d, appContext) =>
      appContext?.company?.costCenters?.find(({ id }) => d === id)?.name,
  },
  'br/prestador/gestao/nomeCentroCusto': {
    hidden: true,
  },
  'br/prestador/gestao/codigoCentroCusto': {
    hidden: true,
  },
  'br/emailCorp': {
    label: 'Email corporativo',
  },
  'br/contrato/dataAssinatura': {
    label: 'Contrato assinado em',
    data: whenDefined(formatDateBR),
  },
  'br/contrato/validade': {
    label: 'Validade do contrato',
    data: whenDefined(formatDateBR),
  },
  'br/contrato/renovacao': {
    label: 'Renovação do contrato',
    data: whenDefined((d) => (d !== 'automática' ? 'Manual' : 'Automática')),
  },
  'br/contrato/inicio': {
    label: 'Data de início do contrato',
    data: whenDefined(formatDateBR),
  },
  'br/contrato/termino': {
    label: 'Data de término do contrato',
    data: whenDefined(formatDateBR),
  },
  'br/empresa/razaoSocial': {
    label: 'Contratada: Razão social',
  },
  'br/empresa/cnpj': {
    label: 'Contratada: CNPJ',
    data: formatCNPJ,
  },
  'br/empresa/cnae': {
    label: 'Contratada: CNAE principal',
    data: formatCNAE,
  },
  'br/empresa/enquadramentoTributario': {
    label: 'Contratada: Enquadramento tributário',
    data: whenDefined((d) =>
      d === 'simples' ? 'SIMPLES Nacional' : 'Lucro Presumido',
    ),
  },
  'br/empresa/endereco/cep': {
    label: 'Empresa: CEP',
    data: unary(formatCEP),
  },
  'br/empresa/endereco/nrLograd': {
    label: 'Empresa: Número',
  },
  'br/empresa/endereco/complemento': {
    label: 'Empresa: Complemento',
  },
  'br/empresa/endereco/bairro': {
    label: 'Empresa: Bairro',
  },
  'br/empresa/endereco/uf': {
    label: 'Empresa: Unidade Federal',
  },
  'br/empresa/endereco/codMunic': {
    label: 'Empresa: Cidade / Município',
    data: byCode(Municipios),
  },
  'br/pagamento/honorarios': {
    label: 'Pagamento: Honorários',
    data: whenDefined(formatMoney),
  },
  'br/pagamento/periodicidade': {
    label: 'Pagamento: Peridiocidade',
    data: whenDefined(capitalize),
  },
  'destination/legalEntityId': {
    label: 'Transferência interna',
    data: (d, appContext) =>
      appContext?.company?.legalEntities?.find(({ id }) => id === d)?.name,
  },
  newLegalEntityId: {
    label: 'Transferência entre empresas',
    data: (d, appContext) =>
      appContext?.company?.legalEntities?.find(({ id }) => id === d)?.name,
  },
  'br/options/adiantamentoSalarial': {
    label: 'Opção de adiantamento salarial',
    data: formatBooleanBR,
  },
  'old/legalEntityId': {
    hidden: true,
  },
  'br/novaMatricula': {
    hidden: true,
  },
  'old/br/matricula': {
    hidden: true,
  },
  newCompanyId: {
    hidden: true,
  },
  'old/companyId': {
    hidden: true,
  },
  effectiveDate: {
    hidden: true,
  },
  'reportAddedEvent/sequenceId': {
    hidden: true,
  },
  contractId: {
    label: 'Gestor(a)',
  },
  'reportRemovedEvent/contractId': {
    hidden: true,
  },
  'reportRemovedEvent/sequenceId': {
    hidden: true,
  },
  terminationDate: {
    label: 'Data de desligamento',
    data: whenDefined(formatDateBR),
  },
};
