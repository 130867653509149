import ExcelJS from 'exceljs';

import { writeDetailsSection } from './sections/details';
import { writeHeaderSection } from './sections/header';
import { writeTableSection } from './sections/table';
import { writeValuesSection } from './sections/values';
import { WorkbookWriter, WorksheetWriter } from './types';

export function newWorkbookWriter(): WorkbookWriter {
  const workbook = new ExcelJS.Workbook();

  return {
    __workbook: workbook,
    addWorksheet: (name: string, { startRow = 2, startColumn = 2 } = {}) => {
      return newWorksheetWriter(workbook, name, startRow, startColumn);
    },
    toBuffer: () => workbook.xlsx.writeBuffer(),
    getFileNameExtension: () => 'xlsx',
  };
}

function newWorksheetWriter(
  workbook: ExcelJS.Workbook,
  name: string,
  startRow = 2,
  startColumn = 2,
): WorksheetWriter {
  const worksheet = workbook.addWorksheet(name, {
    properties: { defaultColWidth: 20 },
    state: 'visible',
    views: [{ showGridLines: false }],
  });

  // Set width for all columns before the start column
  for (let col = 1; col < startColumn; col++) {
    worksheet.getColumn(col).width = 4;
  }

  // Set height for all rows before the start row
  for (let row = 1; row < startRow; row++) {
    worksheet.getRow(row).height = 20;
  }

  let currentRow = startRow;

  return {
    addHeaderSection: (props) =>
      (currentRow = writeHeaderSection({
        workbook,
        worksheet,
        startRow: currentRow,
        startColumn,
        props,
      })),
    addDetailsSection: (props) =>
      (currentRow = writeDetailsSection({
        worksheet,
        startRow: currentRow,
        startColumn,
        props,
      })),
    addTableSection: (props) =>
      (currentRow = writeTableSection({
        worksheet,
        startRow: currentRow,
        startColumn,
        props,
      })),
    addValuesSection: (props) =>
      (currentRow = writeValuesSection({
        worksheet,
        startRow: currentRow,
        startColumn,
        props,
      })),
  };
}
