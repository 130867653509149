import { MembershipSummary } from '@octopus/api';

import { MembershipContext } from '../modules/types';

type Membership = MembershipContext | MembershipSummary;

export function membershipEquals(a?: Membership, b?: Membership) {
  if (!a || !b) {
    return false;
  }
  return a.organizationId === b.organizationId;
}
