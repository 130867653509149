import {
  AdmissionDraftEntry,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftUpdateInput,
} from '@octopus/api';
import { getWorkerCategory } from '@octopus/esocial/contracts';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { CustomFieldsHelper } from '../../formStepInputs/customFields';
import { AdmissionFormState } from '../../types';

const getFormStateFromEntry = ({
  admissionDraftEntry,
}: {
  admissionDraftEntry: AdmissionDraftEntry;
}): AdmissionFormState => {
  if (!admissionDraftEntry) {
    return {};
  }

  const trabalho =
    admissionDraftEntry.br && 'trabalho' in admissionDraftEntry.br
      ? admissionDraftEntry.br?.trabalho
      : undefined;
  const workerCategory =
    admissionDraftEntry.workerCategory ??
    getWorkerCategory(admissionDraftEntry.contractType, trabalho ?? undefined);

  return {
    workerCategory,
    legalEntity: admissionDraftEntry.legalEntityId,
    admissionType: admissionDraftEntry.admissionType,
    user_name: admissionDraftEntry.userData?.name,
    user_email: admissionDraftEntry.userData?.email,
    workerId: admissionDraftEntry.workerId,
    ...CustomFieldsHelper.entryToFormState(admissionDraftEntry),
  };
};

const getUpdateInputFromFormState = ({
  formState,
  currentStepName,
  body,
}: {
  formState: AdmissionFormState;
  currentStepName: AdmissionDraftInputFormStepEnum;
  body: AdmissionDraftUpdateInput;
}): AdmissionDraftUpdateInput => {
  switch (currentStepName) {
    case admissionDraftFormSteps.modalidade_contrato: {
      body.admissionType = formState.admissionType;
      body.workerCategory = formState.workerCategory;
      body.legalEntityId = formState.company?.legalEntity;

      const companyId = Object.entries(formState.company ?? {}).find(
        ([key, _value]) => key.includes('companyId'),
      )?.[1];
      body.companyId = companyId as string;
      break;
    }
    case admissionDraftFormSteps.criacao_usuario: {
      body.userData = {
        name: formState.user_name,
        email: formState.user_email,
      };
      break;
    }
    case admissionDraftFormSteps.custom_fields_admin:
    case admissionDraftFormSteps.custom_fields_worker: {
      const customFields = CustomFieldsHelper.formStateToEntry(formState);
      body.customFields = customFields;
      break;
    }
    default:
      return null;
  }

  return body;
};

export const common = { getUpdateInputFromFormState, getFormStateFromEntry };
