import { useEffect, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import {
  CompanyList,
  PayrollConfigurationEntry,
  PayrollConfigurationInput,
  PayrollConfigurationUpdate,
  fetchGetCompanyPayrollConfiguration,
  fetchPostCompanyPayrollConfiguration,
  fetchPutCompanyPayrollConfiguration,
  useGetAllCompanyPayrollConfiguration,
} from '@octopus/api';
import { formatCNPJ } from '@octopus/formatters';
import { preferences as enginePreferences } from '@octopus/payroll-engine/public-types/core';
import { DateField } from '@octopus/ui/data-grid';

type SingleChoiceFromListPreferencePayload = {
  type: 'singleChoiceFromList';
  values: { [key: string]: string };
  defaultValue: string;
  labels: { [key: string]: string };
};

type MultipleChoiceFromListPreferencePayload = {
  type: 'multipleChoiceFromList';
  values: { [key: string]: string };
  defaultValue: string[];
  labels: { [key: string]: string };
};

type NumberPreferencePayload = {
  type: 'number';
  defaultValue: number;
};

type Preference = {
  id: string;
  title: string;
  parent: 'brPreferences' | 'paymentConfig';
} & (
  | SingleChoiceFromListPreferencePayload
  | NumberPreferencePayload
  | MultipleChoiceFromListPreferencePayload
);

export const preferences = {
  quantosDiasTemNoMes: {
    type: 'singleChoiceFromList',
    ...enginePreferences.quantosDiasTemNoMes,
    title: 'Quantos dias tem no mês',
    labels: {
      sempre30Dias: 'Sempre 30 dias',
      diasReaisDoMes: 'Usar dias reais do mês',
    },
    parent: 'brPreferences',
  },
  usarSempreDiasReaisDoMes: {
    type: 'singleChoiceFromList',
    ...enginePreferences.usarSempreDiasReaisDoMes,
    title: 'Quando sobreescrever sempre 30 dias',
    labels: {
      nunca: 'Nunca',
      admissao: 'Admissão',
    },
    parent: 'brPreferences',
  },
  proporcionalidadeDeDecimoTerceiroAteDezembroParaAdmitidosNoAno: {
    type: 'singleChoiceFromList',
    ...enginePreferences.proporcionalidadeDeDecimoTerceiroAteDezembroParaAdmitidosNoAno,
    title:
      'Preferência de proporcionalidade para a primeira parcela do 13o salário',
    labels: {
      avos: 'Avos adquiridos',
      ano: 'Ano todo, incluindo dezembro',
    },
    parent: 'brPreferences',
  },
  proporcionalidadeAdiantamento: {
    type: 'singleChoiceFromList',
    ...enginePreferences.proporcionalidadeAdiantamento,
    title: 'Proporcionalidade adiantamento',
    labels: {
      proporcional: 'Proporcional',
      integral: 'Integral',
    },
    parent: 'brPreferences',
  },
  pagarMediasNaPrimeiraParcela13o: {
    type: 'singleChoiceFromList',
    ...enginePreferences.pagarMediasNaPrimeiraParcela13o,
    title: 'Pagar médias na primeira parcela do 13o salário',
    labels: {
      sim: 'Sim',
      nao: 'Não',
    },
    parent: 'brPreferences',
  },
  pagarMediasEmFaltasJustificadas: {
    type: 'singleChoiceFromList',
    ...enginePreferences.pagarMediasEmFaltasJustificadas,
    title: 'Pagar médias em faltas justificadas',
    labels: {
      sim: 'Sim',
      nao: 'Não',
    },
    parent: 'brPreferences',
  },
  pagarMediasEmLicencaRemunerada: {
    type: 'singleChoiceFromList',
    ...enginePreferences.pagarMediasEmLicencaRemunerada,
    title: 'Pagar médias em licença remunerada',
    labels: {
      sim: 'Sim',
      nao: 'Não',
    },
    parent: 'brPreferences',
  },
  subtrairFeriasDosMesesTrabalhadosParaMedias: {
    type: 'singleChoiceFromList',
    ...enginePreferences.subtrairFeriasDosMesesTrabalhadosParaMedias,
    title: 'Subtrair férias dos meses trabalhados para médias',
    labels: {
      sim: 'Sim',
      nao: 'Não',
    },
    parent: 'brPreferences',
  },
  subtrairAfastamentosDosMesesTrabalhadosParaMedias: {
    type: 'singleChoiceFromList',
    ...enginePreferences.subtrairAfastamentosDosMesesTrabalhadosParaMedias,
    title: 'Subtrair afastamentos dos meses trabalhados para médias',
    labels: {
      sim: 'Sim',
      nao: 'Não',
    },
    parent: 'brPreferences',
  },
  descontarAjusteNegativoDe13o: {
    type: 'singleChoiceFromList',
    ...enginePreferences.descontarAjusteNegativoDe13o,
    title: 'Descontar ajuste negativo de 13o',
    labels: {
      sim: 'Sim',
      nao: 'Não',
    },
    parent: 'brPreferences',
  },
  considerarMesDaRescisaoParaMedias: {
    type: 'singleChoiceFromList',
    ...enginePreferences.considerarMesDaRescisaoParaMedias,
    title: 'Considerar mês da rescisão para médias',
    labels: {
      quandoAdquirirAvo: 'Quando adquirir avo',
      sempre: 'Sempre',
      nunca: 'Nunca',
    },
    parent: 'brPreferences',
  },
  incluirMediasNaMultaArtigo479: {
    type: 'singleChoiceFromList',
    ...enginePreferences.incluirMediasNaMultaArtigo479,
    title: 'Incluir médias na multa do artigo 479',
    labels: {
      sim: 'Sim',
      nao: 'Não',
    },
    parent: 'brPreferences',
  },
  incluirMediasNaMultaArtigo480: {
    type: 'singleChoiceFromList',
    ...enginePreferences.incluirMediasNaMultaArtigo480,
    title: 'Incluir médias na multa do artigo 480',
    labels: {
      sim: 'Sim',
      nao: 'Não',
    },
    parent: 'brPreferences',
  },
  percentualAdicionalNoturno: {
    type: 'number',
    defaultValue: 0.2,
    id: 'percentualAdicionalNoturno',
    title: 'Percentual de adicional noturno',
    parent: 'brPreferences',
  },
  dsrDeComissoesParaMedias: {
    type: 'singleChoiceFromList',
    ...enginePreferences.dsrDeComissoesParaMedias,
    title: 'DSR de comissões para médias',
    labels: {
      darPreferenciaParaEventoHistorico:
        'Dar preferência para evento histórico',
      sempreRecalcular: 'Sempre recalcular',
    },
    parent: 'brPreferences',
  },
  diasDescontadosParaInsalubridade: {
    type: 'multipleChoiceFromList',
    ...enginePreferences.diasDescontadosParaInsalubridade,
    title: 'Dias descontados para insalubridade',
    labels: {
      ferias: 'Férias',
      admissaoERescisao: 'Admissão e rescisão',
      afastamentos: 'Afastamentos',
    },
    parent: 'brPreferences',
  },
  sendPayslip: {
    id: 'sendPayslip',
    type: 'singleChoiceFromList',
    defaultValue: 'nao',
    values: { sim: 'sim', nao: 'nao' },
    title: 'Enviar holerite por folha',
    labels: { sim: 'Sim', nao: 'Não' },
    parent: 'brPreferences',
  },
  daysBeforePaymentToSendPayslip: {
    id: 'daysBeforePaymentToSendPayslip',
    type: 'number',
    defaultValue: 0,
    title: 'Quantos dias antes do dia do pagamento devemos enviar holerite?',
    parent: 'brPreferences',
  },
  baseMonth: {
    type: 'singleChoiceFromList',
    id: 'baseMonth',
    values: { current: 'current', next: 'next' },
    defaultValue: 'next',
    title: 'Mês de pagamento',
    labels: {
      current: 'Mês atual da competência',
      next: 'Mês seguinte a competência',
    },
    parent: 'paymentConfig',
  },
  baseDay: {
    type: 'number',
    defaultValue: 1,
    id: 'baseDay',
    title: 'Dia de pagamento',
    parent: 'paymentConfig',
  },
} as const satisfies Record<string, Preference>;

type PreferenceKey = keyof typeof preferences;
const preferencesKeys = Object.keys(preferences) as PreferenceKey[];
type PreferenceValues<Key> = Key extends PreferenceKey
  ? (typeof preferences)[Key] extends SingleChoiceFromListPreferencePayload
    ? keyof (typeof preferences)[Key]['values']
    : (typeof preferences)[Key] extends NumberPreferencePayload
      ? number
      : (typeof preferences)[Key] extends MultipleChoiceFromListPreferencePayload
        ? (keyof (typeof preferences)[Key]['values'])[]
        : never
  : never;

function getDefaultPreferences(): Record<PreferenceKey, any> {
  return preferencesKeys.reduce(
    (acc, key) => {
      acc[key] = preferences[key].defaultValue;
      return acc;
    },
    {} as Record<PreferenceKey, any>,
  );
}

export function Preferences({
  organizationId,
  companies,
}: {
  organizationId: string;
  companies: CompanyList;
}) {
  const [response, setResponse] = useState('');
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const [company, setCompany] = useState('');
  const [effectiveDate, setEffectiveDate] = useState<Dayjs | null>(null);
  const [version, setVersion] = useState<number | null>(null);
  const [isNewConfig, setIsNewConfig] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [preferenceOptions, setPreferenceOptions] = useState(
    getDefaultPreferences(),
  );
  const { data: allConfigs } = useGetAllCompanyPayrollConfiguration({
    pathParams: { organizationId, companyId: company },
  });

  function loadConfig(date: Dayjs) {
    setDisabled(true);
    fetchGetCompanyPayrollConfiguration({
      pathParams: {
        organizationId,
        companyId: company,
        effectiveDate: date.format('YYYY-MM-DD'),
      },
    })
      .then((config: PayrollConfigurationEntry) => {
        const newState = getDefaultPreferences();
        preferencesKeys.forEach((key) => {
          const parent = preferences[key].parent;
          const parentObj = config[parent] as Record<string, any>;
          if (parentObj && parentObj[key] !== undefined) {
            newState[key] = parentObj[key];
          }
        });
        setPreferenceOptions(newState);
        if (config.effectiveDate === date.format('YYYY-MM-DD')) {
          setIsNewConfig(false);
          setVersion(config.version);
          setWarning(
            'Você está alterando a configuração existente nessa data.',
          );
          setResponse('');
        } else {
          setIsNewConfig(true);
          setVersion(null);
          setWarning('Nova configuração. Não há registro com a mesma data.');
          setResponse('');
        }
        setDisabled(false);
      })
      .catch((err) => {
        setError('Erro ao buscar configuração: ' + err.message);
        setDisabled(false);
      });
  }

  useEffect(() => {
    if (!company) {
      resetAll();
      return;
    }
    loadConfig(dayjs('9999-12-31'));
  }, [company]);

  useEffect(() => {
    if (!company || effectiveDate === null) return;
    loadConfig(effectiveDate);
  }, [effectiveDate, company]);

  function resetAll() {
    setEffectiveDate(null);
    setPreferenceOptions(getDefaultPreferences());
    setIsNewConfig(true);
    setVersion(null);
    setDisabled(true);
  }

  function setPreferenceOption<Key extends PreferenceKey>(
    key: string,
    value: PreferenceValues<Key>,
  ) {
    setPreferenceOptions((old) => ({ ...old, [key]: value }));
  }

  async function handleSubmit() {
    if (!company || !effectiveDate) return;
    setError('');
    setResponse('');
    setWarning('');
    const effectiveDateStr = effectiveDate.format('YYYY-MM-DD');
    const configBody = preferencesKeys.reduce(
      (acc, key) => {
        if (preferences[key].parent === 'brPreferences') {
          acc.brPreferences[key] = preferenceOptions[key];
        } else if (preferences[key].parent === 'paymentConfig') {
          acc.paymentConfig[key] = preferenceOptions[key];
        }
        return acc;
      },
      {
        brPreferences: {} as Record<string, any>,
        paymentConfig: {} as Record<string, any>,
      },
    );
    if (isNewConfig) {
      try {
        await fetchPostCompanyPayrollConfiguration({
          pathParams: { organizationId, companyId: company },
          body: {
            effectiveDate: effectiveDateStr,
            modules: ['brazil'],
            ...configBody,
          } as PayrollConfigurationInput,
        });
        setResponse('Nova configuração salva com sucesso!');
      } catch (err: any) {
        setError('Erro ao criar nova configuração: ' + err.message);
      }
    } else {
      if (version == null) {
        setError('Versão não encontrada para atualização. Tente recarregar.');
        return;
      }
      try {
        await fetchPutCompanyPayrollConfiguration({
          pathParams: {
            organizationId,
            companyId: company,
            effectiveDate: effectiveDateStr,
          },
          body: { ...configBody, version } as PayrollConfigurationUpdate,
        });
        setResponse('Configuração existente atualizada com sucesso!');
      } catch (err: any) {
        setError('Erro ao atualizar configuração: ' + err.message);
      }
    }
  }

  return (
    <Paper elevation={1} sx={{ backgroundColor: 'white', mt: 2 }}>
      <Box py={4} px={4}>
        <Typography variant="h2">Configurações de Companhia</Typography>
        <Divider sx={{ my: 2 }} />
        {error && (
          <Box pb={2}>
            <Alert severity="error" onClose={() => setError('')}>
              {error}
            </Alert>
          </Box>
        )}
        {response && (
          <Box pb={2}>
            <Alert onClose={() => setResponse('')}>{response}</Alert>
          </Box>
        )}
        {warning && (
          <Box pb={2}>
            <Alert severity="warning" onClose={() => setWarning('')}>
              {warning}
            </Alert>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2">Escolha uma companhia</Typography>
            <Select
              fullWidth
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            >
              <MenuItem value="">
                <em>Selecione...</em>
              </MenuItem>
              {companies.data?.map((c) => (
                <MenuItem key={c.companyId} value={c.companyId}>
                  {c.br?.razaoSocial} ({formatCNPJ(c.br?.cnpj)})
                </MenuItem>
              ))}
            </Select>
          </Box>
          {company && allConfigs && (
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="body2">Datas existentes</Typography>
              <Select
                fullWidth
                value={effectiveDate ? effectiveDate.format('YYYY-MM-DD') : ''}
                onChange={(e) => setEffectiveDate(dayjs(e.target.value))}
              >
                <MenuItem value="">
                  <em>Selecione...</em>
                </MenuItem>
                {allConfigs.map((config: PayrollConfigurationEntry) => (
                  <MenuItem
                    key={config.effectiveDate}
                    value={config.effectiveDate}
                  >
                    {dayjs(config.effectiveDate).format('DD/MM/YYYY')}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2">
              Criar nova - Data da mudança
            </Typography>
            <DateField
              dateFormat="DD / MM / YYYY"
              value={effectiveDate}
              onChange={(date) => setEffectiveDate(date ?? null)}
              sx={{ width: '100%' }}
              disabled={disabled}
            />
          </Box>
          <Divider sx={{ my: 2 }} />
          {preferencesKeys.map((key) => {
            const pref = preferences[key];
            const { type, title } = pref;
            const currentValue = preferenceOptions[key];
            let formElement: JSX.Element | null = null;
            if (type === 'singleChoiceFromList') {
              formElement = (
                <RadioGroup
                  value={currentValue}
                  onChange={(e) =>
                    setPreferenceOption(
                      key,
                      e.target.value as PreferenceValues<typeof key>,
                    )
                  }
                >
                  {Object.entries(pref.labels).map(([val, label]) => (
                    <FormControlLabel
                      key={val}
                      value={val}
                      control={<Radio />}
                      label={label}
                      disabled={disabled}
                    />
                  ))}
                </RadioGroup>
              );
            } else if (type === 'multipleChoiceFromList') {
              formElement = (
                <Select
                  multiple
                  value={currentValue as string[]}
                  onChange={(e) =>
                    setPreferenceOption(
                      key,
                      e.target.value as (
                        | 'ferias'
                        | 'admissaoERescisao'
                        | 'afastamentos'
                      )[],
                    )
                  }
                  disabled={disabled}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                >
                  {Object.entries(pref.labels).map(([val, label]) => (
                    <MenuItem key={val} value={val}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              );
            } else if (type === 'number') {
              formElement = (
                <TextField
                  type="number"
                  value={currentValue}
                  disabled={disabled}
                  onChange={(e) =>
                    setPreferenceOption(key, Number(e.target.value))
                  }
                />
              );
            }
            return (
              <Box
                key={key}
                display="flex"
                flexDirection="column"
                gap={0.5}
                mt={2}
              >
                <Typography variant="body2">{title}</Typography>
                <FormControl size="small">{formElement}</FormControl>
              </Box>
            );
          })}
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box display="flex" justifyContent="flex-end">
          <Button
            onClick={handleSubmit}
            disabled={!company || !effectiveDate || disabled}
          >
            Salvar configurações
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
