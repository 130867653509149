import { useContext, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { Business, CheckCircle } from '@mui/icons-material';
import { Alert, Box, Button } from '@mui/material';

import {
  LegalEntitySummary,
  fetchSearchAllPayrolls,
  useGetAllCompanies,
} from '@octopus/api';
import { formatCNPJ } from '@octopus/formatters';

import { AppContext } from '../../../../../app/context';
import { useFFlags } from '../../../../../app/fflags';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { CompanySummaryContext } from '../../../../types';
import { Record, RecordEntry } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

export type InternalTransferRecordProps = BaseRecordProps<{
  organizationId: string;
  companyId: string;
  legalEntityId: string;
  workerId: string;
}>;

export type CompanyTransferRecordProps = BaseRecordProps<{
  organizationId: string;
  companyId: string;
  contractId: string;
  legalEntityId: string;
  workerId: string;
  matricula: string;
}>;

function InternalTransferRecord(
  props: InternalTransferRecordProps & {
    onCompanyTransfer: () => void;
  },
) {
  const {
    data: { organizationId, companyId, legalEntityId, workerId },
    onCompanyTransfer,
  } = props;

  const { editing, formData, updateData, editRecordProps } =
    useRecordEdit(props);
  const { FFlags } = useFFlags();

  const { appContext } = useContext(AppContext);

  const legalEntities = appContext?.company?.legalEntities;
  const legalEntity = legalEntities?.find(({ id }) => legalEntityId === id);

  const allCompaniesQuery = useGetAllCompanies(
    {
      pathParams: {
        organizationId,
      },
    },
    {
      enabled: props.edit?.isEditable && !!organizationId && !!companyId,
    },
  );
  const allCompanies = allCompaniesQuery.data?.data || [];

  return (
    <Record
      title="Empregador"
      edit={editRecordProps}
      extraActions={
        allCompanies.length > 1 && props.edit?.isEditable ? (
          <FFlags isCompanyTransferEnabledForOrganization>
            <Button
              variant="text"
              onClick={onCompanyTransfer}
              startIcon={<Business />}
            >
              Transferir entre empresas
            </Button>
          </FFlags>
        ) : null
      }
    >
      <RecordEntry
        label="Nome do empregador"
        isLoading={!legalEntity}
        edit={{
          editing,
          type: 'options',
          disabled: !legalEntities,
          value: formData.legalEntityId,
          options: legalEntities?.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
          onChange: (value) => {
            updateData((data) => ({
              ...data,
              legalEntityId: value,
            }));
          },
        }}
      >
        {legalEntity?.name}
      </RecordEntry>
      <RecordEntry
        label="Razão social do empregador"
        isLoading={!legalEntity}
        edit={{
          editing,
          type: 'options',
          value: formData.legalEntityId,
          options: legalEntities?.map(
            ({
              id,
              summary: {
                br: { razaoSocial },
              },
            }) => ({
              value: id,
              label: razaoSocial,
            }),
          ),
          disabled: true,
          onChange: (value) => {
            updateData((data) => ({
              ...data,
              legalEntityId: value,
            }));
          },
        }}
      >
        {legalEntity?.summary?.br?.razaoSocial}
      </RecordEntry>
      <RecordEntry
        label="CNPJ do empregador"
        isLoading={!legalEntity}
        edit={{
          editing,
          type: 'options',
          value: formData.legalEntityId,
          options: legalEntities?.map(
            ({
              id,
              summary: {
                br: { cnpj },
              },
            }) => ({
              value: id,
              label: cnpj,
            }),
          ),
          disabled: true,
          onChange: (value) => {
            updateData((data) => ({
              ...data,
              legalEntityId: value,
            }));
          },
        }}
      >
        {formatCNPJ(legalEntity?.summary?.br?.cnpj)}
      </RecordEntry>
      {editing && (
        <RecordEntry
          label="Matrícula na empresa"
          edit={{
            type: 'text',
            editing,
            disabled: legalEntityId === formData.legalEntityId,
            value: formData.workerId,
            onChange: (value) =>
              updateData((data) => ({
                ...data,
                workerId: value,
              })),
          }}
        >
          {workerId}
        </RecordEntry>
      )}
    </Record>
  );
}

function CompanyTransferRecord(
  props: CompanyTransferRecordProps & {
    onCancel: () => void;
  },
) {
  const {
    data: {
      organizationId,
      companyId,
      legalEntityId,
      workerId,
      matricula,
      contractId,
    },
    onCancel,
  } = props;

  const { showSnackbar } = useSnackbar();
  const { appContext } = useContext(AppContext);
  const { company, currentCompany } = appContext;
  const { companies, legalEntities } = company;

  const { formData, updateData, editRecordProps } = useRecordEdit({
    ...props,
    onSuccess: () => {
      setTimeout(() => {
        showSnackbar({
          isOpen: true,
          variant: 'default',
          Message: 'Colaborador transferido',
          StartAdornment: <CheckCircle />,
          hasCloseAction: true,
          EndAdornment: (
            <Button
              variant="text"
              onClick={() => {
                window.location.reload();
              }}
            >
              Ver
            </Button>
          ),
        });
      }, 100);
      onCancel();
    },
  });
  const editing = true;

  const legalEntitiesByCompanyId = legalEntities.reduce(
    (acc, legalEntity) => {
      acc[legalEntity.summary.companyId] = [
        ...(acc[legalEntity.summary.companyId] ?? []),
        legalEntity.summary,
      ];
      return acc;
    },
    {} as Record<string, LegalEntitySummary[]>,
  );

  const currentLegalEntity = legalEntities?.find(
    (legalEntity) => legalEntity.id === legalEntityId,
  )?.summary;

  const allPayrollsQuery = useQuery({
    queryKey: ['allPayrolls', organizationId, companyId, contractId],
    queryFn: async () => {
      return fetchSearchAllPayrolls({
        pathParams: {
          organizationId,
          companyId,
        },
        body: {
          filtering: {
            elements: {
              contractId: [contractId],
              status: ['open', 'approving'],
            },
          },
        },
      });
    },
  });

  const isLoading = allPayrollsQuery.isLoading;

  return (
    <Record
      title="Transferência entre empresas"
      edit={{
        ...editRecordProps,
        isEditing: editing,
        onCancel: (confirm) => {
          editRecordProps.onCancel(confirm);
          onCancel();
        },
      }}
    >
      <RecordEntry
        label="Nome da empresa"
        isLoading={isLoading}
        edit={{
          editing,
          type: 'options',
          value: formData.companyId,
          options: companies.map((company: CompanySummaryContext) => ({
            value: company.id,
            label: company.name,
          })),
          onChange: (value) => {
            updateData((data) => ({
              ...data,
              companyId: value,
              legalEntityId:
                legalEntitiesByCompanyId[value]?.[0]?.legalEntityId,
            }));
          },
        }}
      >
        {currentCompany?.name}
      </RecordEntry>
      <RecordEntry
        label="Razão social da empresa"
        isLoading={isLoading}
        edit={{
          editing,
          type: 'options',
          value: formData.companyId,
          options: companies.map((company) => ({
            value: company.id,
            label: company.summary.br.razaoSocial,
          })),
          disabled: true,
          onChange: () => null,
        }}
      >
        {currentCompany?.summary?.br?.razaoSocial}
      </RecordEntry>

      <RecordEntry
        label="Nome do empregador (matriz/filial)"
        isLoading={isLoading}
        edit={{
          editing,
          type: 'options',
          value: formData.legalEntityId,
          options: (legalEntitiesByCompanyId[formData.companyId] || []).map(
            (legalEntity) => ({
              value: legalEntity.legalEntityId,
              label: legalEntity.br.nomeFantasia,
            }),
          ),
          onChange: (value) => {
            updateData((data) => ({
              ...data,
              legalEntityId: value,
            }));
          },
        }}
      >
        {currentLegalEntity?.br?.nomeFantasia}
      </RecordEntry>
      <RecordEntry
        label="CNPJ do empregador"
        isLoading={isLoading}
        edit={{
          editing,
          type: 'options',
          value: formData.legalEntityId,
          options: (legalEntitiesByCompanyId[formData.companyId] || []).map(
            (legalEntity: LegalEntitySummary) => ({
              value: legalEntity.legalEntityId,
              label: formatCNPJ(legalEntity.br.cnpj),
            }),
          ),
          disabled: true,
          onChange: () => null,
        }}
      >
        {formatCNPJ(currentLegalEntity?.br?.cnpj)}
      </RecordEntry>

      <RecordEntry
        label="Matrícula na empresa"
        edit={{
          type: 'text',
          editing,
          value: formData.workerId,
          onChange: (value) =>
            updateData((data) => ({ ...data, workerId: value })),
        }}
      >
        {workerId}
      </RecordEntry>

      <RecordEntry
        label="Matrícula no eSocial"
        edit={{
          type: 'text',
          editing,
          value: formData.matricula,
          onChange: (value) =>
            updateData((data) => ({ ...data, matricula: value })),
        }}
      >
        {matricula}
      </RecordEntry>

      <Box pt={2.5}>
        <Alert severity="warning">
          Você ainda possui folhas de pagamento não aprovadas. Recomendamos que
          aprove ou arquive essas folhas antes de realizar a transferência entre
          empresas. Vale lembrar que, durante a migração, apenas o histórico
          utilizado para cálculos futuros será transferido, e não as folhas de
          pagamento em si.
        </Alert>
      </Box>
    </Record>
  );
}

export function EmpregadorRecord({
  internalTransferRecordProps,
  companyTransferRecordProps,
}: {
  internalTransferRecordProps: InternalTransferRecordProps;
  companyTransferRecordProps: CompanyTransferRecordProps;
}) {
  const [transferType, setTransferType] = useState<'internal' | 'company'>(
    'internal',
  );

  if (transferType === 'internal') {
    return (
      <InternalTransferRecord
        {...internalTransferRecordProps}
        onCompanyTransfer={() => setTransferType('company')}
      />
    );
  }

  return (
    <CompanyTransferRecord
      {...companyTransferRecordProps}
      onCancel={() => setTransferType('internal')}
    />
  );
}
