import { AppError, translate } from '@octopus/i18n';

export function translateAPIErrors(error?: AppError): string[] {
  const errors =
    error?.stack?.details &&
    Object.entries(error?.stack?.details)?.map(([_field, detail]) => {
      const translation = detail.errorCode
        ? translate(detail.errorCode, 'pt')
        : null;
      const errorMessage = translation ?? detail.message;
      const displayName = detail.fieldDisplayName
        ? `${detail.fieldDisplayName}: `
        : '';
      return `${displayName}${errorMessage}`;
    });

  return errors;
}

/**
 * Returns API form errors mapped by field.
 *
 * Nested fields path will be written as `<parent>/<child>/[index]`.
 */
export function mapApiErrors(error?: AppError): Record<string, string> {
  return (
    (error?.stack?.errors &&
      error.stack.errors.reduce(
        (errors, error) => {
          error.issues.forEach((issue) => {
            const key = issue.path.join('/');
            errors[key] =
              translate(issue.message, 'pt') ??
              issue.message ??
              'Valor inválido';
          });
          return errors;
        },
        {} as Record<string, string>,
      )) ??
    {}
  );
}
