import React, { useContext } from 'react';

import dayjs from 'dayjs';

import { Box, Button, Divider, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DataGrid, useDataGrid } from '@octopus/ui/data-grid';

import { AppContext } from '../../../app/context';

import { MultiEditReviewParams } from './types';

export type MultiEditReviewProps<Item extends { id: string }, NewParameters> = {
  newParameters: NewParameters;
  items: Item[];
  effectiveDate: string;
  setEffectiveDate: (dt: string) => void;
  onConfirm: () => void;
  onCancel: () => void;
  params: MultiEditReviewParams<Item, NewParameters>;
};

export function MultiEditReview<Item extends { id: string }, NewParameters>({
  newParameters,
  items,
  effectiveDate,
  setEffectiveDate,
  onConfirm,
  onCancel,
  params: { title, heading, getReviewColumnsDef },
}: MultiEditReviewProps<Item, NewParameters>) {
  const { appContext } = useContext(AppContext);
  const { sortingProps, paginationProps } = useDataGrid({
    key: `multi-edit-${appContext.organization.organizationId}`,
    pagination: {
      rowsPerPageOptions: [5, 25, 50, 100],
    },
  });

  const minDate = dayjs().startOf('month').subtract(1, 'month');
  const maxDate = dayjs().startOf('month').add(1, 'month').endOf('month');

  const itemsToShow = items
    .slice(paginationProps.page * paginationProps.rowsPerPage)
    .slice(0, paginationProps.rowsPerPage);

  return (
    <>
      <Box display="flex" flexDirection="column" gap={4} mb={16} pb={12}>
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="h5" fontWeight="700">
            {title}
          </Typography>
          {heading !== undefined && heading}
          <DataGrid
            sortingProps={sortingProps}
            paginationProps={paginationProps}
            totalRowCount={items.length}
            getRowId={(row) => row.id}
            rows={itemsToShow}
            columns={getReviewColumnsDef(newParameters)}
            emptyMessage="Nenhuma mudança selecionada."
          />
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="body1">
            As mudanças devem começar a ter efeito a partir de qual data?
          </Typography>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
          >
            <DatePicker
              defaultValue={dayjs(effectiveDate)}
              sx={{ width: '100%' }}
              onChange={(v) => setEffectiveDate(v.format('YYYY-MM-DD'))}
              minDate={minDate}
              maxDate={maxDate}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box
        display="flex"
        gap={1}
        py={1}
        px={3}
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'end',
          borderTop: `1px solid ${theme.palette.strokes.light}`,
          background: 'rgba(247, 247, 247, 0.90)',
          backdropFilter: 'blur(4px)',
          position: 'fixed',
          bottom: 0,
          right: 0,
          left: 0,
        })}
      >
        <Button color="secondary" size="large" onClick={onCancel}>
          Voltar
        </Button>
        <Button
          color="primaryAlt"
          size="large"
          onClick={onConfirm}
          disabled={
            !effectiveDate ||
            dayjs(effectiveDate).isBefore(minDate) ||
            dayjs(effectiveDate).isAfter(maxDate)
          }
        >
          Confirmar
        </Button>
      </Box>
    </>
  );
}
