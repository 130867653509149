import { useEffect, useRef, useState } from 'react';

import { ExpandMore } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

import {
  MembershipEntry,
  MembershipTypes,
  fetchSearchAllUsers,
  fetchUpdateMembershipEntry,
  useGetMembershipEntry,
} from '@octopus/api';

import { useSnackbar } from '../../../modules/hooks/useSnackbar';

import {
  DialogContentLoading,
  DialogFullLoading,
} from './dialog/DialogContentLoading';
import { PollingOptions, pollUntil, usePollMutation } from './polling';
import { usePopoverMenu } from './PopoverMenu';
import { UserManagementUtils } from './utils';

type MembershipSelection = {
  label: string;
  value: MembershipTypes;
};
export function UserPermissionsSelect({
  membership,
  afterUpdate,
}: {
  membership: MembershipEntry;
  afterUpdate: () => void;
}) {
  const membershipType = membership.type;
  const membershipLabel =
    UserManagementUtils.formatMembershipType(membershipType);

  const membershipOptions: MembershipSelection[] = [
    { label: 'Padrão', value: 'internal' },
    { label: 'Administrador', value: 'owner' },
  ];

  const [selectedMembership, setSelectedMembership] =
    useState<MembershipSelection>();

  const popoverMenuAnchor = useRef(null);
  const popoverMenu = usePopoverMenu<MembershipTypes>({
    anchorOrigin: { vertical: 'center', horizontal: 'right' },
    anchorEl: popoverMenuAnchor.current,
    options: membershipOptions,
    onSelect: ({ value, label }) => {
      if (value === membershipType) {
        popoverMenu.close();
        return;
      }
      popoverMenu.close();
      setSelectedMembership({ label, value });
      setIsDialogOpen(true);
    },
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const mutation = useUpdateMembershipWithPolling({
    organizationId: membership.organizationId,
    membershipId: membership.membershipId,
    version: membership.version,
    membershipType: selectedMembership?.value,
    options: {
      interval: 1000,
      maxAttempts: 10,
    },
  });

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        gap={1}
        sx={{ '&:hover': { cursor: 'pointer' } }}
        onClick={popoverMenu.toggleOpen}
      >
        <Typography variant="body2" color="textSecondary">
          {membershipLabel}
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center">
          <ExpandMore
            ref={popoverMenuAnchor}
            sx={(theme) => ({
              height: '16px',
              width: '16px',
              color: theme.palette.strokes.heavy,
            })}
          />
        </Box>
      </Box>
      {popoverMenu.MenuComponent}
      <UpdateUserPermissionsDialogInternal
        mutation={mutation}
        selectedMembership={selectedMembership}
        isOpen={isDialogOpen}
        close={() => setIsDialogOpen(false)}
        afterUpdate={afterUpdate}
      />
    </>
  );
}

export type UpdateUserPermissionDialogProps = {
  organizationId: string;
  membershipId: string;
  selectedMembership: MembershipSelection;
  isOpen: boolean;
  close: () => void;
  afterUpdate: () => void;
};
export function UpdateUserPermissionDialog({
  organizationId,
  membershipId,
  selectedMembership,
  isOpen,
  close,
  afterUpdate,
}: UpdateUserPermissionDialogProps) {
  const query = useGetMembershipEntry({
    pathParams: { organizationId, membershipId },
  });

  const mutation = useUpdateMembershipWithPolling({
    organizationId,
    membershipId,
    version: query.data?.version,
    membershipType: selectedMembership?.value,
    options: {
      interval: 1000,
      maxAttempts: 10,
    },
  });

  return query.isLoading || query.isError || !query.data ? (
    <DialogFullLoading isOpen={isOpen} close={close} />
  ) : (
    <UpdateUserPermissionsDialogInternal
      selectedMembership={selectedMembership}
      mutation={mutation}
      isOpen={isOpen}
      close={close}
      afterUpdate={afterUpdate}
    />
  );
}

function UpdateUserPermissionsDialogInternal({
  selectedMembership,
  isOpen,
  close,
  mutation,
  afterUpdate,
}: {
  selectedMembership: MembershipSelection;
  isOpen: boolean;
  close: () => void;
  mutation: ReturnType<typeof useUpdateMembershipWithPolling>;
  afterUpdate: () => void;
}) {
  const [scene, setScene] = useState<1 | 2>(1);
  const setSceneLoading = () => setScene(2);

  const { mutate, isLoading, isError, error } = mutation;

  const { showSnackbar } = useSnackbar();

  const updateMembershipSucceeded = () => {
    close();
    setTimeout(() => {
      showSnackbar({
        isOpen: true,
        variant: 'default',
        Message: 'Permissão alterada com sucesso.',
        StartAdornment: <CheckCircleIcon />,
        hasCloseAction: true,
      });
      afterUpdate();
    }, 400);
  };

  const updateMembershipFailed = () => {
    close();
    showSnackbar({
      isOpen: true,
      variant: 'error',
      Message: 'Ocorreu um erro ao alterar a permissão. Tente novamente.',
      StartAdornment: <CancelIcon />,
      hasCloseAction: true,
    });
  };

  useEffect(() => {
    // is at "octopus dancing" modal but loading is false
    if (scene === 2 && !isLoading) {
      if (!isError) {
        updateMembershipSucceeded();
      } else {
        console.error(error);
        updateMembershipFailed();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isError, error, scene]);

  const loadingScene = (
    <DialogContentLoading message="Atualizando a permissão..." />
  );

  const updateMembershipConfimationScene = (
    <>
      <DialogTitle sx={{ pt: 5, px: 4, pb: 2 }}>
        <Typography fontSize="24px" color="textPrimary" fontWeight={700}>
          Mudar permissões para usuário{' '}
          {selectedMembership?.label?.toLowerCase()}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {selectedMembership?.value === 'internal' ? (
          <Box>
            <Typography variant="body2" color="textPrimary">
              Ao confirmar esta alteração, os usuários selecionados{' '}
              <strong>perderão suas permissões de usuário administrador</strong>
              , podendo acessar somente seus dados pessoais como colaborador.
              Deseja continuar?
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="body2" color="textPrimary">
              Ao confirmar esta alteração, os usuários selecionados passarão a
              ter permissões de administrador,{' '}
              <Typography
                component="span"
                variant="body2"
                sx={{ color: 'warning.main' }}
              >
                o que pode incluir visualização e edição dados sensíveis de toda
                a organização.
              </Typography>{' '}
              Deseja continuar?
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          size="large"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            close();
          }}
        >
          Cancelar
        </Button>
        <Button
          color="primaryAlt"
          size="large"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            mutate();
            setSceneLoading();
          }}
        >
          Continuar
        </Button>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      PaperProps={{ sx: { minWidth: '600px' } }}
      open={isOpen}
      onClose={close}
    >
      {scene === 1 && updateMembershipConfimationScene}
      {scene === 2 || isLoading ? loadingScene : null}
    </Dialog>
  );
}

const useUpdateMembershipWithPolling = ({
  organizationId,
  membershipId,
  membershipType,
  version,
  options,
}: {
  organizationId: string;
  membershipId: string;
  membershipType: MembershipTypes;
  version: number;
  options: PollingOptions;
}) => {
  const mutation = usePollMutation({
    mutationKey: [organizationId, membershipId],
    mutationFn: async () =>
      fetchUpdateMembershipEntry({
        pathParams: { organizationId, membershipId },
        body: { type: membershipType, version },
      }).then(() =>
        // poll to check the membership update was reflected in the search projection
        pollUserWithMembership({
          organizationId,
          membershipId,
          membershipType,
          options,
        }),
      ),
  });

  return mutation;
};

const pollUserWithMembership = async ({
  organizationId,
  membershipId,
  membershipType,
  options,
}: {
  organizationId: string;
  membershipId: string;
  membershipType: MembershipTypes;
  options: PollingOptions;
}) =>
  pollUntil(options, () =>
    fetchSearchUserWithMembership({
      organizationId,
      membershipId,
      membershipType,
    }),
  );

const fetchSearchUserWithMembership = ({
  organizationId,
  membershipId,
  membershipType,
}: {
  organizationId: string;
  membershipId: string;
  membershipType: MembershipTypes;
}) =>
  fetchSearchAllUsers({
    pathParams: { organizationId },
    body: {
      pagination: {
        page: 0,
        size: 1,
      },
      filtering: {
        nested: {
          'memberships.membershipId': [membershipId],
          'memberships.type': [membershipType],
          'memberships.organizationId': [organizationId],
        },
      },
    },
  }).then(({ data }) => data.length > 0);
