import { Box } from '@mui/material';

import { isBRPjContract } from '@octopus/contract-types';

import {
  ContatoDeEmergenciaRecord,
  EnderecoEContatoRecord,
  EstruturaOrganizacionalRecord,
  PagamentoRecord,
  pj,
} from '../../../modules/components/people/br';
import { CustomFieldsRecord } from '../../../modules/components/people/CustomFieldsRecord';
import { RecordGroup } from '../../../modules/components/Record';

import { ContractDetailsTitle } from './ContractDetailsTitle';
import { useContractEventsHistory } from './history';
import { ContractDetailsProps } from './types';
import { useContractEdit } from './useContractEdit';

export function PjContractDetails({
  isEditable,
  refs,
  contractQuery,
  historyEnabled,
  showTitle = true,
  customFieldsQuery,
  contractDocuments,
  refetchDocuments,
}: ContractDetailsProps) {
  const { isLoading, data: contractInfo } = contractQuery;

  const { HistoryDrawer, actionsMenu, updateHistory } =
    useContractEventsHistory({
      contractQuery,
      historyEnabled,
      projectionComponent: PjContractDetails,
      customFieldsQuery,
      refetchContract: contractQuery.refetch,
    });

  const contractEdit = useContractEdit({
    contractQuery,
    isEditable,
    afterUpdate: updateHistory,
  });

  if (
    (!isLoading && (!contractInfo || !contractInfo.br)) ||
    !isBRPjContract(contractInfo) ||
    !contractEdit
  ) {
    return null;
  }

  const {
    UpdateDialog,
    updateRecordProps,
    internalTransferRecordProps,
    changeManagerRecordProps,
  } = contractEdit;

  const {
    organizationId,
    companyId,
    contractId,
    legalEntityId,
    status,
    workerId,
    br,
    orgStructure,
    customFields,
  } = contractInfo;

  const {
    pessoa,
    nascimento,
    endereco,
    contato,
    contatoDeEmergencia,
    dependentes,
    emailCorp,
    prestador,
    empresa,
    contrato,
    pagamento,
  } = br;

  const dependentDocuments = contractDocuments?.filter(
    (document) => document.dependentId != null,
  );

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {showTitle ? (
        <ContractDetailsTitle
          title="Dados do prestador(a) de serviço"
          right={actionsMenu}
        />
      ) : null}
      <Box ref={refs?.personalDetailsRef} data-testid="person-details">
        <RecordGroup title="Dados Pessoais">
          <pj.InformacoesPessoaisRecord
            data={{
              pessoa,
              nascimento,
            }}
            edit={updateRecordProps}
          />
          <EnderecoEContatoRecord
            data={{ endereco, contato }}
            edit={updateRecordProps}
          />
          <ContatoDeEmergenciaRecord
            data={{
              contatoDeEmergencia,
            }}
            edit={updateRecordProps}
          />
          <pj.DependentesRecord
            data={{
              dependentes,
            }}
            edit={updateRecordProps}
            organizationId={organizationId}
            contractId={contractId}
            dependentDocuments={dependentDocuments}
            refetchDocuments={refetchDocuments}
          />
        </RecordGroup>
      </Box>
      <Box ref={refs?.contractDetailsRef} data-testid="contract-details">
        <RecordGroup title="Dados Contratuais">
          <pj.SituacaoRecord status={status} contrato={contrato} />
          <pj.PrestadorDeServicoRecord
            companyId={companyId}
            data={{
              organizationId,
              workerId,
              emailCorp,
              prestador,
            }}
            edit={{
              ...updateRecordProps,
              onSave: (data, onSuccess, onError) => {
                const {
                  update: { organizationId: _, workerId, ...changes },
                } = data;
                updateRecordProps.onSave(
                  { update: changes, workerId },
                  onSuccess,
                  onError,
                );
              },
            }}
          />
          <pj.DuracaoDoContratoRecord
            data={{ contrato }}
            edit={updateRecordProps}
          />
          <EstruturaOrganizacionalRecord
            data={{
              orgStructure,
            }}
            parameters={{
              organizationId,
              contractId,
            }}
            edit={changeManagerRecordProps}
          />
          <pj.ContratadaRecord data={{ empresa }} edit={updateRecordProps} />
          <pj.EnderecoEmpresaRecord
            data={{ empresa }}
            edit={updateRecordProps}
          />
          <pj.LocalDePrestacaoDeServico
            data={{
              organizationId,
              companyId,
              legalEntityId,
              workerId,
            }}
            edit={internalTransferRecordProps}
          />
        </RecordGroup>
      </Box>
      {!customFieldsQuery.isLoading && customFieldsQuery.data?.length > 0 && (
        <Box
          ref={refs?.customFieldsDetailsRef}
          data-testid="custom-fields-details"
        >
          <CustomFieldsRecord
            data={{ customFields }}
            edit={{
              ...updateRecordProps,
              onSave: (data, onSuccess, onError) => {
                const {
                  update: { customFields },
                } = data;
                updateRecordProps.onSave(
                  { update: {}, customFields },
                  onSuccess,
                  onError,
                );
              },
            }}
            fieldDefs={customFieldsQuery.data}
          />
        </Box>
      )}
      <Box ref={refs?.paymentDetailsRef} data-testid="payment-details">
        <RecordGroup title="Pagamento">
          <pj.PagamentoRecord data={{ pagamento }} edit={updateRecordProps} />
          <PagamentoRecord data={{ pagamento }} edit={updateRecordProps} />
        </RecordGroup>
      </Box>
      {UpdateDialog}
      {HistoryDrawer}
    </Box>
  );
}
