import {
  accrualPeriodsResourceType,
  vacationsScheduleResourceType,
  vacationsScheduleStatuses,
} from '@octopus/vacations-types';

import { vacationsScheduleStatusesMap } from '../utils/constants';

// Fields available in the context of the schedule
// and the accrual period
// Can you check fields availables in this file:
// packages/services/vacations/vacations-types/src/schedule.ts
export const VacationsContexts: {
  [key: string]: {
    timingFields: Record<string, string>;
    conditionFields: Record<
      string,
      {
        field: string;
        label: string;
        options?: Array<{ value: string; label: string }>;
      }
    >;
  };
} = {
  [vacationsScheduleResourceType]: {
    timingFields: {
      approvalDeadline: 'Data limite de aprovação',
      startDate: 'Data de início',
      endDate: 'Data de término',
    },
    conditionFields: {
      status: {
        field: 'status',
        label: 'Status',
        options: Object.keys(vacationsScheduleStatuses).map(
          (status: string) => ({
            label: vacationsScheduleStatusesMap[status],
            value: status,
          }),
        ),
      },
      daysUntilStart: {
        field: 'daysUntilStart',
        label: 'Dias até o início',
      }
    },
  },
  [accrualPeriodsResourceType]: {
    timingFields: {
      limitDateToStartVacations: 'Data limite para início de férias',
    },
    conditionFields: {
      daysUntilLimitDate: {
        field: 'daysUntilLimitDate',
        label: 'Dias até a data limite',
      },
    },
  },
};

export const NotificationRecipients: {
  [key: string]: {
    label: string;
    value: string;
    templates: {
      [key: string]: string[];
    };
  };
} = {
  employee: {
    label: 'Funcionário',
    value: 'employee',
    templates: {
      [vacationsScheduleResourceType]: [
        'vacationsApprovedWorker',
        'vacationsRejectedWorker',
        'vacationsCanceledWorker',
        'vacationNotice',
      ],
      [accrualPeriodsResourceType]: ['accrualPeriodCloseToLimitDateWorker'],
    },
  },
  'membership:owner': {
    label: 'Proprietário',
    value: 'membership:owner',
    templates: {
      [vacationsScheduleResourceType]: [
        'vacationsApprovalDeadline',
        'newVacationsAdmin',
      ],
      [accrualPeriodsResourceType]: ['accrualPeriodCloseToLimitDateAdmin'],
    },
  },
  'membership:tako:support': {
    label: 'Suporte Tako',
    value: 'membership:tako:support',
    templates: {
      [vacationsScheduleResourceType]: ['vacationsApprovedAdmin'],
    },
  },
  unregistered: {
    label: 'Email',
    value: 'unregistered',
    templates: {
      [vacationsScheduleResourceType]: ['simpleAlertUnregistered'],
      [accrualPeriodsResourceType]: ['simpleAlertUnregistered'],
    },
  },
};
