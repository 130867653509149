import { useQuery } from '@tanstack/react-query';

import { OrganizationSummary, fetchGetOrganizationEntry } from '@octopus/api';

import { MembershipContext } from '../types';

import { appContextQueryConfig } from './constants';

export const useOrganizations = ({
  memberships,
  currentMembership,
}: {
  memberships: MembershipContext[];
  currentMembership: MembershipContext;
}) => {
  const query = useQuery({
    queryKey: ['organizations', memberships?.length],
    queryFn: () =>
      Promise.all(
        memberships.map((m) =>
          fetchGetOrganizationEntry({
            pathParams: {
              organizationId: m.organizationId,
            },
          }).catch(() => {
            return;
          }),
        ),
      ),
    enabled: memberships?.length > 0,
    ...appContextQueryConfig,
  });

  const organizationsMap = query?.data
    ? query?.data?.reduce(
        (acc, org) => {
          if (org) {
            acc[org.organizationId] = org;
          }
          return acc;
        },
        {} as Record<string, OrganizationSummary>,
      )
    : {};

  return {
    ...query,
    data: query?.data,
    organizationsMap,
    currentOrganization:
      organizationsMap?.[currentMembership?.organizationId ?? ''],
    refetch: query.refetch,
  };
};
