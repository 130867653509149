import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { ContractSummary, fetchContractsPerson } from '@octopus/api';

import { ContractContext, MembershipContext, UserContext } from '../types';

import { appContextQueryConfig } from './constants';

export const useUserContracts = (
  membership: MembershipContext,
  user: UserContext,
) => {
  const [currentContract, setCurrentContract] = useState<
    ContractContext | undefined
  >();

  const { data: result, isLoading } = useQuery({
    queryKey: ['contracts', membership?.organizationId, user?.personId],
    queryFn: () =>
      fetchContractsPerson({
        pathParams: {
          organizationId: membership?.organizationId,
          personId: user?.personId,
        },
      }),
    enabled: !!membership?.organizationId && !!user?.personId,
    ...appContextQueryConfig,
  });

  useEffect(() => {
    if (result?.data) {
      const activeContracts: ContractSummary[] = result.data.filter(
        (contract) => contract.status === 'active',
      );

      if (activeContracts.length > 0) {
        setCurrentContract(setContract(activeContracts[0]));
      } else if (result.data.length > 0) {
        setCurrentContract(setContract(result.data[0]));
      } else {
        setCurrentContract(undefined);
      }
    }
  }, [result?.data, membership?.organizationId, user?.personId]);

  const allContracts = result?.data?.map(setContract) || [];

  return { setCurrentContract, currentContract, allContracts, isLoading };
};

const setContract = (contract: ContractSummary): ContractContext => {
  return {
    contractId: contract.contractId,
    contractType: contract.contractType,
    name: contract.name,
    departmentId: contract.departmentId,
    jobTitleId: contract.title,
    isManager: contract.reports?.length > 0,
    workerCategory: contract.workerCategory,
  };
};
