import { useCallback, useMemo, useState } from 'react';
import { DropzoneInputProps, useDropzone } from 'react-dropzone';

import { PDFDocument } from 'pdf-lib';

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Alert, Button, Typography } from '@mui/material';
import { Box, useMediaQuery, useTheme } from '@mui/system';

import { OctopusLoading } from '@octopus/ui/design-system';

import { getDefaultFileUploadDescription } from './utils';

export function UploadComponent({
  setFile,
  disabled,
  disabledMessage,
  message,
  description,
  acceptedFileTypes = {
    'image/*': ['.jpeg', '.png', '.jpg'],
    'application/pdf': ['.pdf'],
  },
  isLoading = false,
  maxFileSize = 5000000,
  loadingMessage = 'Carregando...',
}: {
  setFile: (file: File) => void;
  message?: React.ReactNode;
  disabled?: boolean;
  disabledMessage?: string;
  description?: string;
  acceptedFileTypes: Record<string, string[]>;
  maxFileSize?: number;
  isLoading?: boolean;
  loadingMessage?: string;
}) {
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (disabled && disabledMessage) {
        setError(disabledMessage);
        return;
      }

      const selectedFile = acceptedFiles[0];
      if (selectedFile.size > maxFileSize) {
        setError(`O arquivo deve ter no máximo ${maxFileSize / 1000000}MB`);
        return;
      }

      if (selectedFile.type === 'application/pdf') {
        try {
          const arrayBuffer = await selectedFile.arrayBuffer();
          await PDFDocument.load(arrayBuffer);
        } catch (e) {
          if (e.message.match(/is encrypted/i)) {
            console.error(
              'Erro ao carregar o PDF: Documento não pode conter senha. ',
              e,
            );
            setError('O arquivo não pode conter senha');
            return;
          } else {
            console.warn('Erro ao carregar o PDF. ', e);
          }
        }
      }

      setFile(acceptedFiles[0]);
    },
    [disabled, disabledMessage, setFile, maxFileSize],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
  });

  const [error, setError] = useState(undefined as string);

  const bgColorDragging = '#EDEDED';
  const bgColorNotDragging = '#FFF';

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const bgColor = isSmall
    ? 'background.primary'
    : isDragActive
      ? bgColorDragging
      : bgColorNotDragging;

  return (
    <Box
      {...getRootProps()}
      boxSizing="border-box"
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: (theme) => theme.spacing(1),
        borderRadius: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: 3,
        border: `1px dashed ${theme.palette.strokes.light}`,
        bgcolor: bgColor,
      })}
    >
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: (theme) => theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Box sx={{ maxWidth: '80px' }}>
            <OctopusLoading />
          </Box>
          <Typography variant="h4">{loadingMessage}</Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', bgcolor: bgColor }}>
            <FileUploadOutlinedIcon
              sx={(theme) => ({
                width: '24px',
                height: '24px',
                [theme.breakpoints.up('md')]: {
                  color: theme.palette.text.secondary,
                },
                [theme.breakpoints.down('md')]: {
                  color: theme.palette.primary.main,
                },
              })}
            />
          </Box>
          <DescriptionWeb
            bgColor={bgColor}
            description={description}
            disabled={disabled}
            disabledMessage={disabledMessage}
            maxFileSize={maxFileSize}
            getInputProps={getInputProps}
            onFileSelect={setFile}
            acceptedFileTypes={acceptedFileTypes}
            message={message}
          />
          <DescriptionMobile
            bgColor={bgColor}
            description={description}
            disabled={disabled}
            disabledMessage={disabledMessage}
            maxFileSize={maxFileSize}
            onFileSelect={setFile}
            acceptedFileTypes={acceptedFileTypes}
            message={message}
          />
        </>
      )}
      {error && (
        <Alert
          severity="error"
          sx={{
            width: '100%',
            mt: 2,
            mx: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxSizing: 'border-box',
          }}
        >
          <Typography
            fontSize="12px"
            lineHeight="16px"
            color="error"
            sx={{ width: '100%' }}
          >
            {error}
          </Typography>
        </Alert>
      )}
    </Box>
  );
}

type DropzoneDescriptionProps = {
  description: string;
  bgColor: string;
  disabled: boolean;
  disabledMessage: string;
  onFileSelect: (file?: File) => void;
  maxFileSize: number;
  acceptedFileTypes: Record<string, string[]>;
  getInputProps?: <T extends DropzoneInputProps>(props?: T) => T;
  message?: React.ReactNode;
};

function DescriptionWeb({
  bgColor,
  description,
  disabled,
  maxFileSize,
  disabledMessage,
  getInputProps,
  message,
}: DropzoneDescriptionProps) {
  const resolvedMessage = useMemo(() => {
    if (!message) {
      return (
        <Typography
          variant="button"
          sx={{
            whiteSpace: 'pre-line',
            bgcolor: bgColor,
            textAlign: 'center',
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          Para adicionar um documento{' '}
          <Typography
            variant="button"
            sx={{
              color: (theme) => theme.palette.primary.main,
              cursor: 'pointer',
              fontWeight: 'bold',
            }}
          >
            clique aqui{' '}
          </Typography>
          ou arraste-o para cá.{'\n'}
          {description || getDefaultFileUploadDescription(maxFileSize)}
        </Typography>
      );
    }

    return message;
  }, [message, bgColor, description, maxFileSize]);

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        bgcolor: bgColor,
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      })}
    >
      <input type="file" {...getInputProps()} disabled={disabled} />
      {disabled && (
        <Typography
          variant="button"
          sx={{
            whiteSpace: 'pre-line',
            bgcolor: bgColor,
            textAlign: 'center',
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          {disabledMessage}
        </Typography>
      )}
      {!disabled && resolvedMessage}
    </Box>
  );
}

function DescriptionMobile({
  bgColor,
  description,
  disabled,
  maxFileSize,
  disabledMessage,
  message,
  onFileSelect,
  acceptedFileTypes,
}: DropzoneDescriptionProps) {
  const acceptedFileExtensions = useMemo(
    () =>
      Object.entries(acceptedFileTypes)
        .map(([_, exts]) => exts.join(','))
        .join(','),
    [acceptedFileTypes],
  );

  const resolvedMessage = useMemo(() => {
    if (!message) {
      return (
        <>
          <Button variant="text" component="label">
            <label htmlFor="fileInput">
              Toque aqui para tirar uma foto ou enviar o arquivo do seu
              documento
            </label>
            <input
              disabled={disabled}
              type="file"
              hidden={true}
              accept={acceptedFileExtensions}
              id="fileInput"
              onChange={(event) => {
                onFileSelect(event.target.files[0]);
              }}
            />
          </Button>
          <Typography
            variant="caption"
            sx={{
              bgcolor: bgColor,
              textAlign: 'center',
              whiteSpace: 'pre-line',
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {description || getDefaultFileUploadDescription(maxFileSize)}
          </Typography>
        </>
      );
    }

    return message;
  }, [
    message,
    disabled,
    acceptedFileExtensions,
    bgColor,
    description,
    maxFileSize,
    onFileSelect,
  ]);

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        bgcolor: bgColor,
        width: '100%',
        maxWidth: '100%',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      })}
    >
      {disabled && (
        <Typography
          variant="button"
          sx={{
            whiteSpace: 'pre-line',
            bgcolor: bgColor,
            textAlign: 'center',
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          {disabledMessage}
        </Typography>
      )}{' '}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bgcolor: bgColor,
          justifyContent: 'center',
        }}
      >
        {!disabled && resolvedMessage}
      </Box>
    </Box>
  );
}
