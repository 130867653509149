import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Avatar, Box, Typography } from '@mui/material';

import {
  ChangeMembership,
  MembershipContext,
  MembershipOption,
} from '../../../modules/types';
import { membershipEquals } from '../../../utils';

type MembershipPickerProps = {
  membership: MembershipContext;
  membershipOptions: MembershipOption[];
  setMembershipContext: ChangeMembership;
};

export function MembershipPicker({
  membership,
  membershipOptions,
  setMembershipContext,
}: MembershipPickerProps) {
  if (membershipOptions.length === 0) {
    return <Typography>Nenhuma empresa encontrada</Typography>;
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      px={2}
      data-testid="membership-picker-menu"
    >
      {membershipOptions.map((option) => (
        <MembershipPickerItem
          key={option.organizationId}
          option={option}
          selected={membershipEquals(membership, option)}
          selectOption={() => setMembershipContext(option)}
        />
      ))}
    </Box>
  );
}

type MembershipPickerItemProps = {
  option: MembershipOption;
  selected: boolean;
  selectOption: () => void;
};

function MembershipPickerItem({
  option,
  selected,
  selectOption,
}: MembershipPickerItemProps) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={1}
      onClick={selectOption}
      sx={{
        cursor: 'pointer',
      }}
      data-testid={`membership-picker-item-${option.organizationId}`}
    >
      <Box display="flex" gap={2} width="calc(100% - 32px)">
        <OrganizationAvatar name={option.label} logo={option.logo} />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          maxWidth="calc(100% - 90px)"
        >
          <Typography variant="body1" fontWeight="700" noWrap>
            {option.label}
          </Typography>
        </Box>
      </Box>
      {selected && (
        <Box p={1.5}>
          <CheckOutlinedIcon
            color="primary"
            sx={{
              height: '16px',
              width: '16px',
            }}
            data-testid="membership-picker-menu-item-checked"
          />
        </Box>
      )}
    </Box>
  );
}

function OrganizationAvatar({ name, logo }: { name: string; logo?: string }) {
  if (logo) {
    return (
      <Box width="74px" height="74px" borderRadius={1} overflow="hidden">
        <img src={logo} width="74px" height="74px" alt={name.charAt(0)} />
      </Box>
    );
  }
  return (
    <Avatar
      sx={{
        width: '56px',
        height: '56px',
        p: 1,
        border: '1px solid rgba(237, 237, 237, 1)',
      }}
      variant="rounded"
    >
      <Typography
        variant="caption"
        sx={{ fontWeight: 'bold', fontSize: '18px' }}
      >
        {name.charAt(0)}
      </Typography>
    </Avatar>
  );
}
