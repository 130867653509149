import { useContext } from 'react';

import { AppContext } from '../../app/context';
import { CostCenterContext } from '../types';

export function useCostCenter(
  costCenterId: string | undefined,
): CostCenterContext | undefined {
  const { appContext } = useContext(AppContext);
  if (!costCenterId) {
    return undefined;
  }
  return appContext?.company?.costCenters?.find((cc) => cc.id === costCenterId);
}
