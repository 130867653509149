import { ReportWriterPayload, WorkbookWriter } from './types';

export function writeReportContent({
  writer,
  header,
  details,
  content,
  startRow,
  startColumn,
}: Omit<ReportWriterPayload, 'fileExtension'> & {
  writer: WorkbookWriter;
  startRow?: number;
  startColumn?: number;
}): WorkbookWriter {
  content.forEach((sheetContent) => {
    const sheet = writer.addWorksheet(sheetContent.sheetName, {
      startRow,
      startColumn,
    });

    if (header) {
      sheet.addHeaderSection(header);
    }

    if (details) {
      sheet.addDetailsSection(details);
    }

    if ('table' in sheetContent) {
      sheet.addTableSection(sheetContent.table);
    } else {
      sheet.addValuesSection(sheetContent.values);
    }
  });

  return writer;
}
