import { Box } from '@mui/system';

import { WorkerCategory, workerCategoryLabels } from '@octopus/contract-types';
import { Tag } from '@octopus/ui/design-system';

const configByStatus: Partial<
  Record<WorkerCategory, { color: 'info' | 'default' }>
> = {
  'clt:geral': {
    color: 'info',
  },
};

export default function WorkerCategoryTag({
  workerCategory,
}: {
  workerCategory: WorkerCategory;
}) {
  const color =
    configByStatus[workerCategory ?? 'clt:geral']?.color ?? 'default';
  return (
    <Tag color={color}>
      <Box component="span" whiteSpace={'nowrap'}>
        {workerCategoryLabels[workerCategory] ?? 'Colaborador'}
      </Box>
    </Tag>
  );
}
