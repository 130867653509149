import { useState } from 'react';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';

export function PasteCPFListModal({
  onSubmit,
  close,
}: {
  onSubmit: (cpfList: string[]) => void;
  close: () => void;
}) {
  const [cpfInput, setCpfInput] = useState('');
  const [error, setError] = useState(false);
  const handleSubmit = () => {
    const cpfList = cpfInput
      .split(/[\n,]+/)
      .map((cpf) => cpf.trim())
      .filter((cpf) => cpf !== '');

    onSubmit(cpfList);
    close();
  };

  return (
    <Box width={'600px'}>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'flex-start',
            m: 0,
            width: '100%',
          }}
        >
          <Typography variant="h5" fontSize="24px" fontWeight={700}>
            Adicionar profissionais autônomos
          </Typography>

          <TextField
            multiline
            rows={8}
            fullWidth
            placeholder="Cole aqui a lista de CPFs, separados por vírgula ou por linha. Exemplo: 123.456.789-00,123.456.789-01,123.456.789-02"
            value={cpfInput}
            error={error}
            helperText={error ? 'Insira uma lista de CPFs válida' : ''}
            onChange={(e) => setCpfInput(e.target.value)}
            sx={{ mt: 2 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.primary',
            width: '140px',
            height: '40px',
            px: 4,
            ':hover': {
              backgroundColor: 'strokes.light',
            },
          }}
          size="large"
          onClick={close}
        >
          Cancelar
        </Button>
        <Button
          color="primaryAlt"
          variant="contained"
          size="large"
          sx={{
            width: '140px',
            height: '40px',
          }}
          onClick={() => {
            if (cpfInput.trim() === '') {
              setError(true);
            } else {
              handleSubmit();
            }
          }}
        >
          Aplicar
        </Button>
      </DialogActions>
    </Box>
  );
}
