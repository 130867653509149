import { StatusConfig } from './statusIndex';

export type PayslipStatus = keyof typeof payslipStatuses;

export const payslipStatuses = {
  approved: 'approved',
  scheduled: 'scheduled',
  sent: 'sent',
  archived: 'archived',
} as const;

export const payslipStatusIndex: {
  [key in PayslipStatus]: number;
} = {
  approved: 10,
  scheduled: 100,
  sent: 9999,
  archived: 10000,
};

export const payslipStatusConfig: Record<PayslipStatus, StatusConfig> = {
  archived: {
    index: payslipStatusIndex.archived,
  },
  approved: {
    index: payslipStatusIndex.approved,
  },
  scheduled: {
    index: payslipStatusIndex.scheduled,
  },
  sent: {
    index: payslipStatusIndex.sent,
  },
};
