import { useMemo } from 'react';

import { Typography } from '@mui/material';

import TimesheetFileWarningIcon from '../../../../../../../../assets/fileWarning.svg';
import { TimesheetDownloadTemplate } from '../../TimesheetDownloadTemplate';
import {
  TimesheetEntry,
  TimesheetFileDefinition,
  TimesheetStepWarning,
} from '../types';
import { getErrorsMappingByCode } from '../utils';

import { TimesheetIssues } from './TimesheetIssues';
import { TimesheetValidationActions } from './TimesheetValidationActions';
import { TimesheetValidationContainer } from './TimesheetValidationContainer';

export function TimesheetValidationWarning({
  onConfirm,
  onCancel,
  entries,
  warnings,
  fileDefinition,
}: {
  warnings: TimesheetStepWarning[];
  entries: TimesheetEntry[];
  onCancel: () => void;
  onConfirm: () => void;
  fileDefinition: TimesheetFileDefinition;
}) {
  const entriesCount = useMemo(() => entries.length, [entries]);
  const warningsCount = useMemo(() => warnings.length, [warnings]);
  const warningsByCode = useMemo(
    () => getErrorsMappingByCode(warnings),
    [warnings],
  );

  return (
    <TimesheetValidationContainer sx={{ gap: (theme) => theme.spacing(1.5) }}>
      <TimesheetValidationActions
        onCancel={onCancel}
        onConfirm={onConfirm}
        cancelButtonText="Cancelar"
        confirmButtonText="Inserir válidos"
        showConfirmButton={!!entriesCount}
        icon={
          <img
            src={TimesheetFileWarningIcon}
            height={88}
            width={88}
            alt="Arquivo com lançamentos válidos e inválidos"
          />
        }
      >
        <Typography variant="body1" align="center">
          {entriesCount > 1 ? (
            <>
              Foram identificados <b>{entriesCount} lançamentos válidos</b>
            </>
          ) : (
            <>
              Foi identificado <b>{entriesCount} lançamento válido</b>
            </>
          )}{' '}
          e{' '}
          <b>
            {warningsCount > 1
              ? `${warningsCount} lançamentos com os erros abaixo`
              : `${warningsCount} lançamento com o erro abaixo`}
          </b>
          . Deseja inserir somente{' '}
          {entriesCount > 1 ? 'os lançamentos válidos' : 'o lançamento válido'}{' '}
          por agora?
        </Typography>
        <Typography
          variant="caption"
          align="center"
          sx={{ mt: (theme) => theme.spacing(2), color: 'text.secondary' }}
        >
          Você poderá importar um arquivo com as correções necessárias
          futuramente.
        </Typography>
      </TimesheetValidationActions>
      <TimesheetIssues errors={Object.values(warningsByCode)} type="warning" />
      <TimesheetDownloadTemplate fileDefinition={fileDefinition} />
    </TimesheetValidationContainer>
  );
}
