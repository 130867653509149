import { Alert, Box, Typography } from '@mui/material';

import { getRUMInfo } from '../../utils/getRUMInfo';

export type ErrorAlertProps = {
  message: string;
  showSessionId?: boolean;
};

export function ErrorAlert({ message, showSessionId = true }: ErrorAlertProps) {
  const rumInfo = getRUMInfo();
  return (
    <Alert severity="error">
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography
          variant="body2"
          fontWeight="400"
          sx={{ whiteSpace: 'pre-line' }}
          component="span"
        >
          {message}
        </Typography>
        {showSessionId && rumInfo && rumInfo.sessionId && (
          <Typography variant="body2" fontWeight={700}>
            SessionId: {rumInfo.sessionId}
          </Typography>
        )}
      </Box>
    </Alert>
  );
}
