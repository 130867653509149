import { useState } from 'react';

import {
  List,
  ListItemButton,
  Popover,
  PopoverProps,
  Typography,
} from '@mui/material';

export function usePopoverMenu<SelectionValue = string>({
  anchorOrigin,
  anchorEl,
  options,
  onSelect,
}: {
  anchorOrigin: PopoverProps['anchorOrigin'];
  anchorEl: PopoverProps['anchorEl'];
  options: { label: string; value: SelectionValue }[];
  onSelect: ({
    label,
    value,
  }: {
    label: string;
    value: SelectionValue;
  }) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen((prev) => !prev);
  const close = () => setIsOpen(false);

  const MenuComponent = (
    <PopoverMenu
      anchorOrigin={anchorOrigin}
      anchorEl={anchorEl}
      options={options}
      onSelect={onSelect}
      close={close}
      isOpen={isOpen}
    />
  );

  return { isOpen, toggleOpen, close, MenuComponent };
}

function PopoverMenu<SelectionValue = string>({
  anchorOrigin,
  anchorEl,
  options,
  onSelect,
  close,
  isOpen,
}: {
  anchorEl: PopoverProps['anchorEl'];
  anchorOrigin: PopoverProps['anchorOrigin'];
  options: { label: string; value: SelectionValue }[];
  onSelect: ({
    label,
    value,
  }: {
    label: string;
    value: SelectionValue;
  }) => void;
  close: () => void;
  isOpen: boolean;
}) {
  return (
    <Popover
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={close}
      anchorOrigin={anchorOrigin}
    >
      <List>
        {options.map(({ label, value }) => (
          <ListItemButton
            key={label}
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
              onSelect({ label, value });
            }}
          >
            <Typography variant="body2" color="textPrimary">
              {label}
            </Typography>
          </ListItemButton>
        ))}
      </List>
    </Popover>
  );
}
