import { useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import {
  TimesheetStepError,
  TimesheetStepErrorCode,
  TimesheetStepWarning,
} from '../types';

const BULLET_POINT = '• ';

export function TimesheetIssueAffected({
  detail,
  error,
}: {
  error: {
    code: TimesheetStepErrorCode;
    lines: (TimesheetStepError | TimesheetStepWarning)[];
  };
  detail: {
    title: { singular: string; plural: string };
    description?: string;
    getErrorTargets?: (
      errors: (TimesheetStepError | TimesheetStepWarning)[],
    ) => string[];
  };
}) {
  const targets = useMemo(() => {
    return detail?.getErrorTargets?.(error.lines) ?? [];
  }, [detail, error.lines]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {detail.description && (
        <Typography
          variant="caption"
          sx={{
            paddingBottom: (theme) => targets.length && theme.spacing(0.5),
          }}
        >
          {detail.description}
        </Typography>
      )}
      {targets.map((target, index) => (
        <Typography key={`${target}-${index}`} variant="caption">
          {BULLET_POINT}
          {target}
        </Typography>
      ))}
    </Box>
  );
}
