import { useRef } from 'react';

import { GetDetailRowDataParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component

import { Box, Button, Container, Divider, Typography } from '@mui/material';

import {
  AccountingChartsOfAccounts,
  fetchPutChartsOfAccounts,
  useGetChartsOfAccounts,
} from '@octopus/api';

import { useAutoSizeTable } from '../../payrolls/[period]/[type]/inputs/useAutoSizeTable';

type RowData = {
  id: string;
  name: string;
  accounts: {
    id: string;
    name: string;
  }[];
};

function fromApiDataToRowData(data: AccountingChartsOfAccounts): RowData[] {
  const makeEmptyAccounts = () =>
    Array.from({ length: 1000 }, () => ({
      id: '',
      name: '',
    }));

  const makeEmptyRow = () => ({
    id: '',
    name: '',
    accounts: makeEmptyAccounts(),
  });

  const rows: RowData[] = [];

  Object.values(data.charts).forEach((chart) => {
    rows.push({
      id: chart.id,
      name: chart.name,
      accounts: [
        ...Object.values(chart.accounts).map((account) => ({
          id: account.id,
          name: account.name,
        })),
        ...makeEmptyAccounts(),
      ],
    });
  });

  Array.from({ length: 2 }).forEach(() => {
    rows.push(makeEmptyRow());
  });

  return rows;
}

function fromRowDataToApiData(rows: RowData[]): AccountingChartsOfAccounts {
  const charts: AccountingChartsOfAccounts['charts'] = {};

  rows.forEach((row) => {
    if (!row.id) {
      return;
    }

    charts[row.id] = {
      id: row.id,
      name: row.name,
      accounts: row.accounts.reduce(
        (acc, account) => {
          if (account.id) {
            acc[account.id] = {
              id: account.id,
              name: account.name,
            };
          }
          return acc;
        },
        {} as AccountingChartsOfAccounts['charts']['id']['accounts'],
      ),
    };
  });

  return {
    charts,
  };
}

function Page({ organizationId }: { organizationId: string }) {
  const { data, isSuccess } = useGetChartsOfAccounts({
    pathParams: {
      organizationId,
    },
  });

  const rows = isSuccess ? fromApiDataToRowData(data) : undefined;

  const gridRef = useRef<AgGridReact>();
  useAutoSizeTable({ gridRef, show: true });

  return (
    <Container maxWidth="lg" sx={{ height: '100vh', pt: 4 }}>
      <Typography variant="h1">Planos de Contas</Typography>

      <Box py={3}>
        <Divider />
      </Box>

      <Box className="ag-theme-quartz" height="50%">
        {isSuccess ? (
          <AgGridReact
            masterDetail={true}
            autoSizeStrategy={{
              type: 'fitGridWidth',
            }}
            rowData={rows}
            columnDefs={[
              {
                headerName: 'Código do Plano',
                field: 'id',
                editable: true,
                cellRenderer: 'agGroupCellRenderer',
              },
              {
                headerName: 'Nome do Plano',
                field: 'name',
                editable: true,
              },
            ]}
            keepDetailRows
            detailCellRendererParams={{
              getDetailRowData: (params: GetDetailRowDataParams) =>
                params.successCallback(params.data.accounts),
              detailGridOptions: {
                autoSizeStrategy: {
                  type: 'fitGridWidth',
                },
                columnDefs: [
                  {
                    headerName: 'Código da Conta',
                    field: 'id',
                    editable: true,
                  },
                  {
                    headerName: 'Nome da Conta',
                    field: 'name',
                    editable: true,
                  },
                ],
              },
            }}
            ref={gridRef}
          />
        ) : null}
      </Box>

      <Box pt={2}>
        <Button
          onClick={() => {
            const rows: RowData[] = [];
            gridRef.current.api.forEachNode((node) => {
              rows.push(node.data);
            });

            fetchPutChartsOfAccounts({
              body: fromRowDataToApiData(rows),
              pathParams: {
                organizationId,
              },
            }).then(() => {
              window.location.reload();
            });
          }}
        >
          Salvar
        </Button>
      </Box>
    </Container>
  );
}

export default Page;
