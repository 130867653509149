import { groupBy } from 'lodash';

import { Mapper } from './mapper';
import { UFs } from './uf';

const entries: [number, string][] = [
  [1100015, "Alta Floresta D'Oeste"],
  [1100023, 'Ariquemes'],
  [1100031, 'Cabixi'],
  [1100049, 'Cacoal'],
  [1100056, 'Cerejeiras'],
  [1100064, 'Colorado do Oeste'],
  [1100072, 'Corumbiara'],
  [1100080, 'Costa Marques'],
  [1100098, "Espigão D'Oeste"],
  [1100106, 'Guajará-Mirim'],
  [1100114, 'Jaru'],
  [1100122, 'Ji-Paraná'],
  [1100130, "Machadinho D'Oeste"],
  [1100148, "Nova Brasilândia D'Oeste"],
  [1100155, 'Ouro Preto do Oeste'],
  [1100189, 'Pimenta Bueno'],
  [1100205, 'Porto Velho'],
  [1100254, 'Presidente Médici'],
  [1100262, 'Rio Crespo'],
  [1100288, 'Rolim de Moura'],
  [1100296, "Santa Luzia D'Oeste"],
  [1100304, 'Vilhena'],
  [1100320, 'São Miguel do Guaporé'],
  [1100338, 'Nova Mamoré'],
  [1100346, "Alvorada D'Oeste"],
  [1100379, 'Alto Alegre dos Parecis'],
  [1100403, 'Alto Paraíso'],
  [1100452, 'Buritis'],
  [1100502, 'Novo Horizonte do Oeste'],
  [1100601, 'Cacaulândia'],
  [1100700, 'Campo Novo de Rondônia'],
  [1100809, 'Candeias do Jamari'],
  [1100908, 'Castanheiras'],
  [1100924, 'Chupinguaia'],
  [1100940, 'Cujubim'],
  [1101005, 'Governador Jorge Teixeira'],
  [1101104, 'Itapuã do Oeste'],
  [1101203, 'Ministro Andreazza'],
  [1101302, 'Mirante da Serra'],
  [1101401, 'Monte Negro'],
  [1101435, 'Nova União'],
  [1101450, 'Parecis'],
  [1101468, 'Pimenteiras do Oeste'],
  [1101476, 'Primavera de Rondônia'],
  [1101484, "São Felipe D'Oeste"],
  [1101492, 'São Francisco do Guaporé'],
  [1101500, 'Seringueiras'],
  [1101559, 'Teixeirópolis'],
  [1101609, 'Theobroma'],
  [1101708, 'Urupá'],
  [1101757, 'Vale do Anari'],
  [1101807, 'Vale do Paraíso'],
  [1200013, 'Acrelândia'],
  [1200054, 'Assis Brasil'],
  [1200104, 'Brasiléia'],
  [1200138, 'Bujari'],
  [1200179, 'Capixaba'],
  [1200203, 'Cruzeiro do Sul'],
  [1200252, 'Epitaciolândia'],
  [1200302, 'Feijó'],
  [1200328, 'Jordão'],
  [1200336, 'Mâncio Lima'],
  [1200344, 'Manoel Urbano'],
  [1200351, 'Marechal Thaumaturgo'],
  [1200385, 'Plácido de Castro'],
  [1200393, 'Porto Walter'],
  [1200401, 'Rio Branco'],
  [1200427, 'Rodrigues Alves'],
  [1200435, 'Santa Rosa do Purus'],
  [1200450, 'Senador Guiomard'],
  [1200500, 'Sena Madureira'],
  [1200609, 'Tarauacá'],
  [1200708, 'Xapuri'],
  [1200807, 'Porto Acre'],
  [1300029, 'Alvarães'],
  [1300060, 'Amaturá'],
  [1300086, 'Anamã'],
  [1300102, 'Anori'],
  [1300144, 'Apuí'],
  [1300201, 'Atalaia do Norte'],
  [1300300, 'Autazes'],
  [1300409, 'Barcelos'],
  [1300508, 'Barreirinha'],
  [1300607, 'Benjamin Constant'],
  [1300631, 'Beruri'],
  [1300680, 'Boa Vista do Ramos'],
  [1300706, 'Boca do Acre'],
  [1300805, 'Borba'],
  [1300839, 'Caapiranga'],
  [1300904, 'Canutama'],
  [1301001, 'Carauari'],
  [1301100, 'Careiro'],
  [1301159, 'Careiro da Várzea'],
  [1301209, 'Coari'],
  [1301308, 'Codajás'],
  [1301407, 'Eirunepé'],
  [1301506, 'Envira'],
  [1301605, 'Fonte Boa'],
  [1301654, 'Guajará'],
  [1301704, 'Humaitá'],
  [1301803, 'Ipixuna'],
  [1301852, 'Iranduba'],
  [1301902, 'Itacoatiara'],
  [1301951, 'Itamarati'],
  [1302009, 'Itapiranga'],
  [1302108, 'Japurá'],
  [1302207, 'Juruá'],
  [1302306, 'Jutaí'],
  [1302405, 'Lábrea'],
  [1302504, 'Manacapuru'],
  [1302553, 'Manaquiri'],
  [1302603, 'Manaus'],
  [1302702, 'Manicoré'],
  [1302801, 'Maraã'],
  [1302900, 'Maués'],
  [1303007, 'Nhamundá'],
  [1303106, 'Nova Olinda do Norte'],
  [1303205, 'Novo Airão'],
  [1303304, 'Novo Aripuanã'],
  [1303403, 'Parintins'],
  [1303502, 'Pauini'],
  [1303536, 'Presidente Figueiredo'],
  [1303569, 'Rio Preto da Eva'],
  [1303601, 'Santa Isabel do Rio Negro'],
  [1303700, 'Santo Antônio do Içá'],
  [1303809, 'São Gabriel da Cachoeira'],
  [1303908, 'São Paulo de Olivença'],
  [1303957, 'São Sebastião do Uatumã'],
  [1304005, 'Silves'],
  [1304062, 'Tabatinga'],
  [1304104, 'Tapauá'],
  [1304203, 'Tefé'],
  [1304237, 'Tonantins'],
  [1304260, 'Uarini'],
  [1304302, 'Urucará'],
  [1304401, 'Urucurituba'],
  [1400027, 'Amajari'],
  [1400050, 'Alto Alegre'],
  [1400100, 'Boa Vista'],
  [1400159, 'Bonfim'],
  [1400175, 'Cantá'],
  [1400209, 'Caracaraí'],
  [1400233, 'Caroebe'],
  [1400282, 'Iracema'],
  [1400308, 'Mucajaí'],
  [1400407, 'Normandia'],
  [1400456, 'Pacaraima'],
  [1400472, 'Rorainópolis'],
  [1400506, 'São João da Baliza'],
  [1400605, 'São Luiz'],
  [1400704, 'Uiramutã'],
  [1500107, 'Abaetetuba'],
  [1500131, 'Abel Figueiredo'],
  [1500206, 'Acará'],
  [1500305, 'Afuá'],
  [1500347, 'Água Azul do Norte'],
  [1500404, 'Alenquer'],
  [1500503, 'Almeirim'],
  [1500602, 'Altamira'],
  [1500701, 'Anajás'],
  [1500800, 'Ananindeua'],
  [1500859, 'Anapu'],
  [1500909, 'Augusto Corrêa'],
  [1500958, 'Aurora do Pará'],
  [1501006, 'Aveiro'],
  [1501105, 'Bagre'],
  [1501204, 'Baião'],
  [1501253, 'Bannach'],
  [1501303, 'Barcarena'],
  [1501402, 'Belém'],
  [1501451, 'Belterra'],
  [1501501, 'Benevides'],
  [1501576, 'Bom Jesus do Tocantins'],
  [1501600, 'Bonito'],
  [1501709, 'Bragança'],
  [1501725, 'Brasil Novo'],
  [1501758, 'Brejo Grande do Araguaia'],
  [1501782, 'Breu Branco'],
  [1501808, 'Breves'],
  [1501907, 'Bujaru'],
  [1501956, 'Cachoeira do Piriá'],
  [1502004, 'Cachoeira do Arari'],
  [1502103, 'Cametá'],
  [1502152, 'Canaã dos Carajás'],
  [1502202, 'Capanema'],
  [1502301, 'Capitão Poço'],
  [1502400, 'Castanhal'],
  [1502509, 'Chaves'],
  [1502608, 'Colares'],
  [1502707, 'Conceição do Araguaia'],
  [1502756, 'Concórdia do Pará'],
  [1502764, 'Cumaru do Norte'],
  [1502772, 'Curionópolis'],
  [1502806, 'Curralinho'],
  [1502855, 'Curuá'],
  [1502905, 'Curuçá'],
  [1502939, 'Dom Eliseu'],
  [1502954, 'Eldorado do Carajás'],
  [1503002, 'Faro'],
  [1503044, 'Floresta do Araguaia'],
  [1503077, 'Garrafão do Norte'],
  [1503093, 'Goianésia do Pará'],
  [1503101, 'Gurupá'],
  [1503200, 'Igarapé-Açu'],
  [1503309, 'Igarapé-Miri'],
  [1503408, 'Inhangapi'],
  [1503457, 'Ipixuna do Pará'],
  [1503507, 'Irituia'],
  [1503606, 'Itaituba'],
  [1503705, 'Itupiranga'],
  [1503754, 'Jacareacanga'],
  [1503804, 'Jacundá'],
  [1503903, 'Juruti'],
  [1504000, 'Limoeiro do Ajuru'],
  [1504059, 'Mãe do Rio'],
  [1504109, 'Magalhães Barata'],
  [1504208, 'Marabá'],
  [1504307, 'Maracanã'],
  [1504406, 'Marapanim'],
  [1504422, 'Marituba'],
  [1504455, 'Medicilândia'],
  [1504505, 'Melgaço'],
  [1504604, 'Mocajuba'],
  [1504703, 'Moju'],
  [1504752, 'Mojuí dos Campos'],
  [1504802, 'Monte Alegre'],
  [1504901, 'Muaná'],
  [1504950, 'Nova Esperança do Piriá'],
  [1504976, 'Nova Ipixuna'],
  [1505007, 'Nova Timboteua'],
  [1505031, 'Novo Progresso'],
  [1505064, 'Novo Repartimento'],
  [1505106, 'Óbidos'],
  [1505205, 'Oeiras do Pará'],
  [1505304, 'Oriximiná'],
  [1505403, 'Ourém'],
  [1505437, 'Ourilândia do Norte'],
  [1505486, 'Pacajá'],
  [1505494, 'Palestina do Pará'],
  [1505502, 'Paragominas'],
  [1505536, 'Parauapebas'],
  [1505551, "Pau D'Arco"],
  [1505601, 'Peixe-Boi'],
  [1505635, 'Piçarra'],
  [1505650, 'Placas'],
  [1505700, 'Ponta de Pedras'],
  [1505809, 'Portel'],
  [1505908, 'Porto de Moz'],
  [1506005, 'Prainha'],
  [1506104, 'Primavera'],
  [1506112, 'Quatipuru'],
  [1506138, 'Redenção'],
  [1506161, 'Rio Maria'],
  [1506187, 'Rondon do Pará'],
  [1506195, 'Rurópolis'],
  [1506203, 'Salinópolis'],
  [1506302, 'Salvaterra'],
  [1506351, 'Santa Bárbara do Pará'],
  [1506401, 'Santa Cruz do Arari'],
  [1506500, 'Santa Izabel do Pará'],
  [1506559, 'Santa Luzia do Pará'],
  [1506583, 'Santa Maria das Barreiras'],
  [1506609, 'Santa Maria do Pará'],
  [1506708, 'Santana do Araguaia'],
  [1506807, 'Santarém'],
  [1506906, 'Santarém Novo'],
  [1507003, 'Santo Antônio do Tauá'],
  [1507102, 'São Caetano de Odivelas'],
  [1507151, 'São Domingos do Araguaia'],
  [1507201, 'São Domingos do Capim'],
  [1507300, 'São Félix do Xingu'],
  [1507409, 'São Francisco do Pará'],
  [1507458, 'São Geraldo do Araguaia'],
  [1507466, 'São João da Ponta'],
  [1507474, 'São João de Pirabas'],
  [1507508, 'São João do Araguaia'],
  [1507607, 'São Miguel do Guamá'],
  [1507706, 'São Sebastião da Boa Vista'],
  [1507755, 'Sapucaia'],
  [1507805, 'Senador José Porfírio'],
  [1507904, 'Soure'],
  [1507953, 'Tailândia'],
  [1507961, 'Terra Alta'],
  [1507979, 'Terra Santa'],
  [1508001, 'Tomé-Açu'],
  [1508035, 'Tracuateua'],
  [1508050, 'Trairão'],
  [1508084, 'Tucumã'],
  [1508100, 'Tucuruí'],
  [1508126, 'Ulianópolis'],
  [1508159, 'Uruará'],
  [1508209, 'Vigia'],
  [1508308, 'Viseu'],
  [1508357, 'Vitória do Xingu'],
  [1508407, 'Xinguara'],
  [1600055, 'Serra do Navio'],
  [1600105, 'Amapá'],
  [1600154, 'Pedra Branca do Amapari'],
  [1600204, 'Calçoene'],
  [1600212, 'Cutias'],
  [1600238, 'Ferreira Gomes'],
  [1600253, 'Itaubal'],
  [1600279, 'Laranjal do Jari'],
  [1600303, 'Macapá'],
  [1600402, 'Mazagão'],
  [1600501, 'Oiapoque'],
  [1600535, 'Porto Grande'],
  [1600550, 'Pracuúba'],
  [1600600, 'Santana'],
  [1600709, 'Tartarugalzinho'],
  [1600808, 'Vitória do Jari'],
  [1700251, 'Abreulândia'],
  [1700301, 'Aguiarnópolis'],
  [1700350, 'Aliança do Tocantins'],
  [1700400, 'Almas'],
  [1700707, 'Alvorada'],
  [1701002, 'Ananás'],
  [1701051, 'Angico'],
  [1701101, 'Aparecida do Rio Negro'],
  [1701309, 'Aragominas'],
  [1701903, 'Araguacema'],
  [1702000, 'Araguaçu'],
  [1702109, 'Araguaína'],
  [1702158, 'Araguanã'],
  [1702208, 'Araguatins'],
  [1702307, 'Arapoema'],
  [1702406, 'Arraias'],
  [1702554, 'Augustinópolis'],
  [1702703, 'Aurora do Tocantins'],
  [1702901, 'Axixá do Tocantins'],
  [1703008, 'Babaçulândia'],
  [1703057, 'Bandeirantes do Tocantins'],
  [1703073, 'Barra do Ouro'],
  [1703107, 'Barrolândia'],
  [1703206, 'Bernardo Sayão'],
  [1703305, 'Bom Jesus do Tocantins'],
  [1703602, 'Brasilândia do Tocantins'],
  [1703701, 'Brejinho de Nazaré'],
  [1703800, 'Buriti do Tocantins'],
  [1703826, 'Cachoeirinha'],
  [1703842, 'Campos Lindos'],
  [1703867, 'Cariri do Tocantins'],
  [1703883, 'Carmolândia'],
  [1703891, 'Carrasco Bonito'],
  [1703909, 'Caseara'],
  [1704105, 'Centenário'],
  [1704600, 'Chapada de Areia'],
  [1705102, 'Chapada da Natividade'],
  [1705508, 'Colinas do Tocantins'],
  [1705557, 'Combinado'],
  [1705607, 'Conceição do Tocantins'],
  [1706001, 'Couto Magalhães'],
  [1706100, 'Cristalândia'],
  [1706258, 'Crixás do Tocantins'],
  [1706506, 'Darcinópolis'],
  [1707009, 'Dianópolis'],
  [1707108, 'Divinópolis do Tocantins'],
  [1707207, 'Dois Irmãos do Tocantins'],
  [1707306, 'Dueré'],
  [1707405, 'Esperantina'],
  [1707553, 'Fátima'],
  [1707652, 'Figueirópolis'],
  [1707702, 'Filadélfia'],
  [1708205, 'Formoso do Araguaia'],
  [1708254, 'Tabocão'],
  [1708304, 'Goianorte'],
  [1709005, 'Goiatins'],
  [1709302, 'Guaraí'],
  [1709500, 'Gurupi'],
  [1709807, 'Ipueiras'],
  [1710508, 'Itacajá'],
  [1710706, 'Itaguatins'],
  [1710904, 'Itapiratins'],
  [1711100, 'Itaporã do Tocantins'],
  [1711506, 'Jaú do Tocantins'],
  [1711803, 'Juarina'],
  [1711902, 'Lagoa da Confusão'],
  [1711951, 'Lagoa do Tocantins'],
  [1712009, 'Lajeado'],
  [1712157, 'Lavandeira'],
  [1712405, 'Lizarda'],
  [1712454, 'Luzinópolis'],
  [1712504, 'Marianópolis do Tocantins'],
  [1712702, 'Mateiros'],
  [1712801, 'Maurilândia do Tocantins'],
  [1713205, 'Miracema do Tocantins'],
  [1713304, 'Miranorte'],
  [1713601, 'Monte do Carmo'],
  [1713700, 'Monte Santo do Tocantins'],
  [1713809, 'Palmeiras do Tocantins'],
  [1713957, 'Muricilândia'],
  [1714203, 'Natividade'],
  [1714302, 'Nazaré'],
  [1714880, 'Nova Olinda'],
  [1715002, 'Nova Rosalândia'],
  [1715101, 'Novo Acordo'],
  [1715150, 'Novo Alegre'],
  [1715259, 'Novo Jardim'],
  [1715507, 'Oliveira de Fátima'],
  [1715705, 'Palmeirante'],
  [1715754, 'Palmeirópolis'],
  [1716109, 'Paraíso do Tocantins'],
  [1716208, 'Paranã'],
  [1716307, "Pau D'Arco"],
  [1716505, 'Pedro Afonso'],
  [1716604, 'Peixe'],
  [1716653, 'Pequizeiro'],
  [1716703, 'Colméia'],
  [1717008, 'Pindorama do Tocantins'],
  [1717206, 'Piraquê'],
  [1717503, 'Pium'],
  [1717800, 'Ponte Alta do Bom Jesus'],
  [1717909, 'Ponte Alta do Tocantins'],
  [1718006, 'Porto Alegre do Tocantins'],
  [1718204, 'Porto Nacional'],
  [1718303, 'Praia Norte'],
  [1718402, 'Presidente Kennedy'],
  [1718451, 'Pugmil'],
  [1718501, 'Recursolândia'],
  [1718550, 'Riachinho'],
  [1718659, 'Rio da Conceição'],
  [1718709, 'Rio dos Bois'],
  [1718758, 'Rio Sono'],
  [1718808, 'Sampaio'],
  [1718840, 'Sandolândia'],
  [1718865, 'Santa Fé do Araguaia'],
  [1718881, 'Santa Maria do Tocantins'],
  [1718899, 'Santa Rita do Tocantins'],
  [1718907, 'Santa Rosa do Tocantins'],
  [1719004, 'Santa Tereza do Tocantins'],
  [1720002, 'Santa Terezinha do Tocantins'],
  [1720101, 'São Bento do Tocantins'],
  [1720150, 'São Félix do Tocantins'],
  [1720200, 'São Miguel do Tocantins'],
  [1720259, 'São Salvador do Tocantins'],
  [1720309, 'São Sebastião do Tocantins'],
  [1720499, 'São Valério'],
  [1720655, 'Silvanópolis'],
  [1720804, 'Sítio Novo do Tocantins'],
  [1720853, 'Sucupira'],
  [1720903, 'Taguatinga'],
  [1720937, 'Taipas do Tocantins'],
  [1720978, 'Talismã'],
  [1721000, 'Palmas'],
  [1721109, 'Tocantínia'],
  [1721208, 'Tocantinópolis'],
  [1721257, 'Tupirama'],
  [1721307, 'Tupiratins'],
  [1722081, 'Wanderlândia'],
  [1722107, 'Xambioá'],
  [2100055, 'Açailândia'],
  [2100105, 'Afonso Cunha'],
  [2100154, 'Água Doce do Maranhão'],
  [2100204, 'Alcântara'],
  [2100303, 'Aldeias Altas'],
  [2100402, 'Altamira do Maranhão'],
  [2100436, 'Alto Alegre do Maranhão'],
  [2100477, 'Alto Alegre do Pindaré'],
  [2100501, 'Alto Parnaíba'],
  [2100550, 'Amapá do Maranhão'],
  [2100600, 'Amarante do Maranhão'],
  [2100709, 'Anajatuba'],
  [2100808, 'Anapurus'],
  [2100832, 'Apicum-Açu'],
  [2100873, 'Araguanã'],
  [2100907, 'Araioses'],
  [2100956, 'Arame'],
  [2101004, 'Arari'],
  [2101103, 'Axixá'],
  [2101202, 'Bacabal'],
  [2101251, 'Bacabeira'],
  [2101301, 'Bacuri'],
  [2101350, 'Bacurituba'],
  [2101400, 'Balsas'],
  [2101509, 'Barão de Grajaú'],
  [2101608, 'Barra do Corda'],
  [2101707, 'Barreirinhas'],
  [2101731, 'Belágua'],
  [2101772, 'Bela Vista do Maranhão'],
  [2101806, 'Benedito Leite'],
  [2101905, 'Bequimão'],
  [2101939, 'Bernardo do Mearim'],
  [2101970, 'Boa Vista do Gurupi'],
  [2102002, 'Bom Jardim'],
  [2102036, 'Bom Jesus das Selvas'],
  [2102077, 'Bom Lugar'],
  [2102101, 'Brejo'],
  [2102150, 'Brejo de Areia'],
  [2102200, 'Buriti'],
  [2102309, 'Buriti Bravo'],
  [2102325, 'Buriticupu'],
  [2102358, 'Buritirana'],
  [2102374, 'Cachoeira Grande'],
  [2102408, 'Cajapió'],
  [2102507, 'Cajari'],
  [2102556, 'Campestre do Maranhão'],
  [2102606, 'Cândido Mendes'],
  [2102705, 'Cantanhede'],
  [2102754, 'Capinzal do Norte'],
  [2102804, 'Carolina'],
  [2102903, 'Carutapera'],
  [2103000, 'Caxias'],
  [2103109, 'Cedral'],
  [2103125, 'Central do Maranhão'],
  [2103158, 'Centro do Guilherme'],
  [2103174, 'Centro Novo do Maranhão'],
  [2103208, 'Chapadinha'],
  [2103257, 'Cidelândia'],
  [2103307, 'Codó'],
  [2103406, 'Coelho Neto'],
  [2103505, 'Colinas'],
  [2103554, 'Conceição do Lago-Açu'],
  [2103604, 'Coroatá'],
  [2103703, 'Cururupu'],
  [2103752, 'Davinópolis'],
  [2103802, 'Dom Pedro'],
  [2103901, 'Duque Bacelar'],
  [2104008, 'Esperantinópolis'],
  [2104057, 'Estreito'],
  [2104073, 'Feira Nova do Maranhão'],
  [2104081, 'Fernando Falcão'],
  [2104099, 'Formosa da Serra Negra'],
  [2104107, 'Fortaleza dos Nogueiras'],
  [2104206, 'Fortuna'],
  [2104305, 'Godofredo Viana'],
  [2104404, 'Gonçalves Dias'],
  [2104503, 'Governador Archer'],
  [2104552, 'Governador Edison Lobão'],
  [2104602, 'Governador Eugênio Barros'],
  [2104628, 'Governador Luiz Rocha'],
  [2104651, 'Governador Newton Bello'],
  [2104677, 'Governador Nunes Freire'],
  [2104701, 'Graça Aranha'],
  [2104800, 'Grajaú'],
  [2104909, 'Guimarães'],
  [2105005, 'Humberto de Campos'],
  [2105104, 'Icatu'],
  [2105153, 'Igarapé do Meio'],
  [2105203, 'Igarapé Grande'],
  [2105302, 'Imperatriz'],
  [2105351, 'Itaipava do Grajaú'],
  [2105401, 'Itapecuru Mirim'],
  [2105427, 'Itinga do Maranhão'],
  [2105450, 'Jatobá'],
  [2105476, 'Jenipapo dos Vieiras'],
  [2105500, 'João Lisboa'],
  [2105609, 'Joselândia'],
  [2105658, 'Junco do Maranhão'],
  [2105708, 'Lago da Pedra'],
  [2105807, 'Lago do Junco'],
  [2105906, 'Lago Verde'],
  [2105922, 'Lagoa do Mato'],
  [2105948, 'Lago dos Rodrigues'],
  [2105963, 'Lagoa Grande do Maranhão'],
  [2105989, 'Lajeado Novo'],
  [2106003, 'Lima Campos'],
  [2106102, 'Loreto'],
  [2106201, 'Luís Domingues'],
  [2106300, 'Magalhães de Almeida'],
  [2106326, 'Maracaçumé'],
  [2106359, 'Marajá do Sena'],
  [2106375, 'Maranhãozinho'],
  [2106409, 'Mata Roma'],
  [2106508, 'Matinha'],
  [2106607, 'Matões'],
  [2106631, 'Matões do Norte'],
  [2106672, 'Milagres do Maranhão'],
  [2106706, 'Mirador'],
  [2106755, 'Miranda do Norte'],
  [2106805, 'Mirinzal'],
  [2106904, 'Monção'],
  [2107001, 'Montes Altos'],
  [2107100, 'Morros'],
  [2107209, 'Nina Rodrigues'],
  [2107258, 'Nova Colinas'],
  [2107308, 'Nova Iorque'],
  [2107357, 'Nova Olinda do Maranhão'],
  [2107407, "Olho d'Água das Cunhãs"],
  [2107456, 'Olinda Nova do Maranhão'],
  [2107506, 'Paço do Lumiar'],
  [2107605, 'Palmeirândia'],
  [2107704, 'Paraibano'],
  [2107803, 'Parnarama'],
  [2107902, 'Passagem Franca'],
  [2108009, 'Pastos Bons'],
  [2108058, 'Paulino Neves'],
  [2108108, 'Paulo Ramos'],
  [2108207, 'Pedreiras'],
  [2108256, 'Pedro do Rosário'],
  [2108306, 'Penalva'],
  [2108405, 'Peri Mirim'],
  [2108454, 'Peritoró'],
  [2108504, 'Pindaré-Mirim'],
  [2108603, 'Pinheiro'],
  [2108702, 'Pio XII'],
  [2108801, 'Pirapemas'],
  [2108900, 'Poção de Pedras'],
  [2109007, 'Porto Franco'],
  [2109056, 'Porto Rico do Maranhão'],
  [2109106, 'Presidente Dutra'],
  [2109205, 'Presidente Juscelino'],
  [2109239, 'Presidente Médici'],
  [2109270, 'Presidente Sarney'],
  [2109304, 'Presidente Vargas'],
  [2109403, 'Primeira Cruz'],
  [2109452, 'Raposa'],
  [2109502, 'Riachão'],
  [2109551, 'Ribamar Fiquene'],
  [2109601, 'Rosário'],
  [2109700, 'Sambaíba'],
  [2109759, 'Santa Filomena do Maranhão'],
  [2109809, 'Santa Helena'],
  [2109908, 'Santa Inês'],
  [2110005, 'Santa Luzia'],
  [2110039, 'Santa Luzia do Paruá'],
  [2110104, 'Santa Quitéria do Maranhão'],
  [2110203, 'Santa Rita'],
  [2110237, 'Santana do Maranhão'],
  [2110278, 'Santo Amaro do Maranhão'],
  [2110302, 'Santo Antônio dos Lopes'],
  [2110401, 'São Benedito do Rio Preto'],
  [2110500, 'São Bento'],
  [2110609, 'São Bernardo'],
  [2110658, 'São Domingos do Azeitão'],
  [2110708, 'São Domingos do Maranhão'],
  [2110807, 'São Félix de Balsas'],
  [2110856, 'São Francisco do Brejão'],
  [2110906, 'São Francisco do Maranhão'],
  [2111003, 'São João Batista'],
  [2111029, 'São João do Carú'],
  [2111052, 'São João do Paraíso'],
  [2111078, 'São João do Soter'],
  [2111102, 'São João dos Patos'],
  [2111201, 'São José de Ribamar'],
  [2111250, 'São José dos Basílios'],
  [2111300, 'São Luís'],
  [2111409, 'São Luís Gonzaga do Maranhão'],
  [2111508, 'São Mateus do Maranhão'],
  [2111532, 'São Pedro da Água Branca'],
  [2111573, 'São Pedro dos Crentes'],
  [2111607, 'São Raimundo das Mangabeiras'],
  [2111631, 'São Raimundo do Doca Bezerra'],
  [2111672, 'São Roberto'],
  [2111706, 'São Vicente Ferrer'],
  [2111722, 'Satubinha'],
  [2111748, 'Senador Alexandre Costa'],
  [2111763, 'Senador La Rocque'],
  [2111789, 'Serrano do Maranhão'],
  [2111805, 'Sítio Novo'],
  [2111904, 'Sucupira do Norte'],
  [2111953, 'Sucupira do Riachão'],
  [2112001, 'Tasso Fragoso'],
  [2112100, 'Timbiras'],
  [2112209, 'Timon'],
  [2112233, 'Trizidela do Vale'],
  [2112274, 'Tufilândia'],
  [2112308, 'Tuntum'],
  [2112407, 'Turiaçu'],
  [2112456, 'Turilândia'],
  [2112506, 'Tutóia'],
  [2112605, 'Urbano Santos'],
  [2112704, 'Vargem Grande'],
  [2112803, 'Viana'],
  [2112852, 'Vila Nova dos Martírios'],
  [2112902, 'Vitória do Mearim'],
  [2113009, 'Vitorino Freire'],
  [2114007, 'Zé Doca'],
  [2200053, 'Acauã'],
  [2200103, 'Agricolândia'],
  [2200202, 'Água Branca'],
  [2200251, 'Alagoinha do Piauí'],
  [2200277, 'Alegrete do Piauí'],
  [2200301, 'Alto Longá'],
  [2200400, 'Altos'],
  [2200459, 'Alvorada do Gurguéia'],
  [2200509, 'Amarante'],
  [2200608, 'Angical do Piauí'],
  [2200707, 'Anísio de Abreu'],
  [2200806, 'Antônio Almeida'],
  [2200905, 'Aroazes'],
  [2200954, 'Aroeiras do Itaim'],
  [2201002, 'Arraial'],
  [2201051, 'Assunção do Piauí'],
  [2201101, 'Avelino Lopes'],
  [2201150, 'Baixa Grande do Ribeiro'],
  [2201176, "Barra D'Alcântara"],
  [2201200, 'Barras'],
  [2201309, 'Barreiras do Piauí'],
  [2201408, 'Barro Duro'],
  [2201507, 'Batalha'],
  [2201556, 'Bela Vista do Piauí'],
  [2201572, 'Belém do Piauí'],
  [2201606, 'Beneditinos'],
  [2201705, 'Bertolínia'],
  [2201739, 'Betânia do Piauí'],
  [2201770, 'Boa Hora'],
  [2201804, 'Bocaina'],
  [2201903, 'Bom Jesus'],
  [2201919, 'Bom Princípio do Piauí'],
  [2201929, 'Bonfim do Piauí'],
  [2201945, 'Boqueirão do Piauí'],
  [2201960, 'Brasileira'],
  [2201988, 'Brejo do Piauí'],
  [2202000, 'Buriti dos Lopes'],
  [2202026, 'Buriti dos Montes'],
  [2202059, 'Cabeceiras do Piauí'],
  [2202075, 'Cajazeiras do Piauí'],
  [2202083, 'Cajueiro da Praia'],
  [2202091, 'Caldeirão Grande do Piauí'],
  [2202109, 'Campinas do Piauí'],
  [2202117, 'Campo Alegre do Fidalgo'],
  [2202133, 'Campo Grande do Piauí'],
  [2202174, 'Campo Largo do Piauí'],
  [2202208, 'Campo Maior'],
  [2202251, 'Canavieira'],
  [2202307, 'Canto do Buriti'],
  [2202406, 'Capitão de Campos'],
  [2202455, 'Capitão Gervásio Oliveira'],
  [2202505, 'Caracol'],
  [2202539, 'Caraúbas do Piauí'],
  [2202554, 'Caridade do Piauí'],
  [2202604, 'Castelo do Piauí'],
  [2202653, 'Caxingó'],
  [2202703, 'Cocal'],
  [2202711, 'Cocal de Telha'],
  [2202729, 'Cocal dos Alves'],
  [2202737, 'Coivaras'],
  [2202752, 'Colônia do Gurguéia'],
  [2202778, 'Colônia do Piauí'],
  [2202802, 'Conceição do Canindé'],
  [2202851, 'Coronel José Dias'],
  [2202901, 'Corrente'],
  [2203008, 'Cristalândia do Piauí'],
  [2203107, 'Cristino Castro'],
  [2203206, 'Curimatá'],
  [2203230, 'Currais'],
  [2203255, 'Curralinhos'],
  [2203271, 'Curral Novo do Piauí'],
  [2203305, 'Demerval Lobão'],
  [2203354, 'Dirceu Arcoverde'],
  [2203404, 'Dom Expedito Lopes'],
  [2203420, 'Domingos Mourão'],
  [2203453, 'Dom Inocêncio'],
  [2203503, 'Elesbão Veloso'],
  [2203602, 'Eliseu Martins'],
  [2203701, 'Esperantina'],
  [2203750, 'Fartura do Piauí'],
  [2203800, 'Flores do Piauí'],
  [2203859, 'Floresta do Piauí'],
  [2203909, 'Floriano'],
  [2204006, 'Francinópolis'],
  [2204105, 'Francisco Ayres'],
  [2204154, 'Francisco Macedo'],
  [2204204, 'Francisco Santos'],
  [2204303, 'Fronteiras'],
  [2204352, 'Geminiano'],
  [2204402, 'Gilbués'],
  [2204501, 'Guadalupe'],
  [2204550, 'Guaribas'],
  [2204600, 'Hugo Napoleão'],
  [2204659, 'Ilha Grande'],
  [2204709, 'Inhuma'],
  [2204808, 'Ipiranga do Piauí'],
  [2204907, 'Isaías Coelho'],
  [2205003, 'Itainópolis'],
  [2205102, 'Itaueira'],
  [2205151, 'Jacobina do Piauí'],
  [2205201, 'Jaicós'],
  [2205250, 'Jardim do Mulato'],
  [2205276, 'Jatobá do Piauí'],
  [2205300, 'Jerumenha'],
  [2205359, 'João Costa'],
  [2205409, 'Joaquim Pires'],
  [2205458, 'Joca Marques'],
  [2205508, 'José de Freitas'],
  [2205516, 'Juazeiro do Piauí'],
  [2205524, 'Júlio Borges'],
  [2205532, 'Jurema'],
  [2205540, 'Lagoinha do Piauí'],
  [2205557, 'Lagoa Alegre'],
  [2205565, 'Lagoa do Barro do Piauí'],
  [2205573, 'Lagoa de São Francisco'],
  [2205581, 'Lagoa do Piauí'],
  [2205599, 'Lagoa do Sítio'],
  [2205607, 'Landri Sales'],
  [2205706, 'Luís Correia'],
  [2205805, 'Luzilândia'],
  [2205854, 'Madeiro'],
  [2205904, 'Manoel Emídio'],
  [2205953, 'Marcolândia'],
  [2206001, 'Marcos Parente'],
  [2206050, 'Massapê do Piauí'],
  [2206100, 'Matias Olímpio'],
  [2206209, 'Miguel Alves'],
  [2206308, 'Miguel Leão'],
  [2206357, 'Milton Brandão'],
  [2206407, 'Monsenhor Gil'],
  [2206506, 'Monsenhor Hipólito'],
  [2206605, 'Monte Alegre do Piauí'],
  [2206654, 'Morro Cabeça no Tempo'],
  [2206670, 'Morro do Chapéu do Piauí'],
  [2206696, 'Murici dos Portelas'],
  [2206704, 'Nazaré do Piauí'],
  [2206720, 'Nazária'],
  [2206753, 'Nossa Senhora de Nazaré'],
  [2206803, 'Nossa Senhora dos Remédios'],
  [2206902, 'Novo Oriente do Piauí'],
  [2206951, 'Novo Santo Antônio'],
  [2207009, 'Oeiras'],
  [2207108, "Olho D'Água do Piauí"],
  [2207207, 'Padre Marcos'],
  [2207306, 'Paes Landim'],
  [2207355, 'Pajeú do Piauí'],
  [2207405, 'Palmeira do Piauí'],
  [2207504, 'Palmeirais'],
  [2207553, 'Paquetá'],
  [2207603, 'Parnaguá'],
  [2207702, 'Parnaíba'],
  [2207751, 'Passagem Franca do Piauí'],
  [2207777, 'Patos do Piauí'],
  [2207793, "Pau D'Arco do Piauí"],
  [2207801, 'Paulistana'],
  [2207850, 'Pavussu'],
  [2207900, 'Pedro II'],
  [2207934, 'Pedro Laurentino'],
  [2207959, 'Nova Santa Rita'],
  [2208007, 'Picos'],
  [2208106, 'Pimenteiras'],
  [2208205, 'Pio IX'],
  [2208304, 'Piracuruca'],
  [2208403, 'Piripiri'],
  [2208502, 'Porto'],
  [2208551, 'Porto Alegre do Piauí'],
  [2208601, 'Prata do Piauí'],
  [2208650, 'Queimada Nova'],
  [2208700, 'Redenção do Gurguéia'],
  [2208809, 'Regeneração'],
  [2208858, 'Riacho Frio'],
  [2208874, 'Ribeira do Piauí'],
  [2208908, 'Ribeiro Gonçalves'],
  [2209005, 'Rio Grande do Piauí'],
  [2209104, 'Santa Cruz do Piauí'],
  [2209153, 'Santa Cruz dos Milagres'],
  [2209203, 'Santa Filomena'],
  [2209302, 'Santa Luz'],
  [2209351, 'Santana do Piauí'],
  [2209377, 'Santa Rosa do Piauí'],
  [2209401, 'Santo Antônio de Lisboa'],
  [2209450, 'Santo Antônio dos Milagres'],
  [2209500, 'Santo Inácio do Piauí'],
  [2209559, 'São Braz do Piauí'],
  [2209609, 'São Félix do Piauí'],
  [2209658, 'São Francisco de Assis do Piauí'],
  [2209708, 'São Francisco do Piauí'],
  [2209757, 'São Gonçalo do Gurguéia'],
  [2209807, 'São Gonçalo do Piauí'],
  [2209856, 'São João da Canabrava'],
  [2209872, 'São João da Fronteira'],
  [2209906, 'São João da Serra'],
  [2209955, 'São João da Varjota'],
  [2209971, 'São João do Arraial'],
  [2210003, 'São João do Piauí'],
  [2210052, 'São José do Divino'],
  [2210102, 'São José do Peixe'],
  [2210201, 'São José do Piauí'],
  [2210300, 'São Julião'],
  [2210359, 'São Lourenço do Piauí'],
  [2210375, 'São Luis do Piauí'],
  [2210383, 'São Miguel da Baixa Grande'],
  [2210391, 'São Miguel do Fidalgo'],
  [2210409, 'São Miguel do Tapuio'],
  [2210508, 'São Pedro do Piauí'],
  [2210607, 'São Raimundo Nonato'],
  [2210623, 'Sebastião Barros'],
  [2210631, 'Sebastião Leal'],
  [2210656, 'Sigefredo Pacheco'],
  [2210706, 'Simões'],
  [2210805, 'Simplício Mendes'],
  [2210904, 'Socorro do Piauí'],
  [2210938, 'Sussuapara'],
  [2210953, 'Tamboril do Piauí'],
  [2210979, 'Tanque do Piauí'],
  [2211001, 'Teresina'],
  [2211100, 'União'],
  [2211209, 'Uruçuí'],
  [2211308, 'Valença do Piauí'],
  [2211357, 'Várzea Branca'],
  [2211407, 'Várzea Grande'],
  [2211506, 'Vera Mendes'],
  [2211605, 'Vila Nova do Piauí'],
  [2211704, 'Wall Ferraz'],
  [2300101, 'Abaiara'],
  [2300150, 'Acarape'],
  [2300200, 'Acaraú'],
  [2300309, 'Acopiara'],
  [2300408, 'Aiuaba'],
  [2300507, 'Alcântaras'],
  [2300606, 'Altaneira'],
  [2300705, 'Alto Santo'],
  [2300754, 'Amontada'],
  [2300804, 'Antonina do Norte'],
  [2300903, 'Apuiarés'],
  [2301000, 'Aquiraz'],
  [2301109, 'Aracati'],
  [2301208, 'Aracoiaba'],
  [2301257, 'Ararendá'],
  [2301307, 'Araripe'],
  [2301406, 'Aratuba'],
  [2301505, 'Arneiroz'],
  [2301604, 'Assaré'],
  [2301703, 'Aurora'],
  [2301802, 'Baixio'],
  [2301851, 'Banabuiú'],
  [2301901, 'Barbalha'],
  [2301950, 'Barreira'],
  [2302008, 'Barro'],
  [2302057, 'Barroquinha'],
  [2302107, 'Baturité'],
  [2302206, 'Beberibe'],
  [2302305, 'Bela Cruz'],
  [2302404, 'Boa Viagem'],
  [2302503, 'Brejo Santo'],
  [2302602, 'Camocim'],
  [2302701, 'Campos Sales'],
  [2302800, 'Canindé'],
  [2302909, 'Capistrano'],
  [2303006, 'Caridade'],
  [2303105, 'Cariré'],
  [2303204, 'Caririaçu'],
  [2303303, 'Cariús'],
  [2303402, 'Carnaubal'],
  [2303501, 'Cascavel'],
  [2303600, 'Catarina'],
  [2303659, 'Catunda'],
  [2303709, 'Caucaia'],
  [2303808, 'Cedro'],
  [2303907, 'Chaval'],
  [2303931, 'Choró'],
  [2303956, 'Chorozinho'],
  [2304004, 'Coreaú'],
  [2304103, 'Crateús'],
  [2304202, 'Crato'],
  [2304236, 'Croatá'],
  [2304251, 'Cruz'],
  [2304269, 'Deputado Irapuan Pinheiro'],
  [2304277, 'Ereré'],
  [2304285, 'Eusébio'],
  [2304301, 'Farias Brito'],
  [2304350, 'Forquilha'],
  [2304400, 'Fortaleza'],
  [2304459, 'Fortim'],
  [2304509, 'Frecheirinha'],
  [2304608, 'General Sampaio'],
  [2304657, 'Graça'],
  [2304707, 'Granja'],
  [2304806, 'Granjeiro'],
  [2304905, 'Groaíras'],
  [2304954, 'Guaiúba'],
  [2305001, 'Guaraciaba do Norte'],
  [2305100, 'Guaramiranga'],
  [2305209, 'Hidrolândia'],
  [2305233, 'Horizonte'],
  [2305266, 'Ibaretama'],
  [2305308, 'Ibiapina'],
  [2305332, 'Ibicuitinga'],
  [2305357, 'Icapuí'],
  [2305407, 'Icó'],
  [2305506, 'Iguatu'],
  [2305605, 'Independência'],
  [2305654, 'Ipaporanga'],
  [2305704, 'Ipaumirim'],
  [2305803, 'Ipu'],
  [2305902, 'Ipueiras'],
  [2306009, 'Iracema'],
  [2306108, 'Irauçuba'],
  [2306207, 'Itaiçaba'],
  [2306256, 'Itaitinga'],
  [2306306, 'Itapajé'],
  [2306405, 'Itapipoca'],
  [2306504, 'Itapiúna'],
  [2306553, 'Itarema'],
  [2306603, 'Itatira'],
  [2306702, 'Jaguaretama'],
  [2306801, 'Jaguaribara'],
  [2306900, 'Jaguaribe'],
  [2307007, 'Jaguaruana'],
  [2307106, 'Jardim'],
  [2307205, 'Jati'],
  [2307254, 'Jijoca de Jericoacoara'],
  [2307304, 'Juazeiro do Norte'],
  [2307403, 'Jucás'],
  [2307502, 'Lavras da Mangabeira'],
  [2307601, 'Limoeiro do Norte'],
  [2307635, 'Madalena'],
  [2307650, 'Maracanaú'],
  [2307700, 'Maranguape'],
  [2307809, 'Marco'],
  [2307908, 'Martinópole'],
  [2308005, 'Massapê'],
  [2308104, 'Mauriti'],
  [2308203, 'Meruoca'],
  [2308302, 'Milagres'],
  [2308351, 'Milhã'],
  [2308377, 'Miraíma'],
  [2308401, 'Missão Velha'],
  [2308500, 'Mombaça'],
  [2308609, 'Monsenhor Tabosa'],
  [2308708, 'Morada Nova'],
  [2308807, 'Moraújo'],
  [2308906, 'Morrinhos'],
  [2309003, 'Mucambo'],
  [2309102, 'Mulungu'],
  [2309201, 'Nova Olinda'],
  [2309300, 'Nova Russas'],
  [2309409, 'Novo Oriente'],
  [2309458, 'Ocara'],
  [2309508, 'Orós'],
  [2309607, 'Pacajus'],
  [2309706, 'Pacatuba'],
  [2309805, 'Pacoti'],
  [2309904, 'Pacujá'],
  [2310001, 'Palhano'],
  [2310100, 'Palmácia'],
  [2310209, 'Paracuru'],
  [2310258, 'Paraipaba'],
  [2310308, 'Parambu'],
  [2310407, 'Paramoti'],
  [2310506, 'Pedra Branca'],
  [2310605, 'Penaforte'],
  [2310704, 'Pentecoste'],
  [2310803, 'Pereiro'],
  [2310852, 'Pindoretama'],
  [2310902, 'Piquet Carneiro'],
  [2310951, 'Pires Ferreira'],
  [2311009, 'Poranga'],
  [2311108, 'Porteiras'],
  [2311207, 'Potengi'],
  [2311231, 'Potiretama'],
  [2311264, 'Quiterianópolis'],
  [2311306, 'Quixadá'],
  [2311355, 'Quixelô'],
  [2311405, 'Quixeramobim'],
  [2311504, 'Quixeré'],
  [2311603, 'Redenção'],
  [2311702, 'Reriutaba'],
  [2311801, 'Russas'],
  [2311900, 'Saboeiro'],
  [2311959, 'Salitre'],
  [2312007, 'Santana do Acaraú'],
  [2312106, 'Santana do Cariri'],
  [2312205, 'Santa Quitéria'],
  [2312304, 'São Benedito'],
  [2312403, 'São Gonçalo do Amarante'],
  [2312502, 'São João do Jaguaribe'],
  [2312601, 'São Luís do Curu'],
  [2312700, 'Senador Pompeu'],
  [2312809, 'Senador Sá'],
  [2312908, 'Sobral'],
  [2313005, 'Solonópole'],
  [2313104, 'Tabuleiro do Norte'],
  [2313203, 'Tamboril'],
  [2313252, 'Tarrafas'],
  [2313302, 'Tauá'],
  [2313351, 'Tejuçuoca'],
  [2313401, 'Tianguá'],
  [2313500, 'Trairi'],
  [2313559, 'Tururu'],
  [2313609, 'Ubajara'],
  [2313708, 'Umari'],
  [2313757, 'Umirim'],
  [2313807, 'Uruburetama'],
  [2313906, 'Uruoca'],
  [2313955, 'Varjota'],
  [2314003, 'Várzea Alegre'],
  [2314102, 'Viçosa do Ceará'],
  [2400109, 'Acari'],
  [2400208, 'Açu'],
  [2400307, 'Afonso Bezerra'],
  [2400406, 'Água Nova'],
  [2400505, 'Alexandria'],
  [2400604, 'Almino Afonso'],
  [2400703, 'Alto do Rodrigues'],
  [2400802, 'Angicos'],
  [2400901, 'Antônio Martins'],
  [2401008, 'Apodi'],
  [2401107, 'Areia Branca'],
  [2401206, 'Arês'],
  [2401305, 'Campo Grande'],
  [2401404, 'Baía Formosa'],
  [2401453, 'Baraúna'],
  [2401503, 'Barcelona'],
  [2401602, 'Bento Fernandes'],
  [2401651, 'Bodó'],
  [2401701, 'Bom Jesus'],
  [2401800, 'Brejinho'],
  [2401859, 'Caiçara do Norte'],
  [2401909, 'Caiçara do Rio do Vento'],
  [2402006, 'Caicó'],
  [2402105, 'Campo Redondo'],
  [2402204, 'Canguaretama'],
  [2402303, 'Caraúbas'],
  [2402402, 'Carnaúba dos Dantas'],
  [2402501, 'Carnaubais'],
  [2402600, 'Ceará-Mirim'],
  [2402709, 'Cerro Corá'],
  [2402808, 'Coronel Ezequiel'],
  [2402907, 'Coronel João Pessoa'],
  [2403004, 'Cruzeta'],
  [2403103, 'Currais Novos'],
  [2403202, 'Doutor Severiano'],
  [2403251, 'Parnamirim'],
  [2403301, 'Encanto'],
  [2403400, 'Equador'],
  [2403509, 'Espírito Santo'],
  [2403608, 'Extremoz'],
  [2403707, 'Felipe Guerra'],
  [2403756, 'Fernando Pedroza'],
  [2403806, 'Florânia'],
  [2403905, 'Francisco Dantas'],
  [2404002, 'Frutuoso Gomes'],
  [2404101, 'Galinhos'],
  [2404200, 'Goianinha'],
  [2404309, 'Governador Dix-Sept Rosado'],
  [2404408, 'Grossos'],
  [2404507, 'Guamaré'],
  [2404606, 'Ielmo Marinho'],
  [2404705, 'Ipanguaçu'],
  [2404804, 'Ipueira'],
  [2404853, 'Itajá'],
  [2404903, 'Itaú'],
  [2405009, 'Jaçanã'],
  [2405108, 'Jandaíra'],
  [2405207, 'Janduís'],
  [2405306, 'Januário Cicco'],
  [2405405, 'Japi'],
  [2405504, 'Jardim de Angicos'],
  [2405603, 'Jardim de Piranhas'],
  [2405702, 'Jardim do Seridó'],
  [2405801, 'João Câmara'],
  [2405900, 'João Dias'],
  [2406007, 'José da Penha'],
  [2406106, 'Jucurutu'],
  [2406155, 'Jundiá'],
  [2406205, "Lagoa d'Anta"],
  [2406304, 'Lagoa de Pedras'],
  [2406403, 'Lagoa de Velhos'],
  [2406502, 'Lagoa Nova'],
  [2406601, 'Lagoa Salgada'],
  [2406700, 'Lajes'],
  [2406809, 'Lajes Pintadas'],
  [2406908, 'Lucrécia'],
  [2407005, 'Luís Gomes'],
  [2407104, 'Macaíba'],
  [2407203, 'Macau'],
  [2407252, 'Major Sales'],
  [2407302, 'Marcelino Vieira'],
  [2407401, 'Martins'],
  [2407500, 'Maxaranguape'],
  [2407609, 'Messias Targino'],
  [2407708, 'Montanhas'],
  [2407807, 'Monte Alegre'],
  [2407906, 'Monte das Gameleiras'],
  [2408003, 'Mossoró'],
  [2408102, 'Natal'],
  [2408201, 'Nísia Floresta'],
  [2408300, 'Nova Cruz'],
  [2408409, "Olho d'Água do Borges"],
  [2408508, 'Ouro Branco'],
  [2408607, 'Paraná'],
  [2408706, 'Paraú'],
  [2408805, 'Parazinho'],
  [2408904, 'Parelhas'],
  [2408953, 'Rio do Fogo'],
  [2409100, 'Passa e Fica'],
  [2409209, 'Passagem'],
  [2409308, 'Patu'],
  [2409332, 'Santa Maria'],
  [2409407, 'Pau dos Ferros'],
  [2409506, 'Pedra Grande'],
  [2409605, 'Pedra Preta'],
  [2409704, 'Pedro Avelino'],
  [2409803, 'Pedro Velho'],
  [2409902, 'Pendências'],
  [2410009, 'Pilões'],
  [2410108, 'Poço Branco'],
  [2410207, 'Portalegre'],
  [2410256, 'Porto do Mangue'],
  [2410306, 'Serra Caiada'],
  [2410405, 'Pureza'],
  [2410504, 'Rafael Fernandes'],
  [2410603, 'Rafael Godeiro'],
  [2410702, 'Riacho da Cruz'],
  [2410801, 'Riacho de Santana'],
  [2410900, 'Riachuelo'],
  [2411007, 'Rodolfo Fernandes'],
  [2411056, 'Tibau'],
  [2411106, 'Ruy Barbosa'],
  [2411205, 'Santa Cruz'],
  [2411403, 'Santana do Matos'],
  [2411429, 'Santana do Seridó'],
  [2411502, 'Santo Antônio'],
  [2411601, 'São Bento do Norte'],
  [2411700, 'São Bento do Trairí'],
  [2411809, 'São Fernando'],
  [2411908, 'São Francisco do Oeste'],
  [2412005, 'São Gonçalo do Amarante'],
  [2412104, 'São João do Sabugi'],
  [2412203, 'São José de Mipibu'],
  [2412302, 'São José do Campestre'],
  [2412401, 'São José do Seridó'],
  [2412500, 'São Miguel'],
  [2412559, 'São Miguel do Gostoso'],
  [2412609, 'São Paulo do Potengi'],
  [2412708, 'São Pedro'],
  [2412807, 'São Rafael'],
  [2412906, 'São Tomé'],
  [2413003, 'São Vicente'],
  [2413102, 'Senador Elói de Souza'],
  [2413201, 'Senador Georgino Avelino'],
  [2413300, 'Serra de São Bento'],
  [2413359, 'Serra do Mel'],
  [2413409, 'Serra Negra do Norte'],
  [2413508, 'Serrinha'],
  [2413557, 'Serrinha dos Pintos'],
  [2413607, 'Severiano Melo'],
  [2413706, 'Sítio Novo'],
  [2413805, 'Taboleiro Grande'],
  [2413904, 'Taipu'],
  [2414001, 'Tangará'],
  [2414100, 'Tenente Ananias'],
  [2414159, 'Tenente Laurentino Cruz'],
  [2414209, 'Tibau do Sul'],
  [2414308, 'Timbaúba dos Batistas'],
  [2414407, 'Touros'],
  [2414456, 'Triunfo Potiguar'],
  [2414506, 'Umarizal'],
  [2414605, 'Upanema'],
  [2414704, 'Várzea'],
  [2414753, 'Venha-Ver'],
  [2414803, 'Vera Cruz'],
  [2414902, 'Viçosa'],
  [2415008, 'Vila Flor'],
  [2500106, 'Água Branca'],
  [2500205, 'Aguiar'],
  [2500304, 'Alagoa Grande'],
  [2500403, 'Alagoa Nova'],
  [2500502, 'Alagoinha'],
  [2500536, 'Alcantil'],
  [2500577, 'Algodão de Jandaíra'],
  [2500601, 'Alhandra'],
  [2500700, 'São João do Rio do Peixe'],
  [2500734, 'Amparo'],
  [2500775, 'Aparecida'],
  [2500809, 'Araçagi'],
  [2500908, 'Arara'],
  [2501005, 'Araruna'],
  [2501104, 'Areia'],
  [2501153, 'Areia de Baraúnas'],
  [2501203, 'Areial'],
  [2501302, 'Aroeiras'],
  [2501351, 'Assunção'],
  [2501401, 'Baía da Traição'],
  [2501500, 'Bananeiras'],
  [2501534, 'Baraúna'],
  [2501575, 'Barra de Santana'],
  [2501609, 'Barra de Santa Rosa'],
  [2501708, 'Barra de São Miguel'],
  [2501807, 'Bayeux'],
  [2501906, 'Belém'],
  [2502003, 'Belém do Brejo do Cruz'],
  [2502052, 'Bernardino Batista'],
  [2502102, 'Boa Ventura'],
  [2502151, 'Boa Vista'],
  [2502201, 'Bom Jesus'],
  [2502300, 'Bom Sucesso'],
  [2502409, 'Bonito de Santa Fé'],
  [2502508, 'Boqueirão'],
  [2502607, 'Igaracy'],
  [2502706, 'Borborema'],
  [2502805, 'Brejo do Cruz'],
  [2502904, 'Brejo dos Santos'],
  [2503001, 'Caaporã'],
  [2503100, 'Cabaceiras'],
  [2503209, 'Cabedelo'],
  [2503308, 'Cachoeira dos Índios'],
  [2503407, 'Cacimba de Areia'],
  [2503506, 'Cacimba de Dentro'],
  [2503555, 'Cacimbas'],
  [2503605, 'Caiçara'],
  [2503704, 'Cajazeiras'],
  [2503753, 'Cajazeirinhas'],
  [2503803, 'Caldas Brandão'],
  [2503902, 'Camalaú'],
  [2504009, 'Campina Grande'],
  [2504033, 'Capim'],
  [2504074, 'Caraúbas'],
  [2504108, 'Carrapateira'],
  [2504157, 'Casserengue'],
  [2504207, 'Catingueira'],
  [2504306, 'Catolé do Rocha'],
  [2504355, 'Caturité'],
  [2504405, 'Conceição'],
  [2504504, 'Condado'],
  [2504603, 'Conde'],
  [2504702, 'Congo'],
  [2504801, 'Coremas'],
  [2504850, 'Coxixola'],
  [2504900, 'Cruz do Espírito Santo'],
  [2505006, 'Cubati'],
  [2505105, 'Cuité'],
  [2505204, 'Cuitegi'],
  [2505238, 'Cuité de Mamanguape'],
  [2505279, 'Curral de Cima'],
  [2505303, 'Curral Velho'],
  [2505352, 'Damião'],
  [2505402, 'Desterro'],
  [2505501, 'Vista Serrana'],
  [2505600, 'Diamante'],
  [2505709, 'Dona Inês'],
  [2505808, 'Duas Estradas'],
  [2505907, 'Emas'],
  [2506004, 'Esperança'],
  [2506103, 'Fagundes'],
  [2506202, 'Frei Martinho'],
  [2506251, 'Gado Bravo'],
  [2506301, 'Guarabira'],
  [2506400, 'Gurinhém'],
  [2506509, 'Gurjão'],
  [2506608, 'Ibiara'],
  [2506707, 'Imaculada'],
  [2506806, 'Ingá'],
  [2506905, 'Itabaiana'],
  [2507002, 'Itaporanga'],
  [2507101, 'Itapororoca'],
  [2507200, 'Itatuba'],
  [2507309, 'Jacaraú'],
  [2507408, 'Jericó'],
  [2507507, 'João Pessoa'],
  [2507606, 'Juarez Távora'],
  [2507705, 'Juazeirinho'],
  [2507804, 'Junco do Seridó'],
  [2507903, 'Juripiranga'],
  [2508000, 'Juru'],
  [2508109, 'Lagoa'],
  [2508208, 'Lagoa de Dentro'],
  [2508307, 'Lagoa Seca'],
  [2508406, 'Lastro'],
  [2508505, 'Livramento'],
  [2508554, 'Logradouro'],
  [2508604, 'Lucena'],
  [2508703, "Mãe d'Água"],
  [2508802, 'Malta'],
  [2508901, 'Mamanguape'],
  [2509008, 'Manaíra'],
  [2509057, 'Marcação'],
  [2509107, 'Mari'],
  [2509156, 'Marizópolis'],
  [2509206, 'Massaranduba'],
  [2509305, 'Mataraca'],
  [2509339, 'Matinhas'],
  [2509370, 'Mato Grosso'],
  [2509396, 'Maturéia'],
  [2509404, 'Mogeiro'],
  [2509503, 'Montadas'],
  [2509602, 'Monte Horebe'],
  [2509701, 'Monteiro'],
  [2509800, 'Mulungu'],
  [2509909, 'Natuba'],
  [2510006, 'Nazarezinho'],
  [2510105, 'Nova Floresta'],
  [2510204, 'Nova Olinda'],
  [2510303, 'Nova Palmeira'],
  [2510402, "Olho d'Água"],
  [2510501, 'Olivedos'],
  [2510600, 'Ouro Velho'],
  [2510659, 'Parari'],
  [2510709, 'Passagem'],
  [2510808, 'Patos'],
  [2510907, 'Paulista'],
  [2511004, 'Pedra Branca'],
  [2511103, 'Pedra Lavrada'],
  [2511202, 'Pedras de Fogo'],
  [2511301, 'Piancó'],
  [2511400, 'Picuí'],
  [2511509, 'Pilar'],
  [2511608, 'Pilões'],
  [2511707, 'Pilõezinhos'],
  [2511806, 'Pirpirituba'],
  [2511905, 'Pitimbu'],
  [2512002, 'Pocinhos'],
  [2512036, 'Poço Dantas'],
  [2512077, 'Poço de José de Moura'],
  [2512101, 'Pombal'],
  [2512200, 'Prata'],
  [2512309, 'Princesa Isabel'],
  [2512408, 'Puxinanã'],
  [2512507, 'Queimadas'],
  [2512606, 'Quixaba'],
  [2512705, 'Remígio'],
  [2512721, 'Pedro Régis'],
  [2512747, 'Riachão'],
  [2512754, 'Riachão do Bacamarte'],
  [2512762, 'Riachão do Poço'],
  [2512788, 'Riacho de Santo Antônio'],
  [2512804, 'Riacho dos Cavalos'],
  [2512903, 'Rio Tinto'],
  [2513000, 'Salgadinho'],
  [2513109, 'Salgado de São Félix'],
  [2513158, 'Santa Cecília'],
  [2513208, 'Santa Cruz'],
  [2513307, 'Santa Helena'],
  [2513356, 'Santa Inês'],
  [2513406, 'Santa Luzia'],
  [2513505, 'Santana de Mangueira'],
  [2513604, 'Santana dos Garrotes'],
  [2513653, 'Joca Claudino'],
  [2513703, 'Santa Rita'],
  [2513802, 'Santa Teresinha'],
  [2513851, 'Santo André'],
  [2513901, 'São Bento'],
  [2513927, 'São Bentinho'],
  [2513943, 'São Domingos do Cariri'],
  [2513968, 'São Domingos'],
  [2513984, 'São Francisco'],
  [2514008, 'São João do Cariri'],
  [2514107, 'São João do Tigre'],
  [2514206, 'São José da Lagoa Tapada'],
  [2514305, 'São José de Caiana'],
  [2514404, 'São José de Espinharas'],
  [2514453, 'São José dos Ramos'],
  [2514503, 'São José de Piranhas'],
  [2514552, 'São José de Princesa'],
  [2514602, 'São José do Bonfim'],
  [2514651, 'São José do Brejo do Cruz'],
  [2514701, 'São José do Sabugi'],
  [2514800, 'São José dos Cordeiros'],
  [2514909, 'São Mamede'],
  [2515005, 'São Miguel de Taipu'],
  [2515104, 'São Sebastião de Lagoa de Roça'],
  [2515203, 'São Sebastião do Umbuzeiro'],
  [2515302, 'Sapé'],
  [2515401, 'São Vicente do Seridó'],
  [2515500, 'Serra Branca'],
  [2515609, 'Serra da Raiz'],
  [2515708, 'Serra Grande'],
  [2515807, 'Serra Redonda'],
  [2515906, 'Serraria'],
  [2515930, 'Sertãozinho'],
  [2515971, 'Sobrado'],
  [2516003, 'Solânea'],
  [2516102, 'Soledade'],
  [2516151, 'Sossêgo'],
  [2516201, 'Sousa'],
  [2516300, 'Sumé'],
  [2516409, 'Tacima'],
  [2516508, 'Taperoá'],
  [2516607, 'Tavares'],
  [2516706, 'Teixeira'],
  [2516755, 'Tenório'],
  [2516805, 'Triunfo'],
  [2516904, 'Uiraúna'],
  [2517001, 'Umbuzeiro'],
  [2517100, 'Várzea'],
  [2517209, 'Vieirópolis'],
  [2517407, 'Zabelê'],
  [2600054, 'Abreu e Lima'],
  [2600104, 'Afogados da Ingazeira'],
  [2600203, 'Afrânio'],
  [2600302, 'Agrestina'],
  [2600401, 'Água Preta'],
  [2600500, 'Águas Belas'],
  [2600609, 'Alagoinha'],
  [2600708, 'Aliança'],
  [2600807, 'Altinho'],
  [2600906, 'Amaraji'],
  [2601003, 'Angelim'],
  [2601052, 'Araçoiaba'],
  [2601102, 'Araripina'],
  [2601201, 'Arcoverde'],
  [2601300, 'Barra de Guabiraba'],
  [2601409, 'Barreiros'],
  [2601508, 'Belém de Maria'],
  [2601607, 'Belém do São Francisco'],
  [2601706, 'Belo Jardim'],
  [2601805, 'Betânia'],
  [2601904, 'Bezerros'],
  [2602001, 'Bodocó'],
  [2602100, 'Bom Conselho'],
  [2602209, 'Bom Jardim'],
  [2602308, 'Bonito'],
  [2602407, 'Brejão'],
  [2602506, 'Brejinho'],
  [2602605, 'Brejo da Madre de Deus'],
  [2602704, 'Buenos Aires'],
  [2602803, 'Buíque'],
  [2602902, 'Cabo de Santo Agostinho'],
  [2603009, 'Cabrobó'],
  [2603108, 'Cachoeirinha'],
  [2603207, 'Caetés'],
  [2603306, 'Calçado'],
  [2603405, 'Calumbi'],
  [2603454, 'Camaragibe'],
  [2603504, 'Camocim de São Félix'],
  [2603603, 'Camutanga'],
  [2603702, 'Canhotinho'],
  [2603801, 'Capoeiras'],
  [2603900, 'Carnaíba'],
  [2603926, 'Carnaubeira da Penha'],
  [2604007, 'Carpina'],
  [2604106, 'Caruaru'],
  [2604155, 'Casinhas'],
  [2604205, 'Catende'],
  [2604304, 'Cedro'],
  [2604403, 'Chã de Alegria'],
  [2604502, 'Chã Grande'],
  [2604601, 'Condado'],
  [2604700, 'Correntes'],
  [2604809, 'Cortês'],
  [2604908, 'Cumaru'],
  [2605004, 'Cupira'],
  [2605103, 'Custódia'],
  [2605152, 'Dormentes'],
  [2605202, 'Escada'],
  [2605301, 'Exu'],
  [2605400, 'Feira Nova'],
  [2605459, 'Fernando de Noronha'],
  [2605509, 'Ferreiros'],
  [2605608, 'Flores'],
  [2605707, 'Floresta'],
  [2605806, 'Frei Miguelinho'],
  [2605905, 'Gameleira'],
  [2606002, 'Garanhuns'],
  [2606101, 'Glória do Goitá'],
  [2606200, 'Goiana'],
  [2606309, 'Granito'],
  [2606408, 'Gravatá'],
  [2606507, 'Iati'],
  [2606606, 'Ibimirim'],
  [2606705, 'Ibirajuba'],
  [2606804, 'Igarassu'],
  [2606903, 'Iguaracy'],
  [2607000, 'Inajá'],
  [2607109, 'Ingazeira'],
  [2607208, 'Ipojuca'],
  [2607307, 'Ipubi'],
  [2607406, 'Itacuruba'],
  [2607505, 'Itaíba'],
  [2607604, 'Ilha de Itamaracá'],
  [2607653, 'Itambé'],
  [2607703, 'Itapetim'],
  [2607752, 'Itapissuma'],
  [2607802, 'Itaquitinga'],
  [2607901, 'Jaboatão dos Guararapes'],
  [2607950, 'Jaqueira'],
  [2608008, 'Jataúba'],
  [2608057, 'Jatobá'],
  [2608107, 'João Alfredo'],
  [2608206, 'Joaquim Nabuco'],
  [2608255, 'Jucati'],
  [2608305, 'Jupi'],
  [2608404, 'Jurema'],
  [2608453, 'Lagoa do Carro'],
  [2608503, 'Lagoa de Itaenga'],
  [2608602, 'Lagoa do Ouro'],
  [2608701, 'Lagoa dos Gatos'],
  [2608750, 'Lagoa Grande'],
  [2608800, 'Lajedo'],
  [2608909, 'Limoeiro'],
  [2609006, 'Macaparana'],
  [2609105, 'Machados'],
  [2609154, 'Manari'],
  [2609204, 'Maraial'],
  [2609303, 'Mirandiba'],
  [2609402, 'Moreno'],
  [2609501, 'Nazaré da Mata'],
  [2609600, 'Olinda'],
  [2609709, 'Orobó'],
  [2609808, 'Orocó'],
  [2609907, 'Ouricuri'],
  [2610004, 'Palmares'],
  [2610103, 'Palmeirina'],
  [2610202, 'Panelas'],
  [2610301, 'Paranatama'],
  [2610400, 'Parnamirim'],
  [2610509, 'Passira'],
  [2610608, 'Paudalho'],
  [2610707, 'Paulista'],
  [2610806, 'Pedra'],
  [2610905, 'Pesqueira'],
  [2611002, 'Petrolândia'],
  [2611101, 'Petrolina'],
  [2611200, 'Poção'],
  [2611309, 'Pombos'],
  [2611408, 'Primavera'],
  [2611507, 'Quipapá'],
  [2611533, 'Quixaba'],
  [2611606, 'Recife'],
  [2611705, 'Riacho das Almas'],
  [2611804, 'Ribeirão'],
  [2611903, 'Rio Formoso'],
  [2612000, 'Sairé'],
  [2612109, 'Salgadinho'],
  [2612208, 'Salgueiro'],
  [2612307, 'Saloá'],
  [2612406, 'Sanharó'],
  [2612455, 'Santa Cruz'],
  [2612471, 'Santa Cruz da Baixa Verde'],
  [2612505, 'Santa Cruz do Capibaribe'],
  [2612554, 'Santa Filomena'],
  [2612604, 'Santa Maria da Boa Vista'],
  [2612703, 'Santa Maria do Cambucá'],
  [2612802, 'Santa Terezinha'],
  [2612901, 'São Benedito do Sul'],
  [2613008, 'São Bento do Una'],
  [2613107, 'São Caitano'],
  [2613206, 'São João'],
  [2613305, 'São Joaquim do Monte'],
  [2613404, 'São José da Coroa Grande'],
  [2613503, 'São José do Belmonte'],
  [2613602, 'São José do Egito'],
  [2613701, 'São Lourenço da Mata'],
  [2613800, 'São Vicente Férrer'],
  [2613909, 'Serra Talhada'],
  [2614006, 'Serrita'],
  [2614105, 'Sertânia'],
  [2614204, 'Sirinhaém'],
  [2614303, 'Moreilândia'],
  [2614402, 'Solidão'],
  [2614501, 'Surubim'],
  [2614600, 'Tabira'],
  [2614709, 'Tacaimbó'],
  [2614808, 'Tacaratu'],
  [2614857, 'Tamandaré'],
  [2615003, 'Taquaritinga do Norte'],
  [2615102, 'Terezinha'],
  [2615201, 'Terra Nova'],
  [2615300, 'Timbaúba'],
  [2615409, 'Toritama'],
  [2615508, 'Tracunhaém'],
  [2615607, 'Trindade'],
  [2615706, 'Triunfo'],
  [2615805, 'Tupanatinga'],
  [2615904, 'Tuparetama'],
  [2616001, 'Venturosa'],
  [2616100, 'Verdejante'],
  [2616183, 'Vertente do Lério'],
  [2616209, 'Vertentes'],
  [2616308, 'Vicência'],
  [2616407, 'Vitória de Santo Antão'],
  [2616506, 'Xexéu'],
  [2700102, 'Água Branca'],
  [2700201, 'Anadia'],
  [2700300, 'Arapiraca'],
  [2700409, 'Atalaia'],
  [2700508, 'Barra de Santo Antônio'],
  [2700607, 'Barra de São Miguel'],
  [2700706, 'Batalha'],
  [2700805, 'Belém'],
  [2700904, 'Belo Monte'],
  [2701001, 'Boca da Mata'],
  [2701100, 'Branquinha'],
  [2701209, 'Cacimbinhas'],
  [2701308, 'Cajueiro'],
  [2701357, 'Campestre'],
  [2701407, 'Campo Alegre'],
  [2701506, 'Campo Grande'],
  [2701605, 'Canapi'],
  [2701704, 'Capela'],
  [2701803, 'Carneiros'],
  [2701902, 'Chã Preta'],
  [2702009, 'Coité do Nóia'],
  [2702108, 'Colônia Leopoldina'],
  [2702207, 'Coqueiro Seco'],
  [2702306, 'Coruripe'],
  [2702355, 'Craíbas'],
  [2702405, 'Delmiro Gouveia'],
  [2702504, 'Dois Riachos'],
  [2702553, 'Estrela de Alagoas'],
  [2702603, 'Feira Grande'],
  [2702702, 'Feliz Deserto'],
  [2702801, 'Flexeiras'],
  [2702900, 'Girau do Ponciano'],
  [2703007, 'Ibateguara'],
  [2703106, 'Igaci'],
  [2703205, 'Igreja Nova'],
  [2703304, 'Inhapi'],
  [2703403, 'Jacaré dos Homens'],
  [2703502, 'Jacuípe'],
  [2703601, 'Japaratinga'],
  [2703700, 'Jaramataia'],
  [2703759, 'Jequiá da Praia'],
  [2703809, 'Joaquim Gomes'],
  [2703908, 'Jundiá'],
  [2704005, 'Junqueiro'],
  [2704104, 'Lagoa da Canoa'],
  [2704203, 'Limoeiro de Anadia'],
  [2704302, 'Maceió'],
  [2704401, 'Major Isidoro'],
  [2704500, 'Maragogi'],
  [2704609, 'Maravilha'],
  [2704708, 'Marechal Deodoro'],
  [2704807, 'Maribondo'],
  [2704906, 'Mar Vermelho'],
  [2705002, 'Mata Grande'],
  [2705101, 'Matriz de Camaragibe'],
  [2705200, 'Messias'],
  [2705309, 'Minador do Negrão'],
  [2705408, 'Monteirópolis'],
  [2705507, 'Murici'],
  [2705606, 'Novo Lino'],
  [2705705, "Olho d'Água das Flores"],
  [2705804, "Olho d'Água do Casado"],
  [2705903, "Olho d'Água Grande"],
  [2706000, 'Olivença'],
  [2706109, 'Ouro Branco'],
  [2706208, 'Palestina'],
  [2706307, 'Palmeira dos Índios'],
  [2706406, 'Pão de Açúcar'],
  [2706422, 'Pariconha'],
  [2706448, 'Paripueira'],
  [2706505, 'Passo de Camaragibe'],
  [2706604, 'Paulo Jacinto'],
  [2706703, 'Penedo'],
  [2706802, 'Piaçabuçu'],
  [2706901, 'Pilar'],
  [2707008, 'Pindoba'],
  [2707107, 'Piranhas'],
  [2707206, 'Poço das Trincheiras'],
  [2707305, 'Porto Calvo'],
  [2707404, 'Porto de Pedras'],
  [2707503, 'Porto Real do Colégio'],
  [2707602, 'Quebrangulo'],
  [2707701, 'Rio Largo'],
  [2707800, 'Roteiro'],
  [2707909, 'Santa Luzia do Norte'],
  [2708006, 'Santana do Ipanema'],
  [2708105, 'Santana do Mundaú'],
  [2708204, 'São Brás'],
  [2708303, 'São José da Laje'],
  [2708402, 'São José da Tapera'],
  [2708501, 'São Luís do Quitunde'],
  [2708600, 'São Miguel dos Campos'],
  [2708709, 'São Miguel dos Milagres'],
  [2708808, 'São Sebastião'],
  [2708907, 'Satuba'],
  [2708956, 'Senador Rui Palmeira'],
  [2709004, "Tanque d'Arca"],
  [2709103, 'Taquarana'],
  [2709152, 'Teotônio Vilela'],
  [2709202, 'Traipu'],
  [2709301, 'União dos Palmares'],
  [2709400, 'Viçosa'],
  [2800100, 'Amparo do São Francisco'],
  [2800209, 'Aquidabã'],
  [2800308, 'Aracaju'],
  [2800407, 'Arauá'],
  [2800506, 'Areia Branca'],
  [2800605, 'Barra dos Coqueiros'],
  [2800670, 'Boquim'],
  [2800704, 'Brejo Grande'],
  [2801009, 'Campo do Brito'],
  [2801108, 'Canhoba'],
  [2801207, 'Canindé de São Francisco'],
  [2801306, 'Capela'],
  [2801405, 'Carira'],
  [2801504, 'Carmópolis'],
  [2801603, 'Cedro de São João'],
  [2801702, 'Cristinápolis'],
  [2801900, 'Cumbe'],
  [2802007, 'Divina Pastora'],
  [2802106, 'Estância'],
  [2802205, 'Feira Nova'],
  [2802304, 'Frei Paulo'],
  [2802403, 'Gararu'],
  [2802502, 'General Maynard'],
  [2802601, 'Gracho Cardoso'],
  [2802700, 'Ilha das Flores'],
  [2802809, 'Indiaroba'],
  [2802908, 'Itabaiana'],
  [2803005, 'Itabaianinha'],
  [2803104, 'Itabi'],
  [2803203, "Itaporanga d'Ajuda"],
  [2803302, 'Japaratuba'],
  [2803401, 'Japoatã'],
  [2803500, 'Lagarto'],
  [2803609, 'Laranjeiras'],
  [2803708, 'Macambira'],
  [2803807, 'Malhada dos Bois'],
  [2803906, 'Malhador'],
  [2804003, 'Maruim'],
  [2804102, 'Moita Bonita'],
  [2804201, 'Monte Alegre de Sergipe'],
  [2804300, 'Muribeca'],
  [2804409, 'Neópolis'],
  [2804458, 'Nossa Senhora Aparecida'],
  [2804508, 'Nossa Senhora da Glória'],
  [2804607, 'Nossa Senhora das Dores'],
  [2804706, 'Nossa Senhora de Lourdes'],
  [2804805, 'Nossa Senhora do Socorro'],
  [2804904, 'Pacatuba'],
  [2805000, 'Pedra Mole'],
  [2805109, 'Pedrinhas'],
  [2805208, 'Pinhão'],
  [2805307, 'Pirambu'],
  [2805406, 'Poço Redondo'],
  [2805505, 'Poço Verde'],
  [2805604, 'Porto da Folha'],
  [2805703, 'Propriá'],
  [2805802, 'Riachão do Dantas'],
  [2805901, 'Riachuelo'],
  [2806008, 'Ribeirópolis'],
  [2806107, 'Rosário do Catete'],
  [2806206, 'Salgado'],
  [2806305, 'Santa Luzia do Itanhy'],
  [2806404, 'Santana do São Francisco'],
  [2806503, 'Santa Rosa de Lima'],
  [2806602, 'Santo Amaro das Brotas'],
  [2806701, 'São Cristóvão'],
  [2806800, 'São Domingos'],
  [2806909, 'São Francisco'],
  [2807006, 'São Miguel do Aleixo'],
  [2807105, 'Simão Dias'],
  [2807204, 'Siriri'],
  [2807303, 'Telha'],
  [2807402, 'Tobias Barreto'],
  [2807501, 'Tomar do Geru'],
  [2807600, 'Umbaúba'],
  [2900108, 'Abaíra'],
  [2900207, 'Abaré'],
  [2900306, 'Acajutiba'],
  [2900355, 'Adustina'],
  [2900405, 'Água Fria'],
  [2900504, 'Érico Cardoso'],
  [2900603, 'Aiquara'],
  [2900702, 'Alagoinhas'],
  [2900801, 'Alcobaça'],
  [2900900, 'Almadina'],
  [2901007, 'Amargosa'],
  [2901106, 'Amélia Rodrigues'],
  [2901155, 'América Dourada'],
  [2901205, 'Anagé'],
  [2901304, 'Andaraí'],
  [2901353, 'Andorinha'],
  [2901403, 'Angical'],
  [2901502, 'Anguera'],
  [2901601, 'Antas'],
  [2901700, 'Antônio Cardoso'],
  [2901809, 'Antônio Gonçalves'],
  [2901908, 'Aporá'],
  [2901957, 'Apuarema'],
  [2902005, 'Aracatu'],
  [2902054, 'Araçás'],
  [2902104, 'Araci'],
  [2902203, 'Aramari'],
  [2902252, 'Arataca'],
  [2902302, 'Aratuípe'],
  [2902401, 'Aurelino Leal'],
  [2902500, 'Baianópolis'],
  [2902609, 'Baixa Grande'],
  [2902658, 'Banzaê'],
  [2902708, 'Barra'],
  [2902807, 'Barra da Estiva'],
  [2902906, 'Barra do Choça'],
  [2903003, 'Barra do Mendes'],
  [2903102, 'Barra do Rocha'],
  [2903201, 'Barreiras'],
  [2903235, 'Barro Alto'],
  [2903276, 'Barrocas'],
  [2903300, 'Barro Preto'],
  [2903409, 'Belmonte'],
  [2903508, 'Belo Campo'],
  [2903607, 'Biritinga'],
  [2903706, 'Boa Nova'],
  [2903805, 'Boa Vista do Tupim'],
  [2903904, 'Bom Jesus da Lapa'],
  [2903953, 'Bom Jesus da Serra'],
  [2904001, 'Boninal'],
  [2904050, 'Bonito'],
  [2904100, 'Boquira'],
  [2904209, 'Botuporã'],
  [2904308, 'Brejões'],
  [2904407, 'Brejolândia'],
  [2904506, 'Brotas de Macaúbas'],
  [2904605, 'Brumado'],
  [2904704, 'Buerarema'],
  [2904753, 'Buritirama'],
  [2904803, 'Caatiba'],
  [2904852, 'Cabaceiras do Paraguaçu'],
  [2904902, 'Cachoeira'],
  [2905008, 'Caculé'],
  [2905107, 'Caém'],
  [2905156, 'Caetanos'],
  [2905206, 'Caetité'],
  [2905305, 'Cafarnaum'],
  [2905404, 'Cairu'],
  [2905503, 'Caldeirão Grande'],
  [2905602, 'Camacan'],
  [2905701, 'Camaçari'],
  [2905800, 'Camamu'],
  [2905909, 'Campo Alegre de Lourdes'],
  [2906006, 'Campo Formoso'],
  [2906105, 'Canápolis'],
  [2906204, 'Canarana'],
  [2906303, 'Canavieiras'],
  [2906402, 'Candeal'],
  [2906501, 'Candeias'],
  [2906600, 'Candiba'],
  [2906709, 'Cândido Sales'],
  [2906808, 'Cansanção'],
  [2906824, 'Canudos'],
  [2906857, 'Capela do Alto Alegre'],
  [2906873, 'Capim Grosso'],
  [2906899, 'Caraíbas'],
  [2906907, 'Caravelas'],
  [2907004, 'Cardeal da Silva'],
  [2907103, 'Carinhanha'],
  [2907202, 'Casa Nova'],
  [2907301, 'Castro Alves'],
  [2907400, 'Catolândia'],
  [2907509, 'Catu'],
  [2907558, 'Caturama'],
  [2907608, 'Central'],
  [2907707, 'Chorrochó'],
  [2907806, 'Cícero Dantas'],
  [2907905, 'Cipó'],
  [2908002, 'Coaraci'],
  [2908101, 'Cocos'],
  [2908200, 'Conceição da Feira'],
  [2908309, 'Conceição do Almeida'],
  [2908408, 'Conceição do Coité'],
  [2908507, 'Conceição do Jacuípe'],
  [2908606, 'Conde'],
  [2908705, 'Condeúba'],
  [2908804, 'Contendas do Sincorá'],
  [2908903, 'Coração de Maria'],
  [2909000, 'Cordeiros'],
  [2909109, 'Coribe'],
  [2909208, 'Coronel João Sá'],
  [2909307, 'Correntina'],
  [2909406, 'Cotegipe'],
  [2909505, 'Cravolândia'],
  [2909604, 'Crisópolis'],
  [2909703, 'Cristópolis'],
  [2909802, 'Cruz das Almas'],
  [2909901, 'Curaçá'],
  [2910008, 'Dário Meira'],
  [2910057, "Dias d'Ávila"],
  [2910107, 'Dom Basílio'],
  [2910206, 'Dom Macedo Costa'],
  [2910305, 'Elísio Medrado'],
  [2910404, 'Encruzilhada'],
  [2910503, 'Entre Rios'],
  [2910602, 'Esplanada'],
  [2910701, 'Euclides da Cunha'],
  [2910727, 'Eunápolis'],
  [2910750, 'Fátima'],
  [2910776, 'Feira da Mata'],
  [2910800, 'Feira de Santana'],
  [2910859, 'Filadélfia'],
  [2910909, 'Firmino Alves'],
  [2911006, 'Floresta Azul'],
  [2911105, 'Formosa do Rio Preto'],
  [2911204, 'Gandu'],
  [2911253, 'Gavião'],
  [2911303, 'Gentio do Ouro'],
  [2911402, 'Glória'],
  [2911501, 'Gongogi'],
  [2911600, 'Governador Mangabeira'],
  [2911659, 'Guajeru'],
  [2911709, 'Guanambi'],
  [2911808, 'Guaratinga'],
  [2911857, 'Heliópolis'],
  [2911907, 'Iaçu'],
  [2912004, 'Ibiassucê'],
  [2912103, 'Ibicaraí'],
  [2912202, 'Ibicoara'],
  [2912301, 'Ibicuí'],
  [2912400, 'Ibipeba'],
  [2912509, 'Ibipitanga'],
  [2912608, 'Ibiquera'],
  [2912707, 'Ibirapitanga'],
  [2912806, 'Ibirapuã'],
  [2912905, 'Ibirataia'],
  [2913002, 'Ibitiara'],
  [2913101, 'Ibititá'],
  [2913200, 'Ibotirama'],
  [2913309, 'Ichu'],
  [2913408, 'Igaporã'],
  [2913457, 'Igrapiúna'],
  [2913507, 'Iguaí'],
  [2913606, 'Ilhéus'],
  [2913705, 'Inhambupe'],
  [2913804, 'Ipecaetá'],
  [2913903, 'Ipiaú'],
  [2914000, 'Ipirá'],
  [2914109, 'Ipupiara'],
  [2914208, 'Irajuba'],
  [2914307, 'Iramaia'],
  [2914406, 'Iraquara'],
  [2914505, 'Irará'],
  [2914604, 'Irecê'],
  [2914653, 'Itabela'],
  [2914703, 'Itaberaba'],
  [2914802, 'Itabuna'],
  [2914901, 'Itacaré'],
  [2915007, 'Itaeté'],
  [2915106, 'Itagi'],
  [2915205, 'Itagibá'],
  [2915304, 'Itagimirim'],
  [2915353, 'Itaguaçu da Bahia'],
  [2915403, 'Itaju do Colônia'],
  [2915502, 'Itajuípe'],
  [2915601, 'Itamaraju'],
  [2915700, 'Itamari'],
  [2915809, 'Itambé'],
  [2915908, 'Itanagra'],
  [2916005, 'Itanhém'],
  [2916104, 'Itaparica'],
  [2916203, 'Itapé'],
  [2916302, 'Itapebi'],
  [2916401, 'Itapetinga'],
  [2916500, 'Itapicuru'],
  [2916609, 'Itapitanga'],
  [2916708, 'Itaquara'],
  [2916807, 'Itarantim'],
  [2916856, 'Itatim'],
  [2916906, 'Itiruçu'],
  [2917003, 'Itiúba'],
  [2917102, 'Itororó'],
  [2917201, 'Ituaçu'],
  [2917300, 'Ituberá'],
  [2917334, 'Iuiu'],
  [2917359, 'Jaborandi'],
  [2917409, 'Jacaraci'],
  [2917508, 'Jacobina'],
  [2917607, 'Jaguaquara'],
  [2917706, 'Jaguarari'],
  [2917805, 'Jaguaripe'],
  [2917904, 'Jandaíra'],
  [2918001, 'Jequié'],
  [2918100, 'Jeremoabo'],
  [2918209, 'Jiquiriçá'],
  [2918308, 'Jitaúna'],
  [2918357, 'João Dourado'],
  [2918407, 'Juazeiro'],
  [2918456, 'Jucuruçu'],
  [2918506, 'Jussara'],
  [2918555, 'Jussari'],
  [2918605, 'Jussiape'],
  [2918704, 'Lafaiete Coutinho'],
  [2918753, 'Lagoa Real'],
  [2918803, 'Laje'],
  [2918902, 'Lajedão'],
  [2919009, 'Lajedinho'],
  [2919058, 'Lajedo do Tabocal'],
  [2919108, 'Lamarão'],
  [2919157, 'Lapão'],
  [2919207, 'Lauro de Freitas'],
  [2919306, 'Lençóis'],
  [2919405, 'Licínio de Almeida'],
  [2919504, 'Livramento de Nossa Senhora'],
  [2919553, 'Luís Eduardo Magalhães'],
  [2919603, 'Macajuba'],
  [2919702, 'Macarani'],
  [2919801, 'Macaúbas'],
  [2919900, 'Macururé'],
  [2919926, 'Madre de Deus'],
  [2919959, 'Maetinga'],
  [2920007, 'Maiquinique'],
  [2920106, 'Mairi'],
  [2920205, 'Malhada'],
  [2920304, 'Malhada de Pedras'],
  [2920403, 'Manoel Vitorino'],
  [2920452, 'Mansidão'],
  [2920502, 'Maracás'],
  [2920601, 'Maragogipe'],
  [2920700, 'Maraú'],
  [2920809, 'Marcionílio Souza'],
  [2920908, 'Mascote'],
  [2921005, 'Mata de São João'],
  [2921054, 'Matina'],
  [2921104, 'Medeiros Neto'],
  [2921203, 'Miguel Calmon'],
  [2921302, 'Milagres'],
  [2921401, 'Mirangaba'],
  [2921450, 'Mirante'],
  [2921500, 'Monte Santo'],
  [2921609, 'Morpará'],
  [2921708, 'Morro do Chapéu'],
  [2921807, 'Mortugaba'],
  [2921906, 'Mucugê'],
  [2922003, 'Mucuri'],
  [2922052, 'Mulungu do Morro'],
  [2922102, 'Mundo Novo'],
  [2922201, 'Muniz Ferreira'],
  [2922250, 'Muquém do São Francisco'],
  [2922300, 'Muritiba'],
  [2922409, 'Mutuípe'],
  [2922508, 'Nazaré'],
  [2922607, 'Nilo Peçanha'],
  [2922656, 'Nordestina'],
  [2922706, 'Nova Canaã'],
  [2922730, 'Nova Fátima'],
  [2922755, 'Nova Ibiá'],
  [2922805, 'Nova Itarana'],
  [2922854, 'Nova Redenção'],
  [2922904, 'Nova Soure'],
  [2923001, 'Nova Viçosa'],
  [2923035, 'Novo Horizonte'],
  [2923050, 'Novo Triunfo'],
  [2923100, 'Olindina'],
  [2923209, 'Oliveira dos Brejinhos'],
  [2923308, 'Ouriçangas'],
  [2923357, 'Ourolândia'],
  [2923407, 'Palmas de Monte Alto'],
  [2923506, 'Palmeiras'],
  [2923605, 'Paramirim'],
  [2923704, 'Paratinga'],
  [2923803, 'Paripiranga'],
  [2923902, 'Pau Brasil'],
  [2924009, 'Paulo Afonso'],
  [2924058, 'Pé de Serra'],
  [2924108, 'Pedrão'],
  [2924207, 'Pedro Alexandre'],
  [2924306, 'Piatã'],
  [2924405, 'Pilão Arcado'],
  [2924504, 'Pindaí'],
  [2924603, 'Pindobaçu'],
  [2924652, 'Pintadas'],
  [2924678, 'Piraí do Norte'],
  [2924702, 'Piripá'],
  [2924801, 'Piritiba'],
  [2924900, 'Planaltino'],
  [2925006, 'Planalto'],
  [2925105, 'Poções'],
  [2925204, 'Pojuca'],
  [2925253, 'Ponto Novo'],
  [2925303, 'Porto Seguro'],
  [2925402, 'Potiraguá'],
  [2925501, 'Prado'],
  [2925600, 'Presidente Dutra'],
  [2925709, 'Presidente Jânio Quadros'],
  [2925758, 'Presidente Tancredo Neves'],
  [2925808, 'Queimadas'],
  [2925907, 'Quijingue'],
  [2925931, 'Quixabeira'],
  [2925956, 'Rafael Jambeiro'],
  [2926004, 'Remanso'],
  [2926103, 'Retirolândia'],
  [2926202, 'Riachão das Neves'],
  [2926301, 'Riachão do Jacuípe'],
  [2926400, 'Riacho de Santana'],
  [2926509, 'Ribeira do Amparo'],
  [2926608, 'Ribeira do Pombal'],
  [2926657, 'Ribeirão do Largo'],
  [2926707, 'Rio de Contas'],
  [2926806, 'Rio do Antônio'],
  [2926905, 'Rio do Pires'],
  [2927002, 'Rio Real'],
  [2927101, 'Rodelas'],
  [2927200, 'Ruy Barbosa'],
  [2927309, 'Salinas da Margarida'],
  [2927408, 'Salvador'],
  [2927507, 'Santa Bárbara'],
  [2927606, 'Santa Brígida'],
  [2927705, 'Santa Cruz Cabrália'],
  [2927804, 'Santa Cruz da Vitória'],
  [2927903, 'Santa Inês'],
  [2928000, 'Santaluz'],
  [2928059, 'Santa Luzia'],
  [2928109, 'Santa Maria da Vitória'],
  [2928208, 'Santana'],
  [2928307, 'Santanópolis'],
  [2928406, 'Santa Rita de Cássia'],
  [2928505, 'Santa Terezinha'],
  [2928604, 'Santo Amaro'],
  [2928703, 'Santo Antônio de Jesus'],
  [2928802, 'Santo Estêvão'],
  [2928901, 'São Desidério'],
  [2928950, 'São Domingos'],
  [2929008, 'São Félix'],
  [2929057, 'São Félix do Coribe'],
  [2929107, 'São Felipe'],
  [2929206, 'São Francisco do Conde'],
  [2929255, 'São Gabriel'],
  [2929305, 'São Gonçalo dos Campos'],
  [2929354, 'São José da Vitória'],
  [2929370, 'São José do Jacuípe'],
  [2929404, 'São Miguel das Matas'],
  [2929503, 'São Sebastião do Passé'],
  [2929602, 'Sapeaçu'],
  [2929701, 'Sátiro Dias'],
  [2929750, 'Saubara'],
  [2929800, 'Saúde'],
  [2929909, 'Seabra'],
  [2930006, 'Sebastião Laranjeiras'],
  [2930105, 'Senhor do Bonfim'],
  [2930154, 'Serra do Ramalho'],
  [2930204, 'Sento Sé'],
  [2930303, 'Serra Dourada'],
  [2930402, 'Serra Preta'],
  [2930501, 'Serrinha'],
  [2930600, 'Serrolândia'],
  [2930709, 'Simões Filho'],
  [2930758, 'Sítio do Mato'],
  [2930766, 'Sítio do Quinto'],
  [2930774, 'Sobradinho'],
  [2930808, 'Souto Soares'],
  [2930907, 'Tabocas do Brejo Velho'],
  [2931004, 'Tanhaçu'],
  [2931053, 'Tanque Novo'],
  [2931103, 'Tanquinho'],
  [2931202, 'Taperoá'],
  [2931301, 'Tapiramutá'],
  [2931350, 'Teixeira de Freitas'],
  [2931400, 'Teodoro Sampaio'],
  [2931509, 'Teofilândia'],
  [2931608, 'Teolândia'],
  [2931707, 'Terra Nova'],
  [2931806, 'Tremedal'],
  [2931905, 'Tucano'],
  [2932002, 'Uauá'],
  [2932101, 'Ubaíra'],
  [2932200, 'Ubaitaba'],
  [2932309, 'Ubatã'],
  [2932408, 'Uibaí'],
  [2932457, 'Umburanas'],
  [2932507, 'Una'],
  [2932606, 'Urandi'],
  [2932705, 'Uruçuca'],
  [2932804, 'Utinga'],
  [2932903, 'Valença'],
  [2933000, 'Valente'],
  [2933059, 'Várzea da Roça'],
  [2933109, 'Várzea do Poço'],
  [2933158, 'Várzea Nova'],
  [2933174, 'Varzedo'],
  [2933208, 'Vera Cruz'],
  [2933257, 'Vereda'],
  [2933307, 'Vitória da Conquista'],
  [2933406, 'Wagner'],
  [2933455, 'Wanderley'],
  [2933505, 'Wenceslau Guimarães'],
  [2933604, 'Xique-Xique'],
  [3100104, 'Abadia dos Dourados'],
  [3100203, 'Abaeté'],
  [3100302, 'Abre Campo'],
  [3100401, 'Acaiaca'],
  [3100500, 'Açucena'],
  [3100609, 'Água Boa'],
  [3100708, 'Água Comprida'],
  [3100807, 'Aguanil'],
  [3100906, 'Águas Formosas'],
  [3101003, 'Águas Vermelhas'],
  [3101102, 'Aimorés'],
  [3101201, 'Aiuruoca'],
  [3101300, 'Alagoa'],
  [3101409, 'Albertina'],
  [3101508, 'Além Paraíba'],
  [3101607, 'Alfenas'],
  [3101631, 'Alfredo Vasconcelos'],
  [3101706, 'Almenara'],
  [3101805, 'Alpercata'],
  [3101904, 'Alpinópolis'],
  [3102001, 'Alterosa'],
  [3102050, 'Alto Caparaó'],
  [3102100, 'Alto Rio Doce'],
  [3102209, 'Alvarenga'],
  [3102308, 'Alvinópolis'],
  [3102407, 'Alvorada de Minas'],
  [3102506, 'Amparo do Serra'],
  [3102605, 'Andradas'],
  [3102704, 'Cachoeira de Pajeú'],
  [3102803, 'Andrelândia'],
  [3102852, 'Angelândia'],
  [3102902, 'Antônio Carlos'],
  [3103009, 'Antônio Dias'],
  [3103108, 'Antônio Prado de Minas'],
  [3103207, 'Araçaí'],
  [3103306, 'Aracitaba'],
  [3103405, 'Araçuaí'],
  [3103504, 'Araguari'],
  [3103603, 'Arantina'],
  [3103702, 'Araponga'],
  [3103751, 'Araporã'],
  [3103801, 'Arapuá'],
  [3103900, 'Araújos'],
  [3104007, 'Araxá'],
  [3104106, 'Arceburgo'],
  [3104205, 'Arcos'],
  [3104304, 'Areado'],
  [3104403, 'Argirita'],
  [3104452, 'Aricanduva'],
  [3104502, 'Arinos'],
  [3104601, 'Astolfo Dutra'],
  [3104700, 'Ataléia'],
  [3104809, 'Augusto de Lima'],
  [3104908, 'Baependi'],
  [3105004, 'Baldim'],
  [3105103, 'Bambuí'],
  [3105202, 'Bandeira'],
  [3105301, 'Bandeira do Sul'],
  [3105400, 'Barão de Cocais'],
  [3105509, 'Barão de Monte Alto'],
  [3105608, 'Barbacena'],
  [3105707, 'Barra Longa'],
  [3105905, 'Barroso'],
  [3106002, 'Bela Vista de Minas'],
  [3106101, 'Belmiro Braga'],
  [3106200, 'Belo Horizonte'],
  [3106309, 'Belo Oriente'],
  [3106408, 'Belo Vale'],
  [3106507, 'Berilo'],
  [3106606, 'Bertópolis'],
  [3106655, 'Berizal'],
  [3106705, 'Betim'],
  [3106804, 'Bias Fortes'],
  [3106903, 'Bicas'],
  [3107000, 'Biquinhas'],
  [3107109, 'Boa Esperança'],
  [3107208, 'Bocaina de Minas'],
  [3107307, 'Bocaiúva'],
  [3107406, 'Bom Despacho'],
  [3107505, 'Bom Jardim de Minas'],
  [3107604, 'Bom Jesus da Penha'],
  [3107703, 'Bom Jesus do Amparo'],
  [3107802, 'Bom Jesus do Galho'],
  [3107901, 'Bom Repouso'],
  [3108008, 'Bom Sucesso'],
  [3108107, 'Bonfim'],
  [3108206, 'Bonfinópolis de Minas'],
  [3108255, 'Bonito de Minas'],
  [3108305, 'Borda da Mata'],
  [3108404, 'Botelhos'],
  [3108503, 'Botumirim'],
  [3108552, 'Brasilândia de Minas'],
  [3108602, 'Brasília de Minas'],
  [3108701, 'Brás Pires'],
  [3108800, 'Braúnas'],
  [3108909, 'Brazópolis'],
  [3109006, 'Brumadinho'],
  [3109105, 'Bueno Brandão'],
  [3109204, 'Buenópolis'],
  [3109253, 'Bugre'],
  [3109303, 'Buritis'],
  [3109402, 'Buritizeiro'],
  [3109451, 'Cabeceira Grande'],
  [3109501, 'Cabo Verde'],
  [3109600, 'Cachoeira da Prata'],
  [3109709, 'Cachoeira de Minas'],
  [3109808, 'Cachoeira Dourada'],
  [3109907, 'Caetanópolis'],
  [3110004, 'Caeté'],
  [3110103, 'Caiana'],
  [3110202, 'Cajuri'],
  [3110301, 'Caldas'],
  [3110400, 'Camacho'],
  [3110509, 'Camanducaia'],
  [3110608, 'Cambuí'],
  [3110707, 'Cambuquira'],
  [3110806, 'Campanário'],
  [3110905, 'Campanha'],
  [3111002, 'Campestre'],
  [3111101, 'Campina Verde'],
  [3111150, 'Campo Azul'],
  [3111200, 'Campo Belo'],
  [3111309, 'Campo do Meio'],
  [3111408, 'Campo Florido'],
  [3111507, 'Campos Altos'],
  [3111606, 'Campos Gerais'],
  [3111705, 'Canaã'],
  [3111804, 'Canápolis'],
  [3111903, 'Cana Verde'],
  [3112000, 'Candeias'],
  [3112059, 'Cantagalo'],
  [3112109, 'Caparaó'],
  [3112208, 'Capela Nova'],
  [3112307, 'Capelinha'],
  [3112406, 'Capetinga'],
  [3112505, 'Capim Branco'],
  [3112604, 'Capinópolis'],
  [3112653, 'Capitão Andrade'],
  [3112703, 'Capitão Enéas'],
  [3112802, 'Capitólio'],
  [3112901, 'Caputira'],
  [3113008, 'Caraí'],
  [3113107, 'Caranaíba'],
  [3113206, 'Carandaí'],
  [3113305, 'Carangola'],
  [3113404, 'Caratinga'],
  [3113503, 'Carbonita'],
  [3113602, 'Careaçu'],
  [3113701, 'Carlos Chagas'],
  [3113800, 'Carmésia'],
  [3113909, 'Carmo da Cachoeira'],
  [3114006, 'Carmo da Mata'],
  [3114105, 'Carmo de Minas'],
  [3114204, 'Carmo do Cajuru'],
  [3114303, 'Carmo do Paranaíba'],
  [3114402, 'Carmo do Rio Claro'],
  [3114501, 'Carmópolis de Minas'],
  [3114550, 'Carneirinho'],
  [3114600, 'Carrancas'],
  [3114709, 'Carvalhópolis'],
  [3114808, 'Carvalhos'],
  [3114907, 'Casa Grande'],
  [3115003, 'Cascalho Rico'],
  [3115102, 'Cássia'],
  [3115201, 'Conceição da Barra de Minas'],
  [3115300, 'Cataguases'],
  [3115359, 'Catas Altas'],
  [3115409, 'Catas Altas da Noruega'],
  [3115458, 'Catuji'],
  [3115474, 'Catuti'],
  [3115508, 'Caxambu'],
  [3115607, 'Cedro do Abaeté'],
  [3115706, 'Central de Minas'],
  [3115805, 'Centralina'],
  [3115904, 'Chácara'],
  [3116001, 'Chalé'],
  [3116100, 'Chapada do Norte'],
  [3116159, 'Chapada Gaúcha'],
  [3116209, 'Chiador'],
  [3116308, 'Cipotânea'],
  [3116407, 'Claraval'],
  [3116506, 'Claro dos Poções'],
  [3116605, 'Cláudio'],
  [3116704, 'Coimbra'],
  [3116803, 'Coluna'],
  [3116902, 'Comendador Gomes'],
  [3117009, 'Comercinho'],
  [3117108, 'Conceição da Aparecida'],
  [3117207, 'Conceição das Pedras'],
  [3117306, 'Conceição das Alagoas'],
  [3117405, 'Conceição de Ipanema'],
  [3117504, 'Conceição do Mato Dentro'],
  [3117603, 'Conceição do Pará'],
  [3117702, 'Conceição do Rio Verde'],
  [3117801, 'Conceição dos Ouros'],
  [3117836, 'Cônego Marinho'],
  [3117876, 'Confins'],
  [3117900, 'Congonhal'],
  [3118007, 'Congonhas'],
  [3118106, 'Congonhas do Norte'],
  [3118205, 'Conquista'],
  [3118304, 'Conselheiro Lafaiete'],
  [3118403, 'Conselheiro Pena'],
  [3118502, 'Consolação'],
  [3118601, 'Contagem'],
  [3118700, 'Coqueiral'],
  [3118809, 'Coração de Jesus'],
  [3118908, 'Cordisburgo'],
  [3119005, 'Cordislândia'],
  [3119104, 'Corinto'],
  [3119203, 'Coroaci'],
  [3119302, 'Coromandel'],
  [3119401, 'Coronel Fabriciano'],
  [3119500, 'Coronel Murta'],
  [3119609, 'Coronel Pacheco'],
  [3119708, 'Coronel Xavier Chaves'],
  [3119807, 'Córrego Danta'],
  [3119906, 'Córrego do Bom Jesus'],
  [3119955, 'Córrego Fundo'],
  [3120003, 'Córrego Novo'],
  [3120102, 'Couto de Magalhães de Minas'],
  [3120151, 'Crisólita'],
  [3120201, 'Cristais'],
  [3120300, 'Cristália'],
  [3120409, 'Cristiano Otoni'],
  [3120508, 'Cristina'],
  [3120607, 'Crucilândia'],
  [3120706, 'Cruzeiro da Fortaleza'],
  [3120805, 'Cruzília'],
  [3120839, 'Cuparaque'],
  [3120870, 'Curral de Dentro'],
  [3120904, 'Curvelo'],
  [3121001, 'Datas'],
  [3121100, 'Delfim Moreira'],
  [3121209, 'Delfinópolis'],
  [3121258, 'Delta'],
  [3121308, 'Descoberto'],
  [3121407, 'Desterro de Entre Rios'],
  [3121506, 'Desterro do Melo'],
  [3121605, 'Diamantina'],
  [3121704, 'Diogo de Vasconcelos'],
  [3121803, 'Dionísio'],
  [3121902, 'Divinésia'],
  [3122009, 'Divino'],
  [3122108, 'Divino das Laranjeiras'],
  [3122207, 'Divinolândia de Minas'],
  [3122306, 'Divinópolis'],
  [3122355, 'Divisa Alegre'],
  [3122405, 'Divisa Nova'],
  [3122454, 'Divisópolis'],
  [3122470, 'Dom Bosco'],
  [3122504, 'Dom Cavati'],
  [3122603, 'Dom Joaquim'],
  [3122702, 'Dom Silvério'],
  [3122801, 'Dom Viçoso'],
  [3122900, 'Dona Euzébia'],
  [3123007, 'Dores de Campos'],
  [3123106, 'Dores de Guanhães'],
  [3123205, 'Dores do Indaiá'],
  [3123304, 'Dores do Turvo'],
  [3123403, 'Doresópolis'],
  [3123502, 'Douradoquara'],
  [3123528, 'Durandé'],
  [3123601, 'Elói Mendes'],
  [3123700, 'Engenheiro Caldas'],
  [3123809, 'Engenheiro Navarro'],
  [3123858, 'Entre Folhas'],
  [3123908, 'Entre Rios de Minas'],
  [3124005, 'Ervália'],
  [3124104, 'Esmeraldas'],
  [3124203, 'Espera Feliz'],
  [3124302, 'Espinosa'],
  [3124401, 'Espírito Santo do Dourado'],
  [3124500, 'Estiva'],
  [3124609, 'Estrela Dalva'],
  [3124708, 'Estrela do Indaiá'],
  [3124807, 'Estrela do Sul'],
  [3124906, 'Eugenópolis'],
  [3125002, 'Ewbank da Câmara'],
  [3125101, 'Extrema'],
  [3125200, 'Fama'],
  [3125309, 'Faria Lemos'],
  [3125408, 'Felício dos Santos'],
  [3125507, 'São Gonçalo do Rio Preto'],
  [3125606, 'Felisburgo'],
  [3125705, 'Felixlândia'],
  [3125804, 'Fernandes Tourinho'],
  [3125903, 'Ferros'],
  [3125952, 'Fervedouro'],
  [3126000, 'Florestal'],
  [3126109, 'Formiga'],
  [3126208, 'Formoso'],
  [3126307, 'Fortaleza de Minas'],
  [3126406, 'Fortuna de Minas'],
  [3126505, 'Francisco Badaró'],
  [3126604, 'Francisco Dumont'],
  [3126703, 'Francisco Sá'],
  [3126752, 'Franciscópolis'],
  [3126802, 'Frei Gaspar'],
  [3126901, 'Frei Inocêncio'],
  [3126950, 'Frei Lagonegro'],
  [3127008, 'Fronteira'],
  [3127057, 'Fronteira dos Vales'],
  [3127073, 'Fruta de Leite'],
  [3127107, 'Frutal'],
  [3127206, 'Funilândia'],
  [3127305, 'Galiléia'],
  [3127339, 'Gameleiras'],
  [3127354, 'Glaucilândia'],
  [3127370, 'Goiabeira'],
  [3127388, 'Goianá'],
  [3127404, 'Gonçalves'],
  [3127503, 'Gonzaga'],
  [3127602, 'Gouveia'],
  [3127701, 'Governador Valadares'],
  [3127800, 'Grão Mogol'],
  [3127909, 'Grupiara'],
  [3128006, 'Guanhães'],
  [3128105, 'Guapé'],
  [3128204, 'Guaraciaba'],
  [3128253, 'Guaraciama'],
  [3128303, 'Guaranésia'],
  [3128402, 'Guarani'],
  [3128501, 'Guarará'],
  [3128600, 'Guarda-Mor'],
  [3128709, 'Guaxupé'],
  [3128808, 'Guidoval'],
  [3128907, 'Guimarânia'],
  [3129004, 'Guiricema'],
  [3129103, 'Gurinhatã'],
  [3129202, 'Heliodora'],
  [3129301, 'Iapu'],
  [3129400, 'Ibertioga'],
  [3129509, 'Ibiá'],
  [3129608, 'Ibiaí'],
  [3129657, 'Ibiracatu'],
  [3129707, 'Ibiraci'],
  [3129806, 'Ibirité'],
  [3129905, 'Ibitiúra de Minas'],
  [3130002, 'Ibituruna'],
  [3130051, 'Icaraí de Minas'],
  [3130101, 'Igarapé'],
  [3130200, 'Igaratinga'],
  [3130309, 'Iguatama'],
  [3130408, 'Ijaci'],
  [3130507, 'Ilicínea'],
  [3130556, 'Imbé de Minas'],
  [3130606, 'Inconfidentes'],
  [3130655, 'Indaiabira'],
  [3130705, 'Indianópolis'],
  [3130804, 'Ingaí'],
  [3130903, 'Inhapim'],
  [3131000, 'Inhaúma'],
  [3131109, 'Inimutaba'],
  [3131158, 'Ipaba'],
  [3131208, 'Ipanema'],
  [3131307, 'Ipatinga'],
  [3131406, 'Ipiaçu'],
  [3131505, 'Ipuiúna'],
  [3131604, 'Iraí de Minas'],
  [3131703, 'Itabira'],
  [3131802, 'Itabirinha'],
  [3131901, 'Itabirito'],
  [3132008, 'Itacambira'],
  [3132107, 'Itacarambi'],
  [3132206, 'Itaguara'],
  [3132305, 'Itaipé'],
  [3132404, 'Itajubá'],
  [3132503, 'Itamarandiba'],
  [3132602, 'Itamarati de Minas'],
  [3132701, 'Itambacuri'],
  [3132800, 'Itambé do Mato Dentro'],
  [3132909, 'Itamogi'],
  [3133006, 'Itamonte'],
  [3133105, 'Itanhandu'],
  [3133204, 'Itanhomi'],
  [3133303, 'Itaobim'],
  [3133402, 'Itapagipe'],
  [3133501, 'Itapecerica'],
  [3133600, 'Itapeva'],
  [3133709, 'Itatiaiuçu'],
  [3133758, 'Itaú de Minas'],
  [3133808, 'Itaúna'],
  [3133907, 'Itaverava'],
  [3134004, 'Itinga'],
  [3134103, 'Itueta'],
  [3134202, 'Ituiutaba'],
  [3134301, 'Itumirim'],
  [3134400, 'Iturama'],
  [3134509, 'Itutinga'],
  [3134608, 'Jaboticatubas'],
  [3134707, 'Jacinto'],
  [3134806, 'Jacuí'],
  [3134905, 'Jacutinga'],
  [3135001, 'Jaguaraçu'],
  [3135050, 'Jaíba'],
  [3135076, 'Jampruca'],
  [3135100, 'Janaúba'],
  [3135209, 'Januária'],
  [3135308, 'Japaraíba'],
  [3135357, 'Japonvar'],
  [3135407, 'Jeceaba'],
  [3135456, 'Jenipapo de Minas'],
  [3135506, 'Jequeri'],
  [3135605, 'Jequitaí'],
  [3135704, 'Jequitibá'],
  [3135803, 'Jequitinhonha'],
  [3135902, 'Jesuânia'],
  [3136009, 'Joaíma'],
  [3136108, 'Joanésia'],
  [3136207, 'João Monlevade'],
  [3136306, 'João Pinheiro'],
  [3136405, 'Joaquim Felício'],
  [3136504, 'Jordânia'],
  [3136520, 'José Gonçalves de Minas'],
  [3136553, 'José Raydan'],
  [3136579, 'Josenópolis'],
  [3136603, 'Nova União'],
  [3136652, 'Juatuba'],
  [3136702, 'Juiz de Fora'],
  [3136801, 'Juramento'],
  [3136900, 'Juruaia'],
  [3136959, 'Juvenília'],
  [3137007, 'Ladainha'],
  [3137106, 'Lagamar'],
  [3137205, 'Lagoa da Prata'],
  [3137304, 'Lagoa dos Patos'],
  [3137403, 'Lagoa Dourada'],
  [3137502, 'Lagoa Formosa'],
  [3137536, 'Lagoa Grande'],
  [3137601, 'Lagoa Santa'],
  [3137700, 'Lajinha'],
  [3137809, 'Lambari'],
  [3137908, 'Lamim'],
  [3138005, 'Laranjal'],
  [3138104, 'Lassance'],
  [3138203, 'Lavras'],
  [3138302, 'Leandro Ferreira'],
  [3138351, 'Leme do Prado'],
  [3138401, 'Leopoldina'],
  [3138500, 'Liberdade'],
  [3138609, 'Lima Duarte'],
  [3138625, 'Limeira do Oeste'],
  [3138658, 'Lontra'],
  [3138674, 'Luisburgo'],
  [3138682, 'Luislândia'],
  [3138708, 'Luminárias'],
  [3138807, 'Luz'],
  [3138906, 'Machacalis'],
  [3139003, 'Machado'],
  [3139102, 'Madre de Deus de Minas'],
  [3139201, 'Malacacheta'],
  [3139250, 'Mamonas'],
  [3139300, 'Manga'],
  [3139409, 'Manhuaçu'],
  [3139508, 'Manhumirim'],
  [3139607, 'Mantena'],
  [3139706, 'Maravilhas'],
  [3139805, 'Mar de Espanha'],
  [3139904, 'Maria da Fé'],
  [3140001, 'Mariana'],
  [3140100, 'Marilac'],
  [3140159, 'Mário Campos'],
  [3140209, 'Maripá de Minas'],
  [3140308, 'Marliéria'],
  [3140407, 'Marmelópolis'],
  [3140506, 'Martinho Campos'],
  [3140530, 'Martins Soares'],
  [3140555, 'Mata Verde'],
  [3140605, 'Materlândia'],
  [3140704, 'Mateus Leme'],
  [3140803, 'Matias Barbosa'],
  [3140852, 'Matias Cardoso'],
  [3140902, 'Matipó'],
  [3141009, 'Mato Verde'],
  [3141108, 'Matozinhos'],
  [3141207, 'Matutina'],
  [3141306, 'Medeiros'],
  [3141405, 'Medina'],
  [3141504, 'Mendes Pimentel'],
  [3141603, 'Mercês'],
  [3141702, 'Mesquita'],
  [3141801, 'Minas Novas'],
  [3141900, 'Minduri'],
  [3142007, 'Mirabela'],
  [3142106, 'Miradouro'],
  [3142205, 'Miraí'],
  [3142254, 'Miravânia'],
  [3142304, 'Moeda'],
  [3142403, 'Moema'],
  [3142502, 'Monjolos'],
  [3142601, 'Monsenhor Paulo'],
  [3142700, 'Montalvânia'],
  [3142809, 'Monte Alegre de Minas'],
  [3142908, 'Monte Azul'],
  [3143005, 'Monte Belo'],
  [3143104, 'Monte Carmelo'],
  [3143153, 'Monte Formoso'],
  [3143203, 'Monte Santo de Minas'],
  [3143302, 'Montes Claros'],
  [3143401, 'Monte Sião'],
  [3143450, 'Montezuma'],
  [3143500, 'Morada Nova de Minas'],
  [3143609, 'Morro da Garça'],
  [3143708, 'Morro do Pilar'],
  [3143807, 'Munhoz'],
  [3143906, 'Muriaé'],
  [3144003, 'Mutum'],
  [3144102, 'Muzambinho'],
  [3144201, 'Nacip Raydan'],
  [3144300, 'Nanuque'],
  [3144359, 'Naque'],
  [3144375, 'Natalândia'],
  [3144409, 'Natércia'],
  [3144508, 'Nazareno'],
  [3144607, 'Nepomuceno'],
  [3144656, 'Ninheira'],
  [3144672, 'Nova Belém'],
  [3144706, 'Nova Era'],
  [3144805, 'Nova Lima'],
  [3144904, 'Nova Módica'],
  [3145000, 'Nova Ponte'],
  [3145059, 'Nova Porteirinha'],
  [3145109, 'Nova Resende'],
  [3145208, 'Nova Serrana'],
  [3145307, 'Novo Cruzeiro'],
  [3145356, 'Novo Oriente de Minas'],
  [3145372, 'Novorizonte'],
  [3145406, 'Olaria'],
  [3145455, "Olhos-d'Água"],
  [3145505, 'Olímpio Noronha'],
  [3145604, 'Oliveira'],
  [3145703, 'Oliveira Fortes'],
  [3145802, 'Onça de Pitangui'],
  [3145851, 'Oratórios'],
  [3145877, 'Orizânia'],
  [3145901, 'Ouro Branco'],
  [3146008, 'Ouro Fino'],
  [3146107, 'Ouro Preto'],
  [3146206, 'Ouro Verde de Minas'],
  [3146255, 'Padre Carvalho'],
  [3146305, 'Padre Paraíso'],
  [3146404, 'Paineiras'],
  [3146503, 'Pains'],
  [3146552, 'Pai Pedro'],
  [3146602, 'Paiva'],
  [3146701, 'Palma'],
  [3146750, 'Palmópolis'],
  [3146909, 'Papagaios'],
  [3147006, 'Paracatu'],
  [3147105, 'Pará de Minas'],
  [3147204, 'Paraguaçu'],
  [3147303, 'Paraisópolis'],
  [3147402, 'Paraopeba'],
  [3147501, 'Passabém'],
  [3147600, 'Passa Quatro'],
  [3147709, 'Passa Tempo'],
  [3147808, 'Passa Vinte'],
  [3147907, 'Passos'],
  [3147956, 'Patis'],
  [3148004, 'Patos de Minas'],
  [3148103, 'Patrocínio'],
  [3148202, 'Patrocínio do Muriaé'],
  [3148301, 'Paula Cândido'],
  [3148400, 'Paulistas'],
  [3148509, 'Pavão'],
  [3148608, 'Peçanha'],
  [3148707, 'Pedra Azul'],
  [3148756, 'Pedra Bonita'],
  [3148806, 'Pedra do Anta'],
  [3148905, 'Pedra do Indaiá'],
  [3149002, 'Pedra Dourada'],
  [3149101, 'Pedralva'],
  [3149150, 'Pedras de Maria da Cruz'],
  [3149200, 'Pedrinópolis'],
  [3149309, 'Pedro Leopoldo'],
  [3149408, 'Pedro Teixeira'],
  [3149507, 'Pequeri'],
  [3149606, 'Pequi'],
  [3149705, 'Perdigão'],
  [3149804, 'Perdizes'],
  [3149903, 'Perdões'],
  [3149952, 'Periquito'],
  [3150000, 'Pescador'],
  [3150109, 'Piau'],
  [3150158, 'Piedade de Caratinga'],
  [3150208, 'Piedade de Ponte Nova'],
  [3150307, 'Piedade do Rio Grande'],
  [3150406, 'Piedade dos Gerais'],
  [3150505, 'Pimenta'],
  [3150539, "Pingo-d'Água"],
  [3150570, 'Pintópolis'],
  [3150604, 'Piracema'],
  [3150703, 'Pirajuba'],
  [3150802, 'Piranga'],
  [3150901, 'Piranguçu'],
  [3151008, 'Piranguinho'],
  [3151107, 'Pirapetinga'],
  [3151206, 'Pirapora'],
  [3151305, 'Piraúba'],
  [3151404, 'Pitangui'],
  [3151503, 'Piumhi'],
  [3151602, 'Planura'],
  [3151701, 'Poço Fundo'],
  [3151800, 'Poços de Caldas'],
  [3151909, 'Pocrane'],
  [3152006, 'Pompéu'],
  [3152105, 'Ponte Nova'],
  [3152131, 'Ponto Chique'],
  [3152170, 'Ponto dos Volantes'],
  [3152204, 'Porteirinha'],
  [3152303, 'Porto Firme'],
  [3152402, 'Poté'],
  [3152501, 'Pouso Alegre'],
  [3152600, 'Pouso Alto'],
  [3152709, 'Prados'],
  [3152808, 'Prata'],
  [3152907, 'Pratápolis'],
  [3153004, 'Pratinha'],
  [3153103, 'Presidente Bernardes'],
  [3153202, 'Presidente Juscelino'],
  [3153301, 'Presidente Kubitschek'],
  [3153400, 'Presidente Olegário'],
  [3153509, 'Alto Jequitibá'],
  [3153608, 'Prudente de Morais'],
  [3153707, 'Quartel Geral'],
  [3153806, 'Queluzito'],
  [3153905, 'Raposos'],
  [3154002, 'Raul Soares'],
  [3154101, 'Recreio'],
  [3154150, 'Reduto'],
  [3154200, 'Resende Costa'],
  [3154309, 'Resplendor'],
  [3154408, 'Ressaquinha'],
  [3154457, 'Riachinho'],
  [3154507, 'Riacho dos Machados'],
  [3154606, 'Ribeirão das Neves'],
  [3154705, 'Ribeirão Vermelho'],
  [3154804, 'Rio Acima'],
  [3154903, 'Rio Casca'],
  [3155009, 'Rio Doce'],
  [3155108, 'Rio do Prado'],
  [3155207, 'Rio Espera'],
  [3155306, 'Rio Manso'],
  [3155405, 'Rio Novo'],
  [3155504, 'Rio Paranaíba'],
  [3155603, 'Rio Pardo de Minas'],
  [3155702, 'Rio Piracicaba'],
  [3155801, 'Rio Pomba'],
  [3155900, 'Rio Preto'],
  [3156007, 'Rio Vermelho'],
  [3156106, 'Ritápolis'],
  [3156205, 'Rochedo de Minas'],
  [3156304, 'Rodeiro'],
  [3156403, 'Romaria'],
  [3156452, 'Rosário da Limeira'],
  [3156502, 'Rubelita'],
  [3156601, 'Rubim'],
  [3156700, 'Sabará'],
  [3156809, 'Sabinópolis'],
  [3156908, 'Sacramento'],
  [3157005, 'Salinas'],
  [3157104, 'Salto da Divisa'],
  [3157203, 'Santa Bárbara'],
  [3157252, 'Santa Bárbara do Leste'],
  [3157278, 'Santa Bárbara do Monte Verde'],
  [3157302, 'Santa Bárbara do Tugúrio'],
  [3157336, 'Santa Cruz de Minas'],
  [3157377, 'Santa Cruz de Salinas'],
  [3157401, 'Santa Cruz do Escalvado'],
  [3157500, 'Santa Efigênia de Minas'],
  [3157609, 'Santa Fé de Minas'],
  [3157658, 'Santa Helena de Minas'],
  [3157708, 'Santa Juliana'],
  [3157807, 'Santa Luzia'],
  [3157906, 'Santa Margarida'],
  [3158003, 'Santa Maria de Itabira'],
  [3158102, 'Santa Maria do Salto'],
  [3158201, 'Santa Maria do Suaçuí'],
  [3158300, 'Santana da Vargem'],
  [3158409, 'Santana de Cataguases'],
  [3158508, 'Santana de Pirapama'],
  [3158607, 'Santana do Deserto'],
  [3158706, 'Santana do Garambéu'],
  [3158805, 'Santana do Jacaré'],
  [3158904, 'Santana do Manhuaçu'],
  [3158953, 'Santana do Paraíso'],
  [3159001, 'Santana do Riacho'],
  [3159100, 'Santana dos Montes'],
  [3159209, 'Santa Rita de Caldas'],
  [3159308, 'Santa Rita de Jacutinga'],
  [3159357, 'Santa Rita de Minas'],
  [3159407, 'Santa Rita de Ibitipoca'],
  [3159506, 'Santa Rita do Itueto'],
  [3159605, 'Santa Rita do Sapucaí'],
  [3159704, 'Santa Rosa da Serra'],
  [3159803, 'Santa Vitória'],
  [3159902, 'Santo Antônio do Amparo'],
  [3160009, 'Santo Antônio do Aventureiro'],
  [3160108, 'Santo Antônio do Grama'],
  [3160207, 'Santo Antônio do Itambé'],
  [3160306, 'Santo Antônio do Jacinto'],
  [3160405, 'Santo Antônio do Monte'],
  [3160454, 'Santo Antônio do Retiro'],
  [3160504, 'Santo Antônio do Rio Abaixo'],
  [3160603, 'Santo Hipólito'],
  [3160702, 'Santos Dumont'],
  [3160801, 'São Bento Abade'],
  [3160900, 'São Brás do Suaçuí'],
  [3160959, 'São Domingos das Dores'],
  [3161007, 'São Domingos do Prata'],
  [3161056, 'São Félix de Minas'],
  [3161106, 'São Francisco'],
  [3161205, 'São Francisco de Paula'],
  [3161304, 'São Francisco de Sales'],
  [3161403, 'São Francisco do Glória'],
  [3161502, 'São Geraldo'],
  [3161601, 'São Geraldo da Piedade'],
  [3161650, 'São Geraldo do Baixio'],
  [3161700, 'São Gonçalo do Abaeté'],
  [3161809, 'São Gonçalo do Pará'],
  [3161908, 'São Gonçalo do Rio Abaixo'],
  [3162005, 'São Gonçalo do Sapucaí'],
  [3162104, 'São Gotardo'],
  [3162203, 'São João Batista do Glória'],
  [3162252, 'São João da Lagoa'],
  [3162302, 'São João da Mata'],
  [3162401, 'São João da Ponte'],
  [3162450, 'São João das Missões'],
  [3162500, 'São João del Rei'],
  [3162559, 'São João do Manhuaçu'],
  [3162575, 'São João do Manteninha'],
  [3162609, 'São João do Oriente'],
  [3162658, 'São João do Pacuí'],
  [3162708, 'São João do Paraíso'],
  [3162807, 'São João Evangelista'],
  [3162906, 'São João Nepomuceno'],
  [3162922, 'São Joaquim de Bicas'],
  [3162948, 'São José da Barra'],
  [3162955, 'São José da Lapa'],
  [3163003, 'São José da Safira'],
  [3163102, 'São José da Varginha'],
  [3163201, 'São José do Alegre'],
  [3163300, 'São José do Divino'],
  [3163409, 'São José do Goiabal'],
  [3163508, 'São José do Jacuri'],
  [3163607, 'São José do Mantimento'],
  [3163706, 'São Lourenço'],
  [3163805, 'São Miguel do Anta'],
  [3163904, 'São Pedro da União'],
  [3164001, 'São Pedro dos Ferros'],
  [3164100, 'São Pedro do Suaçuí'],
  [3164209, 'São Romão'],
  [3164308, 'São Roque de Minas'],
  [3164407, 'São Sebastião da Bela Vista'],
  [3164431, 'São Sebastião da Vargem Alegre'],
  [3164472, 'São Sebastião do Anta'],
  [3164506, 'São Sebastião do Maranhão'],
  [3164605, 'São Sebastião do Oeste'],
  [3164704, 'São Sebastião do Paraíso'],
  [3164803, 'São Sebastião do Rio Preto'],
  [3164902, 'São Sebastião do Rio Verde'],
  [3165008, 'São Tiago'],
  [3165107, 'São Tomás de Aquino'],
  [3165206, 'São Tomé das Letras'],
  [3165305, 'São Vicente de Minas'],
  [3165404, 'Sapucaí-Mirim'],
  [3165503, 'Sardoá'],
  [3165537, 'Sarzedo'],
  [3165552, 'Setubinha'],
  [3165560, 'Sem-Peixe'],
  [3165578, 'Senador Amaral'],
  [3165602, 'Senador Cortes'],
  [3165701, 'Senador Firmino'],
  [3165800, 'Senador José Bento'],
  [3165909, 'Senador Modestino Gonçalves'],
  [3166006, 'Senhora de Oliveira'],
  [3166105, 'Senhora do Porto'],
  [3166204, 'Senhora dos Remédios'],
  [3166303, 'Sericita'],
  [3166402, 'Seritinga'],
  [3166501, 'Serra Azul de Minas'],
  [3166600, 'Serra da Saudade'],
  [3166709, 'Serra dos Aimorés'],
  [3166808, 'Serra do Salitre'],
  [3166907, 'Serrania'],
  [3166956, 'Serranópolis de Minas'],
  [3167004, 'Serranos'],
  [3167103, 'Serro'],
  [3167202, 'Sete Lagoas'],
  [3167301, 'Silveirânia'],
  [3167400, 'Silvianópolis'],
  [3167509, 'Simão Pereira'],
  [3167608, 'Simonésia'],
  [3167707, 'Sobrália'],
  [3167806, 'Soledade de Minas'],
  [3167905, 'Tabuleiro'],
  [3168002, 'Taiobeiras'],
  [3168051, 'Taparuba'],
  [3168101, 'Tapira'],
  [3168200, 'Tapiraí'],
  [3168309, 'Taquaraçu de Minas'],
  [3168408, 'Tarumirim'],
  [3168507, 'Teixeiras'],
  [3168606, 'Teófilo Otoni'],
  [3168705, 'Timóteo'],
  [3168804, 'Tiradentes'],
  [3168903, 'Tiros'],
  [3169000, 'Tocantins'],
  [3169059, 'Tocos do Moji'],
  [3169109, 'Toledo'],
  [3169208, 'Tombos'],
  [3169307, 'Três Corações'],
  [3169356, 'Três Marias'],
  [3169406, 'Três Pontas'],
  [3169505, 'Tumiritinga'],
  [3169604, 'Tupaciguara'],
  [3169703, 'Turmalina'],
  [3169802, 'Turvolândia'],
  [3169901, 'Ubá'],
  [3170008, 'Ubaí'],
  [3170057, 'Ubaporanga'],
  [3170107, 'Uberaba'],
  [3170206, 'Uberlândia'],
  [3170305, 'Umburatiba'],
  [3170404, 'Unaí'],
  [3170438, 'União de Minas'],
  [3170479, 'Uruana de Minas'],
  [3170503, 'Urucânia'],
  [3170529, 'Urucuia'],
  [3170578, 'Vargem Alegre'],
  [3170602, 'Vargem Bonita'],
  [3170651, 'Vargem Grande do Rio Pardo'],
  [3170701, 'Varginha'],
  [3170750, 'Varjão de Minas'],
  [3170800, 'Várzea da Palma'],
  [3170909, 'Varzelândia'],
  [3171006, 'Vazante'],
  [3171030, 'Verdelândia'],
  [3171071, 'Veredinha'],
  [3171105, 'Veríssimo'],
  [3171154, 'Vermelho Novo'],
  [3171204, 'Vespasiano'],
  [3171303, 'Viçosa'],
  [3171402, 'Vieiras'],
  [3171501, 'Mathias Lobato'],
  [3171600, 'Virgem da Lapa'],
  [3171709, 'Virgínia'],
  [3171808, 'Virginópolis'],
  [3171907, 'Virgolândia'],
  [3172004, 'Visconde do Rio Branco'],
  [3172103, 'Volta Grande'],
  [3172202, 'Wenceslau Braz'],
  [3200102, 'Afonso Cláudio'],
  [3200136, 'Águia Branca'],
  [3200169, 'Água Doce do Norte'],
  [3200201, 'Alegre'],
  [3200300, 'Alfredo Chaves'],
  [3200359, 'Alto Rio Novo'],
  [3200409, 'Anchieta'],
  [3200508, 'Apiacá'],
  [3200607, 'Aracruz'],
  [3200706, 'Atílio Vivácqua'],
  [3200805, 'Baixo Guandu'],
  [3200904, 'Barra de São Francisco'],
  [3201001, 'Boa Esperança'],
  [3201100, 'Bom Jesus do Norte'],
  [3201159, 'Brejetuba'],
  [3201209, 'Cachoeiro de Itapemirim'],
  [3201308, 'Cariacica'],
  [3201407, 'Castelo'],
  [3201506, 'Colatina'],
  [3201605, 'Conceição da Barra'],
  [3201704, 'Conceição do Castelo'],
  [3201803, 'Divino de São Lourenço'],
  [3201902, 'Domingos Martins'],
  [3202009, 'Dores do Rio Preto'],
  [3202108, 'Ecoporanga'],
  [3202207, 'Fundão'],
  [3202256, 'Governador Lindenberg'],
  [3202306, 'Guaçuí'],
  [3202405, 'Guarapari'],
  [3202454, 'Ibatiba'],
  [3202504, 'Ibiraçu'],
  [3202553, 'Ibitirama'],
  [3202603, 'Iconha'],
  [3202652, 'Irupi'],
  [3202702, 'Itaguaçu'],
  [3202801, 'Itapemirim'],
  [3202900, 'Itarana'],
  [3203007, 'Iúna'],
  [3203056, 'Jaguaré'],
  [3203106, 'Jerônimo Monteiro'],
  [3203130, 'João Neiva'],
  [3203163, 'Laranja da Terra'],
  [3203205, 'Linhares'],
  [3203304, 'Mantenópolis'],
  [3203320, 'Marataízes'],
  [3203346, 'Marechal Floriano'],
  [3203353, 'Marilândia'],
  [3203403, 'Mimoso do Sul'],
  [3203502, 'Montanha'],
  [3203601, 'Mucurici'],
  [3203700, 'Muniz Freire'],
  [3203809, 'Muqui'],
  [3203908, 'Nova Venécia'],
  [3204005, 'Pancas'],
  [3204054, 'Pedro Canário'],
  [3204104, 'Pinheiros'],
  [3204203, 'Piúma'],
  [3204252, 'Ponto Belo'],
  [3204302, 'Presidente Kennedy'],
  [3204351, 'Rio Bananal'],
  [3204401, 'Rio Novo do Sul'],
  [3204500, 'Santa Leopoldina'],
  [3204559, 'Santa Maria de Jetibá'],
  [3204609, 'Santa Teresa'],
  [3204658, 'São Domingos do Norte'],
  [3204708, 'São Gabriel da Palha'],
  [3204807, 'São José do Calçado'],
  [3204906, 'São Mateus'],
  [3204955, 'São Roque do Canaã'],
  [3205002, 'Serra'],
  [3205010, 'Sooretama'],
  [3205036, 'Vargem Alta'],
  [3205069, 'Venda Nova do Imigrante'],
  [3205101, 'Viana'],
  [3205150, 'Vila Pavão'],
  [3205176, 'Vila Valério'],
  [3205200, 'Vila Velha'],
  [3205309, 'Vitória'],
  [3300100, 'Angra dos Reis'],
  [3300159, 'Aperibé'],
  [3300209, 'Araruama'],
  [3300225, 'Areal'],
  [3300233, 'Armação dos Búzios'],
  [3300258, 'Arraial do Cabo'],
  [3300308, 'Barra do Piraí'],
  [3300407, 'Barra Mansa'],
  [3300456, 'Belford Roxo'],
  [3300506, 'Bom Jardim'],
  [3300605, 'Bom Jesus do Itabapoana'],
  [3300704, 'Cabo Frio'],
  [3300803, 'Cachoeiras de Macacu'],
  [3300902, 'Cambuci'],
  [3300936, 'Carapebus'],
  [3300951, 'Comendador Levy Gasparian'],
  [3301009, 'Campos dos Goytacazes'],
  [3301108, 'Cantagalo'],
  [3301157, 'Cardoso Moreira'],
  [3301207, 'Carmo'],
  [3301306, 'Casimiro de Abreu'],
  [3301405, 'Conceição de Macabu'],
  [3301504, 'Cordeiro'],
  [3301603, 'Duas Barras'],
  [3301702, 'Duque de Caxias'],
  [3301801, 'Engenheiro Paulo de Frontin'],
  [3301850, 'Guapimirim'],
  [3301876, 'Iguaba Grande'],
  [3301900, 'Itaboraí'],
  [3302007, 'Itaguaí'],
  [3302056, 'Italva'],
  [3302106, 'Itaocara'],
  [3302205, 'Itaperuna'],
  [3302254, 'Itatiaia'],
  [3302270, 'Japeri'],
  [3302304, 'Laje do Muriaé'],
  [3302403, 'Macaé'],
  [3302452, 'Macuco'],
  [3302502, 'Magé'],
  [3302601, 'Mangaratiba'],
  [3302700, 'Maricá'],
  [3302809, 'Mendes'],
  [3302858, 'Mesquita'],
  [3302908, 'Miguel Pereira'],
  [3303005, 'Miracema'],
  [3303104, 'Natividade'],
  [3303203, 'Nilópolis'],
  [3303302, 'Niterói'],
  [3303401, 'Nova Friburgo'],
  [3303500, 'Nova Iguaçu'],
  [3303609, 'Paracambi'],
  [3303708, 'Paraíba do Sul'],
  [3303807, 'Paraty'],
  [3303856, 'Paty do Alferes'],
  [3303906, 'Petrópolis'],
  [3303955, 'Pinheiral'],
  [3304003, 'Piraí'],
  [3304102, 'Porciúncula'],
  [3304110, 'Porto Real'],
  [3304128, 'Quatis'],
  [3304144, 'Queimados'],
  [3304151, 'Quissamã'],
  [3304201, 'Resende'],
  [3304300, 'Rio Bonito'],
  [3304409, 'Rio Claro'],
  [3304508, 'Rio das Flores'],
  [3304524, 'Rio das Ostras'],
  [3304557, 'Rio de Janeiro'],
  [3304607, 'Santa Maria Madalena'],
  [3304706, 'Santo Antônio de Pádua'],
  [3304755, 'São Francisco de Itabapoana'],
  [3304805, 'São Fidélis'],
  [3304904, 'São Gonçalo'],
  [3305000, 'São João da Barra'],
  [3305109, 'São João de Meriti'],
  [3305133, 'São José de Ubá'],
  [3305158, 'São José do Vale do Rio Preto'],
  [3305208, 'São Pedro da Aldeia'],
  [3305307, 'São Sebastião do Alto'],
  [3305406, 'Sapucaia'],
  [3305505, 'Saquarema'],
  [3305554, 'Seropédica'],
  [3305604, 'Silva Jardim'],
  [3305703, 'Sumidouro'],
  [3305752, 'Tanguá'],
  [3305802, 'Teresópolis'],
  [3305901, 'Trajano de Moraes'],
  [3306008, 'Três Rios'],
  [3306107, 'Valença'],
  [3306156, 'Varre-Sai'],
  [3306206, 'Vassouras'],
  [3306305, 'Volta Redonda'],
  [3500105, 'Adamantina'],
  [3500204, 'Adolfo'],
  [3500303, 'Aguaí'],
  [3500402, 'Águas da Prata'],
  [3500501, 'Águas de Lindóia'],
  [3500550, 'Águas de Santa Bárbara'],
  [3500600, 'Águas de São Pedro'],
  [3500709, 'Agudos'],
  [3500758, 'Alambari'],
  [3500808, 'Alfredo Marcondes'],
  [3500907, 'Altair'],
  [3501004, 'Altinópolis'],
  [3501103, 'Alto Alegre'],
  [3501152, 'Alumínio'],
  [3501202, 'Álvares Florence'],
  [3501301, 'Álvares Machado'],
  [3501400, 'Álvaro de Carvalho'],
  [3501509, 'Alvinlândia'],
  [3501608, 'Americana'],
  [3501707, 'Américo Brasiliense'],
  [3501806, 'Américo de Campos'],
  [3501905, 'Amparo'],
  [3502002, 'Analândia'],
  [3502101, 'Andradina'],
  [3502200, 'Angatuba'],
  [3502309, 'Anhembi'],
  [3502408, 'Anhumas'],
  [3502507, 'Aparecida'],
  [3502606, "Aparecida d'Oeste"],
  [3502705, 'Apiaí'],
  [3502754, 'Araçariguama'],
  [3502804, 'Araçatuba'],
  [3502903, 'Araçoiaba da Serra'],
  [3503000, 'Aramina'],
  [3503109, 'Arandu'],
  [3503158, 'Arapeí'],
  [3503208, 'Araraquara'],
  [3503307, 'Araras'],
  [3503356, 'Arco-Íris'],
  [3503406, 'Arealva'],
  [3503505, 'Areias'],
  [3503604, 'Areiópolis'],
  [3503703, 'Ariranha'],
  [3503802, 'Artur Nogueira'],
  [3503901, 'Arujá'],
  [3503950, 'Aspásia'],
  [3504008, 'Assis'],
  [3504107, 'Atibaia'],
  [3504206, 'Auriflama'],
  [3504305, 'Avaí'],
  [3504404, 'Avanhandava'],
  [3504503, 'Avaré'],
  [3504602, 'Bady Bassitt'],
  [3504701, 'Balbinos'],
  [3504800, 'Bálsamo'],
  [3504909, 'Bananal'],
  [3505005, 'Barão de Antonina'],
  [3505104, 'Barbosa'],
  [3505203, 'Bariri'],
  [3505302, 'Barra Bonita'],
  [3505351, 'Barra do Chapéu'],
  [3505401, 'Barra do Turvo'],
  [3505500, 'Barretos'],
  [3505609, 'Barrinha'],
  [3505708, 'Barueri'],
  [3505807, 'Bastos'],
  [3505906, 'Batatais'],
  [3506003, 'Bauru'],
  [3506102, 'Bebedouro'],
  [3506201, 'Bento de Abreu'],
  [3506300, 'Bernardino de Campos'],
  [3506359, 'Bertioga'],
  [3506409, 'Bilac'],
  [3506508, 'Birigui'],
  [3506607, 'Biritiba Mirim'],
  [3506706, 'Boa Esperança do Sul'],
  [3506805, 'Bocaina'],
  [3506904, 'Bofete'],
  [3507001, 'Boituva'],
  [3507100, 'Bom Jesus dos Perdões'],
  [3507159, 'Bom Sucesso de Itararé'],
  [3507209, 'Borá'],
  [3507308, 'Boracéia'],
  [3507407, 'Borborema'],
  [3507456, 'Borebi'],
  [3507506, 'Botucatu'],
  [3507605, 'Bragança Paulista'],
  [3507704, 'Braúna'],
  [3507753, 'Brejo Alegre'],
  [3507803, 'Brodowski'],
  [3507902, 'Brotas'],
  [3508009, 'Buri'],
  [3508108, 'Buritama'],
  [3508207, 'Buritizal'],
  [3508306, 'Cabrália Paulista'],
  [3508405, 'Cabreúva'],
  [3508504, 'Caçapava'],
  [3508603, 'Cachoeira Paulista'],
  [3508702, 'Caconde'],
  [3508801, 'Cafelândia'],
  [3508900, 'Caiabu'],
  [3509007, 'Caieiras'],
  [3509106, 'Caiuá'],
  [3509205, 'Cajamar'],
  [3509254, 'Cajati'],
  [3509304, 'Cajobi'],
  [3509403, 'Cajuru'],
  [3509452, 'Campina do Monte Alegre'],
  [3509502, 'Campinas'],
  [3509601, 'Campo Limpo Paulista'],
  [3509700, 'Campos do Jordão'],
  [3509809, 'Campos Novos Paulista'],
  [3509908, 'Cananéia'],
  [3509957, 'Canas'],
  [3510005, 'Cândido Mota'],
  [3510104, 'Cândido Rodrigues'],
  [3510153, 'Canitar'],
  [3510203, 'Capão Bonito'],
  [3510302, 'Capela do Alto'],
  [3510401, 'Capivari'],
  [3510500, 'Caraguatatuba'],
  [3510609, 'Carapicuíba'],
  [3510708, 'Cardoso'],
  [3510807, 'Casa Branca'],
  [3510906, 'Cássia dos Coqueiros'],
  [3511003, 'Castilho'],
  [3511102, 'Catanduva'],
  [3511201, 'Catiguá'],
  [3511300, 'Cedral'],
  [3511409, 'Cerqueira César'],
  [3511508, 'Cerquilho'],
  [3511607, 'Cesário Lange'],
  [3511706, 'Charqueada'],
  [3511904, 'Clementina'],
  [3512001, 'Colina'],
  [3512100, 'Colômbia'],
  [3512209, 'Conchal'],
  [3512308, 'Conchas'],
  [3512407, 'Cordeirópolis'],
  [3512506, 'Coroados'],
  [3512605, 'Coronel Macedo'],
  [3512704, 'Corumbataí'],
  [3512803, 'Cosmópolis'],
  [3512902, 'Cosmorama'],
  [3513009, 'Cotia'],
  [3513108, 'Cravinhos'],
  [3513207, 'Cristais Paulista'],
  [3513306, 'Cruzália'],
  [3513405, 'Cruzeiro'],
  [3513504, 'Cubatão'],
  [3513603, 'Cunha'],
  [3513702, 'Descalvado'],
  [3513801, 'Diadema'],
  [3513850, 'Dirce Reis'],
  [3513900, 'Divinolândia'],
  [3514007, 'Dobrada'],
  [3514106, 'Dois Córregos'],
  [3514205, 'Dolcinópolis'],
  [3514304, 'Dourado'],
  [3514403, 'Dracena'],
  [3514502, 'Duartina'],
  [3514601, 'Dumont'],
  [3514700, 'Echaporã'],
  [3514809, 'Eldorado'],
  [3514908, 'Elias Fausto'],
  [3514924, 'Elisiário'],
  [3514957, 'Embaúba'],
  [3515004, 'Embu das Artes'],
  [3515103, 'Embu-Guaçu'],
  [3515129, 'Emilianópolis'],
  [3515152, 'Engenheiro Coelho'],
  [3515186, 'Espírito Santo do Pinhal'],
  [3515194, 'Espírito Santo do Turvo'],
  [3515202, "Estrela d'Oeste"],
  [3515301, 'Estrela do Norte'],
  [3515350, 'Euclides da Cunha Paulista'],
  [3515400, 'Fartura'],
  [3515509, 'Fernandópolis'],
  [3515608, 'Fernando Prestes'],
  [3515657, 'Fernão'],
  [3515707, 'Ferraz de Vasconcelos'],
  [3515806, 'Flora Rica'],
  [3515905, 'Floreal'],
  [3516002, 'Flórida Paulista'],
  [3516101, 'Florínea'],
  [3516200, 'Franca'],
  [3516309, 'Francisco Morato'],
  [3516408, 'Franco da Rocha'],
  [3516507, 'Gabriel Monteiro'],
  [3516606, 'Gália'],
  [3516705, 'Garça'],
  [3516804, 'Gastão Vidigal'],
  [3516853, 'Gavião Peixoto'],
  [3516903, 'General Salgado'],
  [3517000, 'Getulina'],
  [3517109, 'Glicério'],
  [3517208, 'Guaiçara'],
  [3517307, 'Guaimbê'],
  [3517406, 'Guaíra'],
  [3517505, 'Guapiaçu'],
  [3517604, 'Guapiara'],
  [3517703, 'Guará'],
  [3517802, 'Guaraçaí'],
  [3517901, 'Guaraci'],
  [3518008, "Guarani d'Oeste"],
  [3518107, 'Guarantã'],
  [3518206, 'Guararapes'],
  [3518305, 'Guararema'],
  [3518404, 'Guaratinguetá'],
  [3518503, 'Guareí'],
  [3518602, 'Guariba'],
  [3518701, 'Guarujá'],
  [3518800, 'Guarulhos'],
  [3518859, 'Guatapará'],
  [3518909, 'Guzolândia'],
  [3519006, 'Herculândia'],
  [3519055, 'Holambra'],
  [3519071, 'Hortolândia'],
  [3519105, 'Iacanga'],
  [3519204, 'Iacri'],
  [3519253, 'Iaras'],
  [3519303, 'Ibaté'],
  [3519402, 'Ibirá'],
  [3519501, 'Ibirarema'],
  [3519600, 'Ibitinga'],
  [3519709, 'Ibiúna'],
  [3519808, 'Icém'],
  [3519907, 'Iepê'],
  [3520004, 'Igaraçu do Tietê'],
  [3520103, 'Igarapava'],
  [3520202, 'Igaratá'],
  [3520301, 'Iguape'],
  [3520400, 'Ilhabela'],
  [3520426, 'Ilha Comprida'],
  [3520442, 'Ilha Solteira'],
  [3520509, 'Indaiatuba'],
  [3520608, 'Indiana'],
  [3520707, 'Indiaporã'],
  [3520806, 'Inúbia Paulista'],
  [3520905, 'Ipaussu'],
  [3521002, 'Iperó'],
  [3521101, 'Ipeúna'],
  [3521150, 'Ipiguá'],
  [3521200, 'Iporanga'],
  [3521309, 'Ipuã'],
  [3521408, 'Iracemápolis'],
  [3521507, 'Irapuã'],
  [3521606, 'Irapuru'],
  [3521705, 'Itaberá'],
  [3521804, 'Itaí'],
  [3521903, 'Itajobi'],
  [3522000, 'Itaju'],
  [3522109, 'Itanhaém'],
  [3522158, 'Itaoca'],
  [3522208, 'Itapecerica da Serra'],
  [3522307, 'Itapetininga'],
  [3522406, 'Itapeva'],
  [3522505, 'Itapevi'],
  [3522604, 'Itapira'],
  [3522653, 'Itapirapuã Paulista'],
  [3522703, 'Itápolis'],
  [3522802, 'Itaporanga'],
  [3522901, 'Itapuí'],
  [3523008, 'Itapura'],
  [3523107, 'Itaquaquecetuba'],
  [3523206, 'Itararé'],
  [3523305, 'Itariri'],
  [3523404, 'Itatiba'],
  [3523503, 'Itatinga'],
  [3523602, 'Itirapina'],
  [3523701, 'Itirapuã'],
  [3523800, 'Itobi'],
  [3523909, 'Itu'],
  [3524006, 'Itupeva'],
  [3524105, 'Ituverava'],
  [3524204, 'Jaborandi'],
  [3524303, 'Jaboticabal'],
  [3524402, 'Jacareí'],
  [3524501, 'Jaci'],
  [3524600, 'Jacupiranga'],
  [3524709, 'Jaguariúna'],
  [3524808, 'Jales'],
  [3524907, 'Jambeiro'],
  [3525003, 'Jandira'],
  [3525102, 'Jardinópolis'],
  [3525201, 'Jarinu'],
  [3525300, 'Jaú'],
  [3525409, 'Jeriquara'],
  [3525508, 'Joanópolis'],
  [3525607, 'João Ramalho'],
  [3525706, 'José Bonifácio'],
  [3525805, 'Júlio Mesquita'],
  [3525854, 'Jumirim'],
  [3525904, 'Jundiaí'],
  [3526001, 'Junqueirópolis'],
  [3526100, 'Juquiá'],
  [3526209, 'Juquitiba'],
  [3526308, 'Lagoinha'],
  [3526407, 'Laranjal Paulista'],
  [3526506, 'Lavínia'],
  [3526605, 'Lavrinhas'],
  [3526704, 'Leme'],
  [3526803, 'Lençóis Paulista'],
  [3526902, 'Limeira'],
  [3527009, 'Lindóia'],
  [3527108, 'Lins'],
  [3527207, 'Lorena'],
  [3527256, 'Lourdes'],
  [3527306, 'Louveira'],
  [3527405, 'Lucélia'],
  [3527504, 'Lucianópolis'],
  [3527603, 'Luís Antônio'],
  [3527702, 'Luiziânia'],
  [3527801, 'Lupércio'],
  [3527900, 'Lutécia'],
  [3528007, 'Macatuba'],
  [3528106, 'Macaubal'],
  [3528205, 'Macedônia'],
  [3528304, 'Magda'],
  [3528403, 'Mairinque'],
  [3528502, 'Mairiporã'],
  [3528601, 'Manduri'],
  [3528700, 'Marabá Paulista'],
  [3528809, 'Maracaí'],
  [3528858, 'Marapoama'],
  [3528908, 'Mariápolis'],
  [3529005, 'Marília'],
  [3529104, 'Marinópolis'],
  [3529203, 'Martinópolis'],
  [3529302, 'Matão'],
  [3529401, 'Mauá'],
  [3529500, 'Mendonça'],
  [3529609, 'Meridiano'],
  [3529658, 'Mesópolis'],
  [3529708, 'Miguelópolis'],
  [3529807, 'Mineiros do Tietê'],
  [3529906, 'Miracatu'],
  [3530003, 'Mira Estrela'],
  [3530102, 'Mirandópolis'],
  [3530201, 'Mirante do Paranapanema'],
  [3530300, 'Mirassol'],
  [3530409, 'Mirassolândia'],
  [3530508, 'Mococa'],
  [3530607, 'Mogi das Cruzes'],
  [3530706, 'Mogi Guaçu'],
  [3530805, 'Mogi Mirim'],
  [3530904, 'Mombuca'],
  [3531001, 'Monções'],
  [3531100, 'Mongaguá'],
  [3531209, 'Monte Alegre do Sul'],
  [3531308, 'Monte Alto'],
  [3531407, 'Monte Aprazível'],
  [3531506, 'Monte Azul Paulista'],
  [3531605, 'Monte Castelo'],
  [3531704, 'Monteiro Lobato'],
  [3531803, 'Monte Mor'],
  [3531902, 'Morro Agudo'],
  [3532009, 'Morungaba'],
  [3532058, 'Motuca'],
  [3532108, 'Murutinga do Sul'],
  [3532157, 'Nantes'],
  [3532207, 'Narandiba'],
  [3532306, 'Natividade da Serra'],
  [3532405, 'Nazaré Paulista'],
  [3532504, 'Neves Paulista'],
  [3532603, 'Nhandeara'],
  [3532702, 'Nipoã'],
  [3532801, 'Nova Aliança'],
  [3532827, 'Nova Campina'],
  [3532843, 'Nova Canaã Paulista'],
  [3532868, 'Nova Castilho'],
  [3532900, 'Nova Europa'],
  [3533007, 'Nova Granada'],
  [3533106, 'Nova Guataporanga'],
  [3533205, 'Nova Independência'],
  [3533254, 'Novais'],
  [3533304, 'Nova Luzitânia'],
  [3533403, 'Nova Odessa'],
  [3533502, 'Novo Horizonte'],
  [3533601, 'Nuporanga'],
  [3533700, 'Ocauçu'],
  [3533809, 'Óleo'],
  [3533908, 'Olímpia'],
  [3534005, 'Onda Verde'],
  [3534104, 'Oriente'],
  [3534203, 'Orindiúva'],
  [3534302, 'Orlândia'],
  [3534401, 'Osasco'],
  [3534500, 'Oscar Bressane'],
  [3534609, 'Osvaldo Cruz'],
  [3534708, 'Ourinhos'],
  [3534757, 'Ouroeste'],
  [3534807, 'Ouro Verde'],
  [3534906, 'Pacaembu'],
  [3535002, 'Palestina'],
  [3535101, 'Palmares Paulista'],
  [3535200, "Palmeira d'Oeste"],
  [3535309, 'Palmital'],
  [3535408, 'Panorama'],
  [3535507, 'Paraguaçu Paulista'],
  [3535606, 'Paraibuna'],
  [3535705, 'Paraíso'],
  [3535804, 'Paranapanema'],
  [3535903, 'Paranapuã'],
  [3536000, 'Parapuã'],
  [3536109, 'Pardinho'],
  [3536208, 'Pariquera-Açu'],
  [3536257, 'Parisi'],
  [3536307, 'Patrocínio Paulista'],
  [3536406, 'Paulicéia'],
  [3536505, 'Paulínia'],
  [3536570, 'Paulistânia'],
  [3536604, 'Paulo de Faria'],
  [3536703, 'Pederneiras'],
  [3536802, 'Pedra Bela'],
  [3536901, 'Pedranópolis'],
  [3537008, 'Pedregulho'],
  [3537107, 'Pedreira'],
  [3537156, 'Pedrinhas Paulista'],
  [3537206, 'Pedro de Toledo'],
  [3537305, 'Penápolis'],
  [3537404, 'Pereira Barreto'],
  [3537503, 'Pereiras'],
  [3537602, 'Peruíbe'],
  [3537701, 'Piacatu'],
  [3537800, 'Piedade'],
  [3537909, 'Pilar do Sul'],
  [3538006, 'Pindamonhangaba'],
  [3538105, 'Pindorama'],
  [3538204, 'Pinhalzinho'],
  [3538303, 'Piquerobi'],
  [3538501, 'Piquete'],
  [3538600, 'Piracaia'],
  [3538709, 'Piracicaba'],
  [3538808, 'Piraju'],
  [3538907, 'Pirajuí'],
  [3539004, 'Pirangi'],
  [3539103, 'Pirapora do Bom Jesus'],
  [3539202, 'Pirapozinho'],
  [3539301, 'Pirassununga'],
  [3539400, 'Piratininga'],
  [3539509, 'Pitangueiras'],
  [3539608, 'Planalto'],
  [3539707, 'Platina'],
  [3539806, 'Poá'],
  [3539905, 'Poloni'],
  [3540002, 'Pompéia'],
  [3540101, 'Pongaí'],
  [3540200, 'Pontal'],
  [3540259, 'Pontalinda'],
  [3540309, 'Pontes Gestal'],
  [3540408, 'Populina'],
  [3540507, 'Porangaba'],
  [3540606, 'Porto Feliz'],
  [3540705, 'Porto Ferreira'],
  [3540754, 'Potim'],
  [3540804, 'Potirendaba'],
  [3540853, 'Pracinha'],
  [3540903, 'Pradópolis'],
  [3541000, 'Praia Grande'],
  [3541059, 'Pratânia'],
  [3541109, 'Presidente Alves'],
  [3541208, 'Presidente Bernardes'],
  [3541307, 'Presidente Epitácio'],
  [3541406, 'Presidente Prudente'],
  [3541505, 'Presidente Venceslau'],
  [3541604, 'Promissão'],
  [3541653, 'Quadra'],
  [3541703, 'Quatá'],
  [3541802, 'Queiroz'],
  [3541901, 'Queluz'],
  [3542008, 'Quintana'],
  [3542107, 'Rafard'],
  [3542206, 'Rancharia'],
  [3542305, 'Redenção da Serra'],
  [3542404, 'Regente Feijó'],
  [3542503, 'Reginópolis'],
  [3542602, 'Registro'],
  [3542701, 'Restinga'],
  [3542800, 'Ribeira'],
  [3542909, 'Ribeirão Bonito'],
  [3543006, 'Ribeirão Branco'],
  [3543105, 'Ribeirão Corrente'],
  [3543204, 'Ribeirão do Sul'],
  [3543238, 'Ribeirão dos Índios'],
  [3543253, 'Ribeirão Grande'],
  [3543303, 'Ribeirão Pires'],
  [3543402, 'Ribeirão Preto'],
  [3543501, 'Riversul'],
  [3543600, 'Rifaina'],
  [3543709, 'Rincão'],
  [3543808, 'Rinópolis'],
  [3543907, 'Rio Claro'],
  [3544004, 'Rio das Pedras'],
  [3544103, 'Rio Grande da Serra'],
  [3544202, 'Riolândia'],
  [3544251, 'Rosana'],
  [3544301, 'Roseira'],
  [3544400, 'Rubiácea'],
  [3544509, 'Rubinéia'],
  [3544608, 'Sabino'],
  [3544707, 'Sagres'],
  [3544806, 'Sales'],
  [3544905, 'Sales Oliveira'],
  [3545001, 'Salesópolis'],
  [3545100, 'Salmourão'],
  [3545159, 'Saltinho'],
  [3545209, 'Salto'],
  [3545308, 'Salto de Pirapora'],
  [3545407, 'Salto Grande'],
  [3545506, 'Sandovalina'],
  [3545605, 'Santa Adélia'],
  [3545704, 'Santa Albertina'],
  [3545803, "Santa Bárbara d'Oeste"],
  [3546009, 'Santa Branca'],
  [3546108, "Santa Clara d'Oeste"],
  [3546207, 'Santa Cruz da Conceição'],
  [3546256, 'Santa Cruz da Esperança'],
  [3546306, 'Santa Cruz das Palmeiras'],
  [3546405, 'Santa Cruz do Rio Pardo'],
  [3546504, 'Santa Ernestina'],
  [3546603, 'Santa Fé do Sul'],
  [3546702, 'Santa Gertrudes'],
  [3546801, 'Santa Isabel'],
  [3546900, 'Santa Lúcia'],
  [3547007, 'Santa Maria da Serra'],
  [3547106, 'Santa Mercedes'],
  [3547205, 'Santana da Ponte Pensa'],
  [3547304, 'Santana de Parnaíba'],
  [3547403, "Santa Rita d'Oeste"],
  [3547502, 'Santa Rita do Passa Quatro'],
  [3547601, 'Santa Rosa de Viterbo'],
  [3547650, 'Santa Salete'],
  [3547700, 'Santo Anastácio'],
  [3547809, 'Santo André'],
  [3547908, 'Santo Antônio da Alegria'],
  [3548005, 'Santo Antônio de Posse'],
  [3548054, 'Santo Antônio do Aracanguá'],
  [3548104, 'Santo Antônio do Jardim'],
  [3548203, 'Santo Antônio do Pinhal'],
  [3548302, 'Santo Expedito'],
  [3548401, 'Santópolis do Aguapeí'],
  [3548500, 'Santos'],
  [3548609, 'São Bento do Sapucaí'],
  [3548708, 'São Bernardo do Campo'],
  [3548807, 'São Caetano do Sul'],
  [3548906, 'São Carlos'],
  [3549003, 'São Francisco'],
  [3549102, 'São João da Boa Vista'],
  [3549201, 'São João das Duas Pontes'],
  [3549250, 'São João de Iracema'],
  [3549300, "São João do Pau d'Alho"],
  [3549409, 'São Joaquim da Barra'],
  [3549508, 'São José da Bela Vista'],
  [3549607, 'São José do Barreiro'],
  [3549706, 'São José do Rio Pardo'],
  [3549805, 'São José do Rio Preto'],
  [3549904, 'São José dos Campos'],
  [3549953, 'São Lourenço da Serra'],
  [3550001, 'São Luiz do Paraitinga'],
  [3550100, 'São Manuel'],
  [3550209, 'São Miguel Arcanjo'],
  [3550308, 'São Paulo'],
  [3550407, 'São Pedro'],
  [3550506, 'São Pedro do Turvo'],
  [3550605, 'São Roque'],
  [3550704, 'São Sebastião'],
  [3550803, 'São Sebastião da Grama'],
  [3550902, 'São Simão'],
  [3551009, 'São Vicente'],
  [3551108, 'Sarapuí'],
  [3551207, 'Sarutaiá'],
  [3551306, 'Sebastianópolis do Sul'],
  [3551405, 'Serra Azul'],
  [3551504, 'Serrana'],
  [3551603, 'Serra Negra'],
  [3551702, 'Sertãozinho'],
  [3551801, 'Sete Barras'],
  [3551900, 'Severínia'],
  [3552007, 'Silveiras'],
  [3552106, 'Socorro'],
  [3552205, 'Sorocaba'],
  [3552304, 'Sud Mennucci'],
  [3552403, 'Sumaré'],
  [3552502, 'Suzano'],
  [3552551, 'Suzanápolis'],
  [3552601, 'Tabapuã'],
  [3552700, 'Tabatinga'],
  [3552809, 'Taboão da Serra'],
  [3552908, 'Taciba'],
  [3553005, 'Taguaí'],
  [3553104, 'Taiaçu'],
  [3553203, 'Taiúva'],
  [3553302, 'Tambaú'],
  [3553401, 'Tanabi'],
  [3553500, 'Tapiraí'],
  [3553609, 'Tapiratiba'],
  [3553658, 'Taquaral'],
  [3553708, 'Taquaritinga'],
  [3553807, 'Taquarituba'],
  [3553856, 'Taquarivaí'],
  [3553906, 'Tarabai'],
  [3553955, 'Tarumã'],
  [3554003, 'Tatuí'],
  [3554102, 'Taubaté'],
  [3554201, 'Tejupá'],
  [3554300, 'Teodoro Sampaio'],
  [3554409, 'Terra Roxa'],
  [3554508, 'Tietê'],
  [3554607, 'Timburi'],
  [3554656, 'Torre de Pedra'],
  [3554706, 'Torrinha'],
  [3554755, 'Trabiju'],
  [3554805, 'Tremembé'],
  [3554904, 'Três Fronteiras'],
  [3554953, 'Tuiuti'],
  [3555000, 'Tupã'],
  [3555109, 'Tupi Paulista'],
  [3555208, 'Turiúba'],
  [3555307, 'Turmalina'],
  [3555356, 'Ubarana'],
  [3555406, 'Ubatuba'],
  [3555505, 'Ubirajara'],
  [3555604, 'Uchoa'],
  [3555703, 'União Paulista'],
  [3555802, 'Urânia'],
  [3555901, 'Uru'],
  [3556008, 'Urupês'],
  [3556107, 'Valentim Gentil'],
  [3556206, 'Valinhos'],
  [3556305, 'Valparaíso'],
  [3556354, 'Vargem'],
  [3556404, 'Vargem Grande do Sul'],
  [3556453, 'Vargem Grande Paulista'],
  [3556503, 'Várzea Paulista'],
  [3556602, 'Vera Cruz'],
  [3556701, 'Vinhedo'],
  [3556800, 'Viradouro'],
  [3556909, 'Vista Alegre do Alto'],
  [3556958, 'Vitória Brasil'],
  [3557006, 'Votorantim'],
  [3557105, 'Votuporanga'],
  [3557154, 'Zacarias'],
  [3557204, 'Chavantes'],
  [3557303, 'Estiva Gerbi'],
  [4100103, 'Abatiá'],
  [4100202, 'Adrianópolis'],
  [4100301, 'Agudos do Sul'],
  [4100400, 'Almirante Tamandaré'],
  [4100459, 'Altamira do Paraná'],
  [4100509, 'Altônia'],
  [4100608, 'Alto Paraná'],
  [4100707, 'Alto Piquiri'],
  [4100806, 'Alvorada do Sul'],
  [4100905, 'Amaporã'],
  [4101002, 'Ampére'],
  [4101051, 'Anahy'],
  [4101101, 'Andirá'],
  [4101150, 'Ângulo'],
  [4101200, 'Antonina'],
  [4101309, 'Antônio Olinto'],
  [4101408, 'Apucarana'],
  [4101507, 'Arapongas'],
  [4101606, 'Arapoti'],
  [4101655, 'Arapuã'],
  [4101705, 'Araruna'],
  [4101804, 'Araucária'],
  [4101853, 'Ariranha do Ivaí'],
  [4101903, 'Assaí'],
  [4102000, 'Assis Chateaubriand'],
  [4102109, 'Astorga'],
  [4102208, 'Atalaia'],
  [4102307, 'Balsa Nova'],
  [4102406, 'Bandeirantes'],
  [4102505, 'Barbosa Ferraz'],
  [4102604, 'Barracão'],
  [4102703, 'Barra do Jacaré'],
  [4102752, 'Bela Vista da Caroba'],
  [4102802, 'Bela Vista do Paraíso'],
  [4102901, 'Bituruna'],
  [4103008, 'Boa Esperança'],
  [4103024, 'Boa Esperança do Iguaçu'],
  [4103040, 'Boa Ventura de São Roque'],
  [4103057, 'Boa Vista da Aparecida'],
  [4103107, 'Bocaiúva do Sul'],
  [4103156, 'Bom Jesus do Sul'],
  [4103206, 'Bom Sucesso'],
  [4103222, 'Bom Sucesso do Sul'],
  [4103305, 'Borrazópolis'],
  [4103354, 'Braganey'],
  [4103370, 'Brasilândia do Sul'],
  [4103404, 'Cafeara'],
  [4103453, 'Cafelândia'],
  [4103479, 'Cafezal do Sul'],
  [4103503, 'Califórnia'],
  [4103602, 'Cambará'],
  [4103701, 'Cambé'],
  [4103800, 'Cambira'],
  [4103909, 'Campina da Lagoa'],
  [4103958, 'Campina do Simão'],
  [4104006, 'Campina Grande do Sul'],
  [4104055, 'Campo Bonito'],
  [4104105, 'Campo do Tenente'],
  [4104204, 'Campo Largo'],
  [4104253, 'Campo Magro'],
  [4104303, 'Campo Mourão'],
  [4104402, 'Cândido de Abreu'],
  [4104428, 'Candói'],
  [4104451, 'Cantagalo'],
  [4104501, 'Capanema'],
  [4104600, 'Capitão Leônidas Marques'],
  [4104659, 'Carambeí'],
  [4104709, 'Carlópolis'],
  [4104808, 'Cascavel'],
  [4104907, 'Castro'],
  [4105003, 'Catanduvas'],
  [4105102, 'Centenário do Sul'],
  [4105201, 'Cerro Azul'],
  [4105300, 'Céu Azul'],
  [4105409, 'Chopinzinho'],
  [4105508, 'Cianorte'],
  [4105607, 'Cidade Gaúcha'],
  [4105706, 'Clevelândia'],
  [4105805, 'Colombo'],
  [4105904, 'Colorado'],
  [4106001, 'Congonhinhas'],
  [4106100, 'Conselheiro Mairinck'],
  [4106209, 'Contenda'],
  [4106308, 'Corbélia'],
  [4106407, 'Cornélio Procópio'],
  [4106456, 'Coronel Domingos Soares'],
  [4106506, 'Coronel Vivida'],
  [4106555, 'Corumbataí do Sul'],
  [4106571, 'Cruzeiro do Iguaçu'],
  [4106605, 'Cruzeiro do Oeste'],
  [4106704, 'Cruzeiro do Sul'],
  [4106803, 'Cruz Machado'],
  [4106852, 'Cruzmaltina'],
  [4106902, 'Curitiba'],
  [4107009, 'Curiúva'],
  [4107108, 'Diamante do Norte'],
  [4107124, 'Diamante do Sul'],
  [4107157, "Diamante D'Oeste"],
  [4107207, 'Dois Vizinhos'],
  [4107256, 'Douradina'],
  [4107306, 'Doutor Camargo'],
  [4107405, 'Enéas Marques'],
  [4107504, 'Engenheiro Beltrão'],
  [4107520, 'Esperança Nova'],
  [4107538, 'Entre Rios do Oeste'],
  [4107546, 'Espigão Alto do Iguaçu'],
  [4107553, 'Farol'],
  [4107603, 'Faxinal'],
  [4107652, 'Fazenda Rio Grande'],
  [4107702, 'Fênix'],
  [4107736, 'Fernandes Pinheiro'],
  [4107751, 'Figueira'],
  [4107801, 'Floraí'],
  [4107850, 'Flor da Serra do Sul'],
  [4107900, 'Floresta'],
  [4108007, 'Florestópolis'],
  [4108106, 'Flórida'],
  [4108205, 'Formosa do Oeste'],
  [4108304, 'Foz do Iguaçu'],
  [4108320, 'Francisco Alves'],
  [4108403, 'Francisco Beltrão'],
  [4108452, 'Foz do Jordão'],
  [4108502, 'General Carneiro'],
  [4108551, 'Godoy Moreira'],
  [4108601, 'Goioerê'],
  [4108650, 'Goioxim'],
  [4108700, 'Grandes Rios'],
  [4108809, 'Guaíra'],
  [4108908, 'Guairaçá'],
  [4108957, 'Guamiranga'],
  [4109005, 'Guapirama'],
  [4109104, 'Guaporema'],
  [4109203, 'Guaraci'],
  [4109302, 'Guaraniaçu'],
  [4109401, 'Guarapuava'],
  [4109500, 'Guaraqueçaba'],
  [4109609, 'Guaratuba'],
  [4109658, 'Honório Serpa'],
  [4109708, 'Ibaiti'],
  [4109757, 'Ibema'],
  [4109807, 'Ibiporã'],
  [4109906, 'Icaraíma'],
  [4110003, 'Iguaraçu'],
  [4110052, 'Iguatu'],
  [4110078, 'Imbaú'],
  [4110102, 'Imbituva'],
  [4110201, 'Inácio Martins'],
  [4110300, 'Inajá'],
  [4110409, 'Indianópolis'],
  [4110508, 'Ipiranga'],
  [4110607, 'Iporã'],
  [4110656, 'Iracema do Oeste'],
  [4110706, 'Irati'],
  [4110805, 'Iretama'],
  [4110904, 'Itaguajé'],
  [4110953, 'Itaipulândia'],
  [4111001, 'Itambaracá'],
  [4111100, 'Itambé'],
  [4111209, "Itapejara d'Oeste"],
  [4111258, 'Itaperuçu'],
  [4111308, 'Itaúna do Sul'],
  [4111407, 'Ivaí'],
  [4111506, 'Ivaiporã'],
  [4111555, 'Ivaté'],
  [4111605, 'Ivatuba'],
  [4111704, 'Jaboti'],
  [4111803, 'Jacarezinho'],
  [4111902, 'Jaguapitã'],
  [4112009, 'Jaguariaíva'],
  [4112108, 'Jandaia do Sul'],
  [4112207, 'Janiópolis'],
  [4112306, 'Japira'],
  [4112405, 'Japurá'],
  [4112504, 'Jardim Alegre'],
  [4112603, 'Jardim Olinda'],
  [4112702, 'Jataizinho'],
  [4112751, 'Jesuítas'],
  [4112801, 'Joaquim Távora'],
  [4112900, 'Jundiaí do Sul'],
  [4112959, 'Juranda'],
  [4113007, 'Jussara'],
  [4113106, 'Kaloré'],
  [4113205, 'Lapa'],
  [4113254, 'Laranjal'],
  [4113304, 'Laranjeiras do Sul'],
  [4113403, 'Leópolis'],
  [4113429, 'Lidianópolis'],
  [4113452, 'Lindoeste'],
  [4113502, 'Loanda'],
  [4113601, 'Lobato'],
  [4113700, 'Londrina'],
  [4113734, 'Luiziana'],
  [4113759, 'Lunardelli'],
  [4113809, 'Lupionópolis'],
  [4113908, 'Mallet'],
  [4114005, 'Mamborê'],
  [4114104, 'Mandaguaçu'],
  [4114203, 'Mandaguari'],
  [4114302, 'Mandirituba'],
  [4114351, 'Manfrinópolis'],
  [4114401, 'Mangueirinha'],
  [4114500, 'Manoel Ribas'],
  [4114609, 'Marechal Cândido Rondon'],
  [4114708, 'Maria Helena'],
  [4114807, 'Marialva'],
  [4114906, 'Marilândia do Sul'],
  [4115002, 'Marilena'],
  [4115101, 'Mariluz'],
  [4115200, 'Maringá'],
  [4115309, 'Mariópolis'],
  [4115358, 'Maripá'],
  [4115408, 'Marmeleiro'],
  [4115457, 'Marquinho'],
  [4115507, 'Marumbi'],
  [4115606, 'Matelândia'],
  [4115705, 'Matinhos'],
  [4115739, 'Mato Rico'],
  [4115754, 'Mauá da Serra'],
  [4115804, 'Medianeira'],
  [4115853, 'Mercedes'],
  [4115903, 'Mirador'],
  [4116000, 'Miraselva'],
  [4116059, 'Missal'],
  [4116109, 'Moreira Sales'],
  [4116208, 'Morretes'],
  [4116307, 'Munhoz de Melo'],
  [4116406, 'Nossa Senhora das Graças'],
  [4116505, 'Nova Aliança do Ivaí'],
  [4116604, 'Nova América da Colina'],
  [4116703, 'Nova Aurora'],
  [4116802, 'Nova Cantu'],
  [4116901, 'Nova Esperança'],
  [4116950, 'Nova Esperança do Sudoeste'],
  [4117008, 'Nova Fátima'],
  [4117057, 'Nova Laranjeiras'],
  [4117107, 'Nova Londrina'],
  [4117206, 'Nova Olímpia'],
  [4117214, 'Nova Santa Bárbara'],
  [4117222, 'Nova Santa Rosa'],
  [4117255, 'Nova Prata do Iguaçu'],
  [4117271, 'Nova Tebas'],
  [4117297, 'Novo Itacolomi'],
  [4117305, 'Ortigueira'],
  [4117404, 'Ourizona'],
  [4117453, 'Ouro Verde do Oeste'],
  [4117503, 'Paiçandu'],
  [4117602, 'Palmas'],
  [4117701, 'Palmeira'],
  [4117800, 'Palmital'],
  [4117909, 'Palotina'],
  [4118006, 'Paraíso do Norte'],
  [4118105, 'Paranacity'],
  [4118204, 'Paranaguá'],
  [4118303, 'Paranapoema'],
  [4118402, 'Paranavaí'],
  [4118451, 'Pato Bragado'],
  [4118501, 'Pato Branco'],
  [4118600, 'Paula Freitas'],
  [4118709, 'Paulo Frontin'],
  [4118808, 'Peabiru'],
  [4118857, 'Perobal'],
  [4118907, 'Pérola'],
  [4119004, "Pérola d'Oeste"],
  [4119103, 'Piên'],
  [4119152, 'Pinhais'],
  [4119202, 'Pinhalão'],
  [4119251, 'Pinhal de São Bento'],
  [4119301, 'Pinhão'],
  [4119400, 'Piraí do Sul'],
  [4119509, 'Piraquara'],
  [4119608, 'Pitanga'],
  [4119657, 'Pitangueiras'],
  [4119707, 'Planaltina do Paraná'],
  [4119806, 'Planalto'],
  [4119905, 'Ponta Grossa'],
  [4119954, 'Pontal do Paraná'],
  [4120002, 'Porecatu'],
  [4120101, 'Porto Amazonas'],
  [4120150, 'Porto Barreiro'],
  [4120200, 'Porto Rico'],
  [4120309, 'Porto Vitória'],
  [4120333, 'Prado Ferreira'],
  [4120358, 'Pranchita'],
  [4120408, 'Presidente Castelo Branco'],
  [4120507, 'Primeiro de Maio'],
  [4120606, 'Prudentópolis'],
  [4120655, 'Quarto Centenário'],
  [4120705, 'Quatiguá'],
  [4120804, 'Quatro Barras'],
  [4120853, 'Quatro Pontes'],
  [4120903, 'Quedas do Iguaçu'],
  [4121000, 'Querência do Norte'],
  [4121109, 'Quinta do Sol'],
  [4121208, 'Quitandinha'],
  [4121257, 'Ramilândia'],
  [4121307, 'Rancho Alegre'],
  [4121356, "Rancho Alegre D'Oeste"],
  [4121406, 'Realeza'],
  [4121505, 'Rebouças'],
  [4121604, 'Renascença'],
  [4121703, 'Reserva'],
  [4121752, 'Reserva do Iguaçu'],
  [4121802, 'Ribeirão Claro'],
  [4121901, 'Ribeirão do Pinhal'],
  [4122008, 'Rio Azul'],
  [4122107, 'Rio Bom'],
  [4122156, 'Rio Bonito do Iguaçu'],
  [4122172, 'Rio Branco do Ivaí'],
  [4122206, 'Rio Branco do Sul'],
  [4122305, 'Rio Negro'],
  [4122404, 'Rolândia'],
  [4122503, 'Roncador'],
  [4122602, 'Rondon'],
  [4122651, 'Rosário do Ivaí'],
  [4122701, 'Sabáudia'],
  [4122800, 'Salgado Filho'],
  [4122909, 'Salto do Itararé'],
  [4123006, 'Salto do Lontra'],
  [4123105, 'Santa Amélia'],
  [4123204, 'Santa Cecília do Pavão'],
  [4123303, 'Santa Cruz de Monte Castelo'],
  [4123402, 'Santa Fé'],
  [4123501, 'Santa Helena'],
  [4123600, 'Santa Inês'],
  [4123709, 'Santa Isabel do Ivaí'],
  [4123808, 'Santa Izabel do Oeste'],
  [4123824, 'Santa Lúcia'],
  [4123857, 'Santa Maria do Oeste'],
  [4123907, 'Santa Mariana'],
  [4123956, 'Santa Mônica'],
  [4124004, 'Santana do Itararé'],
  [4124020, 'Santa Tereza do Oeste'],
  [4124053, 'Santa Terezinha de Itaipu'],
  [4124103, 'Santo Antônio da Platina'],
  [4124202, 'Santo Antônio do Caiuá'],
  [4124301, 'Santo Antônio do Paraíso'],
  [4124400, 'Santo Antônio do Sudoeste'],
  [4124509, 'Santo Inácio'],
  [4124608, 'São Carlos do Ivaí'],
  [4124707, 'São Jerônimo da Serra'],
  [4124806, 'São João'],
  [4124905, 'São João do Caiuá'],
  [4125001, 'São João do Ivaí'],
  [4125100, 'São João do Triunfo'],
  [4125209, "São Jorge d'Oeste"],
  [4125308, 'São Jorge do Ivaí'],
  [4125357, 'São Jorge do Patrocínio'],
  [4125407, 'São José da Boa Vista'],
  [4125456, 'São José das Palmeiras'],
  [4125506, 'São José dos Pinhais'],
  [4125555, 'São Manoel do Paraná'],
  [4125605, 'São Mateus do Sul'],
  [4125704, 'São Miguel do Iguaçu'],
  [4125753, 'São Pedro do Iguaçu'],
  [4125803, 'São Pedro do Ivaí'],
  [4125902, 'São Pedro do Paraná'],
  [4126009, 'São Sebastião da Amoreira'],
  [4126108, 'São Tomé'],
  [4126207, 'Sapopema'],
  [4126256, 'Sarandi'],
  [4126272, 'Saudade do Iguaçu'],
  [4126306, 'Sengés'],
  [4126355, 'Serranópolis do Iguaçu'],
  [4126405, 'Sertaneja'],
  [4126504, 'Sertanópolis'],
  [4126603, 'Siqueira Campos'],
  [4126652, 'Sulina'],
  [4126678, 'Tamarana'],
  [4126702, 'Tamboara'],
  [4126801, 'Tapejara'],
  [4126900, 'Tapira'],
  [4127007, 'Teixeira Soares'],
  [4127106, 'Telêmaco Borba'],
  [4127205, 'Terra Boa'],
  [4127304, 'Terra Rica'],
  [4127403, 'Terra Roxa'],
  [4127502, 'Tibagi'],
  [4127601, 'Tijucas do Sul'],
  [4127700, 'Toledo'],
  [4127809, 'Tomazina'],
  [4127858, 'Três Barras do Paraná'],
  [4127882, 'Tunas do Paraná'],
  [4127908, 'Tuneiras do Oeste'],
  [4127957, 'Tupãssi'],
  [4127965, 'Turvo'],
  [4128005, 'Ubiratã'],
  [4128104, 'Umuarama'],
  [4128203, 'União da Vitória'],
  [4128302, 'Uniflor'],
  [4128401, 'Uraí'],
  [4128500, 'Wenceslau Braz'],
  [4128534, 'Ventania'],
  [4128559, 'Vera Cruz do Oeste'],
  [4128609, 'Verê'],
  [4128625, 'Alto Paraíso'],
  [4128633, 'Doutor Ulysses'],
  [4128658, 'Virmond'],
  [4128708, 'Vitorino'],
  [4128807, 'Xambrê'],
  [4200051, 'Abdon Batista'],
  [4200101, 'Abelardo Luz'],
  [4200200, 'Agrolândia'],
  [4200309, 'Agronômica'],
  [4200408, 'Água Doce'],
  [4200507, 'Águas de Chapecó'],
  [4200556, 'Águas Frias'],
  [4200606, 'Águas Mornas'],
  [4200705, 'Alfredo Wagner'],
  [4200754, 'Alto Bela Vista'],
  [4200804, 'Anchieta'],
  [4200903, 'Angelina'],
  [4201000, 'Anita Garibaldi'],
  [4201109, 'Anitápolis'],
  [4201208, 'Antônio Carlos'],
  [4201257, 'Apiúna'],
  [4201273, 'Arabutã'],
  [4201307, 'Araquari'],
  [4201406, 'Araranguá'],
  [4201505, 'Armazém'],
  [4201604, 'Arroio Trinta'],
  [4201653, 'Arvoredo'],
  [4201703, 'Ascurra'],
  [4201802, 'Atalanta'],
  [4201901, 'Aurora'],
  [4201950, 'Balneário Arroio do Silva'],
  [4202008, 'Balneário Camboriú'],
  [4202057, 'Balneário Barra do Sul'],
  [4202073, 'Balneário Gaivota'],
  [4202081, 'Bandeirante'],
  [4202099, 'Barra Bonita'],
  [4202107, 'Barra Velha'],
  [4202131, 'Bela Vista do Toldo'],
  [4202156, 'Belmonte'],
  [4202206, 'Benedito Novo'],
  [4202305, 'Biguaçu'],
  [4202404, 'Blumenau'],
  [4202438, 'Bocaina do Sul'],
  [4202453, 'Bombinhas'],
  [4202503, 'Bom Jardim da Serra'],
  [4202537, 'Bom Jesus'],
  [4202578, 'Bom Jesus do Oeste'],
  [4202602, 'Bom Retiro'],
  [4202701, 'Botuverá'],
  [4202800, 'Braço do Norte'],
  [4202859, 'Braço do Trombudo'],
  [4202875, 'Brunópolis'],
  [4202909, 'Brusque'],
  [4203006, 'Caçador'],
  [4203105, 'Caibi'],
  [4203154, 'Calmon'],
  [4203204, 'Camboriú'],
  [4203253, 'Capão Alto'],
  [4203303, 'Campo Alegre'],
  [4203402, 'Campo Belo do Sul'],
  [4203501, 'Campo Erê'],
  [4203600, 'Campos Novos'],
  [4203709, 'Canelinha'],
  [4203808, 'Canoinhas'],
  [4203907, 'Capinzal'],
  [4203956, 'Capivari de Baixo'],
  [4204004, 'Catanduvas'],
  [4204103, 'Caxambu do Sul'],
  [4204152, 'Celso Ramos'],
  [4204178, 'Cerro Negro'],
  [4204194, 'Chapadão do Lageado'],
  [4204202, 'Chapecó'],
  [4204251, 'Cocal do Sul'],
  [4204301, 'Concórdia'],
  [4204350, 'Cordilheira Alta'],
  [4204400, 'Coronel Freitas'],
  [4204459, 'Coronel Martins'],
  [4204509, 'Corupá'],
  [4204558, 'Correia Pinto'],
  [4204608, 'Criciúma'],
  [4204707, 'Cunha Porã'],
  [4204756, 'Cunhataí'],
  [4204806, 'Curitibanos'],
  [4204905, 'Descanso'],
  [4205001, 'Dionísio Cerqueira'],
  [4205100, 'Dona Emma'],
  [4205159, 'Doutor Pedrinho'],
  [4205175, 'Entre Rios'],
  [4205191, 'Ermo'],
  [4205209, 'Erval Velho'],
  [4205308, 'Faxinal dos Guedes'],
  [4205357, 'Flor do Sertão'],
  [4205407, 'Florianópolis'],
  [4205431, 'Formosa do Sul'],
  [4205456, 'Forquilhinha'],
  [4205506, 'Fraiburgo'],
  [4205555, 'Frei Rogério'],
  [4205605, 'Galvão'],
  [4205704, 'Garopaba'],
  [4205803, 'Garuva'],
  [4205902, 'Gaspar'],
  [4206009, 'Governador Celso Ramos'],
  [4206108, 'Grão-Pará'],
  [4206207, 'Gravatal'],
  [4206306, 'Guabiruba'],
  [4206405, 'Guaraciaba'],
  [4206504, 'Guaramirim'],
  [4206603, 'Guarujá do Sul'],
  [4206652, 'Guatambú'],
  [4206702, "Herval d'Oeste"],
  [4206751, 'Ibiam'],
  [4206801, 'Ibicaré'],
  [4206900, 'Ibirama'],
  [4207007, 'Içara'],
  [4207106, 'Ilhota'],
  [4207205, 'Imaruí'],
  [4207304, 'Imbituba'],
  [4207403, 'Imbuia'],
  [4207502, 'Indaial'],
  [4207577, 'Iomerê'],
  [4207601, 'Ipira'],
  [4207650, 'Iporã do Oeste'],
  [4207684, 'Ipuaçu'],
  [4207700, 'Ipumirim'],
  [4207759, 'Iraceminha'],
  [4207809, 'Irani'],
  [4207858, 'Irati'],
  [4207908, 'Irineópolis'],
  [4208005, 'Itá'],
  [4208104, 'Itaiópolis'],
  [4208203, 'Itajaí'],
  [4208302, 'Itapema'],
  [4208401, 'Itapiranga'],
  [4208450, 'Itapoá'],
  [4208500, 'Ituporanga'],
  [4208609, 'Jaborá'],
  [4208708, 'Jacinto Machado'],
  [4208807, 'Jaguaruna'],
  [4208906, 'Jaraguá do Sul'],
  [4208955, 'Jardinópolis'],
  [4209003, 'Joaçaba'],
  [4209102, 'Joinville'],
  [4209151, 'José Boiteux'],
  [4209177, 'Jupiá'],
  [4209201, 'Lacerdópolis'],
  [4209300, 'Lages'],
  [4209409, 'Laguna'],
  [4209458, 'Lajeado Grande'],
  [4209508, 'Laurentino'],
  [4209607, 'Lauro Müller'],
  [4209706, 'Lebon Régis'],
  [4209805, 'Leoberto Leal'],
  [4209854, 'Lindóia do Sul'],
  [4209904, 'Lontras'],
  [4210001, 'Luiz Alves'],
  [4210035, 'Luzerna'],
  [4210050, 'Macieira'],
  [4210100, 'Mafra'],
  [4210209, 'Major Gercino'],
  [4210308, 'Major Vieira'],
  [4210407, 'Maracajá'],
  [4210506, 'Maravilha'],
  [4210555, 'Marema'],
  [4210605, 'Massaranduba'],
  [4210704, 'Matos Costa'],
  [4210803, 'Meleiro'],
  [4210852, 'Mirim Doce'],
  [4210902, 'Modelo'],
  [4211009, 'Mondaí'],
  [4211058, 'Monte Carlo'],
  [4211108, 'Monte Castelo'],
  [4211207, 'Morro da Fumaça'],
  [4211256, 'Morro Grande'],
  [4211306, 'Navegantes'],
  [4211405, 'Nova Erechim'],
  [4211454, 'Nova Itaberaba'],
  [4211504, 'Nova Trento'],
  [4211603, 'Nova Veneza'],
  [4211652, 'Novo Horizonte'],
  [4211702, 'Orleans'],
  [4211751, 'Otacílio Costa'],
  [4211801, 'Ouro'],
  [4211850, 'Ouro Verde'],
  [4211876, 'Paial'],
  [4211892, 'Painel'],
  [4211900, 'Palhoça'],
  [4212007, 'Palma Sola'],
  [4212056, 'Palmeira'],
  [4212106, 'Palmitos'],
  [4212205, 'Papanduva'],
  [4212239, 'Paraíso'],
  [4212254, 'Passo de Torres'],
  [4212270, 'Passos Maia'],
  [4212304, 'Paulo Lopes'],
  [4212403, 'Pedras Grandes'],
  [4212502, 'Penha'],
  [4212601, 'Peritiba'],
  [4212650, 'Pescaria Brava'],
  [4212700, 'Petrolândia'],
  [4212809, 'Balneário Piçarras'],
  [4212908, 'Pinhalzinho'],
  [4213005, 'Pinheiro Preto'],
  [4213104, 'Piratuba'],
  [4213153, 'Planalto Alegre'],
  [4213203, 'Pomerode'],
  [4213302, 'Ponte Alta'],
  [4213351, 'Ponte Alta do Norte'],
  [4213401, 'Ponte Serrada'],
  [4213500, 'Porto Belo'],
  [4213609, 'Porto União'],
  [4213708, 'Pouso Redondo'],
  [4213807, 'Praia Grande'],
  [4213906, 'Presidente Castello Branco'],
  [4214003, 'Presidente Getúlio'],
  [4214102, 'Presidente Nereu'],
  [4214151, 'Princesa'],
  [4214201, 'Quilombo'],
  [4214300, 'Rancho Queimado'],
  [4214409, 'Rio das Antas'],
  [4214508, 'Rio do Campo'],
  [4214607, 'Rio do Oeste'],
  [4214706, 'Rio dos Cedros'],
  [4214805, 'Rio do Sul'],
  [4214904, 'Rio Fortuna'],
  [4215000, 'Rio Negrinho'],
  [4215059, 'Rio Rufino'],
  [4215075, 'Riqueza'],
  [4215109, 'Rodeio'],
  [4215208, 'Romelândia'],
  [4215307, 'Salete'],
  [4215356, 'Saltinho'],
  [4215406, 'Salto Veloso'],
  [4215455, 'Sangão'],
  [4215505, 'Santa Cecília'],
  [4215554, 'Santa Helena'],
  [4215604, 'Santa Rosa de Lima'],
  [4215653, 'Santa Rosa do Sul'],
  [4215679, 'Santa Terezinha'],
  [4215687, 'Santa Terezinha do Progresso'],
  [4215695, 'Santiago do Sul'],
  [4215703, 'Santo Amaro da Imperatriz'],
  [4215752, 'São Bernardino'],
  [4215802, 'São Bento do Sul'],
  [4215901, 'São Bonifácio'],
  [4216008, 'São Carlos'],
  [4216057, 'São Cristóvão do Sul'],
  [4216107, 'São Domingos'],
  [4216206, 'São Francisco do Sul'],
  [4216255, 'São João do Oeste'],
  [4216305, 'São João Batista'],
  [4216354, 'São João do Itaperiú'],
  [4216404, 'São João do Sul'],
  [4216503, 'São Joaquim'],
  [4216602, 'São José'],
  [4216701, 'São José do Cedro'],
  [4216800, 'São José do Cerrito'],
  [4216909, 'São Lourenço do Oeste'],
  [4217006, 'São Ludgero'],
  [4217105, 'São Martinho'],
  [4217154, 'São Miguel da Boa Vista'],
  [4217204, 'São Miguel do Oeste'],
  [4217253, 'São Pedro de Alcântara'],
  [4217303, 'Saudades'],
  [4217402, 'Schroeder'],
  [4217501, 'Seara'],
  [4217550, 'Serra Alta'],
  [4217600, 'Siderópolis'],
  [4217709, 'Sombrio'],
  [4217758, 'Sul Brasil'],
  [4217808, 'Taió'],
  [4217907, 'Tangará'],
  [4217956, 'Tigrinhos'],
  [4218004, 'Tijucas'],
  [4218103, 'Timbé do Sul'],
  [4218202, 'Timbó'],
  [4218251, 'Timbó Grande'],
  [4218301, 'Três Barras'],
  [4218350, 'Treviso'],
  [4218400, 'Treze de Maio'],
  [4218509, 'Treze Tílias'],
  [4218608, 'Trombudo Central'],
  [4218707, 'Tubarão'],
  [4218756, 'Tunápolis'],
  [4218806, 'Turvo'],
  [4218855, 'União do Oeste'],
  [4218905, 'Urubici'],
  [4218954, 'Urupema'],
  [4219002, 'Urussanga'],
  [4219101, 'Vargeão'],
  [4219150, 'Vargem'],
  [4219176, 'Vargem Bonita'],
  [4219200, 'Vidal Ramos'],
  [4219309, 'Videira'],
  [4219358, 'Vitor Meireles'],
  [4219408, 'Witmarsum'],
  [4219507, 'Xanxerê'],
  [4219606, 'Xavantina'],
  [4219705, 'Xaxim'],
  [4219853, 'Zortéa'],
  [4220000, 'Balneário Rincão'],
  [4300034, 'Aceguá'],
  [4300059, 'Água Santa'],
  [4300109, 'Agudo'],
  [4300208, 'Ajuricaba'],
  [4300307, 'Alecrim'],
  [4300406, 'Alegrete'],
  [4300455, 'Alegria'],
  [4300471, 'Almirante Tamandaré do Sul'],
  [4300505, 'Alpestre'],
  [4300554, 'Alto Alegre'],
  [4300570, 'Alto Feliz'],
  [4300604, 'Alvorada'],
  [4300638, 'Amaral Ferrador'],
  [4300646, 'Ametista do Sul'],
  [4300661, 'André da Rocha'],
  [4300703, 'Anta Gorda'],
  [4300802, 'Antônio Prado'],
  [4300851, 'Arambaré'],
  [4300877, 'Araricá'],
  [4300901, 'Aratiba'],
  [4301008, 'Arroio do Meio'],
  [4301057, 'Arroio do Sal'],
  [4301073, 'Arroio do Padre'],
  [4301107, 'Arroio dos Ratos'],
  [4301206, 'Arroio do Tigre'],
  [4301305, 'Arroio Grande'],
  [4301404, 'Arvorezinha'],
  [4301503, 'Augusto Pestana'],
  [4301552, 'Áurea'],
  [4301602, 'Bagé'],
  [4301636, 'Balneário Pinhal'],
  [4301651, 'Barão'],
  [4301701, 'Barão de Cotegipe'],
  [4301750, 'Barão do Triunfo'],
  [4301800, 'Barracão'],
  [4301859, 'Barra do Guarita'],
  [4301875, 'Barra do Quaraí'],
  [4301909, 'Barra do Ribeiro'],
  [4301925, 'Barra do Rio Azul'],
  [4301958, 'Barra Funda'],
  [4302006, 'Barros Cassal'],
  [4302055, 'Benjamin Constant do Sul'],
  [4302105, 'Bento Gonçalves'],
  [4302154, 'Boa Vista das Missões'],
  [4302204, 'Boa Vista do Buricá'],
  [4302220, 'Boa Vista do Cadeado'],
  [4302238, 'Boa Vista do Incra'],
  [4302253, 'Boa Vista do Sul'],
  [4302303, 'Bom Jesus'],
  [4302352, 'Bom Princípio'],
  [4302378, 'Bom Progresso'],
  [4302402, 'Bom Retiro do Sul'],
  [4302451, 'Boqueirão do Leão'],
  [4302501, 'Bossoroca'],
  [4302584, 'Bozano'],
  [4302600, 'Braga'],
  [4302659, 'Brochier'],
  [4302709, 'Butiá'],
  [4302808, 'Caçapava do Sul'],
  [4302907, 'Cacequi'],
  [4303004, 'Cachoeira do Sul'],
  [4303103, 'Cachoeirinha'],
  [4303202, 'Cacique Doble'],
  [4303301, 'Caibaté'],
  [4303400, 'Caiçara'],
  [4303509, 'Camaquã'],
  [4303558, 'Camargo'],
  [4303608, 'Cambará do Sul'],
  [4303673, 'Campestre da Serra'],
  [4303707, 'Campina das Missões'],
  [4303806, 'Campinas do Sul'],
  [4303905, 'Campo Bom'],
  [4304002, 'Campo Novo'],
  [4304101, 'Campos Borges'],
  [4304200, 'Candelária'],
  [4304309, 'Cândido Godói'],
  [4304358, 'Candiota'],
  [4304408, 'Canela'],
  [4304507, 'Canguçu'],
  [4304606, 'Canoas'],
  [4304614, 'Canudos do Vale'],
  [4304622, 'Capão Bonito do Sul'],
  [4304630, 'Capão da Canoa'],
  [4304655, 'Capão do Cipó'],
  [4304663, 'Capão do Leão'],
  [4304671, 'Capivari do Sul'],
  [4304689, 'Capela de Santana'],
  [4304697, 'Capitão'],
  [4304705, 'Carazinho'],
  [4304713, 'Caraá'],
  [4304804, 'Carlos Barbosa'],
  [4304853, 'Carlos Gomes'],
  [4304903, 'Casca'],
  [4304952, 'Caseiros'],
  [4305009, 'Catuípe'],
  [4305108, 'Caxias do Sul'],
  [4305116, 'Centenário'],
  [4305124, 'Cerrito'],
  [4305132, 'Cerro Branco'],
  [4305157, 'Cerro Grande'],
  [4305173, 'Cerro Grande do Sul'],
  [4305207, 'Cerro Largo'],
  [4305306, 'Chapada'],
  [4305355, 'Charqueadas'],
  [4305371, 'Charrua'],
  [4305405, 'Chiapetta'],
  [4305439, 'Chuí'],
  [4305447, 'Chuvisca'],
  [4305454, 'Cidreira'],
  [4305504, 'Ciríaco'],
  [4305587, 'Colinas'],
  [4305603, 'Colorado'],
  [4305702, 'Condor'],
  [4305801, 'Constantina'],
  [4305835, 'Coqueiro Baixo'],
  [4305850, 'Coqueiros do Sul'],
  [4305871, 'Coronel Barros'],
  [4305900, 'Coronel Bicaco'],
  [4305934, 'Coronel Pilar'],
  [4305959, 'Cotiporã'],
  [4305975, 'Coxilha'],
  [4306007, 'Crissiumal'],
  [4306056, 'Cristal'],
  [4306072, 'Cristal do Sul'],
  [4306106, 'Cruz Alta'],
  [4306130, 'Cruzaltense'],
  [4306205, 'Cruzeiro do Sul'],
  [4306304, 'David Canabarro'],
  [4306320, 'Derrubadas'],
  [4306353, 'Dezesseis de Novembro'],
  [4306379, 'Dilermando de Aguiar'],
  [4306403, 'Dois Irmãos'],
  [4306429, 'Dois Irmãos das Missões'],
  [4306452, 'Dois Lajeados'],
  [4306502, 'Dom Feliciano'],
  [4306551, 'Dom Pedro de Alcântara'],
  [4306601, 'Dom Pedrito'],
  [4306700, 'Dona Francisca'],
  [4306734, 'Doutor Maurício Cardoso'],
  [4306759, 'Doutor Ricardo'],
  [4306767, 'Eldorado do Sul'],
  [4306809, 'Encantado'],
  [4306908, 'Encruzilhada do Sul'],
  [4306924, 'Engenho Velho'],
  [4306932, 'Entre-Ijuís'],
  [4306957, 'Entre Rios do Sul'],
  [4306973, 'Erebango'],
  [4307005, 'Erechim'],
  [4307054, 'Ernestina'],
  [4307104, 'Herval'],
  [4307203, 'Erval Grande'],
  [4307302, 'Erval Seco'],
  [4307401, 'Esmeralda'],
  [4307450, 'Esperança do Sul'],
  [4307500, 'Espumoso'],
  [4307559, 'Estação'],
  [4307609, 'Estância Velha'],
  [4307708, 'Esteio'],
  [4307807, 'Estrela'],
  [4307815, 'Estrela Velha'],
  [4307831, 'Eugênio de Castro'],
  [4307864, 'Fagundes Varela'],
  [4307906, 'Farroupilha'],
  [4308003, 'Faxinal do Soturno'],
  [4308052, 'Faxinalzinho'],
  [4308078, 'Fazenda Vilanova'],
  [4308102, 'Feliz'],
  [4308201, 'Flores da Cunha'],
  [4308250, 'Floriano Peixoto'],
  [4308300, 'Fontoura Xavier'],
  [4308409, 'Formigueiro'],
  [4308433, 'Forquetinha'],
  [4308458, 'Fortaleza dos Valos'],
  [4308508, 'Frederico Westphalen'],
  [4308607, 'Garibaldi'],
  [4308656, 'Garruchos'],
  [4308706, 'Gaurama'],
  [4308805, 'General Câmara'],
  [4308854, 'Gentil'],
  [4308904, 'Getúlio Vargas'],
  [4309001, 'Giruá'],
  [4309050, 'Glorinha'],
  [4309100, 'Gramado'],
  [4309126, 'Gramado dos Loureiros'],
  [4309159, 'Gramado Xavier'],
  [4309209, 'Gravataí'],
  [4309258, 'Guabiju'],
  [4309308, 'Guaíba'],
  [4309407, 'Guaporé'],
  [4309506, 'Guarani das Missões'],
  [4309555, 'Harmonia'],
  [4309571, 'Herveiras'],
  [4309605, 'Horizontina'],
  [4309654, 'Hulha Negra'],
  [4309704, 'Humaitá'],
  [4309753, 'Ibarama'],
  [4309803, 'Ibiaçá'],
  [4309902, 'Ibiraiaras'],
  [4309951, 'Ibirapuitã'],
  [4310009, 'Ibirubá'],
  [4310108, 'Igrejinha'],
  [4310207, 'Ijuí'],
  [4310306, 'Ilópolis'],
  [4310330, 'Imbé'],
  [4310363, 'Imigrante'],
  [4310405, 'Independência'],
  [4310413, 'Inhacorá'],
  [4310439, 'Ipê'],
  [4310462, 'Ipiranga do Sul'],
  [4310504, 'Iraí'],
  [4310538, 'Itaara'],
  [4310553, 'Itacurubi'],
  [4310579, 'Itapuca'],
  [4310603, 'Itaqui'],
  [4310652, 'Itati'],
  [4310702, 'Itatiba do Sul'],
  [4310751, 'Ivorá'],
  [4310801, 'Ivoti'],
  [4310850, 'Jaboticaba'],
  [4310876, 'Jacuizinho'],
  [4310900, 'Jacutinga'],
  [4311007, 'Jaguarão'],
  [4311106, 'Jaguari'],
  [4311122, 'Jaquirana'],
  [4311130, 'Jari'],
  [4311155, 'Jóia'],
  [4311205, 'Júlio de Castilhos'],
  [4311239, 'Lagoa Bonita do Sul'],
  [4311254, 'Lagoão'],
  [4311270, 'Lagoa dos Três Cantos'],
  [4311304, 'Lagoa Vermelha'],
  [4311403, 'Lajeado'],
  [4311429, 'Lajeado do Bugre'],
  [4311502, 'Lavras do Sul'],
  [4311601, 'Liberato Salzano'],
  [4311627, 'Lindolfo Collor'],
  [4311643, 'Linha Nova'],
  [4311700, 'Machadinho'],
  [4311718, 'Maçambará'],
  [4311734, 'Mampituba'],
  [4311759, 'Manoel Viana'],
  [4311775, 'Maquiné'],
  [4311791, 'Maratá'],
  [4311809, 'Marau'],
  [4311908, 'Marcelino Ramos'],
  [4311981, 'Mariana Pimentel'],
  [4312005, 'Mariano Moro'],
  [4312054, 'Marques de Souza'],
  [4312104, 'Mata'],
  [4312138, 'Mato Castelhano'],
  [4312153, 'Mato Leitão'],
  [4312179, 'Mato Queimado'],
  [4312203, 'Maximiliano de Almeida'],
  [4312252, 'Minas do Leão'],
  [4312302, 'Miraguaí'],
  [4312351, 'Montauri'],
  [4312377, 'Monte Alegre dos Campos'],
  [4312385, 'Monte Belo do Sul'],
  [4312401, 'Montenegro'],
  [4312427, 'Mormaço'],
  [4312443, 'Morrinhos do Sul'],
  [4312450, 'Morro Redondo'],
  [4312476, 'Morro Reuter'],
  [4312500, 'Mostardas'],
  [4312609, 'Muçum'],
  [4312617, 'Muitos Capões'],
  [4312625, 'Muliterno'],
  [4312658, 'Não-Me-Toque'],
  [4312674, 'Nicolau Vergueiro'],
  [4312708, 'Nonoai'],
  [4312757, 'Nova Alvorada'],
  [4312807, 'Nova Araçá'],
  [4312906, 'Nova Bassano'],
  [4312955, 'Nova Boa Vista'],
  [4313003, 'Nova Bréscia'],
  [4313011, 'Nova Candelária'],
  [4313037, 'Nova Esperança do Sul'],
  [4313060, 'Nova Hartz'],
  [4313086, 'Nova Pádua'],
  [4313102, 'Nova Palma'],
  [4313201, 'Nova Petrópolis'],
  [4313300, 'Nova Prata'],
  [4313334, 'Nova Ramada'],
  [4313359, 'Nova Roma do Sul'],
  [4313375, 'Nova Santa Rita'],
  [4313391, 'Novo Cabrais'],
  [4313409, 'Novo Hamburgo'],
  [4313425, 'Novo Machado'],
  [4313441, 'Novo Tiradentes'],
  [4313466, 'Novo Xingu'],
  [4313490, 'Novo Barreiro'],
  [4313508, 'Osório'],
  [4313607, 'Paim Filho'],
  [4313656, 'Palmares do Sul'],
  [4313706, 'Palmeira das Missões'],
  [4313805, 'Palmitinho'],
  [4313904, 'Panambi'],
  [4313953, 'Pantano Grande'],
  [4314001, 'Paraí'],
  [4314027, 'Paraíso do Sul'],
  [4314035, 'Pareci Novo'],
  [4314050, 'Parobé'],
  [4314068, 'Passa Sete'],
  [4314076, 'Passo do Sobrado'],
  [4314100, 'Passo Fundo'],
  [4314134, 'Paulo Bento'],
  [4314159, 'Paverama'],
  [4314175, 'Pedras Altas'],
  [4314209, 'Pedro Osório'],
  [4314308, 'Pejuçara'],
  [4314407, 'Pelotas'],
  [4314423, 'Picada Café'],
  [4314456, 'Pinhal'],
  [4314464, 'Pinhal da Serra'],
  [4314472, 'Pinhal Grande'],
  [4314498, 'Pinheirinho do Vale'],
  [4314506, 'Pinheiro Machado'],
  [4314548, 'Pinto Bandeira'],
  [4314555, 'Pirapó'],
  [4314605, 'Piratini'],
  [4314704, 'Planalto'],
  [4314753, 'Poço das Antas'],
  [4314779, 'Pontão'],
  [4314787, 'Ponte Preta'],
  [4314803, 'Portão'],
  [4314902, 'Porto Alegre'],
  [4315008, 'Porto Lucena'],
  [4315057, 'Porto Mauá'],
  [4315073, 'Porto Vera Cruz'],
  [4315107, 'Porto Xavier'],
  [4315131, 'Pouso Novo'],
  [4315149, 'Presidente Lucena'],
  [4315156, 'Progresso'],
  [4315172, 'Protásio Alves'],
  [4315206, 'Putinga'],
  [4315305, 'Quaraí'],
  [4315313, 'Quatro Irmãos'],
  [4315321, 'Quevedos'],
  [4315354, 'Quinze de Novembro'],
  [4315404, 'Redentora'],
  [4315453, 'Relvado'],
  [4315503, 'Restinga Sêca'],
  [4315552, 'Rio dos Índios'],
  [4315602, 'Rio Grande'],
  [4315701, 'Rio Pardo'],
  [4315750, 'Riozinho'],
  [4315800, 'Roca Sales'],
  [4315909, 'Rodeio Bonito'],
  [4315958, 'Rolador'],
  [4316006, 'Rolante'],
  [4316105, 'Ronda Alta'],
  [4316204, 'Rondinha'],
  [4316303, 'Roque Gonzales'],
  [4316402, 'Rosário do Sul'],
  [4316428, 'Sagrada Família'],
  [4316436, 'Saldanha Marinho'],
  [4316451, 'Salto do Jacuí'],
  [4316477, 'Salvador das Missões'],
  [4316501, 'Salvador do Sul'],
  [4316600, 'Sananduva'],
  [4316709, 'Santa Bárbara do Sul'],
  [4316733, 'Santa Cecília do Sul'],
  [4316758, 'Santa Clara do Sul'],
  [4316808, 'Santa Cruz do Sul'],
  [4316907, 'Santa Maria'],
  [4316956, 'Santa Maria do Herval'],
  [4316972, 'Santa Margarida do Sul'],
  [4317004, 'Santana da Boa Vista'],
  [4317103, "Sant'Ana do Livramento"],
  [4317202, 'Santa Rosa'],
  [4317251, 'Santa Tereza'],
  [4317301, 'Santa Vitória do Palmar'],
  [4317400, 'Santiago'],
  [4317509, 'Santo Ângelo'],
  [4317558, 'Santo Antônio do Palma'],
  [4317608, 'Santo Antônio da Patrulha'],
  [4317707, 'Santo Antônio das Missões'],
  [4317756, 'Santo Antônio do Planalto'],
  [4317806, 'Santo Augusto'],
  [4317905, 'Santo Cristo'],
  [4317954, 'Santo Expedito do Sul'],
  [4318002, 'São Borja'],
  [4318051, 'São Domingos do Sul'],
  [4318101, 'São Francisco de Assis'],
  [4318200, 'São Francisco de Paula'],
  [4318309, 'São Gabriel'],
  [4318408, 'São Jerônimo'],
  [4318424, 'São João da Urtiga'],
  [4318432, 'São João do Polêsine'],
  [4318440, 'São Jorge'],
  [4318457, 'São José das Missões'],
  [4318465, 'São José do Herval'],
  [4318481, 'São José do Hortêncio'],
  [4318499, 'São José do Inhacorá'],
  [4318507, 'São José do Norte'],
  [4318606, 'São José do Ouro'],
  [4318614, 'São José do Sul'],
  [4318622, 'São José dos Ausentes'],
  [4318705, 'São Leopoldo'],
  [4318804, 'São Lourenço do Sul'],
  [4318903, 'São Luiz Gonzaga'],
  [4319000, 'São Marcos'],
  [4319109, 'São Martinho'],
  [4319125, 'São Martinho da Serra'],
  [4319158, 'São Miguel das Missões'],
  [4319208, 'São Nicolau'],
  [4319307, 'São Paulo das Missões'],
  [4319356, 'São Pedro da Serra'],
  [4319364, 'São Pedro das Missões'],
  [4319372, 'São Pedro do Butiá'],
  [4319406, 'São Pedro do Sul'],
  [4319505, 'São Sebastião do Caí'],
  [4319604, 'São Sepé'],
  [4319703, 'São Valentim'],
  [4319711, 'São Valentim do Sul'],
  [4319737, 'São Valério do Sul'],
  [4319752, 'São Vendelino'],
  [4319802, 'São Vicente do Sul'],
  [4319901, 'Sapiranga'],
  [4320008, 'Sapucaia do Sul'],
  [4320107, 'Sarandi'],
  [4320206, 'Seberi'],
  [4320230, 'Sede Nova'],
  [4320263, 'Segredo'],
  [4320305, 'Selbach'],
  [4320321, 'Senador Salgado Filho'],
  [4320354, 'Sentinela do Sul'],
  [4320404, 'Serafina Corrêa'],
  [4320453, 'Sério'],
  [4320503, 'Sertão'],
  [4320552, 'Sertão Santana'],
  [4320578, 'Sete de Setembro'],
  [4320602, 'Severiano de Almeida'],
  [4320651, 'Silveira Martins'],
  [4320677, 'Sinimbu'],
  [4320701, 'Sobradinho'],
  [4320800, 'Soledade'],
  [4320859, 'Tabaí'],
  [4320909, 'Tapejara'],
  [4321006, 'Tapera'],
  [4321105, 'Tapes'],
  [4321204, 'Taquara'],
  [4321303, 'Taquari'],
  [4321329, 'Taquaruçu do Sul'],
  [4321352, 'Tavares'],
  [4321402, 'Tenente Portela'],
  [4321436, 'Terra de Areia'],
  [4321451, 'Teutônia'],
  [4321469, 'Tio Hugo'],
  [4321477, 'Tiradentes do Sul'],
  [4321493, 'Toropi'],
  [4321501, 'Torres'],
  [4321600, 'Tramandaí'],
  [4321626, 'Travesseiro'],
  [4321634, 'Três Arroios'],
  [4321667, 'Três Cachoeiras'],
  [4321709, 'Três Coroas'],
  [4321808, 'Três de Maio'],
  [4321832, 'Três Forquilhas'],
  [4321857, 'Três Palmeiras'],
  [4321907, 'Três Passos'],
  [4321956, 'Trindade do Sul'],
  [4322004, 'Triunfo'],
  [4322103, 'Tucunduva'],
  [4322152, 'Tunas'],
  [4322186, 'Tupanci do Sul'],
  [4322202, 'Tupanciretã'],
  [4322251, 'Tupandi'],
  [4322301, 'Tuparendi'],
  [4322327, 'Turuçu'],
  [4322343, 'Ubiretama'],
  [4322350, 'União da Serra'],
  [4322376, 'Unistalda'],
  [4322400, 'Uruguaiana'],
  [4322509, 'Vacaria'],
  [4322525, 'Vale Verde'],
  [4322533, 'Vale do Sol'],
  [4322541, 'Vale Real'],
  [4322558, 'Vanini'],
  [4322608, 'Venâncio Aires'],
  [4322707, 'Vera Cruz'],
  [4322806, 'Veranópolis'],
  [4322855, 'Vespasiano Corrêa'],
  [4322905, 'Viadutos'],
  [4323002, 'Viamão'],
  [4323101, 'Vicente Dutra'],
  [4323200, 'Victor Graeff'],
  [4323309, 'Vila Flores'],
  [4323358, 'Vila Lângaro'],
  [4323408, 'Vila Maria'],
  [4323457, 'Vila Nova do Sul'],
  [4323507, 'Vista Alegre'],
  [4323606, 'Vista Alegre do Prata'],
  [4323705, 'Vista Gaúcha'],
  [4323754, 'Vitória das Missões'],
  [4323770, 'Westfália'],
  [4323804, 'Xangri-lá'],
  [5000203, 'Água Clara'],
  [5000252, 'Alcinópolis'],
  [5000609, 'Amambai'],
  [5000708, 'Anastácio'],
  [5000807, 'Anaurilândia'],
  [5000856, 'Angélica'],
  [5000906, 'Antônio João'],
  [5001003, 'Aparecida do Taboado'],
  [5001102, 'Aquidauana'],
  [5001243, 'Aral Moreira'],
  [5001508, 'Bandeirantes'],
  [5001904, 'Bataguassu'],
  [5002001, 'Batayporã'],
  [5002100, 'Bela Vista'],
  [5002159, 'Bodoquena'],
  [5002209, 'Bonito'],
  [5002308, 'Brasilândia'],
  [5002407, 'Caarapó'],
  [5002605, 'Camapuã'],
  [5002704, 'Campo Grande'],
  [5002803, 'Caracol'],
  [5002902, 'Cassilândia'],
  [5002951, 'Chapadão do Sul'],
  [5003108, 'Corguinho'],
  [5003157, 'Coronel Sapucaia'],
  [5003207, 'Corumbá'],
  [5003256, 'Costa Rica'],
  [5003306, 'Coxim'],
  [5003454, 'Deodápolis'],
  [5003488, 'Dois Irmãos do Buriti'],
  [5003504, 'Douradina'],
  [5003702, 'Dourados'],
  [5003751, 'Eldorado'],
  [5003801, 'Fátima do Sul'],
  [5003900, 'Figueirão'],
  [5004007, 'Glória de Dourados'],
  [5004106, 'Guia Lopes da Laguna'],
  [5004304, 'Iguatemi'],
  [5004403, 'Inocência'],
  [5004502, 'Itaporã'],
  [5004601, 'Itaquiraí'],
  [5004700, 'Ivinhema'],
  [5004809, 'Japorã'],
  [5004908, 'Jaraguari'],
  [5005004, 'Jardim'],
  [5005103, 'Jateí'],
  [5005152, 'Juti'],
  [5005202, 'Ladário'],
  [5005251, 'Laguna Carapã'],
  [5005400, 'Maracaju'],
  [5005608, 'Miranda'],
  [5005681, 'Mundo Novo'],
  [5005707, 'Naviraí'],
  [5005806, 'Nioaque'],
  [5006002, 'Nova Alvorada do Sul'],
  [5006200, 'Nova Andradina'],
  [5006259, 'Novo Horizonte do Sul'],
  [5006275, 'Paraíso das Águas'],
  [5006309, 'Paranaíba'],
  [5006358, 'Paranhos'],
  [5006408, 'Pedro Gomes'],
  [5006606, 'Ponta Porã'],
  [5006903, 'Porto Murtinho'],
  [5007109, 'Ribas do Rio Pardo'],
  [5007208, 'Rio Brilhante'],
  [5007307, 'Rio Negro'],
  [5007406, 'Rio Verde de Mato Grosso'],
  [5007505, 'Rochedo'],
  [5007554, 'Santa Rita do Pardo'],
  [5007695, 'São Gabriel do Oeste'],
  [5007703, 'Sete Quedas'],
  [5007802, 'Selvíria'],
  [5007901, 'Sidrolândia'],
  [5007935, 'Sonora'],
  [5007950, 'Tacuru'],
  [5007976, 'Taquarussu'],
  [5008008, 'Terenos'],
  [5008305, 'Três Lagoas'],
  [5008404, 'Vicentina'],
  [5100102, 'Acorizal'],
  [5100201, 'Água Boa'],
  [5100250, 'Alta Floresta'],
  [5100300, 'Alto Araguaia'],
  [5100359, 'Alto Boa Vista'],
  [5100409, 'Alto Garças'],
  [5100508, 'Alto Paraguai'],
  [5100607, 'Alto Taquari'],
  [5100805, 'Apiacás'],
  [5101001, 'Araguaiana'],
  [5101209, 'Araguainha'],
  [5101258, 'Araputanga'],
  [5101308, 'Arenápolis'],
  [5101407, 'Aripuanã'],
  [5101605, 'Barão de Melgaço'],
  [5101704, 'Barra do Bugres'],
  [5101803, 'Barra do Garças'],
  [5101852, 'Bom Jesus do Araguaia'],
  [5101902, 'Brasnorte'],
  [5102504, 'Cáceres'],
  [5102603, 'Campinápolis'],
  [5102637, 'Campo Novo do Parecis'],
  [5102678, 'Campo Verde'],
  [5102686, 'Campos de Júlio'],
  [5102694, 'Canabrava do Norte'],
  [5102702, 'Canarana'],
  [5102793, 'Carlinda'],
  [5102850, 'Castanheira'],
  [5103007, 'Chapada dos Guimarães'],
  [5103056, 'Cláudia'],
  [5103106, 'Cocalinho'],
  [5103205, 'Colíder'],
  [5103254, 'Colniza'],
  [5103304, 'Comodoro'],
  [5103353, 'Confresa'],
  [5103361, "Conquista D'Oeste"],
  [5103379, 'Cotriguaçu'],
  [5103403, 'Cuiabá'],
  [5103437, 'Curvelândia'],
  [5103452, 'Denise'],
  [5103502, 'Diamantino'],
  [5103601, 'Dom Aquino'],
  [5103700, 'Feliz Natal'],
  [5103809, "Figueirópolis D'Oeste"],
  [5103858, 'Gaúcha do Norte'],
  [5103908, 'General Carneiro'],
  [5103957, "Glória D'Oeste"],
  [5104104, 'Guarantã do Norte'],
  [5104203, 'Guiratinga'],
  [5104500, 'Indiavaí'],
  [5104526, 'Ipiranga do Norte'],
  [5104542, 'Itanhangá'],
  [5104559, 'Itaúba'],
  [5104609, 'Itiquira'],
  [5104807, 'Jaciara'],
  [5104906, 'Jangada'],
  [5105002, 'Jauru'],
  [5105101, 'Juara'],
  [5105150, 'Juína'],
  [5105176, 'Juruena'],
  [5105200, 'Juscimeira'],
  [5105234, "Lambari D'Oeste"],
  [5105259, 'Lucas do Rio Verde'],
  [5105309, 'Luciara'],
  [5105507, 'Vila Bela da Santíssima Trindade'],
  [5105580, 'Marcelândia'],
  [5105606, 'Matupá'],
  [5105622, "Mirassol d'Oeste"],
  [5105903, 'Nobres'],
  [5106000, 'Nortelândia'],
  [5106109, 'Nossa Senhora do Livramento'],
  [5106158, 'Nova Bandeirantes'],
  [5106174, 'Nova Nazaré'],
  [5106182, 'Nova Lacerda'],
  [5106190, 'Nova Santa Helena'],
  [5106208, 'Nova Brasilândia'],
  [5106216, 'Nova Canaã do Norte'],
  [5106224, 'Nova Mutum'],
  [5106232, 'Nova Olímpia'],
  [5106240, 'Nova Ubiratã'],
  [5106257, 'Nova Xavantina'],
  [5106265, 'Novo Mundo'],
  [5106273, 'Novo Horizonte do Norte'],
  [5106281, 'Novo São Joaquim'],
  [5106299, 'Paranaíta'],
  [5106307, 'Paranatinga'],
  [5106315, 'Novo Santo Antônio'],
  [5106372, 'Pedra Preta'],
  [5106422, 'Peixoto de Azevedo'],
  [5106455, 'Planalto da Serra'],
  [5106505, 'Poconé'],
  [5106653, 'Pontal do Araguaia'],
  [5106703, 'Ponte Branca'],
  [5106752, 'Pontes e Lacerda'],
  [5106778, 'Porto Alegre do Norte'],
  [5106802, 'Porto dos Gaúchos'],
  [5106828, 'Porto Esperidião'],
  [5106851, 'Porto Estrela'],
  [5107008, 'Poxoréu'],
  [5107040, 'Primavera do Leste'],
  [5107065, 'Querência'],
  [5107107, 'São José dos Quatro Marcos'],
  [5107156, 'Reserva do Cabaçal'],
  [5107180, 'Ribeirão Cascalheira'],
  [5107198, 'Ribeirãozinho'],
  [5107206, 'Rio Branco'],
  [5107248, 'Santa Carmem'],
  [5107263, 'Santo Afonso'],
  [5107297, 'São José do Povo'],
  [5107305, 'São José do Rio Claro'],
  [5107354, 'São José do Xingu'],
  [5107404, 'São Pedro da Cipa'],
  [5107578, 'Rondolândia'],
  [5107602, 'Rondonópolis'],
  [5107701, 'Rosário Oeste'],
  [5107743, 'Santa Cruz do Xingu'],
  [5107750, 'Salto do Céu'],
  [5107768, 'Santa Rita do Trivelato'],
  [5107776, 'Santa Terezinha'],
  [5107792, 'Santo Antônio do Leste'],
  [5107800, 'Santo Antônio do Leverger'],
  [5107859, 'São Félix do Araguaia'],
  [5107875, 'Sapezal'],
  [5107883, 'Serra Nova Dourada'],
  [5107909, 'Sinop'],
  [5107925, 'Sorriso'],
  [5107941, 'Tabaporã'],
  [5107958, 'Tangará da Serra'],
  [5108006, 'Tapurah'],
  [5108055, 'Terra Nova do Norte'],
  [5108105, 'Tesouro'],
  [5108204, 'Torixoréu'],
  [5108303, 'União do Sul'],
  [5108352, 'Vale de São Domingos'],
  [5108402, 'Várzea Grande'],
  [5108501, 'Vera'],
  [5108600, 'Vila Rica'],
  [5108808, 'Nova Guarita'],
  [5108857, 'Nova Marilândia'],
  [5108907, 'Nova Maringá'],
  [5108956, 'Nova Monte Verde'],
  [5200050, 'Abadia de Goiás'],
  [5200100, 'Abadiânia'],
  [5200134, 'Acreúna'],
  [5200159, 'Adelândia'],
  [5200175, 'Água Fria de Goiás'],
  [5200209, 'Água Limpa'],
  [5200258, 'Águas Lindas de Goiás'],
  [5200308, 'Alexânia'],
  [5200506, 'Aloândia'],
  [5200555, 'Alto Horizonte'],
  [5200605, 'Alto Paraíso de Goiás'],
  [5200803, 'Alvorada do Norte'],
  [5200829, 'Amaralina'],
  [5200852, 'Americano do Brasil'],
  [5200902, 'Amorinópolis'],
  [5201108, 'Anápolis'],
  [5201207, 'Anhanguera'],
  [5201306, 'Anicuns'],
  [5201405, 'Aparecida de Goiânia'],
  [5201454, 'Aparecida do Rio Doce'],
  [5201504, 'Aporé'],
  [5201603, 'Araçu'],
  [5201702, 'Aragarças'],
  [5201801, 'Aragoiânia'],
  [5202155, 'Araguapaz'],
  [5202353, 'Arenópolis'],
  [5202502, 'Aruanã'],
  [5202601, 'Aurilândia'],
  [5202809, 'Avelinópolis'],
  [5203104, 'Baliza'],
  [5203203, 'Barro Alto'],
  [5203302, 'Bela Vista de Goiás'],
  [5203401, 'Bom Jardim de Goiás'],
  [5203500, 'Bom Jesus de Goiás'],
  [5203559, 'Bonfinópolis'],
  [5203575, 'Bonópolis'],
  [5203609, 'Brazabrantes'],
  [5203807, 'Britânia'],
  [5203906, 'Buriti Alegre'],
  [5203939, 'Buriti de Goiás'],
  [5203962, 'Buritinópolis'],
  [5204003, 'Cabeceiras'],
  [5204102, 'Cachoeira Alta'],
  [5204201, 'Cachoeira de Goiás'],
  [5204250, 'Cachoeira Dourada'],
  [5204300, 'Caçu'],
  [5204409, 'Caiapônia'],
  [5204508, 'Caldas Novas'],
  [5204557, 'Caldazinha'],
  [5204607, 'Campestre de Goiás'],
  [5204656, 'Campinaçu'],
  [5204706, 'Campinorte'],
  [5204805, 'Campo Alegre de Goiás'],
  [5204854, 'Campo Limpo de Goiás'],
  [5204904, 'Campos Belos'],
  [5204953, 'Campos Verdes'],
  [5205000, 'Carmo do Rio Verde'],
  [5205059, 'Castelândia'],
  [5205109, 'Catalão'],
  [5205208, 'Caturaí'],
  [5205307, 'Cavalcante'],
  [5205406, 'Ceres'],
  [5205455, 'Cezarina'],
  [5205471, 'Chapadão do Céu'],
  [5205497, 'Cidade Ocidental'],
  [5205513, 'Cocalzinho de Goiás'],
  [5205521, 'Colinas do Sul'],
  [5205703, 'Córrego do Ouro'],
  [5205802, 'Corumbá de Goiás'],
  [5205901, 'Corumbaíba'],
  [5206206, 'Cristalina'],
  [5206305, 'Cristianópolis'],
  [5206404, 'Crixás'],
  [5206503, 'Cromínia'],
  [5206602, 'Cumari'],
  [5206701, 'Damianópolis'],
  [5206800, 'Damolândia'],
  [5206909, 'Davinópolis'],
  [5207105, 'Diorama'],
  [5207253, 'Doverlândia'],
  [5207352, 'Edealina'],
  [5207402, 'Edéia'],
  [5207501, 'Estrela do Norte'],
  [5207535, 'Faina'],
  [5207600, 'Fazenda Nova'],
  [5207808, 'Firminópolis'],
  [5207907, 'Flores de Goiás'],
  [5208004, 'Formosa'],
  [5208103, 'Formoso'],
  [5208152, 'Gameleira de Goiás'],
  [5208301, 'Divinópolis de Goiás'],
  [5208400, 'Goianápolis'],
  [5208509, 'Goiandira'],
  [5208608, 'Goianésia'],
  [5208707, 'Goiânia'],
  [5208806, 'Goianira'],
  [5208905, 'Goiás'],
  [5209101, 'Goiatuba'],
  [5209150, 'Gouvelândia'],
  [5209200, 'Guapó'],
  [5209291, 'Guaraíta'],
  [5209408, 'Guarani de Goiás'],
  [5209457, 'Guarinos'],
  [5209606, 'Heitoraí'],
  [5209705, 'Hidrolândia'],
  [5209804, 'Hidrolina'],
  [5209903, 'Iaciara'],
  [5209937, 'Inaciolândia'],
  [5209952, 'Indiara'],
  [5210000, 'Inhumas'],
  [5210109, 'Ipameri'],
  [5210158, 'Ipiranga de Goiás'],
  [5210208, 'Iporá'],
  [5210307, 'Israelândia'],
  [5210406, 'Itaberaí'],
  [5210562, 'Itaguari'],
  [5210604, 'Itaguaru'],
  [5210802, 'Itajá'],
  [5210901, 'Itapaci'],
  [5211008, 'Itapirapuã'],
  [5211206, 'Itapuranga'],
  [5211305, 'Itarumã'],
  [5211404, 'Itauçu'],
  [5211503, 'Itumbiara'],
  [5211602, 'Ivolândia'],
  [5211701, 'Jandaia'],
  [5211800, 'Jaraguá'],
  [5211909, 'Jataí'],
  [5212006, 'Jaupaci'],
  [5212055, 'Jesúpolis'],
  [5212105, 'Joviânia'],
  [5212204, 'Jussara'],
  [5212253, 'Lagoa Santa'],
  [5212303, 'Leopoldo de Bulhões'],
  [5212501, 'Luziânia'],
  [5212600, 'Mairipotaba'],
  [5212709, 'Mambaí'],
  [5212808, 'Mara Rosa'],
  [5212907, 'Marzagão'],
  [5212956, 'Matrinchã'],
  [5213004, 'Maurilândia'],
  [5213053, 'Mimoso de Goiás'],
  [5213087, 'Minaçu'],
  [5213103, 'Mineiros'],
  [5213400, 'Moiporá'],
  [5213509, 'Monte Alegre de Goiás'],
  [5213707, 'Montes Claros de Goiás'],
  [5213756, 'Montividiu'],
  [5213772, 'Montividiu do Norte'],
  [5213806, 'Morrinhos'],
  [5213855, 'Morro Agudo de Goiás'],
  [5213905, 'Mossâmedes'],
  [5214002, 'Mozarlândia'],
  [5214051, 'Mundo Novo'],
  [5214101, 'Mutunópolis'],
  [5214408, 'Nazário'],
  [5214507, 'Nerópolis'],
  [5214606, 'Niquelândia'],
  [5214705, 'Nova América'],
  [5214804, 'Nova Aurora'],
  [5214838, 'Nova Crixás'],
  [5214861, 'Nova Glória'],
  [5214879, 'Nova Iguaçu de Goiás'],
  [5214903, 'Nova Roma'],
  [5215009, 'Nova Veneza'],
  [5215207, 'Novo Brasil'],
  [5215231, 'Novo Gama'],
  [5215256, 'Novo Planalto'],
  [5215306, 'Orizona'],
  [5215405, 'Ouro Verde de Goiás'],
  [5215504, 'Ouvidor'],
  [5215603, 'Padre Bernardo'],
  [5215652, 'Palestina de Goiás'],
  [5215702, 'Palmeiras de Goiás'],
  [5215801, 'Palmelo'],
  [5215900, 'Palminópolis'],
  [5216007, 'Panamá'],
  [5216304, 'Paranaiguara'],
  [5216403, 'Paraúna'],
  [5216452, 'Perolândia'],
  [5216809, 'Petrolina de Goiás'],
  [5216908, 'Pilar de Goiás'],
  [5217104, 'Piracanjuba'],
  [5217203, 'Piranhas'],
  [5217302, 'Pirenópolis'],
  [5217401, 'Pires do Rio'],
  [5217609, 'Planaltina'],
  [5217708, 'Pontalina'],
  [5218003, 'Porangatu'],
  [5218052, 'Porteirão'],
  [5218102, 'Portelândia'],
  [5218300, 'Posse'],
  [5218391, 'Professor Jamil'],
  [5218508, 'Quirinópolis'],
  [5218607, 'Rialma'],
  [5218706, 'Rianápolis'],
  [5218789, 'Rio Quente'],
  [5218805, 'Rio Verde'],
  [5218904, 'Rubiataba'],
  [5219001, 'Sanclerlândia'],
  [5219100, 'Santa Bárbara de Goiás'],
  [5219209, 'Santa Cruz de Goiás'],
  [5219258, 'Santa Fé de Goiás'],
  [5219308, 'Santa Helena de Goiás'],
  [5219357, 'Santa Isabel'],
  [5219407, 'Santa Rita do Araguaia'],
  [5219456, 'Santa Rita do Novo Destino'],
  [5219506, 'Santa Rosa de Goiás'],
  [5219605, 'Santa Tereza de Goiás'],
  [5219704, 'Santa Terezinha de Goiás'],
  [5219712, 'Santo Antônio da Barra'],
  [5219738, 'Santo Antônio de Goiás'],
  [5219753, 'Santo Antônio do Descoberto'],
  [5219803, 'São Domingos'],
  [5219902, 'São Francisco de Goiás'],
  [5220009, "São João d'Aliança"],
  [5220058, 'São João da Paraúna'],
  [5220108, 'São Luís de Montes Belos'],
  [5220157, 'São Luiz do Norte'],
  [5220207, 'São Miguel do Araguaia'],
  [5220264, 'São Miguel do Passa Quatro'],
  [5220280, 'São Patrício'],
  [5220405, 'São Simão'],
  [5220454, 'Senador Canedo'],
  [5220504, 'Serranópolis'],
  [5220603, 'Silvânia'],
  [5220686, 'Simolândia'],
  [5220702, "Sítio d'Abadia"],
  [5221007, 'Taquaral de Goiás'],
  [5221080, 'Teresina de Goiás'],
  [5221197, 'Terezópolis de Goiás'],
  [5221304, 'Três Ranchos'],
  [5221403, 'Trindade'],
  [5221452, 'Trombas'],
  [5221502, 'Turvânia'],
  [5221551, 'Turvelândia'],
  [5221577, 'Uirapuru'],
  [5221601, 'Uruaçu'],
  [5221700, 'Uruana'],
  [5221809, 'Urutaí'],
  [5221858, 'Valparaíso de Goiás'],
  [5221908, 'Varjão'],
  [5222005, 'Vianópolis'],
  [5222054, 'Vicentinópolis'],
  [5222203, 'Vila Boa'],
  [5222302, 'Vila Propício'],
  [5300108, 'Brasília'],
];

/**
 * Map of `Municipio` mappers.
 *
 * You first index into it using a `Estado`'s (state)
 * code to get a handle to a mapper
 * that maps it's `Municipios` (cities).
 *
 * @example
 *   const state = Estados.codes()[0];
 *   const mapper = MunicipiosByEstado[state];
 *   const allOfTheStatesCities = mapper.codes().map(
 *     (code) => mapper.getByCode(code)
 *   );
 *
 */
const MunicipiosByEstado: Record<string, Mapper> = {};

const getEstadoCodeByMunicipioCode = (code: number): number | undefined =>
  code != null ? parseInt(`${code}`.slice(0, 2)) : undefined;

const getEstadoByMunicipioCode = (code: number): string | undefined => {
  const estadoCode = getEstadoCodeByMunicipioCode(code);
  return UFs.getByCode(estadoCode);
};

const municipiosByEstadoCode: Record<string, [number, string][]> = groupBy(
  entries,
  ([code, _value]) => getEstadoCodeByMunicipioCode(code),
);

for (const [code, uf] of UFs.entries()) {
  const matchingMunicipios = municipiosByEstadoCode[code] as [
    string | number,
    string,
  ][];
  MunicipiosByEstado[uf] = Mapper.from(matchingMunicipios);
}

export { MunicipiosByEstado, getEstadoByMunicipioCode };
