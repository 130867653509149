import { useMemo, useState } from 'react';

import {
  IconAlertTriangleFilled,
  IconChevronDown,
  IconChevronUp,
  IconCircleCheckFilled,
} from '@tabler/icons-react';
import dayjs from 'dayjs';

import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';

import { DataGrid, useDataGrid } from '@octopus/ui/data-grid';

import { MultiEditResult, MultiEditStatusParams } from './types';

export type MultiEditStatusProps<Item extends { id: string }, NewParameters> = {
  newParameters: NewParameters;
  results: MultiEditResult<Item>[];
  effectiveDate: string;
  onConfirm: () => void;
  params: MultiEditStatusParams<Item, NewParameters>;
};

export function MultiEditStatus<Item extends { id: string }, NewParameters>({
  newParameters,
  results,
  effectiveDate,
  onConfirm,
  params: { failureMessage, getSuccessColumnsDef, getFailureColumnsDef },
}: MultiEditStatusProps<Item, NewParameters>) {
  const success = useMemo(() => results.filter((r) => r.success), [results]);
  const error = useMemo(() => results.filter((r) => !r.success), [results]);
  return (
    <>
      <Box display="flex" flexDirection="column" gap={4} mb={16} pb={12}>
        {success.length > 0 ? (
          <>
            <PageHeader
              title="Atribuição parcialmente concluída"
              description="Alguns colaboradores foram atribuídos corretamente; no entanto, ocorreram erros com outros. Por favor, confira os detalhes abaixo."
            />
            <SuccessfulEditTable
              newParameters={newParameters}
              effectiveDate={effectiveDate}
              results={success}
              getSuccessColumnsDef={getSuccessColumnsDef}
            />
          </>
        ) : (
          <PageHeader
            title="Falha na atribuição"
            description="Não foi possível realizar a atribuição dos colaboradores. Por favor, confira os detalhes abaixo."
          />
        )}

        <FailedToEditTable
          title={failureMessage.title}
          description={failureMessage.description}
          results={error}
          getFailureColumnsDef={getFailureColumnsDef}
          newParameters={newParameters}
        />
      </Box>
      <Box
        display="flex"
        gap={1}
        py={1}
        px={3}
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'end',
          borderTop: `1px solid ${theme.palette.strokes.light}`,
          background: 'rgba(247, 247, 247, 0.90)',
          backdropFilter: 'blur(4px)',
          position: 'fixed',
          bottom: 0,
          right: 0,
          left: 0,
        })}
      >
        <Button color="primaryAlt" size="large" onClick={onConfirm}>
          Concluir
        </Button>
      </Box>
    </>
  );
}

function PageHeader({
  title,
  description,
}: {
  title: string;
  description?: string;
}) {
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Typography variant="h5" fontWeight="700">
        {title}
      </Typography>
      <Typography variant="body2" fontWeight="400">
        {description}
      </Typography>
      <Divider />
    </Box>
  );
}

function SuccessfulEditTable<
  Item extends {
    id: string;
  },
  NewParameters,
>({
  newParameters,
  results,
  effectiveDate,
  getSuccessColumnsDef,
}: {
  newParameters: NewParameters;
  results: MultiEditResult<Item>[];
  effectiveDate: string;
  getSuccessColumnsDef: MultiEditStatusParams<
    Item,
    NewParameters
  >['getSuccessColumnsDef'];
}) {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const { sortingProps, paginationProps } = useDataGrid({
    key: `multi-edit-success`,
    pagination: {
      rowsPerPageOptions: [5, 25, 50, 100],
    },
  });

  const date = dayjs(effectiveDate, 'YYYY-MM-DD').format('DD/MM/YYYY');

  const resultsToList = results
    .slice(paginationProps.page * paginationProps.rowsPerPage)
    .slice(0, paginationProps.rowsPerPage);

  return (
    <Box display="flex" flexDirection="column" gap={2} pt={3}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap={1} alignItems="center">
          <IconCircleCheckFilled size={20} color={theme.palette.success.main} />
          <Typography variant="body1" fontWeight="700">
            Alterações efetivadas
          </Typography>
        </Box>
        <IconButton onClick={() => setExpanded((expanded) => !expanded)}>
          {expanded ? (
            <IconChevronUp size={20} />
          ) : (
            <IconChevronDown size={20} />
          )}
        </IconButton>
      </Box>
      {expanded ? (
        <DataGrid
          sortingProps={sortingProps}
          paginationProps={paginationProps}
          totalRowCount={results.length}
          getRowId={(row) => row.item.id}
          rows={resultsToList}
          getRowSx={() => ({ height: '56px' })}
          columns={getSuccessColumnsDef(newParameters, date)}
        />
      ) : (
        <Divider />
      )}
    </Box>
  );
}

function FailedToEditTable<
  Item extends {
    id: string;
  },
  NewParameters,
>({
  title,
  description,
  results,
  newParameters,
  getFailureColumnsDef,
}: {
  title: string;
  description?: string;
  results: MultiEditResult<Item>[];
  newParameters: NewParameters;
  getFailureColumnsDef: MultiEditStatusParams<
    Item,
    NewParameters
  >['getFailureColumnsDef'];
}) {
  const theme = useTheme();
  const { sortingProps, paginationProps } = useDataGrid({
    key: `multi-edit-failure`,
    pagination: {
      rowsPerPageOptions: [5, 25, 50, 100],
    },
  });

  const resultsToList = results
    .slice(paginationProps.page * paginationProps.rowsPerPage)
    .slice(0, paginationProps.rowsPerPage);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" gap={1} alignItems="center">
        <IconAlertTriangleFilled size={20} color={theme.palette.error.light} />
        <Typography variant="body1" fontWeight="700">
          {title}
        </Typography>
      </Box>
      {description && (
        <Box>
          <Alert severity="error">{description}</Alert>
        </Box>
      )}
      <Box>
        <DataGrid
          sortingProps={sortingProps}
          paginationProps={paginationProps}
          totalRowCount={results.length}
          getRowId={(row) => row.item.id}
          rows={resultsToList}
          getRowSx={() => ({ minHeight: '56px', height: 'auto' })}
          columns={getFailureColumnsDef(newParameters)}
        />
      </Box>
    </Box>
  );
}
