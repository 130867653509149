import { useQuery } from '@tanstack/react-query';

import { fetchGetAdmissionDraftByUser } from '@octopus/api';
import {
  admissionDraftStatuses,
  membershipTypes,
} from '@octopus/onboarding-types';

import { MembershipContext, UserContext } from '../types';

import { appContextQueryConfig } from './constants';

export const useAdmissionDraft = (
  membership: MembershipContext,
  user: UserContext,
) => {
  const { membershipType, organizationId } = membership || {};
  const { userId, personId } = user || {};

  const isOwnerOrInternal =
    membershipType === membershipTypes.owner ||
    membershipType === membershipTypes.internal;

  const doesNotHavePersonId = !personId;

  return useQuery({
    queryKey: ['admissionDraft', organizationId, userId],
    queryFn: () =>
      fetchGetAdmissionDraftByUser({
        pathParams: { organizationId, userId },
      }).then((draft) => {
        const draftId = draft?.draftId;
        return draft?.draftStatus !== admissionDraftStatuses.archived
          ? { draftId }
          : null;
      }),
    enabled:
      !!organizationId && !!userId && isOwnerOrInternal && doesNotHavePersonId,
    ...appContextQueryConfig,
  });
};
