import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  AdmissionDraftEntry,
  MembershipTypes,
  useGetAdmissionDraft,
} from '@octopus/api';

import { BackButton } from '../../../modules/components/BackButton';
import { PageAlert } from '../../../modules/components/PageAlert';
import {
  CompanyContext,
  MembershipContext,
  MembershipOption,
  OrganizationContext,
} from '../../../modules/types';
import { LoadingScene } from '../components/AdmissionDraftLoadingScene';
import { AdmissionDraftInputPage } from '../new/page';

type Props = {
  organizationId: string;
  companyContext: CompanyContext;
  organizationContext: OrganizationContext;
  membershipProps: {
    membershipType: MembershipTypes;
    membership: MembershipContext;
    membershipOptions: MembershipOption[];
  };
};

export function AdmissionDraftEditPage({
  organizationId,
  companyContext,
  organizationContext,
  membershipProps,
}: Props) {
  const { draftId } = useParams<{ draftId: string }>();
  const [searchParams] = useSearchParams();

  const reviewMode = searchParams.get('reviewMode') === 'true';
  const finishReview = searchParams.get('finishReview') === 'true';
  const [admissionDraft, setAdmissionDraft] = useState<AdmissionDraftEntry>();

  const shouldFetch =
    !!organizationId &&
    !!draftId &&
    (!admissionDraft || draftId !== admissionDraft?.draftId);

  const admissionDraftQuery = useGetAdmissionDraft(
    {
      pathParams: {
        organizationId: organizationId ?? '',
        draftId: draftId ?? '',
      },
      headers: {
        'Force-Refresh': 'true',
      },
    },
    {
      queryHash: `admission-draft-edit-${organizationId}-${draftId}`,
      enabled: shouldFetch,
      staleTime: 1,
      retry: 10,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  const { error, data, isLoading, isFetching } = admissionDraftQuery;

  useEffect(() => {
    if (data != null && admissionDraft == null) {
      setAdmissionDraft(data);
    }
  }, [data, admissionDraft, draftId]);

  if (isLoading || isFetching) {
    return <LoadingScene />;
  }

  if (!isLoading && !isFetching && error) {
    console.error(
      `Failed to load admission: ${draftId}. Error: ${JSON.stringify(error)}`,
    );
    return (
      <>
        <BackButton destination="/admissions" />
        <PageAlert
          message="Erro ao carregar dados da admissão"
          severity="error"
          showRetryMessage={true}
        />
      </>
    );
  }

  return (
    <AdmissionDraftInputPage
      organizationId={organizationId}
      companyContext={companyContext}
      organizationContext={organizationContext}
      admissionDraftEntry={admissionDraft}
      draftId={draftId}
      membershipProps={membershipProps}
      reviewMode={reviewMode}
      finishReview={finishReview}
    />
  );
}
