import { useMemo } from 'react';

import { Typography } from '@mui/material';

import TimesheetFileErrorIcon from '../../../../../../../../assets/fileError.svg';
import { TimesheetDownloadTemplate } from '../../TimesheetDownloadTemplate';
import {
  TimesheetFileDefinition,
  TimesheetStepError,
  TimesheetStepWarning,
} from '../types';
import { getErrorsMappingByCode } from '../utils';

import { TimesheetIssues } from './TimesheetIssues';
import { TimesheetValidationActions } from './TimesheetValidationActions';
import { TimesheetValidationContainer } from './TimesheetValidationContainer';

export function TimesheetValidationError({
  onCancel,
  errors,
  warnings,
  fileDefinition,
}: {
  errors: TimesheetStepError[];
  warnings: TimesheetStepWarning[];
  fileDefinition: TimesheetFileDefinition;
  onCancel: () => void;
}) {
  const errorsMapByCode = useMemo(
    () => getErrorsMappingByCode([...errors, ...warnings]),
    [errors, warnings],
  );
  const errorsCount = useMemo(
    () => errors.length + warnings.length,
    [errors, warnings],
  );

  return (
    <TimesheetValidationContainer sx={{ gap: (theme) => theme.spacing(1.5) }}>
      <TimesheetValidationActions
        onCancel={onCancel}
        cancelButtonText="Tentar novamente"
        icon={
          <img
            src={TimesheetFileErrorIcon}
            height={88}
            width={88}
            alt="Arquivo com lançamentos inválidos"
          />
        }
      >
        <Typography variant="body1" align="center">
          A importação não pode ser feita devido{' '}
          {errorsCount > 1 ? (
            <>
              aos <b>erros abaixo</b>
            </>
          ) : (
            <>
              ao <b>erro abaixo</b>
            </>
          )}
          .
        </Typography>
        <Typography variant="body1" align="center">
          Você pode importar novamente após{' '}
          {errorsCount > 1 ? 'corrigi-los' : 'corrigi-lo'}.
        </Typography>
      </TimesheetValidationActions>
      <TimesheetIssues errors={Object.values(errorsMapByCode)} type="error" />
      <TimesheetDownloadTemplate fileDefinition={fileDefinition} />
    </TimesheetValidationContainer>
  );
}
