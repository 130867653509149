import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { LicenseManager } from 'ag-grid-charts-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import dayjs from 'dayjs';

import { LoadingButton } from '@mui/lab';
import { Box, Divider, Typography } from '@mui/material';

import {
  PayrollInputsConfig,
  PayrollInputsList,
  PayrollInputsTargets,
  PayrollTypes,
  fetchPostPayrollInputsForPeriod,
  fetchSearchAllContracts,
} from '@octopus/api';

import { ErrorToast } from '../../[period]/[type]/inputs/ErrorToast';
import { useSubmissionState } from '../../[period]/[type]/inputs/useSubmissionState';
import { RPANavigation } from '../navigationUtils';
import {
  CancelDialog,
  CreateRPAsDialog,
  MissingCPFsDialog,
} from '../RpaDialogs';

import InputRpaTable from './inputRpaTable';
import { sortByCPFList } from './utils';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-058343}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{WGMI_TECNOLOGIAS_LTDA.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Tako}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Tako}_need_to_be_licensed___{Tako}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{12_May_2025}____[v3]_[0102]_MTc0NzAwNDQwMDAwMA==fb0c9b10a095ec0962e15ce924aa04a2',
);

const CREATE_RPA_PREFIX = 'create-rpa';

export type PayrollInputsTableProps = {
  organizationId: string;
  companyId: string;
  period: string;
  type: PayrollTypes;
  config: PayrollInputsConfig;
  data: PayrollInputsList;
  mode: 'single_edit' | 'multiple_edit' | 'create';
  selectedLegalEntityRef: MutableRefObject<string>;
  cpfListRef?: MutableRefObject<string[]>;
  onSearchTrigger?: (trigger: () => void) => void;
  onLegalEntityChange?: (handler: () => void) => void;
};

function RPASubmission({
  organizationId,
  companyId,
  period,
  type,
  config,
  data,
  mode,
  cpfListRef,
  onSearchTrigger,
  selectedLegalEntityRef,
  onLegalEntityChange,
}: PayrollInputsTableProps) {
  const navigate = useNavigate();
  const [showValidation, setShowValidation] = useState(false);
  const [submissionState, setSubmissionState] = useState<{
    isSubmitting: boolean;
    errorSubmitting?: boolean;
    errorMessage?: string;
  }>({ isSubmitting: false });
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [createRpaDialogOpen, setCreateRpaDialogOpen] = useState(false);
  const [missingCPFs, setMissingCPFs] = useState<string[]>([]);
  const {
    rpas,
    hasPayrollBeenEdited,
    getEditCount,
    getSubmissionInput,
    getNumberOfValidatedRows,
    hasValidationErrors,
    getErrorCount,
  } = useSubmissionState(config, data, getRPAValidation(period));
  const handleLegalEntityChange = useCallback(() => {
    rpas.edit.updateAllLegalEntityIds(selectedLegalEntityRef.current);
  }, [selectedLegalEntityRef]);

  // Register the handler with the parent
  useEffect(() => {
    if (onLegalEntityChange) {
      onLegalEntityChange(handleLegalEntityChange);
    }
  }, [onLegalEntityChange, handleLegalEntityChange]);

  const searchCpfsQuery = useQuery({
    queryKey: ['searchCpfsQuery', organizationId, companyId, period, type],
    queryFn: async () =>
      searchCpfs(cpfListRef, organizationId, companyId, setMissingCPFs),
    enabled: !!organizationId && !!companyId && missingCPFs.length !== 0,
  });

  const resetValidation = useCallback(() => {
    const timeoutId = setTimeout(() => {
      if (showValidation && getErrorCount('rpa') === 0) {
        setShowValidation(false);
      }
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [showValidation, getErrorCount]);

  useEffect(() => {
    resetValidation();
  }, [rpas.data, resetValidation]);

  // Create a memoized trigger function using the refetch function
  const triggerSearch = useCallback(() => {
    searchCpfsQuery.refetch();
  }, [searchCpfsQuery]);

  // Register the trigger function with the parent
  useEffect(() => {
    if (onSearchTrigger) {
      onSearchTrigger(triggerSearch);
    }
  }, [onSearchTrigger, triggerSearch]);

  useEffect(() => {
    if (searchCpfsQuery.data) {
      for (const autonomo of Object.values(searchCpfsQuery.data)) {
        rpas.edit.addEmployee({
          payrollId: `${CREATE_RPA_PREFIX}-${autonomo.contractId}`,
          name: autonomo.name,
          contractId: autonomo.contractId,
          employeeId: autonomo.employeeId,
          personId: autonomo.personId,
          inputs: {},
          legalEntityId: selectedLegalEntityRef.current,
        });
      }
    }
  }, [searchCpfsQuery.data]);

  const submitInputs = async () => {
    setShowValidation(true);
    setSubmissionState({ isSubmitting: true });

    if (getNumberOfValidatedRows('rpa') !== rpas.data.length) {
      setSubmissionState({
        isSubmitting: false,
        errorSubmitting: true,
        errorMessage: 'Por favor, preencha todas as linhas',
      });
      setCreateRpaDialogOpen(false);
      return;
    }

    const submissionInput = getSubmissionInput();
    try {
      setCreateRpaDialogOpen(false);
      const { submissionId } = await fetchPostPayrollInputsForPeriod({
        body: submissionInput,
        pathParams: {
          organizationId,
          companyId,
          periodId: period,
          payrollType: type,
        },
      });
      setSubmissionState({ isSubmitting: false });
      navigate(RPANavigation.rpaInputsSubmissionPath(period, submissionId));
    } catch (error) {
      console.error(error);
      const errorMessage =
        error instanceof Error ? error.message : 'An unexpected error occurred';
      setSubmissionState({
        isSubmitting: false,
        errorSubmitting: true,
        errorMessage,
      });
    }
  };

  const conditionalHasValidationErrors = (
    payrollId: string,
    inputId: string,
    target: PayrollInputsTargets,
  ) => {
    if (!showValidation) return false;
    const row = rpas.data.find((row) => row.payrollId === payrollId);
    if (!row?.contractId) return false;
    return hasValidationErrors(payrollId, inputId, target);
  };

  return (
    <Box>
      <Box width="100%" height={`calc(100vh)`}>
        <InputRpaTable
          organizationId={organizationId}
          companyId={companyId}
          config={config}
          state={rpas}
          hasPayrollBeenEdited={hasPayrollBeenEdited}
          mode={mode}
          legalEntityIdRef={selectedLegalEntityRef}
          hasValidationErrors={conditionalHasValidationErrors}
        />
      </Box>
      <CancelDialog
        open={cancelDialogOpen}
        setOpen={setCancelDialogOpen}
        organizationId={organizationId}
        companyId={companyId}
        payrollId={Object.keys(data.payrolls)[0] ?? ''} // Get the first payroll id
      />
      <CreateRPAsDialog
        open={createRpaDialogOpen}
        setOpen={setCreateRpaDialogOpen}
        onClick={submitInputs}
      />
      <ActionBar
        changes={getEditCount()}
        isLoading={submissionState.isSubmitting}
        onClick={() => setCreateRpaDialogOpen(true)}
        onCancel={() => setCancelDialogOpen(true)}
        mode={mode}
        totalRows={rpas.data.length}
        filledRows={getNumberOfValidatedRows('rpa')}
        errorCount={showValidation ? getErrorCount('rpa') : 0}
      />
      <ErrorToast
        show={submissionState.errorSubmitting}
        close={() => setSubmissionState({ isSubmitting: false })}
        message={submissionState.errorMessage}
      />
      <MissingCPFsDialog
        open={missingCPFs.length > 0}
        resetMissingCPFs={() => setMissingCPFs([])}
        missingCPFs={missingCPFs}
      />
    </Box>
  );
}

function getRPAValidation(period: string): Partial<{
  [key in PayrollInputsTargets]: { [key: string]: (value: string) => boolean };
}> {
  return {
    rpa: {
      serviceValue: (value) => {
        if (!value || value === '') return false;
        const numValue = parseFloat(value);
        return !isNaN(numValue) && numValue > 0;
      },
      paymentDate: (value) => {
        if (!value || value === '') return false;
        const date = dayjs(value, 'YYYY-MM-DD', true);
        if (!date.isValid()) return false;

        // Check if date is not before the period month
        const periodDate = dayjs(period, 'YYYY-MM');
        return date.isSameOrAfter(periodDate, 'month');
      },
      aliquotaISS: (value) => {
        if (!value || value === '') return false;
        const numValue = parseFloat(value);
        return !isNaN(numValue) && numValue >= 0 && numValue <= 1;
      },
    },
  };
}

function ActionBar({
  changes,
  isLoading,
  onClick,
  onCancel,
  mode,
  totalRows,
  filledRows,
  errorCount,
}: {
  changes: number;
  isLoading: boolean;
  onClick: () => void;
  onCancel: () => void;
  mode: 'single_edit' | 'multiple_edit' | 'create';
  totalRows: number;
  filledRows: number;
  errorCount: number;
}) {
  return (
    <Box
      py={1}
      px={3}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        background: 'rgba(247, 247, 248, 0.8)',
        backdropFilter: 'blur(2px)',
        border: '1px solid #EDEDED',
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
        gap: 2,
      }}
    >
      <Box display="flex" alignItems="center" gap={2}>
        {mode === 'single_edit' && (
          <LoadingButton
            onClick={onCancel}
            variant="text"
            color="error"
            size="large"
            sx={{ height: '40px' }}
          >
            Cancelar Pagamento
          </LoadingButton>
        )}
      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        {errorCount > 0 && (
          <Box py={1}>
            <Typography
              variant="caption"
              fontWeight="650"
              fontSize="12px"
              color="error"
            >
              {`${errorCount} ${errorCount === 1 ? 'erro pendente' : 'erros pendentes'}`}
            </Typography>
          </Box>
        )}
        {mode !== 'create' && (
          <Box py={1}>
            <Typography
              variant="caption"
              fontWeight="650"
              fontSize="12px"
              color={changes > 0 ? 'warning.main' : 'text.secondary'}
            >
              {changes === 1 ? '1 alteração' : `${changes} alterações`}
            </Typography>
          </Box>
        )}
        {(errorCount > 0 || mode !== 'create') && (
          <Divider orientation="vertical" flexItem />
        )}
        {mode !== 'single_edit' && (
          <Box py={1}>
            <Typography
              variant="caption"
              fontWeight="500"
              fontSize="12px"
              color="text.secondary"
            >
              {`${filledRows} / ${totalRows} preenchidas`}
            </Typography>
          </Box>
        )}
        <LoadingButton
          onClick={onClick}
          loading={isLoading}
          variant="contained"
          size="large"
          data-testid="submit-inputs-for-calculation"
          color="primaryAlt"
          sx={{ width: '190px', height: '42px' }}
        >
          Aplicar e calcular
        </LoadingButton>
      </Box>
    </Box>
  );
}

async function searchCpfs(
  cpfListRef: MutableRefObject<string[]>,
  organizationId: string,
  companyId: string,
  setMissingCPFs: (cpfs: string[]) => void,
) {
  if (!cpfListRef?.current?.length) {
    return [];
  }

  const allResults = [];
  let page = 0;
  let hasMore = true;
  const batchSize = 100;
  while (hasMore) {
    try {
      const response = await fetchSearchAllContracts({
        pathParams: {
          organizationId,
        },
        body: {
          query: undefined,
          pagination: {
            size: batchSize,
            page,
          },
          sorting: [
            {
              field: 'name',
              order: 'asc',
            },
          ],
          filtering: {
            elements: {
              companyId: [companyId],
              cpfTrab: cpfListRef.current,
              status: [
                {
                  not: 'terminated',
                },
              ],
              workerCategory: ['clt:autonomo'],
            },
          },
        },
      });

      const results = response.data ?? [];
      allResults.push(...results);

      // Check if we got less results than requested, meaning no more pages
      hasMore = results.length === batchSize;
      page++;
    } catch (error) {
      console.error('Error fetching contracts:', error);
      throw new Error('Failed to fetch contracts. Please try again later.');
    }
  }
  const sortedResults = Array.from(
    sortByCPFList(cpfListRef.current, allResults, organizationId).entries(),
  );
  setMissingCPFs(
    sortedResults
      .filter(([_, result]) => result === undefined)
      .map(([cpf]) => cpf),
  );
  return sortedResults
    .filter(([_, result]) => result !== undefined)
    .map(([_, result]) => result);
}

export default RPASubmission;
