import React, { useContext, useMemo, useState } from 'react';

import { IconCirclePlus } from '@tabler/icons-react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  ContractBRPjEntryPrestador,
  CostCenterEntry,
  CostCenterSummary,
  DepartmentEntry,
  DepartmentSummary,
  JobTitleEntry,
  JobTitleSummary,
} from '@octopus/api';

import { AppContext } from '../../../../../app/context';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import {
  pollCostCenter,
  pollDepartment,
  pollJobTitle,
} from '../../../../pollers';
import { CreateCostCenterDrawer } from '../../../costCenters/CreateCostCenterDrawer';
import { CreateDepartmentDrawer } from '../../../departments/CreateDepartmentDrawer';
import { CreateJobTitleDrawer } from '../../../jobTitles/CreateJobTitleDrawer';
import { Record, RecordEntry } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type PrestadorDeServicoRecordData = {
  organizationId: string;
  workerId: string;
  emailCorp: string;
  prestador: ContractBRPjEntryPrestador;
};

export type PrestadorDeServicoRecordProps =
  BaseRecordProps<PrestadorDeServicoRecordData> & {
    companyId: string;
  };

export function PrestadorDeServicoRecord(props: PrestadorDeServicoRecordProps) {
  const {
    companyId,
    data: {
      organizationId,
      workerId,
      emailCorp,
      prestador: { posicao, gestao },
    },
  } = props;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);

  const { appContext, refetchCompanyData } = useContext(AppContext);

  const [isCreateJobDrawerOpen, setIsCreateJobDrawerOpen] = useState(false);
  const [isCreateCostCenterDrawerOpen, setIsCreateCostCenterDrawerOpen] =
    useState(false);
  const [isCreateDepartmentDrawerOpen, setIsCreateDepartmentDrawerOpen] =
    useState(false);

  const { showSnackbar } = useSnackbar();

  const [costCenters, setCostCenters] = useState<
    (CostCenterSummary | CostCenterEntry)[] | undefined
  >(appContext?.company?.costCenters?.map((cc) => cc.summary));
  const costCenter = costCenters?.find(
    ({ costCenterId }) => gestao?.costCenterId === costCenterId,
  );

  const [departments, setDepartments] = useState<
    (DepartmentSummary | DepartmentEntry)[]
  >(appContext?.company?.departments?.map((d) => d.summary));
  const department = departments?.find(
    ({ departmentId }) => posicao.departmentId === departmentId,
  );

  const [jobTitles, setJobTitles] = useState<
    (JobTitleSummary | JobTitleEntry)[] | undefined
  >(appContext?.company?.jobTitles.map((jbt) => jbt.summary));
  const jobTitle = jobTitles?.find(
    ({ jobTitleId }) => posicao.jobTitleId === jobTitleId,
  );
  const maxJobTitleCode = useMemo(
    () =>
      Math.max(
        ...(appContext?.company?.jobTitles?.map((jbt) => jbt.summary.code) ?? [
          0,
        ]),
      ),
    [appContext?.company?.jobTitles?.length],
  );

  return (
    <Record title="Prestador de serviço" edit={editRecordProps}>
      <RecordEntry
        label="Matrícula / Identificador"
        edit={{
          type: 'text',
          editing,
          value: formData.workerId,
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              workerId: value,
            })),
          hasError: hasError('workerId'),
        }}
      >
        {workerId}
      </RecordEntry>
      <RecordEntry
        label="Cargo"
        isLoading={!jobTitle}
        edit={{
          type: 'options',
          editing,
          value: formData.prestador.posicao.jobTitleId,
          disabled: !jobTitles,
          options: jobTitles
            ?.filter(
              ({ active, jobTitleId }) =>
                active || jobTitleId === jobTitle.jobTitleId,
            )
            ?.map(({ jobTitleId, name }) => ({
              value: jobTitleId,
              label: name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              prestador: {
                ...data.prestador,
                posicao: {
                  ...data.prestador.posicao,
                  jobTitleId: value,
                },
              },
            })),
          hasError: hasError('br/prestador/posicao/jobTitleId'),
          action: {
            Icon: <IconCirclePlus size={16} color="blue" />,
            label: 'Criar novo cargo',
            onClick: () => {
              setIsCreateJobDrawerOpen(true);
            },
          },
        }}
      >
        {jobTitle?.name}
      </RecordEntry>
      <RecordEntry
        label="Departamento"
        edit={{
          type: 'options',
          editing,
          disabled: !departments,
          value: formData.prestador.posicao?.departmentId,
          options: [
            { label: 'N/A', value: null },
            ...(departments
              ?.filter(
                ({ active, departmentId }) =>
                  active || departmentId === posicao?.departmentId,
              )
              ?.map(({ departmentId, name }) => ({
                value: departmentId,
                label: name,
              })) ?? []),
          ],
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              prestador: {
                ...data.prestador,
                posicao: {
                  ...data.prestador.posicao,
                  departmentId: value,
                },
              },
            })),
          hasError: hasError('br/prestador/posicao/departmentId'),
          action: {
            Icon: <IconCirclePlus size={16} color="blue" />,
            label: 'Criar novo departamento',
            onClick: () => {
              setIsCreateDepartmentDrawerOpen(true);
            },
          },
        }}
      >
        {department?.name}
      </RecordEntry>
      <RecordEntry
        label="Centro de custo"
        edit={{
          type: 'options',
          editing,
          disabled: !costCenters,
          value: formData.prestador.gestao?.costCenterId,
          options: [
            { label: 'N/A', value: null },
            ...(costCenters
              ?.filter(
                ({ active, costCenterId }) =>
                  active || costCenterId === gestao?.costCenterId,
              )
              ?.map(({ costCenterId, name }) => ({
                value: costCenterId,
                label: name,
              })) ?? []),
          ],
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              prestador: {
                ...data.prestador,
                gestao: {
                  ...data.prestador.gestao,
                  costCenterId: value,
                  nomeCentroCusto:
                    costCenters?.find(
                      ({ costCenterId }) => costCenterId === value,
                    )?.name ?? null,
                  codigoCentroCusto:
                    costCenters?.find(
                      ({ costCenterId }) => costCenterId === value,
                    )?.code ?? null,
                },
              },
            })),
          hasError: hasError('br/prestador/gestao/costCenterId'),
          action: {
            Icon: <IconCirclePlus size={16} color="blue" />,
            label: 'Criar novo centro de custo',
            onClick: () => {
              setIsCreateCostCenterDrawerOpen(true);
            },
          },
        }}
      >
        {costCenter?.name}
      </RecordEntry>
      <RecordEntry
        label="Email corporativo"
        edit={{
          type: 'text',
          editing,
          value: formData.emailCorp,
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              emailCorp: value,
            })),
          hasError: hasError('br/emailCorp'),
        }}
      >
        {emailCorp}
      </RecordEntry>
      {isCreateJobDrawerOpen && (
        <CreateJobTitleDrawer
          organizationId={organizationId}
          companies={appContext?.company?.companies?.map((cmp) => cmp.summary)}
          maxCode={maxJobTitleCode}
          open={isCreateJobDrawerOpen}
          onClose={() => setIsCreateJobDrawerOpen(false)}
          onSuccess={async (jobTitle) => {
            setJobTitles((jbts) => [...jbts, jobTitle]);
            updateData((data) => ({
              ...data,
              prestador: {
                ...data.prestador,
                posicao: {
                  ...data.prestador.posicao,
                  jobTitleId: jobTitle.jobTitleId,
                },
              },
            }));
            setIsCreateJobDrawerOpen(false);
            showSnackbar({
              isOpen: true,
              variant: 'default',
              Message: 'Cargo criado com sucesso',
              StartAdornment: <CheckCircleIcon />,
            });
            await pollJobTitle({
              organizationId,
              jobTitleId: jobTitle.jobTitleId,
            });
            await refetchCompanyData();
          }}
          requireContractType={'br:pj'}
          requireCompany={companyId}
        />
      )}
      {isCreateCostCenterDrawerOpen && (
        <CreateCostCenterDrawer
          organizationId={organizationId}
          companies={appContext?.company?.companies?.map((cmp) => cmp.summary)}
          open={isCreateCostCenterDrawerOpen}
          onClose={() => setIsCreateCostCenterDrawerOpen(false)}
          onSuccess={async (costCenter) => {
            setCostCenters((ccts) => [...ccts, costCenter]);
            updateData((data) => ({
              ...data,
              prestador: {
                ...data.prestador,
                gestao: {
                  ...data.prestador.gestao,
                  costCenterId: costCenter.costCenterId,
                  nomeCentroCusto: costCenter.name,
                  codigoCentroCusto: costCenter.code,
                },
              },
            }));
            setIsCreateCostCenterDrawerOpen(false);
            showSnackbar({
              isOpen: true,
              variant: 'default',
              Message: 'Centro de custo criado com sucesso',
              StartAdornment: <CheckCircleIcon />,
            });
            await pollCostCenter({
              organizationId,
              costCenterId: costCenter.costCenterId,
            });
            await refetchCompanyData();
          }}
          requireCompany={props.companyId}
        />
      )}
      {isCreateDepartmentDrawerOpen && (
        <CreateDepartmentDrawer
          organizationId={organizationId}
          companies={appContext?.company?.companies?.map((cmp) => cmp.summary)}
          open={isCreateDepartmentDrawerOpen}
          onClose={() => setIsCreateDepartmentDrawerOpen(false)}
          onSuccess={async (department) => {
            setDepartments((dpts) => [...dpts, department]);
            updateData((data) => ({
              ...data,
              prestador: {
                ...data.prestador,
                posicao: {
                  ...data.prestador.posicao,
                  departmentId: department.departmentId,
                },
              },
            }));
            setIsCreateDepartmentDrawerOpen(false);
            showSnackbar({
              isOpen: true,
              variant: 'default',
              Message: 'Departamento criado com sucesso',
              StartAdornment: <CheckCircleIcon />,
            });
            await pollDepartment({
              organizationId,
              departmentId: department.departmentId,
            });
            await refetchCompanyData();
          }}
          requireCompany={props.companyId}
        />
      )}
    </Record>
  );
}
