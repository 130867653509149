import {
  IFieldSingleValueWithoutOptionsDefinition,
  IFormDefinitionEntry,
  NEW_UI_TYPE,
} from '@octopus/libs/forms';

import { getFieldProps } from './getFieldsRenderProps';
import { parseFieldCheckboxCard } from './parseField/parseFieldCheckboxCard';
import { parseFieldDocument } from './parseField/parseFieldDocument';
import { parseFieldSelectWithAutoComplete } from './parseField/parseFieldSelectWithAutoComplete';
import { TFormRenderPropOptions } from './parseField/types';
import { TMetaFields, TMetaForm, TPayloadFields } from './types';

export function getFields({
  definition,
  payloadFields,
  metaFields,
  metaForm,
  payloadFormErrors = {},
  fieldsOptions = [],
  levelId = '',
  fieldsetName = '',
  lastFieldCount = 0,
}: {
  definition: IFormDefinitionEntry[];
  payloadFields: TPayloadFields;
  metaFields: TMetaFields;
  metaForm: TMetaForm;
  payloadFormErrors?: Record<string, string[]>;
  fieldsOptions?: TFormRenderPropOptions;
  levelId?: string;
  fieldsetName?: string;
  lastFieldCount?: number;
}): TFormRenderPropOptions {
  for (const fieldDefinition of definition) {
    if ('uiType' in fieldDefinition) {
      const fieldName = fieldDefinition.name;
      switch (fieldDefinition.uiType) {
        case NEW_UI_TYPE.SELECT_AUTOCOMPLETE: {
          const fieldData = payloadFields[fieldName];
          const fieldSelectRenderProps = parseFieldSelectWithAutoComplete(
            fieldData,
            fieldDefinition,
            fieldsetName,
            payloadFormErrors,
          );
          fieldsOptions.push([fieldName, fieldSelectRenderProps]);
          break;
        }
        case NEW_UI_TYPE.CHECKBOX: {
          const fieldData = payloadFields[fieldName];
          const fieldCheckBoxRenderProps = parseFieldCheckboxCard(
            fieldData,
            fieldDefinition as IFieldSingleValueWithoutOptionsDefinition,
            fieldsetName,
          );
          fieldsOptions.push([fieldName, fieldCheckBoxRenderProps]);
          break;
        }
        case NEW_UI_TYPE.DOCUMENT: {
          const fieldData = payloadFields[fieldName];
          const fieldDocumentRenderProps = parseFieldDocument(
            fieldData,
            fieldDefinition as IFieldSingleValueWithoutOptionsDefinition,
            fieldsetName,
          );
          fieldsOptions.push([fieldName, fieldDocumentRenderProps]);
          break;
        }
        default: {
          const fieldProps = getFieldProps({
            fieldDefinition,
            payloadFields,
            metaFields,
            metaForm,
            payloadFormErrors,
            fieldsOptions: [],
            levelId,
            fieldsetName,
            getFields,
            lastFieldCount,
          });

          if (fieldProps) {
            fieldsOptions.push(...fieldProps);
          }
          break;
        }
      }
    }
  }

  if (!payloadFormErrors || Object.keys(payloadFormErrors).length === 0)
    return fieldsOptions;

  return fieldsOptions.map((field) => {
    const [fName, ...FProps] = field;
    const errors: string[] | undefined = payloadFormErrors[fName];

    if (errors) {
      const newFProps = FProps.map((options) => ({
        ...options,
        errors,
      }));

      return [fName, ...newFProps];
    }
    return field;
  });
}
