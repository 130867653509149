import dayjs from 'dayjs';

import { MembershipTypes, OrganizationEntry } from '@octopus/api';

type FormattedLatestLoginDate =
  | { label: 'Nunca'; value: undefined }
  | { label: 'Hoje'; value: undefined }
  | { label: string; value: string };

const formatDatetime = (datetime: string): FormattedLatestLoginDate => {
  if (!datetime) {
    return { label: 'Nunca', value: undefined };
  }
  const daysAgo = dayjs.utc().diff(dayjs.utc(datetime), 'days');
  const value = dayjs(datetime).format('D MMM YYYY - HH:mm');
  if (daysAgo === 0) {
    return { label: 'Hoje', value };
  }
  return {
    label: `${daysAgo} dia${daysAgo > 1 ? 's' : ''} atrás`,
    value,
  };
};

const MEMBERSHIP_TYPE_LABELS: Record<MembershipTypes, string> = {
  internal: 'Padrão',
  owner: 'Administrador',
  'tako:support': 'Suporte',
  external: 'Externo',
} as const;

const formatMembershipType = (membershipType: MembershipTypes) => {
  return MEMBERSHIP_TYPE_LABELS[membershipType];
};

const getSSODomains = (organization: OrganizationEntry): string[] =>
  organization.settings?.domains
    ?.filter(({ sso }) => sso)
    ?.map(({ domain }) => domain) ?? [];

export const UserManagementUtils = {
  formatDatetime,
  formatMembershipType,
  getSSODomains,
};
