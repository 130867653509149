import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ArrowDropDownCircleOutlined,
  BeachAccessOutlined,
} from '@mui/icons-material';
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { Box, Container } from '@mui/system';

import { Button } from '@octopus/ui/design-system';
import {
  accrualPeriodsResourceType,
  baseNotifications,
  vacationsScheduleResourceType,
} from '@octopus/vacations-types';

import VacationsNotificationsTable from './NotificationTable';

type VacationsNotificationsProps = {
  organizationId: string;
};

export const VacationsNotifications: React.FC<VacationsNotificationsProps> = ({
  organizationId,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const notificationsType = [
    { label: 'Selecione um template', id: 'new' },
    {
      label: 'Período Aquisitivo - Customizado ',
      id: accrualPeriodsResourceType,
    },
    {
      label: 'Pedido de Férias - Customizado ',
      id: vacationsScheduleResourceType,
    },
    ...baseNotifications.map((notification) => ({
      label: notification.name,
      id: notification.ruleId,
    })),
  ];

  const handleClick = () => {
    if (selectedIndex !== 0) {
      navigate(
        `/vacations/notifications/new/${notificationsType[selectedIndex].id}`,
      );
    }
  };

  const handleMenuItemClick = (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        py: 9,
        px: 10,
      }}
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          data-testid="vacations-notifications-header"
          gap={1}
          mb={5}
        >
          <BeachAccessOutlined
            sx={{ height: '40px', width: '40px', marginRight: 1.5 }}
          />
          <Typography variant="h1">Notificações de Férias</Typography>
          <Box ml="auto">
            <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="Button group with a nested menu"
            >
              <Button
                onClick={handleClick}
                sx={{
                  paddingRight: '0px',
                  paddingLeft: '8px',
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px',
                }}
              >
                {notificationsType[selectedIndex].label}
              </Button>
              <Button
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleToggle}
                sx={{
                  paddingRight: '4px',
                  paddingLeft: '0px',
                  borderTopLeftRadius: '0px',
                  borderBottomLeftRadius: '0px',
                }}
              >
                <ArrowDropDownCircleOutlined />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{ zIndex: 1 }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {(props) => (
                <Grow
                  {...props.TransitionProps}
                  style={{
                    transformOrigin:
                      props.placement === 'bottom'
                        ? 'center top'
                        : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {notificationsType.map((notificationType, index) => (
                          <MenuItem
                            key={notificationType.id}
                            id={index.toString()}
                            selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            {notificationType.label}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Box>

        <Box>
          <VacationsNotificationsTable organizationId={organizationId} />
        </Box>
      </Container>
    </Box>
  );
};
