import {
  ContractSummary,
  IntegrationTimesheetConfiguration,
  IntegrationTimesheetType,
  LegalEntitySummary,
  PayrollInputsPayload,
  fetchGetIntegrationConfiguration,
  fetchGetLegalEntities,
  fetchSearchAllContracts,
} from '@octopus/api';
import { encryptCpf } from '@octopus/contract-types';

import { PayrollEmployeeData } from '../../types';

import {
  TimesheetEntry,
  TimesheetFileDefinition,
  TimesheetLine,
  TimesheetMapping,
  TimesheetRecord,
  TimesheetResult,
  TimesheetStepError,
  TimesheetStepErrorBase,
  TimesheetStepErrorCode,
  TimesheetStepErrorType,
  TimesheetStepWarning,
} from './types'; // 10MB

const PATTERNS = {
  CNPJ: '^\\d{14}$',
  MONTH: '^(0?[1-9]|1[0-2])$',
  YEAR: '^(19|20)\\d{2}$',
  NUMERIC: '^\\d+$',
  CPF: '^\\d{11}$',
  DECIMAL: '^\\d+([.,]\\d+)?$',
} as const;

const FORMATTER = {
  NUMERIC_ONLY: (value: string) => value.replace(/\D/g, ''),
  DECIMAL: (value: string) => value.replace(',', '.'),
  TIME: (value: string) =>
    convertValueToTimeString(parseFloat(FORMATTER.DECIMAL(value))),
};

export const TIMESHEET_FILE_DEFINITIONS_MAPPING: {
  [type in IntegrationTimesheetType]: TimesheetFileDefinition;
} = {
  pontotel: {
    format: 'text/csv',
    type: 'pontotel',
    delimiter: ';',
    stringDelimiter: '"',
    columns: [
      {
        name: 'empresa_cnpj',
        type: 'string',
        mapping: 'cnpj',
        validation: {
          required: true,
          pattern: PATTERNS.CNPJ,
          placeholder: '00000000000000',
          transform: FORMATTER.NUMERIC_ONLY,
        },
      },
      {
        name: 'mes',
        type: 'string',
        mapping: 'month',
        validation: {
          required: true,
          pattern: PATTERNS.MONTH,
          placeholder: 'MM',
        },
      },
      {
        name: 'ano',
        type: 'string',
        mapping: 'year',
        validation: {
          required: true,
          pattern: PATTERNS.YEAR,
          placeholder: 'YYYY',
        },
      },
      {
        name: 'matricula_empregado',
        type: 'string',
        mapping: 'code',
        validation: {
          required: false,
          placeholder: '01234',
        },
      },
      {
        name: 'cpf_empregado',
        type: 'string',
        mapping: 'cpf',
        validation: {
          required: true,
          pattern: PATTERNS.CPF,
          placeholder: '00000000000',
          transform: FORMATTER.NUMERIC_ONLY,
        },
      },
      {
        name: 'verba',
        type: 'string',
        mapping: 'type',
        validation: {
          required: true,
        },
      },
      {
        name: 'valor_horas',
        type: 'string',
        mapping: 'value',
        validation: {
          required: true,
          pattern: PATTERNS.DECIMAL,
          placeholder: '123,45',
          transform: FORMATTER.TIME,
        },
      },
    ],
  },
};

export const getErrorTargets: {
  byCell: (errors: TimesheetStepErrorBase[]) => string[];
  byDocument: (errors: TimesheetStepWarning[]) => string[];
  byLine: (errors: (TimesheetStepError | TimesheetStepWarning)[]) => string[];
  byColumnNames: (
    errors: (TimesheetStepError | TimesheetStepWarning)[],
  ) => string[];
} = {
  byCell: (errors) =>
    Array.from(
      new Set(
        errors.flatMap((error) =>
          error.columns.map((column) => `Célula ${column}${error.line}`),
        ),
      ),
    ),
  byDocument: (errors) =>
    Array.from(
      new Set(
        errors.map((error) => error?.timesheet?.cpf?.value).filter(Boolean),
      ),
    ),
  byLine: (errors) =>
    Array.from(new Set(errors.map((error) => `Linha ${error.line}`))),
  byColumnNames: (errors) =>
    Array.from(
      new Set(
        errors.flatMap((error) =>
          error.value.length ? error.value : error.expected,
        ),
      ),
    ),
} as const;

export const TIMESHEET_ERROR_DESCRIPTION_MAPPING: Record<
  TimesheetStepErrorCode,
  {
    title: { plural: string; singular: string };
    description?: string;
    getErrorTargets?: (
      errors: (TimesheetStepError | TimesheetStepWarning)[],
    ) => string[];
  }
> = {
  [TimesheetStepErrorCode.PAYROLL_INPUT_ID_NOT_SUPPORTED]: {
    title: {
      plural: 'Algumas verbas não foram identificadas na nossa base.',
      singular: 'Uma verba não foi identificada na nossa base.',
    },
    getErrorTargets: getErrorTargets.byLine,
  },
  [TimesheetStepErrorCode.MISSING_FIELD]: {
    title: {
      plural: 'Algumas células obrigatórias não foram preenchidas.',
      singular: 'Uma célula obrigatória não foi preenchida.',
    },
    getErrorTargets: getErrorTargets.byCell,
  },
  [TimesheetStepErrorCode.MISMATCH_VALUE]: {
    title: {
      plural: 'Algumas células foram preenchidas com valores inválidos.',
      singular: 'Uma célula foi preenchida com valor inválido.',
    },
    getErrorTargets: getErrorTargets.byCell,
  },
  [TimesheetStepErrorCode.CONTRACT_NOT_FOUND]: {
    title: {
      plural:
        'Alguns CPFs não foram encontrados na base de pessoas da sua empresa.',
      singular: 'Um CPF não foi encontrado na base de pessoas da sua empresa.',
    },
    getErrorTargets: getErrorTargets.byDocument,
  },
  [TimesheetStepErrorCode.PERIOD_NOT_MATCH]: {
    title: {
      plural:
        'Algumas linhas possuem períodos diferentes do período que você está editando.',
      singular:
        'Uma linha possui período diferente do período que você está editando.',
    },
    getErrorTargets: getErrorTargets.byLine,
  },
  [TimesheetStepErrorCode.LEGAL_ENTITY_NOT_MATCH]: {
    title: {
      plural:
        'Algumas linhas não correspondem a um CNPJ pertencente à sua empresa.',
      singular:
        'Uma linha não corresponde a um CNPJ pertencente à sua empresa.',
    },
    getErrorTargets: getErrorTargets.byLine,
  },
  [TimesheetStepErrorCode.MISSING_HEADER]: {
    title: {
      plural: 'O arquivo importado está fora do modelo padrão.',
      singular: 'O arquivo importado está fora do modelo padrão.',
    },
    getErrorTargets: getErrorTargets.byColumnNames,
    description:
      'As colunas seguintes não foram encontradas, verifique se elas estão no arquivo e seguem o formato correto.',
  },
  [TimesheetStepErrorCode.UNKNOWN_ERROR]: {
    title: {
      plural: 'Erro desconhecido.',
      singular: 'Erro desconhecido.',
    },
  },
  [TimesheetStepErrorCode.NO_DATA]: {
    title: {
      plural: 'O arquivo importado não possui nenhum valor preenchido.',
      singular: 'O arquivo importado não possui nenhum valor preenchido.',
    },
    description:
      'O arquivo está em branco e por isso nenhum valor foi importado.',
  },
};

export const getTimesheetFileDefinitionsByType = (
  type: IntegrationTimesheetType,
): TimesheetFileDefinition | undefined => {
  return TIMESHEET_FILE_DEFINITIONS_MAPPING[type];
};

async function getAllLegalEntities(
  organizationId: string,
  companyId: string,
): Promise<LegalEntitySummary[]> {
  const limitPerPage = 100;
  let page = 0;
  let returnedSize = limitPerPage;

  try {
    const legalEntities: LegalEntitySummary[] = [];

    while (limitPerPage === returnedSize) {
      const response = await fetchGetLegalEntities({
        pathParams: {
          organizationId,
          companyId,
        },
        queryParams: {
          page: (page++).toString(),
          size: limitPerPage.toString(),
        },
      });

      returnedSize = response.size;

      if (response.data) {
        response.data.forEach((entity) => legalEntities.push(entity));
      }
    }

    return legalEntities;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function getAllContractPages(
  organizationId: string,
  companyId: string,
  employees: PayrollEmployeeData[],
): Promise<ContractSummary[]> {
  let page = 0;
  const size = 100;
  let returnedSize = size;

  try {
    const contracts: ContractSummary[] = [];

    while (size === returnedSize) {
      const response = await fetchSearchAllContracts({
        pathParams: {
          organizationId,
        },
        body: {
          pagination: {
            size,
            page: page++,
          },
          filtering: {
            elements: {
              companyId: [companyId],
              contractId: employees.map((employee) => employee.contractId),
            },
          },
        },
      });

      returnedSize = response.size;

      if (response.data) {
        response.data.forEach((contract) => contracts.push(contract));
      }
    }

    return contracts;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function getTimesheetRecordsMappedByEncryptedCpf(
  organizationId: string,
  records: TimesheetRecord[],
): Promise<TimesheetMapping['timesheetsByEncryptedCpf']> {
  const groupedData = records.reduce(
    (acc, current) => {
      if (current.cpf) {
        const key = encryptCpf(current.cpf.value, organizationId);

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(current);
      }

      return acc;
    },
    {} as TimesheetMapping['timesheetsByEncryptedCpf'],
  );

  return groupedData;
}

async function getEmployeesMappingByContractId(
  employeesData: PayrollEmployeeData[],
): Promise<{
  [contractId: string]: PayrollEmployeeData;
}> {
  const employeesMapping: {
    [contractId: string]: PayrollEmployeeData;
  } = {};

  employeesData.forEach((employee) => {
    employeesMapping[employee.contractId] = employee;
  });

  return employeesMapping;
}

function getLegalEntityByTimesheet(
  timesheet: TimesheetRecord,
  legalEntitiesMapByCnpj: { [cnpj: string]: LegalEntitySummary },
): LegalEntitySummary | undefined {
  try {
    const cnpj = timesheet?.cnpj?.value
      ? timesheet.cnpj.value.replace(/\D/g, '')
      : '';

    const legalEntity = legalEntitiesMapByCnpj[cnpj];

    if (!legalEntity) {
      return undefined;
    }

    return legalEntity;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

function removeSpecialCharacters(value: string): string {
  return value.replace(/\D/g, '');
}

async function getContractMapByCpf(contracts: ContractSummary[]): Promise<{
  [encryptedCpf: string]: ContractSummary;
}> {
  return contracts.reduce(
    (acc, contract) => {
      acc[contract.cpfTrab] = contract;
      return acc;
    },
    {} as {
      [encryptedCpf: string]: ContractSummary;
    },
  );
}

async function getLegalEntitiesMapByCnpj(
  legalEntities: LegalEntitySummary[],
): Promise<{
  [cnpj: string]: LegalEntitySummary;
}> {
  return legalEntities.reduce(
    (acc, legalEntity) => {
      acc[removeSpecialCharacters(legalEntity?.br?.cnpj)] = legalEntity;
      return acc;
    },
    {} as { [cnpj: string]: LegalEntitySummary },
  );
}

function getPeriodByTimesheet(timesheet: TimesheetRecord): string {
  const month = timesheet.month.value.padStart(2, '0');

  return `${timesheet.year.value}-${month}`;
}

function getHeaderStringByFileDefinition(
  definition: TimesheetFileDefinition,
): string {
  return definition.columns
    .map((column) => column.name)
    .join(definition.delimiter);
}

function isPartialHeaderPresent(
  firstLine: string,
  definition: TimesheetFileDefinition,
): boolean {
  const expectedColumnNames = definition.columns.map((column) => column.name);
  const firstLineColumns = firstLine
    .split(definition.delimiter)
    .map((column) =>
      column
        .trim()
        .replace(
          new RegExp(
            `^${definition.stringDelimiter}|${definition.stringDelimiter}$`,
            'g',
          ),
          '',
        ),
    );

  return expectedColumnNames.some((expectedColumn) =>
    firstLineColumns.some(
      (actualColumn) =>
        actualColumn.toLowerCase() === expectedColumn.toLowerCase(),
    ),
  );
}

function getFirstLineHeaders(
  lines: string[],
  definition: TimesheetFileDefinition,
): {
  firstLine: string;
  shouldSkipFirstLine: boolean;
} {
  const firstLine = lines[0];

  if (!firstLine) {
    throw new Error('CSV file does not contain headers');
  }

  const partialHeaderPresent = isPartialHeaderPresent(firstLine, definition);

  if (partialHeaderPresent) {
    return {
      firstLine: firstLine,
      shouldSkipFirstLine: true,
    };
  }

  const expectedHeader = getHeaderStringByFileDefinition(definition);

  return {
    firstLine: expectedHeader,
    shouldSkipFirstLine: false,
  };
}

export async function parseCsvFile(
  file: File | undefined | null,
  definition: TimesheetFileDefinition,
): Promise<TimesheetLine[]> {
  try {
    if (!file) {
      throw new Error('No file provided');
    }

    const maxFileSize = 10 * 1024 * 1024;

    if (file.size > maxFileSize) {
      throw new Error('File size exceeds the maximum limit');
    }

    const columnDelimiter = definition.delimiter;
    const stringDelimiter = definition.stringDelimiter;

    const reader = new FileReader();

    const result = await new Promise((resolve, reject) => {
      reader.onload = (event) => {
        try {
          if (!event.target) {
            return reject(new Error('Failed to read file'));
          }
          const result = event.target.result as string;
          const lines = result.split('\n').filter((line) => line.trim());

          if (lines.length < 1) {
            return reject(new Error('CSV file does not contain enough data'));
          }

          const { firstLine, shouldSkipFirstLine } = getFirstLineHeaders(
            lines,
            definition,
          );

          const headers = firstLine
            .split(columnDelimiter)
            .map((header, columnIndex) => ({
              name: header
                .trim()
                .replace(
                  new RegExp(`^${stringDelimiter}|${stringDelimiter}$`, 'g'),
                  '',
                ),
              column: getCellByColumnIndex(columnIndex),
            }));

          if (shouldSkipFirstLine) {
            lines.shift();
          }

          const data = lines.map((line, lineIndex) => {
            const values = line
              .split(columnDelimiter)
              .map((value, columnIndex) => ({
                value: value
                  .trim()
                  .replace(
                    new RegExp(`^${stringDelimiter}|${stringDelimiter}$`, 'g'),
                    '',
                  ),
                column: getCellByColumnIndex(columnIndex),
                line: getLineByIndex(lineIndex),
              }));

            return headers.reduce((acc, header, index) => {
              acc[header.name] = {
                ...header,
                ...values[index],
              };

              return acc;
            }, {} as TimesheetLine);
          });

          return resolve(data);
        } catch (error) {
          return reject(error);
        }
      };

      reader.onerror = reject;

      reader.readAsText(file);
    });

    return result as TimesheetLine[];
  } catch (error) {
    console.error(error);
    return [];
  }
}

function getCellByColumnIndex(index: number): string {
  return String.fromCharCode(65 + index);
}

function getLineByIndex(index: number): number {
  return index + 2;
}

function validateFileHeaders(
  lines: TimesheetLine[],
  definitions: TimesheetFileDefinition,
): TimesheetStepError | null {
  const expectedHeaders = definitions.columns.flatMap((column) =>
    column.validation?.required ? [column.name] : [],
  );
  const currentHeaders = Object.keys(lines?.[0] ?? {});

  const missingHeaders = expectedHeaders.filter(
    (header) => !currentHeaders.includes(header),
  );

  if (missingHeaders.length) {
    return {
      type: TimesheetStepErrorType.VALIDATION,
      code: TimesheetStepErrorCode.MISSING_HEADER,
      value: missingHeaders,
      expected: expectedHeaders,
      line: 1,
      columns: [],
    };
  }

  return null;
}

function validateFileLines(
  lines: TimesheetLine[],
  definitions: TimesheetFileDefinition,
): { warnings: TimesheetStepWarning[]; lines: TimesheetLine[] } {
  const validLines: TimesheetLine[] = [];

  const warnings = lines.flatMap<TimesheetStepWarning>((line) => {
    const columnWarning = definitions.columns.flatMap<TimesheetStepWarning>(
      (columnDefinition) => {
        const column = line[columnDefinition.name];

        if (!column && !columnDefinition.validation?.required) {
          return [];
        }

        if (
          !column ||
          (columnDefinition?.validation?.required && !column.value)
        ) {
          return [
            {
              type: TimesheetStepErrorType.VALIDATION,
              code: TimesheetStepErrorCode.MISSING_FIELD,
              value: [],
              expected: [],
              line: column.line,
              columns: [column.column],
            },
          ];
        }

        try {
          if (columnDefinition?.validation?.pattern && column.value) {
            const regex = new RegExp(columnDefinition.validation.pattern);

            if (!regex.test(column.value)) {
              throw new Error(
                `Invalid value for column ${columnDefinition.name}: ${column.value}`,
              );
            }
          }
        } catch (error) {
          console.warn(error);

          return [
            {
              type: TimesheetStepErrorType.VALIDATION,
              code: TimesheetStepErrorCode.MISMATCH_VALUE,
              value: [],
              expected: [],
              line: column.line,
              columns: [column.column],
            },
          ];
        }

        return [];
      },
    );

    if (!columnWarning.length) {
      validLines.push(line);
    }

    return columnWarning;
  });

  return {
    warnings,
    lines: validLines,
  };
}

export function validateFileFormat(
  lines: TimesheetLine[],
  definitions: TimesheetFileDefinition,
): {
  errors: TimesheetStepError[];
  warnings: TimesheetStepWarning[];
  lines: TimesheetLine[];
} {
  if (!lines.length) {
    return {
      lines: [],
      errors: [
        {
          type: TimesheetStepErrorType.VALIDATION,
          code: TimesheetStepErrorCode.NO_DATA,
          expected: [],
          value: [],
          line: 0,
          columns: [],
        },
      ],
      warnings: [],
    };
  }

  const error = validateFileHeaders(lines, definitions);

  if (error) {
    return {
      errors: [error],
      warnings: [],
      lines: [],
    };
  }

  const { warnings, lines: linesWithoutErrors } = validateFileLines(
    lines,
    definitions,
  );

  return {
    errors: [],
    warnings,
    lines: linesWithoutErrors,
  };
}

export async function getEntitiesMapped(
  organizationId: string,
  {
    records,
    employeesData,
    contracts,
    legalEntities,
    payrollInputs,
  }: {
    records: TimesheetRecord[];
    employeesData: PayrollEmployeeData[];
    contracts: ContractSummary[];
    legalEntities: LegalEntitySummary[];
    payrollInputs: PayrollInputsPayload;
  },
): Promise<TimesheetMapping> {
  const [
    timesheetsByEncryptedCpf,
    employeesByContractId,
    contractsByEncryptedCpf,
    legalEntitiesByCnpj,
  ] = await Promise.all([
    getTimesheetRecordsMappedByEncryptedCpf(organizationId, records),
    getEmployeesMappingByContractId(employeesData),
    getContractMapByCpf(contracts),
    getLegalEntitiesMapByCnpj(legalEntities),
  ]);

  return {
    timesheetsByEncryptedCpf,
    employeesByContractId,
    contractsByEncryptedCpf,
    legalEntitiesByCnpj,
    payrollInputsByInputId: payrollInputs,
  };
}

export function parseFileLines(
  lines: TimesheetLine[],
  fileDefinitions: TimesheetFileDefinition,
): TimesheetRecord[] {
  return lines.map((line) => {
    return fileDefinitions.columns.reduce((acc, column) => {
      if (!line[column.name]) {
        return acc;
      }

      acc[column.mapping] = {
        ...line[column.name],
        value:
          column.validation?.transform?.(line[column.name].value) ??
          line[column.name].value,
      };
      return acc;
    }, {} as TimesheetRecord);
  });
}

export async function getEntities(
  organizationId: string,
  companyId: string,
  employeesData: PayrollEmployeeData[],
): Promise<{
  legalEntities: LegalEntitySummary[];
  contracts: ContractSummary[];
}> {
  const [legalEntities, contracts] = await Promise.all([
    getAllLegalEntities(organizationId, companyId),
    getAllContractPages(organizationId, companyId, employeesData),
  ]);

  return {
    contracts,
    legalEntities,
  };
}

export function convertValueToTimeString(value: number): string {
  if (!value || isNaN(value) || value < 0) {
    return '00:00:00';
  }

  const hours = Math.floor(value);
  const minutes = Math.floor((value - hours) * 60);
  const seconds = Math.floor(((value - hours) * 60 - minutes) * 60);

  const pad = (num: number) => String(num).padStart(2, '0');

  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
}

function splitValidAndInvalidTimesheets(
  timesheets: TimesheetRecord[],
  mapping: TimesheetMapping,
  contract: ContractSummary,
  payrollPeriod: string,
): TimesheetResult {
  const result = timesheets.reduce(
    (acc, timesheet) => {
      const warnings: TimesheetStepWarning[] = [];
      const entries: TimesheetEntry[] = [];

      const timesheetPeriod = getPeriodByTimesheet(timesheet);

      if (timesheetPeriod !== payrollPeriod) {
        warnings.push({
          type: TimesheetStepErrorType.MATCHING,
          code: TimesheetStepErrorCode.PERIOD_NOT_MATCH,
          expected: [payrollPeriod],
          value: [timesheetPeriod],
          line: timesheet.cnpj.line,
          timesheet,
          columns: [],
        });
      }

      const legalEntity = getLegalEntityByTimesheet(
        timesheet,
        mapping.legalEntitiesByCnpj,
      );

      if (legalEntity?.legalEntityId !== contract.legalEntityId) {
        warnings.push({
          type: TimesheetStepErrorType.MATCHING,
          code: TimesheetStepErrorCode.LEGAL_ENTITY_NOT_MATCH,
          expected: [contract.legalEntityId],
          value: [legalEntity?.legalEntityId],
          line: timesheet.cnpj.line,
          timesheet,
          columns: [],
        });
      }

      const payrollInput = mapping.payrollInputsByInputId[timesheet.type.value];

      if (!payrollInput) {
        warnings.push({
          type: TimesheetStepErrorType.MATCHING,
          code: TimesheetStepErrorCode.PAYROLL_INPUT_ID_NOT_SUPPORTED,
          expected: [],
          value: [],
          line: timesheet.cnpj.line,
          timesheet,
          columns: [],
        });
      }

      if (!warnings.length) {
        entries.push({
          contract,
          employee: mapping.employeesByContractId[contract.contractId],
          timesheet,
        });
      }

      acc.entries.push(...entries);
      acc.warnings.push(...warnings);

      return acc;
    },
    {
      warnings: [],
      entries: [],
    } as TimesheetResult,
  );

  return result;
}

export async function extractValidatedTimesheetEntries(
  mapping: TimesheetMapping,
  payrollPeriod: string,
): Promise<TimesheetResult> {
  const result = Object.entries(mapping.timesheetsByEncryptedCpf).reduce(
    (acc, [encryptedCpf, timesheets]) => {
      const { entries, warnings } = acc;

      const contract = mapping.contractsByEncryptedCpf[encryptedCpf];

      if (contract) {
        const result = splitValidAndInvalidTimesheets(
          timesheets,
          mapping,
          contract,
          payrollPeriod,
        );

        warnings.push(...result.warnings);
        entries.push(...result.entries);
      } else {
        warnings.push(
          ...timesheets.map<TimesheetStepWarning>((timesheet) => ({
            type: TimesheetStepErrorType.MATCHING,
            code: TimesheetStepErrorCode.CONTRACT_NOT_FOUND,
            line: timesheet.cnpj.line,
            timesheet: timesheet,
            columns: [],
            expected: [],
            value: [],
          })),
        );
      }

      return {
        entries,
        warnings,
      };
    },
    { entries: [], warnings: [] } as TimesheetResult,
  );

  return result;
}

async function getMostRecentTimesheetConfiguration(
  organizationId: string,
  companyId: string,
): Promise<IntegrationTimesheetConfiguration | null> {
  try {
    const response = await fetchGetIntegrationConfiguration({
      pathParams: {
        organizationId,
        companyId,
      },
    });

    return response?.timesheet || null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getTimesheetFileDefinitionByCompany(
  organizationId: string,
  companyId: string,
): Promise<TimesheetFileDefinition | undefined> {
  const config = await getMostRecentTimesheetConfiguration(
    organizationId,
    companyId,
  );

  if (!config || !config.enabled) {
    return undefined;
  }

  return getTimesheetFileDefinitionsByType(config.type);
}

export function getTemplateFileByDefinitions(
  definitions: TimesheetFileDefinition,
): File {
  const headers = definitions.columns.map((column) => column.name);
  const timesheetLine = definitions.columns.map((column) =>
    column?.validation?.placeholder
      ? column.validation.placeholder
      : column?.validation?.required
        ? 'required'
        : '',
  );

  const content = [
    headers.join(definitions.delimiter),
    timesheetLine.join(definitions.delimiter),
  ];

  const fileName = `template_${definitions.type.toLowerCase()}.csv`;

  return new File([content.join('\n')], fileName, {
    type: definitions.format,
  });
}

export function getErrorsMappingByCode<T extends TimesheetStepErrorBase>(
  errors: T[],
): {
  [code: string]: {
    code: TimesheetStepErrorCode;
    lines: T[];
  };
} {
  return errors.reduce(
    (acc, error) => {
      if (!acc[error.code]) {
        acc[error.code] = {
          code: error.code,
          lines: [],
        };
      }

      acc[error.code].lines.push(error);

      return acc;
    },
    {} as {
      [code: string]: {
        code: TimesheetStepErrorCode;
        lines: T[];
      };
    },
  );
}
