import { ReactElement, useState } from 'react';

import { Box } from '@mui/system';

import { CreateTemplate } from './CreateTemplate';
import { GenerateDocument } from './GenerateDocument';
import { LeftPanel } from './LeftPanel';
import { ManageTemplates } from './ManageTemplates';

export type Props = {
  organizationId: string;
};

const getComponent = ({
  organizationId,
  selectedItem,
}: {
  organizationId: string;
  selectedItem: number;
}): ReactElement => {
  switch (selectedItem) {
    case 0:
      return <ManageTemplates organizationId={organizationId} />;
    case 1:
      return <CreateTemplate />;
    case 2:
      return <GenerateDocument organizationId={organizationId} />;
    default:
      return <div>Opção não encontrada</div>;
  }
};

export function TemplatesManagerPage({ organizationId }: Props) {
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const Component = getComponent({ selectedItem, organizationId });

  return (
    <Box display="flex" width="100%">
      <LeftPanel
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
      <Box sx={{ px: 10, pb: 6 }} display="flex" width="100%">
        {Component}
      </Box>
    </Box>
  );
}
