import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';

import { Box, Typography } from '@mui/material';

import {
  ContractEntry,
  GetWorkerProvisionsResponse,
  useGetContract,
  useGetWorkerProvisions,
} from '@octopus/api';
import { formatMoney, formatPeriodDate } from '@octopus/formatters';
import { tiposDeProvisoesPadroes } from '@octopus/payroll-engine/public-types/brazil';

import { CalculationExplanation } from '../../../../modules/components/payrolls/CalculationExplanation';
import { DataFetching } from '../../../../modules/dataFetching';

function Table({
  data,
  period,
  contract,
}: {
  data: GetWorkerProvisionsResponse;
  period: string;
  contract: ContractEntry;
}) {
  const detailCellRenderer = useCallback(DetailCellRenderer, []);
  return (
    <Box height="100%" px={8} py={4}>
      <Typography variant="h1">
        Provisões {formatPeriodDate(period)} de {contract.name}
      </Typography>

      <Box className="ag-theme-quartz" height="1000px" width="100%" pt={2}>
        <AgGridReact<{
          key: string;
          provisionedInPeriod: string;
          deposits: string;
          withdrawals: string;
          paymentsForFruition: string;
          paymentsForIndemnification: string;
          paymentsReversal: string;
          writeOffs: string;
          adjustments: string;
          oldProvisionedTotal: string;
          newProvisionedTotal: string;
        }>
          masterDetail
          embedFullWidthRows
          detailCellRenderer={detailCellRenderer}
          detailCellRendererParams={{
            sharedElements: data.sharedElements,
          }}
          detailRowAutoHeight
          autoSizeStrategy={{
            type: 'fitGridWidth',
            defaultMinWidth: 160,
          }}
          rowData={Object.entries(data.provisions)
            .sort(([a], [b]) => a.localeCompare(b))
            .map(([key, value]) => ({
              key,
              ...value,
            }))}
          columnDefs={[
            {
              field: 'key',
              headerName: 'Conta',
              pinned: 'left',
              minWidth: 300,
              cellRenderer: 'agGroupCellRenderer',
              valueFormatter: ({ value }: { value: string }) => {
                return (
                  {
                    [tiposDeProvisoesPadroes.ferias.ferias]: 'Férias - Gozo',
                    [tiposDeProvisoesPadroes.ferias.inssPatronal]:
                      'Férias - INSS Patronal',
                    [tiposDeProvisoesPadroes.ferias.inssRat]:
                      'Férias - INSS RAT',
                    [tiposDeProvisoesPadroes.ferias.inssOutrasEntidades]:
                      'Férias - INSS Outras Entidades',
                    [tiposDeProvisoesPadroes.ferias.fgts]: 'Férias - FGTS',
                    [tiposDeProvisoesPadroes.decimoTerceiro.decimoTerceiro]:
                      'Décimo Terceiro - Décimo Terceiro',
                    [tiposDeProvisoesPadroes.decimoTerceiro.inssPatronal]:
                      'Décimo Terceiro - INSS Patronal',
                    [tiposDeProvisoesPadroes.decimoTerceiro.inssRat]:
                      'Décimo Terceiro - INSS RAT',
                    [tiposDeProvisoesPadroes.decimoTerceiro
                      .inssOutrasEntidades]:
                      'Décimo Terceiro - INSS Outras Entidades',
                    [tiposDeProvisoesPadroes.decimoTerceiro.fgts]:
                      'Décimo Terceiro - FGTS',
                  }[value] || value
                );
              },
            },
            ...(
              [
                {
                  field: 'oldProvisionedTotal',
                  headerName: 'Saldo Inicial',
                  maxWidth: 150,
                },
                { field: 'deposits', headerName: 'Depósitos', maxWidth: 150 },
                {
                  field: 'withdrawals',
                  headerName: 'Retiradas',
                  maxWidth: 150,
                },
                {
                  field: 'provisionedInPeriod',
                  headerName: 'Provisionado no mês',
                },
                {
                  field: 'paymentsForFruition',
                  headerName: 'Baixas - Fruição',
                },
                {
                  field: 'paymentsForIndemnification',
                  headerName: 'Baixas - Indenização',
                },
                { field: 'paymentsReversal', headerName: 'Baixas - Estornos' },
                { field: 'writeOffs', headerName: 'Baixas - Cancelamentos' },
                { field: 'adjustments', headerName: 'Ajustes' },
                {
                  field: 'newProvisionedTotal',
                  headerName: 'Saldo Final',
                  pinned: 'right',
                },
              ] as const
            ).map((column) => ({
              ...column,
              valueFormatter: (params: { value: string }) =>
                formatMoney(params.value),
            })),
          ]}
        />
      </Box>
    </Box>
  );
}

function WorkerProvisionsPage({ organizationId }: { organizationId: string }) {
  const { period, contractId } = useParams<{
    period: string;
    contractId: string;
  }>();

  return (
    <DataFetching<{
      provisions: GetWorkerProvisionsResponse;
      contract: ContractEntry;
    }>
      fetchResult={{
        results: {
          provisions: useGetWorkerProvisions({
            pathParams: {
              organizationId,
              periodId: period,
              contractId,
            },
          }),
          contract: useGetContract({
            pathParams: {
              organizationId,
              contractId,
            },
          }),
        },
      }}
      Loading={() => (
        <Box>
          <Typography>Loading...</Typography>
        </Box>
      )}
      Data={({ data }) => (
        <Table
          data={data.provisions}
          period={period}
          contract={data.contract}
        />
      )}
    />
  );
}

export default WorkerProvisionsPage;

function CalculationExplanationDetail({
  sharedElements,
  elements: specificElements,
}: {
  sharedElements: GetWorkerProvisionsResponse['sharedElements'];
  elements: GetWorkerProvisionsResponse['provisions'][string]['elements'];
}) {
  const [elementsStack, setElementsStack] = useState<string[]>([
    'newProvisionedTotal',
  ]);

  const elements = { ...sharedElements, ...specificElements };

  return (
    <Box
      width="100%"
      display="flex"
      sx={{
        overflowY: 'auto',
        scrollbarGutter: 'stable',
      }}
    >
      {elementsStack.map((elementId, index) => {
        const element = elements[elementId];
        if (!element) {
          return null;
        }

        return (
          <Box
            sx={{
              minWidth: '500px',
              maxWidth: '500px',
            }}
          >
            <CalculationExplanation
              key={element.id}
              element={element}
              canGoBack={false}
              onClickElement={({ id: elementId }) => {
                setElementsStack((elementsStack) => [
                  ...elementsStack.slice(0, index + 1),
                  elementId,
                ]);
              }}
              onExit={() => {
                setElementsStack((elementsStack) => elementsStack.slice(0, -1));
              }}
              goBack={() => undefined}
            />
          </Box>
        );
      })}
    </Box>
  );
}

function DetailCellRenderer({
  pinned,
  data,
  sharedElements,
}: CustomCellRendererProps<{
  elements: GetWorkerProvisionsResponse['provisions'][string]['elements'];
}> & {
  sharedElements: GetWorkerProvisionsResponse['sharedElements'];
}) {
  if (pinned) {
    return null;
  }

  return (
    <CalculationExplanationDetail
      sharedElements={sharedElements}
      elements={data.elements}
    />
  );
}
