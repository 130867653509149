import React, { useEffect, useState } from 'react';

import { IconUsers } from '@tabler/icons-react';

import { Box, Button, Skeleton, Typography } from '@mui/material';

import {
  ContractList,
  ContractSummary,
  DepartmentSummary,
  SearchInput,
  useSearchAllContracts,
} from '@octopus/api';
import { contractStatuses } from '@octopus/contract-types';
import {
  DataGrid,
  DataGridProps,
  DataGridToolbar,
  GridRenderCellParams,
  GridValueGetterParams,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { ErrorAlert } from '../../../ErrorAlert';
import { jobTitleColumn } from '../../../table/columns/CompanyColumns';
import UserAvatar from '../../../UserAvatar';

import { DepartmentAddContracts } from './DepartmentAddContracts';
import { DepartmentContractActionMenu } from './DepartmentContractActionMenu';
import { DepartmentMoveContractDialog } from './DepartmentMoveContractDialog';

export type DepartmentContractsProps = {
  organizationId: string;
  department: DepartmentSummary;
  contractsCount: number | undefined;
  onContractsAdded: () => void;
  onContractsMoved: () => void;
};

export function DepartmentContracts({
  organizationId,
  department,
  contractsCount,
  onContractsAdded,
  onContractsMoved,
}: DepartmentContractsProps) {
  const [addingContracts, setAddingContracts] = useState(false);
  const searchAllContracts = useSearchAllContracts();
  const dataGridProps = useDataGrid({
    key: `department-contracts-${organizationId}`,
    sorting: { field: 'name', order: 'asc' },
    search: { type: 'state' },
  });

  const fetchDepartmentContracts = () => {
    if (organizationId === undefined || department.departmentId === undefined) {
      return;
    }
    searchAllContracts.mutate({
      pathParams: {
        organizationId,
      },
      body: {
        filtering: {
          elements: {
            departmentId: [department.departmentId],
            status: [{ not: contractStatuses.terminated }],
          },
        },
        sorting: [
          {
            field: dataGridProps.sortingProps.field,
            order: dataGridProps.sortingProps.order,
          },
        ],
        pagination: {
          page: dataGridProps.paginationProps.page,
          size: dataGridProps.paginationProps.rowsPerPage,
        },
        ...(dataGridProps.searchProps.searchTerm.length > 0 && {
          query: dataGridProps.searchProps.searchTerm,
        }),
      } as SearchInput,
    });
  };

  if (addingContracts) {
    return (
      <DepartmentAddContracts
        organizationId={organizationId}
        department={department}
        onCancel={() => setAddingContracts(false)}
        onContractsAdded={() => {
          setAddingContracts(false);
          onContractsAdded();
          fetchDepartmentContracts();
        }}
      />
    );
  }
  return (
    <ViewDepartmentContracts
      organizationId={organizationId}
      department={department}
      contractsCount={contractsCount}
      fetchDepartmentContracts={fetchDepartmentContracts}
      dataGridProps={dataGridProps}
      searchAllContracts={searchAllContracts}
      onAddContracts={() => setAddingContracts(true)}
      onContractsMoved={onContractsMoved}
    />
  );
}

function ViewDepartmentContracts({
  organizationId,
  department,
  contractsCount,
  fetchDepartmentContracts,
  dataGridProps,
  searchAllContracts,
  onAddContracts,
  onContractsMoved,
}: {
  organizationId: string;
  department: DepartmentSummary;
  contractsCount: number | undefined;
  fetchDepartmentContracts: () => void;
  dataGridProps: DataGridProps;
  searchAllContracts: ReturnType<typeof useSearchAllContracts>;
  onAddContracts: () => void;
  onContractsMoved: () => void;
}) {
  const [moveContract, setMoveContract] = useState<ContractSummary | undefined>(
    undefined,
  );

  const { isLoading, isError, data } = searchAllContracts;

  useEffect(() => {
    fetchDepartmentContracts();
  }, [
    organizationId,
    department.departmentId,
    dataGridProps.searchProps.searchTerm,
    dataGridProps.sortingProps.field,
    dataGridProps.sortingProps.order,
    dataGridProps.paginationProps.page,
    dataGridProps.paginationProps.rowsPerPage,
  ]);

  return (
    <Box display="flex" height="100%" flexDirection="column" gap={1}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mr={2}
      >
        <Box display="flex" gap={2}>
          <DataGridToolbar
            searchPlaceholder="Procurar"
            hideFilters={true}
            filters={[]}
            searchProps={dataGridProps.searchProps}
            filteringProps={dataGridProps.filteringProps}
            searchBarSx={{
              maxWidth: '240px',
            }}
          />
          {contractsCount !== undefined && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="left"
              gap={1}
            >
              <IconUsers size={16} />
              <Typography variant="caption">
                {contractsCount} contrato{contractsCount !== 1 ? 's' : ''}
              </Typography>
            </Box>
          )}
        </Box>
        {department.active && (
          <Button size="large" color="primaryAlt" onClick={onAddContracts}>
            Adicionar pessoas
          </Button>
        )}
      </Box>
      <Box
        sx={{
          height: '100%',
          maxHeight: 'calc(100vh - 330px)',
          overflowY: 'auto',
          overflowX: 'overlay',
        }}
      >
        <ContractsTable
          isLoading={isLoading}
          isError={isError}
          data={data}
          dataGridProps={dataGridProps}
          moveContract={setMoveContract}
        />
      </Box>
      <DepartmentMoveContractDialog
        open={moveContract !== undefined}
        onClose={() => setMoveContract(undefined)}
        onMoved={() => {
          fetchDepartmentContracts();
          onContractsMoved();
          setMoveContract(undefined);
        }}
        contract={moveContract}
      />
    </Box>
  );
}

function ContractsTable({
  isLoading,
  isError,
  data,
  dataGridProps,
  moveContract,
}: {
  isLoading: boolean;
  isError: boolean;
  data: ContractList | undefined;
  dataGridProps: DataGridProps;
  moveContract: (contract: ContractSummary) => void;
}) {
  const { paginationProps, sortingProps } = dataGridProps;
  if (isError) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <ErrorAlert
          message="Falha ao carregar contratos, por favor tente novamente mais tarde.
          Se o problema persistir, entre em contato com o suporte da Tako."
        />
      </Box>
    );
  }

  if (isLoading || !data) {
    return (
      <Box mr={2} height="95%">
        <Skeleton variant="rounded" width="100%" height="100%" sx={{ mt: 1 }} />
      </Box>
    );
  }

  return (
    <Box width="565px">
      <DataGrid
        sortingProps={sortingProps}
        paginationProps={paginationProps}
        totalRowCount={data.total || 0}
        getRowId={(row) => {
          return `${row.contractId}-${row.personId}`;
        }}
        rows={data.data}
        columns={[
          {
            field: 'name',
            headerName: 'Nome',
            renderHeader: (params) => {
              return <Box ml={2}>{params.field}</Box>;
            },
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
              return params.row.name;
            },
            renderCell: ({ value, row }) => {
              return (
                <Box>
                  <UserAvatar
                    name={value}
                    pictureUrl={row.pictureUrl}
                    expandNameOnHover={true}
                    sx={{
                      '--UserAvatar-name-max-width': '13em',
                    }}
                  />
                </Box>
              );
            },
          },
          jobTitleColumn(),
          {
            field: 'action',
            headerName: '',
            renderCell: (params: GridRenderCellParams) => (
              <DepartmentContractActionMenu
                moveContract={() => moveContract(params.row)}
              />
            ),
          },
        ]}
        onRowClick={(params) =>
          window.open(`/people/${params.row.contractId}`, '_blank')
        }
        getRowSx={() => ({
          'td:nth-last-of-type(1)': {
            '*': {
              opacity: 0,
            },
          },
          ':hover': {
            'td:nth-last-of-type(1)': {
              '*': {
                opacity: 1,
              },
            },
          },
        })}
      />
    </Box>
  );
}
