import { useState } from 'react';

import { QueryClient, useQueryClient } from '@tanstack/react-query';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Typography,
} from '@mui/material';

import {
  ContractEntry,
  PayrollEntry,
  PayrollList,
  fetchArchivePayroll,
  fetchSearchAllPayrolls,
  useGetContract,
} from '@octopus/api';
import { contractTypes } from '@octopus/contract-types';
import { payrollStatuses } from '@octopus/payroll-types';

import { ActionMenu } from '../../../modules/components/ActionMenu';
import { SnackbarType } from '../../../modules/hooks/snackbarContext';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { pollUntil } from '../../../utils/pollUntil';
import { useFFlags } from '../../fflags';

export const useArchivePayroll = (
  payroll: PayrollEntry,
  onExit: () => void,
) => {
  const [isOpen, setIsOpen] = useState(false);
  const { FFlags } = useFFlags();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: contract, isLoading } = useGetContract(
    {
      pathParams: {
        organizationId: payroll.organizationId,
        contractId: payroll.contractId,
      },
    },
    { enabled: !!payroll.contractId },
  );

  const handleClose = () => {
    setIsOpen(false);
    onExit();
  };

  const ArchivePayroll = () => {
    if (isLoading) {
      return <Skeleton variant="rectangular" width={32} height={32} />;
    }

    if (!canArchivePayroll(contract)) return null;

    return (
      <FFlags proLaborePayrollArchivingEnabled>
        <>
          <ActionMenu
            variant="button"
            menuItems={[
              {
                label: 'Arquivar folha',
                onClick: () => setIsOpen(true),
              },
            ]}
          />
          <ArchivePayrollModal
            onCancel={() => setIsOpen(false)}
            onConfirm={handleArchivePayroll(
              payroll,
              handleClose,
              showSnackbar,
              updatePayrollsPage(queryClient),
            )}
            isOpen={isOpen}
          />
        </>
      </FFlags>
    );
  };

  return { ArchivePayroll };
};

const ArchivePayrollModal = (props: {
  onCancel: () => void;
  onConfirm: () => Promise<void>;
  isOpen: boolean;
}) => {
  const { onCancel, onConfirm, isOpen } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Typography mt={2} mb={1} variant="h1" fontWeight={700}>
          Arquivar folha de pagamento
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" fontWeight={500}>
          A folha de pagamento será arquivada e não será mais possível editar os
          dados da folha. Caso necessário, entre em contato com o suporte.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          variant="outlined"
          color="primaryAlt"
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={handleConfirm}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : 'Arquivar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const canArchivePayroll = (contract: ContractEntry) => {
  if (contract.contractType === contractTypes.brClt) {
    const { trabalho } = contract?.br || {};
    return !!trabalho?.codCateg && [721, 722, 723].includes(trabalho?.codCateg);
  }

  return false;
};

const handleArchivePayroll =
  (
    payroll: PayrollEntry,
    onExit: () => void,
    showSnackbar: (snackbar: SnackbarType) => void,
    updatePayrollsPage: () => void,
  ) =>
  () => {
    const onSuccess = () => {
      onExit();
      showSnackbar({
        isOpen: true,
        Message: 'Folha de pagamento arquivada com sucesso',
        hasCloseAction: true,
      });
    };

    const onError = () => {
      showSnackbar({
        isOpen: true,
        variant: 'error',
        Message: 'Erro ao arquivar folha de pagamento',
        hasCloseAction: true,
      });
    };

    return fetchArchivePayroll({
      pathParams: {
        organizationId: payroll.organizationId,
        companyId: payroll.companyId,
        payrollId: payroll.payrollId,
      },
    })
      .then(waitingForPayroll(payroll))
      .then(updatePayrollsPage)
      .then(onSuccess)
      .catch(onError);
  };

const waitingForPayroll = (payroll: PayrollEntry) => () => {
  const assertion = (result: PayrollList) => {
    return result.data.some(({ payrollId }) => payrollId === payroll.payrollId);
  };

  const pathParams = {
    organizationId: payroll.organizationId,
    companyId: payroll.companyId,
  };

  const body = {
    filtering: {
      elements: {
        period: [payroll.period],
        type: [payroll.type],
        status: [payrollStatuses.archived],
      },
    },
  };

  return pollUntil({
    action: () => fetchSearchAllPayrolls({ pathParams, body }),
    assertion,
    intervalMillis: 1000,
    timeoutSeconds: 60,
  });
};

const updatePayrollsPage = (queryClient: QueryClient) => () => {
  return queryClient.refetchQueries({
    predicate(query) {
      return (
        query.queryKey.includes('searchAllPayrolls') ||
        query.queryKey.includes('period-summary')
      );
    },
  });
};

