export type ExtraFields = {
  jobTitleName: string;
  todayInString: string;
  companyName: string;
  companyRazaoSocial: string;
  companyAddress: string;
  companyCity: string;
  companyUf: string;
  companyUfExtenso: string;
  companyCnpj: string;
  companyLogradouro: string;
  companyComplemento: string;
  companyNumero: string;
  companyBairro: string;
  companyCep: string;
  organizationName: string;
  enderecoCompleto: string;
  salarioPorExtenso: string;
  admissionDateInString: string;
  ctps: string;
  ctpsSeries: string;
  paisNascimento: string;
  periodoExperienciaEmDias: number;
  prorrogacaoPeriodoExperienciaEmDias: number;
  sindicatoName: string;
  sindicatoCodigoSindical: string;
};

export const ExtraFieldLabels = {
  jobTitleName: 'Cargo por extenso',
  todayInString: 'Data de hoje (por extenso)',
  companyName: 'Nome da empresa (company)',
  companyRazaoSocial: 'Razão social da empresa (company)',
  companyAddress: 'Endereço completo da empresa (company)',
  companyCity: 'Cidade da empresa (company)',
  companyUf: 'Estado(uf) da empresa (company)',
  companyUfExtenso: 'Estado(uf) da empresa (company) por extenso',
  companyCnpj: 'CNPJ da empresa (company)',
  companyLogradouro: 'Logradouro da empresa (company)',
  companyComplemento: 'Complemento da empresa (company)',
  companyNumero: 'Número da empresa (company)',
  companyBairro: 'Bairro da empresa (company)',
  companyCep: 'CEP da empresa (company)',
  organizationName: 'Nome da organização (organization)',
  enderecoCompleto: 'Endereço completo do colaborador (endereco)',
  salarioPorExtenso: 'Salário (por extenso)',
  admissionDateInString: 'Data de admissão (por extenso)',
  ctps: 'Carteira de Trabalho e Previdência Social (CTPS)',
  ctpsSeries: 'Série da CTPS',
  paisNascimento: 'País de nascimento (por extenso)',
  periodoExperienciaEmDias: 'Período de experiência em dias',
  prorrogacaoPeriodoExperienciaEmDias: 'Prorrogação do período de experiência em dias',
  sindicatoName: 'Nome do sindicato',
  sindicatoCodigoSindical: 'Código Sindical do sindicato',
};

export const EXTRA_FIELDS_PREFIX = 'extraField';
