import React from 'react';

import { IconArchive } from '@tabler/icons-react';

import { Box, Button, ListItem, Typography } from '@mui/material';

import { CompanySummary, JobTitleEntry } from '@octopus/api';
import { CBOs } from '@octopus/esocial/mapper';
import { formatCBO } from '@octopus/formatters';

import { useFFlags } from '../../../../app/fflags';

export type JobTitleDataPros = {
  data: JobTitleEntry;
  companies: CompanySummary[] | undefined;
  startEditing?: () => void;
};

export function JobTitleData({
  data,
  companies,
  startEditing,
}: JobTitleDataPros) {
  const { FFlags } = useFFlags();
  return (
    <Box data-testid="job-title-data">
      {!data.active && (
        <Box display="flex" px={1.5} py={0.5}>
          <Box
            display="flex"
            alignItems="center"
            gap={0.5}
            bgcolor="background.error"
            px={1}
            py={0.5}
            borderRadius={0.5}
          >
            <IconArchive className="error main" size="16" />
            <Typography color="error.main" variant="body2" fontWeight="500">
              Desativado
            </Typography>
          </Box>
        </Box>
      )}
      <Box display="flex">
        <Box
          display="flex"
          flexDirection="column"
          gap={0.25}
          p={1.5}
          flex="1 1 0"
        >
          <Typography variant="caption" fontWeight="700">
            Código
          </Typography>
          <Typography variant="body2" fontWeight="400">
            {data.code ?? '-'}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={0.25}
          p={1.5}
          flex="5 1 0"
        >
          <Typography variant="caption" fontWeight="700">
            Nome
          </Typography>
          <Typography variant="body2" fontWeight="400">
            {data.name}
          </Typography>
        </Box>
      </Box>
      {data.occupationCode && (
        <Box display="flex">
          <Box
            display="flex"
            flexDirection="column"
            gap={0.25}
            p={1.5}
            flex="1 1 0"
          >
            <Typography variant="caption" fontWeight="700">
              CBO
            </Typography>
            <Typography variant="body2" fontWeight="400">
              {formatCBO(data.occupationCode)}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={0.25}
            p={1.5}
            flex="5 1 0"
          >
            <Typography variant="caption" fontWeight="700">
              Título
            </Typography>
            <Typography variant="body2" fontWeight="400">
              {CBOs.getByCode(data.occupationCode)}
            </Typography>
          </Box>
        </Box>
      )}
      {data.description && (
        <Box display="flex" flexDirection="column" gap={0.25} p={1.5}>
          <Typography variant="caption" fontWeight="700">
            Descrição
          </Typography>
          <Typography variant="body2" fontWeight="400">
            {data.description}
          </Typography>
        </Box>
      )}
      <Box display="flex" flexDirection="column" gap={0.25} p={1.5}>
        <Typography variant="caption" fontWeight="700">
          Atuações que podem usar este cargo
        </Typography>
        <Typography
          variant="body2"
          fontWeight="400"
          sx={{
            listStyleType: 'disc',
            listStylePosition: 'inside',
          }}
        >
          {!data.contractTypes || data.contractTypes.length === 2 ? (
            'Todas'
          ) : data.contractTypes[0] === 'br:clt' ? (
            <ListItem
              sx={{
                listStyleType: 'disc',
                display: 'list-item',
                p: 0.5,
              }}
            >
              Colaborador
            </ListItem>
          ) : (
            <ListItem
              sx={{
                listStyleType: 'disc',
                display: 'list-item',
                p: 0.5,
              }}
            >
              Prestador de serviço
            </ListItem>
          )}
        </Typography>
      </Box>
      {companies?.length > 1 && (
        <Box display="flex" flexDirection="column" gap={0.25} p={1.5}>
          <Typography variant="caption" fontWeight="700">
            Empresas que podem usar este cargo
          </Typography>
          <Typography
            variant="body2"
            fontWeight="400"
            sx={{
              listStyleType: 'disc',
              listStylePosition: 'inside',
            }}
          >
            {!data.enabledForCompanies ||
            data.enabledForCompanies.length === companies?.length
              ? 'Todas'
              : data.enabledForCompanies.map((companyId) => {
                  return (
                    <ListItem
                      sx={{
                        listStyleType: 'disc',
                        display: 'list-item',
                        p: 0.5,
                      }}
                    >
                      {companies?.find(
                        (company) => company.companyId === companyId,
                      )?.name ?? '-'}
                    </ListItem>
                  );
                })}
          </Typography>
        </Box>
      )}
      {data.br && (
        <>
          <Box display="flex" flexDirection="column" gap={0.25} p={1.5}>
            <Typography variant="caption" fontWeight="700">
              Cargo de confiança
            </Typography>
            <Typography variant="body2" fontWeight="400">
              {data.br?.cargoDeConfianca === true ? 'Sim' : 'Não'}
            </Typography>
          </Box>
          {data.br?.cargoDeConfianca === true && (
            <Box
              borderLeft="6px solid"
              borderColor="strokes.light"
              ml={1}
              px={2}
              py={1}
              display="flex"
              flexDirection="column"
            >
              <Box display="flex">
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={0.25}
                  p={1.5}
                  flex="1 1 0"
                >
                  <Typography variant="caption" fontWeight="700">
                    CBO
                  </Typography>
                  <Typography variant="body2" fontWeight="400">
                    {formatCBO(data.br.cboDoCargoDeConfianca)}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={0.25}
                  p={1.5}
                  flex="5 1 0"
                >
                  <Typography variant="caption" fontWeight="700">
                    Título
                  </Typography>
                  <Typography variant="body2" fontWeight="400">
                    {CBOs.getByCode(data.br.cboDoCargoDeConfianca)}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap={0.25} p={1.5}>
                <Typography variant="caption" fontWeight="700">
                  Descrição
                </Typography>
                <Typography variant="body2" fontWeight="400">
                  {data.br.descricaoDoCargoDeConfianca}
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
      <FFlags canEditJobTitle>
        <Box display="flex" justifyContent="flex-end" width="100%" pt={3}>
          <Button
            size="large"
            variant="outlined"
            color="secondary"
            sx={{
              width: '120px',
              height: '40px',
            }}
            onClick={startEditing}
            data-testid="job-title-edit-button"
          >
            Editar
          </Button>
        </Box>
      </FFlags>
    </Box>
  );
}
