import { useState } from 'react';

import { IconArrowRight } from '@tabler/icons-react';

import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';

import { Button } from '@octopus/ui/design-system';

type TimesheetDialog = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onOverride: () => void;
  onNotOverride: () => void;
};

export function TimesheetOverrideInputDialog({
  open,
  setOpen,
  onOverride,
  onNotOverride,
}: TimesheetDialog) {
  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="timesheet-override-dialog-title"
      aria-describedby="timesheet-override-dialog-description"
      sx={{
        width: '100%',
      }}
      onClose={() => setOpen(false)}
      data-testid="timesheet-override-dialog"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          py: (theme) => theme.spacing(2),
        }}
        data-testid="timesheet-override-dialog-container"
      >
        <DialogTitle
          id="timesheet-override-dialog-title"
          data-testid="timesheet-override-dialog-header"
          variant="h2"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pt: (theme) => theme.spacing(3),
            pb: (theme) => theme.spacing(2),
          }}
        >
          Lançamentos conflitantes
        </DialogTitle>
        <DialogContent
          id="timesheet-override-dialog-description"
          data-testid="timesheet-override-dialog-content"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body1" sx={{ pb: (theme) => theme.spacing(1) }}>
            Alguns lançamentos importados conflitam com valores previamente
            preenchidos. Como deseja continuar?
          </Typography>
          <PaperButton
            data-testid="timesheet-override-dialog-override"
            title="Substituir lançamentos anteriores"
            description="Inserir todos os valores e substituir qualquer valor já preenchido anteriormente."
            onClick={onOverride}
          />
          <PaperButton
            data-testid="timesheet-override-dialog-keep"
            title="Manter lançamentos anteriores"
            description="Inserir somente os valores para lançamentos que ainda não foram preenchidos e manter valores existentes inalterados."
            onClick={onNotOverride}
          />
        </DialogContent>
      </Box>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: 2,
        }}
      >
        <Button variantSemantic="secondary" onClick={() => setOpen(false)}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type PaperButtonProps = {
  onClick: () => void | Promise<void>;
  title: string;
  description: string;
};

function PaperButton({
  onClick,
  title,
  description,
  ...props
}: PaperButtonProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    try {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      await onClick();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper
      onClick={handleClick}
      data-testid="timesheet-override-dialog-paper-button"
      sx={{
        cursor: 'pointer',
        py: (theme) => theme.spacing(4),
        px: (theme) => theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: (theme) => theme.spacing(2),
        '&:hover': {
          bgcolor: (theme) =>
            isLoading ? theme.palette.action.hover : theme.palette.grey[50],
        },
        opacity: isLoading ? 0.5 : 1,
        border: (theme) => `1px solid ${theme.palette.strokes.light}`,
        borderRadius: (theme) => theme.spacing(1.5),
      }}
      elevation={0}
      {...props}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: (theme) => theme.spacing(1),
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {description}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <IconArrowRight size={24} />
        )}
      </Box>
    </Paper>
  );
}
