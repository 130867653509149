import ExcelJS from 'exceljs';

import { capitalize, formatCNPJ } from '../formatter';
import { setCell, setImage, setKeyValue } from '../operations';
import { workbookStyles } from '../styles';
import { HeaderSectionProps } from '../types';

import { TAKO_LOGO } from './takoLogo';

const LOGO_HEIGHT = 24;

export function writeHeaderSection({
  workbook,
  worksheet,
  startRow,
  startColumn,
  props: { organizationName, reportName, companyLegalIdentifier, author },
}: {
  workbook: ExcelJS.Workbook;
  worksheet: ExcelJS.Worksheet;
  startRow: number;
  startColumn: number;
  props: HeaderSectionProps;
}): number {
  setImage(workbook, worksheet, {
    base64Image: TAKO_LOGO,
    position: { col: startColumn - 1, row: startRow - 1 },
    dimensions: { width: LOGO_HEIGHT * 3.5, height: LOGO_HEIGHT },
  });

  setKeyValue(worksheet, startRow + 2, startColumn, {
    key: organizationName,
    value: formatCNPJ(companyLegalIdentifier),
    keyStyle: { ...workbookStyles.captionKey, bold: true },
  });
  setCell(
    worksheet,
    startRow + 4,
    startColumn,
    reportName,
    workbookStyles.title,
  );
  setKeyValue(worksheet, startRow + 5, startColumn, {
    key: 'Gerado em',
    value: new Date(),
    valueStyle: workbookStyles.bodyValue,
  });
  setKeyValue(worksheet, startRow + 6, startColumn, {
    key: 'Gerado por',
    value: capitalize(author),
    valueStyle: workbookStyles.bodyValue,
  });
  return startRow + 8;
}
