import React from 'react';

import { Box, Skeleton, Typography } from '@mui/material';

import {
  ContractList,
  OrganizationEntry,
  useGetAllCompanies,
} from '@octopus/api';

import {
  SidePanel,
  SidePanelMenu,
  SidePanelMenuItem,
} from '../../modules/components/SidePanel';
import { QueryResult } from '../../modules/types';
import { useFFlags } from '../fflags';

import {
  OrganizationInfoCard,
  OrganizationInfoCardSkeleton,
} from './OrganizationInfoCard';
import { PageContent, pageContents } from './types';

export type OrganizationSidePanelProps = {
  organizationId: string;
  organizationQuery: QueryResult<OrganizationEntry>;
  contractsQuery: Omit<QueryResult<ContractList>, 'refetch' | 'isFetching'>;
  pageContent: PageContent;
  setPageContent: (pageContent: PageContent) => void;
};

export function OrganizationSidePanel({
  organizationId,
  organizationQuery,
  contractsQuery,
  pageContent,
  setPageContent,
}: OrganizationSidePanelProps) {
  const organization = organizationQuery.data;
  const { name, logo } = organization ?? ({} as OrganizationEntry);

  const { FFlags } = useFFlags();

  const companiesQuery = useGetAllCompanies(
    {
      pathParams: {
        organizationId,
      },
      queryParams: {
        size: '1',
      },
    },
    {
      enabled: !!organizationId,
    },
  );

  return (
    <Box data-testid="organization-side-panel">
      <SidePanel
        isLoading={organizationQuery.isLoading}
        name={name}
        InfoCard={
          <OrganizationInfoCard
            organizationId={organizationId}
            refetchOrganization={organizationQuery.refetch}
            name={name}
            logo={logo}
          >
            <Box display="flex" flexDirection="column" gap={0.5}>
              {companiesQuery.isLoading ? (
                <Skeleton variant="rounded" width="100px" height="16px" />
              ) : (
                <Typography variant="caption">
                  {companiesQuery.data?.total ?? 0} Empresa
                  {companiesQuery.data?.total !== 1 && 's'}
                </Typography>
              )}
              {contractsQuery.isLoading ? (
                <Skeleton variant="rounded" width="100px" height="16px" />
              ) : (
                <Typography variant="caption">
                  {contractsQuery.data?.total ?? 0} Contrato
                  {contractsQuery.data?.total !== 1 && 's'}
                </Typography>
              )}
            </Box>
          </OrganizationInfoCard>
        }
        InfoCardSkeleton={<OrganizationInfoCardSkeleton />}
        Menu={
          <SidePanelMenu>
            <SidePanelMenuItem
              title="Cargos"
              selected={pageContent === pageContents.jobTitles}
              onClick={() => {
                setPageContent(pageContents.jobTitles);
              }}
              testId="job-title-page-button"
            />
            <FFlags canAccessCostCenterManagement>
              <SidePanelMenuItem
                title="Centros de Custo"
                selected={pageContent === pageContents.costCenters}
                onClick={() => {
                  setPageContent(pageContents.costCenters);
                }}
                testId="cost-centers-page-button"
              />
            </FFlags>
            <FFlags canAccessDepartmentManagement>
              <SidePanelMenuItem
                title="Departamentos"
                selected={pageContent === pageContents.departments}
                onClick={() => {
                  setPageContent(pageContents.departments);
                }}
                testId="departments-page-button"
              />
            </FFlags>
          </SidePanelMenu>
        }
      />
    </Box>
  );
}
