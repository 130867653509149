import { ChangeEvent, useContext, useEffect, useState } from 'react';

import { IconCopy, IconFileImport } from '@tabler/icons-react';

import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

import { fetchListCustomFields, fetchPostTemplate } from '@octopus/api';
import type {
  CustomFieldEntry,
  TemplateAttachmentAllowedContentType,
  TemplateCategoryEnum,
} from '@octopus/api';
import { FieldChangeFormatters } from '@octopus/formatters';
import {
  EXTRA_FIELDS_PREFIX,
  ExtraFieldLabels,
  TERMINATION_FIELDS_PREFIX,
  TerminationFieldLabels,
} from '@octopus/template-types';

import { PageContainer } from '../../../modules/components/page/PageContainer';
import { PageTitle } from '../../../modules/components/page/PageTitle';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { AppContext } from '../../context';

import { templateCategoryLabels } from './types';
import { isValidHttpUrl } from './utils';

type OptionType = {
  key: string;
  label: string;
};

export function CreateTemplate() {
  const { appContext } = useContext(AppContext);
  const organizationId = appContext?.membership?.organizationId;

  const [documentURL, setDocumentURL] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [category, setCategory] = useState<TemplateCategoryEnum>();
  const [customFields, setCustomFields] = useState<OptionType[]>([]);
  const [file, setFile] = useState<File | null>(null);

  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!organizationId) return;

    fetchListCustomFields({
      pathParams: {
        organizationId,
      },
    })
      .then((fields: CustomFieldEntry[]) => {
        const customFieldOptions = fields
          .filter((field) => field.type !== 'document')
          .map((field) => ({
            key: `customField/${field.name}`,
            label: `${field.label} (Campo Customizado)`,
          }));
        setCustomFields(customFieldOptions);
      })
      .catch((err) => {
        console.error('Erro ao carregar campos customizados:', err);
      });
  }, [organizationId]);

  const handleSaveTemplate = async () => {
    if (!organizationId) {
      console.error('OrganizationId não encontrado');
      return;
    }

    setIsLoading(true);

    fetchPostTemplate({
      body: {
        name: templateName,
        category,
        attachment: {
          type: 'template',
          contentType: file.type as TemplateAttachmentAllowedContentType,
          fileName: file.name,
          contentLength: file.size,
        },
      },
      pathParams: {
        organizationId,
      },
    })
      .then(({ file: { uploadUrl } }) => {
        uploadTemplateFile(uploadUrl, file);
      })
      .catch((error) => {
        showSnackbar({
          variant: 'error',
          isOpen: true,
          Message: 'Erro ao criar template',
          autoHideDuration: 3000,
        });
        console.error({ error });
        setIsLoading(false);
      });
  };

  const uploadTemplateFile = async (url: string, file: File) => {
    await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    })
      .then(() => {
        showSnackbar({
          variant: 'default',
          isOpen: true,
          Message: 'Template salvo com sucesso',
          autoHideDuration: 3000,
        });

        setDocumentURL('');
        setTemplateName('');
        setCategory(undefined);
        setCustomFields([]);
        setFile(null);
      })
      .catch((error) => {
        showSnackbar({
          variant: 'error',
          isOpen: true,
          Message: 'Erro ao salvar template',
          autoHideDuration: 3000,
        });
        console.error({ error });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleURLChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isValidHttpUrl(e.target.value)) {
      setDocumentURL(e.target.value);
    } else {
      alert('URL inválida');
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files?.[0];
      if (!file) {
        alert('Nenhum arquivo selecionado');
        return;
      }
      setFile(file);
    } catch (error) {
      console.error(error);
      showSnackbar({
        variant: 'error',
        isOpen: true,
        Message: 'Erro ao importar documento',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <PageContainer width="100%">
      <PageTitle title="Criar template" icon={IconFileImport} />
      <Box
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Box
          sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="h3">1. Seleção do documento</Typography>
          <Typography variant="body2">
            Cole aqui a URL(endereço) do documento que deseja usar como
            template. Deve ser um documento do Google Docs.
          </Typography>
          <TextField
            fullWidth
            value={documentURL}
            onChange={handleURLChange}
            placeholder="Cole a URL do documento aqui"
          />
        </Box>
        {documentURL && (
          <Box
            sx={{
              width: '100%',
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant="h3">
              2. Substituição de Campos do Contrato
            </Typography>
            <Typography variant="body2">
              Digite o nome do campo para buscar a chave que deve ser
              substituida no documento abaixo.
              <br />
              Após encontrar a chave, copie o texto e cole no template.
            </Typography>
            {organizationId ? (
              <SelectField customFields={customFields} />
            ) : (
              <Typography color="error">
                Erro: OrganizationId não encontrado
              </Typography>
            )}
            <iframe title="template" src={documentURL} height="900px" />
          </Box>
        )}
        {documentURL && (
          <Box
            sx={{
              width: '100%',
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
              }}
            >
              <Typography variant="h3">
                3. Substituição de Campos do Contrato
              </Typography>
              <Typography variant="body2">
                Após terminar todas as alterações, baixe o arquivo acima no
                formato .docx e faça o upload desse arquivo abaixo:
              </Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
              }}
            >
              <Typography variant="h4">Envio do arquivo</Typography>
              <Typography variant="caption">
                Somente arquivos .docx são aceitos.
              </Typography>
              <input type="file" accept="*.docx" onChange={handleFileChange} />
            </Box>
            <Box
              sx={{
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
              }}
            >
              <Typography variant="h4">Nome</Typography>
              <Typography variant="caption">
                Dê um nome ao template para facilitar a identificação futura.
              </Typography>
              <TextField
                fullWidth
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                placeholder="Nome do template"
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
              }}
            >
              <Typography variant="h4">Categoria</Typography>
              <Typography variant="caption">
                Em qual processo o template será utilizado?
              </Typography>
              <SelectCategory setCategory={setCategory} />
            </Box>
            <LoadingButton
              loading={isLoading}
              disabled={!file || !templateName || !category}
              variant="contained"
              color="primary"
              sx={{ alignSelf: 'flex-end' }}
              onClick={handleSaveTemplate}
            >
              Salvar template
            </LoadingButton>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

const SelectField = ({ customFields }: { customFields: OptionType[] }) => {
  const [option, setOption] = useState<OptionType | null>(null);

  const options = [
    ...Object.entries(FieldChangeFormatters)
      .filter(
        ([_key, option]) =>
          'label' in option && typeof option.label === 'string',
      )
      .map(([key, option]) => ({
        key,
        label:
          'label' in option && typeof option.label === 'string'
            ? option.label +
              (key.split('/')[1] ? ` (${key.split('/')[1]})` : '')
            : key,
      })),
    ...customFields,
    ...Object.entries(ExtraFieldLabels).map(([key, label]) => ({
      key: `${EXTRA_FIELDS_PREFIX}/${key}`,
      label,
    })),
    ...Object.entries(TerminationFieldLabels).map(([key, label]) => ({
      key: `${TERMINATION_FIELDS_PREFIX}/${key}`,
      label,
    })),
  ] as OptionType[];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        width: '100%',
        boxSizing: 'border-box',
        py: 2,
      }}
    >
      <Autocomplete
        sx={{ width: '50%' }}
        forcePopupIcon
        disablePortal
        fullWidth
        clearOnBlur={false}
        noOptionsText="Nenhuma opção encontrada."
        value={option}
        onChange={(_, newValue) => setOption(newValue)}
        options={options}
        renderInput={(params) => (
          <TextField
            fullWidth
            placeholder="Selecione um campo a ser substituído no template"
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
                autoComplete: 'off',
                defaultValue: null,
                value: option?.label,
              },
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option?.key === value?.key}
        getOptionKey={(option) =>
          !option ? '' : 'key' in option ? option?.key : option
        }
        getOptionLabel={(option) =>
          !option ? '' : 'label' in option ? option?.label : option
        }
      />
      {option && (
        <Box
          display="flex"
          flexDirection="column"
          gap={0.5}
          sx={{ width: '50%' }}
        >
          <Typography variant="body2">
            Substitua{' '}
            <b>
              <i>{option?.label}</i>
            </b>{' '}
            no documento abaixo por:
          </Typography>
          <Box display="flex" flexDirection="row" gap={2}>
            <Typography variant="body1" color="text.secondary">
              {option?.key ? `{${option?.key}}` : 'Selecione uma opção ao lado'}
            </Typography>
            <Button
              variant="contained"
              color="primaryAlt"
              size="small"
              sx={{ display: 'flex', alignItems: 'center', gap: 1, px: 1 }}
              onClick={() =>
                navigator.clipboard.writeText(
                  option?.key ? `{${option?.key}}` : '',
                )
              }
            >
              <IconCopy size={16} />
              Copiar
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const SelectCategory = ({
  setCategory,
}: {
  setCategory: (category: TemplateCategoryEnum) => void;
}) => {
  const [option, setOption] = useState<OptionType | null>(null);
  const options = Object.entries(templateCategoryLabels).map(
    ([key, value]) => ({
      key,
      label: value,
    }),
  ) as OptionType[];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Autocomplete
        sx={{ width: '50%' }}
        forcePopupIcon
        disablePortal
        fullWidth
        clearOnBlur={false}
        noOptionsText="Nenhuma opção encontrada."
        value={option}
        onChange={(_, newValue) => {
          setOption(newValue);
          setCategory(newValue?.key as TemplateCategoryEnum);
        }}
        options={options}
        renderInput={(params) => (
          <TextField
            fullWidth
            placeholder="Selecione um campo a ser substituído no template"
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
                autoComplete: 'off',
                defaultValue: null,
                value: option?.label,
              },
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option?.key === value?.key}
        getOptionKey={(option) =>
          !option ? '' : 'key' in option ? option?.key : option
        }
        getOptionLabel={(option) =>
          !option ? '' : 'label' in option ? option?.label : option
        }
      />
    </Box>
  );
};
