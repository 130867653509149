import { useContext } from 'react';

import { Box } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { AppContext } from '../../../../app/context';
import { useCostCenter } from '../../../hooks/useCostCenter';
import { useDepartment } from '../../../hooks/useDepartment';
import { useJobTitle } from '../../../hooks/useJobTitle';
import { ExpandableTypography } from '../../ExpandableTypography';

const CompanyComponent = ({ value }: { value: string }) => {
  const { appContext } = useContext(AppContext);
  const { companiesMap } = appContext || {};

  return (
    <Box maxWidth={150}>
      <ExpandableTypography>{companiesMap?.[value]?.name}</ExpandableTypography>
    </Box>
  );
};

export const companyColumn: GridColDef = {
  field: 'companyId',
  headerName: 'Empresa',
  sortable: false,
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.companyId;
  },
  renderCell: ({ value }) => {
    return <CompanyComponent value={value} />;
  },
};

const DepartmentComponent = ({ value }: { value: string | undefined }) => {
  const department = useDepartment(value);

  return (
    <ExpandableTypography
      sx={{
        '--ExpandableTypography-max-width': '13em',
      }}
    >
      {department?.name ?? value ?? '--'}
    </ExpandableTypography>
  );
};

export function departmentColumn(
  valueGetter: (params: GridValueGetterParams) => string = (
    params: GridValueGetterParams,
  ) => {
    return params.row.departmentId;
  },
): GridColDef {
  return {
    field: 'departmentId',
    headerName: 'Departamento',
    sortable: false,
    valueGetter,
    renderCell: ({ value }) => {
      return <DepartmentComponent value={value} />;
    },
  };
}

const JobTitleComponent = ({ value }: { value: string | undefined }) => {
  const jobTitle = useJobTitle(value);

  return (
    <ExpandableTypography
      sx={{
        '--ExpandableTypography-max-width': '13em',
      }}
    >
      {jobTitle?.summary?.name ?? value ?? '--'}
    </ExpandableTypography>
  );
};

export function jobTitleColumn(
  valueGetter: (params: GridValueGetterParams) => string = (
    params: GridValueGetterParams,
  ) => {
    return params.row.title;
  },
): GridColDef {
  return {
    field: 'title',
    headerName: 'Cargo',
    sortable: false,
    valueGetter,
    renderCell: ({ value }) => {
      return <JobTitleComponent value={value} />;
    },
  };
}

const CostCenterComponent = ({ value }: { value: string | undefined }) => {
  const costCenter = useCostCenter(value);

  return (
    <ExpandableTypography
      sx={{
        '--ExpandableTypography-max-width': '13em',
      }}
    >
      {costCenter?.summary?.name ?? value ?? '--'}
    </ExpandableTypography>
  );
};

export function costCenterColumn(
  valueGetter: (params: GridValueGetterParams) => string = (
    params: GridValueGetterParams,
  ) => {
    return params.row.costCenterId;
  },
): GridColDef {
  return {
    field: 'costCenterId',
    headerName: 'Centro de custo',
    sortable: false,
    valueGetter,
    renderCell: ({ value }) => {
      return <CostCenterComponent value={value} />;
    },
  };
}
