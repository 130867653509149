import { omit } from 'lodash';

import {
  companyColumn,
  departmentColumn,
} from '../../../../modules/components/table/columns/CompanyColumns';
import {
  ManagerVacationsTabsConfig,
  VacationsDetailsTabs,
  VacationsTableColumns,
  VacationsTabs,
  VacationsTabsConfig,
} from '../../utils/types';

import {
  approvalDeadlineColumn,
  daysTakenColumn,
  historyActionsColumn,
  parametersColumn,
  periodColumn,
} from './Columns';
import {
  balanceColumn,
  concessionPeriodColumn,
  limitDateToStartVacationsColumn,
  nameColumn,
  nameDepartmentColumn,
} from './EmployeeColumns';
import { payrollActionsColumn, payrollStatusColumn } from './PayrollColumns';
import {
  scheduleActionsColumn,
  scheduleReviewsColumn,
  scheduleStatusColumn,
} from './ScheduleColumns';

export const getColumnsByTab = (
  isManager: boolean,
  refetchAll?: () => Promise<void>,
): VacationsTableColumns => {
  const columns = {
    [VacationsTabs.people]: [
      nameColumn,
      departmentColumn((params) => params.row.workerData.departmentId),
      companyColumn,
      balanceColumn,
      concessionPeriodColumn,
      limitDateToStartVacationsColumn,
    ],
    [VacationsTabs.requests]: [
      nameDepartmentColumn,
      companyColumn,
      daysTakenColumn,
      periodColumn,
      ...(isManager ? [] : [parametersColumn]),
      scheduleReviewsColumn,
      scheduleActionsColumn(isManager, refetchAll),
      approvalDeadlineColumn,
    ],
    [VacationsTabs.calculations]: [
      nameDepartmentColumn,
      companyColumn,
      daysTakenColumn,
      periodColumn,
      parametersColumn,
      scheduleStatusColumn,
      payrollStatusColumn,
      payrollActionsColumn(refetchAll),
      approvalDeadlineColumn,
    ],
    [VacationsTabs.vacationsHistory]: [
      nameColumn,
      departmentColumn((params) => params.row.workerData.departmentId),
      companyColumn,
      daysTakenColumn,
      periodColumn,
      scheduleStatusColumn,
      historyActionsColumn,
    ],
  };

  if (isManager) {
    return omit(columns, [VacationsTabs.calculations]);
  }

  return columns;
};

export const vacationsTabsConfig: VacationsTabsConfig = {
  [VacationsTabs.people]: {
    key: VacationsTabs.people,
    label: 'Colaboradores',
    detailsTab: VacationsDetailsTabs.available,
  },
  [VacationsTabs.requests]: {
    key: VacationsTabs.requests,
    label: 'Solicitações',
    detailsTab: VacationsDetailsTabs.requests,
    emptyMessage:
      'Não existem solicitações aguardando aprovação neste momento.',
  },
  [VacationsTabs.calculations]: {
    key: VacationsTabs.calculations,
    label: 'Cálculos',
    detailsTab: VacationsDetailsTabs.calculations,
    emptyMessage: 'Não existem cálculos para serem aprovados neste momento.',
  },
  [VacationsTabs.vacationsHistory]: {
    key: VacationsTabs.vacationsHistory,
    label: 'Histórico',
    detailsTab: VacationsDetailsTabs.history,
    emptyMessage: 'Não existem férias agendadas ainda.',
  },
};

export const getVacationsTabsConfig = (
  isManager: boolean,
): VacationsTabsConfig | ManagerVacationsTabsConfig => {
  if (isManager) {
    return omit(vacationsTabsConfig, [
      VacationsTabs.calculations,
    ]) as ManagerVacationsTabsConfig;
  }

  return vacationsTabsConfig as VacationsTabsConfig;
};
