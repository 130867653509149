import { IconDownload, IconFileSpreadsheet } from '@tabler/icons-react';

import { Box, Button, Typography } from '@mui/material';

import { TimesheetFileDefinition } from './steps/types';
import { getTemplateFileByDefinitions } from './steps/utils';

type TimesheetDownloadTemplateProps = {
  fileDefinition: TimesheetFileDefinition;
};

export function TimesheetDownloadTemplate({
  fileDefinition,
}: TimesheetDownloadTemplateProps) {
  function downloadFile(file: File) {
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = file.name;
    a.click();
    URL.revokeObjectURL(url);
  }

  async function handleDownloadTemplate() {
    try {
      const file = getTemplateFileByDefinitions(fileDefinition);

      downloadFile(file);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box
      data-testid="timesheet-download-template-section"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        bgcolor: (theme) => theme.palette.background.primary,
        paddingY: (theme) => theme.spacing(2),
        minHeight: (theme) => theme.spacing(5),
        borderRadius: (theme) => theme.spacing(1),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          paddingX: (theme) => theme.spacing(2),
          gap: (theme) => theme.spacing(2),
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconFileSpreadsheet size={24} className="primary light" />
        </Box>
        <Box
          data-testid="timesheet-download-template-description"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: (theme) => theme.spacing(10),
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            O arquivo com os lançamentos deve seguir nosso template padrão para
            minimizar erros no preenchimento.
          </Typography>
        </Box>
        <Box
          sx={{ display: 'flex', alignItems: 'center' }}
          role="region"
          aria-label="Template download"
        >
          <Button
            variant="outlined"
            color="primary"
            endIcon={<IconDownload size={18} />}
            onClick={handleDownloadTemplate}
            aria-label="Download timesheet template"
            sx={{
              height: (theme) => theme.spacing(4),
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              borderColor: (theme) => theme.palette.strokes.primary,
            }}
          >
            Baixar modelo
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
