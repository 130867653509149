import React, { useEffect, useState } from 'react';

import { z } from 'zod';

import { LoadingButton } from '@mui/lab';
import { Box, Skeleton, Typography } from '@mui/material';

import {
  VacationsConfigurationInput,
  schemas,
  useListVacationsConfiguration,
  usePutVacationsConfiguration,
} from '@octopus/api';

import { VacationsApprovalsConfiguration } from './approvalConfiguration';
import { LABEL_MAP } from './fields';
import { GroupVacationsConfig } from './groupVacations';
import { VacationsPaymentConfiguration } from './payment';
import { RuleByMembershipConfiguration } from './rules';
import { UnionExtraDaysConfig } from './unionExtraDays';

function validateConfiguration(
  configuration: VacationsConfigurationInput,
): string | null {
  try {
    schemas.vacationsConfigurationInput.parse(configuration);
    configuration.unionExtraDays.forEach((day) => {
      schemas.vacationsConfigurationNonDeductibleDay.parse(day);
    });

    const errorsInApprovalSteps = configuration.approvalConfiguration.steps
      .map((step, idx) => {
        const existentRoles: string[] = [];
        const duplicates = new Set<string>();

        step.roles.forEach((role) => {
          if (existentRoles.includes(LABEL_MAP[role])) {
            duplicates.add(LABEL_MAP[role]);
          } else {
            existentRoles.push(LABEL_MAP[role]);
          }
        });

        if (duplicates.size > 0) {
          return `A etapa ${idx + 1} de aprovação possui aprovadores duplicados: ${[...duplicates].join(', ')}`;
        }

        return null;
      })
      .filter((error) => error !== null); // Filtra etapas sem erros

    if (errorsInApprovalSteps.length > 0) {
      return errorsInApprovalSteps.join('\n');
    }

    return null;
  } catch (e) {
    if (e instanceof z.ZodError) {
      return buildErrorMessageFromZodError(e);
    }

    return 'Erro desconhecido.';
  }
}

export function VacationsConfigurationDetails({
  organizationId,
}: {
  organizationId: string;
}) {
  const [configuration, setConfiguration] =
    useState<VacationsConfigurationInput | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const { data, isLoading } = useListVacationsConfiguration(
    { pathParams: { organizationId: organizationId ?? '' } },
    { refetchOnWindowFocus: false, refetchOnMount: false },
  );

  const putVacations = usePutVacationsConfiguration({
    onError: (e) => {
      if (e instanceof z.ZodError) {
        setError(buildErrorMessageFromZodError(e));
      } else {
        setError('Erro desconhecido.');
      }
    },

    onSuccess: () => {
      setSuccess('Configuração salva com sucesso.');
    },
  });

  useEffect(() => {
    if (!isLoading && data && configuration === null) {
      setConfiguration({
        unionExtraDays: data.unionExtraDays,
        paymentDateConfiguration: data.paymentDateConfiguration,
        approvalConfiguration: data.approvalConfiguration,
        groupVacationsConfiguration: data.groupVacationsConfiguration,
        rules: data.rules,
      });
    }
  }, [isLoading, data, configuration]);

  if (isLoading || !configuration) {
    return (
      <Box>
        <Skeleton height={100} />
      </Box>
    );
  }

  const handleChange = (
    field: keyof VacationsConfigurationInput,
    value: unknown,
  ) => {
    setConfiguration((old) => {
      if (!old) return null;
      return { ...old, [field]: value };
    });
  };

  const submit = async () => {
    if (!configuration) return;
    const result = validateConfiguration(configuration);
    if (result) {
      setError(result);
      return;
    }
    setError(null);
    setSuccess(null);

    console.log('saving configuration', configuration);

    await putVacations.mutateAsync({
      pathParams: { organizationId },
      body: configuration,
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} p={1}>
      <VacationsPaymentConfiguration
        config={configuration.paymentDateConfiguration}
        onChange={(updated) =>
          handleChange('paymentDateConfiguration', updated)
        }
      />
      <VacationsApprovalsConfiguration
        config={configuration.approvalConfiguration}
        onChange={(updated) => handleChange('approvalConfiguration', updated)}
      />
      <UnionExtraDaysConfig
        config={configuration.unionExtraDays}
        onChange={(updated) => handleChange('unionExtraDays', updated)}
      />
      <GroupVacationsConfig
        config={configuration.groupVacationsConfiguration}
        onChange={(updated) =>
          handleChange('groupVacationsConfiguration', updated)
        }
      />

      <RuleByMembershipConfiguration
        config={configuration.rules}
        onChange={(updated) => handleChange('rules', updated)}
      />

      {error && (
        <Typography color="error" whiteSpace="pre-line">
          {error}
        </Typography>
      )}
      {success && (
        <Typography color="success" whiteSpace="pre-line">
          {success}
        </Typography>
      )}

      <Box
        display="flex"
        width={'100%'}
        justifyContent="flex-end"
        height={'40px'}
      >
        <Box width={'80px'}>
          <LoadingButton
            fullWidth
            sx={{ height: '40px' }}
            loading={putVacations.isLoading}
            variant={'contained'}
            onClick={submit}
          >
            Salvar
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}

function buildErrorMessageFromZodError(e: z.ZodError) {
  let message = '';
  for (const issue of e.issues) {
    const pathElements = issue.path.map((p) => {
      // Se p for string e existir em LABEL_MAP, trocamos pelo texto amigável
      if (typeof p === 'string' && LABEL_MAP[p]) {
        return LABEL_MAP[p];
      }
      // Se for um número (índice do array) ou string sem mapeamento, deixamos como está
      return p;
    });

    const replacedPath = pathElements
      .map((el) => (typeof el === 'number' ? `Item #${el + 1}` : el))
      .join(' > ');

    message += `${replacedPath}: ${issue.message}\n`;
  }
  return message;
}
