import React, { useEffect, useState } from 'react';

import { Box, Skeleton } from '@mui/material';

import {
  CompanySummary,
  JobTitleEntry,
  useGetJobTitleEntry,
} from '@octopus/api';

import { ErrorAlert } from '../../ErrorAlert';

import { JobTitleData } from './JobTitleData';
import { JobTitleEdit } from './JobTitleEdit';

export type JobTitleDetailsProps = {
  organizationId: string;
  jobTitleId: string;
  companies: CompanySummary[] | undefined;
  onEdit: (updatedEntry: JobTitleEntry) => void;
  onArchive: (jobTitleId: string) => void;
  onRestore: (jobTitleId: string) => void;
};

export function JobTitle({
  organizationId,
  jobTitleId,
  companies,
  onEdit,
  onArchive,
  onRestore,
}: JobTitleDetailsProps) {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsEditing(false);
  }, [organizationId, jobTitleId]);

  const { isLoading, isError, data, remove } = useGetJobTitleEntry(
    {
      pathParams: {
        organizationId,
        jobTitleId,
      },
    },
    {
      enabled: !!organizationId && !!jobTitleId,
    },
  );

  if (isLoading) {
    return <Skeleton variant="rounded" width="100%" height="100%" />;
  }

  if (isError) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <ErrorAlert
          message="Falha ao carregar cargo, por favor tente novamente mais tarde.
          Se o problema persistir, entre em contato com o suporte da Tako."
        />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      {!isEditing ? (
        <JobTitleData
          data={data}
          companies={companies}
          startEditing={() => setIsEditing(true)}
        />
      ) : (
        <JobTitleEdit
          jobTitle={data}
          companies={companies}
          onEdit={(jobTitle) => {
            onEdit(jobTitle);
            remove();
          }}
          onArchive={(jobTitleId) => {
            onArchive(jobTitleId);
            remove();
          }}
          onRestore={(jobTitleId) => {
            onRestore(jobTitleId);
            remove();
          }}
          stopEditing={() => setIsEditing(false)}
        />
      )}
    </Box>
  );
}
