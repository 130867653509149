import { useEffect, useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { CompanySummary, MembershipSummary } from '@octopus/api';

import { CompanySummaryContext, MembershipContext } from '../types';

import { fetchCompanyContext } from './companyContextHelper';
import { appContextQueryConfig } from './constants';
import { useSessionStorage } from './useSessionStorage';

const KEY = 'company';

export const useCompanies = ({
  currentMembership,
  memberships,
}: {
  currentMembership: MembershipContext;
  memberships: MembershipSummary[];
}) => {
  const queryClient = useQueryClient();
  const { sessionStorageContext, setSessionStorageContext } =
    useSessionStorage<CompanySummaryContext>(KEY);
  const [currentCompany, setCurrentCompany] = useState<CompanySummaryContext>();
  const query = useQuery({
    queryKey: ['company', currentMembership?.organizationId],
    queryFn: () =>
      fetchCompanyContext({
        organizationId: currentMembership?.organizationId,
      }),
    enabled: !!currentMembership?.organizationId,
    ...appContextQueryConfig,
  });

  useEffect(() => {
    // Non-blocking prefetch of companies for all memberships
    if (memberships?.length > 0) {
      memberships.forEach((membership) => {
        queryClient.prefetchQuery({
          queryKey: ['company', membership.organizationId],
          queryFn: () =>
            fetchCompanyContext({
              organizationId: membership.organizationId,
            }),
          ...appContextQueryConfig,
        });
      });
    }
  }, [memberships]);

  const companies: CompanySummaryContext[] = query.data?.companies ?? [];
  const companiesMap = companies.reduce(
    (acc, c) => {
      acc[c.id] = c.summary;
      return acc;
    },
    {} as Record<string, CompanySummary>,
  );

  const shouldSetMembershipContext = !query.isLoading && query.data;
  const shouldSetFromSessionStorage = sessionStorageContext && !currentCompany;

  useEffect(() => {
    if (shouldSetFromSessionStorage) {
      setCurrentCompany(sessionStorageContext);
    } else if (shouldSetMembershipContext) {
      setCurrentCompany(query.data?.companies[0]);
      setSessionStorageContext(query.data?.companies[0]);
    }
  }, [shouldSetFromSessionStorage, shouldSetMembershipContext]);

  const handleChangeCompany = (company: CompanySummaryContext) => {
    setCurrentCompany(company);
    setSessionStorageContext(company);
  };

  return {
    ...query,
    companies,
    companiesMap,
    currentCompany,
    setCurrentCompany: handleChangeCompany,
    refetch: query.refetch,
  };
};
