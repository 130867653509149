import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';

import { Cancel } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseOutlined';
import {
  Box,
  ClickAwayListener,
  IconButton,
  Popper,
  Skeleton,
  Typography,
} from '@mui/material';

import {
  ContractEntry,
  ContractSummary,
  SearchFilteringInput,
  fetchGetContract,
  fetchSearchAllContracts,
} from '@octopus/api';
import { SearchBar, SearchBarProps, SearchProps } from '@octopus/ui/data-grid';

import { AppContext } from '../../../app/context';
import { DataFetching, FetchResult } from '../../dataFetching';
import { useSnackbar } from '../../hooks/useSnackbar';
import UserAvatar from '../UserAvatar';

export type MultipleContractsSelectorProps = {
  organizationId: string;
  selectedContracts: ContractEntry[];
  setSelectedContracts: (contracts: ContractEntry[]) => void;
  searchFilters?: SearchFilteringInput;
  searchBarProps?: Omit<SearchBarProps, 'searchProps' | 'onFocus' | 'onClose'>;
  error?: boolean;
};

export function MultipleContractsSelector({
  organizationId,
  selectedContracts = [],
  setSelectedContracts,
  searchFilters,
  searchBarProps,
  error,
}: MultipleContractsSelectorProps) {
  const [search, setSearch] = useState('');
  const hasSearchedHook = useState(false);

  return (
    <Box px={0.75} display="flex" flexDirection="column" width="100%" gap={1}>
      {selectedContracts.length > 0 && (
        <Box gap={1} display={'flex'} flexDirection={'column'}>
          {selectedContracts.map((contract) => (
            <ActiveContract
              key={contract.contractId}
              contract={contract}
              error={error}
              clearContract={() => {
                setSelectedContracts(
                  selectedContracts.filter(
                    (c) => c.contractId !== contract.contractId,
                  ),
                );
              }}
            />
          ))}
        </Box>
      )}
      <SearchContract
        search={{
          searchTerm: search,
          setSearchTerm: debounce(setSearch, 300),
        }}
        organizationId={organizationId}
        setActiveContract={(contract) => {
          if (
            !selectedContracts.find((c) => c.contractId === contract.contractId)
          ) {
            setSelectedContracts([...selectedContracts, contract]);
          }
        }}
        hasSearchedHook={hasSearchedHook}
        searchFilters={searchFilters}
        searchBarProps={searchBarProps}
      />
    </Box>
  );
}

function SearchContract({
  organizationId,
  setActiveContract,
  search,
  hasSearchedHook,
  searchBarProps,
  searchFilters,
}: {
  organizationId: string;
  search: SearchProps;
  setActiveContract: (contract: ContractEntry) => void;
  hasSearchedHook: [boolean, (hasSearched: boolean) => void];
  searchBarProps?: Omit<SearchBarProps, 'searchProps' | 'onFocus' | 'onClose'>;
  searchFilters?: SearchFilteringInput;
}) {
  const searchFieldRef = useRef(null);
  const { showSnackbar } = useSnackbar();

  const [isLoadingContract, setIsLoadingContract] = useState(false);
  const [showContractSearch, setShowContractSearch] = useState<boolean>(
    search.searchTerm.length > 0,
  );
  const [hasSearched, setHasSearched] = hasSearchedHook;
  const [activeContractId, setActiveContractId] = React.useState<
    string | undefined
  >();

  const { searchTerm } = search;

  useEffect(() => {
    if (activeContractId) {
      setIsLoadingContract(true);
      fetchGetContract({
        pathParams: {
          organizationId,
          contractId: activeContractId,
        },
      })
        .then((contract) => {
          setActiveContract(contract);
        })
        .catch((err) => {
          console.error(
            'Error loading contract on MultipleContractsSelector',
            err,
          );
          showSnackbar({
            isOpen: true,
            variant: 'error',
            Message:
              'Ocorreu um erro. Por favor tente novamente ou contacte o suporte da Tako.',
            StartAdornment: <Cancel />,
            autoHideDuration: 3000,
            hasCloseAction: true,
          });
        })
        .finally(() => {
          setIsLoadingContract(false);
          setActiveContractId(undefined);
          setShowContractSearch(false);
        });
    }
  }, [activeContractId]);

  const useFetch = () => {
    return useQuery({
      queryKey: [
        'searchAllContracts',
        organizationId,
        JSON.stringify(searchFilters),
        searchTerm,
      ],
      queryFn: async () => {
        const contractors = await fetchSearchAllContracts({
          pathParams: {
            organizationId,
          },
          body: {
            query: searchTerm.length > 0 ? searchTerm : undefined,
            pagination: {
              size: 30,
              page: 0,
            },
            sorting: [
              {
                field: 'name',
                order: 'asc',
              },
            ],
            filtering: searchFilters,
          },
        });
        setHasSearched(true);
        return contractors.data ?? [];
      },
    });
  };

  if (isLoadingContract) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap="8px"
        width="100%"
        bgcolor="background.paper"
        border="1px solid #EDEDED"
        borderRadius={1}
      >
        <Skeleton variant="rounded" height={40} width="100%" />
      </Box>
    );
  }

  return (
    <ClickAwayListener onClickAway={() => setShowContractSearch(false)}>
      <Box ref={searchFieldRef}>
        <SearchBar
          {...(searchBarProps ?? {})}
          searchProps={search}
          onFocus={() => setShowContractSearch(true)}
          onClose={() => setShowContractSearch(false)}
          sx={{
            width: '100%',
            ...(searchBarProps?.sx ?? {}),
          }}
        />
        <ContractSearch
          searchFieldRef={searchFieldRef}
          hasSearched={hasSearched}
          useFetch={useFetch}
          setActiveContractId={setActiveContractId}
          show={showContractSearch}
        />
      </Box>
    </ClickAwayListener>
  );
}

function ActiveContract({
  contract,
  error,
  clearContract,
}: {
  contract: ContractEntry;
  error?: boolean;
  clearContract: () => void;
}) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      gap={1}
      py={0.25}
      borderRadius={1}
      bgcolor="background.paper"
      alignItems="center"
      border="1px solid"
      borderColor={error ? 'red' : 'strokes.light'}
      boxSizing="border-box"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        overflow="hidden"
      >
        <UserAvatar
          name={contract.br.pessoa.nmTrab}
          pictureUrl={contract.profilePicture?.pictureUrl}
          showFullName={true}
          avatarTextProps={{
            fontSize: '10px',
            lineHeight: '16px',
            color: 'text.primary',
            fontWeight: '500',
          }}
        />
      </Box>
      <Box
        onClick={clearContract}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        px={1}
      >
        <IconButton>
          <CloseRoundedIcon
            color="action"
            sx={{ ...(error ? { color: 'red' } : {}) }}
          />
        </IconButton>
      </Box>
    </Box>
  );
}

function ContractSearch({
  searchFieldRef,
  useFetch,
  hasSearched,
  setActiveContractId,
  show,
}: {
  searchFieldRef: RefObject<Element>;
  useFetch: () => FetchResult<ContractSummary[]>;
  hasSearched: boolean;
  setActiveContractId: (contractId: string) => void;
  show: boolean;
}) {
  const { appContext } = useContext(AppContext);
  return (
    <Popper
      id="ContractSearchPopper"
      disablePortal={true}
      open={show}
      anchorEl={searchFieldRef.current}
      placement="bottom-start"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
      ]}
      sx={{
        zIndex: 9999,
        minHeight: '180px',
      }}
    >
      <DataFetching<ContractSummary[]>
        useHook={useFetch}
        Loading={() => (
          <Box
            pt={1}
            width={searchFieldRef.current?.clientWidth}
            bgcolor={'background.paper'}
          >
            <Skeleton variant="rounded" height={180} width="100%" />
          </Box>
        )}
        Data={({ data: response }) => {
          return (
            <Box
              borderRadius={1}
              border="1px solid #EDEDED"
              boxShadow="0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)"
              maxHeight={300}
              sx={{ overflowY: 'auto' }}
              minWidth={searchFieldRef.current?.clientWidth}
              bgcolor="background.paper"
            >
              {response &&
                response.map((summary) => (
                  <Box
                    key={summary.contractId}
                    display="flex"
                    flexDirection="row"
                    gap={1.5}
                    py={1.25}
                    px={1}
                    alignItems="center"
                    sx={(theme) => ({
                      '&:hover': {
                        backgroundColor: theme.palette.strokes.secondary,
                        cursor: 'pointer',
                      },
                      zIndex: 99999,
                    })}
                    onClick={() => setActiveContractId(summary.contractId)}
                  >
                    <UserAvatar
                      name={summary.name}
                      pictureUrl={summary.pictureUrl}
                      showFullName={false}
                    />
                    <Box display="flex" flexDirection="column" pr={2}>
                      <Box>
                        <Typography variant="body2">{summary.name}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption">
                          {summary.titleName}{' '}
                          {summary.departmentId &&
                            `| ${
                              appContext?.company?.departments?.find(
                                (d) => d.id === summary.departmentId,
                              )?.name
                            }`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              {!response ||
                (hasSearched && response.length === 0 && (
                  <Box px={1} py={1} mb={0.5} ml={0.5}>
                    <Typography
                      variant="caption"
                      fontSize="12px"
                      color="text.secondary"
                      fontWeight={700}
                    >
                      Nenhum resultado encontrado.
                    </Typography>
                  </Box>
                ))}
            </Box>
          );
        }}
      />
    </Popper>
  );
}
