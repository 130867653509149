import { useContext } from 'react';

import { AppContext } from '../../app/context';
import { LegalEntityContext } from '../types';

export function useLegalEntity(
  legalEntityId: string | undefined,
): LegalEntityContext | undefined {
  const { appContext } = useContext(AppContext);
  if (!legalEntityId) {
    return undefined;
  }
  return appContext?.company?.legalEntities?.find(
    (legalEntity) => legalEntity.id === legalEntityId,
  );
}
