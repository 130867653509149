import { useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import {
  ContractBRCltDependent,
  useUploadContractDocument,
} from '@octopus/api';
import { translate } from '@octopus/i18n';
import { Tag } from '@octopus/ui/design-system';

import { FileComponent } from '../../../../modules/components/file';
import { SnackbarType } from '../../../../modules/hooks/snackbarContext';
import { RequiredTag } from '../../../admission/components/RequiredTag';

import { ALLOWED_CONTENT_TYPES } from './types';
import {
  getContentType,
  getErrorSnackbar,
  getIdentifierFromCPF,
  getSuccessSnackbar,
} from './utils';

const ACCEPTED_FILE_TYPES = Object.entries(ALLOWED_CONTENT_TYPES).reduce(
  (acc, [ext, type]) => {
    acc[type] = acc[type] || [];
    acc[type].push(ext);
    return acc;
  },
  {} as Record<string, string[]>,
);

const getDependentIdentifier = (dependente: ContractBRCltDependent) =>
  dependente.id ?? getIdentifierFromCPF(dependente.cpfDep);

export function UploadDocumentModal({
  open,
  onClose,
  organizationId,
  contractId,
  dependentes,
  workerName,
  onSuccess,
  showSnackbar,
}: {
  open: boolean;
  onClose: () => void;
  organizationId: string;
  contractId: string;
  dependentes?: ContractBRCltDependent[];
  workerName: string;
  onSuccess: () => void;
  showSnackbar: (snackbar: SnackbarType) => void;
}) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [documentName, setDocumentName] = useState<string>('');
  const [dependentId, setDependentId] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const uploadDocumentMutation = useUploadContractDocument({
    onSuccess: async (response) => {
      if (selectedFile && response.uploadUrl) {
        try {
          await fetch(response.uploadUrl, {
            method: 'PUT',
            body: selectedFile,
            headers: {
              'Content-Type': selectedFile.type,
            },
          });
          await new Promise((resolve) => setTimeout(resolve, 4000));
          onSuccess();
          onClose();
        } catch (error) {
          console.error('Erro ao fazer upload do arquivo:', error);
        }
      }
    },
    onError: (error: { status: number | 'unknown'; payload: string }) => {
      console.error('Erro ao criar documento:', error);
    },
  });

  const handleFileChange = (file: File) => {
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    if (!selectedFile) return;

    setIsLoading(true);

    try {
      await uploadDocumentMutation.mutateAsync({
        pathParams: {
          organizationId,
          contractId,
        },
        body: {
          title: documentName,
          type: 'outro',
          contentType: getContentType(selectedFile),
          contentLength: selectedFile.size,
          dependentId:
            dependentId && dependentId !== 'colaborador' ? dependentId : null,
          fileName: selectedFile.name,
        },
      });

      showSnackbar(getSuccessSnackbar('Documento adicionado'));
      setSelectedFile(null);
      setDependentId(null);
      setDocumentName('');
    } catch (e) {
      console.error(e);
      showSnackbar(
        getErrorSnackbar(
          e.message?.startsWith('CTR')
            ? translate(e.message, 'pt')
            : 'Ocorreu um erro ao adicionar o documento',
        ),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderSelectedValue = (selected: string) => {
    if (selected === null) {
      return 'Selecione a pessoa';
    }

    if (selected === 'colaborador') {
      return 'Colaborador';
    }

    return dependentes?.find((dep) => getDependentIdentifier(dep) === selected)
      ?.nmDep;
  };

  const handleClose = () => {
    onClose();
    setSelectedFile(null);
    setDependentId(null);
    setDocumentName('');
  };

  const renderDependentMenuItem = (dependente: ContractBRCltDependent) => {
    const identifier = getDependentIdentifier(dependente);
    return (
      <MenuItem
        key={identifier}
        value={identifier}
        selected={dependentId === identifier}
      >
        <Box display="flex" alignItems="center" gap={1.5}>
          {dependente.nmDep}
          <Tag color="info">Dependente</Tag>
        </Box>
      </MenuItem>
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="h2" fontWeight={700}>
            Adicionar documento
          </Typography>

          <Box>
            <Typography variant="caption" sx={{ display: 'flex', mb: 1 }}>
              Nome do documento
              <RequiredTag required={true} />
            </Typography>
            <TextField
              id="documentName"
              fullWidth
              placeholder="Ex. Comprovante de endereço, RG ou Diploma"
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
              required
            />
          </Box>

          {dependentes && dependentes.length > 0 && (
            <Box>
              <Typography variant="caption" sx={{ display: 'flex', mb: 1 }}>
                Relativo ao
                <RequiredTag required={true} />
              </Typography>
              <Select
                fullWidth
                value={dependentId}
                onChange={(e) => setDependentId(e.target.value)}
                renderValue={renderSelectedValue}
              >
                <MenuItem key="colaborador" value="colaborador">
                  <Box display="flex" alignItems="center" gap={1.5}>
                    {workerName}
                    <Tag color="default">Colaborador</Tag>
                  </Box>
                </MenuItem>
                {dependentes.map(renderDependentMenuItem)}
              </Select>
            </Box>
          )}
          <FileComponent
            description="São aceitos arquivos com tamanho máximo de 10MB."
            disabled={
              isLoading ||
              !documentName ||
              (dependentes?.length > 0 && !dependentId)
            }
            disabledMessage={
              !documentName
                ? 'Por favor digite o nome do documento no campo acima'
                : !dependentId && dependentes?.length > 0
                  ? 'Por favor selecione o Colaborador ou Dependente'
                  : isLoading
                    ? 'Por favor aguarde...'
                    : 'Nenhum documento pode ser enviado nesse momento'
            }
            setFile={handleFileChange}
            showDeleteButton={true}
            isLoading={isLoading}
            file={selectedFile}
            filePreviewType="list"
            acceptedFileTypes={ACCEPTED_FILE_TYPES}
            hideChevron={true}
            maxFileSize={10000000}
            showDeleteConfirmation={false}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="secondary"
          size="large"
          disabled={isLoading}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primaryAlt"
          size="large"
          disabled={!selectedFile || isLoading}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
