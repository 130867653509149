import { useEffect, useState } from 'react';

import { Img } from '@react-email/components';

import { Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Divider,
  FormControl,
  Paper,
  Typography,
} from '@mui/material';

import {
  OrganizationEntry,
  OrganizationPublicAssetsEntry,
  fetchGetOrganizationEntry,
  fetchHandlePostPublicAssets,
  fetchPutOrganizationEntry,
} from '@octopus/api';

import { FileComponent } from '../../../modules/components/file';
import { uploadFile } from '../../../modules/components/file/upload';
import { SnackbarType } from '../../../modules/hooks/snackbarContext';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';

import { AdmissionPreferences, AdmissionPreferencesType } from './admission';

type ImagePreferencePayload = {
  defaultValue: string;
};
type Preference = {
  type: 'image' | 'admission';
  id: string;
  title: string;
  subtitle: string;
  parent: 'brPreferences' | 'paymentConfig';
} & ImagePreferencePayload;

export const orgPreferences = {
  organizationLogo: {
    type: 'image',
    id: 'organizationLogo',
    title: 'Logo da organização',
    subtitle:
      'Esse logo aparecerá para os colaboradores nos emails enviados pela Tako',
    parent: 'brPreferences',
    defaultValue: '',
  },
  admission: {
    type: 'admission',
    id: 'admission',
    title: 'Admissão',
    subtitle: 'Personalização dos campos do formulário de admissão',
    parent: 'brPreferences',
    defaultValue: '',
  },
} as const satisfies Record<string, Preference>;

type PreferenceKey = keyof typeof orgPreferences;

type PreferenceValues<Key> = Key extends PreferenceKey
  ? (typeof orgPreferences)[Key] extends ImagePreferencePayload
    ? string
    : never
  : never;

export function OrgPreferences({ organizationId }: { organizationId: string }) {
  const [response, setResponse] = useState('');
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [organization, setOrganization] = useState<OrganizationEntry>({
    name: 'loading org',
    organizationId: '',
    active: false,
    createdOn: undefined,
    createdBy: '',
  });
  const [file, setFile] = useState(undefined as File);
  const [admissionPreferences, setAdmissionPreferences] =
    useState<AdmissionPreferencesType>();
  const { showSnackbar } = useSnackbar();

  const [preferenceOptions, setPreferenceOptions] = useState(
    Object.keys(orgPreferences).reduce(
      (acc, key) => ({
        ...acc,
        [key]: orgPreferences[key as PreferenceKey].defaultValue,
      }),
      {} as { [key in PreferenceKey]: PreferenceValues<key> },
    ),
  );

  useEffect(() => {
    fetchGetOrganizationEntry({
      pathParams: {
        organizationId: organizationId,
      },
    }).then((organizationEntry) => {
      setOrganization(organizationEntry);
      setPreferenceOption('organizationLogo', organizationEntry.logoUrl);
      setAdmissionPreferences(organizationEntry?.settings?.admission);
    });
  }, [organizationId, file, response]);

  const setPreferenceOption = <Key extends PreferenceKey>(
    key: Key,
    value: PreferenceValues<Key>,
  ) => {
    setPreferenceOptions({
      ...preferenceOptions,
      [key]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    await submitPreferences({
      organization,
      file,
      admissionPreferences,
      setOrganization,
      setAdmissionPreferences,
      setResponse,
      showSnackbar,
    });
    setIsLoading(false);
  };

  return (
    <Paper elevation={1} sx={{ backgroundColor: 'white' }}>
      <Box py={4} px={4}>
        <Typography variant="h2">
          Configurações da Organização - {organization.name}
        </Typography>
        <Divider sx={{ my: 2 }} />

        {error && (
          <Box pb={2}>
            <Alert severity="error" onClose={() => setError('')}>
              {error}
            </Alert>
          </Box>
        )}

        {response && (
          <Box pb={2}>
            <Alert onClose={() => setResponse('')}>{response}</Alert>
          </Box>
        )}

        {warning && (
          <Box pb={2}>
            <Alert severity="warning" onClose={() => setWarning('')}>
              {warning}
            </Alert>
          </Box>
        )}

        <Box display="flex" flexDirection="column" gap={2}>
          {Object.values(orgPreferences).map((preference) => {
            const form = (() => {
              switch (preference.type) {
                case 'image': {
                  return (
                    <Box display="flex" flexDirection="row" gap={1.5}>
                      <Img
                        src={preferenceOptions[preference.id]}
                        width="200"
                        height="250"
                      />
                      <FileComponent setFile={setFile} file={file} />
                    </Box>
                  );
                }
                case 'admission': {
                  return (
                    <AdmissionPreferences
                      admissionPreferences={admissionPreferences}
                      setAdmissionPreferences={setAdmissionPreferences}
                    />
                  );
                }
                default: {
                  return null;
                }
              }
            })();

            return (
              <Box
                display="flex"
                flexDirection="column"
                gap={2.5}
                py={1.5}
                key={preference.id}
              >
                <Box display="flex" flexDirection="column" gap={1}>
                  <Typography variant="h3">{preference.title}</Typography>
                  <Typography variant="body1">{preference.subtitle}</Typography>
                </Box>
                <Box display="flex" gap={1}>
                  <FormControl
                    size={undefined}
                    sx={{ height: '100%', width: '100%' }}
                  >
                    {form}
                  </FormControl>
                </Box>
                <Divider sx={{ mt: 2 }} />
              </Box>
            );
          })}
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <LoadingButton
            loading={isLoading}
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Salvar configurações
          </LoadingButton>
        </Box>
      </Box>
    </Paper>
  );
}

async function submitPreferences({
  organization,
  file,
  admissionPreferences,
  setResponse,
  setOrganization,
  setAdmissionPreferences,
  showSnackbar,
}: {
  organization: OrganizationEntry;
  file: File;
  admissionPreferences: AdmissionPreferencesType;
  setOrganization: (organization: OrganizationEntry) => void;
  setAdmissionPreferences: (
    admissionPreferences: AdmissionPreferencesType,
  ) => void;
  setResponse: (resp: string) => void;
  showSnackbar: (snackbar: SnackbarType) => void;
}) {
  const { organizationId, settings, logo, logoUrl, name } = organization;
  if (file) {
    type allowedContentType = 'image/jpeg' | 'image/jpg' | 'image/png';
    await fetchHandlePostPublicAssets({
      pathParams: {
        organizationId,
      },
      body: {
        type: 'logo',
        fileName: file.name,
        contentType: file.type as allowedContentType,
        contentLength: file.size,
      },
    }).then((response: OrganizationPublicAssetsEntry) => {
      uploadFile(response.uploadUrl, file).then(() =>
        setResponse('Arquivo atualizado com sucesso!'),
      );
    });
  }

  if (admissionPreferences) {
    await fetchPutOrganizationEntry({
      pathParams: {
        organizationId,
      },
      body: {
        name,
        logo,
        logoUrl,
        settings: {
          ...settings,
          admission: admissionPreferences,
        },
      },
    }).then((organizationEntry) => {
      setOrganization(organizationEntry);
      setAdmissionPreferences(organizationEntry?.settings?.admission);
      showSnackbar({
        isOpen: true,
        Message: 'Preferencias atualizadas!',
        StartAdornment: <Cancel />,
        autoHideDuration: 5000,
        hasCloseAction: true,
      });
    });
  }
}
