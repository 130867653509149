import React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Box, Input } from '@mui/material';

import { credentialsProvider } from '../../credentialsProvider';

interface UploadSpreadsheetProps {
  organizationId: string;
}

export default function UploadSpreadsheet({
  organizationId,
}: UploadSpreadsheetProps) {
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState<File | undefined>();
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleClick = async () => {
    if (!file) {
      return;
    }

    setLoading(true);
    setSuccess(false);
    setError(false);

    const body = new FormData();
    body.append('spreadsheet', file);

    const credentials = await credentialsProvider.getCredentials();

    window
      .fetch(`/api/organizations/${organizationId}/spreadsheets`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${credentials}`,
        },
        body,
      })
      .then((response) => {
        setLoading(false);
        setFile(undefined);
        if (response.ok) {
          setSuccess(true);
          setError(false);
        } else {
          setSuccess(false);
          setError(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setSuccess(false);
        setError(true);
        setFile(undefined);
      });
  };

  return (
    <Box>
      {success && (
        <Box pt={2} pb={4}>
          <Alert severity="success">
            Planilha enviada com sucesso, aguarde alguns minutos para que ela
            seja processada.
          </Alert>
        </Box>
      )}

      {error && (
        <Box pt={2} pb={4}>
          <Alert severity="error">
            Ocorreu um erro ao enviar a planilha, tente novamente ou se o erro
            persistir, entre em contato com nosso suporte.
          </Alert>
        </Box>
      )}

      {file && loading && (
        <Box pt={2} pb={4}>
          <Alert severity="info">Enviando a planilha, aguarde...</Alert>
        </Box>
      )}

      <Box pt={2} pb={4}>
        <Input type="file" onChange={handleFileChange} />

        <LoadingButton
          onClick={handleClick}
          loading={loading}
          variant="contained"
        >
          <span>Upload</span>
        </LoadingButton>
      </Box>
    </Box>
  );
}
