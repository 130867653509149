import ExcelJS from 'exceljs';

import { WorksheetRange } from '../types';

export function autoResizeColumns(
  worksheet: ExcelJS.Worksheet,
  { from, to }: WorksheetRange,
  padding = 4,
  minWidth = 12,
  maxWidth = 32,
) {
  for (let colNum = from.column; colNum <= to.column; colNum++) {
    let size = minWidth;
    const column = worksheet.getColumn(colNum);
    for (let rowNum = from.row; rowNum <= to.row; rowNum++) {
      const cell = worksheet.getCell(rowNum, colNum);
      const width = calculateWidth(cell, padding, minWidth, maxWidth);
      if (width > size) {
        size = width;
      }
    }
    column.width = size;
  }
}

function calculateWidth(
  cell: ExcelJS.Cell,
  padding: number,
  minWidth: number,
  maxWidth: number,
) {
  let length = getCellLength(cell);
  if (length < padding * 4) {
    length += padding;
  }
  if (length <= minWidth) {
    return minWidth;
  }
  if (length > maxWidth) {
    return maxWidth;
  }
  return length;
}

function getCellLength(cell: ExcelJS.Cell) {
  if (cell.type === 4) {
    return 10;
  }
  if (cell.type === 2) {
    if (cell.style.numFmt !== undefined) {
      return (cell?.text?.length || 0) + 4;
    }
  }
  return cell?.text?.length || 0;
}
