import { v4 } from 'uuid';

import {
  AdmissionDraftInput,
  AdmissionDraftInputFormInfo,
  AdmissionDraftInputFormSectionEnum,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftInputFormSteps,
  AdmissionDraftUpdateInput,
  AdmissionType,
} from '@octopus/api';
import { getContractTypeFromWorkerCategory } from '@octopus/esocial/contracts';

import { getEffectiveDate } from './inputUtils';
import {
  getCreateInputFromFormState,
  getUpdateInputFromFormState,
} from './mappers';
import {
  AdmissionFormState,
  AdmissionFormSteps,
  FormStepOptions,
} from './types';

const getInputStepsFromFormSteps = ({
  previousFormSteps,
  formSteps,
  section,
  currentStepName,
  isAdminStep,
  reviewMode,
  currentStepCompleted = true,
}: {
  previousFormSteps: AdmissionDraftInputFormSteps;
  formSteps: AdmissionFormSteps;
  section: AdmissionDraftInputFormSectionEnum;
  currentStepName: AdmissionDraftInputFormStepEnum;
  isAdminStep: boolean;
  reviewMode: boolean;
  currentStepCompleted?: boolean;
}): AdmissionDraftInputFormSteps => {
  const data: AdmissionDraftInputFormSteps = { ...previousFormSteps };

  Object.keys(formSteps).forEach((key) => {
    const started =
      key === currentStepName || previousFormSteps[key]?.started || false;

    const completed =
      key === currentStepName
        ? currentStepCompleted
        : previousFormSteps[key]?.completed || false;

    const reviewed = reviewMode
      ? isAdminStep ||
        key === currentStepName ||
        previousFormSteps[key]?.reviewed
      : isAdminStep && completed;

    data[key] = {
      section,
      step: key as AdmissionDraftInputFormStepEnum,
      started,
      completed,
      reviewed,
    };
  });
  return data;
};

const getStartingStep = ({
  steps,
  reviewMode,
  countAdminSteps,
  finishReview,
}: {
  steps?: {
    name: string;
    isFirst: boolean;
    data: any;
  }[];
  reviewMode: boolean;
  countAdminSteps: number;
  finishReview: boolean;
}): string => {
  if (!steps) return null;

  if (reviewMode && finishReview) {
    const lastStep = steps[steps.length - 1];
    return lastStep.name;
  }

  const nextStep =
    steps.find((step, index) => {
      const stepOptions = step?.data?.options as FormStepOptions;
      if (step.isFirst) {
        return false;
      }

      const isAdminStep = index < countAdminSteps;
      const needsReview = reviewMode && !stepOptions?.reviewed;

      return isAdminStep ? !stepOptions?.completed : needsReview;
    }) ?? steps[steps.length - 1];

  return nextStep?.name;
};

const getFormInfo = ({
  previousFormInfo,
  adminFormSteps,
  workerFormSteps,
  currentStepName,
  reviewMode,
  currentStepCompleted,
}: {
  previousFormInfo: AdmissionDraftInputFormInfo;
  adminFormSteps: AdmissionFormSteps;
  workerFormSteps: AdmissionFormSteps;
  currentStepName: AdmissionDraftInputFormStepEnum;
  reviewMode: boolean;
  currentStepCompleted?: boolean;
}): AdmissionDraftInputFormInfo => {
  const payroll_data = getInputStepsFromFormSteps({
    previousFormSteps: previousFormInfo?.formSections?.['payroll_data'] || {},
    formSteps: adminFormSteps,
    section: 'payroll_data',
    currentStepName,
    isAdminStep: true,
    reviewMode,
    currentStepCompleted,
  });

  const personal_data = getInputStepsFromFormSteps({
    previousFormSteps: previousFormInfo?.formSections?.['personal_data'] || {},
    formSteps: workerFormSteps,
    section: 'personal_data',
    currentStepName,
    isAdminStep: false,
    reviewMode,
  });

  return {
    formSections: {
      payroll_data,
      personal_data,
    },
  };
};

const getCreateBody = ({
  previousFormInfo,
  formState,
  adminFormSteps,
  workerFormSteps,
  currentStepName,
}: {
    previousFormInfo: AdmissionDraftInputFormInfo;
  formState: AdmissionFormState;
  adminFormSteps: AdmissionFormSteps;
  workerFormSteps: AdmissionFormSteps;
  currentStepName: AdmissionDraftInputFormStepEnum;
}): AdmissionDraftInput => {
  const formInfo = getFormInfo({
    previousFormInfo: previousFormInfo,
    adminFormSteps,
    workerFormSteps,
    currentStepName,
    reviewMode: false,
  });

  const createInput = getCreateInputFromFormState({ formState });
  return {
    contractType: getContractTypeFromWorkerCategory(formState.workerCategory),
    admissionType: formState.admissionType as AdmissionType,
    effectiveStartDate: getEffectiveDate(),
    formInfo,
    ...createInput,
  };
};

const getUpdateBody = ({
  previousFormInfo,
  formState,
  adminFormSteps,
  workerFormSteps,
  currentStepName,
  reviewMode,
  currentStepCompleted,
}: {
  previousFormInfo: AdmissionDraftInputFormInfo;
  formState: AdmissionFormState;
  adminFormSteps: AdmissionFormSteps;
  workerFormSteps: AdmissionFormSteps;
  currentStepName: AdmissionDraftInputFormStepEnum;
  reviewMode: boolean;
  currentStepCompleted?: boolean;
}): AdmissionDraftUpdateInput => {
  const formInfo = getFormInfo({
    previousFormInfo,
    adminFormSteps,
    workerFormSteps,
    currentStepName,
    reviewMode,
    currentStepCompleted,
  });

  const updateInput = getUpdateInputFromFormState({
    formState,
    currentStepName,
  });

  return {
    contractType: getContractTypeFromWorkerCategory(formState.workerCategory),
    effectiveStartDate: getEffectiveDate(),
    formInfo,
    ...updateInput,
  };
};

const getHeaders = () => {
  const idempotencyKey = v4();
  return {
    'x-idempotency-key': idempotencyKey,
  };
};

export { getCreateBody, getStartingStep, getUpdateBody, getHeaders };
