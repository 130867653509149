import { z } from 'zod';

import { nonEmptyString } from '@octopus/libs/forms';

import {
  CompanyContext,
  CompanySummaryContext,
} from '../../../../../modules/types';

const getCompanyIdsSchema = (companies: CompanySummaryContext[]) =>
  companies.reduce(
    (acc, company) => {
      return { ...acc, [`companyId_${company.id}`]: z.string().optional() };
    },
    {} as Record<string, z.ZodType>,
  );

export const getCompanySchema = (
  companies: CompanySummaryContext[],
  fullCompanyContext?: CompanyContext,
) =>
  z
    .object({
      ...getCompanyIdsSchema(companies),
      legalEntity: nonEmptyString.optional(),
    })
    .superRefine((formState, ctx) => {
      const [companyId, legalEntity] = Object.values(
        formState || {},
      ) as string[];

      if (companyId == null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Obrigatório',
          path: ['companyId'],
        });
      }

      if (legalEntity == null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Obrigatório',
          path: ['legalEntity'],
        });
      }

      if (companyId && legalEntity && fullCompanyContext) {
        const isLegalEntityValid = fullCompanyContext.legalEntities.some(
          (le) =>
            (le.id === legalEntity ||
              le.summary.legalEntityId === legalEntity) &&
            le.summary.companyId === companyId,
        );

        if (!isLegalEntityValid) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Local de serviço inválido para esta empresa',
            path: ['legalEntity'],
          });
        }
      }
    });
