import { useMaskito } from '@maskito/react';

import { Typography } from '@mui/material';

import {
  ContractBRCltEntryRegime,
  ContractBRCltEntryTrabalho,
  ContractBRCltEntryVinculo,
} from '@octopus/api';
import { WorkerCategory } from '@octopus/contract-types';
import {
  Categorias,
  IndicativoAdmissao,
  NaturezaAtividade,
  TipoAdmissao,
  TipoRegimeJornada,
  TipoRegimePrevidenciario,
  TipoRegimeTrabalho,
} from '@octopus/esocial/mapper';
import { formatDateBR } from '@octopus/formatters';

import { Record, RecordEntry, mapperToOptions } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type AdmissaoERegimeDeTrabalhoRecordData = {
  vinculo: ContractBRCltEntryVinculo;
  trabalho: ContractBRCltEntryTrabalho;
  regime: ContractBRCltEntryRegime;
  workerCategory: WorkerCategory;
};

export type AdmissaoERegimeDeTrabalhoRecordProps =
  BaseRecordProps<AdmissaoERegimeDeTrabalhoRecordData>;

export function AdmissaoERegimeDeTrabalhoRecord(
  props: AdmissaoERegimeDeTrabalhoRecordProps,
) {
  const {
    data: { vinculo, trabalho, regime, workerCategory },
  } = props;

  const editProps: AdmissaoERegimeDeTrabalhoRecordProps = {
    ...props,
    edit: {
      ...props.edit,
      onSave: (data, onSuccess, onError) => {
        const {
          update: { workerCategory: _, ...update },
        } = data;

        props.edit.onSave(
          { update: update as AdmissaoERegimeDeTrabalhoRecordData },
          onSuccess,
          onError,
        );
      },
    },
  };

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit<AdmissaoERegimeDeTrabalhoRecordData>(editProps);

  const prepareOnChange = (
    group: 'vinculo' | 'trabalho' | 'regime',
    key: string,
    formatter: (val: string) => string | number = (val) => val,
  ) => {
    return (value: string) =>
      updateData((data) => ({
        ...data,
        [group]: { ...data[group], [key]: formatter(value) },
      }));
  };

  const procTrabMask = useMaskito({
    options: {
      mask: new Array(20).fill(/\d/),
    },
  });

  return (
    <Record title="Admissão e regime de trabalho" edit={editRecordProps}>
      <RecordEntry
        label="Data de admissão"
        edit={{
          editing,
          type: 'date',
          valueFormat: 'YYYY-MM-DD',
          value: formData.regime.dtAdm,
          onChange: prepareOnChange('regime', 'dtAdm'),
          hasError: hasError('br/regime/dtAdm'),
        }}
      >
        {formatDateBR(regime.dtAdm)}
      </RecordEntry>
      <RecordEntry
        label="Tipo de regime trabalhista"
        edit={{
          editing,
          type: 'options',
          onChange: prepareOnChange('vinculo', 'tpRegTrab', parseInt),
          value: formData.vinculo.tpRegTrab,
          options: mapperToOptions({ mapper: TipoRegimeTrabalho }),
          hasError: hasError('br/vinculo/tpRegTrab'),
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          sx={(theme) => ({
            bgcolor: theme.palette.background.default,
            px: 1,
            py: 0.5,
            borderRadius: 1,
          })}
        >
          {vinculo.tpRegTrab === 1 ? 'CLT' : 'Estatutário'}
        </Typography>
      </RecordEntry>
      <RecordEntry
        label="Tipo de admissão"
        edit={{
          editing,
          type: 'options',
          onChange: prepareOnChange('regime', 'tpAdmissao', parseInt),
          value: formData.regime.tpAdmissao,
          options: mapperToOptions({ mapper: TipoAdmissao }),
          hasError: hasError('br/regime/tpAdmissao'),
        }}
      >
        {TipoAdmissao.getByCode(regime.tpAdmissao)}
      </RecordEntry>
      <RecordEntry
        label="Indicativo de admissão"
        edit={{
          editing,
          type: 'options',
          onChange: prepareOnChange('regime', 'indAdmissao', parseInt),
          value: formData.regime.indAdmissao,
          options: mapperToOptions({ mapper: IndicativoAdmissao }),
          hasError: hasError('br/regime/indAdmissao'),
        }}
      >
        {IndicativoAdmissao.getByCode(regime.indAdmissao)}
      </RecordEntry>
      <RecordEntry
        label="Código da categoria"
        edit={{
          editing,
          type: 'options',
          onChange: prepareOnChange('trabalho', 'codCateg', parseInt),
          value: formData.trabalho.codCateg,
          options: mapperToOptions({
            mapper: Categorias,
            filter: (code: string | number) =>
              ['101', '103', '721', '722', '723', '901'].includes(`${code}`),
          }),
          hasError: hasError('br/trabalho/codCateg'),
        }}
      >
        {Categorias.getByCode(trabalho.codCateg)}
      </RecordEntry>
      <RecordEntry
        label="Número do processo trabalhista"
        edit={{
          editing,
          type: 'text',
          onChange: prepareOnChange('regime', 'nrProcTrab'),
          value: formData.regime.nrProcTrab,
          hasError: hasError('br/regime/nrProcTrab'),
          mask: procTrabMask,
        }}
      >
        {regime.nrProcTrab}
      </RecordEntry>
      <RecordEntry
        label="Tipo de regime de jornada"
        edit={{
          editing,
          type: 'options',
          onChange: prepareOnChange('regime', 'tpRegJor', parseInt),
          value: formData.regime.tpRegJor,
          options: mapperToOptions({ mapper: TipoRegimeJornada }),
          hasError: hasError('br/regime/tpRegJor'),
        }}
      >
        {TipoRegimeJornada.getByCode(regime.tpRegJor)}
      </RecordEntry>
      <RecordEntry
        label="Natureza da atividade"
        edit={{
          editing,
          type: 'options',
          onChange: prepareOnChange('regime', 'natAtividade', parseInt),
          value: formData.regime.natAtividade,
          options: mapperToOptions({
            mapper: NaturezaAtividade,
          }),
          hasError: hasError('br/regime/natAtividade'),
        }}
      >
        {NaturezaAtividade.getByCode(regime.natAtividade)}
      </RecordEntry>
      {workerCategory !== 'clt:estagiario' && (
        <RecordEntry
          label="Data de opção do FGTS"
          edit={{
            editing,
            type: 'date',
            valueFormat: 'YYYY-MM-DD',
            value: formData.regime.dtOpcFGTS,
            onChange: prepareOnChange('regime', 'dtOpcFGTS'),
            hasError: hasError('br/regime/dtOpcFGTS'),
          }}
        >
          {regime.dtOpcFGTS ? formatDateBR(regime.dtOpcFGTS) : undefined}
        </RecordEntry>
      )}
      <RecordEntry
        label="Tipo de regime previdenciario"
        edit={{
          editing,
          type: 'options',
          onChange: prepareOnChange('vinculo', 'tpRegPrev', parseInt),
          value: formData.vinculo.tpRegPrev,
          options: mapperToOptions({
            mapper: TipoRegimePrevidenciario,
          }),
          hasError: hasError('br/vinculo/tpRegPrev'),
        }}
      >
        {TipoRegimePrevidenciario.getByCode(vinculo.tpRegPrev)}
      </RecordEntry>
      <RecordEntry
        label="Alvará judicial relativo à contratação de menor de idade"
        edit={{
          editing,
          type: 'text',
          onChange: prepareOnChange('vinculo', 'nrProcJud'),
          value: formData.vinculo.nrProcJud,
          hasError: hasError('br/vinculo/nrProcJud'),
        }}
      >
        {vinculo.nrProcJud}
      </RecordEntry>
    </Record>
  );
}
