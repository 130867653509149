import { NotificationConfiguration } from '@octopus/api';
import { CheckCondition } from '@octopus/libs/conditional-evaluator';

import {
  accrualPeriodsResourceType,
  vacationsScheduleResourceType,
} from './schedule';

const alertWorkerVacationsRejected: Omit<
  NotificationConfiguration,
  'organizationId'
> = {
  resourceType: vacationsScheduleResourceType,
  condition: {
    type: 'check',
    field: 'status',
    value: 'rejected',
    operator: 'eq',
  },
  timings: [
    {
      when: 'immediately',
    },
  ],
  ruleId: 'alertWorkerVacationsRejected',
  description: 'Notifica férias rejeitadas',
  name: 'Férias rejeitadas',
  actions: [
    {
      recipient: {
        type: 'employee',
        template: 'vacationsRejectedWorker',
      },
      type: 'email',
    },
  ],
  tags: ['aprovação'],
};

const alertWorkerVacationsCanceled: Omit<
  NotificationConfiguration,
  'organizationId'
> = {
  resourceType: vacationsScheduleResourceType,
  condition: {
    type: 'check',
    field: 'status',
    value: 'canceled',
    operator: 'eq',
  },
  timings: [
    {
      when: 'immediately',
    },
  ],
  ruleId: 'alertWorkerVacationsCanceled',
  description: 'Notifica férias canceladas',
  name: 'Férias canceladas',
  actions: [
    {
      recipient: {
        type: 'employee',
        template: 'vacationsCanceledWorker',
      },
      type: 'email',
    },
  ],
  tags: ['aprovação'],
};

const alertNewVacations: Omit<NotificationConfiguration, 'organizationId'> = {
  resourceType: vacationsScheduleResourceType,
  condition: {
    type: 'check',
    field: 'status',
    value: 'created',
    operator: 'eq',
  },
  timings: [
    {
      when: 'immediately',
    },
  ],
  ruleId: 'alertNewVacations',
  description: 'Notifica novas férias',
  name: 'Novo pedido de férias',
  actions: [
    {
      recipient: {
        type: 'membership:owner',
        template: 'newVacationsAdmin',
      },
      type: 'email',
    },
    {
      recipient: {
        type: 'membership:owner',
        template: 'newVacationsWorker',
      },
      type: 'email',
    },
  ],
  tags: ['aprovação'],
};

const alertWorkerAccrualPeriodCloseToLimit: Omit<
  NotificationConfiguration,
  'organizationId'
> = {
  resourceType: accrualPeriodsResourceType,
  condition: {
    type: 'check',
    field: 'daysUntilLimitDate',
    operator: 'lte',
    value: '45',
  },
  timings: [
    {
      when: 'before',
      referenceField: 'limitDateToStartVacations',
      seconds: 0,
      days: 45,
    },
    {
      when: 'before',
      referenceField: 'limitDateToStartVacations',
      seconds: 0,
      days: 15,
    },
  ],
  ruleId: 'alertWorkerAccrualPeriodCloseToLimit',
  description:
    'Notifica quando um colaborador está com dias disponíveis de férias em um período aquisitivo que está próximo do fim do período concessivo',
  name: 'Alerta colaborador com férias próximas ao vencimento',
  actions: [
    {
      recipient: {
        type: 'employee',
        template: 'accrualPeriodCloseToLimitDateWorker',
      },
      type: 'email',
    },
  ],
  tags: ['periodoAquisitivo'],
};

const alertAdminAccrualPeriodCloseToLimit: Omit<
  NotificationConfiguration,
  'organizationId'
> = {
  resourceType: accrualPeriodsResourceType,
  condition: {
    type: 'check',
    field: 'daysUntilLimitDate',
    operator: 'lte',
    value: '30',
  },
  timings: [
    {
      when: 'before',
      referenceField: 'limitDateToStartVacations',
      seconds: 0,
      days: 30,
    },
    {
      when: 'before',
      referenceField: 'limitDateToStartVacations',
      seconds: 0,
      days: 15,
    },
  ],
  ruleId: 'alertAdminAccrualPeriodCloseToLimit',
  description:
    'Notifica um admin quando um colaborador está com dias disponíveis de férias em um período aquisitivo que está próximo do fim do período concessivo',
  name: 'Alerta admin sobre férias próximas ao vencimento',
  actions: [
    {
      recipient: {
        type: 'membership:owner',
        template: 'accrualPeriodCloseToLimitDateAdmin',
      },
      type: 'email',
    },
  ],
  tags: ['periodoAquisitivo'],
};

const alertWorkerVacationsApproved: Omit<
  NotificationConfiguration,
  'organizationId'
> = {
  resourceType: vacationsScheduleResourceType,
  condition: {
    type: 'check',
    field: 'status',
    value: 'approved',
    operator: 'eq',
  },
  timings: [
    {
      when: 'immediately',
    },
  ],
  ruleId: 'alertWorkerVacationsApproved',
  description: 'Notifica férias aprovadas',
  name: 'Férias aprovadas',
  actions: [
    {
      recipient: {
        type: 'employee',
        template: 'vacationsApprovedWorker',
      },
      type: 'email',
    },
    {
      recipient: {
        type: 'membership:tako:support',
        template: 'vacationsApprovedAdmin',
      },
      type: 'email',
    },
  ],
  tags: ['aprovação'],
};

const vacationNotice: Omit<NotificationConfiguration, 'organizationId'> = {
  resourceType: vacationsScheduleResourceType,
  condition: {
    type: 'and',
    conditions: [
      {
        type: 'check',
        field: 'status',
        values: ['approved', 'payrollCreated', 'payrollApproved'],
        operator: 'in',
      },
      {
        type: 'check',
        field: 'daysUntilStart',
        maxValue: 30,
        minValue: 5,
        operator: 'between',
      } as CheckCondition,
    ],
  },
  timings: [
    {
      when: 'immediately',
    },
    {
      when: 'before',
      referenceField: 'startDate',
      seconds: 0,
      days: 30,
    },
  ],
  ruleId: 'workerVacationNotice',
  description: 'Recibo de férias',
  name: 'Recibo de férias',
  actions: [
    {
      recipient: {
        type: 'employee',
        template: 'vacationNotice',
      },
      type: 'email',
    },
  ],
  tags: ['aprovação'],
};

export const baseNotifications = [
  alertAdminAccrualPeriodCloseToLimit,
  alertWorkerVacationsRejected,
  alertWorkerAccrualPeriodCloseToLimit,
  alertNewVacations,
  alertWorkerVacationsApproved,
  alertWorkerVacationsCanceled,
  vacationNotice,
];
