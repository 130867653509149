import {
  ChangeEvent,
  ReactElement,
  type RefCallback,
  useEffect,
  useState,
} from 'react';

import { useMaskito } from '@maskito/react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputProps,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Mapper } from '@octopus/esocial/mapper';

import { parseMask } from '../../../utils/parseMask';
import VirtualizedAutocomplete, {
  VirtualizedAutocompleteActionProps,
} from '../VirtualizedAutocomplete';

export type RecordEntryProps = {
  label: string;
  children?: string | ReactElement | undefined | string[];
  isLoading?: boolean;
  hide?: boolean;
  hideValue?: boolean;
  edit?: EditingProps;
  description?: string;
};

type BaseRecordEntryEditProps = {
  editing: boolean;
  disabled?: boolean;
  inputProps?: Partial<InputProps>;
  hasError?: boolean;
};

type TextRecordEntryEditProps = {
  type: 'text';
  value: string | number;
  mask?: RefCallback<HTMLElement>;
  maskStr?: string;
  onChange: (value: string) => void;
};

type NumberRecordEntryEditProps = {
  type: 'number';
  value: number | undefined;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  isInteger?: boolean;
};

export type OptionsRecordEntrySelectOption = {
  label: string;
  value: string | number;
  disabled?: boolean;
};

type OptionsRecordEntryEditProps = {
  type: 'options';
  value: string | number | undefined;
  options: OptionsRecordEntrySelectOption[];
  onChange: (value: string) => void;
  nullable?: boolean;
  action?: VirtualizedAutocompleteActionProps;
};

type MultiOptionsRecordEntryEditProps = {
  type: 'multi-options';
  values: string[] | number[];
  options: OptionsRecordEntrySelectOption[];
  onChange: (values: string[] | number[]) => void;
};

type DateRecordEntryEditProps = {
  type: 'date';
  value: string | number;
  valueFormat: string;
  onChange: (value: string) => void;
  startDate?: string;
  endDate?: string;
};

type CheckRecordEntryOption = {
  label: string;
  value: boolean;
  disabled?: boolean;
};

type MultiCheckRecordEntryOption = CheckRecordEntryOption & {
  key: string;
};

type CheckRecordEntryEditProps = {
  type: 'check';
  value: boolean;
  onChange: (value: boolean) => void;
};

type MultiCheckRecordEntryEditProps = {
  type: 'multi-check';
  options: MultiCheckRecordEntryOption[];
  onChange: (key: string, value: boolean) => void;
};

type OptionsAndTextRecordEntryEditProps = {
  type: 'options-and-text';
  options: Omit<OptionsRecordEntryEditProps, 'type'>;
  text: Omit<TextRecordEntryEditProps, 'type'> & { disabled?: boolean };
};

type LongTextRecordEntryEditProps = {
  type: 'longtext';
  value: string;
  onChange: (value: string) => void;
};

export type EditingProps = BaseRecordEntryEditProps &
  (
    | TextRecordEntryEditProps
    | NumberRecordEntryEditProps
    | OptionsRecordEntryEditProps
    | MultiOptionsRecordEntryEditProps
    | DateRecordEntryEditProps
    | CheckRecordEntryEditProps
    | MultiCheckRecordEntryEditProps
    | OptionsAndTextRecordEntryEditProps
    | LongTextRecordEntryEditProps
  );

export function RecordEntry(props: RecordEntryProps) {
  const {
    label,
    children,
    isLoading = false,
    hide = false,
    edit,
    description,
  } = props;
  if (hide) {
    return null;
  }
  let content;
  if (isLoading) {
    content = (
      <Skeleton
        variant="rectangular"
        width="100%"
        data-testid="loading-skeleton"
      />
    );
  } else if (edit && edit.editing) {
    content = <RecordEntryEditField {...edit} data-testid="edit-field" />;
  } else {
    if (props.hideValue) {
      content = null;
    } else if (typeof children === 'string') {
      content = (
        <Box overflow="hidden">
          <Typography
            variant="body2"
            color="text.primary"
            data-testid="child-string"
          >
            {children}
          </Typography>
        </Box>
      );
    } else if (typeof children === 'object' && Array.isArray(children)) {
      content = (
        <Box display="flex" flexDirection="column" gap={1}>
          {children.map((child, index) => (
            <Typography
              key={index}
              variant="body2"
              color="text.primary"
              data-testid={`child-array-${index}`}
            >
              {child}
            </Typography>
          ))}
        </Box>
      );
    } else if (!children) {
      content = (
        <Typography
          variant="body2"
          color="strokes.heavy"
          data-testid="no-info-text"
        >
          Não informado
        </Typography>
      );
    } else {
      content = children;
    }
  }
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={2.5}
      data-testid="record-entry"
    >
      <Typography
        variant="body2"
        color="text.primary"
        display="inline-flex"
        width="100%"
        maxWidth="36%"
        data-testid="label"
      >
        {label}
      </Typography>
      <Box
        display="inline-flex"
        width="100%"
        maxWidth="57%"
        flexGrow={1}
        gap={0.5}
        data-testid="content"
      >
        {content}
        {description && (
          <Typography
            variant="body2"
            color="text.primary"
            display="flex"
            alignItems="center"
            justifyContent={edit && edit.editing ? 'center' : 'left'}
            minWidth="40%"
            maxWidth="60%"
            data-testid="description"
          >
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

function RecordEntryEditField(props: EditingProps) {
  switch (props.type) {
    case 'text':
      return <TextRecordEntryEditField {...props} />;
    case 'number':
      return <NumberRecordEntryEditField {...props} />;
    case 'options':
      return <OptionsRecordEntryEditField {...props} />;
    case 'multi-options':
      return <MultiOptionsRecordEntryEditField {...props} />;
    case 'date':
      return <DateRecordEntryEditField {...props} />;
    case 'multi-check':
      return <MultiCheckRecordEntryEditField {...props} />;
    case 'check':
      return <CheckRecordEntryEditField {...props} />;
    case 'options-and-text':
      return <OptionsAndTextRecordEntryEditField {...props} />;
    case 'longtext':
      return <LongTextRecordEntryEditField {...props} />;
    default:
      throw new Error('Invalid record entry edit field type');
  }
}

function TextRecordEntryEditField({
  mask,
  maskStr,
  value,
  onChange,
  disabled,
  inputProps,
  hasError,
}: BaseRecordEntryEditProps & Omit<TextRecordEntryEditProps, 'type'>) {
  const [error, setError] = useState(false);

  const maskStrOptions = {
    mask: parseMask(maskStr),
  };

  const maskStrRef = useMaskito({
    options: maskStrOptions,
  });

  useEffect(() => {
    setError(hasError);
  }, [hasError]);

  return (
    <TextField
      variant="outlined"
      value={value ?? ''}
      InputProps={{
        ...inputProps,
        ...(error
          ? { endAdornment: <ErrorOutlineOutlinedIcon color="error" /> }
          : {}),
      }}
      fullWidth
      onInput={(event: ChangeEvent<HTMLInputElement>) => {
        setError(false);
        onChange(event.target.value);
      }}
      disabled={disabled}
      ref={mask ?? (maskStr !== undefined ? maskStrRef : undefined)}
      error={error}
      helperText={error ? 'Valor inválido' : ''}
    />
  );
}

function NumberRecordEntryEditField({
  value,
  onChange,
  disabled,
  inputProps,
  hasError,
  isInteger,
  min,
  max,
}: BaseRecordEntryEditProps & Omit<NumberRecordEntryEditProps, 'type'>) {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(hasError);
  }, [hasError]);

  return (
    <TextField
      variant="outlined"
      type="number"
      value={value ?? ''}
      InputProps={{
        ...inputProps,
        ...(error
          ? { endAdornment: <ErrorOutlineOutlinedIcon color="error" /> }
          : {}),
      }}
      fullWidth
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setError(false);
        const val = isInteger
          ? parseInt(event.target.value, 10)
          : parseFloat(event.target.value);
        if (Number.isNaN(val)) {
          setError(true);
          return;
        }
        onChange(val);
      }}
      disabled={disabled}
      error={
        error ||
        Number.isNaN(value) ||
        (min !== undefined && value < min) ||
        (max !== undefined && value > max)
      }
      helperText={
        min !== undefined && value < min
          ? `Valor precisa ser maior ou igual a ${min}`
          : max !== undefined && value > max
            ? `Valor precisa ser menor ou igual a ${max}`
            : error
              ? 'Valor inválido'
              : ''
      }
    />
  );
}

function OptionsRecordEntryEditField({
  value,
  options,
  onChange,
  disabled,
  hasError,
  nullable,
  action,
}: BaseRecordEntryEditProps & Omit<OptionsRecordEntryEditProps, 'type'>) {
  return (
    <VirtualizedAutocomplete
      value={value}
      onChange={(val) => onChange(val as string)}
      options={options}
      disabled={disabled}
      hasError={hasError}
      nullable={nullable}
      action={action}
    />
  );
}

function MultiOptionsRecordEntryEditField({
  values,
  options,
  onChange,
  disabled,
  hasError,
}: BaseRecordEntryEditProps & Omit<MultiOptionsRecordEntryEditProps, 'type'>) {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(hasError);
  }, [hasError]);

  const vals: unknown[] =
    values !== undefined && Array.isArray(values)
      ? values.filter((val) => options.find((option) => option.value === val))
      : [];

  return (
    <Select
      fullWidth
      multiple
      disabled={options === undefined || options.length === 0 || disabled}
      value={vals}
      onChange={({ target: { value } }) => {
        setError(false);
        onChange(
          (typeof value === 'string' ? value.split(',') : value) as
            | string[]
            | number[],
        );
      }}
      placeholder="Nenhum"
      error={error}
      inputProps={{
        ...(error
          ? { endAdornment: <ErrorOutlineOutlinedIcon color="error" /> }
          : {}),
        'data-testid': 'multi-options-select',
      }}
      renderValue={(selected) => {
        if (!Array.isArray(selected)) {
          return '';
        }
        if (selected.length === options.length) {
          return 'Todos';
        }
        if (selected.length === 1) {
          return (
            options.find(({ value }) => value === selected[0])?.label ??
            `${selected[0]}`
          );
        }
        return `${selected.length} itens selecionados`;
      }}
    >
      {options?.map(({ label, value, disabled }) => (
        <MenuItem key={value} value={value} disabled={disabled}>
          <Checkbox checked={vals?.includes(value)} />
          <ListItemText primary={label} />
        </MenuItem>
      ))}
    </Select>
  );
}

function DateRecordEntryEditField({
  value,
  onChange,
  valueFormat,
  disabled,
  hasError,
  startDate,
  endDate,
}: BaseRecordEntryEditProps & Omit<DateRecordEntryEditProps, 'type'>) {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(hasError);
  }, [hasError]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <DatePicker
        defaultValue={
          value && dayjs(value, 'YYYY-MM-DD').isValid()
            ? dayjs(value)
            : undefined
        }
        sx={{ width: '100%' }}
        onChange={(v) => {
          onChange(v ? v.format(valueFormat) : null);
        }}
        disabled={disabled}
        slotProps={{
          textField: {
            error,
            helperText: error ? 'Data inválida' : '',
          },
        }}
        minDate={
          startDate && dayjs(startDate, 'YYYY-MM-DD').isValid()
            ? dayjs(startDate)
            : undefined
        }
        maxDate={
          endDate && dayjs(endDate, 'YYYY-MM-DD').isValid()
            ? dayjs(endDate)
            : undefined
        }
      />
    </LocalizationProvider>
  );
}

function CheckRecordEntryEditField({
  value,
  onChange,
}: BaseRecordEntryEditProps & Omit<CheckRecordEntryEditProps, 'type'>) {
  let checked;
  if (typeof value === 'boolean') {
    checked = value;
  } else {
    checked = false;
  }
  return (
    <Checkbox checked={checked} onChange={(_, value) => onChange(value)} />
  );
}

function MultiCheckRecordEntryEditField({
  options,
  onChange,
  disabled: allDisabled,
}: BaseRecordEntryEditProps & Omit<MultiCheckRecordEntryEditProps, 'type'>) {
  return (
    <FormGroup
      sx={{
        paddingTop: 1,
        alignSelf: 'flex-start',
      }}
    >
      {options.map(({ key, label, value, disabled }) => (
        <FormControlLabel
          key={key}
          disabled={allDisabled || disabled}
          control={
            <Checkbox
              disabled={allDisabled || disabled}
              checked={value}
              onChange={(_, value) => onChange(key, value)}
            />
          }
          label={
            <Typography variant="body2" fontWeight="500">
              {label}
            </Typography>
          }
        />
      ))}
    </FormGroup>
  );
}

function OptionsAndTextRecordEntryEditField({
  hasError,
  editing,
  options,
  text,
}: BaseRecordEntryEditProps &
  Omit<OptionsAndTextRecordEntryEditProps, 'type'>) {
  return (
    <Box display="flex" flexDirection="row" gap={1}>
      <Box minWidth="128px" maxWidth="128px">
        <OptionsRecordEntryEditField
          editing={editing}
          {...options}
          hasError={hasError}
        />
      </Box>
      <TextRecordEntryEditField
        editing={editing}
        {...text}
        hasError={hasError}
      />
    </Box>
  );
}

function LongTextRecordEntryEditField({
  value,
  onChange,
  disabled,
  inputProps,
  hasError,
}: BaseRecordEntryEditProps & Omit<LongTextRecordEntryEditProps, 'type'>) {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(hasError);
  }, [hasError]);

  return (
    <TextField
      variant="outlined"
      value={value}
      InputProps={{
        ...inputProps,
        ...(error
          ? { endAdornment: <ErrorOutlineOutlinedIcon color="error" /> }
          : {}),
      }}
      fullWidth
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setError(false);
        onChange(event.target.value);
      }}
      disabled={disabled}
      error={error}
      helperText={error ? 'Valor inválido' : ''}
      multiline
      minRows={2}
    />
  );
}

type MapperToOptionsArgs = {
  mapper: Mapper;
  sortBy?: 'label' | 'value';
  getLabel?: (code: string | number) => string;
  filter?: (code: string | number) => boolean;
};

export function mapperToOptions({
  mapper,
  getLabel,
  sortBy = 'value',
  filter = () => true,
}: MapperToOptionsArgs): OptionsRecordEntrySelectOption[] {
  const labelMapper = getLabel ?? mapper.getByCode.bind(mapper);

  type Sorter = (
    a: OptionsRecordEntrySelectOption,
    b: OptionsRecordEntrySelectOption,
  ) => number;

  const sorter: Sorter =
    sortBy === 'value'
      ? (a, b) => sortValue(a.value, b.value)
      : (a, b) => sortValue(a.label, b.label);

  return mapper
    .codes()
    .filter(filter)
    .map((code) => ({
      label: labelMapper(code),
      value: code,
    }))
    .sort(sorter);
}

function sortValue<T extends string | number>(a: T, b: T): number {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }
  return 0;
}
