import { useState } from 'react';

import { Submission } from '@conform-to/react';

import { ArrowBackOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Container, Typography } from '@mui/material';

import {
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftStatus,
} from '@octopus/api';
import {
  adminOnlyStatuses,
  customSteps,
  finalStatuses,
  initialSteps,
  workerOnlyStatuses,
} from '@octopus/onboarding-types';

import { MultiStepView } from '../../../modules/navigation/multiStep/MultiStepView';
import { MembershipContext, MembershipOption } from '../../../modules/types';
import { MembershipSelectorChip } from '../../navigation/bottombar/membershipSelectorChip';
import { getNextButtonLabel } from '../utils/labels';

import { LeftFormStepper } from './form/LeftFormStepper';
import { AdmissionFormState, AdmissionFormSteps } from './form/types';
import { NewAdmissionForm } from './NewAdmissionForm';
import { NewAdmissionHeader } from './NewAdmissionHeader';

type Props = {
  isWorkerExperience: boolean;
  draftId: string;
  organizationId: string;
  draftStatus: AdmissionDraftStatus;
  isSmallDevice: boolean;
  saveAndCloseButtonId: string;
  isLoading: boolean;
  adminFormSteps: AdmissionFormSteps;
  workerFormSteps: AdmissionFormSteps;
  formSteps: AdmissionFormSteps;
  formState: AdmissionFormState;
  multiStepView: any; //todo: add type
  onStepSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    formData: Submission<any, string[], any>,
  ) => void;
  onSubmitCustom: ({
    stepName,
    extraData,
  }: {
    stepName?: AdmissionDraftInputFormStepEnum;
    extraData?: Record<string, any>;
  }) => void;
  onSubmitCustomCallback: (newFormState: AdmissionFormState) => void;
  getCurrentStepName: () => AdmissionDraftInputFormStepEnum;
  updateAdmissionDraft: ({
    currentStepName,
    newFormState,
    shouldClose,
  }: {
    currentStepName: AdmissionDraftInputFormStepEnum;
    newFormState: AdmissionFormState;
    shouldClose?: boolean;
  }) => void;
  forceCompleteCurrentStep: () => void;
  closeFormPage: () => void;
  membershipProps: {
    membership: MembershipContext;
    membershipOptions: MembershipOption[];
  };
  reviewMode: boolean;
  pjEnabled: boolean;
};

export const NewAdmissionContainer = ({
  isWorkerExperience,
  draftId,
  organizationId,
  draftStatus,
  saveAndCloseButtonId,
  isSmallDevice,
  isLoading,
  adminFormSteps,
  workerFormSteps,
  formSteps,
  formState,
  multiStepView,
  onStepSubmit,
  onSubmitCustom,
  onSubmitCustomCallback,
  getCurrentStepName,
  updateAdmissionDraft,
  forceCompleteCurrentStep,
  closeFormPage,
  membershipProps,
  reviewMode,
  pjEnabled,
}: Props) => {
  const [showForm, setShowForm] = useState<boolean>();

  const shouldToggleForm = isSmallDevice;
  const currentStepName = getCurrentStepName();
  const isInitialSteps = initialSteps.has(currentStepName);

  const submitLabel = getNextButtonLabel({
    draftId,
    draftStatus,
    currentStepName,
    isWorkerExperience,
    isLastStep: multiStepView.currentStep?.isLast,
    isSmallDevice,
  });

  const getFormId = (stepName: string) =>
    draftId ? `${draftId}_${stepName}` : null;

  const backButtonClick = (updateDisabled: boolean) => {
    if (updateDisabled) {
      closeFormPage();
    }

    if (isSmallDevice) {
      if (showForm) {
        setShowForm(!showForm);
      }
      return;
    }

    const currentStepName = getCurrentStepName();
    const isInitialSteps = initialSteps.has(currentStepName);
    if (isInitialSteps && !draftId) {
      forceCompleteCurrentStep();
      return closeFormPage();
    }

    const isCustomStep = customSteps.has(currentStepName);

    if (isCustomStep) {
      return updateAdmissionDraft({
        currentStepName,
        newFormState: { ...formState },
        shouldClose: true,
      });
    }
  };
  const updateDisabled =
    membershipProps.membership.membershipType !== 'tako:support' &&
    (finalStatuses.has(draftStatus) ||
      (adminOnlyStatuses.has(draftStatus) && isWorkerExperience) ||
      (workerOnlyStatuses.has(draftStatus) && !isWorkerExperience));

  return (
    <>
      <Box
        sx={(theme) => ({
          width: '100%',
          border: 'none',
          alignSelf: 'flex-start',
          position: 'fixed',
          background: 'white',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'row',
          boxSizing: 'border-box',
          [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
          },
          [theme.breakpoints.down('md')]: {
            justifyContent: showForm ? 'space-between' : 'flex-end',
            px: 1,
            py: 2,
          },
        })}
      >
        <Button
          disabled={isLoading}
          id={saveAndCloseButtonId}
          onClick={() => backButtonClick(updateDisabled)}
          type={isInitialSteps && !draftId ? 'button' : 'submit'}
          form={`payload:${getFormId(multiStepView.currentStep?.name)}`}
          color="secondary"
          sx={(theme) => ({
            width: 'auto',
            border: 'none',
            alignSelf: 'flex-start',
            gap: 1,
            [theme.breakpoints.up('md')]: {
              py: 2,
              display: 'flex',
            },
            [theme.breakpoints.down('md')]: {
              px: 0,
              display: !showForm ? 'none' : 'flex',
              justifyContent: 'flex-start',
            },
          })}
        >
          <ArrowBackOutlined
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: { height: '16px', width: '16px' },
              [theme.breakpoints.down('md')]: { height: '24px', width: '24px' },
              justifySelf: 'flex-start',
            })}
          />
          {!isSmallDevice && (
            <Typography variant="body2">
              {isInitialSteps && !draftId
                ? 'Cancelar e sair'
                : updateDisabled
                  ? 'Sair'
                  : 'Salvar e sair'}
            </Typography>
          )}
        </Button>
        <Box>
          <MembershipSelectorChip
            membership={membershipProps.membership}
            membershipOptions={membershipProps.membershipOptions}
          />
        </Box>
      </Box>
      <Container
        sx={(theme) => ({
          display: 'flex',
          minHeight: '100svh',
          width: '100%',
          flexDirection: 'column',
          [theme.breakpoints.up('md')]: {
            marginTop: 7,
          },
        })}
      >
        <MultiStepView multiStepView={multiStepView}>
          {Object.entries(formSteps).map(([key, value]) => {
            const { definition, options } = value;
            const showLeftFormStepper = shouldToggleForm
              ? !options.hideLeftMenu && !showForm
              : !options.hideLeftMenu;
            const showRightForm = shouldToggleForm ? showForm : true;
            const stepName = key as AdmissionDraftInputFormStepEnum;
            const isStepActive =
              stepName ===
              (multiStepView.currentStep
                ?.name as AdmissionDraftInputFormStepEnum);
            const updateStepDisabled =
              updateDisabled || (reviewMode && stepName === 'envio_convite');
            return (
              <MultiStepView.Step name={key} key={key}>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'row',
                    boxSizing: 'border-box',
                    width: '100%',
                    [theme.breakpoints.up('md')]: {
                      mx: 'auto',
                      px: 7,
                      py: 10,
                      gap: 7,
                    },
                    [theme.breakpoints.down('md')]: {
                      px: 0,
                      py: 10,
                      gap: 0,
                    },
                  })}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {showLeftFormStepper && (
                      <LeftFormStepper
                        isWorkerExperience={isWorkerExperience}
                        adminSteps={adminFormSteps}
                        workerSteps={workerFormSteps}
                        moveToStepClick={(key) => {
                          if (shouldToggleForm) {
                            setShowForm(!showForm);
                          }
                          multiStepView.replaceCurrent(key);
                        }}
                        currentStep={getCurrentStepName()}
                        currentStepIndex={multiStepView.currentStep?.idx}
                        draftStatus={draftStatus}
                        isPj={pjEnabled && formState.workerCategory === 'pj'}
                      />
                    )}
                    {showLeftFormStepper && isWorkerExperience && (
                      <Box
                        sx={(theme) => ({
                          width: '100%',
                          [theme.breakpoints.up('md')]: {
                            display: 'flex',
                            paddingTop: 4,
                          },
                          [theme.breakpoints.down('md')]: {
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            background: theme.palette.background.paper,
                            border: 'none',
                            px: 2,
                            py: 2,
                          },
                        })}
                      >
                        <LoadingButton
                          fullWidth
                          form={`payload:${getFormId(multiStepView.currentStep?.name)}`}
                          color="primaryAlt"
                          variant="contained"
                          disabled={updateStepDisabled}
                          loading={isLoading}
                          sx={(theme) => ({
                            py: 1.25,
                            [theme.breakpoints.up('md')]: {
                              display: 'none',
                            },
                            [theme.breakpoints.down('md')]: {
                              display: 'block',
                              width: 'calc(100svw - 32px)',
                            },
                          })}
                          type="button"
                          onClick={() =>
                            onSubmitCustom({ stepName: 'finaliza_admissao' })
                          }
                        >
                          Enviar para o departamento pessoal
                        </LoadingButton>
                      </Box>
                    )}
                  </Box>
                  {showRightForm && isStepActive && (
                    <Box
                      sx={(theme) => ({
                        width: '100%',
                        maxWidth: !options.hideLeftMenu ? '480px' : '540px',
                        marginX: options.hideLeftMenu ? 'auto' : undefined,
                        [theme.breakpoints.up('lg')]: {
                          minWidth: '580px',
                          mt: '2px',
                        },
                      })}
                    >
                      <NewAdmissionHeader
                        title={options.hideTitle ? undefined : options.title}
                        hideLeftMenu={options.hideLeftMenu}
                      />
                      {options.getCustomComponent != null ? (
                        options.getCustomComponent({
                          updateDisabled: updateStepDisabled,
                          formState,
                          stepDefinition: value,
                          onSubmitCallback: onSubmitCustomCallback,
                          goToNextStep: () => {
                            updateAdmissionDraft({
                              currentStepName: stepName,
                              newFormState: { ...formState },
                            });
                          },
                          goToPreviousStep: () => {
                            multiStepView.goBack();
                          },
                          onSubmit: ({
                            extraData,
                          }: {
                            extraData?: Record<string, any>;
                          }) =>
                            onSubmitCustom({ stepName: options.id, extraData }),
                          submitLabel,
                          isLoading,
                          organizationId,
                          draftId,
                        })
                      ) : (
                        <NewAdmissionForm
                          isSmallDevice={isSmallDevice}
                          disabled={updateStepDisabled}
                          onSubmit={onStepSubmit}
                          definition={definition}
                          submitLabel={submitLabel}
                          isLoading={isLoading}
                          stepName={stepName}
                          id={getFormId(options.id)}
                          disclaimer={options.disclaimer}
                          canGoToPreviousStep={
                            !multiStepView.currentStep?.isFirst
                          }
                          goToPreviousStep={() => multiStepView.goBack()}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </MultiStepView.Step>
            );
          })}
        </MultiStepView>
      </Container>
    </>
  );
};
