import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Close } from '@mui/icons-material';
import { Box, Button, Link, Tab, Tabs, Typography } from '@mui/material';

import { LegalEntityEntry, PayrollElement, PayrollEntry } from '@octopus/api';
import { capitalize, formatMoney, formatPeriodDate } from '@octopus/formatters';
import { summaryElements } from '@octopus/payroll-engine/public-types/core';

import {
  ArrowNavigation,
  Navigator,
} from '../../../../modules/components/Navigator';
import { CalculationExplanation } from '../../../../modules/components/payrolls/CalculationExplanation';
import { ElementsTable } from '../../../../modules/components/payrolls/ElementsTable';
import { PayrollDetailsComponent } from '../../../../modules/components/payrolls/PayrollDetails';
import { PeriodFormat } from '../../../../modules/types';
import { useArchivePayroll } from '../../hooks/useArchivePayroll';

export function PayrollDetails({
  organizationId,
  companyId,
  period,
  type,
  contractId,
  payrollId,
  compareToPeriod,
  workerNavigation,
  onExit,
}: {
  organizationId: string;
  companyId: string;
  contractId: string;
  period: PeriodFormat;
  compareToPeriod?: PeriodFormat;
  type: string;
  payrollId: string;
  workerNavigation: ArrowNavigation;
  onExit: () => void;
}) {
  return (
    <PayrollDetailsComponent
      organizationId={organizationId}
      companyId={companyId}
      period={period}
      type={type}
      contractId={contractId}
      payrollId={payrollId}
      compareToPeriod={compareToPeriod}
      workerNavigation={workerNavigation}
      onExit={onExit}
      payrollWindowDetailsRender={(props) => (
        <PayrollDetailsWindow {...props} />
      )}
    />
  );
}

function PayrollDetailsWindow({
  payroll,
  legalEntity,
  comparisonPeriod,
  comparisonPayroll,
  isLoadingComparisonPayroll,
  periodNavigation,
  workerNavigation,
  onExit,
}: {
  payroll: PayrollEntry;
  legalEntity: LegalEntityEntry;
  comparisonPeriod: string | null | undefined;
  comparisonPayroll: PayrollEntry | undefined;
  isLoadingComparisonPayroll?: boolean;
  periodNavigation: ArrowNavigation;
  workerNavigation: ArrowNavigation;
  onExit: () => void;
}) {
  const { ArchivePayroll } = useArchivePayroll(payroll, onExit);
  const [elementStack, setElementStack] = useState<PayrollElement[]>([]);
  const onClickElement = (element: PayrollElement, replace: boolean) => {
    if (element.id === elementStack[elementStack.length - 1]?.id) {
      setElementStack([]);
      return;
    }
    const payrollElement = payroll.outputs?.elements[element.id];
    if (replace) {
      setElementStack([payrollElement]);
    } else {
      setElementStack((state) => [...state, payrollElement]);
    }
  };

  const header = (
    <Box
      px={5}
      pt={4}
      pb={2}
      sx={{ display: 'flex', justifyContent: 'space-between' }}
      data-testid="payroll-details"
    >
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="h3">
          {capitalize(payroll.workerData.name)}
        </Typography>
        <Box display="flex" gap={1}>
          {payroll.workerData.employeeId && (
            <>
              <Typography
                variant="caption"
                component="p"
                fontWeight="500"
                color="text.secondary"
              >
                Matrícula: {payroll.workerData.employeeId}
              </Typography>

              <Typography
                variant="caption"
                component="p"
                fontWeight="300"
                color="text.secondary"
              >
                -
              </Typography>
            </>
          )}

          <Typography
            variant="caption"
            component="p"
            fontWeight="500"
            color="text.secondary"
          >
            {capitalize(payroll.workerData.jobTitle)}
          </Typography>
          <Typography
            variant="caption"
            component="p"
            fontWeight="300"
            color="text.secondary"
          >
            -
          </Typography>
          <Typography
            variant="caption"
            component="p"
            fontWeight="500"
            color="text.secondary"
          >
            {formatMoney(payroll.workerData.salary)}
          </Typography>
        </Box>

        <Box display="flex" gap={1}>
          <Typography
            variant="caption"
            component="p"
            fontWeight="500"
            color="text.secondary"
          >
            Admissão:{' '}
            {payroll.workerData.admissionDate.split('-').reverse().join('/')}
          </Typography>
          <Typography
            variant="caption"
            component="p"
            fontWeight="300"
            color="text.secondary"
          >
            -
          </Typography>
          <Typography
            variant="caption"
            component="p"
            fontWeight="500"
            color="text.secondary"
          >
            Local de serviço: {legalEntity.br?.nomeFantasia}
          </Typography>
        </Box>
      </Box>

      <Box display={'flex'} gap={1.5}>
        <Navigator
          arrowNavigation={workerNavigation}
          arrowsDirection="vertical"
          iconSize="medium"
        />
        <Button
          color="secondary"
          size="small"
          onClick={() => {
            onExit();
          }}
          sx={[
            () => ({
              borderColor: 'transparent',
              borderRadius: '50%',
              minWidth: 32,
              minHeight: 32,
              height: 32,
              width: 32,
            }),
          ]}
        >
          <Close sx={{ fontSize: '24px' }} />
        </Button>
      </Box>
    </Box>
  );

  const netPayContent = (
    <Box px={2}>
      <Typography variant="h3" sx={{ pb: 2, px: 2.3 }}>
        Proventos
      </Typography>
      <ElementsTable
        payroll={payroll}
        comparisonPeriod={comparisonPeriod}
        isLoadingComparisonPayroll={isLoadingComparisonPayroll}
        comparisonPayroll={comparisonPayroll}
        summary="workerEarningsTotal"
        onClickElement={(element) => onClickElement(element, true)}
        selectedElementId={elementStack[elementStack.length - 1]?.id}
      />

      <Typography variant="h3" sx={{ px: 2.3, pb: 2, pt: 6 }}>
        Deduções
      </Typography>
      <ElementsTable
        payroll={payroll}
        comparisonPeriod={comparisonPeriod}
        isLoadingComparisonPayroll={isLoadingComparisonPayroll}
        comparisonPayroll={comparisonPayroll}
        summary="workerDeductionsTotal"
        onClickElement={(element) => onClickElement(element, true)}
        selectedElementId={elementStack[elementStack.length - 1]?.id}
      />
    </Box>
  );

  const totalCostContent = (
    <Box px={2}>
      <ElementsTable
        payroll={payroll}
        comparisonPeriod={comparisonPeriod}
        isLoadingComparisonPayroll={isLoadingComparisonPayroll}
        comparisonPayroll={comparisonPayroll}
        summary="totalCost"
        onClickElement={(element) => onClickElement(element, true)}
        selectedElementId={elementStack[elementStack.length - 1]?.id}
      />
    </Box>
  );

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const activeTab = {
    0: summaryElements.netPay,
    1: summaryElements.totalCost,
  }[activeTabIndex];

  const mainContent = (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={3}
        pl={4}
        pr={1.5}
        sx={(theme) => ({
          boxShadow: `0 -1px 0 ${theme.palette.strokes.light} inset`,
        })}
      >
        <Tabs
          value={activeTabIndex}
          onChange={(_, newTab) => setActiveTabIndex(newTab)}
        >
          <Tab label="Holerite" sx={{ mr: 3 }} />
          <Tab label="Prévia de custo" />
        </Tabs>
        <ArchivePayroll />
      </Box>

      <Box pb={2} width="100%">
        <Box pb={3} />

        <Box
          sx={{
            position: 'relative',
          }}
        >
          {[summaryElements.netPay, summaryElements.totalCost].map(
            (tab) =>
              tab === activeTab && (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    position: tab === activeTab ? 'relative' : 'absolute',
                    top: 0,
                  }}
                >
                  {{ netPay: netPayContent, totalCost: totalCostContent }[tab]}
                </Box>
              ),
          )}
        </Box>
      </Box>
    </>
  );

  const footer = (
    <Box
      px={3}
      py={2}
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        background: 'rgba(247, 247, 248, 0.85)',
        backdropFilter: 'blur(4px)',
      }}
    >
      <Typography variant="body1" fontWeight="bold">
        {activeTab === summaryElements.netPay &&
          `Valor líquido: ${formatMoney(payroll.outputs?.netPay.total)}`}
        {activeTab === summaryElements.totalCost &&
          `Prévia de custo: ${formatMoney(payroll.outputs?.totalCost.total)}`}
      </Typography>
    </Box>
  );

  const elementIsProvisionElement = (
    elementStack[elementStack.length - 1]?.id || ''
  ).startsWith('provisoes');
  const provisionLink = elementIsProvisionElement ? (
    <Box>
      <Link
        component={RouterLink}
        to={`/provisions/${payroll.period}/${payroll.contractId}`}
      >
        Acessar explicação de cálculo de provisões →
      </Link>
    </Box>
  ) : null;

  return (
    <Box display="flex" alignContent="stretch" height="100%">
      <Box
        width={elementStack.length > 0 ? '520px' : '0px'}
        height="100%"
        zIndex={1}
        sx={{
          opacity: elementStack.length > 0 ? 1 : 0,
          transition: 'all 0.2s',
          backgroundColor: 'background.default',
          overflowY: 'overlay',
        }}
      >
        {elementStack.length > 0 && (
          <CalculationExplanation
            element={elementStack[elementStack.length - 1]}
            canGoBack={elementStack.length > 1}
            goBack={() => setElementStack((state) => state.slice(0, -1))}
            onClickElement={(element) => onClickElement(element, false)}
            onExit={() => setElementStack([])}
            additionalSubHeaderElement={provisionLink}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        zIndex={2}
        sx={(theme) => ({
          boxShadow: `-4px 4px 16px ${theme.palette.strokes.light}, -2px 2px 4px ${theme.palette.strokes.light}`,
        })}
      >
        <Box flex={1} overflow="overlay" width="624px">
          {header}
          {payroll.type === 'monthly' && (
            <Navigator
              label={formatPeriodDate(payroll.period)}
              arrowNavigation={periodNavigation}
              arrowsDirection="horizontal"
              iconSize="small"
            />
          )}
          {mainContent}
          <Box height="56px" /> {/* offset footer size*/}
          {footer}
        </Box>
      </Box>
    </Box>
  );
}
