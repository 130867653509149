import { useQuery } from '@tanstack/react-query';

import { fetchGetUserMembership } from '@octopus/api';

import { UserContext } from '../types';

import { appContextQueryConfig } from './constants';
import { useMembershipWithStorage } from './useMembershipWithStorage';

export const useMembership = (user: UserContext) => {
  const query = useQuery({
    queryKey: ['membership', user?.userId],
    queryFn: () =>
      fetchGetUserMembership({
        pathParams: {
          userId: user?.userId,
        },
        queryParams: {
          size: '100',
        },
      }).then((response) => response.data),
    enabled: !!user?.userId,
    ...appContextQueryConfig,
  });

  const { currentMembership, changeMembership } = useMembershipWithStorage(
    query,
    user,
  );

  return {
    ...query,
    memberships: query?.data,
    currentMembership,
    changeMembership,
  };
};
