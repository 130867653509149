import React, { useEffect } from 'react';

import { Close } from '@mui/icons-material';
import { Box, Button, Drawer, Typography } from '@mui/material';

import { CompanySummary, JobTitleEntry, JobTitleSummary } from '@octopus/api';

import { ContentTabs } from '../../ContentTabs';
import { ArrowNavigation, Navigator } from '../../Navigator';

import { JobTitle } from './JobTitle';
import { JobTitleContracts } from './JobTitleContracts';
import { JobTitleHistory } from './JobTitleHistory';

export type DetailsDrawerProps = {
  organizationId: string;
  companies: CompanySummary[] | undefined;
  jobTitleSummary: JobTitleSummary | undefined;
  contractsCount: number;
  navigation: ArrowNavigation;
  open: boolean;
  onEdit: (updatedEntry: JobTitleEntry) => void;
  onArchive: (jobTitleId: string) => void;
  onRestore: (jobTitleId: string) => void;
  onClose: () => void;
};

const detailsContents = {
  jobTitle: 'jobTitle',
  contracts: 'contracts',
  history: 'history',
} as const;

type DetailsContent = keyof typeof detailsContents;

export function DetailsDrawer({
  organizationId,
  companies,
  jobTitleSummary,
  navigation,
  contractsCount,
  open,
  onEdit,
  onArchive,
  onRestore,
  onClose,
}: DetailsDrawerProps) {
  const [detailsContent, setDetailsContent] = React.useState<DetailsContent>(
    detailsContents.jobTitle,
  );

  useEffect(() => {
    setDetailsContent(detailsContents.jobTitle);
  }, [open]);

  if (jobTitleSummary === undefined) {
    return null;
  }

  const content = (() => {
    switch (detailsContent) {
      case detailsContents.jobTitle:
        return (
          <JobTitle
            organizationId={organizationId}
            jobTitleId={jobTitleSummary.jobTitleId}
            companies={companies}
            onEdit={onEdit}
            onArchive={onArchive}
            onRestore={onRestore}
          />
        );
      case detailsContents.contracts:
        return (
          <JobTitleContracts
            organizationId={organizationId}
            jobTitleId={jobTitleSummary.jobTitleId}
            contractsCount={contractsCount}
          />
        );
      case detailsContents.history:
        return (
          <JobTitleHistory
            organizationId={organizationId}
            jobTitleId={jobTitleSummary.jobTitleId}
            companies={companies}
          />
        );
    }
  })();

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      data-testid="job-title-details-drawer"
    >
      <Box
        display="flex"
        flexDirection="column"
        pt={5}
        pb={4}
        px={7}
        height="100%"
        width="700px"
        boxSizing="border-box"
        gap={3}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography
            variant="h6"
            maxWidth="440px"
            data-testid="job-title-name"
          >
            {jobTitleSummary.name}
          </Typography>
          <Box display="flex" gap={1.5}>
            <Navigator
              arrowNavigation={navigation}
              arrowsDirection="vertical"
              iconSize="medium"
            />
            <Button
              color="secondary"
              size="small"
              onClick={onClose}
              sx={[
                () => ({
                  borderColor: 'transparent',
                  borderRadius: '50%',
                  minWidth: 32,
                  minHeight: 32,
                  height: 32,
                  width: 32,
                }),
              ]}
            >
              <Close sx={{ fontSize: '24px' }} />
            </Button>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" height="100%" width="100%">
          <ContentTabs
            selected={detailsContent}
            setSelected={setDetailsContent}
            tabs={[
              {
                value: detailsContents.jobTitle,
                label: 'Detalhes',
              },
              {
                value: detailsContents.contracts,
                label: 'Contratos',
              },
              {
                value: detailsContents.history,
                label: 'Histórico',
              },
            ]}
            tabProps={{
              variant: 'body2',
            }}
          />
          {content}
        </Box>
      </Box>
    </Drawer>
  );
}
