import { useEffect } from 'react';

import { Cancel, CheckCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import {
  ApproveAllPayrollsError,
  useSendAllPayrollPayslip,
} from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

import { TwoStepPeriodDialog } from '../../../../modules/components/payrolls/TwoStepPeriodDialog';
import { useSnackbar } from '../../../../modules/hooks/useSnackbar';

export function SendPayslipsConfirmationModal({
  organizationId,
  companyId,
  close,
}: {
  organizationId: string;
  companyId: string;
  close: () => void;
}) {
  const { mutate, isLoading, isError, isSuccess, error } =
    useSendAllPayrollPayslip();
  const showSnackbar = useSnackbar().showSnackbar;

  const handleConfirm = (periodId: string) => {
    mutate({
      pathParams: {
        organizationId,
        companyId,
        periodId,
        payrollType: 'rpa',
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      showSnackbar({
        isOpen: true,
        Message: 'RPAs enviados com sucesso',
        StartAdornment: <CheckCircle />,
        autoHideDuration: 5000,
        hasCloseAction: true,
      });
      close();
    }
  }, [isSuccess]);

  if (isLoading) {
    return <LoadingScene />;
  }

  if (isError) {
    return <ErrorScene error={error} close={close} />;
  }

  if (isSuccess) {
    return null;
  }

  const confirmationContent = (
    <Box gap={2} mt={2}>
      <Typography fontSize="16px" fontWeight={500}>
        Ao confirmar, o que acontece em seguida:
      </Typography>

      <Box display="flex" flexDirection="column">
        <Box
          component="ul"
          pl={2}
          gap={2}
          display="flex"
          flexDirection="column"
        >
          <li>
            <Typography fontWeight={500} variant="body1" fontSize="16px">
              Disponibilização dos RPAs
            </Typography>
            <Typography fontWeight={500} variant="caption" fontSize="12px">
              Os autônomos terão acesso aos documentos no sistema, disponíveis
              também para download.
            </Typography>
          </li>
        </Box>
      </Box>
    </Box>
  );

  return (
    <TwoStepPeriodDialog
      title="Enviar RPAs"
      onConfirm={handleConfirm}
      onClose={close}
      confirmationContent={confirmationContent}
      periodWarning="Todos os pagamentos serão enviados para o mês selecionado."
      periodRequirements="Os pagamentos devem estar aprovados."
    />
  );
}

function ErrorScene({
  error,
  close,
}: {
  error: ApproveAllPayrollsError;
  close: () => void;
}) {
  return (
    <>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={1.8} pb={3}>
          <Cancel fontSize="huge" color="error" />
          <Typography variant="h1">Erro no envio</Typography>
        </Box>
        <Typography variant="body1">
          Não foi possível enviar os recibos, certifique-se de que não existem
          pendências e tente novamente.
        </Typography>
        {error && <pre>{JSON.stringify(error.payload, null, 2)}</pre>}
      </DialogContent>

      <DialogActions>
        <Button size="large" sx={{ minWidth: '140px' }} onClick={close}>
          Ok
        </Button>
      </DialogActions>
    </>
  );
}

function LoadingScene() {
  return (
    <DialogContent>
      <Box
        py={10}
        px={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box width="100px">
          <OctopusLoading />
        </Box>
        <Box pt={2}>
          <Typography variant="h4" textAlign="center">
            Enviando recibos, aguarde alguns segundos...
          </Typography>
        </Box>
      </Box>
    </DialogContent>
  );
}
