import { IconFolderCog } from '@tabler/icons-react';

import { Box } from '@mui/system';

import { PageContainer } from '../../../modules/components/page/PageContainer';
import { PageTitle } from '../../../modules/components/page/PageTitle';

import { TemplatesTable } from './TemplatesTable';

type Props = {
  organizationId: string;
};

export function ManageTemplates({ organizationId }: Props) {
  return (
    <Box display="flex" width="100%">
      <PageContainer>
        <PageTitle title="Gerenciar templates" icon={IconFolderCog} />
        <Box
          sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <TemplatesTable organizationId={organizationId} />
        </Box>
      </PageContainer>
    </Box>
  );
}
