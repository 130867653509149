import { useState } from 'react';

import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { AdmissionDraftAttachmentType } from '@octopus/api';
import { NEW_UI_TYPE } from '@octopus/libs/forms';

import { UploadAdmissionAttachment } from '../../../app/admission/components/UploadAdmissionAttachment';
import {
  TFieldDocumentRenderProps,
  TFieldRenderProps,
} from '../parseField/types';

import { FieldWrapper } from './commons/FieldWrapper';

type FieldProps = {
  field: TFieldRenderProps & TFieldDocumentRenderProps;
  sx?: SxProps<Theme>;
};

export function FieldDocument(props: FieldProps) {
  const { field } = props;
  const { extraProps } = field;
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);

  return (
    <FieldWrapper
      field={{
        ...field,
        label: {
          textContent: '',
          subtextContent: '',
          props: {
            ...field.label.props,
          },
        },
      }}
      sx={field.props.sx}
    >
      <UploadAdmissionAttachment
        setIsLoading={setIsLoadingFile}
        organizationId={extraProps?.organizationId}
        companyId={extraProps?.companyId}
        draftId={extraProps?.draftId}
        allowedTypes={[
          extraProps?.documentType as AdmissionDraftAttachmentType,
        ]}
        title={field.label.textContent}
        description={field.label.subtextContent}
        required={false}
        key={field.input.props.form}
        formStep="custom_fields"
        isLoading={isLoadingFile}
        disabled={false}
      />
    </FieldWrapper>
  );
}

FieldDocument.uiType = NEW_UI_TYPE.DOCUMENT;
FieldDocument.canRender = (arg: TFieldRenderProps) => {
  return 'input' in arg && arg.uiType === NEW_UI_TYPE.DOCUMENT;
};
