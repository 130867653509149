import { useEffect, useState } from 'react';

import JSONEditor from 'jsoneditor';

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  fetchUpdateReportExtraDefinitionMetadata,
  useGetReportExtraDefinitionMetadata,
} from '@octopus/api';

type ReconciliacaoContabilRuleset = {
  rules: Array<{
    section: string;
    name: string;
    formula: string;
  }>;
  selectTokens: string[];
};

type Data = {
  rulesets: Array<{
    name: string;
    ruleset: ReconciliacaoContabilRuleset;
  }>;
};

const templatedExtraDefinitionMetadata: Data = {
  rulesets: [
    {
      name: 'Regras Padrão',
      ruleset: {
        rules: [
          {
            formula: 'credito_aaaa/34134 - rubrica.1.eSocial1000',
            name: 'Líquido 01',
            section: 'Líquido',
          },
          {
            formula: 'debito_aaaa/6565 - rubrica.1.eSocial1000',
            name: 'Líquido 02',
            section: 'Líquido',
          },
          {
            formula:
              'provisions.ferias/ferias.adjustments + provisions.decimoTerceiro/inssRat.adjustments + rubrica.1.eSocial5180',
            name: 'Provisões',
            section: 'Líquido',
          },
        ],
        selectTokens: ['amount'],
      },
    },
  ],
};

function isExpressionValid(expression: string): boolean {
  const stack = [];
  const opening = '(';
  const closing = ')';

  for (let i = 0; i < expression.length; i++) {
    if (expression[i] === opening) {
      stack.push(opening);
    } else if (expression[i] === closing) {
      if (stack.length === 0) {
        return false;
      }
      stack.pop();
    }
  }

  return stack.length === 0;
}

function Page({ organizationId }: { organizationId: string }) {
  const { data, isLoading, isSuccess, error } =
    useGetReportExtraDefinitionMetadata({
      pathParams: {
        organizationId,
        reportId: 'br:reconciliacaoContabil',
      },
    });

  const [putError, setPutError] = useState('');
  const [extraDefinitionMetadata, setExtraDefinitionMetadata] =
    useState<Data>(undefined);

  useEffect(() => {
    if (isSuccess) {
      if (data && 'rulesets' in data) {
        setExtraDefinitionMetadata(data as Data);
      } else {
        setExtraDefinitionMetadata(templatedExtraDefinitionMetadata);
      }
    }
  }, [isSuccess, data]);

  if (isLoading || !extraDefinitionMetadata) {
    return <div>Loading...</div>;
  }

  if (!isSuccess) {
    return (
      <div>
        <div>Erro ao carregar dados</div>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </div>
    );
  }

  return (
    <Box p={4}>
      <Box pb={4}>
        <Typography variant="h1">Regras de Reconciliação Contábil</Typography>
      </Box>
      {extraDefinitionMetadata.rulesets.map((ruleset) => (
        <Box
          key={ruleset.name}
          mb={2}
          p={2}
          border="1px solid #ccc"
          borderRadius={2}
          display="flex"
          flexDirection="column"
          gap={4}
        >
          <Typography variant="h2">{ruleset.name}</Typography>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Seção</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Fórmula</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ruleset.ruleset.rules.map((rule, index) => (
                  <TableRow key={index}>
                    <TableCell>{rule.section}</TableCell>
                    <TableCell>{rule.name}</TableCell>
                    <TableCell
                      sx={{
                        color: isExpressionValid(rule.formula)
                          ? 'green'
                          : 'red',
                      }}
                    >
                      {rule.formula}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box>
            <Typography variant="h3">Tokens Selecionados</Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Token</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ruleset.ruleset.selectTokens.map((token, index) => (
                    <TableRow key={index}>
                      <TableCell>{token}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      ))}

      <Box
        pt={4}
        sx={{
          height: '600px',
        }}
      >
        <Editor
          extraDefinitionMetadata={extraDefinitionMetadata}
          setExtraDefinitionMetadata={setExtraDefinitionMetadata}
        />
      </Box>

      <pre>{putError}</pre>

      <Box pt={4}>
        <Button
          onClick={() => {
            fetchUpdateReportExtraDefinitionMetadata({
              body: extraDefinitionMetadata,
              pathParams: {
                organizationId,
                reportId: 'br:reconciliacaoContabil',
              },
            })
              .then(() => {
                window.location.reload();
              })
              .catch((error) => {
                setPutError(JSON.stringify(error, null, 2));
              });
          }}
        >
          Salvar
        </Button>
      </Box>
    </Box>
  );
}

let loaded = false;
let editor: JSONEditor | null = null;

function Editor({
  extraDefinitionMetadata,
  setExtraDefinitionMetadata,
}: {
  extraDefinitionMetadata: Data;
  setExtraDefinitionMetadata: (data: Data) => void;
}) {
  useEffect(() => {
    if (loaded) {
      return () => {
        editor?.destroy();
        loaded = false;
      };
    }

    const container = document.getElementById('jsoneditor');
    editor = new JSONEditor(container, {
      mode: 'code',
      onChange: () => {
        setExtraDefinitionMetadata(editor.get());
      },
    });

    editor.set(extraDefinitionMetadata);

    loaded = true;

    return () => {
      editor.destroy();
      loaded = false;
    };
  }, []);

  return (
    <div
      id="jsoneditor"
      style={{
        width: '100%',
        height: '600px',
      }}
    ></div>
  );
}

export default Page;
