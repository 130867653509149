import { cnpj, cpf } from 'cpf-cnpj-validator';

import {
  AdmissionDraftContractBRPjEntry,
  AdmissionDraftContractBRPjUpdate,
  AdmissionDraftEntry,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftUpdateInput,
  BREstados,
  BRPaises,
  BRTipoLogradouro,
  ContractBRPjEntryPessoa,
} from '@octopus/api';
import { banksList } from '@octopus/contract-types';
import { getEstadoByMunicipioCode } from '@octopus/esocial/mapper';
import {
  formatCEP,
  formatCNPJ,
  formatCPF,
  getOnlyDigits,
} from '@octopus/formatters';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import {
  PixKeyType,
  parsePixKey,
} from '../../../../../../modules/payrolls/parser';
import { AdmissionFormState } from '../../types';

const getFormStateFromEntry = ({
  admissionDraftEntry,
}: {
  admissionDraftEntry: AdmissionDraftEntry;
}): AdmissionFormState => {
  if (!admissionDraftEntry) {
    return {};
  }

  const { pagamento, empresa } =
    admissionDraftEntry.br as AdmissionDraftContractBRPjEntry;

  const pessoa = admissionDraftEntry.person?.br
    ?.pessoa as ContractBRPjEntryPessoa;

  return {
    tipoPix: pagamento?.tipoPix || parsePixKey(pagamento?.chavePix),
    chavePix: pagamento?.chavePix,
    codigoBanco: pagamento?.codigoBanco,
    agencia: pagamento?.agencia,
    conta: pagamento?.conta,

    nome: pessoa?.nmTrab || admissionDraftEntry.userData?.name,
    nomeSocial: pessoa?.nmSoc,
    cpf: formatCPF(pessoa?.cpfTrab),

    razaoSocial: empresa?.razaoSocial,
    cnpjEmpresa: formatCNPJ(empresa?.cnpj),
    cnae: empresa?.cnae,
    enquadramentoTributario: empresa?.enquadramentoTributario,
    empresa: {
      endereco: {
        cep: formatCEP(empresa?.endereco?.cep),
        tpLograd: empresa?.endereco?.tpLograd,
        dscLograd: empresa?.endereco?.dscLograd,
        nrLograd: empresa?.endereco?.nrLograd,
        complemento: empresa?.endereco?.complemento,
        bairro: empresa?.endereco?.bairro,
        uf: {
          defaultValue: empresa?.endereco?.uf,
          defaultCodMunic: empresa?.endereco?.codMunic,
        },
      },
    },
  };
};

const getUpdateInputFromFormState = ({
  formState,
  currentStepName,
  body,
}: {
  formState: AdmissionFormState;
  currentStepName: AdmissionDraftInputFormStepEnum;
  body: AdmissionDraftUpdateInput;
}): AdmissionDraftUpdateInput => {
  switch (currentStepName) {
    case admissionDraftFormSteps.dados_empresa: {
      const { razaoSocial, cnpjEmpresa, cnae, enquadramentoTributario } =
        formState;
      const codMunic = formState.uf.codMunic;
      const uf = getEstadoByMunicipioCode(codMunic);

      body.br = {
        empresa: {
          razaoSocial,
          cnpj: cnpj.strip(cnpjEmpresa),
          cnae,
          enquadramentoTributario,
          endereco: {
            tipo: 'brasil',
            tpLograd: formState.tpLograd as BRTipoLogradouro,
            dscLograd: formState.dscLograd,
            nrLograd: formState.nrLograd,
            ...(formState.complemento && {
              complemento: formState.complemento,
            }),
            bairro: formState.bairro,
            cep: getOnlyDigits(formState.cep?.toString()),
            codMunic: Number(codMunic),
            uf: uf as BREstados,
          },
        },
      } as AdmissionDraftContractBRPjUpdate;
      break;
    }
    case admissionDraftFormSteps.dados_bancarios: {
      const nomeBanco = formState.codigoBanco
        ? banksList[formState.codigoBanco]
        : null;

      const [tipoPix, chavePix] =
        Object.entries(formState.tipoPix ?? {})?.[0] || [];
      const hasChavePix = tipoPix && tipoPix !== 'chave_pix_none';

      body.br = {
        pagamento: {
          ...(hasChavePix && { tipoPix: tipoPix as PixKeyType }),
          ...(hasChavePix && chavePix && { chavePix: chavePix as string }),
          ...(formState.agencia && { agencia: formState.agencia }),
          ...(formState.codigoBanco && { codigoBanco: formState.codigoBanco }),
          ...(formState.conta && { conta: formState.conta }),
          ...(nomeBanco && { nomeBanco }),
        },
      } as AdmissionDraftContractBRPjUpdate;
      break;
    }
    case admissionDraftFormSteps.dados_pessoais: {
      const nmSoc = formState.hasNomeSocial?.nomeSocial;
      const nmSocHidden = formState.hasNomeSocial?.nomeSocialHidden;

      body.person = {
        countryCode: 'BR',
        br: {
          pessoa: {
            nmTrab: formState.nome,
            ...(nmSoc && { nmSoc }),
            ...(nmSocHidden && { nmSoc: null }),
            cpfTrab: cpf.strip(formState.cpf),
          },
          nascimento: {
            dtNascto: formState.dtNascto,
            paisNac: Number(formState.nacionalidade) as BRPaises,
            paisNascto: Number(formState.paisNascto) as BRPaises,
          },
        },
      };
      break;
    }
    default:
      return null;
  }

  return body;
};

export const pjMapper = { getUpdateInputFromFormState, getFormStateFromEntry };
