import React, { useState } from 'react';

import dayjs from 'dayjs';

import { Box, Container, Dialog, Skeleton } from '@mui/material';

import TakoProgress from '../TakoProgress';

import { MultiEditReview } from './MultiEditReview';
import { MultiEditStatus } from './MultiEditStatus';
import {
  MultiEditExecutor,
  MultiEditItemsProvider,
  MultiEditLoadingParams,
  MultiEditResult,
  MultiEditReviewParams,
  MultiEditStatusParams,
} from './types';

export type MultiEditDialogProps<Item extends { id: string }, NewParameters> = {
  open: boolean;
  onCancel: () => void;
  onSuccess: (showSnackbar: boolean) => void;
  newParameters: NewParameters;
  itemsProvider: MultiEditItemsProvider<Item>;
  loadingParams?: MultiEditLoadingParams;
  reviewParams: MultiEditReviewParams<Item, NewParameters>;
  execute: MultiEditExecutor<Item, NewParameters>;
  statusParams: MultiEditStatusParams<Item, NewParameters>;
};

export function MultiEditDialog<Item extends { id: string }, NewParameters>({
  open,
  onCancel,
  onSuccess,
  newParameters,
  itemsProvider,
  loadingParams,
  reviewParams,
  execute,
  statusParams,
}: MultiEditDialogProps<Item, NewParameters>) {
  const { isLoading, items } = itemsProvider();
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullScreen={true}
      PaperProps={{ sx: { borderRadius: 0 } }}
    >
      <Container maxWidth="md">
        <Box pt={11} px={3} height="100vh" boxSizing="border-box">
          {isLoading || !items ? (
            <Skeleton variant="rounded" height="70vh" />
          ) : (
            <MultiEditDialogContent
              newParameters={newParameters}
              items={items}
              onCancel={onCancel}
              onSuccess={onSuccess}
              loadingParams={loadingParams}
              reviewParams={reviewParams}
              execute={execute}
              statusParams={statusParams}
            />
          )}
        </Box>
      </Container>
    </Dialog>
  );
}

function MultiEditDialogContent<Item extends { id: string }, NewParameters>({
  newParameters,
  items,
  onCancel,
  onSuccess,
  loadingParams,
  reviewParams,
  execute,
  statusParams,
}: {
  newParameters: NewParameters;
  items: Item[];
  onCancel: () => void;
  onSuccess: (showSnackbar: boolean) => void;
  loadingParams: MultiEditLoadingParams;
  reviewParams: MultiEditReviewParams<Item, NewParameters>;
  execute: MultiEditExecutor<Item, NewParameters>;
  statusParams: MultiEditStatusParams<Item, NewParameters>;
}) {
  const [effectiveDate, setEffectiveDate] = useState(
    dayjs().format('YYYY-MM-DD'),
  );
  const [confirmed, setConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<MultiEditResult<Item>[] | undefined>(
    undefined,
  );
  const [executedChanges, setExecutedChanges] = useState(false);
  const [progress, setProgress] = useState<number>(0);

  const incrementProgress = () => setProgress((p) => ++p);

  if (!isLoading && !confirmed) {
    return (
      <MultiEditReview
        newParameters={newParameters}
        items={items}
        effectiveDate={effectiveDate}
        setEffectiveDate={setEffectiveDate}
        params={reviewParams}
        onConfirm={async () => {
          setConfirmed(true);
          setIsLoading(true);
          const results = await execute(
            items,
            newParameters,
            effectiveDate,
            incrementProgress,
          );
          setData(results);
          incrementProgress();
          setTimeout(() => {
            if (!results.some((r) => !r.success)) {
              onSuccess(true);
            } else {
              setExecutedChanges(true);
            }
          }, 300);
        }}
        onCancel={onCancel}
      />
    );
  }
  if ((confirmed && !executedChanges) || !data) {
    return (
      <MultiEditLoading
        current={progress}
        total={items.length + 1}
        loadingProps={loadingParams}
      />
    );
  }

  return (
    <MultiEditStatus
      newParameters={newParameters}
      results={data}
      effectiveDate={effectiveDate}
      onConfirm={() => onSuccess(false)}
      params={statusParams}
    />
  );
}

function MultiEditLoading({
  current,
  total,
  loadingProps,
}: {
  current: number;
  total: number;
  loadingProps?: MultiEditLoadingParams;
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      position="relative"
      top="-100px"
    >
      <TakoProgress
        width="340px"
        height="284px"
        progress={{ current, total }}
        label={{
          phrases: loadingProps?.phrases || [
            'Consultando permissões para mudança',
            'Verificando novos valores',
            'Executando mudanças',
            'Criando a fonte da verdade',
            'Executando mudanças',
            'Concluindo mudanças',
            'Lutando contra a correnteza',
            'Colocando tinta na caneta',
            'Lutando contra piratas',
            'Desviando do Kraken',
            'Mergulhando para águas profundas',
          ],
          cycleTimeInMs: 3000,
        }}
      />
    </Box>
  );
}
