import React from 'react';

import { IconTrash } from '@tabler/icons-react';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import {
  VacationsConfigurationApprovalConfiguration,
  VacationsScheduleApprovalRole,
} from '@octopus/api';
import { Button } from '@octopus/ui/design-system';

/**
 * Recebe:
 *  - config: { steps: { roles: VacationsScheduleApprovalRole[] }[] }
 *  - onChange: callback que recebe o OBJETO atualizado
 */
export function VacationsApprovalsConfiguration({
  config,
  onChange,
}: {
  config: VacationsConfigurationApprovalConfiguration;
  onChange: (updated: VacationsConfigurationApprovalConfiguration) => void;
}) {
  // Se não tiver "steps", inicializa para array vazio:
  const steps = config?.steps ?? [];

  /** Adiciona uma nova etapa (com roles vazio) */
  const addStep = () => {
    onChange({
      ...config,
      steps: [...steps, { roles: [] }],
    });
  };

  /** Remove a etapa de índice "stepIndex" */
  const removeStep = (stepIndex: number) => {
    onChange({
      ...config,
      steps: steps.filter((_, i) => i !== stepIndex),
    });
  };

  /** Adiciona uma nova role (padrão 'membership:owner') no step de índice "stepIndex" */
  const addRole = (stepIndex: number) => {
    const newSteps = [...steps];
    newSteps[stepIndex] = {
      ...newSteps[stepIndex],
      roles: [
        ...newSteps[stepIndex].roles,
        'membership:owner' as VacationsScheduleApprovalRole,
      ],
    };
    onChange({ ...config, steps: newSteps });
  };

  /** Remove uma role específica no step */
  const removeRole = (stepIndex: number, roleIndex: number) => {
    const newSteps = [...steps];
    newSteps[stepIndex] = {
      ...newSteps[stepIndex],
      roles: newSteps[stepIndex].roles.filter((_, i) => i !== roleIndex),
    };
    onChange({ ...config, steps: newSteps });
  };

  /** Atualiza o valor de uma role específica */
  const changeRoleValue = (
    stepIndex: number,
    roleIndex: number,
    newValue: string,
  ) => {
    const newSteps = [...steps];
    newSteps[stepIndex] = {
      ...newSteps[stepIndex],
      roles: newSteps[stepIndex].roles.map((role, i) =>
        i === roleIndex ? (newValue as VacationsScheduleApprovalRole) : role,
      ),
    };
    onChange({ ...config, steps: newSteps });
  };

  /**
   * Função de validação local: se step.roles estiver vazio, consideramos erro.
   * Retornamos uma string com a mensagem ou null se estiver tudo certo.
   */
  const validateStep = (
    roles: VacationsScheduleApprovalRole[],
  ): string | null => {
    if (!roles || roles.length === 0) {
      return 'É preciso ter pelo menos um aprovador nessa etapa.';
    }
    return null;
  };

  return (
    <Accordion
      className="borderless"
      defaultExpanded={false}
      sx={{
        bgcolor: '#F8F8F8',
        borderRadius: 1,
        border: 'none',
        width: '100%',
        height: '100%',
        paddingX: 2,
        py: 1,
        boxSizing: 'border-box',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3-content"
        id="panel3-header"
      >
        <Box display="flex">
          <Typography variant="h3">Aprovadores do pedido de férias</Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box pt={2}>
          {steps.map((step, stepIndex) => {
            // Valida este step
            const errorMessage = validateStep(step.roles);

            return (
              <Box
                key={stepIndex}
                border="1px solid #ccc"
                borderRadius={2}
                padding={2}
                marginBottom={2}
              >
                {/* Cabeçalho do Step */}
                <Box display="flex" justifyContent="space-between" pb={3}>
                  <Typography variant="h6" gutterBottom>
                    Etapa {stepIndex + 1}
                  </Typography>
                  <Box display="flex" justifyContent="flex-end">
                    <IconButton
                      onClick={() => removeStep(stepIndex)}
                      sx={{
                        border: '1px solid #EDEDED',
                        borderRadius: 1,
                        boxShadow:
                          '0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 16px 0px rgba(0, 0, 0, 0.03)',
                      }}
                    >
                      <CloseRoundedIcon
                        color={'action'}
                        width={'16px'}
                        height={'16px'}
                      />
                    </IconButton>
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display={'flex'} gap={4}>
                    {/* Lista as roles */}
                    {step.roles.map((role, roleIndex) => (
                      <Box
                        key={roleIndex}
                        display="flex"
                        alignItems="center"
                        gap={2}
                        width={'200px'}
                      >
                        {/* Exemplo de Select com FormControl para exibir erro se quiser */}
                        <FormControl
                          fullWidth
                          error={Boolean(errorMessage)} // Se quiser destacar o Select em vermelho
                          size="small"
                        >
                          <Select
                            margin="dense"
                            value={role}
                            fullWidth
                            onChange={(e) =>
                              changeRoleValue(
                                stepIndex,
                                roleIndex,
                                e.target.value,
                              )
                            }
                          >
                            {[
                              { value: 'membership:owner', label: 'Admin' },
                              { value: 'manager', label: 'Gestor' },
                              // Adicione outras roles se precisar
                            ].map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>

                          {/* Exemplo: exibir a mesma msg de erro se o step estiver inválido */}
                          {errorMessage && (
                            <FormHelperText>{errorMessage}</FormHelperText>
                          )}
                        </FormControl>

                        {/* Botão de remover role */}
                        <Button
                          variantSemantic="secondary"
                          variantLayout="tiny"
                          onClick={() => removeRole(stepIndex, roleIndex)}
                        >
                          <IconTrash
                            width={20}
                            height={20}
                            style={{ padding: 1 }}
                          />
                        </Button>
                      </Box>
                    ))}
                  </Box>

                  {/* Botão de adicionar role */}
                  <Button
                    variantSemantic="secondary"
                    variantLayout="small"
                    sx={{
                      width: '150px',
                      height: '50px',
                    }}
                    onClick={() => addRole(stepIndex)}
                  >
                    Adicionar aprovador
                  </Button>

                  {/* Se quiser mostrar erro (caso não haja roles) abaixo do bloco */}
                  {errorMessage && (
                    <Typography variant="body2" color="error">
                      {errorMessage}
                    </Typography>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box pt={2} display={'flex'} justifyContent={'flex-end'}>
          <Button
            sx={{
              height: '60px',
            }}
            variantSemantic="primary"
            onClick={addStep}
          >
            Adicionar nova etapa de aprovação
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
