import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { SidebarProps } from '../types';

import { BottombarAppSelector } from './appSelector';

export function Bottombar({
  membership,
  membershipOptions,
  apps,
}: SidebarProps) {
  const location = useLocation();
  if (
    location.pathname.match(/\/new/) ||
    location.pathname.match('/vacations/.*/request')
  ) {
    return null;
  }

  return (
    <Box>
      <Box
        display="block"
        position="fixed"
        bottom="0px"
        height="56px"
        width="100%"
        my={1.5}
        zIndex={1000}
      >
        <Box display="flex" width="100%" height="100%" justifyContent="center">
          <BottombarAppSelector
            membership={membership}
            membershipOptions={membershipOptions}
            apps={apps}
          />
        </Box>
      </Box>
    </Box>
  );
}
