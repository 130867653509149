import { Box, Button, styled } from '@mui/material';

export function TimesheetValidationActions({
  onCancel,
  onConfirm,
  children,
  showConfirmButton = false,
  confirmButtonText = 'Inserir',
  cancelButtonText = 'Cancelar',
  icon,
}: {
  showConfirmButton?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onCancel: () => void;
  onConfirm?: () => void;
  children: React.ReactNode;
  icon: React.ReactNode;
}) {
  return (
    <Box
      data-testid="timesheet-validation-actions"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box
        data-testid="feedback-result-header"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          maxWidth: (theme) => theme.spacing(55),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {icon}
        </Box>
        <Box
          data-testid="feedback-result-header-content"
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: (theme) => theme.spacing(2),
          }}
        >
          {children}
        </Box>
      </Box>
      <Box
        data-testid="feedback-result-header-actions"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          paddingTop: (theme) => theme.spacing(5),
          paddingBottom: (theme) => theme.spacing(3.5),
          gap: (theme) => theme.spacing(1.5),
        }}
      >
        <TimesheetActionButton color="secondary" onClick={onCancel}>
          {cancelButtonText}
        </TimesheetActionButton>
        {showConfirmButton && (
          <TimesheetActionButton color="primary" onClick={onConfirm}>
            {confirmButtonText}
          </TimesheetActionButton>
        )}
      </Box>
    </Box>
  );
}

const TimesheetActionButton = styled(Button)(({ theme }) => ({
  maxWidth: theme.spacing(25),
  height: theme.spacing(5),
  width: '100%',
  fontWeight: theme.typography.fontWeightMedium,
}));
