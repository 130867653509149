import { useContext } from 'react';

import { makeElementListFilter } from '@octopus/ui/data-grid';

import { AppContext } from '../../../../app/context';
import {
  CompanySummaryContext,
  DepartmentContext,
  LegalEntityContext,
} from '../../../types';

export const useCompanyFilter = () => {
  const { appContext } = useContext(AppContext);
  const { company } = appContext;
  const companies = company?.companies ?? [];

  const labels: Record<string, string> = {};
  companies.forEach((company: CompanySummaryContext) => {
    labels[company.id] = company.name;
  });

  return makeElementListFilter({
    label: 'Empresa',
    propertyToFilter: 'companyId',
    elements: companies.map((company: CompanySummaryContext) => company.id),
    labels,
  });
};

export const useLegalEntityFilter = () => {
  const { appContext } = useContext(AppContext);
  const { company } = appContext;
  const legalEntities = company?.legalEntities ?? [];

  const labels: Record<string, string> = {};
  legalEntities.forEach((legalEntity: LegalEntityContext) => {
    labels[legalEntity.id] = legalEntity.name;
  });

  return makeElementListFilter({
    label: 'Empregador',
    propertyToFilter: 'legalEntityId.keyword',
    elements: legalEntities.map((legalEntity) => legalEntity.id),
    labels,
  });
};

export const useDepartmentFilter = () => {
  const { appContext } = useContext(AppContext);
  const { company } = appContext;
  const departments = company?.departments ?? [];

  const labels: Record<string, string> = {};
  departments.forEach((department: DepartmentContext) => {
    labels[department.id] = department.name;
  });

  return makeElementListFilter({
    label: 'Departamento',
    propertyToFilter: 'departmentId',
    elements: departments.map((department) => department.id),
    labels,
  });
};
