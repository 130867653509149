export function flattenObject(
  obj: Record<string, unknown>,
  parentKey?: string,
): Record<string, string | string[] | null> {
  const result: Record<string, string | string[] | null> = {};
  const stack: Array<{ obj: Record<string, unknown>; parentKey?: string }> = [
    { obj, parentKey },
  ];
  while (stack.length > 0) {
    const { obj, parentKey } = stack.pop()!;
    for (const key in obj) {
      const newKey = parentKey ? `${parentKey}/${key}` : key;
      if (
        typeof obj[key] === 'object' &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        stack.push({
          obj: obj[key] as Record<string, unknown>,
          parentKey: newKey,
        });
      } else if (!Array.isArray(obj[key])) {
        result[newKey] = obj[key] === null ? null : `${obj[key]}`;
      } else {
        result[newKey] = obj[key].map((v) => `${v}`);
      }
    }
  }
  return result;
}
