import { ReactNode } from 'react';

import { Typography } from '@mui/material';

export type InfoTagsVariants =
  | 'warning'
  | 'success'
  | 'info'
  | 'default'
  | 'error';

const statusMap = {
  warning: {
    backgroundColor: '#FEF7F1',
    color: '#93500B',
  },
  success: {
    backgroundColor: '#F4FBF6',
    color: '#066F4F',
  },
  info: {
    backgroundColor: '#F0F6FF',
    color: '#0058DB',
  },
  default: {
    backgroundColor: '#F7F7F8',
    color: '#616161',
  },
  error: {
    backgroundColor: '#FDF2F4',
    color: '#BC1A41',
  },
};

export const InfoTags = ({
  text,
  variant,
}: {
  text: string | ReactNode;
  variant: InfoTagsVariants;
}) => {
  const { color, backgroundColor } = statusMap[variant];
  return (
    <Typography
      sx={{
        backgroundColor,
        color,
      }}
      px={1}
      py={0.5}
      borderRadius={0.5}
      fontSize={12}
      fontWeight={500}
      variant="body1"
      width="fit-content"
      height="fit-content"
      whiteSpace="nowrap"
    >
      {text}
    </Typography>
  );
};
