import { z } from 'zod';

import { AdmissionDraftInputFormStepEnum } from '@octopus/api';
import { banksList } from '@octopus/contract-types';
import { IFormDefinition } from '@octopus/libs/forms';

import { pixKeyTypes } from '../../../modules/payrolls/parser';
import { OrganizationContext } from '../../../modules/types';
import { DocumentSectionsType } from '../new/documents/types';
import { FormKey } from '../new/form/types';

type FieldConfig =
  | {
      title: string;
      type: 'document' | 'text';
    }
  | {
      title: string;
      type: 'select';
      options?: Record<string, string>;
    };

export const fieldsWithConfigs: Partial<
  Record<
    AdmissionDraftInputFormStepEnum,
    Partial<Record<FormKey | AdmissionDraftInputFormStepEnum, FieldConfig>>
  >
> = {
  documentos: {
    formacao_academica: { title: 'Certificado de formação', type: 'document' },
  },
  dados_bancarios: {
    tipoPix: { title: 'Pix', type: 'select', options: pixKeyTypes },
    codigoBanco: {
      title: 'Código do Banco',
      type: 'select',
      options: banksList,
    },
    agencia: {
      title: 'Agência',
      type: 'text',
    },
    conta: {
      title: 'Conta',
      type: 'text',
    },
  },
};

export const applyOrganizationSettingsToDocuments = ({
  organizationContext,
  documentSections,
  section,
}: {
  organizationContext: OrganizationContext;
  documentSections: DocumentSectionsType;
  section: AdmissionDraftInputFormStepEnum;
}) => {
  const { admission: admissionSettings } = organizationContext?.settings || {};
  const documentConfigs = Object.values(
    fieldsWithConfigs[section] || {},
  ).filter((field) => field.type === 'document');

  if (
    !admissionSettings ||
    !admissionSettings.fields ||
    !documentConfigs.length
  )
    return documentSections;

  return Object.entries(documentSections).reduce((acc, [key, document]) => {
    const documentConfig = fieldsWithConfigs[section][key as FormKey];
    const documentSettings = admissionSettings.fields[key as FormKey];
    const required =
      documentConfig &&
      documentSettings?.section === section &&
      documentSettings?.required
        ? { required: true }
        : {};

    return {
      ...acc,
      [key]: {
        ...document,
        ...required,
      },
    };
  }, {});
};

export const applyOrganizationSettingsToFields = ({
  organizationContext,
  fields,
  section,
}: {
  organizationContext: OrganizationContext;
  fields: IFormDefinition;
  section: AdmissionDraftInputFormStepEnum;
}): IFormDefinition => {
  const { admission: admissionSettings } = organizationContext?.settings || {};

  if (
    !admissionSettings ||
    !admissionSettings.fields ||
    Object.keys(admissionSettings.fields).length === 0
  ) {
    return fields;
  }

  return fields
    .filter((field) => {
      const fieldSettings = admissionSettings.fields[field.name];
      const fieldConfig = fieldsWithConfigs[section]?.[field.name as FormKey];

      return fieldConfig && fieldSettings ? !fieldSettings.hidden : true;
    })
    .map((field) => {
      const fieldSettings = admissionSettings.fields[field.name];
      const fieldConfig = fieldsWithConfigs[section]?.[field.name as FormKey];

      if (!fieldSettings || !fieldConfig || fieldConfig.type === 'document')
        return field;

      let type = 'type' in field ? field.type : null;
      if (type && type instanceof z.ZodOptional && fieldSettings.required) {
        type = type.refine((item) => item != null, {
          params: {
            i18n: { key: 'form_invalid_required_field' },
          },
        });
      }
      const options =
        'options' in field && fieldSettings.allowedValues
          ? field.options.filter((option) =>
              fieldSettings.allowedValues.find((i) => i === option.value),
            )
          : null;

      return {
        ...field,
        ...(options && { options }),
        ...(type && { type }),
      };
    });
};
