import { useCallback, useEffect, useState } from 'react';

import { FormMetadata } from '@conform-to/react';
import { IconFile, IconTrash } from '@tabler/icons-react';
import { v4 } from 'uuid';

import { Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button as ButtonMUI,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { fetchDeleteAdmissionDraftAttachment } from '@octopus/api';
import { Parentesco } from '@octopus/esocial/mapper';
import { Button } from '@octopus/ui/design-system';

import { Label } from '../../../../modules/form/Field/commons/Label';
import { TPayloadForm } from '../../../../modules/form/types';
import { useSnackbar } from '../../../../modules/hooks/useSnackbar';
import { DeleteDependentDialog } from '../../components/DeleteDependentDialog';
import { AdmissionFormState, FormStepDefinition } from '../form/types';

import { Dependent, extractParentescoType } from './form/types';
import { NewAdmissionDependentForm } from './NewAdmissionDependentForm';

type Props = {
  disabled: boolean;
  isLoading: boolean;
  formState: AdmissionFormState;
  stepDefinition: FormStepDefinition;
  onSubmit: ({ extraData }: { extraData?: Record<string, any> }) => void;
  goToPreviousStep: () => void;
  organizationId: string;
  draftId: string;
  submitLabel: string;
};

const emptyDependent: Dependent = {
  nome: '',
  parentesco: {
    type: '',
    descrDep: '',
  },
  cpf: '',
  sexo: '',
  dtNascto: '',
  incTrab: 'false',
  calculos: [],
  file: null,
};

export const AddNewDependent = ({
  disabled,
  isLoading,
  formState,
  stepDefinition,
  onSubmit,
  goToPreviousStep,
  organizationId,
  draftId,
  submitLabel,
}: Props) => {
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [dependentes, setDependentes] = useState<Dependent[]>(
    formState?.dependentes,
  );
  const [showDeleteDependentDialog, setShowDeleteDependentDialog] =
    useState<boolean>();
  const [dependentToBeDeleted, setDependentToBeDeleted] = useState<number>();
  const [isEditing, setIsEditing] = useState<boolean[]>([]);
  const [hasDependentes, setHasDependentes] = useState<boolean>();
  const [formByDependent, setFormByDependent] = useState<
    FormMetadata<
      {
        [k: string]: any;
      },
      string[]
    >[]
  >([]);

  const formHasError = (form: TPayloadForm) =>
    form != null && (form.errors?.length > 0 || !form.valid);

  const submitDependents = () => {
    let isValid = true;
    const depList = dependentes ? [...dependentes] : [];
    formByDependent.forEach((form, idx) => {
      if (!form) {
        return;
      }

      form.validate();
      depList[idx] = { ...depList[idx], ...(form.value as Dependent) };
      if (formHasError(form)) {
        isValid = false;
      }
    });

    let errorMessage = '';
    const dependentMissingFile = depList.find((dep) => !dep.file);
    const distinctCPFs = new Set(depList.map((dep) => dep.cpf));

    if (distinctCPFs.size < depList.length) {
      errorMessage =
        'Não é possível criar dependentes com o mesmo CPF, verifique o CPF de cada dependente';
    } else if (dependentMissingFile) {
      errorMessage = `${dependentMissingFile.nome}: Documento é obrigatório`;
    } else if (!isValid) {
      errorMessage = 'Verifique os campos com erro e tente novamente';
    }

    if (errorMessage) {
      showSnackbar({
        isOpen: true,
        variant: 'error',
        Message: errorMessage,
        StartAdornment: <Cancel />,
        autoHideDuration: 5000,
        hasCloseAction: true,
      });
    }

    setDependentes(depList);
    if (!errorMessage) {
      onSubmit({ extraData: { dependentes: depList } });
    }
  };

  const toggleDependent = useCallback(
    (dependentIdx: number) => {
      const newIsEditing = [...isEditing];
      const newValue = !(isEditing[dependentIdx] || false);
      newIsEditing[dependentIdx] = newValue;
      setIsEditing(newIsEditing);
    },
    [isEditing],
  );

  const addNewDependent = useCallback(() => {
    const dependentIdx = dependentes?.length ?? 0;

    const depList = dependentes || [];
    depList.push({ ...emptyDependent, id: v4() });

    setDependentes(depList);
    const newIsEditing = [...isEditing];
    newIsEditing[dependentIdx] = true;
    setIsEditing(newIsEditing);
  }, [dependentes, isEditing]);

  const editDependent = (dependentIdx: number) => {
    toggleDependent(dependentIdx);
  };

  const updateDependent = (dependent: Dependent, dependentIdx: number) => {
    const depList = dependentes || [];
    depList[dependentIdx] = dependent;

    setDependentes(depList);
  };

  const deleteDependent = () => {
    const dependentIdx = dependentToBeDeleted;
    const removed = dependentes.splice(dependentIdx, 1);
    isEditing.splice(dependentIdx, 1);
    formByDependent.splice(dependentIdx, 1);

    const fileId = removed?.[0]?.file?.id;

    if (fileId) {
      fetchDeleteAdmissionDraftAttachment({
        pathParams: {
          draftId,
          organizationId,
          attachmentId: fileId,
        },
      });
    }

    setDependentes(dependentes);
    setIsEditing(isEditing);
    setDependentToBeDeleted(-1);
    setHasDependentes(false);
  };

  useEffect(() => {
    if (hasDependentes && (!dependentes || dependentes.length === 0)) {
      addNewDependent();
    }
  }, [hasDependentes, dependentes, addNewDependent]);

  if (!formState || !stepDefinition) {
    return null;
  }
  const dependentesCount = dependentes?.length ?? 0;

  const defaultValue =
    hasDependentes == null
      ? { defaultValue: dependentesCount > 0 ? 'true' : 'false' }
      : { defaultValue: hasDependentes.toString() };

  const renderSelectedValue = (selected: string) => {
    if (selected == null) {
      return 'Selecione uma opção';
    }

    return selected === 'true' ? 'Sim' : 'Não';
  };

  const goBackButton = (
    <ButtonMUI
      fullWidth
      color="secondary"
      variant="outlined"
      disabled={disabled || isLoading}
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          px: 6,
          marginLeft: 'auto',
          width: 'auto',
        },
        [theme.breakpoints.down('md')]: {
          py: 1.25,
          px: 2,
          mt: 1.5,
          display: 'block',
          width: 'calc(100svw - 64px)',
        },
      })}
      onClick={goToPreviousStep}
    >
      Voltar
    </ButtonMUI>
  );

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          height: `calc(100svh - ${theme.spacing(38)})`,
          overflow: 'scroll',
          '::-webkit-scrollbar': { display: 'none' },
        },
      })}
    >
      {(!dependentes || dependentesCount <= 0) && (
        <Box>
          <Label
            field={{
              uiType: 'SELECT',
              fieldsetName: '',
              props: {
                key: 'hasDependents',
                id: 'hasDependents',
                name: 'hasDependents',
                form: '',
              },
              select: {
                props: {
                  required: true,
                  key: 'hasDependents',
                  id: 'hasDependents',
                  name: 'hasDependents',
                  form: '',
                },
                options: [],
              },
              label: {
                textContent: 'Você possui dependentes?',
                props: { htmlFor: '' },
              },
            }}
          />
          <Select
            fullWidth
            displayEmpty
            {...defaultValue}
            value={hasDependentes?.toString()}
            onChange={(e) => setHasDependentes(e.target.value === 'true')}
            renderValue={renderSelectedValue}
          >
            <MenuItem key={'sim'} value={'true'}>
              Sim
            </MenuItem>
            <MenuItem key={'nao'} value={'false'}>
              Não
            </MenuItem>
          </Select>
        </Box>
      )}
      {dependentesCount > 0 && (
        <Box display="flex" flexDirection="column" gap={2}>
          {Object.entries(dependentes).map(([key, dep], index) => (
            <Box
              key={key}
              gap={1}
              data-testid="dependente-card"
              sx={(theme) => ({
                border: '1px solid',
                borderRadius: '8px',
                borderColor: formHasError(formByDependent?.[index])
                  ? theme.palette.strokes.error
                  : theme.palette.strokes.light,
                cursor: 'pointer',
                [theme.breakpoints.up('md')]: {
                  padding: 3,
                },
                [theme.breakpoints.down('md')]: {
                  padding: 2,
                },
              })}
              onClick={() => toggleDependent(index)}
            >
              <Box display="flex" flexDirection="row">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems={'space-between'}
                >
                  <Typography variant="h4">{`${index + 1}. ${dep.nome || 'Novo Dependente'}`}</Typography>
                  <Typography
                    variant="caption"
                    mt={0.5}
                    mb={0.5}
                    flexWrap={'wrap'}
                  >
                    {Parentesco.getByCode(extractParentescoType(dep))}
                  </Typography>
                </Box>

                {isEditing[index] && (
                  <Button
                    disabled={isLoading || disabled}
                    sx={{
                      marginLeft: 'auto',
                      display: 'inline-flex',
                      alignItems: 'center',
                      border: 'none',
                    }}
                    variantSemantic="secondary"
                    variantLayout="tiny"
                    onClick={() => {
                      setDependentToBeDeleted(index);
                      setShowDeleteDependentDialog(true);
                    }}
                  >
                    <IconTrash width={20} height={20} color="#D9204C" />
                  </Button>
                )}
                {!isEditing[index] && (
                  <Button
                    disabled={isLoading || disabled}
                    sx={{
                      marginLeft: 'auto',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                    variantSemantic="highlight"
                    variantLayout="tiny"
                    onClick={() => editDependent(index)}
                  >
                    <IconFile width={20} height={20} />
                  </Button>
                )}
              </Box>

              <Box
                sx={{ display: isEditing[index] ? undefined : 'none', mt: 2 }}
                onClick={(e) => e.stopPropagation()}
              >
                <NewAdmissionDependentForm
                  disabled={disabled}
                  isLoading={isLoading}
                  formState={formState}
                  stepDefinition={stepDefinition}
                  currentDependente={dep}
                  organizationId={organizationId}
                  draftId={draftId}
                  setCurrentDependent={(dependent) =>
                    updateDependent(dependent, index)
                  }
                  setForm={(form) => {
                    if (formByDependent.length <= dependentes?.length) {
                      formByDependent[index] = form;
                    } else {
                      setFormByDependent([...formByDependent, form]);
                    }
                  }}
                  onSubmit={() => {
                    /** not needed here */
                  }}
                  formId={`dependent_${index}`}
                />
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {(hasDependentes || dependentesCount > 0) && (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            mt: 2,
          }}
        >
          <LoadingButton
            disabled={disabled || isLoading}
            sx={(theme) => ({
              py: 1.25,
              marginLeft: 'auto',
              display: 'inline-flex',
              alignItems: 'center',
              width: '100%',
              [theme.breakpoints.up('md')]: {
                px: 6,
                marginLeft: 'auto',
              },
              [theme.breakpoints.down('md')]: {
                px: 2,
                display: 'block',
                width: 'calc(100svw - 32px)',
              },
            })}
            color="secondary"
            variant="outlined"
            type="submit"
            onClick={addNewDependent}
          >
            {'+ Adicionar novo dependente'}
          </LoadingButton>
        </Box>
      )}
      <Box
        sx={(theme) => ({
          width: '100%',
          [theme.breakpoints.up('md')]: {
            display: 'flex',
            paddingTop: 3,
            gap: 2,
          },
          [theme.breakpoints.down('md')]: {
            position: 'fixed',
            bottom: 0,
            paddingBottom: 3,
            left: 0,
            background: theme.palette.background.paper,
            borderColor: 'transparent',
            border: 'none',
            px: 4,
          },
        })}
      >
        {!isMobile && goBackButton}
        <LoadingButton
          fullWidth
          color="primaryAlt"
          variant="contained"
          disabled={disabled}
          loading={isLoading}
          sx={(theme) => ({
            py: 1.25,
            [theme.breakpoints.up('md')]: {
              px: 6,
              width: 'auto',
            },
            [theme.breakpoints.down('md')]: {
              px: 2,
              display: 'block',
              width: 'calc(100svw - 64px)',
            },
          })}
          onClick={submitDependents}
          data-testid={`submit-dependentes`}
        >
          {submitLabel}
        </LoadingButton>
        {isMobile && goBackButton}
      </Box>

      <DeleteDependentDialog
        open={showDeleteDependentDialog}
        setOpen={setShowDeleteDependentDialog}
        isSmallDevice={isMobile}
        deleteDependent={deleteDependent}
      />
    </Box>
  );
};
