import { useState } from 'react';

import { ColumnInfo, RowInfo } from './types';

type InfoPopperState = {
  open: boolean;
  ref: HTMLElement | null;
};

export type ColumnInfoPopperState = InfoPopperState & {
  info: ColumnInfo | null;
};
export type ShowColumnInfo = (ref: HTMLElement, info: ColumnInfo) => void;

export type RowInfoPopperState = InfoPopperState & {
  info: RowInfo | null;
};
export type ShowRowInfo = (ref: HTMLElement, info: RowInfo) => void;

export function useInfoPoppers(): {
  columnInfoState: ColumnInfoPopperState;
  rowInfoState: RowInfoPopperState;
  showColumnInfo: ShowColumnInfo;
  showRowInfo: ShowRowInfo;
  hideInfoPoppers: () => void;
} {
  const [columnInfoState, setColumnInfoState] = useState<ColumnInfoPopperState>(
    {
      open: false,
      ref: null,
      info: null,
    },
  );
  const [rowInfoState, setRowInfoState] = useState<RowInfoPopperState>({
    open: false,
    ref: null,
    info: null,
  });

  const hideInfoPoppers = () => {
    setColumnInfoState({ open: false, ref: null, info: null });
    setRowInfoState({ open: false, ref: null, info: null });
  };

  const showColumnInfo = (ref: HTMLElement, info: ColumnInfo) => {
    hideInfoPoppers();
    setColumnInfoState({ open: true, ref, info });
  };

  const showRowInfo = (ref: HTMLElement, info: RowInfo) => {
    hideInfoPoppers();
    setRowInfoState({ open: true, ref, info });
  };

  return {
    columnInfoState,
    rowInfoState,
    hideInfoPoppers,
    showRowInfo,
    showColumnInfo,
  };
}
