import { useRef, useState } from 'react';

import { IconDotsVertical, IconUserShare } from '@tabler/icons-react';

import {
  Box,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  useTheme,
} from '@mui/material';

export type DepartmentContractActionMenuProps = {
  moveContract: () => void;
};

export function DepartmentContractActionMenu({
  moveContract,
}: DepartmentContractActionMenuProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  return (
    <Box display="flex" justifyContent="flex-end">
      <IconButton
        size="small"
        onClick={(event) => {
          event.preventDefault();
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        sx={{
          borderRadius: '8px',
          padding: '4px',
        }}
      >
        <IconDotsVertical
          style={{
            width: '20px',
            height: '20px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
        sx={{
          m: 1,
        }}
        data-testid="filters-popover"
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              setOpen(false);
              moveContract();
            }}
          >
            <Box display="flex" gap={1.5} alignItems="center" my={0.5} mx={1}>
              <IconUserShare
                size={16}
                color={theme.palette.secondary.lighter}
              />
              <Typography variant="body2">
                Mover para outro departamento
              </Typography>
            </Box>
          </MenuItem>
        </MenuList>
      </Popover>
    </Box>
  );
}
