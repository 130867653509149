import { useContext, useState } from 'react';

import { IconBuilding, IconCheck, IconChevronDown } from '@tabler/icons-react';

import { Box, List, ListItemButton, Popover, Typography } from '@mui/material';

import { AppContext } from '../../../app/context';
import { CompanySummaryContext } from '../../types';

export const SwitchCompany = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { appContext, changeCompany } = useContext(AppContext);
  const { company, currentCompany } = appContext;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <CurrentCompany company={currentCompany} handleClick={handleClick} />
      <CompanyList
        allCompanies={company?.companies}
        changeCompany={changeCompany}
        currentCompany={currentCompany}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </Box>
  );
};

export const CurrentCompany = ({
  company,
  handleClick,
}: {
  company: CompanySummaryContext;
  handleClick: (event: React.MouseEvent<any>) => void;
}) => {
  return (
    <Box
      sx={{ cursor: 'pointer' }}
      width={240}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={0.5}
      onClick={handleClick}
    >
      <Box display="flex" alignItems="center" gap={1} mr={1.5}>
        <IconBuilding size={16} style={{ color: '#25252D' }} />
        <Box display="flex" flexDirection="column">
          <Typography variant="body2" color="text.secondary" fontWeight={500}>
            {'Empresa'}
          </Typography>
          <Typography
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            variant="body2"
            color="text.primary"
            maxWidth={160}
            fontWeight={700}
            title={company?.name}
          >
            {company?.name}
          </Typography>
        </Box>
      </Box>
      <IconChevronDown size={20} className="default" />
    </Box>
  );
};

export const CompanyList = ({
  allCompanies,
  changeCompany,
  currentCompany,
  anchorEl,
  onClose,
}: {
  allCompanies: CompanySummaryContext[];
  changeCompany: (company: CompanySummaryContext) => void;
  currentCompany: CompanySummaryContext;
  anchorEl: null | HTMLElement;
  onClose: () => void;
}) => {
  return (
    <Popover
      open={!!anchorEl}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        mt: 1.5,
        borderRadius: '12px',
      }}
    >
      <List
        sx={{
          minWidth: 350,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          py: 1.5,
        }}
      >
        <Typography
          key="visualizing"
          ml={2}
          variant="body2"
          color="text.secondary"
          fontWeight={500}
        >
          {'Visualizando'}
        </Typography>
        {allCompanies?.map((company) => {
          return (
            <CompanyItem
              key={company.id}
              company={company}
              active={company?.id === currentCompany?.id}
              changeCompany={changeCompany}
            />
          );
        })}
      </List>
    </Popover>
  );
};

export const CompanyItem = ({
  company,
  active,
  changeCompany,
}: {
  company: CompanySummaryContext;
  active: boolean;
  changeCompany: (company: CompanySummaryContext) => void;
}) => {
  return (
    <ListItemButton key={company.id} onClick={() => changeCompany(company)}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '100%' }}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <IconBuilding size={20} style={{ color: '#25252D' }} />
          <Box>
            <Typography
              variant="body2"
              color="text.primary"
              width={250}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              title={company?.name}
            >
              {company?.name}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          sx={{ mr: active ? 0 : '36px' }}
        >
          {active && <IconCheck size={20} className="info" />}
        </Box>
      </Box>
    </ListItemButton>
  );
};
