/**
 * "Polyfill" for `Set.prototype.isSubsetOf` (wihtout adding to Set.prototype; must be called as a standalone function)
 *
 * @param subset The Set to check if it's contained within the other set
 * @param set The Set to check against
 * @returns whether or not `subset` is a sub set of `set`
 */
export function isSubsetOf<T>(subset: Set<T>, set: Set<T>): boolean {
  if (subset.size > set.size) return false;
  for (const item of subset) {
    if (!set.has(item)) {
      return false;
    }
  }
  return true;
}
