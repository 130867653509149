import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { setCredentialsProvider } from '@octopus/api';
import { initZod } from '@octopus/i18n';

import { envVars } from '../env.js';

import App from './app/app';
import { credentialsProvider } from './modules/credentialsProvider';
import { rum } from './utils/rum';

initZod('pt')
  .then(() => {
    console.log('Zod initialized');
  })
  .catch((error) => {
    console.error('Failed to initialize Zod', error);
  });

try {
  rum.init(envVars.rumApplicationId, envVars.identityPoolId);
} catch (error) {
  console.error('Failed to load rum', error);
}

setCredentialsProvider(credentialsProvider);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>,
);
