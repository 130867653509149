import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { fetchGetPayrollInputsSubmissionDetails } from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

import { useInterval } from '../../../../../modules/hooks/useInterval';
import { PeriodFormat } from '../../../../../modules/types';
import { RPANavigation } from '../../navigationUtils';
export type RPAInputsSubmissionProps = {
  organizationId: string;
  companyId: string;
};

export function RPAInputsSubmission({
  organizationId,
  companyId,
}: RPAInputsSubmissionProps) {
  const navigate = useNavigate();
  const { period, submissionId } = useParams<{
    period: PeriodFormat;
    submissionId: string;
  }>();

  const evaluateSubmissionStatus = async () => {
    const { status } = await fetchGetPayrollInputsSubmissionDetails({
      pathParams: {
        organizationId,
        companyId,
        periodId: period,
        payrollType: 'rpa',
        submissionId,
      },
    });
    if (status === 'success' || status === 'failure') {
      navigate(RPANavigation.homePagePath(), {
        state: { submission: status },
      });
    }
  };

  useInterval(evaluateSubmissionStatus, 3000);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={2}
        position="relative"
        top="-50px"
      >
        <Box width="150px" height="150px">
          <OctopusLoading />
        </Box>
        <Box>
          <Typography variant="h4">
            Calculando, aguarde alguns instantes...
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
