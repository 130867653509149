import { useQuery } from '@tanstack/react-query';

import { fetchSearchAllPeriodsSummaries } from '@octopus/api';
import { FilteringProps, PaginationProps } from '@octopus/ui/data-grid';

import { getActiveElementFilters, getActiveRangeFilters } from '../../../utils';
import { PeriodGroupsConfigType } from '../types';

export const useFetchPayroll = (
  organizationId: string | undefined,
  companyId: string | undefined,
  tab: PeriodGroupsConfigType,
  filteringProps: FilteringProps,
  paginationProps: PaginationProps,
) => {
  const elementFilters = getActiveElementFilters(filteringProps);
  const rangeFilters = getActiveRangeFilters(filteringProps);
  return useQuery({
    queryKey: [
      organizationId,
      companyId,
      paginationProps,
      tab,
      elementFilters,
      rangeFilters,
    ],
    queryFn: () => {
      return fetchSearchAllPeriodsSummaries({
        pathParams: {
          organizationId: organizationId || '',
          companyId: companyId || '',
        },
        body: {
          pagination: {
            page: paginationProps.page,
            size: paginationProps.rowsPerPage,
          },
          sorting: [
            {
              field: 'period',
              order: 'desc',
            },
          ],
          filtering: {
            elements: {
              status: [tab],
              active: ['true', ...(tab === 'closed' ? ['false'] : [])],
              type: [
                'monthly',
                'thirteenthFirst',
                'thirteenthSecond',
                'advance',
                'complementary',
              ],
              ...elementFilters,
            },
            ...(rangeFilters ? { ranges: rangeFilters } : {}),
          },
        },
      });
    },
    enabled: !!organizationId && !!companyId,
  });
};
