import { useEffect, useState } from 'react';

import JSONEditor from 'jsoneditor';

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  fetchUpdateReportExtraDefinitionMetadata,
  useGetReportExtraDefinitionMetadata,
} from '@octopus/api';

type LayoutArquivoContabil = {
  granularity:
    | 'porColaborador'
    | 'porCentroDeCusto'
    | 'porEmpregador'
    | 'porEmpresa'
    | 'porEmpregadorECentroDeCusto';

  columns: Array<{
    headerName?: string;
    field?: string;
  }>;

  newFields: Record<
    string,
    {
      type: 'mapper';
      sourceField: string;
      mapping: Record<string, string>;
    }
  >;

  showHeaders: boolean;

  output:
    | {
        type: 'csv';
        delimiter: string;
        extension: 'csv' | 'txt';
      }
    | {
        type: 'xlsx';
      };
};

type Data = {
  layouts: Array<{
    name: string;
    layout: LayoutArquivoContabil;
  }>;
};

const templatedExtraDefinitionMetadata: Data = {
  layouts: [
    {
      name: 'Layout Padrão',
      layout: {
        granularity: 'porEmpregadorECentroDeCusto',

        columns: [
          {
            headerName: 'Conta Contábil - Débito',
            field: '{debit_account}',
          },
          {
            headerName: 'Conta Contábil - Crédito',
            field: '{credit_account}',
          },

          {
            headerName: 'Valor do lançamento',
            field: '{amount}',
          },
        ],

        newFields: {
          codigoEmpresa: {
            type: 'mapper',
            sourceField: 'empregador_cnpj',
            mapping: {
              '00.000.000/0001-00': '295',
            },
          },
        },

        showHeaders: true,

        output: {
          type: 'xlsx',
        },
      },
    },
  ],
};

function Page({ organizationId }: { organizationId: string }) {
  const { data, isLoading, isSuccess, error } =
    useGetReportExtraDefinitionMetadata({
      pathParams: {
        organizationId,
        reportId: 'br:arquivoContabil',
      },
    });

  const [putError, setPutError] = useState('');
  const [extraDefinitionMetadata, setExtraDefinitionMetadata] =
    useState<Data>(undefined);

  useEffect(() => {
    if (isSuccess) {
      if (data && 'layouts' in data) {
        setExtraDefinitionMetadata(data as Data);
      } else {
        setExtraDefinitionMetadata(templatedExtraDefinitionMetadata);
      }
    }
  }, [isSuccess, data]);

  if (isLoading || !extraDefinitionMetadata) {
    return <div>Loading...</div>;
  }

  if (!isSuccess) {
    return (
      <div>
        <div>Erro ao carregar dados</div>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </div>
    );
  }

  return (
    <Box p={4}>
      <Box pb={4}>
        <Typography variant="h1">Layout do arquivo contábil</Typography>
      </Box>
      {extraDefinitionMetadata.layouts.map((layout) => (
        <Box
          key={layout.name}
          mb={2}
          p={2}
          border="1px solid #ccc"
          borderRadius={2}
          display="flex"
          flexDirection="column"
          gap={4}
        >
          <Typography variant="h2">{layout.name}</Typography>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {layout.layout.columns.map((column, index) => (
                    <TableCell key={`header-${index}`}>
                      {column.headerName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {layout.layout.columns.map((column, index) => (
                    <TableCell key={`field-${index}`}>{column.field}</TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}

      <Box
        pt={4}
        sx={{
          height: '600px',
        }}
      >
        <Editor
          extraDefinitionMetadata={extraDefinitionMetadata}
          setExtraDefinitionMetadata={setExtraDefinitionMetadata}
        />
      </Box>

      <pre>{putError}</pre>

      <Box pt={4}>
        <Button
          onClick={() => {
            fetchUpdateReportExtraDefinitionMetadata({
              body: extraDefinitionMetadata,
              pathParams: {
                organizationId,
                reportId: 'br:arquivoContabil',
              },
            })
              .then(() => {
                window.location.reload();
              })
              .catch((error) => {
                setPutError(JSON.stringify(error, null, 2));
              });
          }}
        >
          Salvar
        </Button>
      </Box>
    </Box>
  );
}

let loaded = false;
let editor: JSONEditor | null = null;

function Editor({
  extraDefinitionMetadata,
  setExtraDefinitionMetadata,
}: {
  extraDefinitionMetadata: Data;
  setExtraDefinitionMetadata: (data: Data) => void;
}) {
  useEffect(() => {
    if (loaded) {
      return () => {
        editor?.destroy();
        loaded = false;
      };
    }

    const container = document.getElementById('jsoneditor');
    editor = new JSONEditor(container, {
      mode: 'code',
      onChange: () => {
        setExtraDefinitionMetadata(editor.get());
      },
    });

    editor.set(extraDefinitionMetadata);

    loaded = true;

    return () => {
      editor.destroy();
      loaded = false;
    };
  }, []);

  return (
    <div
      id="jsoneditor"
      style={{
        width: '100%',
        height: '600px',
      }}
    ></div>
  );
}

export default Page;
