import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconLoader } from '@tabler/icons-react';

import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import { useCreateUsersJob } from '@octopus/api';
import { WorkerCategory } from '@octopus/contract-types';

import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { DialogContentLoading } from '../components/dialog/DialogContentLoading';

type CreateUsersDialogFilterProps =
  | {
      contractIds: Set<string>;
    }
  | {
      legalEntityId?: string;
      companyId?: string;
      workerCategories?: WorkerCategory[];
    };
type CreateUsersDialogProps = {
  organizationId: string;
  filters: CreateUsersDialogFilterProps;
  onClose: () => void;
  isOpen: boolean;
};

export function CreateUsersDialog({
  organizationId,
  filters,
  isOpen,
  onClose,
}: CreateUsersDialogProps) {
  const navigate = useNavigate();
  const [scene, setScene] = useState<1 | 2>(1);

  const { isLoading, isError, error, mutate } = useCreateUsersJob();
  const executeRequest = () => {
    setScene(2);
    mutate({
      pathParams: { organizationId },
      body: {
        name: 'createUsers',
        parameters:
          'contractIds' in filters
            ? {
                contractIds: Array.from(filters.contractIds),
              }
            : {
                filters: {
                  companyId: filters.companyId,
                  legalEntityId: filters.legalEntityId,
                  contractTypes: filters.workerCategories,
                },
              },
      },
    });
  };

  const { showSnackbar } = useSnackbar();

  const createJobSucceeded = () => {
    onClose();
    navigate('/users-management');
    setTimeout(() => {
      showSnackbar({
        autoHideDuration: 3000,
        isOpen: true,
        variant: 'default',
        Message:
          'Os acessos estão sendo liberados, isso pode levar alguns instantes...',
        StartAdornment: <IconLoader />,
        hasCloseAction: true,
      });
    }, 400);
  };
  const createJobFailed = () => {
    onClose();
    showSnackbar({
      autoHideDuration: 3000,
      isOpen: true,
      variant: 'error',
      Message: 'Ocorreu um erro ao liberar os acessos. Tente novamente.',
      StartAdornment: <CancelIcon />,
      hasCloseAction: true,
    });
  };

  useEffect(() => {
    // is at "octopus dancing" modal but loading is false
    if (scene === 2 && !isLoading) {
      if (!isError) {
        createJobSucceeded();
      } else {
        console.error(error);
        createJobFailed();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isError, error, scene]);

  const createUsersScene = (
    <>
      <DialogTitle sx={{ pt: 5, px: 4, pb: 2 }}>
        <Typography fontSize="24px" color="textPrimary" fontWeight={700}>
          Liberar acessos
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography
            color="textPrimary"
            fontSize="16px"
            lineHeight="24px"
            fontWeight={500}
          >
            Ao confirmar, o que acontece em seguida:
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 2, py: 0 }}>
            <ListItem disableGutters sx={{ pb: 2, display: 'list-item' }}>
              <Typography variant="body1" color="textPrimary">
                Processamento dos acessos
              </Typography>
              <Typography variant="caption" color="textSecondary">
                A liberação pode levar alguns minutos para ser concluída. Assim
                que estiver finalizada, você receberá um e-mail de confirmação.
              </Typography>
            </ListItem>
            <ListItem disableGutters sx={{ py: 0, display: 'list-item' }}>
              <Typography variant="body1" color="textPrimary">
                Acessos disponíveis
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Cada pessoa da lista de contratos será notificada por e-mail
                assim que seu acesso for liberado.
              </Typography>
            </ListItem>
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" size="large" onClick={onClose}>
          Cancelar
        </Button>
        <Button color="primaryAlt" size="large" onClick={executeRequest}>
          Confirmar
        </Button>
      </DialogActions>
    </>
  );

  const loadingScene = <DialogContentLoading message="Liberando acessos..." />;

  return (
    <Dialog
      PaperProps={{ sx: { minWidth: '600px' } }}
      open={isOpen}
      onClose={onClose}
    >
      {scene === 1 && createUsersScene}
      {scene === 2 && loadingScene}
    </Dialog>
  );
}
