import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconArrowRight } from '@tabler/icons-react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  Popover,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

import { useCountPendingAccess } from './data';

type PendingAccessMenuProps = {
  organizationId: string;
  withSelectionDialog?: boolean;
};
export function PendingAccessActionMenu({
  organizationId,
  withSelectionDialog = false,
}: PendingAccessMenuProps) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const popoverAnchor = useRef(null);
  const openMenu = () => setMenuOpen(true);
  const closeMenu = () => setMenuOpen(false);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const { isError, isLoading, data } = useCountPendingAccess({
    organizationId,
  });

  const goToPage = (filtered = false) => {
    const params = new URLSearchParams({ filtered: filtered.toString() });
    navigate({
      pathname: `/users-management/pending-access/${organizationId}`,
      search: params.toString(),
    });
  };

  return (
    !isError &&
    !isLoading &&
    data?.total > 0 && (
      <>
        <IconButton
          ref={popoverAnchor}
          size="medium"
          sx={{
            borderRadius: 1,
            marginLeft: 'auto',
            justifySelf: 'flex-end',
          }}
          onClick={openMenu}
        >
          <MoreVertIcon />
        </IconButton>
        <Popover
          open={menuOpen}
          onClose={closeMenu}
          anchorEl={popoverAnchor.current}
          anchorOrigin={{
            horizontal: -250,
            vertical: 'bottom',
          }}
        >
          <List sx={{ width: '280px' }}>
            <ListItemButton
              onClick={() =>
                withSelectionDialog ? openDialog() : goToPage(true)
              }
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Typography variant="body2" color="textPrimary">
                  Liberar acessos pendentes
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {data?.total}
                </Typography>
              </Box>
            </ListItemButton>
          </List>
        </Popover>
        <PendingAccessNavigationDialog
          organizationId={organizationId}
          pendingAccessCount={data?.total ?? 0}
          isOpen={dialogOpen}
          onClose={closeDialog}
        />
      </>
    )
  );
}

type PendingAccessNavigationDialogProps = {
  organizationId: string;
  pendingAccessCount: number;
  isOpen: boolean;
  onClose: () => void;
};
function PendingAccessNavigationDialog({
  organizationId,
  pendingAccessCount,
  isOpen,
  onClose,
}: PendingAccessNavigationDialogProps) {
  const navigate = useNavigate();
  const goToPage = (filtered = false) => {
    const params = new URLSearchParams({ filtered: filtered.toString() });
    navigate(`/users-management/pending-access/${organizationId}?${params}`);
  };

  return (
    <Dialog
      PaperProps={{ sx: { minWidth: '600px' } }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle sx={{ pt: 5, px: 4, pb: 2 }}>
        <Typography fontSize="24px" color="textPrimary" fontWeight={700}>
          Liberar acessos
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textPrimary">
          Existem {pendingAccessCount} contratos cadastrados que ainda não
          possuem usuários com acessos liberados.
        </Typography>
        <List sx={{ py: 2 }}>
          <Box display="flex" flexDirection="column" gap={1.5}>
            <PendingAccessNavigationButton
              title="Liberar todos"
              message="Libere todos os contratos cadastrados sem acessos liberados."
              onClick={() => goToPage()}
            />
            <PendingAccessNavigationButton
              title="Liberar parcialmente"
              message="Selecione os contratos que você deseja liberar."
              onClick={() => goToPage(true)}
            />
          </Box>
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" size="large" onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function PendingAccessNavigationButton({
  title,
  message,
  onClick,
}: {
  title: string;
  message: string;
  onClick: () => void;
}) {
  return (
    <ListItemButton
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
      sx={(theme) => ({
        borderRadius: '12px',
        border: `1px solid ${theme.palette.strokes.light}`,
        p: 3,
      })}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        gap={3}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography color="textPrimary" fontSize="16px" fontWeight={500}>
            {title}
          </Typography>
          <Typography color="textPrimary" variant="body2">
            {message}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <IconArrowRight className="small" />
        </Box>
      </Box>
    </ListItemButton>
  );
}
