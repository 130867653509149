import {
  ColDef,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { GridReadyEvent } from 'ag-grid-community/dist/types/core/events';
import { AgGridReact } from 'ag-grid-react';

import { Box, ClickAwayListener } from '@mui/material';

import { PayrollInputsConfig } from '@octopus/api';

import { useRef } from 'react';

import { ColumnInfoPopper } from './ColumnInfoPopper';
import { EmployeeIdentifierCell } from './EmployeeIdentifierCell';
import { IdentifierHeader } from './IdentifierHeader';
import { newInputColDef } from './inputColDef';
import { RowInfoPopper } from './RowInfoPopper';
import {
  ColumnInfo,
  EMPLOYEE_COLUMN_ID,
  PayrollEmployeeData,
  RowInfo,
} from './types';
import { useAutoSizeTable } from './useAutoSizeTable';
import { useInfoPoppers } from './useInfoPoppers';
import {
  EmployeesState,
  HasPayrollBeenEdited,
  InputsEdit,
} from './useSubmissionState';
import { NoRowsOverlay, copyCell, copyHeader, pasteCell } from './utils';

export type InputsTableProps = {
  organizationId: string;
  companyId: string;
  config: PayrollInputsConfig;
  state: EmployeesState;
  hasPayrollBeenEdited: HasPayrollBeenEdited;
  show: boolean;
};

export function InputsTable({
  organizationId,
  companyId,
  config,
  state: { data, edit },
  hasPayrollBeenEdited,
  show,
}: InputsTableProps) {
  const gridRef = useRef<AgGridReact>();
  useAutoSizeTable({ gridRef, show });
  const {
    columnInfoState,
    rowInfoState,
    showColumnInfo,
    showRowInfo,
    hideInfoPoppers,
  } = useInfoPoppers();

  const colDefs = prepareColDef({
    config,
    showColumnInfo,
    showRowInfo,
    hideInfoPoppers,
    edit,
    hasPayrollBeenEdited,
  });

  return (
    <ClickAwayListener
      onClickAway={() => gridRef.current?.api?.clearRangeSelection()}
    >
      <Box
        className="ag-theme-quartz"
        height="100%"
        width="100%"
        onMouseLeave={hideInfoPoppers}
      >
        <AgGridReact<PayrollEmployeeData>
          columnDefs={colDefs}
          columnMenu="new"
          copyHeadersToClipboard
          enableFillHandle
          enableRangeSelection
          fillHandleDirection="y"
          getContextMenuItems={() => []}
          getRowId={(data) => data.data.payrollId}
          maintainColumnOrder
          noRowsOverlayComponent={NoRowsOverlay}
          onGridReady={initGrid}
          onUndoStarted={() => edit.undo()}
          onRedoStarted={() => edit.redo()}
          processCellForClipboard={(params) => copyCell(params, config)}
          processCellFromClipboard={(params) => pasteCell(params, config)}
          processHeaderForClipboard={copyHeader}
          reactiveCustomComponents
          ref={gridRef}
          rowData={data}
          suppressMultiRangeSelection
          undoRedoCellEditing
        />
        <ColumnInfoPopper
          open={columnInfoState.open}
          anchorEl={columnInfoState.ref}
          info={columnInfoState.info}
          data={data}
          api={gridRef.current?.api}
          handleClose={hideInfoPoppers}
          setAll={edit.setAll}
        />
        <RowInfoPopper
          organizationId={organizationId}
          companyId={companyId}
          open={rowInfoState.open}
          anchorEl={rowInfoState.ref}
          info={rowInfoState.info}
          api={gridRef.current?.api}
          handleClose={hideInfoPoppers}
        />
      </Box>
    </ClickAwayListener>
  );
}

type ColDefProps = {
  config: PayrollInputsConfig;
  showColumnInfo: (ref: HTMLElement, info: ColumnInfo) => void;
  showRowInfo: (ref: HTMLElement, info: RowInfo) => void;
  hideInfoPoppers: () => void;
  edit: InputsEdit;
  hasPayrollBeenEdited: HasPayrollBeenEdited;
};

function prepareColDef({
  config,
  showColumnInfo,
  showRowInfo,
  hideInfoPoppers,
  edit,
  hasPayrollBeenEdited,
}: ColDefProps): ColDef<PayrollEmployeeData, string>[] {
  return [
    {
      colId: EMPLOYEE_COLUMN_ID,
      headerName: 'Colaborador',
      headerComponent: IdentifierHeader,
      cellRendererParams: {
        showRowInfo,
        hasPayrollBeenEdited,
      },
      cellRenderer: EmployeeIdentifierCell,
      valueGetter: (params) => `${params.data.name}\t${params.data.employeeId}`,
      flex: 1,
      pinned: 'left',
      sortable: true,
      resizable: true,
      suppressMovable: true,
      initialSort: 'asc',
      comparator: (a, b) => a.localeCompare(b),
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
      minWidth: 280,
      cellStyle: {
        padding: '0',
      },
    },
    ...Object.values(config.payload)
      .filter((entry) => entry.target === 'employee')
      .sort((a, b) => a.label.localeCompare(b.label))
      .map((entry) =>
        newInputColDef({
          entry,
          showColumnInfo,
          hideInfoPoppers,
          hasBeenEdited: (
            data: PayrollEmployeeData,
            inputId: string,
            value: string | null,
          ) => edit.hasBeenEdited(data.payrollId, inputId, value),
          hasValidationErrors: () => false,
          valueGetter: ({
            data: { inputs },
          }: ValueGetterParams<PayrollEmployeeData, string>) =>
            inputs[entry.id],
          valueSetter: ({
            newValue,
            data: { payrollId },
          }: ValueSetterParams<PayrollEmployeeData, string>) => {
            edit.set(payrollId, entry.id, newValue);
            return true;
          },
        }),
      ),
  ];
}

function initGrid({ api }: GridReadyEvent<PayrollEmployeeData>) {
  api.applyColumnState({
    state: [{ colId: EMPLOYEE_COLUMN_ID, sort: 'asc' }],
    defaultState: { sort: null },
  });
}
