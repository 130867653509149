import { Box, DialogContent, Typography } from '@mui/material';

import { OctopusLoading } from '@octopus/ui/design-system';

export function LoadingScene({ title }: { title: string }) {
  return (
    <DialogContent
      data-testid={'dialogContent'}
      sx={(theme) => ({
        height: '100%',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.secondary,
      })}
    >
      <Box
        data-testid={'outerBox'}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        <Box width="100px">
          <OctopusLoading />
        </Box>
        <Box pt={2}>
          <Typography variant="h4" textAlign="center">
            {title}
          </Typography>
        </Box>
      </Box>
    </DialogContent>
  );
}
