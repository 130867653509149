import React from 'react';

import { identity, pickBy } from 'lodash';

import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { CompanySummary, DepartmentInput } from '@octopus/api';

import { ErrorAlert } from '../ErrorAlert';

export type DepartmentFormProps = {
  companies: CompanySummary[] | undefined;
  form: DepartmentInput;
  setForm: (setter: (form: DepartmentInput) => DepartmentInput) => void;
  failure: string | undefined;
  errors: Record<string, string>;
  clearError: (key: string) => void;
  disabled: boolean;
  requireCompany?: string;
};

export function DepartmentForm({
  companies,
  form,
  setForm,
  failure,
  errors,
  clearError,
  disabled,
  requireCompany,
}: DepartmentFormProps) {
  const { code, name, enabledForCompanies, description } = form;

  return (
    <>
      {failure && (
        <Box pb={2}>
          <ErrorAlert message={failure} />
        </Box>
      )}
      <Box display="flex" flexDirection="column" gap={4} pb={10}>
        <Box display="flex" flexDirection="row" gap={2.5}>
          <Box display="flex" flexDirection="column" gap={1} flex="1 1 0">
            <Typography variant="caption">Código*</Typography>
            <TextField
              name="code"
              variant="outlined"
              placeholder="0000"
              value={code}
              onChange={(event) => {
                clearError('code');
                setForm((current) => ({
                  ...current,
                  code: event.target.value,
                }));
              }}
              fullWidth
              error={errors.code !== undefined}
              helperText={errors.code}
              disabled={disabled}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={1} flex="3 1 0">
            <Typography variant="caption">Nome*</Typography>
            <TextField
              name="name"
              variant="outlined"
              placeholder="Ex.: Engenharia"
              value={name}
              onChange={(e) => {
                clearError('name');
                setForm((current) => ({
                  ...current,
                  name: e.target.value,
                }));
              }}
              fullWidth
              error={errors.name !== undefined}
              helperText={errors.name}
              disabled={disabled}
            />
          </Box>
        </Box>
        {companies?.length > 1 && (
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="caption">
              Quais empresas podem usar este departamento?
            </Typography>
            <Select
              name="enabledForCompanies"
              multiple
              value={enabledForCompanies}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                if (value.length === 0) {
                  return;
                }
                setForm((current) => ({
                  ...current,
                  enabledForCompanies: value as string[],
                }));
              }}
              renderValue={(selected) => {
                if (selected.length === companies.length) {
                  return 'Todas';
                }
                return companies
                  .filter(({ companyId }) => selected.includes(companyId))
                  .map(({ name }) => name)
                  .join(', ');
              }}
              disabled={disabled}
            >
              {companies.map(({ companyId, name }) => (
                <MenuItem
                  key={companyId}
                  value={companyId}
                  disabled={
                    requireCompany !== undefined && requireCompany === companyId
                  }
                >
                  <Checkbox checked={enabledForCompanies.includes(companyId)} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="caption">Descrição</Typography>
          <TextField
            name="description"
            variant="outlined"
            placeholder="Descreva o departamento"
            value={description}
            onChange={(e) => {
              clearError('description');
              setForm((current) => ({
                ...current,
                description: e.target.value,
              }));
            }}
            fullWidth
            multiline
            rows={3}
            error={errors.description !== undefined}
            helperText={errors.description}
            disabled={disabled}
          />
        </Box>
      </Box>
    </>
  );
}

export function sanitizeDepartmentInput(
  input: DepartmentInput,
  companies: CompanySummary[] | undefined,
): DepartmentInput {
  const result = pickBy(input, identity) as DepartmentInput;
  result.name = result.name?.trim();
  if (result.enabledForCompanies?.length === companies.length) {
    delete result.enabledForCompanies;
  }
  return result;
}
