import { useQuery } from '@tanstack/react-query';

import { SearchInput, fetchSearchAdmissionDrafts } from '@octopus/api';
import { contractTypes } from '@octopus/contract-types';

import {
  CountProps,
  Counts,
  CountsSkeleton,
} from '../../modules/components/Counts';

export function AdmissionCount({ organizationId }: { organizationId: string }) {
  const queryTotals = useQuery({
    queryKey: ['getAdmissionTotals', { organizationId }],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const searchFilter: SearchInput = {
        counting: {
          filtered: {
            total: true,
            byProp: {
              contractType: Object.values(contractTypes),
            },
          },
        },
        filtering: {
          elements: {
            draftStatus: [
              { not: 'archived' },
              { not: 'contract_created' },
              { not: 'admission_approved' },
            ],
            contractType: Object.values(contractTypes),
          },
        },
      };

      const totals = await fetchSearchAdmissionDrafts({
        pathParams: {
          organizationId,
        },
        body: searchFilter,
      });

      const counts: CountProps = {};
      counts['all'] = totals?.metadata?.filtered?.counters?.total ?? 0;

      if (counts['all'] === 0) {
        return counts;
      }

      Object.entries(
        totals.metadata.filtered.counters.byProp['contractType'],
      ).forEach(([key, value]) => {
        counts[key] = value;
      });

      if (
        counts['all'] === counts['br:pj'] ||
        counts['all'] === counts['br:clt']
      ) {
        return counts;
      }

      Object.entries(counts).forEach(([key, value]) => {
        counts[key] = value;
      });

      return counts;
    },
  });

  if (queryTotals?.isLoading || queryTotals?.isFetching) {
    return <CountsSkeleton />;
  }

  return (
    <Counts
      dataTestId={'admission-counts'}
      props={getContractTypeLabels(queryTotals.data)}
      allKey={contractLabels.all}
      allTooltip="Pessoas que estão em processo de admissão."
    />
  );
}

const contractLabels = {
  all: 'Total de pessoas',
  'br:clt': 'CLTs',
  'br:pj': 'Prestadores de serviço',
} as const;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getContractTypeLabels(counts: CountProps): CountProps {
  return Object.entries(contractLabels).reduce((acc, [key, label]) => {
    const count = (counts && counts[key]) || 0;
    return {
      ...acc,
      [label]: count,
    };
  }, {} as CountProps);
}
