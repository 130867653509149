import { Box, Typography } from '@mui/material';

import { UploadComponent } from '../../../../../../../modules/components/file/upload';

const ACCEPTED_FILE_TYPE = {
  'text/csv': ['.csv'],
};
const FILE_SIZE_LIMIT_KB = 888;
const FILE_LINE_LIMIT = 88888;

type TimesheetLoadFileStepProps = {
  onFileSet: (file?: File) => void | Promise<void>;
};

export function TimesheetLoadFileStep({
  onFileSet,
}: TimesheetLoadFileStepProps) {
  return (
    <Box
      data-testid="timesheet-load-file-step"
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        maxHeight: (theme) => theme.spacing(150),
        minHeight: (theme) => theme.spacing(30),
      }}
    >
      <UploadComponent
        setFile={onFileSet}
        acceptedFileTypes={ACCEPTED_FILE_TYPE}
        message={
          <Typography
            variant="button"
            sx={{
              whiteSpace: 'pre-line',
              textAlign: 'center',
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            <Typography
              variant="button"
              sx={{
                color: (theme) => theme.palette.primary.main,
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              Clique aqui{' '}
            </Typography>
            ou arraste o arquivo nesta janela{'\n'}
            Apenas arquivos .CSV são aceitos (máximo {FILE_SIZE_LIMIT_KB}kb e{' '}
            {FILE_LINE_LIMIT} linhas)
          </Typography>
        }
      />
    </Box>
  );
}
