import { useQuery } from '@tanstack/react-query';

import {
  SearchFilteringInput,
  fetchSearchAllPendingAccessContracts,
} from '@octopus/api';

const fetchPendingAccessCount = (
  {
    organizationId,
    elements = {},
  }: {
    organizationId: string;
    elements?: SearchFilteringInput['elements'];
  },
  { signal }: { signal: AbortSignal },
) =>
  fetchSearchAllPendingAccessContracts(
    {
      pathParams: { organizationId },
      body: {
        pagination: { page: 0, size: 1 },
        filtering: {
          elements: {
            ...elements,
            userId: [{ empty: true }],
          },
        },
      },
    },
    signal,
  ).then(({ total }) => ({ total }));

export function useCountPendingAccess({
  organizationId,
}: {
  organizationId: string;
}) {
  return useQuery({
    queryKey: ['countPendingAccess', organizationId],
    queryFn: ({ signal }) =>
      fetchPendingAccessCount({ organizationId }, { signal }),
  });
}

export function useCountAllPendingAccess({
  organizationId,
}: {
  organizationId: string;
}) {
  return useQuery({
    queryKey: ['countAllPendingAccess', organizationId],
    queryFn: ({ signal }) =>
      Promise.all([
        fetchPendingAccessCount(
          { organizationId, elements: { email: [{ empty: false }] } },
          { signal },
        ),
        fetchPendingAccessCount(
          { organizationId, elements: { email: [{ empty: true }] } },
          { signal },
        ),
      ]).then(([valid, invalid]) => ({
        valid: valid.total,
        invalid: invalid.total,
      })),
  });
}
