import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import {
  ContractList,
  ContractSummary,
  DepartmentSummary,
  SearchInput,
  useSearchAllContracts,
} from '@octopus/api';
import { contractStatuses } from '@octopus/contract-types';
import {
  DataGrid,
  DataGridProps,
  DataGridToolbar,
  GridColDef,
  GridValueGetterParams,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { ErrorAlert } from '../../../ErrorAlert';
import { departmentColumn } from '../../../table/columns/CompanyColumns';
import UserAvatar from '../../../UserAvatar';

import { DepartmentAddContractsDialog } from './DepartmentAddContractsDialog';
import { AddContractEntry } from './types';

export type DepartmentAddContractsProps = {
  organizationId: string;
  department: DepartmentSummary;
  onCancel: () => void;
  onContractsAdded: () => void;
};

export function DepartmentAddContracts({
  organizationId,
  department,
  onCancel,
  onContractsAdded,
}: DepartmentAddContractsProps) {
  const { mutate, isLoading, isError, data } = useSearchAllContracts();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedContracts, setSelectedContracts] = useState<
    AddContractEntry[]
  >([]);

  const dataGridProps = useDataGrid({
    key: `department-contracts-${organizationId}`,
    sorting: { field: 'name', order: 'asc' },
    search: { type: 'state' },
  });

  useEffect(() => {
    if (organizationId === undefined || department === undefined) {
      return;
    }
    mutate({
      pathParams: {
        organizationId,
      },
      body: {
        filtering: {
          elements: {
            departmentId: [{ not: department.departmentId }],
            status: [{ not: contractStatuses.terminated }],
          },
        },
        sorting: [
          {
            field: dataGridProps.sortingProps.field,
            order: dataGridProps.sortingProps.order,
          },
        ],
        pagination: {
          page: dataGridProps.paginationProps.page,
          size: dataGridProps.paginationProps.rowsPerPage,
        },
        ...(dataGridProps.searchProps.searchTerm.length > 0 && {
          query: dataGridProps.searchProps.searchTerm,
        }),
      } as SearchInput,
    });
  }, [
    organizationId,
    department.departmentId,
    dataGridProps.searchProps.searchTerm,
    dataGridProps.sortingProps.field,
    dataGridProps.sortingProps.order,
    dataGridProps.paginationProps.page,
    dataGridProps.paginationProps.rowsPerPage,
  ]);

  return (
    <Box display="flex" height="100%" flexDirection="column" gap={1}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" gap={2}>
          <DataGridToolbar
            searchPlaceholder="Procurar"
            hideFilters={true}
            filters={[]}
            searchProps={dataGridProps.searchProps}
            filteringProps={dataGridProps.filteringProps}
            searchBarSx={{
              maxWidth: '240px',
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          height: '100%',
          maxHeight: 'calc(100vh - 320px)',
          overflowY: 'auto',
          overflowX: 'overlay',
        }}
      >
        <ContractsTable
          isLoading={isLoading}
          isError={isError}
          data={data}
          dataGridProps={dataGridProps}
          columns={prepareColumns(
            selectedContracts,
            (entry) => {
              setSelectedContracts((current) => [...current, entry]);
            },
            (contractId) => {
              setSelectedContracts((current) =>
                current.filter((entry) => entry.contractId !== contractId),
              );
            },
          )}
        />
      </Box>
      <Box
        py={1}
        px={3}
        gap={3}
        boxSizing="border-box"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        sx={(theme) => ({
          background: 'rgba(247, 247, 247, 0.90)',
          borderTop: `1px solid ${theme.palette.strokes.light}`,
          backdropFilter: 'blur(4px)',
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: '700px',
        })}
      >
        <Box display="flex" gap={1} alignItems="center">
          {selectedContracts.length > 0 && (
            <Typography variant="caption" color="text.secondary">
              {selectedContracts.length} contrato
              {selectedContracts.length > 1 && 's'} selecionado
              {selectedContracts.length > 1 && 's'}
            </Typography>
          )}
          <Button color="secondary" size="large" onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            color="primaryAlt"
            size="large"
            onClick={() => setIsDialogOpen(true)}
            disabled={selectedContracts.length === 0}
          >
            Adicionar
          </Button>
        </Box>
      </Box>
      <DepartmentAddContractsDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        newDepartment={department}
        contractsToAdd={selectedContracts}
        onContractsAdded={onContractsAdded}
      />
    </Box>
  );
}

function ContractsTable({
  isLoading,
  isError,
  data,
  dataGridProps,
  columns,
}: {
  isLoading: boolean;
  isError: boolean;
  data: ContractList | undefined;
  dataGridProps: DataGridProps;
  columns: GridColDef<ContractSummary>[];
}) {
  const { paginationProps, sortingProps } = dataGridProps;
  if (isError) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <ErrorAlert
          message="Falha ao carregar contratos, por favor tente novamente mais tarde.
          Se o problema persistir, entre em contato com o suporte da Tako."
        />
      </Box>
    );
  }

  if (isLoading || !data) {
    return (
      <Box mr={2} height="95%">
        <Skeleton variant="rounded" width="100%" height="100%" sx={{ mt: 1 }} />
      </Box>
    );
  }

  return (
    <Box width="565px">
      <DataGrid
        sortingProps={sortingProps}
        paginationProps={paginationProps}
        totalRowCount={data.total || 0}
        getRowId={(row) => {
          return `${row.contractId}-${row.personId}`;
        }}
        rows={data.data}
        columns={columns}
      />
    </Box>
  );
}

function prepareColumns(
  selectedContracts: AddContractEntry[],
  selectContract: (entry: AddContractEntry) => void,
  cancelAddContract: (contractId: string) => void,
): GridColDef<ContractSummary>[] {
  return [
    {
      field: 'name',
      headerName: 'Nome',
      renderHeader: (params) => {
        return <Box ml={2}>{params.field}</Box>;
      },
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.name;
      },
      renderCell: ({ value, row }) => {
        return (
          <Stack direction="row" spacing={0}>
            <Checkbox
              checked={
                !!selectedContracts.find(
                  ({ contractId }) => contractId === row.contractId,
                )
              }
              onChange={(_, checked) =>
                checked
                  ? selectContract({
                      id: row.contractId,
                      contractId: row.contractId,
                      contractType: row.contractType,
                      name: row.name,
                      profileUrl: row.pictureUrl,
                      oldDepartmentId: row.departmentId,
                    })
                  : cancelAddContract(row.contractId)
              }
              sx={{}}
            />
            <UserAvatar
              name={value}
              pictureUrl={row.pictureUrl}
              expandNameOnHover={true}
              sx={{
                '--UserAvatar-name-max-width': '12.5em',
              }}
              avatarProps={{
                ml: 0.25,
              }}
            />
          </Stack>
        );
      },
    },
    departmentColumn(),
  ];
}
