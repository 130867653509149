import React from 'react';

import { FormProvider, getFormProps } from '@conform-to/react';

import { Box, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

import * as FieldComponents from './Field';
// eslint-disable-next-line import/no-cycle
import * as FieldsetComponents from './Fieldset';
import { TFieldRenderPropOptions } from './parseField/types';
import { TMetaForm, TPayloadForm } from './types';

type IFormProps = React.PropsWithChildren & {
  payloadForm: TPayloadForm;
  metaForm: TMetaForm;
};

export function Form({ payloadForm, metaForm, children }: IFormProps) {
  return (
    <FormProvider context={payloadForm.context}>
      <FormProvider context={metaForm.context}>
        <div id={payloadForm.errorId}>{payloadForm.errors}</div>
        <form {...getFormProps(metaForm)} />
        <form {...getFormProps(payloadForm)} />
        {children}
      </FormProvider>
    </FormProvider>
  );
}

const Components = Object.assign({}, FieldComponents, FieldsetComponents);

type IFormFieldProps = {
  for: TFieldRenderPropOptions;
  sx?: SxProps<Theme>;
};

Form.Field = function FormField(props: IFormFieldProps) {
  const [_fieldName, ...fieldDataOptions] = props.for;

  const toRenderUiMatchFieldJSX = Object.values(Components).reduce(
    (toRenderUiMatchFieldJSX, FieldComponent) => {
      if (toRenderUiMatchFieldJSX) return toRenderUiMatchFieldJSX;
      if (!('uiType' in FieldComponent)) return toRenderUiMatchFieldJSX;
      const fieldData = fieldDataOptions.find(
        (fieldData) =>
          FieldComponent.uiType === fieldData.uiType &&
          FieldComponent.canRender(fieldData),
      );
      if (!fieldData) return toRenderUiMatchFieldJSX;
      return (
        <FieldComponent
          key={fieldData.props.key}
          field={fieldData as any}
          sx={props.sx}
        />
      );
    },
    undefined as React.ReactNode | undefined,
  );

  const toRenderFieldJSX =
    toRenderUiMatchFieldJSX ||
    Object.values(Components).reduce(
      (toRenderFieldJSX, FieldComponent) => {
        if (toRenderFieldJSX) return toRenderFieldJSX;
        const fieldOptionToRender = fieldDataOptions.find((field) =>
          FieldComponent.canRender(field),
        );
        if (!fieldOptionToRender) return toRenderFieldJSX;

        return (
          <FieldComponent
            key={fieldOptionToRender.props.key}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            field={fieldOptionToRender as any}
            sx={props.sx}
          />
        );
      },
      undefined as React.ReactNode | undefined,
    );

  return toRenderFieldJSX;
};

type IFormLayoutProps = React.PropsWithChildren;
Form.Layout = function FormLayout(props: IFormLayoutProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '26px 0',
      }}
    >
      {React.Children.map(props.children, (Child) => {
        return <Box>{Child}</Box>;
      })}
    </Box>
  );
};
