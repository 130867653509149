const lancamentosDeEncargos = {
  fgts: {
    id: 'encargo.fgts',
    descricao: 'Encargos - FGTS',
  },
  grrf: {
    id: 'encargo.grrf',
    descricao: 'Encargos - FGTS GRRF',
  },
  inssPatronal: {
    id: 'encargo.inssPatronal',
    descricao: 'Encargos - INSS Patronal',
  },
  inssOutrasEntidades: {
    id: 'encargo.inssOutrasEntidades',
    descricao: 'Encargos - INSS Outras Entidades',
  },
  inssRat: {
    id: 'encargo.inssRat',
    descricao: 'Encargos - INSS RAT',
  },
};

const lancamentosDerivados = {
  liquidoDeRescisao: {
    id: 'derivados.liquidoDeRescisao',
    descricao: 'Líquido de Rescisão',
  },
  liquidoDeFerias: {
    id: 'derivados.liquidoDeFerias',
    descricao: 'Líquido de Folhas de Férias',
  },
  liquidoDeDecimoTerceiro: {
    id: 'derivados.liquidoDeDecimoTerceiro',
    descricao: 'Líquido de Folhas de 13º',
  },
  liquidoDeMensal: {
    id: 'derivados.liquidoDeMensal',
    descricao: 'Líquido de Folhas Mensais',
  },
  liquidoDeProLabore: {
    id: 'derivados.liquidoDeProLabore',
    descricao: 'Líquido de Folhas Mensais de Pró-Labore',
  },
  liquidoDeEstagio: {
    id: 'derivados.liquidoDeEstagio',
    descricao: 'Líquido de Folhas Mensais de Estágio',
  },
  liquidoDeAdiantamento: {
    id: 'derivados.liquidoDeAdiantamento',
    descricao: 'Líquido de Adiantamento',
  },
  liquidoDeComplementar: {
    id: 'derivados.liquidoDeComplementar',
    descricao: 'Líquido de Complementar',
  },
  liquidoDeRpa: {
    id: 'derivados.liquidoDeRpa',
    descricao: 'Líquido de RPA',
  },
  tercoSobreFeriasSobreAvisoPrevioIndenizado: {
    id: 'derivados.tercoSobreFeriasSobreAvisoPrevioIndenizado',
    descricao: 'Terço sobre Férias sobre Aviso Prévio Indenizado',
  },
};

const lancamentosParaRelatorios = {
  mediaDecimoTerceiroDominioValor: {
    id: 'relatorios.medias.decimoTerceiro.dominio.valor',
    descricao: 'Média de Valor (Dominio) para 13º',
  },
  mediaDecimoTerceiroDominioHoras: {
    id: 'relatorios.medias.decimoTerceiro.dominio.horas',
    descricao: 'Média de Horas (Dominio) para 13º',
  },
};

const lancamentosDePj = {
  valorAPagar: {
    id: 'pj.valorAPagar',
    descricao: 'PJ - Valor a Pagar',
  },
  inss: {
    id: 'pj.inss',
    descricao: 'PJ - INSS',
  },
  irrf: {
    id: 'pj.irrf',
    descricao: 'PJ - IRRF',
  },
  csll: {
    id: 'pj.csll',
    descricao: 'PJ - CSLL',
  },
  cofins: {
    id: 'pj.cofins',
    descricao: 'PJ - COFINS',
  },
  pisPasep: {
    id: 'pj.pisPasep',
    descricao: 'PJ - PIS/PASEP',
  },
  iss: {
    id: 'pj.iss',
    descricao: 'PJ - ISS',
  },
  outrasRetencoes: {
    id: 'pj.outrasRetencoes',
    descricao: 'PJ - Outras Retenções',
  },
};

export {
  lancamentosDeEncargos,
  lancamentosDerivados,
  lancamentosParaRelatorios,
  lancamentosDePj,
};
