import { getCollectionProps, getFieldsetProps } from '@conform-to/react';

import {
  IFieldCollectionValueWithOptionsDefinition,
  IFieldSingleValueWithOptionsDefinition,
  UI_TYPE,
  parseFieldUiInfo,
} from '@octopus/libs/forms';

import { TMetaField } from '../types';

import { TFieldRenderProps, TFieldsetRenderProps } from './types';

export function parseFieldRadio(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldMetadata: TMetaField,
  fieldDefinition:
    | IFieldCollectionValueWithOptionsDefinition
    | IFieldSingleValueWithOptionsDefinition,
  _fieldsetName = '',
): TFieldRenderProps & TFieldsetRenderProps {
  const radioFieldsetProps = getFieldsetProps(fieldMetadata);
  const childFieldsRenderProps = getCollectionProps(fieldMetadata, {
    type: 'radio',
    options: fieldDefinition.options.map(
      (option) => `${option.value.toString()}`,
    ),
  }).map((radioProps, idx) => {
    const optionDefinition = fieldDefinition.options[idx];
    const fieldRenderProps: TFieldRenderProps = {
      uiType: UI_TYPE.RADIO,
      fieldsetName: optionDefinition.groupLabel || '',
      props: {
        key: radioProps.id,
      },
      label: {
        textContent: optionDefinition.label,
        subtextContent: optionDefinition.subLabel,
        props: {
          htmlFor: radioProps.id,
        },
      },
      input: {
        props: {
          ...radioProps,
          defaultChecked: optionDefinition.selected,
          required: radioProps.required || optionDefinition.required,
          disabled: optionDefinition.disabled,
        },
      },
    };
    return fieldRenderProps;
  });

  const fieldRenderProps: TFieldRenderProps & TFieldsetRenderProps = {
    uiType: parseFieldUiInfo(fieldDefinition),
    fieldsetName: '',
    errors: fieldDefinition.errors,
    props: {
      key: radioFieldsetProps.id,
      id: radioFieldsetProps.id,
      name: radioFieldsetProps.name,
      form: radioFieldsetProps.form,
    },
    label: {
      textContent: fieldDefinition.label,
      props: {
        htmlFor: radioFieldsetProps.id,
      },
    },
    fields: childFieldsRenderProps,
  };

  return fieldRenderProps;
}
