import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { ChevronRight } from '@mui/icons-material';
import { Box, Button, Skeleton, Tooltip, Typography } from '@mui/material';

import {
  PayrollCalculationTotals,
  PayrollPayslipList,
  PayrollStatus,
  PayrollSummary,
  PayrollType,
  SearchFilteringRange,
  SearchSorting,
  WithMetadataResult,
  fetchSearchAllPayrolls,
  fetchSearchAllPayslips,
} from '@octopus/api';
import {
  formatDateBR,
  formatDateWithDaysAdded,
  formatMoney,
  formatPeriodDate,
} from '@octopus/formatters';
import { summaryElements } from '@octopus/payroll-engine/public-types/core';
import {
  DataGrid,
  FilterOptions,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  makeDateRangeFilter,
  makeElementListFilter,
  makeMoneyRangeFilter,
  makeYearMonthPickerFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';
import { Tag } from '@octopus/ui/design-system';

import SendIcon from '../../../assets/send.svg';
import { useSendPayslip } from '../../../modules/components/payslips/SendPayslips';
import { DataFetching } from '../../../modules/dataFetching';
import { PeriodFormat } from '../../../modules/types';
import { getActiveElementFilters } from '../../../utils';
import { hasGeneratedAllPayslips } from '../../../utils/statusIndexUtils';

type LegalEntityEntry = { id: string; name: string };

type PayrollPayslipListWithMetadata = WithMetadataResult & PayrollPayslipList;

export function RpaTable({
  organizationId,
  companyId,
  type,
  compareTo,
  onWorkerClick,
  selectedStatus,
  dataGridProps,
  setCountByStatus,
}: {
  organizationId: string;
  companyId: string;
  type: PayrollType;
  compareTo: PeriodFormat | undefined;
  onWorkerClick: (
    payrollId: string,
    contractId: string,
    rows: Array<PayrollSummary>,
    selectedTab: PayrollStatus,
  ) => void;
  selectedStatus: PayrollStatus;
  dataGridProps: ReturnType<typeof useDataGrid>;
  setCountByStatus: (countByStatus: {
    open: number;
    approved: number;
    closed: number;
    payslipApprovedNotSent: number;
  }) => void;
}) {
  const { sortingProps, filteringProps, searchProps, paginationProps } =
    dataGridProps;

  const useFetch = () => {
    // Get calculation total range filters
    const calculationRangeFilters = new Set(
      Object.values(summaryElements).map(
        (element) => `calculationTotals.${element}`,
      ) as string[],
    );

    // Process calculation total range filters
    const calculationRangeFiltersValue = Object.fromEntries(
      Object.entries(filteringProps.filtersState.rangeFilters)
        .filter(([k]) => filteringProps.activeFilters.has(k))
        .map(([k, v]) => [`calculationTotals.${k}`, v] as const)
        .filter(([k]) => calculationRangeFilters.has(k)),
    ) as Record<string, SearchFilteringRange>;

    // Process other range filters (like paymentDate)
    const otherRangeFilters = Object.fromEntries(
      Object.entries(filteringProps.filtersState.rangeFilters)
        .filter(([k]) => filteringProps.activeFilters.has(k))
        .filter(
          ([k]) => !calculationRangeFilters.has(`calculationTotals.${k}`),
        ),
    );

    const [query] = (() => {
      const { searchTerm } = searchProps;
      return [searchTerm, ''];
    })();

    const elementFilters = {
      type: [type],
      status:
        selectedStatus === 'closed'
          ? ['reconciled', selectedStatus]
          : [selectedStatus],
    };

    const arrayFilters = getActiveElementFilters(filteringProps);
    const payrollBody = {
      query: query.length > 0 ? query : undefined,
      pagination: {
        size: paginationProps.rowsPerPage,
        page: paginationProps.page,
      },
      ...(sortingProps.field
        ? {
            sorting: [
              {
                field: prepareSortField(sortingProps.field),
                order: sortingProps.order,
              },
            ] as SearchSorting,
          }
        : {
            sorting: [
              {
                field: 'period',
                order: 'asc',
              },
            ] as SearchSorting,
          }),
      filtering: {
        ranges: {
          ...calculationRangeFiltersValue,
          ...otherRangeFilters,
        },
        elements: elementFilters,
        arrays: arrayFilters,
      },
    };
    return useQuery({
      queryKey: [
        'searchAllRPAs',
        organizationId,
        companyId,
        query,
        paginationProps,
        sortingProps,
        calculationRangeFiltersValue,
        otherRangeFilters,
        elementFilters,
        arrayFilters,
        compareTo,
      ],
      queryFn: async () => {
        const payrolls = await fetchSearchAllPayrolls({
          pathParams: {
            organizationId,
            companyId,
          },
          body: payrollBody,
        });
        let payslips: PayrollPayslipListWithMetadata = {
          data: [],
          total: 0,
          size: 0,
          page: 0,
        };
        const payslipBody = {
          filtering: {
            // ranges: calculationRangeFiltersValue,
            elements: {
              type: [type],
              status: ['approved'],
              payrollId: payrolls.data.map((payroll) => payroll.payrollId),
            },
          },
        };

        payslips = await fetchSearchAllPayslips({
          pathParams: {
            organizationId,
            companyId,
          },
          body: payslipBody,
        });

        const comparisons: Record<string, PayrollCalculationTotals> = {};
        if (compareTo) {
          const fetchComparisonPayrollsResult = await fetchSearchAllPayrolls({
            pathParams: {
              organizationId,
              companyId,
            },
            body: {
              ...payrollBody,
              filtering: {
                elements: {
                  ...elementFilters,
                  period: [compareTo],
                  contractId: payrolls.data.map(
                    (payroll) => payroll.contractId,
                  ),
                },
                arrays: arrayFilters,
              },
            },
          });
          for (const payroll of fetchComparisonPayrollsResult.data) {
            if (payroll.calculationTotals) {
              comparisons[payroll.contractId] = payroll.calculationTotals;
            }
          }
        }
        const payslipLookup = new Set(
          payslips.data.map((payslip) => payslip.payrollId),
        );
        const data = {
          ...payrolls,
          data: payrolls.data.map((payroll) => {
            return {
              ...payroll,
              ...(compareTo && {
                comparison: comparisons[payroll.contractId],
              }),
              ...{
                hasApprovedPayslip: payslipLookup.has(payroll.payrollId),
              },
            };
          }),
        };
        const typeStatusBuckets = data?.metadata?.buckets?.counters?.byProp?.[
          'type-status'
        ] as { [key: string]: any };
        const payslipsStatusByPeriod =
          payslips?.metadata?.buckets?.counters?.byProp?.[
            'type-period-status'
          ]?.['rpa'] ?? {};
        const payslipApprovedNotSent = Object.values(
          payslipsStatusByPeriod,
        ).reduce<number>((total, periodStatus) => {
          return (
            total + ((periodStatus as Record<string, number>)['approved'] ?? 0)
          );
        }, 0);
        const counters = typeStatusBuckets
          ? {
              open: typeStatusBuckets.rpa?.open ?? 0,
              approved: typeStatusBuckets.rpa?.approved ?? 0,
              closed:
                (typeStatusBuckets.rpa?.reconciled ?? 0) +
                (typeStatusBuckets.rpa?.closed ?? 0),
              payslipApprovedNotSent: payslipApprovedNotSent,
            }
          : undefined;

        return { data, counters };
      },
    });
  };

  const { data: response } = useFetch();

  useMemo((): void => {
    if (response?.counters) {
      setCountByStatus(response.counters);
    }
  }, [response?.counters, setCountByStatus]);

  return (
    <DataFetching
      useHook={useFetch}
      Loading={() => (
        <Box display="flex" flexDirection="column" gap="8px" pt={1}>
          <Skeleton variant="rounded" height={300} width="100%" />
        </Box>
      )}
      Data={({ data: response }) => {
        return response ? (
          <DataGrid<PayrollSummary>
            sortingProps={sortingProps}
            paginationProps={paginationProps}
            totalRowCount={response.data.total}
            getRowId={(row) => `${row.contractId}/${row.payrollId}`}
            rows={response.data.data}
            columns={getColumnsForStatus(selectedStatus)}
            onRowClick={(params) =>
              onRowClick({
                onWorkerClick,
                row: params.row,
                rows: response.data.data,
              })
            }
            emptyMessage={getEmptyMessageForStatus(selectedStatus)}
            getRowSx={() => ({
              height: '56px',
              'td:nth-last-of-type': {
                opacity: 0,
              },
              '&:hover': {
                'td:nth-last-of-type': {
                  opacity: 1,
                },
              },
            })}
          />
        ) : null;
      }}
    />
  );
}

function onRowClick({
  onWorkerClick,
  row,
  rows,
}: {
  row: PayrollSummary;
  onWorkerClick: (
    payrollId: string,
    contractId: string,
    rows: Array<PayrollSummary>,
    status: PayrollStatus,
  ) => void;
  rows: Array<PayrollSummary>;
}) {
  if (!row) {
    return;
  }

  onWorkerClick(row.payrollId, row.contractId, rows, row.status);
}

export function useFilters({
  legalEntities,
}: {
  legalEntities: LegalEntityEntry[];
}): FilterOptions {
  return [
    makeYearMonthPickerFilter({
      label: 'Competência',
      propertyToFilter: 'period',
    }),
    makeMoneyRangeFilter({
      label: 'Valor',
      propertyToFilter: summaryElements.workerEarningsTotal,
      getRangeMin: () => 0,
      getRangeMax: () => 15_000,
    }),
    makeDateRangeFilter({
      label: 'Data de pagamento',
      propertyToFilter: 'paymentDate',
    }),
    ...(legalEntities.length > 1
      ? [
          makeElementListFilter({
            label: 'Empregador',
            propertyToFilter: 'legalEntityId.keyword',
            elements: legalEntities.map(({ id }) => id),
            labels: legalEntities.reduce(
              (acc, { id, name }) => {
                acc[id] = name;
                return acc;
              },
              {} as Record<string, string>,
            ),
          }),
        ]
      : []),
    // TODO: use this filter once we have the data
    // makeElementListFilter({
    //   label: 'Envio de RPA',
    //   propertyToFilter: 'hasApprovedPayslip',
    //   elements: ['true', 'false'],
    //   labels: {
    //     true: 'Enviado',
    //     false: 'Não enviado',
    //   },
    // }),
  ].filter(Boolean);
}

const BASE_COLUMN_WIDTH = 150;

const columns: GridColDef<PayrollSummary>[] = [
  {
    field: 'name',
    headerName: 'Autônomo',
    sortable: true,
    flex: 1,
    width: BASE_COLUMN_WIDTH,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.workerData.name;
    },
  },
  {
    field: 'period',
    headerName: 'Competência',
    sortable: true,
    flex: 0,
    width: BASE_COLUMN_WIDTH,
    maxWidth: BASE_COLUMN_WIDTH,
    valueGetter: (params: GridValueGetterParams) => {
      return formatPeriodDate(params.row.period);
    },
  },
  {
    field: 'calculationTotals.workerEarningsTotal',
    headerName: 'Valor',
    sortable: true,
    flex: 0,
    width: BASE_COLUMN_WIDTH,
    maxWidth: BASE_COLUMN_WIDTH,
    valueGetter: (params: GridValueGetterParams) => {
      return formatMoney(params.row.calculationTotals.workerEarningsTotal);
    },
  },
  {
    field: 'paymentDate',
    headerName: 'Data de pagamento',
    sortable: true,
    flex: 0,
    width: BASE_COLUMN_WIDTH,
    maxWidth: BASE_COLUMN_WIDTH,
    valueGetter: (params: GridValueGetterParams) => {
      return formatDateBR(params.row.paymentDate || params.row.date);
    },
  },
].filter(Boolean);

function prepareSortField(orderBy?: string) {
  if (orderBy === 'name') {
    return 'workerData.name';
  }
  return orderBy;
}

function getColumnsForStatus(state: PayrollStatus) {
  switch (state) {
    case 'open':
      return [
        ...columns,
        {
          field: 'approvalDate',
          headerName: 'Aprovar até',
          flex: 0,
          width: BASE_COLUMN_WIDTH,
          maxWidth: BASE_COLUMN_WIDTH,
          valueGetter: (params: GridValueGetterParams) => {
            return formatDateBR(
              formatDateWithDaysAdded(
                params.row.paymentDate || params.row.date,
                -2,
              ),
            );
          },
        },
        {
          field: 'reviewCall',
          headerName: '',
          flex: 0,
          width: BASE_COLUMN_WIDTH,
          maxWidth: BASE_COLUMN_WIDTH,
          renderCell: () => {
            return (
              <Box
                display="flex"
                justifyContent="center"
                flexWrap="wrap"
                alignContent="center"
                height={'35px'}
              >
                <Typography variant="body2" color="info.main" fontWeight={550}>
                  Revisar
                </Typography>
                <ChevronRight
                  sx={{ color: 'blue', height: '17px', width: '17px' }}
                />
              </Box>
            );
          },
        },
      ];
    case 'approved':
    case 'closed':
      return [
        ...columns,
        {
          field: 'receipts',
          headerName: 'RPA',
          flex: 0,
          width: BASE_COLUMN_WIDTH,
          maxWidth: BASE_COLUMN_WIDTH,
          sortable: false,
          renderCell: (
            params: GridRenderCellParams<
              string | undefined,
              PayrollSummary & { hasApprovedPayslip: boolean }
            >,
          ) => {
            const { sendPayslipsProps, sendPayslips, SendPayslipsComponent } =
              // The linter is whining about this not being a React component because it doesn't
              // start with a capital letter!
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useSendPayslip({
                organizationId: params.row.organizationId,
                companyId: params.row.companyId,
              });

            const hasAlreadySent = !params.row.hasApprovedPayslip;

            if (hasAlreadySent) {
              return (
                <Box display="flex" justifyContent="flex-start" width="100px">
                  <Tag color="success">Enviado</Tag>
                </Box>
              );
            }

            const button = (
              <Box display="flex" justifyContent="center" width="100px">
                <Button
                  color="secondary"
                  endIcon={<Box component="img" src={SendIcon} />}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    sendPayslips({
                      payrollId: params.row.payrollId,
                    });
                  }}
                >
                  Enviar
                </Button>
              </Box>
            );

            return (
              <Box display="flex" alignItems="center">
                {hasGeneratedAllPayslips ? (
                  button
                ) : (
                  <Tooltip title="Os recibos ainda estão sendo gerados. Tente novamente dentro de alguns instantes.">
                    <span>{button}</span>
                  </Tooltip>
                )}
                <SendPayslipsComponent
                  {...sendPayslipsProps}
                  payrollType={params.row.type}
                />
              </Box>
            );
          },
        },
      ];
    default:
      return columns;
  }
}

function getEmptyMessageForStatus(state: PayrollStatus) {
  switch (state) {
    case 'open':
      return 'Não existem pagamentos em aberto.';
    case 'approved':
      return 'Não existem pagamentos aprovados.';
    case 'closed':
      return 'Não existem pagamentos fechados.';
    default:
      return 'Não há pagamentos.';
  }
}
