import { useEffect, useState } from 'react';

import { debounce } from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Dialog } from '@mui/material';

import { useResendUserCreatedInvite } from '@octopus/api';

import { useSnackbar } from '../../../modules/hooks/useSnackbar';

import { DialogContentLoading } from './dialog/DialogContentLoading';

type ResendUserCreatedInviteDialogProps = {
  organizationId: string;
  userId: string;
  isOpen: boolean;
  onClose: () => void;
};
export function ResendUserCreatedInviteDialog({
  organizationId,
  userId,
  isOpen,
  onClose,
}: ResendUserCreatedInviteDialogProps) {
  const { showSnackbar } = useSnackbar();
  const { isError, error, isLoading, mutate } = useResendUserCreatedInvite();
  const request = debounce(mutate);
  const [requestMade, setRequestMade] = useState(false);

  const failed = () => {
    console.error(error);
    onClose();
    setTimeout(() => {
      showSnackbar({
        isOpen: true,
        variant: 'error',
        Message:
          'Ocorreu um erro ao reenviar a senha temporária. Tente novamente.',
        StartAdornment: <CancelIcon />,
        hasCloseAction: true,
      });
    }, 400);
  };
  const succeeded = () => {
    onClose();
    setTimeout(() => {
      showSnackbar({
        isOpen: true,
        variant: 'default',
        Message: 'Senha temporária reenviada com sucesso.',
        StartAdornment: <CheckCircleIcon />,
        hasCloseAction: true,
      });
    }, 400);
  };

  useEffect(() => {
    console.log('executing request');
    request({
      pathParams: { organizationId, userId },
    });
    setRequestMade(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requestMade && !isLoading) {
      if (isError) {
        console.log('failed');
        failed();
        setRequestMade(false);
      } else {
        console.log('success!');
        succeeded();
        setRequestMade(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <Dialog
      PaperProps={{ sx: { minWidth: '600px' } }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogContentLoading message="Reenviando..." />
    </Dialog>
  );
}
