export type BackoffOptions = {
  /**
   * minimum/start backoff delay in milliseconds.
   */
  readonly min: number;
  /**
   * maximum backoff delay in milliseconds.
   */
  readonly max?: number;
  /**
   * backoff multiplication factor.
   */
  readonly factor?: number;
  /**
   * maximum amount of jitter to add to backoff delay in milliseconds
   * i.e. jitter added will be a random amount between 0 and `jitter`.
   */
  readonly jitter?: number;
};
const BACKOFF_DEFAULT: Required<BackoffOptions> = {
  min: 0,
  max: Number.MAX_SAFE_INTEGER,
  factor: 1,
  jitter: 0,
};
export type RetryOptions = {
  /**
   * maximum amount of attempts to call the decorated function.
   * notice that the first attempt is also counted e.g.
   * if `attempts=5` the decorated function will be called at most 5 times.
   */
  readonly attempts: number;
  /**
   * exponential backoff configuration.
   * if not set, no backoff strategy will be used.
   */
  readonly backoff?: BackoffOptions;
  /**
   * Callback to indicate whether or not an error should trigger a retry of the decorated function.
   *
   * @param {Error} error rejected by the decorated function
   * @returns {boolean} whether or not the error should trigger a retry attempt
   */
  readonly retryOn?: (error: Error) => boolean;
};
type Action<Args extends unknown[], Return> = (
  ...args: Args
) => Promise<Return>;

const wait = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

const randomBetween = (min: number, max: number) =>
  min + Math.random() * (max - min);

type Attempt = {
  readonly waitTime: number;
  readonly attempt: number;
};
function* getAttempts(options: RetryOptions): Generator<Attempt> {
  const backoff = {
    ...BACKOFF_DEFAULT,
    ...options.backoff,
  };

  for (let attempt = 1; attempt <= options.attempts; attempt++) {
    if (attempt === 1) {
      yield { waitTime: 0, attempt: attempt };
      continue;
    }
    if (attempt === 2) {
      yield { waitTime: backoff.min, attempt };
      continue;
    }

    // min * (factor ^ (attempt - 2)): -2, because it starts at attempt 1
    const factor = backoff.factor ?? BACKOFF_DEFAULT.factor;
    const backoffDelay = Math.min(
      backoff.min * Math.pow(factor, attempt - 2),
      backoff.max,
    );

    if (!backoff.jitter) {
      yield { waitTime: backoffDelay, attempt: attempt };
      continue;
    }

    const jitter = randomBetween(0, backoff.jitter);
    const jitteredDelay = Math.min(backoffDelay + jitter, backoff.max);

    yield {
      waitTime: jitteredDelay,
      attempt: attempt,
    };
  }
}

/**
 * Decorates a function with retry logic, allowing it to be retried multiple times with configurable backoff.
 *
 * @param options - Configuration options for retry behavior
 * @param options.attempts - Maximum number of retry attempts
 * @param options.retryOn - Optional predicate to determine if an error should trigger retry
 * @param options.backoff - Backoff configuration for delays between retries
 * @param options.backoff.min - Minimum delay in ms
 * @param options.backoff.max - Maximum delay in ms
 * @param options.backoff.factor - Exponential backoff multiplier (default: 2)
 * @param options.backoff.jitter - Random jitter range in ms to add to delays
 * @param fn - The function to wrap with retry logic
 * @returns A decorated function that will retry on failure according to the options
 */
export function withRetry<Args extends unknown[], Return>(
  options: RetryOptions,
  fn: Action<Args, Return>,
): Action<Args, Return> {
  const shouldRetry = options.retryOn ?? ((_) => true);

  const retriable = async (...args: Args): Promise<Return> => {
    const attempts = getAttempts(options);

    for (const { waitTime, attempt } of attempts) {
      if (waitTime > 0) {
        await wait(waitTime);
      }
      try {
        const result = await fn(...args);
        return result;
      } catch (err) {
        console.error(err, attempt);
        if (shouldRetry(err) && attempt < options.attempts) {
          continue;
        }
        return Promise.reject(err);
      }
    }
    return Promise.reject(Error('Unreachable retry state')); // added to appease the compiler;
  };

  return retriable;
}
