import {
  GeneratePdfReportVariables,
  fetchGeneratePdfReport,
} from '@octopus/api';

export const downloadPdfReport = async (args: GeneratePdfReportVariables) => {
  type PdfReportResponse = { fileContent: string; fileName: string };
  let file: PdfReportResponse | { fileContent: null; fileName: null } = {
    fileContent: null,
    fileName: null,
  };
  const maxAttempts = 120; // 5 minutes
  const delay = 5 * 1000; // 5 seconds
  let attempts = 0;

  do {
    attempts++;
    // Fetch the report (type is ReportsOutput = PdfReportResponse | { rawData: string; fileName: string })
    const response = await fetchGeneratePdfReport(args);

    // Assert that we expect PdfReportResponse in this context
    file = response as PdfReportResponse;
    if (file.fileContent) {
      break;
    }

    // Reset file if content wasn't found, to ensure loop condition works
    file = { fileContent: null, fileName: null };

    await new Promise((resolve) => setTimeout(resolve, delay));
  } while (!file.fileContent && attempts < maxAttempts);

  if (!file.fileContent) {
    throw new Error('Failed to download PDF report after multiple attempts');
  }

  return {
    fileContent: file.fileContent,
    fileName: file.fileName,
  };
};
