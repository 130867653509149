import { useContext } from 'react';

import { Alert, Box, Divider, Typography } from '@mui/material';

import { Button } from '@octopus/ui/design-system';

import { CompanyContext } from '../../../../modules/types';
import { AppContext } from '../../../context';
import { AdmissionFormState } from '../form/types';

import { cltEstagiarioSummaryFields } from './cltEstagiarioSummaryFields';
import { cltGeralSummaryFields } from './cltGeralSummaryFields';
import { pjSummaryFields } from './pjSummaryFields';

type Props = {
  disabled: boolean;
  formState: AdmissionFormState;
  onSubmit: ({ extraData }: { extraData?: Record<string, any> }) => void;
  submitLabel: string;
  isLoading: boolean;
  reviewMode: boolean;
};

export const NewAdmissionSummary = ({
  disabled,
  formState,
  onSubmit,
  submitLabel,
  isLoading,
  reviewMode,
}: Props) => {
  const { appContext } = useContext(AppContext);
  const { company: companyContext } = appContext;
  const fields = getSummaryFields({ formState, companyContext });

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 1.5,
        border: `1px solid ${theme.palette.strokes.light}`,
        paddingTop: 4,
        paddingBottom: 3,
        px: 4,
        minWidth: '480px',
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography variant="h4">Resumo</Typography>
        <Divider sx={{ my: 2 }} />
      </Box>
      {reviewMode &&
        <Alert severity="info">
          <Typography variant="body2" color="info.dark">
            O kit admissional fica disponível para ser gerado na aba ‘Concluídas’. Passe o mouse no final da linha e clique no menu para gerar o documento.
          </Typography>
        </Alert>
      }
      <Box
        component={'table'}
        width="100%"
        style={{
          borderSpacing: 12,
          marginLeft: '-8px',
        }}
      >
        <thead></thead>
        <tbody style={{ width: '100%' }}>
          {fields.map(({ label, value }) => (
            <tr key={label} style={{ width: '100%' }}>
              <td style={{ width: '50%' }}>
                <Typography
                  sx={{ color: 'text.secondary' }}
                  variant="body2"
                  fontWeight="normal"
                >
                  {label}
                </Typography>
              </td>
              <td style={{ width: '50%' }}>
                <Typography variant="body2">{value}</Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </Box>
      <Button
        onClick={() => onSubmit({})}
        variantSemantic="primary"
        sx={{ width: '100%', mt: 2 }}
        isLoading={isLoading}
        disabled={disabled || isLoading}
        data-testid={`submit-finaliza_admissao`}
      >
        {submitLabel}
      </Button>
    </Box>
  );
};

const getSummaryFields = ({
  formState,
  companyContext,
}: {
  formState: AdmissionFormState;
  companyContext: CompanyContext;
}) => {
  switch (formState.workerCategory) {
    case 'clt:geral':
    case 'clt:jovemAprendiz':
    case 'clt:tsv':
      return cltGeralSummaryFields.getSummaryFields({
        formState,
        companyContext,
      });

    case 'clt:estagiario':
      return cltEstagiarioSummaryFields.getSummaryFields({
        formState,
        companyContext,
      });

    case 'pj':
      return pjSummaryFields.getSummaryFields({
        formState,
        companyContext,
      });

    default:
      throw new Error(
        `getSummaryFields not implemented for workercategory ${formState.workerCategory}`,
      );
  }
};
