import { useMemo } from 'react';

import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';

import { TimesheetStepError, TimesheetStepErrorCode } from '../types';
import { TIMESHEET_ERROR_DESCRIPTION_MAPPING } from '../utils';

import { TimesheetIssueAffected } from './TimesheetIssueAffected';

export function TimesheetIssue({
  error,
  defaultExpanded,
  index,
}: {
  error: { code: TimesheetStepErrorCode; lines: TimesheetStepError[] };
  defaultExpanded: boolean;
  index: number;
}) {
  const detail = useMemo(() => {
    return (
      TIMESHEET_ERROR_DESCRIPTION_MAPPING[error.code] ??
      TIMESHEET_ERROR_DESCRIPTION_MAPPING.UNKNOWN_ERROR
    );
  }, [error.code]);

  return (
    <>
      {index > 0 && (
        <Box
          key={`box-${error.code}-${index}`}
          data-testid="timesheet-issues-divider"
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Divider
            key={`divider-${error.code}-${index}`}
            sx={{ width: 'calc(100% - 40px)' }}
            variant="fullWidth"
          />
        </Box>
      )}

      <Accordion
        data-testid="timesheet-issue"
        defaultExpanded={defaultExpanded}
        elevation={0}
        sx={{
          width: '100%',
          border: 'none',
          m: 0,
          '&.Mui-expanded': {
            m: 0,
          },
          bgcolor: 'inherit',
          '&:before': {
            display: 'none',
          },
          '&.Mui-expanded::before': {
            display: 'none',
          },
          '&:first-of-type': {
            borderTopLeftRadius: (theme) => theme.spacing(1.5),
            borderTopRightRadius: (theme) => theme.spacing(1.5),
            pt: (theme) => theme.spacing(0.5),
          },
          '&:last-of-type': {
            borderBottomLeftRadius: (theme) => theme.spacing(1.5),
            borderBottomRightRadius: (theme) => theme.spacing(1.5),
            pb: (theme) => theme.spacing(0.5),
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          data-testid="timesheet-issue-summary"
          sx={{
            paddingX: (theme) => theme.spacing(2.5),
            paddingY: (theme) => theme.spacing(2),
            minHeight: 0,
            transition: 'all 0.2s',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: 0,
            },
            '&.Mui-expanded': {
              margin: 0,
              pb: 0,
              minHeight: 0,
            },
          }}
        >
          <Typography variant="caption">
            <b>
              {error.lines.length > 1
                ? detail.title.plural
                : detail.title.singular}
            </b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          data-testid="timesheet-issue-details"
          sx={{
            paddingX: 2.5,
            paddingTop: 1,
            paddingBottom: 2,
            borderRadius: 1,
          }}
        >
          <TimesheetIssueAffected error={error} detail={detail} />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
