import {
  fetchGetAllJobTitles,
  fetchSearchAllCostCenters,
  fetchSearchDepartments,
} from '@octopus/api';

import { pollUntil } from '../utils';

export async function pollJobTitle({
  organizationId,
  jobTitleId,
  extraFilters = {},
  intervalMillis = 500,
  timeoutSeconds = 5,
}: {
  organizationId: string;
  jobTitleId: string;
  extraFilters?: Record<string, string[]>;
  intervalMillis?: number;
  timeoutSeconds?: number;
}) {
  try {
    await pollUntil({
      action: () =>
        fetchGetAllJobTitles({
          pathParams: {
            organizationId,
          },
          queryParams: {
            elementFilters: JSON.stringify({
              jobTitleId: [jobTitleId],
              ...extraFilters,
            }),
          },
        }),
      assertion: (list) => list.data.length === 1,
      intervalMillis,
      timeoutSeconds,
    });
  } catch (_) {
    console.warn('Polling for job title failed');
  }
}

export async function pollCostCenter({
  organizationId,
  costCenterId,
  extraFilters = {},
  intervalMillis = 500,
  timeoutSeconds = 10,
}: {
  organizationId: string;
  costCenterId: string;
  extraFilters?: Record<string, (string | number | boolean)[]>;
  intervalMillis?: number;
  timeoutSeconds?: number;
}) {
  try {
    await pollUntil({
      action: () =>
        fetchSearchAllCostCenters({
          pathParams: {
            organizationId,
          },
          body: {
            filtering: {
              elements: {
                costCenterId: [costCenterId],
                ...extraFilters,
              },
            },
          },
        }),
      assertion: (list) => list.data.length === 1,
      intervalMillis,
      timeoutSeconds,
    });
  } catch (_) {
    console.warn('Polling for cost center failed');
  }
}

export async function pollDepartment({
  organizationId,
  departmentId,
  extraFilters = {},
  intervalMillis = 500,
  timeoutSeconds = 10,
}: {
  organizationId: string;
  departmentId: string;
  extraFilters?: Record<string, (string | number | boolean)[]>;
  intervalMillis?: number;
  timeoutSeconds?: number;
}) {
  try {
    await pollUntil({
      action: () =>
        fetchSearchDepartments({
          pathParams: {
            organizationId,
          },
          body: {
            filtering: {
              elements: {
                departmentId: [departmentId],
                ...extraFilters,
              },
            },
          },
        }),
      assertion: (list) => list.data.length === 1,
      intervalMillis,
      timeoutSeconds,
    });
  } catch (_) {
    console.warn('Polling for departments failed');
  }
}
