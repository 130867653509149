import React from 'react';

import {
  AdmissionDraftInputFormInfo,
  AdmissionDraftInputFormSteps,
  AdmissionDraftStatus,
  AdmissionType,
} from '@octopus/api';
import { GrauInstrucao } from '@octopus/esocial/mapper';
import { z } from '@octopus/i18n';
import { IFormDefinition, UI_TYPE } from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { OrganizationContext } from '../../../../../modules/types';
import { AdmissionReviewDependent } from '../../../review/AdmissionReviewDependents';
import { AddNewDependent } from '../../dependentes/NewAdmissionDependents';
import { NewAdmissionDocuments } from '../../documents/NewAdmissionDocuments';
import { NewProfilePicture } from '../../pictures/NewProfilePicture';
import { NewAdmissionSummary } from '../../summary/NewAdmissionSummary';
import {
  getDadosBancariosFields,
  getDeficienciaFields,
} from '../fieldDefinitions';
import { getDadosPessoaisFields } from '../fieldDefinitions/dadosPessoais';
import { getEnderecoEContatoFields } from '../fieldDefinitions/enderecoEContatos';
import { getOptionsFromMapper } from '../inputUtils';
import {
  AdmissionFormState,
  AdmissionFormSteps,
  AdmissionIds,
  FormStepDefinition,
} from '../types';

import { getCustomFieldsStepDefinition } from './customFields';
import { TFFlags } from '../../../../fflags';

export const getWorkerSteps = async ({
  admissionIds,
  formState,
  formSteps,
  finalizaAdmissao,
  isPj,
  organizationContext,
}: {
  admissionIds: AdmissionIds;
  formState: AdmissionFormState;
  formSteps: AdmissionDraftInputFormSteps;
  finalizaAdmissao: FormStepDefinition;
  isPj: boolean;
  organizationContext: OrganizationContext;
}): Promise<AdmissionFormSteps> => {
  const { grauInstr } = formState;
  const dados_pessoais = getDadosPessoaisFields({
    formState,
    isPj,
    dadosPessoaisFormSteps: formSteps[admissionDraftFormSteps.dados_pessoais],
  });

  const formacao_academica = {
    definition: [
      {
        label: 'Escolaridade',
        type: z.number().int(),
        name: 'grauInstr',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione',
        options: getOptionsFromMapper({
          mapper: GrauInstrucao,
          currentValue: grauInstr,
        }),
      },
    ],
    options: {
      id: admissionDraftFormSteps.formacao_academica,
      title: 'Formação acadêmica',
      completed:
        formSteps[admissionDraftFormSteps.formacao_academica]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.formacao_academica]?.reviewed,
    },
  };

  const pessoa_com_deficiencia = getDeficienciaFields({
    formState,
    deficienciaFormSteps: formSteps[admissionDraftFormSteps.deficiencias],
  });

  const dados_bancarios = getDadosBancariosFields({
    formState,
    dadosBancariosFormSteps: formSteps[admissionDraftFormSteps.dados_bancarios],
    organizationContext,
  });

  const documents_section = {
    definition: [] as IFormDefinition,
    options: {
      getCustomComponent: ({
        updateDisabled,
        isLoading,
        formState,
        stepDefinition,
        onSubmitCallback,
        goToNextStep,
        goToPreviousStep,
        organizationId,
        draftId,
        submitLabel,
      }: {
        updateDisabled: boolean;
        isLoading: boolean;
        formState: AdmissionFormState;
        stepDefinition: FormStepDefinition;
        goToNextStep: () => void;
        goToPreviousStep: () => void;
        onSubmitCallback: (newFormState: AdmissionFormState) => void;
        organizationId: string;
        draftId: string;
        submitLabel: string;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }
        return (
          <NewAdmissionDocuments
            disabled={updateDisabled}
            isLoading={isLoading}
            formState={formState}
            stepDefinition={stepDefinition}
            onSubmitCallback={onSubmitCallback}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            organizationId={organizationId}
            draftId={draftId}
            submitLabel={submitLabel}
            workerCategory={formState.workerCategory}
            organizationContext={organizationContext}
          />
        );
      },
      id: admissionDraftFormSteps.documentos,
      title: 'Documentos',
      completed: formSteps[admissionDraftFormSteps.documentos]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.documentos]?.reviewed,
    },
  };

  const profile_picture_section = {
    definition: [] as IFormDefinition,
    options: {
      getCustomComponent: ({
        updateDisabled,
        isLoading,
        formState,
        stepDefinition,
        goToNextStep,
        goToPreviousStep,
        organizationId,
        draftId,
        submitLabel,
      }: {
        updateDisabled: boolean;
        isLoading: boolean;
        formState: AdmissionFormState;
        stepDefinition: FormStepDefinition;
        goToNextStep: () => void;
        goToPreviousStep: () => void;
        organizationId: string;
        draftId: string;
        submitLabel: string;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }
        return (
          <NewProfilePicture
            disabled={updateDisabled}
            isLoading={isLoading}
            formState={formState}
            stepDefinition={stepDefinition}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            organizationId={organizationId}
            draftId={draftId}
            submitLabel={submitLabel}
            workerCategory={formState.workerCategory}
            profilePictureId={formState.profilePictureId}
          />
        );
      },
      id: admissionDraftFormSteps.foto_perfil,
      title: 'Foto de perfil',
      completed: formSteps[admissionDraftFormSteps.foto_perfil]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.foto_perfil]?.reviewed,
    },
  };

  const dependentes_section = {
    definition: [] as IFormDefinition,
    options: {
      getCustomComponent: ({
        updateDisabled,
        isLoading,
        formState,
        stepDefinition,
        onSubmit,
        goToPreviousStep,
        organizationId,
        draftId,
        submitLabel,
      }: {
        updateDisabled: boolean;
        isLoading: boolean;
        formState: AdmissionFormState;
        onSubmit: ({ extraData }: { extraData?: Record<string, any> }) => void;
        stepDefinition: FormStepDefinition;
        goToPreviousStep: () => void;
        organizationId: string;
        draftId: string;
        submitLabel: string;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }
        return (
          <AddNewDependent
            disabled={updateDisabled}
            isLoading={isLoading}
            formState={formState}
            stepDefinition={stepDefinition}
            onSubmit={onSubmit}
            goToPreviousStep={goToPreviousStep}
            organizationId={organizationId}
            draftId={draftId}
            submitLabel={submitLabel}
          />
        );
      },
      id: admissionDraftFormSteps.dependentes,
      title: 'Dependentes',
      completed: formSteps[admissionDraftFormSteps.dependentes]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.dependentes]?.reviewed,
    },
  };

  const endereco_e_contatos = getEnderecoEContatoFields({
    formState,
    enderecoFormSteps: formSteps[admissionDraftFormSteps.endereco_e_contatos],
  });

  const custom_fields_worker = await getCustomFieldsStepDefinition({
    admissionIds,
    filledBy: 'worker',
    formState,
    formSteps,
  });

  return {
    dados_pessoais,
    formacao_academica,
    endereco_e_contatos,
    deficiencias: pessoa_com_deficiencia,
    dependentes: dependentes_section,
    dados_bancarios,
    ...(custom_fields_worker && { custom_fields_worker }),
    foto_perfil: profile_picture_section,
    documentos: documents_section,
    ...(finalizaAdmissao && { finaliza_admissao: finalizaAdmissao }),
  };
};

export const getReviewDependentStep = ({
  fieldStepsState,
  dependentId,
}: {
  fieldStepsState: AdmissionDraftInputFormInfo;
  dependentId: string;
}): FormStepDefinition => {
  const formSteps = fieldStepsState?.formSections?.['personal_data'] ?? {};
  return {
    definition: [] as IFormDefinition,
    options: {
      getCustomComponent: ({
        formState,
        stepDefinition,
        onSubmitCallback,
        organizationId,
        draftId,
        formId,
      }: {
        updateDisabled: boolean;
        isLoading: boolean;
        formState: AdmissionFormState;
        stepDefinition: FormStepDefinition;
        onSubmitCallback: (newFormState: AdmissionFormState) => void;
        organizationId: string;
        draftId: string;
        formId?: string;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }
        return (
          <AdmissionReviewDependent
            formState={formState}
            stepDefinition={stepDefinition}
            onSubmitCallback={onSubmitCallback}
            organizationId={organizationId}
            draftId={draftId}
            formId={formId}
            dependentId={dependentId}
          />
        );
      },
      id: admissionDraftFormSteps.dependentes,
      title: 'Dependentes',
      dependentId,
      completed: formSteps[admissionDraftFormSteps.dependentes]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.dependentes]?.reviewed,
    },
  };
};

export const getAdminSteps = async ({
  admissionIds,
  formState,
  formSteps,
}: {
  admissionIds: AdmissionIds;
  formState: AdmissionFormState;
  formSteps: AdmissionDraftInputFormSteps;
}): Promise<AdmissionFormSteps> => {
  const custom_fields_admin = await getCustomFieldsStepDefinition({
    filledBy: 'admin',
    admissionIds,
    formState,
    formSteps,
  });

  return {
    custom_fields_admin,
  };
};

export const getSummaryStep = ({
  draftStatus,
  admissionType,
  stepName,
  formSteps,
  isWorkerExperience,
  fflags,
}: {
  draftStatus: AdmissionDraftStatus;
  admissionType: AdmissionType;
  stepName: string;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
    fflags?: TFFlags;
}): FormStepDefinition => {
  if (
    (admissionType === 'admin_fills' && stepName !== 'finaliza_admissao') ||
    (admissionType === 'send_invite' && stepName !== 'envio_convite') ||
    isWorkerExperience
  ) {
    return null;
  }

  const cta =
    admissionType === 'admin_fills'
      ? draftStatus !== 'admission_submitted'
        ? 'Enviar admissão para revisão'
        : 'Concluir admissão'
      : draftStatus !== 'draft_created'
        ? 'Reenviar convite'
        : 'Salvar candidato(a) e enviar convite';

  return {
    definition: [] as IFormDefinition,
    options: {
      id: stepName as keyof typeof admissionDraftFormSteps,
      title: 'Resumo e finalização',
      hideTitle: true,
      completed: formSteps[stepName]?.completed,
      reviewed: formSteps[stepName]?.reviewed,

      getCustomComponent: ({
        updateDisabled,
        formState,
        stepDefinition,
        onSubmit,
        isLoading,
      }: {
        updateDisabled: boolean;
        formState: AdmissionFormState;
        stepDefinition: FormStepDefinition;
        onSubmit: ({ extraData }: { extraData?: Record<string, any> }) => void;
        isLoading: boolean;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }

        return (
          <NewAdmissionSummary
            submitLabel={cta}
            formState={formState}
            onSubmit={onSubmit}
            isLoading={isLoading}
            disabled={updateDisabled}
            reviewMode={fflags?.kitAdmissionalEnabled && admissionType === 'admin_fills' && (draftStatus === 'admission_submitted' || draftStatus === 'contract_created')}
          />
        );
      },
    },
  };
};
