import { useRef, useState } from 'react';

import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, MenuItem, Popover, Typography } from '@mui/material';

import {
  VacationsScheduleSummary,
  fetchDownloadTemplateAttachments,
} from '@octopus/api';

import { useSnackbar } from '../../../../modules/hooks/useSnackbar';
import { useDownloadPayslips } from '../../../../modules/pdf/useDownloadPayslips';
import { downloadFile } from '../../../../utils';
import { useFFlags } from '../../../fflags';
import {
  canDownloadVacationNotice,
  canDownloadVacationReceipt,
} from '../../utils/status';

export const HistoryActions = ({ row }: { row: VacationsScheduleSummary }) => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  const isLoading = false;
  const { showSnackbar } = useSnackbar();
  const { downloadSinglePayslip } = useDownloadPayslips();
  const { fflags } = useFFlags();
  const isKitFeriasEnabled = fflags.kitFeriasEnabled.enabled;

  const handleDownloadNotice = () => {
    const { organizationId, contractId, workerData, sequence } = row;

    showSnackbar({
      isOpen: true,
      variant: 'default',
      Message: 'Documento gerado, iniciando download',
      hasCloseAction: false,
    });

    fetchDownloadTemplateAttachments({
      pathParams: {
        organizationId,
        templateId: `aviso-${contractId}-${sequence}`,
      },
      body: {
        workerName: workerData.name,
      },
    })
      .then((response) => {
        downloadFile(response.downloadUrl);
      })
      .catch((error) => {
        console.error('Erro ao baixar aviso de férias:', error);
        showSnackbar({
          isOpen: true,
          variant: 'error',
          Message: 'Erro ao baixar aviso de férias',
          hasCloseAction: true,
        });
      });

    setOpen(false);
  };

  const handleDownloadReceipt = () => {
    const {
      organizationId,
      companyId,
      contractId,
      payrollId,
      workerData,
      sequence,
    } = row;

    showSnackbar({
      isOpen: true,
      variant: 'default',
      Message: 'Documento gerado, iniciando download',
      hasCloseAction: false,
    });

    fetchDownloadTemplateAttachments({
      pathParams: {
        organizationId,
        templateId: `recibo-${contractId}-${sequence}`,
      },
      body: {
        workerName: workerData.name,
      },
    })
      .then((response) => {
        downloadFile(response.downloadUrl);
      })
      .catch((error) => {
        console.error(
          'Erro ao baixar recibo pelo template, tentando método alternativo:',
          error,
        );

        downloadSinglePayslip({
          organizationId,
          companyId,
          contractId,
          payrollId,
        });
      });

    setOpen(false);
  };

  // Verificar se as férias têm status para permitir baixar o aviso
  const canDownloadNotice = canDownloadVacationNotice(row.status);
  const canDownloadReceipt = canDownloadVacationReceipt(
    row.status,
    row.payrollId,
  );

  if (!isKitFeriasEnabled || (!canDownloadNotice && !canDownloadReceipt)) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="flex-end">
      <IconButton
        size="small"
        onClick={(event) => {
          event.preventDefault();
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        sx={{
          borderRadius: '8px',
          padding: '4px',
          opacity: open ? 1 : 'var(--VacationActionMenu-opacity, 0)',
        }}
      >
        <MoreVert
          fontSize="inherit"
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => {
          if (!isLoading) {
            setOpen(false);
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        elevation={1}
        sx={{
          fontSize: '14px',
          transform: 'translateY(-5px) translateX(5px)',
          '.MuiPaper-root': {
            padding: '4px',
            minWidth: '9.9215em',
          },
        }}
      >
        {canDownloadNotice && (
          <MenuItem
            onClick={() => {
              handleDownloadNotice();
            }}
            sx={{
              p: 0,
              borderRadius: '4px',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                my: '8px',
                ml: '12px',
                mr: '32px',
              }}
            >
              Baixar Aviso de Férias
            </Typography>
          </MenuItem>
        )}

        {canDownloadReceipt && (
          <MenuItem
            onClick={() => {
              handleDownloadReceipt();
            }}
            sx={{
              p: 0,
              borderRadius: '4px',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                my: '8px',
                ml: '12px',
                mr: '32px',
              }}
            >
              Baixar Recibo de Férias
            </Typography>
          </MenuItem>
        )}
      </Popover>
    </Box>
  );
};
