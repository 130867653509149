import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

export function RemoveAllAutonomosFromListModal({
  onSubmit,
  close,
}: {
  onSubmit: () => void;
  close: () => void;
}) {
  return (
    <Box width={'600px'}>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'flex-start',
            m: 0,
            width: '100%',
          }}
        >
          <Typography variant="h5" fontSize="24px" fontWeight={700}>
            Remover todos da lista
          </Typography>

          <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
            <Typography variant="body1">
              {`Ao confirmar todos os autônomos serão removidos juntamente com seus valores preenchidos. Deseja prosseguir?`}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.primary',
            width: '140px',
            height: '40px',
            px: 4,
            ':hover': {
              backgroundColor: 'strokes.light',
            },
          }}
          size="large"
          onClick={() => {
            close();
          }}
          data-testid="cancel-approve-payroll-button"
        >
          Voltar
        </Button>
        <Button
          color="error"
          variant="contained"
          size="large"
          sx={{
            width: '140px',
            height: '40px',
          }}
          onClick={onSubmit}
          data-testid="confirm-approve-payroll-button"
        >
          Remover
        </Button>
      </DialogActions>
    </Box>
  );
}
