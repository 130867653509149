import { useEffect, useState } from 'react';

import { Close } from '@mui/icons-material';
import { Box, Button, Drawer, Skeleton } from '@mui/material';

import {
  PayrollEntry,
  PayrollPayslipEntry,
  useGetPayroll,
  useGetPayrollPayslip,
} from '@octopus/api';

import { DataFetching } from '../../dataFetching';
import { PayslipActionsMenu } from '../payslips/ActionsMenu';

import Payslip from './Payslip';
import { useArchivePayrollModal } from './useArchivePayroll';

export function PayslipDetailsDrawer({
  showPayslipDrawer,
  closePayslipDrawer,
  organizationId,
  companyId,
  payrollId,
}: {
  organizationId: string;
  companyId: string;
  payrollId: string;
  showPayslipDrawer: boolean;
  closePayslipDrawer: () => void;
}) {
  return (
    <Drawer
      anchor={'bottom'}
      open={showPayslipDrawer}
      onClose={closePayslipDrawer}
      elevation={2}
      sx={{
        bgcolor: 'transparent',
        p: { md: 3, sm: 0 },
        px: 10,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '100vw',
          height: '100%',
          transition: 'transform 1.5s ease-in-out', // Apply transition to the drawer paper
          transform: showPayslipDrawer ? 'translateX(0)' : 'translateX(-100%)', // Slide effect when drawer is open/closed
        },
      }}
    >
      <Box
        sx={{
          borderRadius: 'md',
          p: 2,
          px: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          color="secondary"
          size="small"
          onClick={() => {
            closePayslipDrawer();
          }}
          sx={{
            borderColor: 'transparent',
            borderRadius: '50%',
            minWidth: '36px',
            minHeight: '36px',
            height: '36px',
            width: '36px',
            alignSelf: 'flex-end',
          }}
        >
          <Close sx={{ fontSize: '32px' }} />
        </Button>

        {payrollId && (
          <PayslipDetailsComponent
            organizationId={organizationId}
            companyId={companyId}
            payrollId={payrollId}
            closePayslipDrawer={closePayslipDrawer}
          />
        )}
      </Box>
    </Drawer>
  );
}

function PayslipDetailsComponent({
  organizationId,
  companyId,
  payrollId,
  closePayslipDrawer,
}: {
  organizationId: string;
  companyId: string;
  payrollId: string;
  closePayslipDrawer: () => void;
}) {
  const [currentPeriodPayrollId, setCurrentPeriodPayrollId] =
    useState<string>(payrollId);

  useEffect(() => {
    setCurrentPeriodPayrollId(payrollId);
  }, [payrollId]);

  const useGetPayrollPayslipResult = useGetPayrollPayslip({
    pathParams: {
      organizationId,
      companyId,
      payrollId: currentPeriodPayrollId,
    },
  });

  const useGetPayrollResult = useGetPayroll({
    pathParams: {
      organizationId,
      companyId,
      payrollId: currentPeriodPayrollId,
    },
  });

  const { archivePayrollProps, archivePayroll, ArchivePayrollModal } =
    useArchivePayrollModal(organizationId, companyId);

  useEffect(() => {
    if (archivePayrollProps.isSuccess) {
      closePayslipDrawer();
    }
  }, [archivePayrollProps.isSuccess, closePayslipDrawer]);

  return (
    <DataFetching<{
      payslipData: PayrollPayslipEntry;
      payrollData: PayrollEntry;
    }>
      containerSx={{ height: '100%', maxWidth: 'sm' }}
      fetchResult={{
        results: {
          payslipData: useGetPayrollPayslipResult,
          payrollData: useGetPayrollResult,
        },
      }}
      Loading={() => (
        <Box
          display="flex"
          flexDirection="column"
          gap="8px"
          p={4}
          width="560px"
        >
          <Skeleton variant="rounded" width="100%" height={60} />
          <Skeleton variant="rounded" width="100%" height={50} sx={{ mt: 1 }} />
          <Box display="flex" pt={2} pb={3} gap="4px">
            <Skeleton variant="rounded" width={70} height={30} />
            <Skeleton variant="rounded" width={70} height={30} />
          </Box>
          <Skeleton variant="rounded" width={100} height={40} />
          {new Array(5).fill(null).map((_, index) => (
            <Skeleton key={index} variant="rounded" width="100%" height={40} />
          ))}
        </Box>
      )}
      Data={({ data }) => {
        if (!data) {
          return null;
        }
        const { payslipData, payrollData } = data;

        return (
          <>
            <Payslip
              payslip={payslipData}
              actionMenu={() => (
                <PayslipActionsMenu
                  payslip={payslipData}
                  payrollStatus={payrollData.status}
                  cancelAction={() => {
                    archivePayroll(
                      payrollId,
                      payrollData.workerData.name,
                      payrollData.type,
                    );
                  }}
                />
              )}
            />
            <ArchivePayrollModal {...archivePayrollProps} />
          </>
        );
      }}
    />
  );
}
