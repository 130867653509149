import { DayOfWeek, LocalDate, TemporalAdjusters } from '@js-joda/core';

import { findNearestBankingDate, isBankingDay } from '@octopus/date-utils';

import { PaymentDateConfiguration, PaymentWeekDay } from './configurations';

function getPaymentDate(
  startDate: string,
  paymentConfig: PaymentDateConfiguration,
): string {
  const {
    amountOfDaysBeforeStart,
    typeOfDays,
    paymentWeekDays,
    whatToDoIfPaymentDateIsNotBankingDay,
  } = paymentConfig;

  const strategies = {
    calendar: findPaymentDateWithCalendarDays,
    banking: findPaymentDateWithBankingDays,
  };

  return strategies[typeOfDays](
    startDate,
    amountOfDaysBeforeStart,
    paymentWeekDays,
    whatToDoIfPaymentDateIsNotBankingDay,
  );
}

function findPaymentDateWithCalendarDays(
  startDate: string,
  amountOfDaysBeforeStart: number,
  paymentWeekDays: PaymentWeekDay[],
  whatToDoIfPaymentDateIsNotBankingDay: PaymentDateConfiguration['whatToDoIfPaymentDateIsNotBankingDay'],
): string {
  const paymentDate = LocalDate.parse(startDate).minusDays(
    amountOfDaysBeforeStart,
  );

  if (paymentWeekDays && paymentWeekDays.length > 0) {
    return findNearestDayOfWeek(
      paymentDate,
      paymentWeekDays,
      whatToDoIfPaymentDateIsNotBankingDay,
    );
  }

  if (
    whatToDoIfPaymentDateIsNotBankingDay === 'findNext' &&
    !isBankingDay(paymentDate)
  ) {
    return findNearestBankingDate(paymentDate).toString();
  }

  return paymentDate.toString();
}

function findNearestDayOfWeek(
  ref: LocalDate,
  paymentWeekDays: PaymentWeekDay[],
  whatToDoIfPaymentDateIsNotBankingDay: PaymentDateConfiguration['whatToDoIfPaymentDateIsNotBankingDay'],
): string {
  // remove weekDays that are after current day of week
  const filtered = paymentWeekDays.filter(
    (weekDay) =>
      DayOfWeek.valueOf(weekDay.toUpperCase()).value() <=
      ref.dayOfWeek().value(),
  );

  // find to which week day we need to adjust our date to
  // if all where filtered, we use the last of the array
  const closestWeekDay =
    filtered.length > 0 ? filtered.at(-1) : paymentWeekDays.at(-1);

  if (!closestWeekDay) {
    return ref.toString();
  }

  // adjust the date to the desired weekDay
  const date = ref.with(
    TemporalAdjusters.previousOrSame(
      DayOfWeek.valueOf(closestWeekDay.toUpperCase()),
    ),
  );

  if (
    !isBankingDay(date) &&
    whatToDoIfPaymentDateIsNotBankingDay === 'findNext'
  ) {
    return findNearestBankingDate(date).toString();
  }

  return date.toString();
}

function findPaymentDateWithBankingDays(
  startDate: string,
  amountOfDaysBeforeStart: number,
  paymentWeekDays: PaymentWeekDay[],
  whatToDoIfPaymentDateIsNotBankingDay: PaymentDateConfiguration['whatToDoIfPaymentDateIsNotBankingDay'],
): string {
  let currDate = LocalDate.parse(startDate);

  const sortedPaymentWeekDays = paymentWeekDays.sort(
    (a, b) =>
      DayOfWeek.valueOf(a.toUpperCase()).value() -
      DayOfWeek.valueOf(b.toUpperCase()).value(),
  );

  for (let i = 1; i <= amountOfDaysBeforeStart; i++) {
    currDate = findNearestBankingDate(currDate.minusDays(1));
  }

  if (sortedPaymentWeekDays && sortedPaymentWeekDays.length > 0) {
    return findNearestDayOfWeek(
      currDate,
      sortedPaymentWeekDays,
      whatToDoIfPaymentDateIsNotBankingDay,
    );
  }

  return currDate.toString();
}

export const vacationsPaymentDateService = {
  getPaymentDate: getPaymentDate,
};
