import { useMaskito } from '@maskito/react';
import { v4 as uuidv4 } from 'uuid';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Typography } from '@mui/material';

import { ContractBRCltDependent, ContractDocumentEntry } from '@octopus/api';
import { Sexo, TipoDependente } from '@octopus/esocial/mapper';
import {
  formatBooleanBR,
  formatCPF,
  formatDateBR,
  getOnlyDigits,
} from '@octopus/formatters';

import { MaskitoOptionsBR } from '../../../../form/Field/MaskitoOptions';
import {
  Record,
  RecordEntry,
  RecordEntryGroup,
  mapperToOptions,
} from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';
import { DependenteDocuments } from '../DependenteDocuments';

type DependentesRecordData = {
  dependentes: ContractBRCltDependent[] | undefined;
};

export type DependentesRecordProps = BaseRecordProps<DependentesRecordData> & {
  organizationId: string;
  contractId: string;
  dependentDocuments: ContractDocumentEntry[] | undefined;
  refetchDocuments?: () => void;
};

export function DependentesRecord(props: DependentesRecordProps) {
  const { organizationId, contractId, dependentDocuments, refetchDocuments } =
    props;
  const { dependentes } = props.data;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);

  const prepareOnChange =
    (
      index: number,
      field: keyof ContractBRCltDependent,
      formatter?: (value: string) => string | number | boolean,
    ) =>
    (value: string) =>
      updateData((data) => {
        return {
          ...data,
          dependentes: data.dependentes.map((dep, i) => {
            if (i === index) {
              if (!value) {
                const { [field]: _, ...rest } = dep;
                return { ...rest } as ContractBRCltDependent;
              }
              return {
                ...dep,
                id: dep.id || uuidv4(),
                [field]: formatter ? formatter(value) : value,
              };
            }
            return dep;
          }),
        };
      });

  const addButton = (
    <Box display="flex" width="100%" justifyContent="flex-end">
      <Button
        variant="outlined"
        color="primaryAlt"
        onClick={() => {
          updateData((data) => ({
            ...data,
            dependentes: [
              ...(data?.dependentes ?? []),
              {
                nmDep: '',
                incTrab: false,
                depIRRF: false,
                depSF: false,
              } as ContractBRCltDependent,
            ],
          }));
        }}
      >
        Adicionar novo dependente
      </Button>
    </Box>
  );

  const dependentesList = editing ? formData.dependentes : dependentes;

  if (!dependentesList || dependentesList.length === 0) {
    return (
      <Record title="Dependentes" edit={editRecordProps}>
        <RecordEntry label="Nenhum dependente cadastrado" hideValue={!editing}>
          {addButton}
        </RecordEntry>
      </Record>
    );
  }

  return (
    <Record title="Dependentes" edit={editRecordProps}>
      <Box display="flex" flexDirection="column" gap={2}>
        {dependentesList.map((dependente, index) => (
          <DependenteEntry
            key={index}
            dependente={dependente}
            dependentDocuments={dependentDocuments?.filter(
              (document) => document.dependentId === dependente.id,
            )}
            refetchDocuments={refetchDocuments}
            editing={editing}
            organizationId={organizationId}
            contractId={contractId}
            prepareOnChange={(field, formatter) =>
              prepareOnChange(index, field, formatter)
            }
            withDivider={index > 0}
            removeEntry={() =>
              updateData((data) => ({
                ...data,
                dependentes: data.dependentes.filter((_, i) => i !== index),
              }))
            }
            hasError={(field) => hasError(`br/dependentes/${index}/${field}`)}
          />
        ))}
      </Box>
      {editing && <Box pt={2}>{addButton}</Box>}
    </Record>
  );
}

type DependenteEntryProps = {
  dependente: ContractBRCltDependent;
  dependentDocuments: ContractDocumentEntry[] | undefined;
  editing: boolean;
  prepareOnChange: (
    field: keyof ContractBRCltDependent,
    formatter?: (str: string) => string | number | boolean,
  ) => (val: string) => void;
  withDivider: boolean;
  removeEntry: () => void;
  hasError: (field: keyof ContractBRCltDependent) => boolean;
  organizationId: string;
  contractId: string;
  refetchDocuments?: () => void;
};

function DependenteEntry({
  dependente,
  dependentDocuments,
  editing,
  prepareOnChange,
  withDivider,
  removeEntry,
  hasError,
  organizationId,
  contractId,
  refetchDocuments,
}: DependenteEntryProps) {
  const cpfMask = useMaskito({
    options: MaskitoOptionsBR.cpf,
  });
  const {
    nmDep,
    tpDep,
    cpfDep,
    sexoDep,
    dtNascto,
    incTrab,
    depIRRF,
    depSF,
    descrDep,
  } = dependente;

  return (
    <RecordEntryGroup isEditing={editing} withDivider={withDivider}>
      <RecordEntry
        label="Nome completo"
        edit={{
          editing,
          type: 'text',
          value: nmDep,
          onChange: prepareOnChange('nmDep'),
          hasError: hasError('nmDep'),
        }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          textAlign="right"
          fontWeight="700"
        >
          {nmDep}
        </Typography>
      </RecordEntry>
      <RecordEntry
        label="Parentesco"
        edit={{
          editing,
          type: 'options',
          value: tpDep,
          onChange: prepareOnChange('tpDep'),
          hasError: hasError('tpDep'),
          options: mapperToOptions({ mapper: TipoDependente }),
        }}
      >
        {TipoDependente.getByCode(tpDep)}
      </RecordEntry>
      {tpDep === 99 && (
        <RecordEntry
          label="Descrição do parentesco"
          edit={{
            editing,
            type: 'text',
            value: descrDep,
            onChange: prepareOnChange('descrDep'),
            hasError: hasError('descrDep'),
          }}
        >
          {descrDep}
        </RecordEntry>
      )}
      <RecordEntry
        label="Data de nascimento"
        edit={{
          editing,
          type: 'date',
          valueFormat: 'YYYY-MM-DD',
          value: dtNascto,
          onChange: prepareOnChange('dtNascto'),
          hasError: hasError('dtNascto'),
        }}
      >
        {dtNascto ? formatDateBR(dtNascto) : undefined}
      </RecordEntry>
      <RecordEntry
        label="CPF"
        edit={{
          editing,
          type: 'text',
          value: formatCPF(cpfDep),
          onChange: prepareOnChange('cpfDep', getOnlyDigits),
          mask: cpfMask,
          hasError: hasError('cpfDep'),
        }}
      >
        {formatCPF(cpfDep)}
      </RecordEntry>
      <RecordEntry
        label="Sexo"
        edit={{
          editing,
          type: 'options',
          onChange: prepareOnChange('sexoDep'),
          value: sexoDep,
          options: mapperToOptions({ mapper: Sexo }),
          hasError: hasError('sexoDep'),
        }}
      >
        {Sexo.getByCode(sexoDep)}
      </RecordEntry>
      <RecordEntry
        label="Possui incapacidade física ou mental para trabalho?"
        edit={{
          editing,
          type: 'options',
          onChange: prepareOnChange('incTrab', (val) => val === 'true'),
          value: `${incTrab}`,
          options: [
            {
              label: 'Sim',
              value: 'true',
            },
            {
              label: 'Não',
              value: 'false',
            },
          ],
          hasError: hasError('incTrab'),
        }}
      >
        {formatBooleanBR(incTrab)}
      </RecordEntry>
      <RecordEntry
        label="Considerado(a) nos cálculos de"
        edit={{
          type: 'multi-check',
          editing,
          onChange: (prop, value) => {
            prepareOnChange(
              prop as keyof ContractBRCltDependent,
              (val) => val === 'true',
            )(`${value}`);
          },
          options: [
            {
              label: 'Imposto de Renda',
              key: 'depIRRF',
              value: depIRRF,
            },
            {
              label: 'Salário familia',
              key: 'depSF',
              value: depSF,
            },
          ],
        }}
      >
        {getConsiderados(depIRRF, depSF)}
      </RecordEntry>

      <DependenteDocuments
        dependente={dependente}
        organizationId={organizationId}
        contractId={contractId}
        dependentDocuments={dependentDocuments}
        editing={editing}
        refetchDocuments={refetchDocuments}
      />

      {editing && (
        <Box width="100%" pt={1}>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteOutlineIcon />}
            onClick={removeEntry}
          >
            Excluir dependente
          </Button>
        </Box>
      )}
    </RecordEntryGroup>
  );
}

function getConsiderados(depIRRF: boolean, depSF: boolean) {
  if (depIRRF && depSF) {
    return (
      <Typography variant="body2" color="text.primary" textAlign="left">
        Imposto de renda
        <br />
        Salário família
      </Typography>
    );
  } else if (depIRRF) {
    return 'Imposto de renda';
  } else if (depSF) {
    return 'Salário família';
  } else {
    return 'Não é considerado nos cálculos';
  }
}
