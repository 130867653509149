import React, { useContext, useState } from 'react';

import { IconAlertTriangleFilled } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';

import {
  Alert,
  Box,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';

import {
  PendingAccessContractSummary,
  fetchSearchAllPendingAccessContracts,
} from '@octopus/api';
import { DataGrid, DataGridToolbar, useDataGrid } from '@octopus/ui/data-grid';

import { PageTabs } from '../../../modules/components/page/PageTabs';
import TabLabel from '../../../modules/components/TabLabel';
import { DataFetching } from '../../../modules/dataFetching';
import { prepareDataGridSearchInput } from '../../../utils';
import { AppContext } from '../../context';
import { useCountAllPendingAccess } from '../components/pending-access';
import {
  PendingAccessContractTab,
  PendingAccessContractTabKey,
  PendingAccessContractsTableComponents,
} from '../components/pending-access/table';

type PendingAccessContractsTableProps = {
  organizationId: string;
  filtered: boolean;
  contractSelection: {
    selectedContracts: Set<string>;
    addContract: (contractId: string) => void;
    removeContract: (contractId: string) => void;
    addMultipleContracts: (contractIds: Iterable<string>) => void;
    removeMultipleContracts: (contractIds: Iterable<string>) => void;
  };
};
export function PendingAccessContractsTable({
  organizationId,
  filtered,
  contractSelection: {
    selectedContracts,
    addContract,
    removeContract,
    addMultipleContracts,
    removeMultipleContracts,
  },
}: PendingAccessContractsTableProps) {
  const { appContext } = useContext(AppContext);
  const tabs = PendingAccessContractsTableComponents.tabs;
  const [selectedTab, setSelectedTab] = useState<PendingAccessContractTab>(
    tabs.valid,
  );

  const searchFilters =
    PendingAccessContractsTableComponents.searchFilters(appContext);
  const dataGridProps = useDataGrid({
    key: `pending-access-contracts-${organizationId}`,
    filters: searchFilters,
    sorting: { field: 'name.keyword', order: 'asc' },
    pagination: {
      rowsPerPageOptions: [50, 100],
      initialRowsPerPageOptionIndex: 1,
    },
  });
  const { sortingProps, searchProps, paginationProps, filteringProps } =
    dataGridProps;
  const searchInput = prepareDataGridSearchInput(dataGridProps);

  if (searchInput.filtering) {
    searchInput.filtering = {
      ...searchInput.filtering,
      ...selectedTab.filtering,
      elements: {
        ...searchInput.filtering.elements,
        ...selectedTab.filtering.elements,
        userId: [{ empty: true }],
      },
    };
  }

  const countQuery = useCountAllPendingAccess({
    organizationId,
  });
  const searchQuery = useQuery({
    enabled: !!organizationId,
    queryKey: [
      'searchPendingAccessContracts',
      organizationId,
      searchInput,
      paginationProps,
    ],
    queryFn: ({ signal }) =>
      fetchSearchAllPendingAccessContracts(
        {
          pathParams: { organizationId },
          body: searchInput,
        },
        signal,
      ),
  });

  const pageSelectableContracts = filtered
    ? new Set(
        searchQuery.data?.data
          ?.filter(({ email }) => !!email)
          ?.map(({ contractId }) => contractId),
      )
    : undefined;

  const selectContract = (
    contract: PendingAccessContractSummary,
    selected: boolean,
  ) => {
    if (selected) {
      addContract(contract.contractId);
    } else {
      removeContract(contract.contractId);
    }
  };
  const selectAllInPage = (selected: boolean) => {
    if (!pageSelectableContracts) return;
    if (selected) {
      addMultipleContracts(pageSelectableContracts);
    } else {
      removeMultipleContracts(pageSelectableContracts);
    }
  };

  const { columns } = PendingAccessContractsTableComponents.useColumns({
    appContext,
    contractSelection: !filtered
      ? undefined
      : {
          selectAllInPage,
          pageSelectableContracts,
          selectContract,
          selectedContracts,
          currentTab: selectedTab.key,
        },
  });

  const theme = useTheme();

  return (
    <Box sx={{ width: '100%' }} display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="column"
        gap={1.5}
        sx={{ pt: 7, pb: 4 }}
      >
        <Typography variant="h1">Liberar acessos</Typography>
        <Typography variant="body2" color="textSecondary">
          Selecione as pessoas que você gostaria de liberar acessos.
        </Typography>
      </Box>

      {!countQuery.isLoading &&
        !countQuery.isError &&
        countQuery.data?.invalid > 0 && (
          <Box sx={{ pb: 3 }}>
            <Alert
              severity="error"
              icon={<IconAlertTriangleFilled className="medium error" />}
            >
              <strong>Atenção</strong>: {countQuery.data?.invalid} contratos não
              possuem email cadastrado e por isso não poderão ter seus acessos
              liberados.
            </Alert>
          </Box>
        )}

      <Box>
        {filtered && (
          <DataGridToolbar
            filters={searchFilters}
            searchProps={searchProps}
            filteringProps={filteringProps}
          />
        )}

        {countQuery.data?.invalid > 0 && (
          <PageTabs>
            <Tabs
              value={selectedTab.key}
              onChange={(_, tab) => {
                setSelectedTab(tabs[tab as keyof typeof tabs]);
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: theme.palette[selectedTab.color].main,
                },
              }}
            >
              {Object.entries(tabs).map(([key, config]) => (
                <Tab
                  value={key}
                  key={key}
                  icon={
                    <TabLabel
                      count={
                        countQuery.data?.[key as PendingAccessContractTabKey]
                      }
                      isSelected={selectedTab.key === key}
                      color={config.color}
                      label={config.label}
                    />
                  }
                />
              ))}
            </Tabs>
          </PageTabs>
        )}

        <DataFetching
          fetchResult={searchQuery}
          Loading={() => (
            <Box display="flex" flexDirection="column" gap="8px" mt={1.5}>
              <Skeleton variant="rounded" height={300} width="100%" />
            </Box>
          )}
          Data={({ data }) =>
            !!data && (
              <Box pb={13}>
                <DataGrid
                  sortingProps={sortingProps}
                  paginationProps={paginationProps}
                  totalRowCount={data.total || 0}
                  getRowId={(row) => `${row.organizationId}/${row.contractId}`}
                  rows={data.data}
                  columns={columns}
                  emptyMessage="Nenhum acesso pendente encontrado"
                  hoverEffect={false}
                />
              </Box>
            )
          }
        />
      </Box>
    </Box>
  );
}
