import { z } from 'zod';

import {
  AdmissionDraftInputFormStep,
  ContractBRCltTipoContrato,
} from '@octopus/api';
import { WorkerCategory } from '@octopus/contract-types';
import { TipoContrato } from '@octopus/esocial/mapper';
import {
  calculateDaysDifferenceBetweenDates,
  formatDateBR,
  formatDateWithDaysAdded,
} from '@octopus/formatters';
import {
  IFormDefinition,
  UI_TYPE,
  dateYearMonthSchema,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { FormStepDefinition } from '../types';

import { duracaoSchema } from './duracaoSchema';

type TipoContratoType = {
  type: ContractBRCltTipoContrato;
  duracaoContr?: number;
  dtPerExpTerm?: string;
  dtPerExpProrTerm?: string;
  probationPeriod?: string;
  dtTerm?: string;
};

const getContractTypeLabel = (
  code: number,
  probationPeriod = false,
): string => {
  if (probationPeriod) {
    return 'Prazo determinado, período de experiência';
  }
  return TipoContrato.getByCode(code);
};

const probationPeriodOptions = [
  '15',
  '30',
  '60',
  '90',
  '15+15',
  '30+30',
  '45+45',
  '30+60',
  '60+30',
];

const getProbationDisclaimer =
  (admissionDate: string) => (selectedValue: string) => {
    const [days, extensionDays] = selectedValue.split('+');
    const endDate = formatDateWithDaysAdded(admissionDate, +days - 1);
    const endDateFormatted = formatDateBR(endDate);
    if (extensionDays) {
      const extensionEndDate = formatDateBR(
        formatDateWithDaysAdded(endDate, +extensionDays),
      );
      return `A data de término do primeiro período de experiência(${days}) é ${endDateFormatted}, e do segundo(${extensionDays}) é ${extensionEndDate}.`;
    }
    return `A data de término do período de experiência é ${endDateFormatted}`;
  };

const probationPeriodFormOption = ({
  tpContr,
  admissionDate,
}: {
  tpContr: TipoContratoType;
  admissionDate: string;
}) => ({
  label: getContractTypeLabel(2, true),
  selected: tpContr?.type === 2 && tpContr?.dtPerExpTerm != null,
  fields: [
    {
      label: '-',
      hidden: true,
      type: z.string().optional(),
      name: 'periodoExperienciaEnabled',
      value: 'true',
      uiType: UI_TYPE.TEXT_NUMBER,
    },
    {
      label: 'Período de experiência',
      type: z.string(),
      placeholder: 'Selecione o tipo de contrato',
      hint: {
        title: 'Duração do período de experiência do colaborador',
        body: 'Este campo é relevante para avaliar o desempenho inicial do colaborador e decidir sobre a efetivação.',
      },
      name: 'probationPeriod',
      uiType: UI_TYPE.SELECT,
      disclaimerFromSelection: getProbationDisclaimer(admissionDate),
      value: tpContr?.probationPeriod ?? '',
      options: probationPeriodOptions.map((option) => {
        const [days, extensionDays] = option.split('+');
        return {
          label: `${option} dias`,
          selected:
            calculateDaysDifferenceBetweenDates(
              admissionDate,
              tpContr?.dtPerExpTerm,
            ) +
              1 ===
              Number(days) &&
            calculateDaysDifferenceBetweenDates(
              admissionDate,
              tpContr?.dtPerExpProrTerm,
            ) +
              1 ===
              Number(extensionDays),
          value: option,
        };
      }),
    },
  ],
});

const getDuracaoFieldDefinition = ({
  tpContr,
  admissionDate,
  workerCategory,
}: {
  tpContr: TipoContratoType;
  admissionDate: string;
  workerCategory: WorkerCategory;
}): IFormDefinition => {
  return [
    {
      label: 'Tipo de contrato',
      name: 'tpContr',
      type: duracaoSchema,
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione o tipo de contrato',
      fieldsOptions:
        workerCategory === 'clt:jovemAprendiz'
          ? [
              {
                label: getContractTypeLabel(2),
                selected: true,
                fields: [
                  {
                    label: '-',
                    hidden: true,
                    type: z.string().optional(),
                    name: 'jovemAprendizEnabled',
                    value: 'true',
                    uiType: UI_TYPE.TEXT,
                  },
                  {
                    label: 'Data prevista para término',
                    type: dateYearMonthSchema,
                    name: 'dtTerm',
                    uiType: UI_TYPE.DATE_PICKER,
                    placeholder: '00/00/0000',
                    value: tpContr?.dtTerm || '',
                  },
                ],
              },
            ]
          : [
              {
                label: getContractTypeLabel(1),
                selected: tpContr?.type === 1,
                fields: [
                  {
                    label: '-',
                    hidden: true,
                    type: z.number().optional(),
                    name: 'type',
                    value: 1,
                    uiType: UI_TYPE.TEXT_NUMBER,
                  },
                ],
              },
              {
                label: getContractTypeLabel(2),
                selected: tpContr?.type === 2 && !tpContr?.dtPerExpTerm,
                fields: [
                  {
                    label: 'Quantidade de dias do contrato',
                    type: z
                      .number()
                      .int()
                      .min(1)
                      .max(2 * 365)
                      .optional(),
                    name: 'duracaoContr',
                    uiType: UI_TYPE.TEXT_NUMBER,
                    placeholder: 'Ex: 15',
                    value: tpContr?.duracaoContr || '',
                  },
                ],
              },
              probationPeriodFormOption({ tpContr, admissionDate }),
            ],
    },
  ];
};

export const getDuracaoFields = ({
  tpContr,
  admissionDate,
  duracaoFormSteps,
  workerCategory,
}: {
  tpContr: TipoContratoType;
  admissionDate: string;
  duracaoFormSteps: AdmissionDraftInputFormStep;
  workerCategory?: WorkerCategory;
}): FormStepDefinition => ({
  definition: getDuracaoFieldDefinition({
    tpContr,
    admissionDate,
    workerCategory,
  }),
  options: {
    id: admissionDraftFormSteps.duracao,
    title: 'Duração de contrato',
    completed: duracaoFormSteps?.completed,
    reviewed: duracaoFormSteps?.reviewed,
  },
});
