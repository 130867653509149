import {
  AdmissionDraftInputFormSteps,
  AdmissionDraftStatus,
} from '@octopus/api';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import {
  CompanyContext,
  OrganizationContext,
} from '../../../../../modules/types';
import { TFFlags } from '../../../../fflags';
import {
  getDuracaoFields,
  getProfissionalFields,
  getRegimeFields,
} from '../fieldDefinitions';
import { getBolsaAuxilioFields } from '../fieldDefinitions/cltEstagiario/bolsaAuxilio';
import { getEstagioFields } from '../fieldDefinitions/cltEstagiario/estagio';
import { getCriacaoUsuarioFields } from '../fieldDefinitions/criacaoUsuario';
import { getJornadaFields } from '../fieldDefinitions/jornada';
import { getModalidadeContratoFields } from '../fieldDefinitions/modalidadeContrato';
import {
  AdmissionFormState,
  AdmissionFormSteps,
  AdmissionIds,
  FormStepDefinition,
} from '../types';

import * as common from './common';
import { getSummaryStep } from './common';
import { IFormSteps } from './types';

const getAdminSteps = async ({
  admissionIds,
  draftStatus,
  formState,
  companyContext,
  formSteps,
  isWorkerExperience,
  fflags,
}: {
  admissionIds: AdmissionIds;
  draftStatus: AdmissionDraftStatus;
  formState: AdmissionFormState;
  companyContext: CompanyContext;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
  fflags: TFFlags;
}): Promise<AdmissionFormSteps> => {
  if (
    !companyContext ||
    !companyContext.costCenters ||
    !companyContext.jobTitles ||
    !companyContext.legalEntities ||
    !companyContext.companies
  ) {
    return {};
  }

  const { admissionType, tpContr, admissionDate } = formState;

  const modalidade_contrato = getModalidadeContratoFields({
    companyContext,
    formState,
    fflags,
    modalidadeFormSteps: formSteps[admissionDraftFormSteps.modalidade_contrato],
  });

  const criacao_usuario = getCriacaoUsuarioFields({
    formState,
    criacaoUsuarioFormSteps: formSteps[admissionDraftFormSteps.criacao_usuario],
    pjEnabled: fflags.pjAdmissionEnabled.enabled,
  });

  const profissional = await getProfissionalFields({
    companyContext,
    admissionIds,
    formState,
    profissionalFormSteps: formSteps[admissionDraftFormSteps.profissional],
    hideCargoConfianca: true,
  });

  const regime_trabalho = getRegimeFields({
    formState,
    regimeFormSteps: formSteps[admissionDraftFormSteps.regime_trabalho],
    hideRegimeTrabFields: true,
  });

  const estagio_info = getEstagioFields({
    formState,
    estagioFormSteps: formSteps[admissionDraftFormSteps.estagio_info],
  });

  const duracao = getDuracaoFields({
    tpContr,
    admissionDate,
    duracaoFormSteps: formSteps[admissionDraftFormSteps.duracao],
  });

  const jornada = getJornadaFields({
    formState,
    jornadaFormSteps: formSteps[admissionDraftFormSteps.jornada],
    hideJornadaFields: true,
  });

  const remuneracao = getBolsaAuxilioFields({
    formState,
    remuneracaoFormSteps: formSteps[admissionDraftFormSteps.remuneracao],
  });

  const envio_convite = getSummaryStep({
    draftStatus,
    admissionType,
    stepName: 'envio_convite',
    formSteps,
    isWorkerExperience,
    fflags,
  });

  const { custom_fields_admin } = await common.getAdminSteps({
    admissionIds,
    formState,
    formSteps,
  });

  return {
    modalidade_contrato,
    criacao_usuario,
    profissional,
    regime_trabalho,
    estagio_info,
    duracao,
    jornada,
    remuneracao,
    ...(custom_fields_admin && { custom_fields_admin }),
    ...(envio_convite && { envio_convite }),
  };
};

export const getWorkerSteps = ({
  admissionIds,
  formState,
  formSteps,
  finalizaAdmissao,
  organizationContext,
}: {
  admissionIds: AdmissionIds;
  formState: AdmissionFormState;
  formSteps: AdmissionDraftInputFormSteps;
  finalizaAdmissao: FormStepDefinition;
  organizationContext: OrganizationContext;
}): Promise<AdmissionFormSteps> => {
  return common.getWorkerSteps({
    admissionIds,
    formState,
    formSteps,
    finalizaAdmissao,
    isPj: false,
    organizationContext,
  });
};

export const cltEstagiario = {
  getAdminSteps,
  getWorkerSteps,
} as IFormSteps;
