import { PayrollTypes } from '@octopus/api';

export function formatPayrollType(type: PayrollTypes, long = false) {
  const labels: { [key in PayrollTypes]: { short: string; long: string } } = {
    monthly: {
      short: 'Mensal',
      long: 'Folha mensal',
    },
    advance: {
      short: 'Adiantamento',
      long: 'Folha de adiantamento',
    },
    complementary: {
      short: 'Complementar',
      long: 'Folha complementar',
    },
    complementaryTermination: {
      short: 'Rescisão Complementar',
      long: 'Folha de rescisão complementar',
    },
    termination: {
      short: 'Rescisão',
      long: 'Folha de rescisão',
    },
    vacations: {
      short: 'Férias',
      long: 'Folha de férias',
    },
    thirteenthFirst: {
      short: '13º salário (1/2)',
      long: 'Folha de 13º salário - Adiantamento',
    },
    thirteenthSecond: {
      short: '13º salário (2/2)',
      long: 'Folha de 13º salário - 2ª parcela',
    },
    provisions: {
      short: 'Provisões',
      long: 'Folha de Provisões',
    },
    rpa: {
      short: 'RPA',
      long: 'Recibo de Pagamento Autônomo',
    },
  };
  const label = labels[type];
  return long ? label.long : label.short;
}
