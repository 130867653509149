import { ReactElement, useState } from 'react';

import { IconCameraPlus } from '@tabler/icons-react';

import { Box, Skeleton, Typography, useTheme } from '@mui/material';

import { fetchPutOrganizationEntry } from '@octopus/api';

import { BouncingBackground } from '../../modules/components/BouncingBackground';
import { OrganizationAvatar } from '../../modules/components/OrganizationAvatar';
import { PictureInputModal } from '../../modules/components/PictureInputModal';

export type OrganizationInfoCardProps = {
  organizationId: string;
  refetchOrganization: () => Promise<unknown>;
  name: string;
  logo: string | undefined;
  children?: ReactElement;
};

export function OrganizationInfoCard({
  organizationId,
  refetchOrganization,
  name,
  logo,
  children,
}: OrganizationInfoCardProps) {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const submitOrganizationLogo = async (img: File) => {
    const reader = new FileReader();
    const data = await new Promise<string>((resolve) => {
      reader.readAsDataURL(img);
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
    });

    await fetchPutOrganizationEntry({
      pathParams: {
        organizationId,
      },
      body: {
        logo: data,
      },
    });
    setIsModalOpen(false);
    await refetchOrganization();
  };

  return (
    <>
      <BouncingBackground color={theme.palette.text.secondary}>
        <Box
          display="flex"
          flexDirection="column"
          pr={5}
          pt={10}
          gap={1}
          sx={(theme) => ({
            [theme.breakpoints.up('sm')]: {
              pl: 4.25,
            },
            [theme.breakpoints.down('sm')]: {
              pl: 3.5,
            },
          })}
          data-testid="person-info-card"
        >
          <OrganizationAvatar
            name={name}
            logo={logo}
            size="100px"
            AvatarTextProps={{ fontSize: 40 }}
            onClick={() => setIsModalOpen(true)}
            AvatarHoverIcon={IconCameraPlus}
          />
          <Typography variant="h2" fontWeight="700" mt={3} mb={0.5}>
            {name}
          </Typography>
          {children}
        </Box>
      </BouncingBackground>
      <PictureInputModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onSubmit={submitOrganizationLogo}
        cropShape="rect"
      />
    </>
  );
}

export function OrganizationInfoCardSkeleton() {
  return (
    <BouncingBackground color="#808080">
      <Box
        display="flex"
        flexDirection="column"
        pr={5}
        pl={4.25}
        pt={10}
        gap={1}
      >
        <Skeleton
          variant="rounded"
          sx={{
            borderRadius: '0%',
            minHeight: '100px',
            width: '100px',
            border: '1.5px solid #FFFFFF',
            boxSizing: 'border-box',
          }}
        />
        <Skeleton
          variant="rounded"
          width="100%"
          height="36px"
          sx={{
            mt: 3,
          }}
        />
        <Skeleton variant="rounded" width="100%" height="36px" />
      </Box>
    </BouncingBackground>
  );
}
