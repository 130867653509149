import React from 'react';

import { Close } from '@mui/icons-material';
import { Box, Button, Drawer, Typography } from '@mui/material';

import {
  CompanySummary,
  DepartmentEntry,
  DepartmentSummary,
} from '@octopus/api';

import { ContentTabs } from '../../ContentTabs';
import { ArrowNavigation, Navigator } from '../../Navigator';

import { Department } from './Department';
import { DepartmentContracts } from './DepartmentContracts';
import { DepartmentHistory } from './DepartmentHistory';

export type DetailsDrawerProps = {
  organizationId: string;
  companies: CompanySummary[] | undefined;
  departmentSummary: DepartmentSummary | undefined;
  contractsCount: number | undefined;
  navigation: ArrowNavigation;
  open: boolean;
  onEdit: (updatedEntry: DepartmentEntry) => void;
  onArchive: (departmentId: string) => void;
  onRestore: (departmentId: string) => void;
  onContractsAdded: () => void;
  onContractsMoved: () => void;
  onClose: () => void;
};

const detailsContents = {
  department: 'department',
  contracts: 'contracts',
  history: 'history',
} as const;

type DetailsContent = keyof typeof detailsContents;

export function DetailsDrawer({
  organizationId,
  companies,
  departmentSummary,
  contractsCount,
  navigation,
  open,
  onEdit,
  onArchive,
  onRestore,
  onContractsAdded,
  onContractsMoved,
  onClose,
}: DetailsDrawerProps) {
  const [detailsContent, setDetailsContent] = React.useState<DetailsContent>(
    detailsContents.department,
  );

  if (departmentSummary === undefined) {
    return null;
  }

  const content = (() => {
    switch (detailsContent) {
      case detailsContents.department:
        return (
          <Department
            organizationId={organizationId}
            departmentId={departmentSummary.departmentId}
            companies={companies}
            onEdit={onEdit}
            onArchive={onArchive}
            onRestore={onRestore}
          />
        );
      case detailsContents.contracts:
        return (
          <DepartmentContracts
            organizationId={organizationId}
            department={departmentSummary}
            contractsCount={contractsCount}
            onContractsAdded={onContractsAdded}
            onContractsMoved={onContractsMoved}
          />
        );
      case detailsContents.history:
        return (
          <DepartmentHistory
            organizationId={organizationId}
            departmentId={departmentSummary.departmentId}
            companies={companies}
          />
        );
    }
  })();

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      data-testid="department-details-drawer"
    >
      <Box
        display="flex"
        flexDirection="column"
        pt={5}
        pb={4}
        px={7}
        height="100%"
        width="700px"
        boxSizing="border-box"
        gap={3}
        sx={{
          overflowY: 'overlay',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography
            variant="h6"
            maxWidth="440px"
            data-testid="department-name"
          >
            {departmentSummary.name}
          </Typography>
          <Box display="flex" gap={1.5}>
            <Navigator
              arrowNavigation={navigation}
              arrowsDirection="vertical"
              iconSize="medium"
            />
            <Button
              color="secondary"
              size="small"
              onClick={onClose}
              sx={[
                () => ({
                  borderColor: 'transparent',
                  borderRadius: '50%',
                  minWidth: 32,
                  minHeight: 32,
                  height: 32,
                  width: 32,
                }),
              ]}
            >
              <Close sx={{ fontSize: '24px' }} />
            </Button>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" height="100%" width="100%">
          <ContentTabs
            selected={detailsContent}
            setSelected={setDetailsContent}
            tabs={[
              {
                value: detailsContents.department,
                label: 'Detalhes',
              },
              {
                value: detailsContents.contracts,
                label: 'Contratos',
              },
              {
                value: detailsContents.history,
                label: 'Histórico',
              },
            ]}
            tabProps={{
              variant: 'body2',
            }}
          />
          {content}
        </Box>
      </Box>
    </Drawer>
  );
}
