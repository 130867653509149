import { Box, SxProps, Theme } from '@mui/material';

export function TimesheetValidationContainer({
  children,
  sx = {},
}: {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}) {
  return (
    <Box
      data-testid="timesheet-validation-container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: (theme) => theme.spacing(5),
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
