import { CustomFieldEntry } from '@octopus/api';

const getDocumentType = (type: string): string => {
  return type.replace(/^customFields_document_/, '');
};

const getCustomDocumentTitle = (
  type: string,
  customFields: CustomFieldEntry[],
): string => {
  if (!type) {
    return '';
  }

  const documentType = getDocumentType(type);
  const customFieldDocument = customFields?.find(
    (field) => field.type === 'document' && field.name === documentType,
  );

  return customFieldDocument?.label || type;
};

export { getCustomDocumentTitle };
