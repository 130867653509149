import { Box, Divider, Skeleton } from '@mui/material';

import {
  ContractDocumentEntry,
  ContractEntry,
  CustomFieldEntry,
} from '@octopus/api';

import { PageAlert } from '../../../modules/components/PageAlert';
import { QueryResult } from '../../../modules/types';

import { CltContractDetails } from './CltContractDetails';
import { PjContractDetails } from './PjContractDetails';
import { PageRefs } from './types';

export function ContractDetailsContent({
  refs,
  contractQuery,
  customFieldsQuery,
  contractDocuments,
  refetchDocuments,
}: {
  refs: PageRefs;
  contractQuery: QueryResult<ContractEntry>;
  customFieldsQuery: QueryResult<CustomFieldEntry[]>;
  contractDocuments: ContractDocumentEntry[];
  refetchDocuments?: () => void;
}) {
  if (contractQuery.isLoading) {
    return <ContractDetailsContentSkeleton />;
  }

  switch (contractQuery.data?.contractType) {
    case 'br:clt':
      return (
        <CltContractDetails
          refs={refs}
          contractQuery={contractQuery}
          isEditable={true}
          historyEnabled={true}
          customFieldsQuery={customFieldsQuery}
          contractDocuments={contractDocuments}
          refetchDocuments={refetchDocuments}
        />
      );
    case 'br:pj':
      return (
        <PjContractDetails
          refs={refs}
          contractQuery={contractQuery}
          isEditable={true}
          historyEnabled={true}
          customFieldsQuery={customFieldsQuery}
          contractDocuments={contractDocuments}
          refetchDocuments={refetchDocuments}
        />
      );
    default:
      return (
        <PageAlert
          message="Tipo de contrato não suportado"
          severity="error"
          showRetryMessage={false}
          retryMessage="Se o problema persistir, entre em contato com o suporte da Tako."
        />
      );
  }
}

function ContractDetailsContentSkeleton() {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Skeleton variant="rounded" height="48px" />
      <Divider />
      <Skeleton variant="rounded" height="40vh" />
      <Skeleton variant="rounded" height="40vh" />
      <Skeleton variant="rounded" height="40vh" />
    </Box>
  );
}
