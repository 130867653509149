import {
  GetAllPayrollPayslipPdfPathParams,
  GetPayrollPayslipPdfPathParams,
  PayrollPayslipPdf,
  fetchGetAllPayrollPayslipPdf,
  fetchGetPayrollPayslipPdf,
} from '@octopus/api';

import { downloadBase64PdfFile } from '../downloads/download';

export const downloadPayslipPdf = async ({
  organizationId,
  companyId,
  payrollId,
  contractId,
}: GetPayrollPayslipPdfPathParams) => {
  let file: PayrollPayslipPdf = { fileContent: null, fileName: null };
  const maxAttempts = 120; // 5 minutes  (lambda timeout)
  const delay = 3 * 1000; // 3 seconds
  let attempts = 0;

  do {
    attempts++;
    file = await fetchGetPayrollPayslipPdf({
      pathParams: {
        organizationId,
        companyId,
        contractId,
        payrollId,
      },
    });
    if (file.fileContent) {
      downloadBase64PdfFile(file.fileContent, file.fileName);
      break;
    }

    await new Promise((resolve) => setTimeout(resolve, delay));
  } while (!file.fileContent && attempts < maxAttempts);
  if (!file.fileContent) {
    throw new Error('Failed to download payslips');
  }
};

export const downloadAllPayslipPdf = async ({
  organizationId,
  companyId,
  periodId,
  payrollType,
}: GetAllPayrollPayslipPdfPathParams) => {
  let file: PayrollPayslipPdf = { fileContent: null, fileName: null };
  const maxAttempts = 120; // 5 minutes  (lambda timeout)
  const delay = 3 * 1000; // 3 seconds
  let attempts = 0;

  do {
    attempts++;
    file = await fetchGetAllPayrollPayslipPdf({
      pathParams: {
        organizationId,
        companyId,
        periodId,
        payrollType,
      },
    });
    if (file.fileContent) {
      downloadBase64PdfFile(file.fileContent, file.fileName);
      break;
    }

    await new Promise((resolve) => setTimeout(resolve, delay));
  } while (!file.fileContent && attempts < maxAttempts);
  if (!file.fileContent) {
    throw new Error('Failed to download payslips');
  }
};
