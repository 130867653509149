import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Theme, useTheme } from '@mui/material';

import { MembershipContext, MembershipOption } from '../../../modules/types';
import { useFFlags } from '../../fflags';
import { OrderedApps, SidebarApp } from '../types';

import { MembershipSelectorChip } from './membershipSelectorChip';

export function BottombarAppSelector({
  membership,
  membershipOptions,
  apps,
}: {
  membership: MembershipContext;
  membershipOptions: MembershipOption[];
  apps: SidebarApp[];
}) {
  const { FFlags } = useFFlags();
  return (
    <Box
      display="flex"
      alignItems="center"
      py={1}
      px={4}
      borderRadius={6}
      gap={2.5}
      maxWidth="280px"
      overflow="auto"
      overflow-y="hidden"
      border="0.5px solid"
      borderColor={(theme: Theme) => theme.palette.strokes.heavy}
      sx={{
        bgcolor: 'rgba(37, 37, 45, 0.95)',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
        backdropFilter: 'blur(6px)',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      {OrderedApps.map((appName, i) => {
        const app = apps.find((app) => app.title === appName);
        if (!app) return null;
        if (app.fflag) {
          return (
            <FFlags key={i} {...{ [app.fflag]: true }}>
              <BottombarAppSelectorItem key={i} app={app} />
            </FFlags>
          );
        }
        return <BottombarAppSelectorItem key={i} app={app} />;
      })}
      <MembershipSelectorChip
        membership={membership}
        membershipOptions={membershipOptions}
      />
    </Box>
  );
}

function BottombarAppSelectorItem({ app }: { app: SidebarApp }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const { Icon, path } = app;

  const selected = useMemo(() => {
    return pathname.startsWith(path);
  }, [pathname, path]);

  const color = selected
    ? theme.palette.primaryAlt.contrastText
    : theme.palette.strokes.heavy;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      onClick={() => navigate(path)}
      borderRadius={1}
      bgcolor={selected ? 'rgba(247, 247, 248, 0.2)' : 'rgba(0,0,0,0)'}
      color={color}
      padding={1}
    >
      <Icon className="large" style={{ color }} />
    </Box>
  );
}
