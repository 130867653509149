import ExcelJS from 'exceljs';

import { WorksheetRange } from '../types';

export function setAutoFilter(
  worksheet: ExcelJS.Worksheet,
  range: WorksheetRange,
) {
  worksheet.autoFilter = range;
}
