import React, { useContext, useEffect, useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { ContractSummary } from '@octopus/api';

import { AppContext } from '../../../../../app/context';
import VirtualizedAutocomplete from '../../../VirtualizedAutocomplete';

import {
  changeCostCenterForContract,
  pollUntilContractCostCenterIsUpdated,
} from './changeCostCenterForContracts';

export type CostCenterMoveContractDialogProps = {
  open: boolean;
  onClose: () => void;
  onMoved: () => void;
  contract: ContractSummary | undefined;
};

export function CostCenterMoveContractDialog({
  open,
  onClose,
  onMoved,
  contract,
}: CostCenterMoveContractDialogProps) {
  const [scene, setScene] = useState<number>(0);
  const [newCostCenter, setNewCostCenter] = useState<string | null>(null);
  const [effectiveDate, setEffectiveDate] = useState<string | null>(
    dayjs().format('YYYY-MM-DD'),
  );
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    setScene(0);
    setNewCostCenter(null);
    setError(undefined);
    setEffectiveDate(dayjs().format('YYYY-MM-DD'));
  }, [open]);

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      await changeCostCenterForContract(
        contract.organizationId,
        contract,
        newCostCenter,
        effectiveDate,
      );
      await pollUntilContractCostCenterIsUpdated(
        contract.organizationId,
        contract.contractId,
        newCostCenter,
      );
    },
  });

  if (contract === undefined) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Typography mb={1} variant="h5" fontWeight={700}>
          Mover para outro centro de custo
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ minHeight: '205px' }}>
        {scene === 0 && (
          <SelectCostCenterAndDateContent
            currentCostCenter={contract.costCenterId}
            newCostCenter={newCostCenter}
            effectiveDate={effectiveDate}
            setNewCostCenter={setNewCostCenter}
            setEffectiveDate={setEffectiveDate}
            error={error}
          />
        )}
        {scene === 1 && <ConfirmChanges />}
      </DialogContent>
      <DialogActions>
        <Button
          color="primaryAlt"
          size="large"
          variant="outlined"
          onClick={onClose}
          sx={{
            minWidth: 120,
          }}
        >
          Cancelar
        </Button>
        <LoadingButton
          color="primaryAlt"
          size="large"
          variant="contained"
          disabled={!newCostCenter || !effectiveDate}
          loading={isLoading}
          sx={{
            minWidth: 120,
          }}
          onClick={() => {
            setError(undefined);
            if (scene === 0) {
              setScene(1);
            } else {
              mutate(undefined, {
                onSuccess: onMoved,
                onError: (e) => {
                  setError((e as Error)?.message);
                  setScene(0);
                },
              });
            }
          }}
        >
          Mudar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

function SelectCostCenterAndDateContent({
  currentCostCenter,
  newCostCenter,
  effectiveDate,
  setNewCostCenter,
  setEffectiveDate,
  error,
}: {
  currentCostCenter: string | undefined;
  newCostCenter: string | null;
  effectiveDate: string | null;
  setNewCostCenter: (newCostCenter: string | null) => void;
  setEffectiveDate: (effectiveDate: string | null) => void;
  error: string | undefined;
}) {
  const { appContext } = useContext(AppContext);

  const minDate = dayjs().startOf('month').subtract(1, 'month');
  const maxDate = dayjs().startOf('month').add(1, 'month').endOf('month');

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {error && (
        <Alert severity="error">
          <Typography variant="body2">{error}</Typography>
        </Alert>
      )}
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="body2">Qual o novo centro de custo?</Typography>
        <VirtualizedAutocomplete
          placeholder="Selecione o centro de custo"
          value={newCostCenter}
          onChange={(val) => setNewCostCenter(val as string | null)}
          options={[
            { value: null, label: 'Selecione um centro de custo' },
            ...(appContext?.company?.costCenters
              ?.filter((cc) => cc.id !== currentCostCenter)
              ?.filter((cc) => cc.summary.active)
              ?.map((cc) => ({
                value: cc.id,
                label: cc.name,
              })) ?? []),
          ]}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="body2">
          A mudança deve começar a ter efeito a partir de qual data?
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <DatePicker
            defaultValue={dayjs(effectiveDate)}
            sx={{ width: '100%' }}
            onChange={(v) => {
              if (v.isBefore(minDate)) {
                setEffectiveDate(null);
              } else if (v.isAfter(maxDate)) {
                setEffectiveDate(null);
              } else {
                setEffectiveDate(v.format('YYYY-MM-DD'));
              }
            }}
            minDate={minDate}
            maxDate={maxDate}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
}

function ConfirmChanges() {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="body1" fontWeight="700">
        Ao confirmar, o que acontece em seguida:
      </Typography>
      <List sx={{ py: 0, px: 2, listStyleType: 'disc' }}>
        <ListItem disableGutters sx={{ pb: 2, display: 'list-item' }}>
          <Box>
            <Typography variant="body1" fontWeight="500">
              Transferência e atualização dos dados
            </Typography>
            <Typography variant="caption">
              As pessoas selecionadas serão transferidas e terão seus Centros de
              custo atualizados a partir da data selecionada.
            </Typography>
          </Box>
        </ListItem>
        <ListItem disableGutters sx={{ pb: 2, display: 'list-item' }}>
          <Box>
            <Typography variant="body1" fontWeight="500">
              Aplicação em novos relatórios
            </Typography>
            <Typography variant="caption">
              As novas informações serão consideradas apenas para novos
              relatórios, sem impactar relatórios anteriores à data selecionada.
            </Typography>
          </Box>
        </ListItem>
      </List>
    </Box>
  );
}
