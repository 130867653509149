import { useState } from 'react';

import { IconReportMoney } from '@tabler/icons-react';

import { Box, Skeleton, Tab, Tabs } from '@mui/material';

import { DataGrid, DataGridToolbar, useDataGrid } from '@octopus/ui/data-grid';

import { SwitchCompany } from '../../modules/components/companies/SwitchCompany';
import { PageContainer } from '../../modules/components/page/PageContainer';
import { PageTabs } from '../../modules/components/page/PageTabs';
import { PageTitle } from '../../modules/components/page/PageTitle';
import { DataFetching } from '../../modules/dataFetching';

import { getColumnsForStatus } from './components/Columns';
import { PeriodTabLabel } from './components/Tabs';
import { periodGroupsConfig } from './const';
import { useFetchPayroll } from './hooks/useFetchPayroll';
import { useFilters } from './hooks/useFilters';
import { getEmptyMessageForStatus, getRowSxForStatus } from './utils';

export type PayrollsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

function Payrolls({ organizationId, companyId }: PayrollsProps) {
  const [tab, setTab] = useState<keyof typeof periodGroupsConfig>('open');
  const [countByStatus, setCountByStatus] = useState<{
    [key in keyof typeof periodGroupsConfig]: number;
  }>({
    open: 0,
    approved: 0,
    closed: 0,
  });

  const filters = useFilters();
  const { filteringProps, searchProps, sortingProps, paginationProps } =
    useDataGrid({
      key: `payrolls-${organizationId}`,
      filters,
    });

  const useFetch = () =>
    useFetchPayroll(
      organizationId,
      companyId,
      tab,
      filteringProps,
      paginationProps,
    );

  return (
    <PageContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        alignItems="center"
        gap={2}
      >
        <PageTitle
          dataTestId="payrolls-period-header"
          title="Folhas de Pagamento"
          icon={IconReportMoney}
        />

        <SwitchCompany />
      </Box>

      <DataGridToolbar
        filters={filters}
        searchProps={searchProps}
        filteringProps={filteringProps}
        searchPlaceholder="Procurar..."
      />

      <PageTabs>
        <Tabs
          value={tab}
          onChange={(_, newTab) => setTab(newTab)}
          textColor="inherit"
          TabIndicatorProps={{
            sx: {
              backgroundColor: `${periodGroupsConfig[tab].color}.main`,
            },
          }}
          data-testid="payrolls-period-tabs"
        >
          {Object.entries(periodGroupsConfig).map(
            ([groupName, groupConfig]) => (
              <Tab
                key={groupName}
                value={groupName}
                icon={
                  <PeriodTabLabel
                    isSelected={groupName === tab}
                    config={groupConfig}
                    count={
                      countByStatus[
                        groupName as keyof typeof periodGroupsConfig
                      ]
                    }
                  />
                }
                data-testid={`payrolls-period-tab-${groupName}`}
              />
            ),
          )}
        </Tabs>
      </PageTabs>

      <DataFetching
        useHook={useFetch}
        Loading={() => <Skeleton variant="rounded" height={400} width="100%" />}
        onData={(data) => {
          if (data?.metadata?.countByStatus) {
            setCountByStatus({
              ...{
                open: 0,
                approved: 0,
                closed: 0,
              },
              ...data.metadata.countByStatus,
            });
          }
        }}
        Data={({ data: list }) => {
          return (
            <DataGrid
              sortingProps={sortingProps}
              paginationProps={paginationProps}
              totalRowCount={list?.total ?? 0}
              rows={list?.data ?? []}
              getRowId={(row) => `${row.period}/${row.type}`}
              getRowLink={(row) => {
                return `/payrolls/${row.period}/${row.type}`;
              }}
              columns={getColumnsForStatus(tab)}
              emptyMessage={getEmptyMessageForStatus(tab)}
              getRowSx={() => ({
                height: '60px',
                ...getRowSxForStatus(tab),
              })}
            />
          );
        }}
      />
    </PageContainer>
  );
}

export default Payrolls;
