import {
  Box,
  Divider,
  List,
  ListItemButton,
  Popover,
  SvgIcon,
  Typography,
} from '@mui/material';

type menuAction = {
  label: string;
  count?: number;
  onClick?: () => void;
  icon?: typeof SvgIcon;
  disabled?: boolean;
  tooltip?: string;
  divider?: boolean;
};

export default function EnableMoreActionsPopover({
  moreActionsProps,
  actions,
}: {
  moreActionsProps: {
    enableMoreActionsPopoverOpen: boolean;
    setEnableMoreActionsPopoverOpen: (value: boolean) => void;
    popoverAnchorEl: HTMLButtonElement | null;
    setPopOverAnchorEl: (value: HTMLButtonElement | null) => void;
  };
  actions: menuAction[];
}) {
  return (
    <Popover
      open={moreActionsProps.enableMoreActionsPopoverOpen}
      anchorEl={moreActionsProps.popoverAnchorEl}
      onClose={() => moreActionsProps.setEnableMoreActionsPopoverOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      elevation={1}
      sx={{
        mt: 0.5,
      }}
      data-testid="filters-popover"
    >
      <List sx={{ pb: 1, px: 0 }}>
        {actions.map(({ label, onClick, icon, divider, disabled, count }) =>
          divider ? (
            <Divider key="divider" sx={{ my: 1 }} />
          ) : (
            <ListItemButton
              sx={{
                width: '100%',
                gap: 1.5,
                maxHeight: '32px',
                alignItems: 'center',
                pt: 1,
              }}
              key={label}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (disabled) {
                  return;
                }
                onClick();
                moreActionsProps.setEnableMoreActionsPopoverOpen(false);
              }}
            >
              {icon ? (
                <Box
                  component={icon}
                  sx={{
                    p: 0,
                    height: '16px',
                    width: '16px',
                    color: disabled ? '#BABABF' : 'primary',
                    alignSelf: 'center',
                  }}
                />
              ) : undefined}
              <Box
                display="flex"
                flexDirection="row"
                gap={4}
                alignItems="baseline"
                alignContent="space-between"
                width="100%"
              >
                <Typography
                  sx={(theme) => ({
                    width: '100%',
                    color: disabled
                      ? theme.palette.strokes.heavy
                      : theme.palette.text.primary,
                    fontSize: '14px',
                    fontWeight: '450',
                  })}
                >
                  {label}
                </Typography>
                {count > 0 && (
                  <Typography
                    sx={(theme) => ({
                      color: disabled
                        ? theme.palette.strokes.heavy
                        : theme.palette.text.secondary,
                      fontSize: '12px',
                      fontWeight: '650',
                    })}
                  >
                    {count}
                  </Typography>
                )}
              </Box>
            </ListItemButton>
          ),
        )}
      </List>
    </Popover>
  );
}
