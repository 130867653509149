const FormErrorCodes: Record<string, string> = {
  form_invalid_cpf: 'Invalid CPF',
  form_invalid_cnpj: 'Invalid CNPJ',
  form_invalid_date: 'Invalid date',
  form_invalid_birth_date: 'Invalid birthdate, minium is 14 years',
  form_invalid_empty_string: 'Invalid empty string',
  form_invalid_dt_prev_term_string_with_value:
    'Estimated end date must be after admission date ({{admissionDate}})',
  form_invalid_required_field: 'This field is required',
};

export default FormErrorCodes;
