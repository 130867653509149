import { Box } from '@mui/material';

export const PageTabs = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      my={2}
      sx={(theme) => ({
        boxShadow: `0 -1px 0 ${theme.palette.strokes.light} inset`,
      })}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      {children}
    </Box>
  );
};
