import {
  PayrollPeriodTotalizers,
  PayrollPeriodTotalizersSummary,
} from '@octopus/api';
import {
  PayrollStatus,
  PayslipStatus,
  payrollStatusIndex,
  payrollStatuses,
  payslipStatusIndex,
  payslipStatuses,
} from '@octopus/payroll-types';

export function countPayrollStatus(
  totalizers: PayrollPeriodTotalizers | PayrollPeriodTotalizersSummary,
  condition: (statusIndex: number, count: number) => boolean,
) {
  return Object.entries(totalizers.counts.payrolls.byStatus)
    .filter(
      ([status, count]) =>
        condition(payrollStatusIndex[status as PayrollStatus], count) &&
        count > 0,
    )
    .reduce((total, [_, count]) => total + count, 0);
}

export function countPayslipStatus(
  totalizers: PayrollPeriodTotalizers | PayrollPeriodTotalizersSummary,
  condition: (statusIndex: number, count: number) => boolean,
) {
  return Object.entries(totalizers.counts.payslips.byStatus)
    .filter(
      ([status, count]) =>
        condition(payslipStatusIndex[status as PayslipStatus], count) &&
        count > 0,
    )
    .reduce((total, [_, count]) => total + count, 0);
}

export function hasGeneratedAllPayslips(
  totalizers: PayrollPeriodTotalizers | PayrollPeriodTotalizersSummary,
): boolean {
  const generatedPayslips = countPayslipStatus(
    totalizers,
    (statusIndex) => statusIndex <= payslipStatusIndex.sent,
  );
  const approvedPayslips = countPayslipStatus(
    totalizers,
    (statusIndex) =>
      statusIndex >= payslipStatusIndex.approved &&
      statusIndex < payslipStatusIndex.archived,
  );
  console.log({ generatedPayslips, approvedPayslips });
  return generatedPayslips === approvedPayslips;
}

export function isCalculatingPayrolls(
  totalizers: PayrollPeriodTotalizers | PayrollPeriodTotalizersSummary,
) {
  return (
    totalizers?.counts?.payrolls?.byStatus?.[payrollStatuses.calculating] > 0
  );
}

export const hasSentAllPayslips = (
  totalizers: PayrollPeriodTotalizers | PayrollPeriodTotalizersSummary,
) => {
  return (
    !totalizers?.counts?.payslips?.byStatus?.[payslipStatuses.approved] &&
    !totalizers?.counts?.payslips?.byStatus?.[payslipStatuses.scheduled]
  );
};
