export type TerminationFields = {
  tipoContrato: string;
  causaAfastamento: string;
  remuneracaoMesAnt: string;
  dataAdmissao: string;
  dataAvisoPrevio: string;
  dataAfastamento: string;
  codAfastamento: string;
  pensaoAlimTRCT: string;
  pensaoAlimFGTS: string;
  categoriaTrabalhador: string;
  codigoSindical: string;
  cnpjNomeEntidadeSindical: string;
  saldoDiasSalario: string;
  comissoes: string;
  gratificacao: string;
  adicInsalubridade: string;
  adicPericulosidade: string;
  adicNoturno: string;
  horasExtras: string;
  gorjetas: string;
  descansoSemanalRemunerado: string;
  reflexoDSRSalarioVariavel: string;
  multaArt477: string;
  salarioFamilia: string;
  decimoTerceiroSalarioProporcional: string;
  decimoTerceiroSalarioExerc: string;
  feriasProporcional: string;
  feriasVencPerAquisitivo: string;
  tercoConstitucFerias: string;
  avisoPrevioIndenizado: string;
  decimoTerceiroSalarioAvisoPrevio: string;
  feriasAvisoPrevio: string;
  ajusteSaldoDevedor: string;
  totalBruto: string;
  pensaoAlimenticia: string;
  adiantamentoSalarial: string;
  avisoPrevioIndenizadoDias: string;
  avisoPrevioDescontadoDias: string;
  irrf: string;
  previdenciaSocial: string;
  previdenciaSocialDecimoTerceiro: string;
  irrfDecimoTerceiro: string;
  totalDeducoes: string;
  totalLiquido: string;
  adicionalPorDeTempoDeServico: string;
  decimoTerceiroSalarioAdiantado: string;
  mesesDecimoTerceiro: string;
};

export const TerminationFieldLabels = {
  tipoContrato: 'Tipo de Contrato',
  causaAfastamento: 'Causa do Afastamento',
  remuneracaoMesAnt: 'Remuneração Mês Ant.',
  dataAdmissao: 'Data de Admissão',
  dataAvisoPrevio: 'Data do Aviso Prévio',
  dataAfastamento: 'Data de Afastamento',
  codAfastamento: 'Cód. Afastamento',
  pensaoAlimTRCT: 'Pensão Alim. (%) (TRCT)',
  pensaoAlimFGTS: 'Pensão Alim. (%) (FGTS)',
  categoriaTrabalhador: 'Categoria do Trabalhador',
  codigoSindical: 'Código Sindical',
  cnpjNomeEntidadeSindical: 'CNPJ e Nome da Entidade Sindical Laboral',
  saldoDiasSalario: 'Saldo de dias Salário',
  comissoes: 'Comissões',
  gratificacao: 'Gratificação',
  adicInsalubridade: 'Adic. de Insalubridade',
  adicPericulosidade: 'Adic. de Periculosidade',
  adicNoturno: 'Adic. Noturno',
  horasExtras: 'Horas Extras',
  gorjetas: 'Gorjetas',
  descansoSemanalRemunerado: 'Descanso Semanal Remunerado (DSR)',
  reflexoDSRSalarioVariavel: 'Reflexo do DSR sobre Salário Variável',
  multaArt477: 'Multa Art. 477, § 8º/CLT',
  salarioFamilia: 'Salário-Família',
  decimoTerceiroSalarioProporcional: '13º Salário Proporcional',
  decimoTerceiroSalarioExerc: '13º Salário-Exerc',
  feriasProporcional: 'Férias Proporc',
  feriasVencPerAquisitivo: 'Férias Venc. Per. Aquisitivo',
  tercoConstitucFerias: 'Terço Constituc. de Férias',
  avisoPrevioIndenizado: 'Aviso Prévio Indenizado',
  decimoTerceiroSalarioAvisoPrevio: '13º Salário (Aviso Prévio Indenizado)',
  feriasAvisoPrevio: 'Férias (Aviso Prévio Indenizado)',
  ajusteSaldoDevedor: 'Ajuste do saldo devedor',
  totalBruto: 'TOTAL BRUTO',
  pensaoAlimenticia: 'Pensão Alimentícia',
  adiantamentoSalarial: 'Adiantamento Salarial',
  avisoPrevioIndenizadoDias: 'Aviso Prévio Indenizado dias',
  avisoPrevioDescontadoDias: 'Aviso Prévio Descontado dias',
  irrf: 'IRRF',
  previdenciaSocial: 'Previdência Social',
  previdenciaSocialDecimoTerceiro: 'Prev Social - 13º Salário',
  irrfDecimoTerceiro: 'IRRF sobre 13º Salário',
  totalDeducoes: 'TOTAL DEDUÇÕES',
  totalLiquido: 'TOTAL LÍQUIDO',
  adicionalPorDeTempoDeServico: 'Adicional por tempo de serviço',
  decimoTerceiroSalarioAdiantado: '13º Salário Adiantado',
  mesesDecimoTerceiro: 'Meses de 13º Salário',
};

export const TERMINATION_FIELDS_PREFIX = 'terminationField';
