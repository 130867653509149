import { FieldMetadata, getInputProps } from '@conform-to/react';

import {
  IFieldSingleValueWithoutOptionsDefinition,
  parseFieldUiInfo,
} from '@octopus/libs/forms';

import { TFieldDocumentRenderProps, TFieldRenderProps } from './types';

export function parseFieldDocument(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldMetadata: FieldMetadata<any>,
  fieldDefinition: IFieldSingleValueWithoutOptionsDefinition,
  fieldsetName = '',
): TFieldRenderProps & TFieldDocumentRenderProps {
  const inputProps = getInputProps(fieldMetadata, {
    type: 'file',
  });

  const fieldRenderProps: TFieldRenderProps = {
    uiType: parseFieldUiInfo(fieldDefinition),
    fieldsetName,
    props: {
      key: inputProps.id,
    },
    label: {
      textContent: fieldDefinition.label,
      subtextContent: fieldDefinition.subLabel,
      props: {
        htmlFor: inputProps.id,
      },
    },
    input: {
      props: {
        ...inputProps,
        placeholder: fieldDefinition.placeholder,
        info: fieldDefinition.info,
      },
    },
    extraProps:
      fieldDefinition?.extraProps as TFieldDocumentRenderProps['extraProps'],
  };

  return fieldRenderProps;
}
