import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import { fetchGetUserIdentity } from '@octopus/api';

import { rum } from '../../utils/rum';

import { appContextQueryConfig } from './constants';

export const useUserIdentity = (onAuthFailure: () => void) => {
  const query = useQuery({
    queryKey: ['user-identity'],
    queryFn: () =>
      fetchGetUserIdentity({}).then((res) => ({
        userId: res.userId,
        email: res.email,
        name: res.name,
        personId: res.personId,
      })),
    ...appContextQueryConfig,
  });

  useEffect(() => {
    if (query.isError) {
      onAuthFailure();
    }
  }, [query.isError, onAuthFailure]);

  useEffect(() => {
    if (!query.isError && query.data?.userId) {
      rum.addUserIdMetadata(query.data.userId);
    }
  }, [query.isLoading, query.data?.userId]);

  return query.data;
};
