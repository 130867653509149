import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { Box, useTheme } from '@mui/material';

import {
  VacationsAccrualPeriodEntry,
  VacationsConfigurationEntry,
  fetchGetContractAccrualPeriods,
  fetchGetVacationsConfigurationByContract,
} from '@octopus/api';

import { getVacationsConfiguration } from '../new/utils';

import RequestVacationContainer from './RequestVacationContainer';
import { LoadingScene } from './utils/RequestVacationLoadingScene';
import {
  RequestVacationFormSteps,
  RequestVacationFormStepsKeys,
  requestVacationObject,
} from './utils/types';

export type VacationsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

function VacationRequestPage({
  organizationId,
}: {
  organizationId: string | undefined;
}) {
  const [requestVacation, setRequestVacation] = useState<requestVacationObject>(
    {},
  );
  const theme = useTheme();
  const { contractId } = useParams();
  const [searchParams] = useSearchParams();

  const [accrualPeriod, setAccrualPeriod] = useState<
    VacationsAccrualPeriodEntry & { nextSequence: number }
  >(null);

  const [vacationsConfiguration, setVacationsConfiguration] =
    useState<VacationsConfigurationEntry>(null);

  const startDate = searchParams.get('startDate');
  const pathParams = { organizationId, contractId };
  const useQueryParams = {
    cacheTime: 1,
    staleTime: 1,
    enabled: !!organizationId,
  };

  const accrualPeriodsQuery = useQuery({
    queryKey: [organizationId, contractId, 'accrualPeriodsQuery'],
    queryFn: () => {
      return fetchGetContractAccrualPeriods({
        pathParams,
        queryParams: {
          effectiveDate: dayjs().add(366, 'day').format('YYYY-MM-DD'),
        },
      });
    },
    ...useQueryParams,
  });

  const vacationsConfigQuery = useQuery({
    queryKey: [organizationId, contractId, 'config'],
    queryFn: () => {
      return fetchGetVacationsConfigurationByContract({
        pathParams,
      });
    },
    ...useQueryParams,
  });

  const formSteps: RequestVacationFormSteps = useMemo(() => {
    const filteredOutSteps: RequestVacationFormStepsKeys[] = [];
    const steps: RequestVacationFormStepsKeys[] = [
      'initialStep',
      'abonoPecuniario',
      'thirteenthAdvance',
      'dateAndDuration',
      'reviewAndSubmit',
      'result',
    ];
    if (accrualPeriod && vacationsConfiguration) {
      const configurations = getVacationsConfiguration(
        accrualPeriod,
        vacationsConfiguration,
      );

      if (!configurations.canSellVacation) {
        filteredOutSteps.push('abonoPecuniario');
      }
      if (!configurations.isThirteenthSalaryAdvanceEligible) {
        filteredOutSteps.push('thirteenthAdvance');
      }
    }
    return Object.fromEntries(
      steps
        .filter((ele) => !filteredOutSteps.includes(ele))
        .map((ele) => [ele, requestVacation]),
    );
  }, [accrualPeriod, vacationsConfiguration, requestVacation]);

  useEffect(() => {
    if (accrualPeriodsQuery.data) {
      setAccrualPeriod({
        ...accrualPeriodsQuery.data.accrualPeriods.find(
          (accrualPeriod) => accrualPeriod.startDate === startDate,
        ),
        nextSequence: accrualPeriodsQuery.data.nextSequence,
      });
    }
  }, [accrualPeriodsQuery.data, startDate]);

  useEffect(() => {
    if (vacationsConfigQuery.data) {
      setVacationsConfiguration(vacationsConfigQuery.data);
    }
  }, [vacationsConfigQuery.data]);

  if (!accrualPeriod || !vacationsConfiguration) {
    return <LoadingScene title={`Iniciando pedido de férias`} />;
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.secondary,
      }}
      height={'100vh'}
    >
      <RequestVacationContainer
        accrualPeriod={accrualPeriod}
        formSteps={formSteps}
        vacationsConfiguration={vacationsConfiguration}
        setRequestVacation={setRequestVacation}
        organizationId={organizationId}
        contractId={contractId}
      />
    </Box>
  );
}

export default VacationRequestPage;
