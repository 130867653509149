import React, { ReactNode } from 'react';

import { useQuery } from '@tanstack/react-query';

import { Box, Skeleton } from '@mui/material';

import {
  CompanySummary,
  HistoryActivateEvent,
  HistoryCreateEvent,
  HistoryDeactivateEvent,
  HistoryEvent,
  HistoryUpdateEvent,
  JobTitleInput,
  fetchListJobTitleHistoryEvents,
} from '@octopus/api';
import { flattenObject } from '@octopus/commons';
import { formatBooleanBR, formatCBO } from '@octopus/formatters';

import { QueryResult } from '../../../types';
import { ErrorAlert } from '../../ErrorAlert';
import {
  EventsTimeline,
  TimelineEventItem,
  TimelineEventItemChangeCreate,
  TimelineEventItemChangeUpdate,
} from '../../EventsTimeline';

export type JobTitleHistoryProps = {
  organizationId: string;
  jobTitleId: string;
  companies: CompanySummary[] | undefined;
};

export function JobTitleHistory({
  organizationId,
  jobTitleId,
  companies,
}: JobTitleHistoryProps) {
  const { isLoading, data, isError } = useRetrieveAllHistoryEvents(
    organizationId,
    jobTitleId,
  );

  if (isError) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <ErrorAlert
          message="Falha ao carregar histórico de cargo, por favor tente novamente mais tarde.
          Se o problema persistir, entre em contato com o suporte da Tako."
        />
      </Box>
    );
  }

  if (isLoading || !data) {
    return (
      <Skeleton variant="rounded" width="100%" height="100%" sx={{ mt: 1 }} />
    );
  }

  return (
    <Box p={2}>
      <EventsTimeline
        events={data}
        renderEventContent={(event, index, allEvents) =>
          renderJobTitleHistoryEvent(event, index, allEvents, companies)
        }
      />
    </Box>
  );
}

function useRetrieveAllHistoryEvents(
  organizationId: string,
  jobTitleId: string,
): QueryResult<HistoryEvent[]> {
  return useQuery({
    queryKey: ['fetchListJobTitleHistoryEvents', organizationId, jobTitleId],
    queryFn: async () => {
      let data: HistoryEvent[] = [];
      let token: string | undefined = undefined;
      do {
        const { events, nextToken } = await fetchListJobTitleHistoryEvents({
          pathParams: {
            organizationId,
            jobTitleId,
          },
          ...(token && {
            queryParams: {
              startToken: token,
            },
          }),
        });
        data = [...data, ...events];
        token = nextToken;
      } while (token !== undefined);
      return data;
    },
    enabled: !!organizationId && !!jobTitleId,
  });
}

function renderJobTitleHistoryEvent(
  event: HistoryEvent,
  index: number,
  allEvents: HistoryEvent[],
  companies: CompanySummary[] | undefined,
): ReactNode | null {
  switch (event.type) {
    case 'create':
      return <CreateEvent event={event} companies={companies} />;
    case 'update':
      return (
        <UpdateEvent
          event={event}
          index={index}
          allEvents={allEvents}
          companies={companies}
        />
      );
    case 'activate':
      return <ActivateEvent event={event} />;
    case 'deactivate':
      return <DeactivateEvent event={event} />;
    default:
      return null;
  }
}

function CreateEvent({
  event,
  companies,
}: {
  event: HistoryCreateEvent;
  companies: CompanySummary[] | undefined;
}) {
  const { author, timestamp, payload } = event;

  const jobTitleInput = payload as JobTitleInput;
  const changes: TimelineEventItemChangeCreate[] = [
    {
      label: 'Código',
      value: `${jobTitleInput.code}`,
    },
    {
      label: 'Nome',
      value: jobTitleInput.name,
    },
    ...(companies.length > 1
      ? [
          {
            label: 'Quais empresas podem usar este cargo',
            value: (() => {
              if (
                !jobTitleInput.enabledForCompanies ||
                jobTitleInput.enabledForCompanies.length === companies.length
              ) {
                return 'Todas';
              }
              return jobTitleInput.enabledForCompanies
                .map(
                  (company) =>
                    companies.find(({ companyId }) => companyId === company)
                      ?.name ?? company,
                )
                .join(', ');
            })(),
          },
        ]
      : []),
    {
      label: 'Atuações que podem usar este cargo',
      value: (() => {
        if (
          !jobTitleInput.contractTypes ||
          jobTitleInput.contractTypes.length > 1
        ) {
          return 'Todos';
        }
        if (jobTitleInput.contractTypes[0] === 'br:pj') {
          return 'Prestador de serviço';
        } else {
          return 'Colaborador';
        }
      })(),
    },
    ...(jobTitleInput.occupationCode
      ? [
          {
            label: 'CBO',
            value: formatCBO(jobTitleInput.occupationCode),
          },
        ]
      : []),
    ...(jobTitleInput.description
      ? [
          {
            label: 'Descrição',
            value: jobTitleInput.description,
          },
        ]
      : []),
    {
      label: 'Cargo de confiança',
      value: formatBooleanBR(jobTitleInput.br?.cargoDeConfianca ?? false),
    },
    ...(jobTitleInput.br?.cboDoCargoDeConfianca
      ? [
          {
            label: 'CBO do cargo de confiança',
            value: formatCBO(jobTitleInput.br.cboDoCargoDeConfianca),
          },
        ]
      : []),
    ...(jobTitleInput.br?.descricaoDoCargoDeConfianca
      ? [
          {
            label: 'Descrição do cargo de confiança',
            value: jobTitleInput.br.descricaoDoCargoDeConfianca,
          },
        ]
      : []),
  ];

  return (
    <TimelineEventItem
      label="Cargo criado em"
      timestamp={timestamp}
      author={author}
      changes={changes}
    />
  );
}

function UpdateEvent({
  event,
  index,
  allEvents,
  companies,
}: {
  event: HistoryUpdateEvent;
  index: number;
  allEvents: HistoryEvent[];
  companies: CompanySummary[] | undefined;
}) {
  const { author, timestamp, payload } = event;

  const current = flattenObject(payload);
  const previous = projectJobTitleToIndex(index, allEvents);

  function prepareChange(
    label: string,
    key: string,
    formatter: (val: string | string[] | null) => string | null = (val) =>
      val as string | null,
  ): TimelineEventItemChangeUpdate[] {
    if (current[key] === undefined) {
      return [];
    }
    return [
      {
        label,
        from: formatter(previous[key]),
        to: formatter(current[key]),
      },
    ];
  }

  const changes: TimelineEventItemChangeUpdate[] = [
    ...prepareChange('Código', 'code'),
    ...prepareChange('Nome', 'name'),
    ...(companies.length > 1
      ? prepareChange(
          'Quais empresas podem usar este cargo',
          'enabledForCompanies',
          (val) => {
            const enabledForCompanies = val as string[] | null;
            if (!val || val.length === companies.length) {
              return 'Todas';
            }
            return enabledForCompanies
              .map(
                (company) =>
                  companies.find(({ companyId }) => companyId === company)
                    ?.name ?? company,
              )
              .join(', ');
          },
        )
      : []),
    ...prepareChange(
      'Atuações que podem usar este cargo',
      'contractTypes',
      (val) => {
        if (!val || val.length > 1) {
          return 'Todos';
        }
        if (val[0] === 'br:pj') {
          return 'Prestador de serviço';
        } else {
          return 'Colaborador';
        }
      },
    ),
    ...prepareChange('CBO', 'occupationCode', (val) =>
      formatCBO(val as string),
    ),
    ...prepareChange('Descrição', 'description'),
    ...prepareChange('Cargo de confiança', 'br/cargoDeConfianca', (val) =>
      formatBooleanBR(val as string),
    ),
    ...prepareChange(
      'CBO do cargo de confiança',
      'br/cboDoCargoDeConfianca',
      (val) => formatCBO(val as string),
    ),
    ...prepareChange(
      'Descrição do cargo de confiança',
      'br/descricaoDoCargoDeConfianca',
    ),
  ];

  return (
    <TimelineEventItem
      label="Cargo atualizado em"
      timestamp={timestamp}
      author={author}
      changes={changes}
    />
  );
}

function ActivateEvent({ event }: { event: HistoryActivateEvent }) {
  const { author, timestamp } = event;
  return (
    <TimelineEventItem
      label="Cargo ativado em"
      timestamp={timestamp}
      author={author}
    />
  );
}

function DeactivateEvent({ event }: { event: HistoryDeactivateEvent }) {
  const { author, timestamp } = event;
  return (
    <TimelineEventItem
      label="Cargo desativado em"
      timestamp={timestamp}
      author={author}
    />
  );
}

function projectJobTitleToIndex(
  index: number,
  allEvents: HistoryEvent[],
): Record<string, string | string[] | null> {
  const eventsToApply = allEvents.slice(index + 1);
  return eventsToApply.reverse().reduce(
    (projection, event) => {
      if ('payload' in event) {
        return {
          ...projection,
          ...flattenObject(event['payload'] ?? {}),
        };
      }
      return projection;
    },
    {} as Record<string, string | string[] | null>,
  );
}
