import { useState } from 'react';

export type SnackbarResult = {
  showSnackbar: (snackbar: SnackbarType) => void;
  currentSnackbar: SnackbarType;
};

export type SnackbarType = {
  isOpen: boolean;
  variant?: 'default' | 'error' | 'warning';
  StartAdornment?: React.ReactNode;
  EndAdornment?: React.ReactNode;
  Message?: React.ReactNode;
  autoHideDuration?: number;
  hasCloseAction?: boolean;
};

const DEFAULT_SNACKBAR_DURATION_MS = 5000;

export function useSnackbarContext(): SnackbarResult {
  const [currentSnackbar, setCurrentSnackbar] = useState<SnackbarType>();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const showSnackbar = (snackbar: SnackbarType): void => {
    setCurrentSnackbar(snackbar);
    addHideTimeout(snackbar);

    if (timeoutId) {
      clearTimeout(timeoutId);
      addHideTimeout(snackbar);
    }
  };

  const addHideTimeout = (snackbar: SnackbarType) => {
    const hideTimeout =
      snackbar.autoHideDuration ?? DEFAULT_SNACKBAR_DURATION_MS;

    const timeoutId = setTimeout(() => clear(), hideTimeout);
    setTimeoutId(timeoutId);
  };

  const clear = () => {
    setCurrentSnackbar(null);
  };

  return {
    showSnackbar,
    currentSnackbar,
  };
}
