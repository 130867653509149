import { useCallback, useMemo } from 'react';

import { TimesheetValidationError } from './feedback/TimesheetValidationError';
import { TimesheetValidationSuccess } from './feedback/TimesheetValidationSuccess';
import { TimesheetValidationWarning } from './feedback/TimesheetValidationWarning';
import {
  TimesheetEntry,
  TimesheetFileDefinition,
  TimesheetStepError,
  TimesheetStepWarning,
} from './types';

type TimesheetValidationResultStepProps = {
  errors: TimesheetStepError[];
  warnings: TimesheetStepWarning[];
  entries: TimesheetEntry[];
  fileDefinition: TimesheetFileDefinition;
  onCancel: () => void;
  onConfirm: () => void;
};

export function TimesheetValidationResultStep({
  errors,
  warnings,
  onCancel,
  entries,
  onConfirm,
  fileDefinition,
}: TimesheetValidationResultStepProps) {
  const ValidationComponent = useMemo(() => {
    if (errors.length || !entries.length) {
      return TimesheetValidationError;
    }

    if (warnings.length) {
      return TimesheetValidationWarning;
    }

    return TimesheetValidationSuccess;
  }, [errors, warnings, entries]);

  const onCancelWrapper = useCallback(onCancel, [onCancel]);
  const onConfirmWrapper = useCallback(onConfirm, [onConfirm]);

  const validationProps = useMemo(
    () => ({
      onCancel: onCancelWrapper,
      onConfirm: onConfirmWrapper,
      entries,
      errors,
      warnings,
      fileDefinition,
    }),
    [
      onCancelWrapper,
      onConfirmWrapper,
      entries,
      errors,
      warnings,
      fileDefinition,
    ],
  );

  return <ValidationComponent {...validationProps} />;
}
