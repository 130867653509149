import { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Snackbar, Typography } from '@mui/material';

import { PayrollInputsSubmissionStatus } from '@octopus/api';

export function SubmissionToast({
  successMessage,
}: {
  successMessage?: string;
}) {
  const location = useLocation();
  const submissionStatus = location?.state?.submission as
    | PayrollInputsSubmissionStatus
    | undefined;
  const [showToast, setShowToast] = useState<boolean>(!!submissionStatus);

  const toasts: Record<PayrollInputsSubmissionStatus, ReactElement> = {
    success: (
      <>
        <CheckCircleIcon />
        <Typography variant="body2" color="white">
          {successMessage ??
            'As edições foram aplicadas e o cálculo da folha foi atualizado.'}
        </Typography>
      </>
    ),
    failure: (
      <>
        <CancelIcon />
        <Typography variant="body2" color="white">
          Ocorreu um erro ao aplicar as edições. Tente novamente.
        </Typography>
      </>
    ),
    processing: (
      <Typography variant="body2" color="white">
        A folha está sendo calculada e os valores serão atualizados em
        instantes.
      </Typography>
    ),
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={showToast}
      onClose={() => {
        setShowToast(undefined);
        location.state.submission = undefined;
      }}
      onAnimationEnd={() => {
        setShowToast(undefined);
        location.state.submission = undefined;
      }}
      message={
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          py={0.5}
          px={2}
          gap={1.5}
        >
          {toasts[submissionStatus]}
        </Box>
      }
      key={'submissionToast'}
      autoHideDuration={10000}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => {
            setShowToast(undefined);
            location.state.submission = undefined;
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    ></Snackbar>
  );
}
