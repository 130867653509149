import React from 'react';

import {
  IconBuilding,
  IconExternalLink,
  IconMapPin,
  IconUserPlus,
} from '@tabler/icons-react';
import dayjs from 'dayjs';

import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { PendingAccessContractSummary } from '@octopus/api';

import WorkerCategoryTag from '../../../../modules/components/contracts/WorkerCategoryTag';
import UserAvatar from '../../../../modules/components/UserAvatar';
import { useDepartment } from '../../../../modules/hooks/useDepartment';
import { IAppContext } from '../../../../modules/types';

function ContractSummaryLabel({
  icon,
  label,
}: {
  icon: React.ReactElement;
  label: string;
}) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
      {icon}
      <Typography color="textSecondary" variant="caption">
        {label}
      </Typography>
    </Box>
  );
}

type PendingAccessContractSummaryLinkCardProps = {
  contract: PendingAccessContractSummary;
  appContext: IAppContext;
};
export function PendingAccessContractSummaryLinkCard({
  appContext,
  contract,
}: PendingAccessContractSummaryLinkCardProps) {
  const createdAt = `Admissão em ${dayjs(contract.createdAt).format('DD/MM/YYYY')}`;
  const jobTitle = appContext?.company?.jobTitles?.find(
    ({ id }) => id === contract.jobTitleId,
  )?.summary?.name;
  const company = appContext.company?.companies?.find(
    ({ id }) => id === contract.companyId,
  )?.name;
  const legalEntity = appContext.company?.legalEntities?.find(
    ({ id }) => id === contract.legalEntityId,
  )?.name;
  const department = useDepartment(contract.departmentId);

  const openContract = () => {
    window.open(`/people/${contract.contractId}`);
  };

  return (
    <Box sx={{ p: 2.5 }}>
      <Box display="flex" flexDirection="row" gap={1.5} alignItems="flex-start">
        <UserAvatar
          name={contract.name}
          showFullName={false}
          pictureUrl={contract.pictureUrl}
          expandNameOnHover={false}
          avatarProps={{ ml: 0 }}
          size={40}
        />
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" gap={1} alignItems="center">
              <Typography
                onClick={openContract}
                color="textPrimary"
                fontSize="20px"
                fontWeight={700}
                sx={{
                  '&:hover': { textDecoration: 'underline', cursor: 'pointer' },
                }}
              >
                {contract.name}
              </Typography>
              <Box
                onClick={openContract}
                sx={{ '&:hover': { cursor: 'pointer' } }}
              >
                <IconExternalLink className="small cursor-pointer" />
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" gap={1}>
              <Typography color="textPrimary" variant="caption">
                {jobTitle}
              </Typography>
              {department?.name && (
                <Divider
                  orientation="vertical"
                  flexItem={true}
                  sx={(theme) => ({ color: theme.palette.text.placeholder })}
                />
              )}
              <Typography color="textPrimary" variant="caption">
                {department?.name}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={0.25}>
            <ContractSummaryLabel
              label={createdAt}
              icon={<IconUserPlus className="x-small" />}
            />
            <ContractSummaryLabel
              label={company}
              icon={<IconBuilding className="x-small" />}
            />
            <ContractSummaryLabel
              label={legalEntity}
              icon={<IconMapPin className="x-small" />}
            />
          </Box>
          <Box>
            <WorkerCategoryTag workerCategory={contract.workerCategory} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
