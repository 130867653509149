import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IconSend } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';

import {
  Box,
  Button,
  Container,
  Drawer,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  PayrollPeriodSummaryEntry,
  PayrollSummary,
  PayrollTypes,
  fetchGetPeriodSummary,
} from '@octopus/api';
import { formatDateBR } from '@octopus/formatters';
import { payslipStatusIndex } from '@octopus/payroll-types';

import { BackButton } from '../../../../modules/components/BackButton';
import { useSendPayslips } from '../../../../modules/components/payslips/SendPayslips';
import { DataFetching, FetchResult } from '../../../../modules/dataFetching';
import { PeriodFormat } from '../../../../modules/types';
import {
  countPayslipStatus,
  hasGeneratedAllPayslips,
} from '../../../../utils/statusIndexUtils';

import { ApprovalModal } from './ApprovalModal';
import { PayrollDetails } from './PayrollDetails';
import { PayrollsTable } from './PayrollsTable';
import { PeriodInfo } from './PeriodInfo';
import { SubmissionToast } from './SubmissionToast';

export default function PayrollPage({
  organizationId,
  companyId,
}: {
  organizationId: string | undefined;
  companyId: string | undefined;
}) {
  const { period, type } = useParams<{
    period: PeriodFormat;
    type: PayrollTypes;
  }>();

  if (!organizationId || !companyId) {
    return (
      <Typography variant="h1">
        Selecione uma organização e companhia
      </Typography>
    );
  }

  if (!period || !type) {
    return (
      <Typography variant="h1">
        Folha do mês informado não encontrada
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
      }}
    >
      <Toolbar />
      <Payrolls
        organizationId={organizationId}
        companyId={companyId}
        period={period}
        type={type}
      />
      <SubmissionToast />
    </Box>
  );
}

function PeriodCallToAction({
  organizationId,
  companyId,
  period,
  type,
  onClickApprove,
  fetchGetPeriodSummary,
}: {
  organizationId: string;
  companyId: string;
  period: PeriodFormat;
  type: PayrollTypes;
  onClickApprove: () => void;
  fetchGetPeriodSummary: FetchResult<PayrollPeriodSummaryEntry>;
}) {
  const navigate = useNavigate();
  const { sendPayslipsProps, sendPayslips, SendPayslipsComponent } =
    useSendPayslips({
      organizationId,
      companyId,
    });

  return (
    <>
      <DataFetching
        fetchResult={fetchGetPeriodSummary}
        Loading={() => null}
        Data={({ data }) => {
          const hasPayslips = countPayslipStatus(
            data.totalizers,
            (statusIndex) => statusIndex <= payslipStatusIndex.sent,
          );
          const hasNotApprovedAllPayslips = countPayslipStatus(
            data.totalizers,
            (statusIndex) =>
              statusIndex >= payslipStatusIndex.approved &&
              statusIndex < payslipStatusIndex.sent,
          );

          if (!data) {
            return null;
          }

          if (
            data.status === 'closed' &&
            !hasPayslips &&
            !hasNotApprovedAllPayslips
          ) {
            return null;
          }

          return (
            <Box
              py={1}
              px={3}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                background: 'rgba(247, 247, 248, 0.8)',
                backdropFilter: 'blur(4px)',
                position: 'fixed',
                bottom: 0,
                right: 0,
                left: 0,
              }}
            >
              {(() => {
                if (data.status === 'open') {
                  return (
                    <Box display="flex" gap={1}>
                      <Button
                        onClick={() => navigate('inputs')}
                        size="large"
                        data-testid="edit-payrolls-button"
                        color="secondary"
                        sx={{ width: '190px', height: '42px' }}
                      >
                        Editar lançamentos
                      </Button>

                      <Button
                        onClick={onClickApprove}
                        size="large"
                        data-testid="approve-payrolls-button"
                        color="primaryAlt"
                        sx={{ width: '190px', height: '42px' }}
                      >
                        Aprovar folha
                      </Button>
                    </Box>
                  );
                }

                if (hasPayslips && hasNotApprovedAllPayslips) {
                  const { payslipScheduledSendAt, paymentDate } = data;

                  const button = (
                    <Box display="flex" gap={3} alignItems="center">
                      <Typography variant="caption" color="text.secondary">
                        {payslipScheduledSendAt
                          ? `O envio dos holerites está programado para ${formatDateBR(payslipScheduledSendAt)}`
                          : paymentDate &&
                            `Enviar holerites até ${formatDateBR(paymentDate)}`}
                      </Typography>
                      <Button
                        disabled={!hasGeneratedAllPayslips(data.totalizers)}
                        startIcon={<IconSend size={20} />}
                        color={payslipScheduledSendAt ? 'secondary' : 'primary'}
                        size="large"
                        onClick={() =>
                          sendPayslips({
                            periodId: period,
                            payrollType: type,
                          })
                        }
                        data-testid="send-payslips-button"
                        sx={{ width: '190px', height: '42px' }}
                      >
                        Enviar holerites
                      </Button>
                    </Box>
                  );

                  if (hasGeneratedAllPayslips(data.totalizers)) {
                    return button;
                  }

                  return (
                    <Tooltip title="Os recibos ainda estão sendo gerados. Tente novamente dentro de alguns instantes.">
                      <span>{button}</span>
                    </Tooltip>
                  );
                }

                return null;
              })()}
            </Box>
          );
        }}
      />

      <SendPayslipsComponent {...sendPayslipsProps} />
    </>
  );
}

function Payrolls({
  organizationId,
  companyId,
  period,
  type,
}: {
  organizationId: string;
  companyId: string;
  period: PeriodFormat;
  type: PayrollTypes;
}) {
  const navigate = useNavigate();
  const [selectedPayrollId, setSelectedPayrollId] = useState<
    string | undefined
  >();
  const [selectedContractId, setSelectedContractId] = useState<
    string | undefined
  >();

  const [currentPayrollIndex, setCurrentPayrollIndex] = useState<
    number | undefined
  >();
  const [payrolls, setPayrolls] = useState<Array<PayrollSummary> | undefined>();

  const [compareTo, setCompareTo] = useState<PeriodFormat | undefined>(
    undefined,
  );
  const [showDrawer, setShowDrawer] = useState(false);
  const closeDrawer = () => setShowDrawer(false);
  const selectPayroll = (
    payrollId: string,
    contractId: string,
    rows: Array<PayrollSummary>,
  ) => {
    setSelectedPayrollId(payrollId);
    setSelectedContractId(contractId);

    const currentIndex = rows.findIndex((row) => row.payrollId === payrollId);

    setCurrentPayrollIndex(currentIndex);
    setPayrolls(rows);

    setShowDrawer(true);
  };

  const [openPendingModal, setOpenPendingModal] = useState(false);

  useEffect(() => {
    setCompareTo(undefined);
  }, [organizationId, companyId, period, type]);

  const payrollSummary = useQuery({
    queryKey: ['payrollSummary', organizationId, companyId, period, type],
    queryFn: () =>
      fetchGetPeriodSummary({
        pathParams: {
          organizationId,
          companyId,
          periodId: period,
          payrollType: type,
        },
      }),
    enabled: !!organizationId && !!companyId && !!period && !!type,
  });

  const workerNavigation = useMemo(
    () => ({
      canGoBackwards:
        payrolls != null &&
        currentPayrollIndex > 0 &&
        currentPayrollIndex <= payrolls.length - 1,
      canGoForward:
        payrolls != null &&
        currentPayrollIndex >= 0 &&
        currentPayrollIndex < payrolls.length - 1,
      goBackwards: () => {
        if (currentPayrollIndex <= 0) {
          return;
        }
        const newIndex = currentPayrollIndex - 1;
        const payroll = payrolls[newIndex];
        setCurrentPayrollIndex(newIndex);
        setSelectedContractId(payroll.contractId);
        setSelectedPayrollId(payroll.payrollId);
      },
      goForward: () => {
        if (currentPayrollIndex >= payrolls.length - 1) {
          return;
        }
        const newIndex = currentPayrollIndex + 1;
        const payroll = payrolls[newIndex];
        setCurrentPayrollIndex(newIndex);
        setSelectedContractId(payroll.contractId);
        setSelectedPayrollId(payroll.payrollId);
      },
    }),
    [payrolls, currentPayrollIndex],
  );

  const totals = payrollSummary?.data?.totalizers?.totals;
  const rubricaElements: {
    [key: string]: string;
  } = {};
  totals?.netPay.elements.forEach((key) => {
    const value = totals?.elements[key];
    if (value) {
      rubricaElements[key] = value.name;
    }
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
      }}
    >
      <BackButton destination="/payrolls" />
      <Container maxWidth="lg">
        <Box mt={4}>
          <PeriodInfo
            organizationId={organizationId}
            companyId={companyId}
            period={period}
            type={type}
            compareTo={compareTo}
            setCompareTo={setCompareTo}
          />
        </Box>
        <Box pt={3.2} pb={8}>
          <PayrollsTable
            organizationId={organizationId}
            status={payrollSummary?.data?.status}
            companyId={companyId}
            period={period}
            type={type}
            compareTo={compareTo}
            onWorkerClick={selectPayroll}
            rubricaElements={rubricaElements}
          />
        </Box>
      </Container>

      <PeriodCallToAction
        {...{ organizationId, companyId, period, type }}
        onClickApprove={() => setOpenPendingModal(true)}
        fetchGetPeriodSummary={payrollSummary}
      />

      <Drawer
        anchor={'right'}
        open={showDrawer}
        onClose={closeDrawer}
        elevation={2}
      >
        {selectedPayrollId && selectedContractId && (
          <PayrollDetails
            organizationId={organizationId}
            companyId={companyId}
            period={period}
            type={type}
            payrollId={selectedPayrollId}
            contractId={selectedContractId}
            compareToPeriod={compareTo}
            workerNavigation={workerNavigation}
            onExit={closeDrawer}
          />
        )}
      </Drawer>
      <ApprovalModal
        onApprove={() => navigate(`/payrolls`)}
        open={openPendingModal}
        setOpen={setOpenPendingModal}
        organizationId={organizationId}
        companyId={companyId}
        period={period}
        type={type}
      />
    </Box>
  );
}
