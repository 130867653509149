import { useContext } from 'react';

import { AppContext } from '../../app/context';
import { JobTitleContext } from '../types';

export function useJobTitle(
  jobTitleId: string | undefined,
): JobTitleContext | undefined {
  const { appContext } = useContext(AppContext);
  if (!jobTitleId) {
    return undefined;
  }
  return appContext?.company?.jobTitles?.find(
    (jobTitle) => jobTitle.id === jobTitleId,
  );
}
