import { useState } from 'react';

import { Drawer } from '@mui/material';

import { ContractEntry, CustomFieldEntry } from '@octopus/api';

import { QueryResult } from '../../../../modules/types';
import { ContractActionsMenu } from '../ContractActionsMenu';
import { ContractDetailsProps } from '../types';

import { ContractEventsHistory } from './ContractEventsHistory';
import { Utils } from './utils';

type ContractEventsHistoryArgs = {
  contractQuery: QueryResult<ContractEntry>;
  historyEnabled: boolean;
  projectionComponent: React.FC<ContractDetailsProps>;
  customFieldsQuery: QueryResult<CustomFieldEntry[]>;
  refetchContract: () => void;
};

type UseContractEventsHistory = {
  actionsMenu: JSX.Element;
  HistoryDrawer: JSX.Element;
  updateHistory?: () => void;
};

export function useContractEventsHistory({
  contractQuery,
  historyEnabled,
  projectionComponent,
  customFieldsQuery,
  refetchContract,
}: ContractEventsHistoryArgs): UseContractEventsHistory {
  const [showingHistory, setShowingHistory] = useState<boolean>(false);
  const showHistory = () => setShowingHistory(true);
  const closeHistory = () => setShowingHistory(false);

  const {
    isError: contractError,
    isLoading: contractIsLoading,
    data: contractResponse,
  } = contractQuery;

  if (!historyEnabled || contractError || contractIsLoading) {
    return {
      actionsMenu: null,
      HistoryDrawer: null,
    };
  }

  const {
    isLoading: eventsLoading,
    data: eventsResponse,
    isError: eventsError,
    refetch,
  } = Utils.Hooks.useGetAllAuthoredContractEvents({
    organizationId: contractResponse.organizationId,
    contractId: contractResponse.contractId,
  });

  const events =
    contractIsLoading || !eventsResponse
      ? []
      : Utils.Events.supportedHistoryEventsSorted(eventsResponse);

  if (eventsError) {
    return {
      actionsMenu: null,
      HistoryDrawer: null,
    };
  }

  const refetchAll = () => {
    refetchContract();
    refetch();
  };

  if (eventsLoading) {
    return {
      actionsMenu: (
        <ContractActionsMenu
          onHistorySelected={showHistory}
          scheduledEvents={0}
          contractEntry={contractResponse}
          refetchContract={refetchAll}
        />
      ),
      HistoryDrawer: null,
    };
  }

  const { contractId, organizationId } = contractResponse;
  const personName =
    contractResponse.br?.pessoa?.nmSoc ?? contractResponse.br?.pessoa?.nmTrab;

  const scheduledEvents = events.reduce(
    (amount, event) =>
      (amount +=
        Utils.Events.eventIsScheduled(event) && !event.canceled ? 1 : 0),
    0,
  );

  const showActionsMenu = (
    <ContractActionsMenu
      onHistorySelected={showHistory}
      scheduledEvents={scheduledEvents}
      contractEntry={contractResponse}
      refetchContract={refetchAll}
    />
  );

  const historyDrawer = (
    <Drawer
      anchor="right"
      open={showingHistory}
      onClose={closeHistory}
      elevation={2}
    >
      <ContractEventsHistory
        events={events}
        projectionComponent={projectionComponent}
        contractId={contractId}
        organizationId={organizationId}
        personName={personName}
        onDismiss={closeHistory}
        onEventCanceled={refetchAll}
        customFieldsQuery={customFieldsQuery}
      />
    </Drawer>
  );

  return {
    actionsMenu: showActionsMenu,
    HistoryDrawer: historyDrawer,
    updateHistory: refetch,
  };
}
