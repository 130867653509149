import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Skeleton, Typography } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';

import {
  ContractBRCltDependent,
  ContractDocumentEntry,
  ContractDocumentType,
  ContractEntry,
  CustomFieldEntry,
  SearchInput,
  useDeleteContractDocument,
  useDownloadContractDocuments,
  useSearchContractDocuments,
} from '@octopus/api';
import { useDataGrid, useSearch } from '@octopus/ui/data-grid';
import { Tag } from '@octopus/ui/design-system';

import { ExpandableTypography } from '../../../../modules/components/ExpandableTypography';
import { useSnackbar } from '../../../../modules/hooks/useSnackbar';
import { QueryResult } from '../../../../modules/types';
import { downloadFile } from '../../../../utils';
import { getCustomDocumentTitle } from '../../../admission/new/form/formStepInputs/customFieldsDocuments';
import { AppContext } from '../../../context';

import { DocumentsTable } from './DocumentsTable';
import { DOCUMENT_TYPE_LABELS } from './types';
import { UploadDocumentModal } from './UploadDocumentModal';
import { getErrorSnackbar, getIdentifierFromCPF } from './utils';

export function ContractDocumentsContent({
  contractQuery,
  customFieldsQuery,
}: {
  contractQuery: QueryResult<ContractEntry>;
  customFieldsQuery: QueryResult<CustomFieldEntry[]>;
}) {
  const { showSnackbar } = useSnackbar();
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);

  const searchContractDocumentsQuery = useSearchContractDocuments();
  const { appContext } = useContext(AppContext);
  const dataGridProps = useDataGrid({
    key: `contract-documents-${appContext.organization.organizationId}`,
    pagination: {
      rowsPerPageOptions: [10, 25, 50],
      initialRowsPerPageOptionIndex: 0,
    },
  });
  const searchProps = useSearch();
  const { sortingProps, paginationProps } = dataGridProps;

  const downloadDocumentsMutation = useDownloadContractDocuments({
    onSuccess: (response) => {
      downloadFile(response.downloadUrl);
    },
    onError: (error: { status: number | 'unknown'; payload: string }) => {
      console.error('Erro ao baixar documentos:', error);
      showSnackbar(getErrorSnackbar());
    },
  });

  const deleteDocumentMutation = useDeleteContractDocument({
    onError: (error: { status: number | 'unknown'; payload: string }) => {
      console.error('Erro ao excluir documento:', error);
    },
  });

  const searchContractDocumentsFetch = useCallback(
    (searchTerm?: string) => {
      if (
        !contractQuery.data?.organizationId ||
        !contractQuery.data?.contractId
      ) {
        return;
      }
      try {
        searchContractDocumentsQuery.mutate({
          pathParams: {
            organizationId: contractQuery.data.organizationId,
            contractId: contractQuery.data.contractId,
          },
          body: {
            query: searchTerm,
            filtering: {
              status: {
                not: ['deleted'],
              },
            },
            sorting: sortingProps.field
              ? [
                  {
                    field: sortingProps.field,
                    order: sortingProps.order || 'asc',
                  },
                ]
              : [
                  {
                    field: 'title.keyword',
                    order: 'asc',
                  },
                  {
                    field: 'type.keyword',
                    order: 'asc',
                  },
                ],
            pagination: {
              page: paginationProps.page,
              size: paginationProps.rowsPerPage,
            },
          } as SearchInput,
        });
      } catch (e) {
        console.error(e);
        showSnackbar(
          getErrorSnackbar(
            'Erro ao buscar documentos. Por favor recarregue a página.',
          ),
        );
      } finally {
        setForceRefresh(false);
      }
    },
    [
      contractQuery.data?.organizationId,
      contractQuery.data?.contractId,
      sortingProps.field,
      sortingProps.order,
      paginationProps.page,
      paginationProps.rowsPerPage,
      forceRefresh,
    ],
  );

  useEffect(() => {
    if (contractQuery.data?.organizationId && contractQuery.data?.contractId) {
      searchContractDocumentsFetch(searchProps.searchTerm);
    }
  }, [
    contractQuery.data?.organizationId,
    contractQuery.data?.contractId,
    sortingProps.field,
    sortingProps.order,
    paginationProps.page,
    paginationProps.rowsPerPage,
    searchProps.searchTerm,
    searchContractDocumentsFetch,
  ]);

  if (
    contractQuery.isLoading &&
    (!contractQuery.data?.contractId || !contractQuery.data?.organizationId)
  ) {
    return <ContractDocumentsContentSkeleton />;
  }

  const dependentes =
    'dependentes' in contractQuery.data.br
      ? contractQuery.data.br.dependentes?.filter(
          (d): d is ContractBRCltDependent => 'nmDep' in d,
        )
      : undefined;

  const getDependentName = (dependentId: string) => {
    if (!dependentes) return null;

    const dependente = dependentes.find(
      (d) =>
        d.id === dependentId || getIdentifierFromCPF(d.cpfDep) === dependentId,
    );
    if (!dependente) return null;

    return dependente.nmDep;
  };

  const DOCUMENTS_TABLE_COLUMNS: GridColDef<ContractDocumentEntry>[] = [
    {
      field: 'title.keyword',
      headerName: 'Nome',
      flex: 6,
      valueGetter: (params: GridValueGetterParams<ContractDocumentEntry>) => {
        const { title, dependentId, type } = params.row ?? {};

        const documentType = type as ContractDocumentType;
        const mappedType = DOCUMENT_TYPE_LABELS[documentType] || documentType;
        const label =
          title != null && title !== type
            ? title
            : getCustomDocumentTitle(mappedType, customFieldsQuery.data);

        if (dependentId) {
          const dependentName = getDependentName(dependentId);
          if (dependentName) {
            return `${label} - ${dependentName}`;
          }
        }
        return label;
      },
      renderCell: ({ value }) => {
        return (
          <ExpandableTypography sx={{ maxWidth: 320 }}>
            {(value ?? '').toString()}
          </ExpandableTypography>
        );
      },
    },
    ...(dependentes?.length > 0 ||
    searchContractDocumentsQuery.data?.data?.some(
      (doc: ContractDocumentEntry) => doc.dependentId,
    )
      ? [
          {
            field: 'dependentId',
            headerName: 'Relativo ao',
            flex: 2,
            sortable: false,
            valueGetter: (
              params: GridValueGetterParams<ContractDocumentEntry>,
            ) => {
              return params.row.dependentId ? 'Dependente' : 'Colaborador';
            },
            renderCell: (
              params: GridRenderCellParams<ContractDocumentEntry>,
            ) => {
              const value = params.row.dependentId
                ? 'Dependente'
                : 'Colaborador';
              return (
                <Tag color={value === 'Colaborador' ? 'default' : 'info'}>
                  {value}
                </Tag>
              );
            },
          },
        ]
      : []),
    {
      field: 'createdOn',
      headerName: 'Criado em',
      flex: 2,
      minWidth: 90,
      valueGetter: (params: GridValueGetterParams<ContractDocumentEntry>) => {
        return new Date(params.row.createdOn).toLocaleDateString('pt-BR');
      },
      renderCell: (params: GridRenderCellParams<ContractDocumentEntry>) => {
        return (
          <ExpandableTypography>
            {(params.value ?? '').toString()}
          </ExpandableTypography>
        );
      },
    },
  ];

  return (
    <Box display="flex" gap={2} flexDirection="column">
      <Typography variant="h5" fontWeight={700}>
        Documentos
      </Typography>
      <DocumentsTable
        searchContractDocumentsQuery={searchContractDocumentsQuery}
        organizationId={contractQuery.data.organizationId}
        contractId={contractQuery.data.contractId}
        columns={DOCUMENTS_TABLE_COLUMNS}
        downloadDocumentsMutation={downloadDocumentsMutation}
        deleteDocumentMutation={deleteDocumentMutation}
        showSnackbar={showSnackbar}
        dataGridProps={dataGridProps}
        onRefresh={() => setForceRefresh(true)}
        onUpload={() => setUploadModalOpen(true)}
      />

      <UploadDocumentModal
        open={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
        organizationId={contractQuery.data.organizationId}
        contractId={contractQuery.data.contractId}
        dependentes={dependentes}
        workerName={contractQuery.data.br.pessoa.nmTrab}
        onSuccess={() => setForceRefresh(true)}
        showSnackbar={showSnackbar}
      />
    </Box>
  );
}

function ContractDocumentsContentSkeleton() {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Skeleton variant="rounded" height="48px" />
      <Skeleton variant="rounded" height="80vh" />
    </Box>
  );
}
