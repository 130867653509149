import { IconReceipt } from '@tabler/icons-react';

import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';

import { PageContainer } from '../../modules/components/page/PageContainer';
import { PageTitle } from '../../modules/components/page/PageTitle';
import { PageAlert } from '../../modules/components/PageAlert';
import { SwitchContract } from '../../modules/components/user/SwitchContract';

import { ReceiptsCards } from './ReceiptsCards';
import { ReceiptsTable } from './ReceiptsTable';

export type ReceiptsProps = {
  organizationId: string;
  contractId: string | undefined;
};

export function Receipts({ organizationId, contractId }: ReceiptsProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  if (!contractId) {
    return (
      <PageAlert
        message="Seu perfil de acesso não possui contratos vinculados a essa organização."
        severity="warning"
        showRetryMessage={false}
      />
    );
  }
  return (
    <Box>
      {isMobile && (
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          alignItems="center"
          mt={2.5}
          mb={-1}
        >
          <SwitchContract textAlign="center" />
          <Divider sx={{ width: '100%' }} />
        </Box>
      )}
      <PageContainer>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <PageTitle
            dataTestId="receipts-title"
            title="Holerites e recibos"
            icon={IconReceipt}
          />
          {!isMobile && (
            <Box marginBottom={5}>
              <SwitchContract />
            </Box>
          )}
        </Box>
        <ReceiptsContent
          organizationId={organizationId}
          contractId={contractId}
        />
      </PageContainer>
    </Box>
  );
}

function ReceiptsContent({
  organizationId,
  contractId,
}: {
  organizationId: string;
  contractId: string | undefined;
}) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  if (isSmall) {
    return (
      <ReceiptsCards organizationId={organizationId} contractId={contractId} />
    );
  }
  return (
    <ReceiptsTable organizationId={organizationId} contractId={contractId} />
  );
}
