import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isDeleting: boolean;
};

export function DeleteConfirmationDialog({
  open,
  onClose,
  onConfirm,
  isDeleting,
}: Props) {
  const actions = (
    <>
      <LoadingButton
        loading={isDeleting}
        variant={'contained'}
        color="error"
        sx={{ px: 2.25, py: 1.25 }}
        onClick={() => onConfirm()}
      >
        Excluir template
      </LoadingButton>
      <Button
        variant={'contained'}
        color="secondary"
        sx={{ px: 2.25, py: 1.25 }}
        onClick={() => onClose()}
      >
        Manter template
      </Button>
    </>
  );

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 1,
        alignItems: 'flex-start',
        textAlign: 'left',
      }}
    >
      <Typography variant="h2">
        Tem certeza que deseja excluir esse template?
      </Typography>
      <Typography variant="body1">
        Se houver documentos gerados com esse template, eles <b>não</b> serão
        removidos. Você sempre pode incluí-lo novamente, se necessário.
      </Typography>
    </Box>
  );
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: { display: 'none' },
      })}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0,0,0, 0.8)',
          },
        },
      }}
    >
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}
