import { LicenseManager } from 'ag-grid-charts-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

import { LoadingButton } from '@mui/lab';
import { Box, Divider, IconButton, Tab, Tabs, Typography } from '@mui/material';

import {
  PayrollInputsConfig,
  PayrollInputsList,
  PayrollInputsTargets,
  PayrollTypes,
  fetchPostPayrollInputsForPeriod,
} from '@octopus/api';

import { useMemo, useState } from 'react';

import { ConfirmDialog } from '../../../../../modules/components/ConfirmDialog';
import { DropdownButton } from '../../../../../modules/components/DropdownButton';
import { useFFlags } from '../../../../fflags';

import { DependentsTable } from './DependentsTable';
import { ErrorToast } from './ErrorToast';
import { InputsTable } from './InputsTable';
import { TimesheetDrawer } from './timesheet/TimesheetDrawer';
import { useSubmissionState } from './useSubmissionState';

import { useNavigate } from 'react-router-dom';

import { IconTrash } from '@tabler/icons-react';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-058343}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{WGMI_TECNOLOGIAS_LTDA.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Tako}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Tako}_need_to_be_licensed___{Tako}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{12_May_2025}____[v3]_[0102]_MTc0NzAwNDQwMDAwMA==fb0c9b10a095ec0962e15ce924aa04a2',
);

export type PayrollInputsTableProps = {
  organizationId: string;
  companyId: string;
  period: string;
  type: PayrollTypes;
  config: PayrollInputsConfig;
  data: PayrollInputsList;
};

export function PayrollSubmission({
  organizationId,
  companyId,
  period,
  type,
  config,
  data,
}: PayrollInputsTableProps) {
  const navigate = useNavigate();
  const [submissionState, setSubmissionState] = useState<{
    isSubmitting: boolean;
    errorSubmitting?: boolean;
    errorMessage?: string;
  }>({ isSubmitting: false });
  const [timesheetDrawerOpen, setTimesheetDrawerOpen] = useState(false);
  const [confirmResetDialogOpen, setConfirmResetDialogOpen] = useState(false);

  const targets = useMemo(() => {
    return getSubmissionTargets(config);
  }, [config]);

  const { FFlags } = useFFlags();

  const [target, setTarget] = useState<PayrollInputsTargets>(targets[0]);

  const {
    employees,
    dependents,
    hasPayrollBeenEdited,
    getEditCount,
    getSubmissionInput,
  } = useSubmissionState(config, data);

  const submitInputs = async () => {
    setSubmissionState({ isSubmitting: true });
    const submissionInput = getSubmissionInput();
    try {
      const { submissionId } = await fetchPostPayrollInputsForPeriod({
        body: submissionInput,
        pathParams: {
          organizationId,
          companyId,
          periodId: period,
          payrollType: type,
        },
      });
      setSubmissionState({ isSubmitting: false });
      navigate(submissionId);
    } catch (error) {
      console.error(error);
      setSubmissionState({
        isSubmitting: false,
        errorSubmitting: true,
        errorMessage: error.message,
      });
    }
  };

  const onResetChanges = () => {
    try {
      employees.edit.resetAll();
      dependents.edit.resetAll();
    } finally {
      setConfirmResetDialogOpen(false);
    }
  };

  const getTabCount = (selectedTabTarget: PayrollInputsTargets) => {
    const tabCountMap: Record<PayrollInputsTargets, number | undefined> = {
      employee: employees?.data?.length,
      dependent: dependents?.data?.length,
      rpa: undefined,
    };

    return tabCountMap[selectedTabTarget];
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
          pb: (theme) => theme.spacing(1.5),
        }}
      >
        <Box
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.strokes.heavy}`,
          }}
        >
          <Tabs value={target} onChange={(_, newVal) => setTarget(newVal)}>
            {targets.map((tabTarget) => (
              <Tab
                key={tabTarget}
                value={tabTarget}
                iconPosition="end"
                icon={
                  <TargetTabLabel
                    isSelected={target === tabTarget}
                    label={getTargetLabel(tabTarget)}
                    count={getTabCount(tabTarget)}
                  />
                }
              />
            ))}
          </Tabs>
        </Box>
        <Box display="flex">
          <FFlags hasAccessToTheTimesheetImport>
            <DropdownButton
              title="Importar"
              items={[
                {
                  label: 'Importar lançamentos de ponto',
                  onClick: () => setTimesheetDrawerOpen(true),
                },
              ]}
            />
          </FFlags>
        </Box>
      </Box>
      <Box
        width="100%"
        height={`calc(100vh - ${targets.length > 1 ? '305' : '250'}px)`}
      >
        <Box height="100%" display={target === 'employee' ? 'flex' : 'none'}>
          <InputsTable
            organizationId={organizationId}
            companyId={companyId}
            config={config}
            state={employees}
            hasPayrollBeenEdited={hasPayrollBeenEdited}
            show={target === 'employee'}
          />
        </Box>
        <Box height="100%" display={target === 'dependent' ? 'flex' : 'none'}>
          <DependentsTable
            organizationId={organizationId}
            companyId={companyId}
            config={config}
            state={dependents}
            hasPayrollBeenEdited={hasPayrollBeenEdited}
            show={target === 'dependent'}
          />
        </Box>
      </Box>
      <ActionBar
        changes={getEditCount()}
        isLoading={submissionState.isSubmitting}
        onClick={submitInputs}
        onResetChanges={() => setConfirmResetDialogOpen(true)}
      />
      <ErrorToast
        show={submissionState.errorSubmitting}
        close={() => setSubmissionState({ isSubmitting: false })}
      />
      <TimesheetDrawer
        period={period}
        companyId={companyId}
        employees={employees}
        organizationId={organizationId}
        open={timesheetDrawerOpen}
        payrollInputs={config.payload}
        onClose={() => setTimesheetDrawerOpen(false)}
      />
      <ConfirmDialog
        open={confirmResetDialogOpen}
        onClose={() => setConfirmResetDialogOpen(false)}
        onConfirm={onResetChanges}
        title="Descartar mudanças"
        description="Deseja descartar todas as mudanças não aplicadas e voltar para a última versão calculada?"
      />
    </Box>
  );
}

function getSubmissionTargets(
  config: PayrollInputsConfig,
): PayrollInputsTargets[] {
  const targetsSet = Object.values(config.payload).reduce(
    (targets, current) => {
      if (current.target === 'rpa') {
        return targets;
      } else {
        targets.add(current.target);
      }
      return targets;
    },
    new Set<PayrollInputsTargets>(),
  );

  return Array.from(targetsSet).sort((a, b) =>
    getTargetLabel(a).localeCompare(getTargetLabel(b)),
  );
}

function getTargetLabel(target: PayrollInputsTargets): string {
  switch (target) {
    case 'employee':
      return 'Colaboradores';
    case 'dependent':
      return 'Dependentes';
    case 'rpa':
      return 'RPA';
    default:
      target satisfies never;
      return target;
  }
}

function ActionBar({
  changes,
  isLoading,
  onClick,
  onResetChanges,
}: {
  changes: number;
  isLoading: boolean;
  onClick: () => void;
  onResetChanges: () => void;
}) {
  if (changes === 0) {
    return null;
  }
  return (
    <Box
      py={1}
      px={3}
      sx={{
        display: 'flex',
        justifyContent: 'end',
        background: 'rgba(247, 247, 248, 0.8)',
        backdropFilter: 'blur(2px)',
        border: '1px solid #EDEDED',
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
      }}
    >
      <Box display="flex" alignItems="center" gap={2}>
        <Box
          sx={{
            maxHeight: 40,
            borderRadius: 6,
            bgcolor: 'strokes.warning',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              px: 2,
              py: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1.5,
            }}
          >
            <Typography
              variant="caption"
              fontWeight="medium"
              color="warning.dark"
            >
              {changes > 1
                ? `${changes} mudanças não aplicadas`
                : '1 mudança não aplicada'}
            </Typography>
            <Divider orientation="vertical" variant="fullWidth" flexItem />
            <IconButton
              onClick={onResetChanges}
              sx={{
                p: 0.5,
                color: 'warning.dark',
              }}
            >
              <IconTrash width={16} height={16} />
            </IconButton>
          </Box>
        </Box>
        <LoadingButton
          onClick={onClick}
          loading={isLoading}
          variant="contained"
          size="large"
          data-testid="submit-inputs-for-calculation"
          color="primaryAlt"
          sx={{ width: '190px', height: '42px' }}
        >
          Aplicar e calcular
        </LoadingButton>
      </Box>
    </Box>
  );
}

function TargetTabLabel({
  isSelected,
  label,
  count,
}: {
  isSelected: boolean;
  label: string;
  count: number;
}) {
  const fontWeight = isSelected ? 700 : 500;
  const textColor = isSelected ? `primary.main` : 'text.secondary';
  const bgColor = isSelected ? `background.primary` : 'background.default';
  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
      <Typography color={textColor} variant="body1" fontWeight={fontWeight}>
        {label}
      </Typography>
      {count > 0 && (
        <Typography
          bgcolor={bgColor}
          color={textColor}
          py={0.25}
          px={1}
          borderRadius={2}
          variant="caption"
          fontWeight={fontWeight}
        >
          {count}
        </Typography>
      )}
    </Box>
  );
}
