import { useMemo, useState } from 'react';

import { IconChevronDown } from '@tabler/icons-react';

import {
  Box,
  Button,
  MenuItem,
  Popover,
  Typography,
  useTheme,
} from '@mui/material';

type DropdownButtonItem = {
  label: string;
  disabled?: boolean;
  onClick?: () => void | Promise<void>;
};

type DropdownButtonProps = {
  title: string;
  disabled?: boolean;
  items: DropdownButtonItem[];
};

export function DropdownButton({
  items,
  title,
  disabled = false,
}: DropdownButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const theme = useTheme();

  const handleClose = () => setAnchorEl(null);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => setAnchorEl(event.currentTarget);

  const open = useMemo(() => !!anchorEl, [anchorEl]);
  const id = useMemo(() => (open ? 'button-dropdown' : undefined), [open]);

  return (
    <Box>
      <Button
        aria-describedby={id}
        aria-haspopup="true"
        aria-expanded={open}
        data-testid="dropdown-button"
        disabled={disabled}
        onClick={handleClick}
        endIcon={
          <IconChevronDown
            width={20}
            height={20}
            color={theme.palette.text.secondary}
          />
        }
        variant="outlined"
        color="secondary"
        sx={{
          height: (theme) => theme.spacing(5),
        }}
      >
        {title}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        elevation={1}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              item.onClick?.();
              handleClose();
            }}
            disabled={disabled || item.disabled}
            data-testid={`dropdown-item-${index}`}
            sx={{
              p: (theme) => theme.spacing(2),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                key={item.label}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {item.label}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Popover>
    </Box>
  );
}
