import { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';

import { credentialsProvider } from '../../credentialsProvider';
import { download } from '../../spreadsheets/downloader';

export default function DirectDownloadButton({
  organizationId,
  setError,
  type,
  label,
  params,
  tooltip,
}: {
  organizationId: string;
  setError: (error: boolean) => void;
  type: string;
  label: string;
  params?: Record<string, string>;
  tooltip?: string;
}) {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    setError(false);

    const credentials = await credentialsProvider.getCredentials();

    download(credentials, organizationId, type, params)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const button = (
    <LoadingButton onClick={handleClick} loading={loading} variant="contained">
      <span>{label}</span>
    </LoadingButton>
  );

  return tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button;
}
