import { useMemo } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

export function DeleteFileConfirmationDialog({
  open,
  onClose,
  onConfirm,
  isLoading,
  plural,
  description,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  plural?: boolean;
  description?: string;
}) {
  const textDescription = useMemo(() => {
    if (description) {
      return description;
    }

    if (plural) {
      return `Ao excluir estes documentos, eles serão removidos permanentemente da plataforma. Certifique-se de já ter adicionado os documentos atualizados do usuário clicando em “Adicionar documento”.`;
    }

    return `Ao excluir este documento, ele será removido permanentemente da plataforma. Certifique-se de já ter adicionado o documento atualizado do usuário clicando em “Adicionar documento”.`;
  }, [plural, description]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} alignItems="stretch">
          <Typography variant="h2">
            Excluir documento{plural ? 's' : ''}
          </Typography>
          <Box>
            <Typography variant="body1">{textDescription}</Typography>
          </Box>
          <Alert severity="error">
            <Typography component="span" color="error.dark" fontWeight={500}>
              Atenção, essa ação não pode ser desfeita.
            </Typography>
          </Alert>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2.5 }}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={onClose}
          disabled={isLoading}
        >
          Cancelar
        </Button>
        <LoadingButton
          color="primaryAlt"
          variant="contained"
          size="large"
          onClick={onConfirm}
          disabled={isLoading}
          loading={isLoading}
        >
          Confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
