import React from 'react';

import { Box, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

export type TabLabelProps = {
  color?: string;
  label: string;
  isSelected: boolean;
  count?: number;
  variant?: Variant;
};

export default function TabLabel({
  isSelected,
  label,
  color = 'primary',
  count,
  variant = 'body1',
}: TabLabelProps) {
  const fontWeight = isSelected ? 700 : 500;
  const backgroundColor = isSelected ? `${color}.main` : 'strokes.light';
  const labelColor = isSelected ? `${color}.main` : 'text.secondary';
  const numberColor = isSelected ? 'primaryAlt.contrastText' : 'text.secondary';

  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
      <Typography
        component="span"
        color={labelColor}
        variant={variant}
        fontWeight={fontWeight}
      >
        {label}
      </Typography>
      {count !== undefined && count > 0 && (
        <Box
          paddingY={0.25}
          paddingX={1}
          alignItems={'center'}
          borderRadius={2}
          bgcolor={backgroundColor}
          height={'20px'}
        >
          <Typography
            variant="caption"
            fontWeight={fontWeight}
            color={numberColor}
          >
            {count}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
