import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import { LegalEntityContext } from '../../../modules/types';

import { ApprovalConfirmationModal } from './modals/ApprovalConfirmationModal';
import { CancelConfirmationModal } from './modals/CancelConfirmationModal';
import { CreateRPAsModal } from './modals/CreateRPAsModal';
import { PasteCPFListModal } from './modals/PasteCPFListModal';
import { PeriodApprovalConfirmationModal } from './modals/PeriodApprovalConfirmationModal';
import { RemoveAllAutonomosFromListModal } from './modals/RemoveAllAutonomosFromListModal';
import { SelectLegalEntityModal } from './modals/SelectLegalEntityModal';
import { SelectPeriodModal } from './modals/SelectPeriodModal';
import { SendPayslipsConfirmationModal } from './modals/SendPayslipsConfirmationModal';
export function ApprovalDialog({
  open,
  setOpen,
  organizationId,
  companyId,
  payrollId,
  setSingleApprovalStatus,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  organizationId: string;
  companyId: string;
  payrollId: string;
  setSingleApprovalStatus: (
    status: 'pending' | 'approved' | 'rejected',
  ) => void;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      data-testid="approve-payrolls-modal"
    >
      <ApprovalConfirmationModal
        organizationId={organizationId}
        companyId={companyId}
        payrollId={payrollId}
        close={() => setOpen(false)}
        setSingleApprovalStatus={setSingleApprovalStatus}
      />
    </Dialog>
  );
}

export function CancelDialog({
  open,
  setOpen,
  organizationId,
  companyId,
  payrollId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  organizationId: string;
  companyId: string;
  payrollId: string;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      data-testid="cancel-payroll-modal"
    >
      <CancelConfirmationModal
        organizationId={organizationId}
        companyId={companyId}
        payrollId={payrollId}
        close={() => setOpen(false)}
      />
    </Dialog>
  );
}

export function SelectPeriodDialog({
  open,
  setOpen,
  organizationId,
  companyId,
  mode,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  organizationId: string;
  companyId: string;
  mode?: 'edit' | 'create' | 'approve' | 'send';
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      data-testid="approve-payrolls-modal"
    >
      {['create', 'edit'].includes(mode) ? (
        <SelectPeriodModal
          organizationId={organizationId}
          companyId={companyId}
          close={() => setOpen(false)}
          isEditing={mode === 'edit'}
        />
      ) : mode === 'send' ? (
        <SendPayslipsConfirmationModal
          organizationId={organizationId}
          companyId={companyId}
          close={() => setOpen(false)}
        />
      ) : (
        <PeriodApprovalConfirmationModal
          organizationId={organizationId}
          companyId={companyId}
          close={() => setOpen(false)}
        />
      )}
    </Dialog>
  );
}

export function SelectLegalEntityDialog({
  open,
  setOpen,
  selectedLegalEntity,
  setSelectedLegalEntity,
  legalEntities,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedLegalEntity: string;
  setSelectedLegalEntity: (legalEntity: string) => void;
  legalEntities: LegalEntityContext[];
}) {
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      <SelectLegalEntityModal
        close={() => setOpen(false)}
        selectedLegalEntity={selectedLegalEntity}
        setSelectedLegalEntity={setSelectedLegalEntity}
        legalEntities={legalEntities}
      />
    </Dialog>
  );
}

export function CreateRPAsDialog({
  open,
  setOpen,
  onClick,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClick: () => void;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      data-testid="approve-payrolls-modal"
    >
      <CreateRPAsModal onClick={onClick} close={() => setOpen(false)} />
    </Dialog>
  );
}

export function PasteCPFListDialog({
  open,
  setOpen,
  onSubmit,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (cpfList: string[]) => void;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      <PasteCPFListModal onSubmit={onSubmit} close={() => setOpen(false)} />
    </Dialog>
  );
}

export function MissingCPFsDialog({
  open,
  resetMissingCPFs,
  missingCPFs,
}: {
  open: boolean;
  resetMissingCPFs: () => void;
  missingCPFs: string[];
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog open={true} maxWidth="sm">
      <Box display="flex" flexDirection="column" width="550px">
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              alignItems: 'flex-start',
              m: 0,
              width: '100%',
            }}
          >
            <Typography variant="h3">CPFs não encontrados</Typography>
            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
              Os seguintes CPFs não foram encontrados entre os autônomos da sua
              empresa. Certifique-se de que eles estão corretos e pertencem a
              profissionais autônomos cadastrados na sua empresa.
            </Typography>
            <Box
              sx={{
                borderRadius: 1,
                marginTop: 1,
              }}
            >
              <Box component="ul" sx={{ margin: 0, paddingLeft: 2 }}>
                {missingCPFs.map((cpf) => (
                  <Typography component="li" key={cpf}>
                    {cpf}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primaryAlt"
            variant="contained"
            size="large"
            sx={{
              width: '137px',
              height: '48px',
            }}
            onClick={resetMissingCPFs}
          >
            Ok, entendi
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export function RemoveAllAutonomosDialog({
  open,
  setOpen,
  onSubmit,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: () => void;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      <RemoveAllAutonomosFromListModal
        onSubmit={onSubmit}
        close={() => setOpen(false)}
      />
    </Dialog>
  );
}
