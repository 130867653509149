import { useContext, useState } from 'react';

import { IconCheck, IconChevronDown, IconUser } from '@tabler/icons-react';

import {
  Avatar,
  Box,
  Divider,
  List,
  ListItemButton,
  Popover,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { AppContext } from '../../../app/context';
import { getAvatarColor } from '../../../utils';
import { ContractContext } from '../../types';

export const SwitchContract = ({
  textAlign,
  popoverAlign,
}: {
  textAlign?: 'center' | 'left' | 'right';
  popoverAlign?: 'left' | 'right';
}) => {
  const { appContext, changeContract } = useContext(AppContext);
  const { allContracts, contract, company } = appContext || {};
  const { jobTitles } = company || {};
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  let currentContractIndex;

  const jobTitlesMap =
    jobTitles?.length > 0
      ? jobTitles?.reduce(
          (acc, jobTitle) => {
            acc[jobTitle.id as string] = jobTitle?.name;
            return acc;
          },
          {} as Record<string, string>,
        )
      : {};

  const allContractsWithJobTitles = allContracts?.map(
    (c: ContractContext, idx: number) => {
      if (c.contractId === contract?.contractId) {
        currentContractIndex = idx;
      }
      return {
        ...c,
        jobTitle: jobTitlesMap?.[c?.jobTitleId],
        idx,
      };
    },
  );

  const currentContract = {
    ...contract,
    jobTitle: jobTitlesMap?.[contract?.jobTitleId],
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <Box>
      <CurrentContract
        contract={currentContract}
        currentContractIndex={currentContractIndex}
        handleClick={handleClick}
        align={textAlign}
      />
      {isSmall ? (
        <ContractListMobile
          allContracts={allContractsWithJobTitles}
          changeContract={changeContract}
          currentContractIndex={currentContractIndex}
          open={open}
          onClose={handleClose}
        />
      ) : (
        <ContractList
          allContracts={allContractsWithJobTitles}
          changeContract={changeContract}
          currentContractIndex={currentContractIndex}
          anchorEl={anchorEl}
          onClose={handleClose}
          align={popoverAlign}
        />
      )}
    </Box>
  );
};

export const CurrentContract = ({
  contract,
  currentContractIndex,
  handleClick,
  align = 'right',
}: {
  contract: ContractContext & { jobTitle: string };
  currentContractIndex: number;
  handleClick: (event: React.MouseEvent<any>) => void;
  align?: 'center' | 'left' | 'right';
}) => {
  const alignItems = {
    center: 'center',
    right: 'end',
    left: 'start',
  };

  return (
    <Box
      sx={{ cursor: 'pointer' }}
      display="flex"
      alignItems="center"
      justifyContent={align}
      gap={0.5}
      onClick={handleClick}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems={alignItems[align]}
        mr={1.5}
      >
        <Box display="flex" gap={0.5}>
          <IconUser size={16} style={{ color: '#25252D' }} />
          <Typography variant="body2" color="text.primary" fontWeight={700}>
            Contrato {currentContractIndex + 1}
          </Typography>
        </Box>
        <Typography variant="body2" color="text.secondary" fontWeight={500}>
          {contract?.jobTitle}
        </Typography>
      </Box>
      <IconChevronDown size={20} className="default" />
    </Box>
  );
};

export const ContractListMobile = ({
  allContracts,
  changeContract,
  currentContractIndex,
  open,
  onClose,
}: {
  allContracts: ContractContext[];
  changeContract: (contract: ContractContext) => void;
  currentContractIndex: number;
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => null}
      swipeAreaWidth={0}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        },
      }}
    >
      <Divider
        sx={{
          width: 64,
          height: 4,
          mt: 3,
          mb: 2,
          mx: 'auto',
          bgcolor: '#EDEDED',
        }}
      />
      <List>
        {allContracts?.map((contract, idx) => {
          return (
            <ContractItem
              key={contract.contractId}
              contract={contract}
              idx={idx}
              active={idx === currentContractIndex}
              changeContract={changeContract}
              isMobile={true}
            />
          );
        })}
      </List>
    </SwipeableDrawer>
  );
};

export const ContractList = ({
  allContracts,
  changeContract,
  currentContractIndex,
  anchorEl,
  onClose,
  align = 'right',
}: {
  allContracts: ContractContext[];
  changeContract: (contract: ContractContext) => void;
  currentContractIndex: number;
  anchorEl: null | HTMLElement;
  onClose: () => void;
  align?: 'left' | 'right';
}) => {
  return (
    <Popover
      open={!!anchorEl}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: align,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: align,
      }}
      sx={{
        mt: 1.5,
        borderRadius: '12px',
      }}
    >
      <List
        sx={{
          minWidth: 350,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          py: 1.5,
        }}
      >
        {allContracts?.map((contract, idx) => {
          return (
            <ContractItem
              key={contract.contractId}
              contract={contract}
              idx={idx}
              active={idx === currentContractIndex}
              changeContract={changeContract}
            />
          );
        })}
      </List>
    </Popover>
  );
};

export const ContractItem = ({
  contract,
  idx,
  active,
  changeContract,
  isMobile = false,
}: {
  contract: ContractContext;
  idx: number;
  active: boolean;
  changeContract: (contract: ContractContext) => void;
  isMobile?: boolean;
}) => {
  return (
    <ListItemButton
      key={contract.contractId}
      onClick={() => changeContract(contract)}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '100%' }}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <Avatar
            sx={(theme) => ({
              width: 24,
              height: 24,
              backgroundColor: getAvatarColor(idx.toString(), theme),
              fontSize: '8px',
              border: `0.5px solid rgba(0, 0, 0, 0.08)`,
              color: theme.palette.text.secondary,
            })}
            variant="circular"
          >
            {contract?.name?.substring(0, 2)?.toUpperCase()}
          </Avatar>
          <Box>
            <Typography variant="body2" color="text.primary">
              {`Contrato ${idx + 1}`}
            </Typography>
            {isMobile && (
              <Typography variant="caption" color="text.secondary">
                {contract.jobTitle}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          sx={{ mr: active ? 0 : '36px' }}
        >
          {!isMobile && (
            <Typography variant="caption" color="text.secondary">
              {contract.jobTitle}
            </Typography>
          )}

          {active && <IconCheck size={20} className="info" />}
        </Box>
      </Box>
    </ListItemButton>
  );
};
