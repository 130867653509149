import { useContext, useEffect, useMemo } from 'react';

import { LDContext, initialize } from 'launchdarkly-js-client-sdk';

import { createFFlags } from '@octopus/ui/flags';

import { IAppContext } from '../modules/types';

import { AppContext } from './context';

export const features = {
  canEditRpas: 'canEditRpas',
  isPjEnabledForOrganization: 'isPjEnabledForOrganization',
  isCltEnabledForOrganization: 'isCltEnabledForOrganization',
  isCompanyTransferEnabledForOrganization:
    'isCompanyTransferEnabledForOrganization',
  pjAdmissionEnabled: 'pjAdmissionEnabled',
  tsvAdmissionEnabled: 'tsvAdmissionEnabled',
  hasAccessToAutonomos: 'hasAccessToAutonomos',
  managerVacationsApprovalEnabled: 'managerVacationsApprovalEnabled',
  proLaborePayrollArchivingEnabled: 'proLaborePayrollArchivingEnabled',
  hasAccessToTheTimesheetImport: 'hasAccessToTheTimesheetImport',
  canAccessOrganizationManagement: 'canAccessOrganizationManagement',
  canEditJobTitle: 'canEditJobTitle',
  canAccessCostCenterManagement: 'canAccessCostCenterManagement',
  canAccessDepartmentManagement: 'canAccessDepartmentManagement',
  kitAdmissionalEnabled: 'kitAdmissionalEnabled',
  kitDemissionalEnabled: 'kitDemissionalEnabled',
  kitFeriasEnabled: 'kitFeriasEnabled',
} as const;

const FEATURE_TO_FLAG_MAPPING: {
  [key in keyof typeof features]: {
    key: string;
    defaultValue: boolean;
  };
} = {
  isPjEnabledForOrganization: {
    key: 'is-pj-enabled-for-organization',
    defaultValue: false,
  },
  isCltEnabledForOrganization: {
    key: 'is-clt-enabled-for-organization',
    defaultValue: true,
  },
  isCompanyTransferEnabledForOrganization: {
    key: 'is-company-transfer-enabled-for-organization',
    defaultValue: false,
  },
  pjAdmissionEnabled: {
    key: 'pj-admission-enabled',
    defaultValue: false,
  },
  tsvAdmissionEnabled: {
    key: 'tsv-admission-enabled',
    defaultValue: false,
  },
  hasAccessToAutonomos: {
    key: 'HasAccessToAutonomos',
    defaultValue: false,
  },
  canEditRpas: {
    key: 'canEditRpas',
    defaultValue: false,
  },
  managerVacationsApprovalEnabled: {
    key: 'managerVacationApproval',
    defaultValue: false,
  },
  proLaborePayrollArchivingEnabled: {
    key: 'proLaborePayrollArchivingEnabled',
    defaultValue: false,
  },
  hasAccessToTheTimesheetImport: {
    key: 'has-access-to-the-timesheet-import',
    defaultValue: false,
  },
  canAccessOrganizationManagement: {
    key: 'can-access-organization-management',
    defaultValue: false,
  },
  canEditJobTitle: {
    key: 'can-edit-job-title',
    defaultValue: false,
  },
  canAccessCostCenterManagement: {
    key: 'can-access-cost-center-management',
    defaultValue: false,
  },
  canAccessDepartmentManagement: {
    key: 'can-access-department-management',
    defaultValue: false,
  },
  kitAdmissionalEnabled: {
    key: 'kit-admissional',
    defaultValue: false,
  },
  kitDemissionalEnabled: {
    key: 'kit-demissional',
    defaultValue: false,
  },
  kitFeriasEnabled: {
    key: 'kit-ferias',
    defaultValue: false,
  },
} as const;

export type TFFlags = {
  [key in keyof typeof features]: { enabled: boolean };
};

const flagNames = Object.values(features);

export const { FFlagsProvider, useFFlags, resolveFFlags } =
  createFFlags(flagNames);

export function useResolveFFlags() {
  const { isError, error, appContext } = useContext(AppContext);
  const ldClient = useMemo(
    () =>
      initialize('6696d0363fdedb0fa94429a8', {
        kind: 'init',
        key: 'auditing',
        anonymous: true,
      }),
    [],
  );

  useEffect(() => {
    resolveFFlags(async (fflags) => {
      if (isError) {
        console.error(
          `Error fetching app context. Couldn't initialize fflags.`,
        );
        console.error(error);
        return;
      }
      if (
        appContext?.membership?.organizationId &&
        appContext?.user?.userId &&
        appContext?.user?.email
      ) {
        await ldClient.identify(prepareContext(appContext));
        for (const [feature, { key, defaultValue }] of Object.entries(
          FEATURE_TO_FLAG_MAPPING,
        )) {
          fflags.set(
            feature as keyof typeof features,
            ldClient.variation(key, defaultValue),
          );
        }
      }
    });
  }, [
    appContext?.membership?.organizationId,
    appContext?.user?.userId,
    appContext?.user?.email,
  ]);
}

function prepareContext(appContext: IAppContext): LDContext {
  return {
    kind: 'multi',
    user: {
      key: appContext.user.userId,
      email: appContext.user.email,
    },
    organization: {
      key: appContext.membership.organizationId,
    },
  };
}
