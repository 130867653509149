import { MembershipSummary, OrganizationSummary } from '@octopus/api';

import {
  ChangeCompany,
  ChangeContract,
  ChangeMembership,
  IAppContext,
  MembershipContext,
  MembershipOption,
} from '../types';

import { useAdmissionDraft } from './useAdmissionDraft';
import { useCompanies } from './useCompanies';
import { useMembership } from './useMembership';
import { useOrganizations } from './useOrganizations';
import { useUserIdentity } from './useUser';
import { useUserContracts } from './useUserContracts';

export type AppContextProps = {
  onAuthFailure: () => void;
};

type AppContextState = {
  isLoading: boolean;
  isError: boolean;
  error?: string;
};

export type AppContextResult = AppContextState & {
  appContext: IAppContext | undefined;
  membershipOptions: MembershipOption[] | undefined;
  changeMembership: ChangeMembership;
  changeContract: ChangeContract;
  changeCompany: ChangeCompany;
  refetchCompanyData: () => Promise<unknown>;
};

export function useAppContext({
  onAuthFailure,
}: AppContextProps): AppContextResult {
  const user = useUserIdentity(onAuthFailure);
  const membership = useMembership(user);
  const organizations = useOrganizations(membership);
  const companies = useCompanies(membership);
  const contracts = useUserContracts(membership?.currentMembership, user);
  const admissionDraft = useAdmissionDraft(membership?.currentMembership, user);

  const isLoading = membership.isLoading || organizations.isLoading;

  return {
    isLoading,
    isError: false,
    appContext: user?.userId
      ? {
          user,
          membership: toMembershipContext(
            membership?.currentMembership,
            organizations?.organizationsMap,
          ),
          organization: organizations?.currentOrganization,
          companiesMap: companies?.companiesMap,
          company: companies?.data,
          currentCompany: companies?.currentCompany,
          allContracts: contracts?.allContracts,
          contract: contracts?.currentContract,
          admission: admissionDraft?.data,
        }
      : undefined,
    membershipOptions: getMembershipOptions(
      membership?.memberships,
      organizations?.organizationsMap,
    ),
    changeContract: contracts?.setCurrentContract,
    changeMembership: membership?.changeMembership,
    changeCompany: companies?.setCurrentCompany,
    refetchCompanyData: companies?.refetch,
  };
}

const toMembershipContext = (
  membership: MembershipContext,
  organizations: Record<string, OrganizationSummary>,
) => ({
  organizationId: membership?.organizationId,
  membershipType: membership?.membershipType,
  label: organizations?.[membership?.organizationId]?.name,
  logo: organizations?.[membership?.organizationId]?.logo,
});

const getMembershipOptions = (
  membership: MembershipSummary[],
  organizations: Record<string, OrganizationSummary>,
): MembershipOption[] => {
  return membership
    ?.map((m) => {
      const organization = organizations?.[m?.organizationId];
      return {
        organizationId: m?.organizationId,
        membershipType: m?.type,
        label: organization?.name,
        logo: organization?.logo,
      };
    })
    ?.sort((a, b) => a?.label?.localeCompare(b?.label));
};
