import ExcelJS from 'exceljs';

export type SetImageProps = {
  base64Image: string;
  position: {
    row: number;
    col: number;
  };
  dimensions: {
    width: number;
    height: number;
  };
};

export function setImage(
  workbook: ExcelJS.Workbook,
  worksheet: ExcelJS.Worksheet,
  {
    base64Image,
    position: { row, col },
    dimensions: { width, height },
  }: SetImageProps,
) {
  const imageId = workbook.addImage({
    base64: base64Image,
    extension: 'png',
  });
  worksheet.addImage(imageId, { tl: { col, row }, ext: { width, height } });
}
