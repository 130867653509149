import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import {
  VacationsAccrualPeriodEntry,
  VacationsConfigurationEntry,
} from '@octopus/api';
import {
  DaysBeforeDSRRuleConfiguration,
  DaysBeforeStartRuleConfiguration,
  ExtraVacationDaysRule,
} from '@octopus/vacations-types';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const getCurrentAccrualPeriods = (
  accrualPeriods: VacationsAccrualPeriodEntry[],
): VacationsAccrualPeriodEntry | undefined => {
  return accrualPeriods?.find(({ daysAvailable }) => daysAvailable > 0);
};

export const getAvailablesVacations = (
  accrualPeriods: VacationsAccrualPeriodEntry[],
) => {
  return accrualPeriods
    ?.filter((accrualPeriod) =>
      dayjs(accrualPeriod?.startDate).subtract(1, 'day').isBefore(dayjs()),
    )
    ?.filter((accrualPeriod) => accrualPeriod?.lost === undefined)
    ?.filter(
      (accrualPeriod) =>
        !(
          accrualPeriod?.daysAvailable === 0 &&
          (accrualPeriod?.daysTaken > 0 || accrualPeriod?.daysScheduled > 0) &&
          dayjs().isAfter(accrualPeriod?.concessionPeriod?.startDate)
        ),
    );
};

export const getVacationsConfiguration = (
  accrualPeriod: VacationsAccrualPeriodEntry,
  vacationsConfiguration: VacationsConfigurationEntry,
) => {
  const { rules } = vacationsConfiguration || {};
  const { concessionPeriod, daysAvailable } = accrualPeriod || {};
  if (!rules || !concessionPeriod) {
    return {};
  }

  const isUpcomingConcessionPeriod = dayjs().isBefore(
    accrualPeriod?.concessionPeriod?.startDate,
  );

  const effectiveDate = isUpcomingConcessionPeriod
    ? dayjs(accrualPeriod?.concessionPeriod?.startDate)
    : dayjs();

  const {
    enabled: daysBeforeStartEnabled,
    minimum,
    maximum,
  } = rules?.daysBeforeStart as DaysBeforeStartRuleConfiguration;

  const { enabled: daysBeforeDSREnabled, days: daysBeforeDsr } =
    rules?.daysBeforeDSR as DaysBeforeDSRRuleConfiguration;

  const { enabled: daysAvailableEnabled } =
    rules?.daysAvailable as DaysBeforeDSRRuleConfiguration;

  const { enabled: atLeast5DaysEnabled } =
    rules?.art134AtLeast5Days as DaysBeforeDSRRuleConfiguration;

  const { enabled: mayOnlySellAThirdOfVacationsEnabled } =
    rules?.mayOnlySellAThirdOfVacations as DaysBeforeDSRRuleConfiguration;

  const canSellVacation = rules?.maySellVacations;
  const remainingDaysAvailable = daysAvailable;

  const minDate = daysBeforeStartEnabled
    ? dayjs().add(minimum, 'day').isAfter(effectiveDate)
      ? dayjs().add(minimum, 'day')
      : effectiveDate
    : effectiveDate;
  const maxDate = daysBeforeStartEnabled
    ? effectiveDate.add(maximum, 'day')
    : undefined;

  const minDuration = 1;
  const maxDuration = daysAvailable;
  const disableWeekends = (date: dayjs.Dayjs) => {
    if (!daysBeforeDSREnabled) {
      return false;
    }
    if (daysBeforeDsr < 2) {
      return [0, 6].includes(date.day());
    }
    return [0, 5, 6].includes(date.day());
  };

  const currentMonth = dayjs().format('MMMM').toLowerCase();
  const isThirteenthSalaryAdvanceEligible =
    rules?.mayRequestThirteenthAdvance &&
    rules?.thirteenthAdvanceAllowedMonths?.monthsAllowedToRequest?.includes(
      currentMonth,
    );

  return {
    minDate,
    maxDate,
    minDuration,
    maxDuration,
    disableWeekends,
    canSellVacation,
    remainingDaysAvailable,
    isThirteenthSalaryAdvanceEligible,
    daysAvailableEnabled,
    atLeast5DaysEnabled,
    mayOnlySellAThirdOfVacationsEnabled,
  };
};

/**
 * Calculates the return date considering collective holiday rules
 * @param startDate - The vacation start date
 * @param days - Number of vacation days (must be positive)
 * @param configuration - Vacation configuration containing collective holiday rules
 * @returns The calculated return date
 */
export function calculateReturnDate(
  startDate: Dayjs,
  days: number,
  configuration: VacationsConfigurationEntry,
): Dayjs {
  const { unionExtraDays } = configuration || {};

  let extraDays = 0;
  if (Array.isArray(unionExtraDays) && unionExtraDays.length > 0) {
    extraDays = calculateTotalVacationDaysWithCollectiveRules(
      startDate,
      startDate.add(days - 1, 'day'),
      unionExtraDays as ExtraVacationDaysRule[],
    );
  }

  return startDate.add(days + extraDays - 1, 'day');
}

/**
 * Calculates additional vacation days based on collective holiday rules.
 * @param startDate - The start date of the vacation period
 * @param endDate - The end date of the vacation period
 * @param rules - Array of rules defining non-deductible days
 * @returns The number of extra vacation days based on the rules
 * @throws {Error} If any required parameter is null/undefined
 */
export function calculateTotalVacationDaysWithCollectiveRules(
  startDate: Dayjs,
  endDate: Dayjs,
  rules: ExtraVacationDaysRule[],
): number {
  let extraDays = 0;
  if (!rules?.length || !endDate || !startDate) {
    return 0;
  }

  for (const rule of rules) {
    const ruleStart = dayjs(rule.start);
    const ruleEnd = dayjs(rule.end);

    // Verifica se o período de férias está dentro do intervalo de validade da regra
    if (startDate.isBefore(ruleEnd) && endDate.isAfter(ruleStart)) {
      // Cria a data que queremos verificar, ajustando o ano para coincidir com o período de férias
      let checkDate = dayjs(
        new Date(startDate.year(), rule.month - 1, rule.day),
      );

      // Ajusta o ano de checkDate se estiver fora do período de férias
      if (checkDate.isBefore(startDate)) {
        checkDate = checkDate.add(1, 'year');
      }

      // Verifica se a data está no intervalo e, se necessário, se cai em um dia útil
      const isInRange =
        checkDate.isSameOrAfter(startDate) && checkDate.isSameOrBefore(endDate);
      const isWeekday = checkDate.day() >= 1 && checkDate.day() <= 5;

      if (isInRange && (!rule.onlyWeekdays || isWeekday)) {
        extraDays += 1;
      }
    }
  }

  return extraDays;
}
