import { ZoomInOutlined } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Skeleton, Typography } from '@mui/material';

import { PayrollElement, PayrollEntry } from '@octopus/api';
import { formatMoney } from '@octopus/formatters';

import { DisplayDiff } from './DisplayDiff';

export function ElementsTableItem({
  currentElement,
  comparisonElement,
  isLoadingComparisonPayroll,
  comparisonPayroll,
  onClickElement,
  selectedElementId,
  selectorIconPosition = 'left',
  hasComparison,
}: {
  currentElement?: PayrollElement;
  comparisonElement?: PayrollElement;
  comparisonPayroll?: PayrollEntry;
  isLoadingComparisonPayroll?: boolean;
  onClickElement: (currentElement: PayrollElement) => void;
  selectedElementId?: string | undefined;
  selectorIconPosition?: 'left' | 'right';
  hasComparison: boolean;
}) {
  const id = currentElement?.id || comparisonElement?.id;
  const enableClick = currentElement != null;
  return (
    <Box
      component="tr"
      key={id}
      onClick={() => enableClick && onClickElement(currentElement)}
      sx={(theme) => ({
        cursor: enableClick ? 'pointer' : 'inherit',
        transition: 'all 0.2s',
        'td:first-of-type': {
          pl: 0.5,
          borderRadius: '8px 0 0 8px',
        },
        'td:last-child': {
          pr: 0.5,
          borderRadius: '0 8px 8px 0',
        },
        'td[data-css-isDirectionIndicator] p': {
          opacity: 0,
          color:
            selectedElementId === id
              ? theme.palette.text.secondary
              : theme.palette.primary.main,
          transition: 'all 0.2s',
        },
        'td[data-css-isDirectionIndicator=true] p': {
          opacity: selectedElementId === id ? 1 : 0,
        },
        ':hover': {
          transform: 'translate(1px)',
          backgroundColor: 'strokes.light',
          'td[data-css-isDirectionIndicator=true] p': {
            opacity: 1,
          },
          'td[data-css-isDirectionIndicator=false] p': {
            opacity: 0,
          },
        },
        backgroundColor:
          selectedElementId === id ? theme.palette.background.default : '',
      })}
      data-testid="payroll-details-element"
    >
      {enableClick ? (
        <td
          role="presentation"
          data-css-isDirectionIndicator={selectorIconPosition === 'left'}
        >
          <Typography
            variant="body2"
            sx={{
              pt: 0.5,
              transition: 'all 0.2s',
              minHeight: '28px',
            }}
          >
            <ZoomInOutlined fontSize="small" />
          </Typography>
        </td>
      ) : (
        <td />
      )}
      <Box component="td" sx={{ paddingInlineEnd: 1, width: '20em' }}>
        <Typography variant="body2">
          {currentElement?.name || comparisonElement?.name}
        </Typography>
      </Box>
      <td>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2">
            {formatMoney(currentElement?.amount, {
              signDisplay: '-',
              withSign: currentElement?.type === 'deductions',
            })}
          </Typography>
          {currentElement &&
            currentElement.meta?.alert &&
            {
              info: <InfoIcon color="action" fontSize="small" />,
              warning: <WarningAmberIcon color="action" fontSize="small" />,
            }[currentElement.meta?.alert.type]}
        </Box>
      </td>
      {hasComparison && (
        <td>
          {isLoadingComparisonPayroll && (
            <Skeleton variant="rounded" width="100%" />
          )}
          {!isLoadingComparisonPayroll && comparisonPayroll && (
            <DisplayDiff
              current={currentElement?.amount}
              compare={comparisonElement?.amount}
              withSign={false}
              withCurrency={false}
              variant="caption"
            />
          )}
          {!isLoadingComparisonPayroll && !comparisonPayroll && (
            <Typography
              variant="caption"
              sx={{
                color: 'strokes.heavy',
              }}
            >
              N/A
            </Typography>
          )}
        </td>
      )}
      <td
        role="presentation"
        data-css-isDirectionIndicator={selectorIconPosition === 'right'}
      >
        <Typography
          variant="body2"
          sx={{
            pt: 0.5,
            transition: 'all 0.2s',
          }}
        >
          <ZoomInOutlined fontSize="small" />
        </Typography>
      </td>
    </Box>
  );
}
