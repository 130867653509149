import { useMemo } from 'react';

import { Box, Divider, useTheme } from '@mui/material';

import { TimesheetStepError, TimesheetStepErrorCode } from '../types';

import { TimesheetIssue } from './TimesheetIssue';
import { TimesheetValidationContainer } from './TimesheetValidationContainer';
export function TimesheetIssues({
  errors,
  type,
}: {
  errors: { code: TimesheetStepErrorCode; lines: TimesheetStepError[] }[];
  type: 'error' | 'warning';
}) {
  const theme = useTheme();

  const defaultExpanded = useMemo(() => errors.length === 1, [errors]);

  const backgroundColor = useMemo(
    () =>
      type === 'error'
        ? theme.palette.error.lightest
        : theme.palette.warning.lightest,
    [theme.palette.error.lightest, theme.palette.warning.lightest, type],
  );

  if (!errors.length) {
    return null;
  }

  return (
    <TimesheetValidationContainer
      sx={{
        padding: 0,
        gap: (theme) => theme.spacing(1.5),
      }}
    >
      <Divider sx={{ width: '100%' }} variant="fullWidth" />
      <Box
        data-testid="timesheet-issues"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          bgcolor: backgroundColor,
          borderRadius: (theme) => theme.spacing(1.5),
        }}
      >
        {errors.map((error, index) => (
          <TimesheetIssue
            key={`issue-${error.code}-${index}`}
            index={index}
            error={error}
            defaultExpanded={defaultExpanded}
          />
        ))}
      </Box>
    </TimesheetValidationContainer>
  );
}
