import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IconChevronDown } from '@tabler/icons-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import {
  Box,
  Button,
  Container,
  Dialog,
  Skeleton,
  Toolbar,
  Typography,
} from '@mui/material';

import {
  PayrollInputsConfig,
  PayrollInputsList,
  PayrollInputsPayroll,
  fetchGetPayrollInputsConfiguration,
  fetchGetPayrollInputsForPeriod,
} from '@octopus/api';
import { formatPeriodDate } from '@octopus/formatters';
import { DataGridToolbar, useDataGrid } from '@octopus/ui/data-grid';

import Pencil from '../../../assets/pencil.svg';
import { TrashIcon } from '../../../modules/components/autonomos/icon';
import { PageAlert } from '../../../modules/components/PageAlert';
import EnableMoreActionsPopover from '../../../modules/components/payrolls/moreActionsPopover';
import { DataFetching } from '../../../modules/dataFetching';
import { LegalEntityContext } from '../../../modules/types';
import { AppContext } from '../../context';

import createBaseRPAInputConfiguration from './inputs/createBaseRpaInputConfiguration';
import RPASubmission from './inputs/RPASubmission';
import {
  PasteCPFListDialog,
  RemoveAllAutonomosDialog,
  SelectLegalEntityDialog,
} from './RpaDialogs';

export default function RPALaunchPage({
  organizationId,
  companyId,
}: {
  organizationId: string | undefined;
  companyId: string | undefined;
}) {
  const { period } = useParams<{
    period: string;
  }>();
  const navigate = useNavigate();
  const { appContext } = useContext(AppContext);
  const queryClient = useQueryClient();

  // URL params
  const isEditing =
    new URLSearchParams(window.location.search).get('editMode') === 'true';
  const urlLegalEntityId = new URLSearchParams(window.location.search).get(
    'legalEntityId',
  );

  // State
  const [showSelectLegalEntityDialog, setShowSelectLegalEntityDialog] =
    useState(false);
  const [showPasteCPFDialog, setShowPasteCPFDialog] = useState(false);
  const [enableMoreActionsPopoverOpen, setEnableMoreActionsPopoverOpen] =
    useState(false);
  const [popoverAnchorEl, setPopOverAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [selectedLegalEntity, setSelectedLegalEntity] = useState<string>(
    urlLegalEntityId &&
      appContext?.company?.legalEntities.some(
        (le) => le.id === urlLegalEntityId,
      )
      ? urlLegalEntityId
      : appContext?.company?.legalEntities[0].id,
  );

  const [showRemoveAllAutonomosDialog, setShowRemoveAllAutonomosDialog] =
    useState(false);

  // More actions state
  const moreActionsState = {
    enableMoreActionsPopoverOpen,
    setEnableMoreActionsPopoverOpen,
    popoverAnchorEl,
    setPopOverAnchorEl,
  };

  // Refs
  const cpfListRef = useRef<string[]>([]);
  const selectedLegalEntityRef = useRef<string>(selectedLegalEntity);
  const rpaHandlersRef = useRef({
    search: () => console.log('Search handler not initialized'),
    legalEntityChange: () =>
      console.log('Legal entity handler not initialized'),
  });

  const [createPayload, setCreatePayload] = useState<PayrollInputsList>({
    organizationId,
    companyId,
    period,
    type: 'rpa',
    payrolls: {},
  });

  // Callbacks
  const handleCPFListSubmit = useCallback((newCpfList: string[]) => {
    cpfListRef.current = newCpfList;
    setShowPasteCPFDialog(false);
    rpaHandlersRef.current.search();
  }, []);

  const registerSearchHandler = useCallback((handler: () => void) => {
    rpaHandlersRef.current.search = handler;
  }, []);

  const registerLegalEntityHandler = useCallback((handler: () => void) => {
    rpaHandlersRef.current.legalEntityChange = handler;
  }, []);

  // Effects
  useEffect(() => {
    selectedLegalEntityRef.current = selectedLegalEntity;
    rpaHandlersRef.current.legalEntityChange();
  }, [selectedLegalEntity]);

  // Enhanced cleanup effect
  useEffect(() => {
    return () => {
      // Reset the CPF list
      cpfListRef.current = [];
      // Clear any other related queries
      queryClient.removeQueries(['searchCpfsQuery', organizationId, companyId]);
    };
  }, [queryClient, organizationId, companyId]);

  const editRPAMoreActions = [
    {
      label: 'Colar uma lista de CPFs',
      onClick: () => setShowPasteCPFDialog(true),
    },
    {
      label: '',
      divider: true,
    },
    {
      label: 'Remover todos autônomos',
      icon: TrashIcon(),
      onClick: () => setShowRemoveAllAutonomosDialog(true),
    },
  ];

  const dataGridProps = useDataGrid({
    filters: [],
    key: `rpa-launch-${organizationId}`,
  });

  const inputsConfiguration = useQuery({
    queryKey: ['rpa-fetch-inputs-configuration', organizationId, companyId],
    queryFn: () => {
      return fetchGetPayrollInputsConfiguration({
        pathParams: {
          organizationId,
          companyId,
        },
      });
    },
    refetchOnWindowFocus: false,
    enabled: !!organizationId && !!companyId,
  });

  useEffect(() => {
    try {
      if (
        inputsConfiguration.isSuccess &&
        Object.values(inputsConfiguration.data.payload).filter(
          (input) => input.target === 'rpa',
        ).length === 0
      ) {
        inputsConfiguration.data.payload = createBaseRPAInputConfiguration({
          organizationId,
          companyId,
          existingPayload: inputsConfiguration.data.payload,
        });
      }
    } catch (error) {
      console.error('Error creating base RPA input configuration', error);
    }
  }, [inputsConfiguration.isSuccess]);

  const payrollInputs = useQuery({
    queryKey: ['rpa-fetch-inputs', organizationId, companyId],
    queryFn: () => {
      return fetchGetPayrollInputsForPeriod({
        pathParams: {
          organizationId,
          companyId,
          periodId: period,
          payrollType: 'rpa',
        },
      });
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!organizationId && !!companyId && isEditing,
  });

  const handleRemoveAllAutonomosSubmit = useCallback(() => {
    if (!isEditing) {
      setCreatePayload({
        organizationId,
        companyId,
        period,
        type: 'monthly',
        payrolls: {},
      });
      setCreatePayload({
        organizationId,
        companyId,
        period,
        type: 'rpa',
        payrolls: {},
      });
    }
    setShowRemoveAllAutonomosDialog(false);
    try {
      queryClient.setQueryData(
        ['rpa-fetch-inputs', organizationId, companyId],
        {
          ...payrollInputs.data,
          payrolls: {},
        },
      );
    } catch (error) {
      console.error('Failed to remove autonomos:', error);
    }
  }, [organizationId, companyId, period]);

  const toolbar = useMemo(
    () => (
      <DataGridToolbar
        filters={[]}
        searchProps={dataGridProps.searchProps}
        filteringProps={dataGridProps.filteringProps}
        searchPlaceholder="Procurar"
        hideFilters={true}
      >
        <Box
          sx={{
            alignContent: 'end',
            flex: 'end',
            display: 'flex',
            gap: 1,
            justifyContent: 'end',
            width: '100%',
          }}
        >
          <Button
            color="secondary"
            sx={{ display: 'flex', width: 130, height: 40, p: 1, m: 0, gap: 1 }}
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              moreActionsState.setPopOverAnchorEl(event.currentTarget);
              moreActionsState.setEnableMoreActionsPopoverOpen(true);
            }}
          >
            <Typography variant={'body2'} color="primaryAlt" fontWeight={400}>
              Mais ações
            </Typography>
            <IconChevronDown height={'20px'} width={'20px'} />
          </Button>
        </Box>
        <EnableMoreActionsPopover
          moreActionsProps={moreActionsState}
          actions={editRPAMoreActions}
        />
        <SelectLegalEntityDialog
          open={showSelectLegalEntityDialog}
          setOpen={setShowSelectLegalEntityDialog}
          selectedLegalEntity={selectedLegalEntity}
          setSelectedLegalEntity={setSelectedLegalEntity}
          legalEntities={
            appContext.company.legalEntities as LegalEntityContext[]
          }
        />
      </DataGridToolbar>
    ),
    [
      showSelectLegalEntityDialog,
      moreActionsState,
      showRemoveAllAutonomosDialog,
    ],
  );
  const dataFetchingContent = useMemo(
    () => (
      <DataFetching<{
        inputsConfiguration: PayrollInputsConfig;
        payrollInputs: PayrollInputsList;
      }>
        containerSx={{ height: '100%' }}
        fetchResult={{
          results: {
            inputsConfiguration: {
              ...inputsConfiguration,
              isLoading: inputsConfiguration.isLoading,
            },
            payrollInputs: isEditing
              ? {
                  ...payrollInputs,
                  isLoading: payrollInputs.isLoading,
                }
              : {
                  ...payrollInputs,
                  data: createPayload,
                  isLoading: false,
                },
          },
        }}
        Loading={() => (
          <Skeleton
            variant="rounded"
            width="100%"
            height="calc(100vh - 245px)"
          />
        )}
        Data={({ data }) => (
          <RPASubmission
            organizationId={organizationId}
            companyId={companyId}
            period={period}
            type="rpa"
            data={{
              ...data.payrollInputs,
              payrolls: filterPayrollsByLegalEntity(
                data.payrollInputs.payrolls,
                selectedLegalEntity,
              ),
            }}
            config={data.inputsConfiguration}
            mode={isEditing ? 'multiple_edit' : 'create'}
            cpfListRef={cpfListRef}
            onSearchTrigger={registerSearchHandler}
            selectedLegalEntityRef={selectedLegalEntityRef}
            onLegalEntityChange={registerLegalEntityHandler}
          />
        )}
        Error={() => {
          if (inputsConfiguration.error) {
            inputsConfiguration.data.payload = createBaseRPAInputConfiguration({
              organizationId,
              companyId,
            });
          }
          return <GenericError />;
        }}
      />
    ),
    [
      inputsConfiguration.status,
      inputsConfiguration.data,
      inputsConfiguration.error,
      organizationId,
      companyId,
      period,
      payrollInputs.data,
      registerSearchHandler,
      createPayload,
    ],
  );

  return (
    <Dialog
      fullScreen
      open={true}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'background.paper',
          borderRadius: 0,
        },
      }}
    >
      <Toolbar
        sx={{
          padding: 0,
          position: 'fixed',
          backdropFilter: 'blur(4px)',
          backgroundColor: 'rgba(255, 255, 255, 0.85)',
        }}
      >
        <Button
          variant="text"
          color="primaryAlt"
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            padding: 0,
            height: '26px',
          }}
          onClick={() => navigate(`/autonomos`)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <ArrowBackRounded />
            Voltar
          </Box>
        </Button>
      </Toolbar>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          height: 'calc(100vh - 64px)',
          marginTop: '64px',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          data-testid="autonomo-page-header"
          px="40px"
          pt="16px"
        >
          <Typography variant="subtitle1" fontSize={'12px'}>
            Pagamentos para autônomos
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '12px',
              alignItems: 'baseline',
              pb: 1,
              height: '32px',
              '& img': {
                opacity: 0,
                transition: 'opacity 0.3s ease-in-out',
              },
              '&:hover img': { opacity: 1 },
            }}
            onClick={() => {
              if (appContext.company.legalEntities.length > 1) {
                setShowSelectLegalEntityDialog(true);
              }
            }}
          >
            <Typography variant="h1" fontSize={'24px'}>
              {formatPeriodDate(period)}
            </Typography>
            <Typography variant="subtitle1" fontSize={'14px'}>
              •
            </Typography>
            <Typography variant="subtitle1" fontSize={'14px'}>
              {
                appContext.company.legalEntities.find(
                  (legalEntity) => legalEntity.id === selectedLegalEntity,
                )?.name
              }
            </Typography>
            {/* We should only show the pencil icon if there are more than one legal entity */}
            {appContext.company.legalEntities.length > 1 && (
              <Box
                component="img"
                src={Pencil}
                sx={{ height: '20px', width: '20px', padding: '0px' }}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            px: '40px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          {toolbar}
          {dataFetchingContent}
        </Box>
      </Container>
      <PasteCPFListDialog
        open={showPasteCPFDialog}
        setOpen={setShowPasteCPFDialog}
        onSubmit={handleCPFListSubmit}
      />
      <RemoveAllAutonomosDialog
        open={showRemoveAllAutonomosDialog}
        setOpen={setShowRemoveAllAutonomosDialog}
        onSubmit={handleRemoveAllAutonomosSubmit}
      />
    </Dialog>
  );
}

function GenericError() {
  return (
    <Box
      border={(theme) => `1px solid ${theme.palette.strokes.light}`}
      borderRadius={2}
      width="100%"
      height="calc(100vh - 245px)"
    >
      <PageAlert
        message="Falha ao carregar tabela de lançamentos!"
        severity="error"
        showRetryMessage={true}
      />
    </Box>
  );
}

function filterPayrollsByLegalEntity(
  payrolls: { [key: string]: PayrollInputsPayroll },
  selectedLegalEntity: string,
): { [key: string]: PayrollInputsPayroll } {
  return Object.entries(payrolls)
    .filter(([_, payroll]) => payroll.legalEntityId === selectedLegalEntity)
    .reduce(
      (acc, [key, payroll]) => {
        acc[key] = payroll;
        return acc;
      },
      {} as { [key: string]: PayrollInputsPayroll },
    );
}
