import { useState } from 'react';

import { IconUsersGroup } from '@tabler/icons-react';

import { Box } from '@mui/material';

import { PageContainer } from '../../modules/components/page/PageContainer';
import { PageTitle } from '../../modules/components/page/PageTitle';

import { PeopleCount } from './PeopleCount';
import { PeopleTable } from './PeopleTable';

export type PeopleProps = {
  organizationId: string | undefined;
};

export default function People({ organizationId }: PeopleProps) {
  const getContractLink = (contractId: string) => {
    return `${contractId}`;
  };

  const [showTerminated, setShowTerminated] = useState(false);

  return (
    <PageContainer>
      <PageTitle
        dataTestId="people-header"
        title="Pessoas"
        icon={IconUsersGroup}
      />

      <PeopleCount organizationId={organizationId} />

      <Box py={3.2}>
        <PeopleTable
          organizationId={organizationId}
          getContractLink={getContractLink}
          setShowTerminated={setShowTerminated}
          showTerminated={showTerminated}
        />
      </Box>
    </PageContainer>
  );
}
