import React, { useContext, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Skeleton, Typography } from '@mui/material';

import {
  CompanyList,
  DepartmentEntry,
  DepartmentList,
  DepartmentSummary,
  SearchInput,
  fetchSearchDepartments,
  useSearchAllContracts,
} from '@octopus/api';
import {
  DataGrid,
  DataGridProps,
  DataGridToolbar,
  GridColDef,
  GridValueGetterParams,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { ContentTabs } from '../../modules/components/ContentTabs';
import { CreateDepartmentDrawer } from '../../modules/components/departments/CreateDepartmentDrawer';
import { DepartmentDetailsDrawer } from '../../modules/components/departments/DepartmentDetailsDrawer';
import { ExpandableTypography } from '../../modules/components/ExpandableTypography';
import { ArrowNavigation } from '../../modules/components/Navigator';
import { PageContainer } from '../../modules/components/page/PageContainer';
import { PageTitle } from '../../modules/components/page/PageTitle';
import { SnackbarType } from '../../modules/hooks/snackbarContext';
import { useSnackbar } from '../../modules/hooks/useSnackbar';
import { pollDepartment } from '../../modules/pollers';
import { QueryResult } from '../../modules/types';
import { AppContext } from '../context';

export type DepartmentsContentProps = {
  organizationId: string;
  companiesQuery: QueryResult<CompanyList>;
};

const departmentsStatuses = {
  active: 'active',
  deactivated: 'deactivated',
} as const;

type DepartmentStatus = keyof typeof departmentsStatuses;

export function DepartmentsContent({
  organizationId,
  companiesQuery,
}: DepartmentsContentProps) {
  const { refetchCompanyData } = useContext(AppContext);
  const [searchTerm, setSearchTerm] = useState('');

  const [showDepartmentCreation, setShowDepartmentCreation] = useState(false);
  const [isLoadingNewData, setIsLoadingNewData] = useState(false);

  const [selectedDepartment, setSelectedDepartment] = useState<
    DepartmentSummary | undefined
  >();

  const [departmentsStatus, setDepartmentsStatus] = useState<DepartmentStatus>(
    departmentsStatuses.active,
  );

  const prepareSearchInput = (datagridProps: DataGridProps, active: boolean) =>
    ({
      pagination: {
        page: datagridProps.paginationProps.page,
        size: datagridProps.paginationProps.rowsPerPage,
      },
      filtering: {
        elements: {
          active: [`${active}`],
        },
      },
      sorting: [
        {
          field: datagridProps.sortingProps.field,
          order: datagridProps.sortingProps.order,
        },
      ],
      ...(searchTerm.length > 0 && {
        query: searchTerm,
      }),
    }) as SearchInput;

  const activeDepartmentsDatagridProps = useDataGrid({
    key: `active-departments-${organizationId}`,
    sorting: {
      field: 'name',
      order: 'asc',
    },
  });
  const activeDepartmentsQuery = useQuery({
    queryKey: [
      'fetchSearchDepartments',
      organizationId,
      activeDepartmentsDatagridProps.paginationProps.page,
      activeDepartmentsDatagridProps.paginationProps.rowsPerPage,
      activeDepartmentsDatagridProps.sortingProps.field,
      activeDepartmentsDatagridProps.sortingProps.order,
      true,
      searchTerm,
    ],
    queryFn: ({ signal }) => {
      return fetchSearchDepartments(
        {
          pathParams: {
            organizationId,
          },
          body: prepareSearchInput(activeDepartmentsDatagridProps, true),
        },
        signal,
      );
    },
    enabled: !!organizationId,
  });

  const inactiveDepartmentsDatagridProps = useDataGrid({
    key: `inactive-departments-${organizationId}`,
    sorting: {
      field: 'name',
      order: 'asc',
    },
  });
  const inactiveDepartmentsQuery = useQuery({
    queryKey: [
      'fetchSearchDepartments',
      organizationId,
      inactiveDepartmentsDatagridProps.paginationProps.page,
      inactiveDepartmentsDatagridProps.paginationProps.rowsPerPage,
      inactiveDepartmentsDatagridProps.sortingProps.field,
      inactiveDepartmentsDatagridProps.sortingProps.order,
      false,
      searchTerm,
    ],
    queryFn: ({ signal }) => {
      return fetchSearchDepartments(
        {
          pathParams: {
            organizationId,
          },
          body: prepareSearchInput(inactiveDepartmentsDatagridProps, false),
        },
        signal,
      );
    },
    enabled: !!organizationId,
  });

  const datagridProps =
    departmentsStatus === departmentsStatuses.active
      ? activeDepartmentsDatagridProps
      : inactiveDepartmentsDatagridProps;
  const departmentsQuery =
    departmentsStatus === departmentsStatuses.active
      ? activeDepartmentsQuery
      : inactiveDepartmentsQuery;

  const refetch = async () => {
    await Promise.all([
      activeDepartmentsQuery.refetch(),
      inactiveDepartmentsQuery.refetch(),
      refetchCompanyData(),
    ]);
  };

  const contractsQuery = useSearchAllContracts();
  const departmentIds =
    departmentsQuery?.data?.data?.map(({ departmentId }) => departmentId) ?? [];

  const fetchContractsByDepartment = () => {
    if (organizationId && departmentIds.length > 0) {
      contractsQuery.mutate({
        pathParams: {
          organizationId,
        },
        body: {
          pagination: {
            size: 1,
          },
          filtering: {
            elements: {
              status: [{ not: 'terminated' }],
            },
          },
          counting: {
            filtered: {
              byProp: {
                departmentId: departmentIds,
              },
            },
          },
        },
      });
    }
  };

  const departmentIdsString = departmentIds.join('/');
  useEffect(() => {
    fetchContractsByDepartment();
  }, [organizationId, departmentIdsString]);

  const contractsByDepartment =
    contractsQuery?.data?.metadata?.filtered?.counters?.byProp?.departmentId;

  return (
    <PageContainer toolbar={false}>
      <PageTitle title="Departamentos" dataTestId="departments-page-title" />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mb={2}
        mt={1}
      >
        <DataGridToolbar
          searchPlaceholder="Procurar"
          hideFilters={true}
          filters={[]}
          searchProps={{ searchTerm, setSearchTerm }}
          filteringProps={datagridProps.filteringProps}
        />
        <Button
          size="large"
          color="primaryAlt"
          onClick={() => setShowDepartmentCreation(true)}
          data-testid="department-create-button"
        >
          Novo departamento
        </Button>
      </Box>
      <ContentTabs
        selected={departmentsStatus}
        setSelected={setDepartmentsStatus}
        tabs={[
          {
            label: 'Ativos',
            value: departmentsStatuses.active,
            count: activeDepartmentsQuery.data?.total,
          },
          {
            label: 'Desativados',
            value: departmentsStatuses.deactivated,
            hidden: inactiveDepartmentsQuery.data?.total === 0,
            count: inactiveDepartmentsQuery.data?.total,
          },
        ]}
      />
      <Table
        datagridProps={datagridProps}
        isLoading={departmentsQuery.isLoading || isLoadingNewData}
        DepartmentsQuery={departmentsQuery}
        contractsByDepartment={contractsByDepartment}
        onRowClick={(row) => {
          setSelectedDepartment(row);
        }}
      />
      <Creation
        organizationId={organizationId}
        companies={companiesQuery.data}
        open={showDepartmentCreation}
        onClose={() => setShowDepartmentCreation(false)}
        setLoading={setIsLoadingNewData}
        refetch={refetch}
      />
      <Details
        organizationId={organizationId}
        companies={companiesQuery.data}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        setLoading={setIsLoadingNewData}
        departments={departmentsQuery.data}
        refetch={refetch}
        contractsByDepartment={contractsByDepartment}
        refetchContractsByDepartment={fetchContractsByDepartment}
      />
    </PageContainer>
  );
}

function Table({
  datagridProps,
  isLoading,
  DepartmentsQuery,
  contractsByDepartment,
  onRowClick,
}: {
  datagridProps: DataGridProps;
  isLoading: boolean;
  DepartmentsQuery: QueryResult<DepartmentList>;
  contractsByDepartment: Record<string, number> | undefined;
  onRowClick: (row: DepartmentSummary) => void;
}) {
  if (isLoading) {
    return (
      <Box display="flex" flexDirection="column" gap="8px" pt={1}>
        <Skeleton
          variant="rounded"
          height={45 + datagridProps.paginationProps.rowsPerPage * 58}
          width="100%"
        />
      </Box>
    );
  }

  const columns: GridColDef<DepartmentSummary>[] = [
    {
      field: 'code',
      headerName: 'Código',
      sortable: true,
      valueGetter: (params: GridValueGetterParams<DepartmentSummary>) => {
        return params.row.code;
      },
      renderCell: ({ value }) => {
        return <Typography variant="body2">{value}</Typography>;
      },
    },
    {
      field: 'name',
      headerName: 'Departamento',
      sortable: true,
      valueGetter: (params: GridValueGetterParams<DepartmentSummary>) => {
        return params.row.name;
      },
      renderCell: ({ value }) => {
        return (
          <ExpandableTypography
            expandTextOnHover
            variant="body2"
            sx={{ '--ExpandableTypography-max-width': '20em' }}
          >
            {value}
          </ExpandableTypography>
        );
      },
    },
    {
      field: 'departmentCount',
      headerName: 'Contratos',
      sortable: false,
      valueGetter: (params: GridValueGetterParams<DepartmentSummary>) => {
        return params.row.departmentId;
      },
      renderCell: ({ value }) => {
        if (!contractsByDepartment) {
          return <Skeleton variant="text" width="50px" />;
        }
        return (
          <Typography variant="body2">
            {contractsByDepartment[value] ?? 0}
          </Typography>
        );
      },
    },
  ];

  return (
    <DataGrid
      getRowSx={() => ({
        height: '56px',
      })}
      sortingProps={datagridProps.sortingProps}
      paginationProps={datagridProps.paginationProps}
      totalRowCount={DepartmentsQuery.data.total || 0}
      getRowId={(row) => row.departmentId}
      rows={DepartmentsQuery.data.data}
      columns={columns}
      onRowClick={({ row }) => onRowClick(row)}
    />
  );
}

function Creation({
  organizationId,
  companies,
  open,
  onClose,
  setLoading,
  refetch,
}: {
  organizationId: string;
  companies: CompanyList | undefined;
  open: boolean;
  onClose: () => void;
  setLoading: (loading: boolean) => void;
  refetch: () => Promise<void>;
}) {
  const { showSnackbar } = useSnackbar();

  const onSuccess = async ({ departmentId }: DepartmentEntry) =>
    handleUpdatesToList({
      organizationId,
      departmentId,
      setLoading,
      closeDrawer: onClose,
      showSnackbar,
      snackbarMessage: 'Departamento criado',
      refetch,
    });

  return (
    <CreateDepartmentDrawer
      organizationId={organizationId}
      companies={companies?.data}
      open={open}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );
}

function Details({
  organizationId,
  companies,
  selectedDepartment,
  setSelectedDepartment,
  setLoading,
  departments,
  refetch,
  contractsByDepartment,
  refetchContractsByDepartment,
}: {
  organizationId: string;
  companies: CompanyList | undefined;
  selectedDepartment: DepartmentSummary | undefined;
  setSelectedDepartment: (department: DepartmentSummary | undefined) => void;
  setLoading: (loading: boolean) => void;
  departments: DepartmentList | undefined;
  refetch: () => Promise<void>;
  contractsByDepartment: Record<string, number> | undefined;
  refetchContractsByDepartment: () => void;
}) {
  const { showSnackbar } = useSnackbar();
  const [detailsNavigation, setDetailsNavigation] = useState<ArrowNavigation>({
    canGoForward: false,
    canGoBackwards: false,
    goForward: undefined,
    goBackwards: undefined,
  });

  useEffect(() => {
    if (selectedDepartment !== undefined) {
      const currentIndex = departments?.data?.findIndex(
        ({ departmentId }) => selectedDepartment.departmentId === departmentId,
      );
      if (currentIndex !== undefined) {
        setDetailsNavigation({
          canGoForward: currentIndex < departments?.data?.length - 1,
          canGoBackwards: currentIndex > 0,
          goForward: () =>
            setSelectedDepartment(departments?.data[currentIndex + 1]),
          goBackwards: () =>
            setSelectedDepartment(departments?.data[currentIndex - 1]),
        });
        return;
      }
    }
    setDetailsNavigation({
      canGoForward: false,
      canGoBackwards: false,
      goForward: undefined,
      goBackwards: undefined,
    });
  }, [selectedDepartment]);

  const handleUpdates = async (
    departmentId: string,
    message: string,
    filters: Record<string, string[]>,
  ) =>
    handleUpdatesToList({
      organizationId,
      departmentId,
      setLoading,
      closeDrawer: () => setSelectedDepartment(undefined),
      showSnackbar,
      snackbarMessage: message,
      filters,
      refetch,
    });

  const onEdit = (department: DepartmentEntry) =>
    handleUpdates(department.departmentId, 'Departamento atualizado', {
      version: [`${department.version}`],
    });

  const onRestore = (departmentId: string) =>
    handleUpdates(departmentId, 'Departamento reativado', {
      departmentId: [departmentId],
      active: ['true'],
    });

  const onArchive = (departmentId: string) =>
    handleUpdates(departmentId, 'Departamento desativado', {
      departmentId: [departmentId],
      active: ['false'],
    });

  return (
    <DepartmentDetailsDrawer
      organizationId={organizationId}
      companies={companies?.data}
      departmentSummary={selectedDepartment}
      contractsCount={contractsByDepartment?.[selectedDepartment?.departmentId]}
      navigation={detailsNavigation}
      open={selectedDepartment !== undefined}
      onEdit={onEdit}
      onRestore={onRestore}
      onArchive={onArchive}
      onContractsAdded={() => {
        refetchContractsByDepartment();
      }}
      onContractsMoved={() => {
        refetchContractsByDepartment();
        showSnackbar({
          isOpen: true,
          variant: 'default',
          Message: 'Movimentação realizada',
          StartAdornment: <CheckCircleIcon />,
        });
      }}
      onClose={() => setSelectedDepartment(undefined)}
    />
  );
}

async function handleUpdatesToList({
  organizationId,
  departmentId,
  setLoading,
  closeDrawer,
  showSnackbar,
  snackbarMessage,
  filters,
  refetch,
}: {
  organizationId: string;
  departmentId: string;
  setLoading: (loading: boolean) => void;
  closeDrawer: () => void;
  showSnackbar: (snackbar: SnackbarType) => void;
  snackbarMessage: string;
  filters?: Record<string, string[]>;
  refetch: () => Promise<unknown>;
}) {
  setLoading(true);
  closeDrawer();
  showSnackbar({
    isOpen: true,
    variant: 'default',
    Message: snackbarMessage,
    StartAdornment: <CheckCircleIcon />,
    hasCloseAction: true,
  });
  await pollDepartment({ organizationId, departmentId, extraFilters: filters });
  setTimeout(() => {
    refetch().finally(() => setLoading(false));
  }, 200);
}
