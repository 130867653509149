import {
  ActionMenu,
  ActionMenuItem,
} from '../../../modules/components/ActionMenu';

const getDeleteAction = (
  draftId: string,
  {
    setShowDeleteAdmissionDialog,
    setSelectedAdmissionDraft,
  }: {
    setShowDeleteAdmissionDialog: (open: boolean) => void;
    setSelectedAdmissionDraft: ({ draftId }: { draftId: string }) => void;
  },
) => ({
  label: 'Excluir',
  onClick: () => {
    setShowDeleteAdmissionDialog(true);
    setSelectedAdmissionDraft({ draftId });
  },
});

export function admissionDraftActionMenu(
  draftId: string,
  {
    setShowDeleteAdmissionDialog,
    setSelectedAdmissionDraft,
  }: {
    setShowDeleteAdmissionDialog: (open: boolean) => void;
    setSelectedAdmissionDraft: ({ draftId }: { draftId: string }) => void;
  },
) {
  return createActionMenu([
    getDeleteAction(draftId, {
      setShowDeleteAdmissionDialog,
      setSelectedAdmissionDraft,
    }),
  ]);
}

export function admissionDoneActionMenu(
  draftId: string,
  contractId: string,
  {
    setShowGenerateKitDialog,
    setSelectedAdmissionDraft,
  }: {
    setShowGenerateKitDialog?: (open: boolean) => void;
    setShowDeleteAdmissionDialog?: (open: boolean) => void;
    setShowAdminTakeOverDialog?: (open: boolean) => void;
    setShowResendInviteDialog?: (open: boolean) => void;
    setSelectedAdmissionDraft: ({
      draftId,
      name,
      email,
      contractId,
    }: {
      draftId: string;
      name?: string;
      email?: string;
      contractId?: string;
    }) => void;
  },
) {
  if (!setShowGenerateKitDialog) {
    return null;
  }

  return createActionMenu([
    {
      label: 'Gerar documentos admissionais',
      onClick: () => {
        setShowGenerateKitDialog(true);
        setSelectedAdmissionDraft({ draftId, contractId });
      },
    },
  ]);
}

export function inProgressCandidateActionMenu(
  draftId: string,
  name: string,
  email: string,
  doneSteps: number,
  {
    setShowDeleteAdmissionDialog,
    setShowAdminTakeOverDialog,
    setShowResendInviteDialog,
    setSelectedAdmissionDraft,
  }: {
    setShowDeleteAdmissionDialog: (open: boolean) => void;
    setShowAdminTakeOverDialog: (open: boolean) => void;
    setShowResendInviteDialog: (open: boolean) => void;
    setSelectedAdmissionDraft: ({
      draftId,
      name,
      email,
    }: {
      draftId: string;
      name?: string;
      email?: string;
    }) => void;
  },
) {
  return createActionMenu([
    {
      label: 'Preencher para o candidato',
      onClick: () => {
        setShowAdminTakeOverDialog(true);
        setSelectedAdmissionDraft({ draftId });
      },
    },
    {
      label: 'Reenviar Convite',
      isDisabled: doneSteps > 0,
      onClick: () => {
        setSelectedAdmissionDraft({ draftId, name, email });
        setShowResendInviteDialog(true);
      },
    },
    getDeleteAction(draftId, {
      setShowDeleteAdmissionDialog,
      setSelectedAdmissionDraft,
    }),
  ]);
}

export function inProgressAdminActionMenu(
  draftId: string,
  {
    setShowDeleteAdmissionDialog,
    setSelectedAdmissionDraft,
  }: {
    setShowDeleteAdmissionDialog: (open: boolean) => void;
    setSelectedAdmissionDraft: ({ draftId }: { draftId: string }) => void;
  },
) {
  return createActionMenu([
    {
      label: 'Continuar Preenchimento',
      href: `/admissions/new/${draftId}`,
    },
    getDeleteAction(draftId, {
      setShowDeleteAdmissionDialog,
      setSelectedAdmissionDraft,
    }),
  ]);
}

export function inReviewActionMenu(
  {
    draftId,
  }: {
    draftId: string;
  },
  {
    setShowDeleteAdmissionDialog,
    setSelectedAdmissionDraft,
  }: {
    setShowDeleteAdmissionDialog: (open: boolean) => void;
    setSelectedAdmissionDraft: ({ draftId }: { draftId: string }) => void;
  },
) {
  return createActionMenu([
    getDeleteAction(draftId, {
      setShowDeleteAdmissionDialog,
      setSelectedAdmissionDraft,
    }),
  ]);
}

function createActionMenu(menuItems: ActionMenuItem[]) {
  return (
    <ActionMenu
      sx={{
        '--ActionMenu-button-margin': '0px 0px 0px 0px',
      }}
      menuItems={menuItems}
    />
  );
}
