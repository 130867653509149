import { useEffect, useRef, useState } from 'react';

import { IconAlertTriangle } from '@tabler/icons-react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

import {
  OrganizationEntry,
  UserSummary,
  useSendUserOptInInvite,
} from '@octopus/api';

import { useSnackbar } from '../../../modules/hooks/useSnackbar';

import { DialogContentLoading } from './dialog/DialogContentLoading';
import { usePopoverMenu } from './PopoverMenu';
import { ResendUserCreatedInviteDialog } from './ResendUserCreatedInvite';
import { UserManagementUtils } from './utils';

type UserEmailChangeProps = {
  organization: OrganizationEntry;
  user: UserSummary;
  afterUpdate: () => void;
};
export function UserEmailChange({
  organization,
  user,
  afterUpdate,
}: UserEmailChangeProps) {
  const [isChangeEmailDialogOpen, setIsChangeEmailDialogOpen] =
    useState<boolean>(false);

  const [isResendInviteDialogOpen, setIsResendInviteDialogOpen] =
    useState<boolean>(false);

  const ssoDomains = UserManagementUtils.getSSODomains(organization);

  const canChangeEmail = ssoDomains.length > 0 && user.ssoOnly !== true;
  const canResendInvite = !user.ssoOnly && !user.latestLoginDate;
  const hasActionMenu = canChangeEmail || canResendInvite;

  const popoverMenuAnchor = useRef(null);
  const popoverMenu = usePopoverMenu<'changeEmail' | 'resendInvite'>({
    anchorOrigin: { vertical: 'bottom', horizontal: -230 },
    anchorEl: popoverMenuAnchor.current,
    options: [
      ...(canChangeEmail
        ? [
            {
              label: 'Solicitar troca para email corporativo',
              value: 'changeEmail' as const,
            },
          ]
        : []),
      ...(canResendInvite
        ? [
            {
              label: 'Reenviar senha temporária',
              value: 'resendInvite' as const,
            },
          ]
        : []),
    ],
    onSelect: ({ value }) => {
      if (value === 'changeEmail') {
        setIsChangeEmailDialogOpen(true);
        return;
      }
      if (value === 'resendInvite') {
        setIsResendInviteDialogOpen(true);
      }
      popoverMenu.close();
    },
  });

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body2" color="textSecondary" sx={{ py: 0.5 }}>
          {user.email}
        </Typography>

        {hasActionMenu && (
          <IconButton
            size="small"
            sx={{ borderRadius: 1 }}
            onClick={popoverMenu.toggleOpen}
            ref={popoverMenuAnchor}
          >
            <MoreVertIcon />
          </IconButton>
        )}
      </Box>
      {popoverMenu.MenuComponent}
      {isChangeEmailDialogOpen && (
        <ChangeUserEmailDialog
          organizationId={organization.organizationId}
          ssoDomains={ssoDomains}
          user={user}
          isOpen={isChangeEmailDialogOpen}
          onClose={() => setIsChangeEmailDialogOpen(false)}
          afterUpdate={afterUpdate}
        />
      )}
      {isResendInviteDialogOpen && (
        <ResendUserCreatedInviteDialog
          organizationId={organization.organizationId}
          userId={user.userId}
          isOpen={isResendInviteDialogOpen}
          onClose={() => setIsResendInviteDialogOpen(false)}
        />
      )}
    </>
  );
}

export type ChangeUserEmailDialogProps = {
  organizationId: string;
  ssoDomains: string[];
  user: UserSummary;
  isOpen: boolean;
  onClose: () => void;
  afterUpdate: () => void;
};
export function ChangeUserEmailDialog({
  organizationId,
  ssoDomains,
  user,
  isOpen,
  onClose,
  afterUpdate,
}: ChangeUserEmailDialogProps) {
  const [scene, setScene] = useState<1 | 2>(1);
  const setSceneLoading = () => setScene(2);
  const { showSnackbar } = useSnackbar();

  const { mutate, isLoading, isError, error } = useSendUserOptInInvite({
    mutationKey: [organizationId, user.userId],
  });

  const sendEmailInviteSucceeded = () => {
    onClose();
    setTimeout(() => {
      showSnackbar({
        autoHideDuration: 3000,
        isOpen: true,
        variant: 'default',
        Message: 'Pedido de troca de email enviado com sucesso.',
        StartAdornment: <CheckCircleIcon />,
        hasCloseAction: true,
      });
      afterUpdate();
    }, 400);
  };
  const sendEmailInviteFailed = () => {
    onClose();
    showSnackbar({
      autoHideDuration: 3000,
      isOpen: true,
      variant: 'error',
      Message: 'Ocorreu um erro ao pedir a troca de email. Tente novamente.',
      StartAdornment: <CancelIcon />,
      hasCloseAction: true,
    });
  };

  useEffect(() => {
    // is at "octopus dancing" modal but loading is false
    if (scene === 2 && !isLoading) {
      if (!isError) {
        sendEmailInviteSucceeded();
      } else {
        console.error(error);
        sendEmailInviteFailed();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isError, error, scene]);

  const loadingScene = <DialogContentLoading message="Solicitando troca..." />;

  const submitChangeEmail = (email: string) =>
    mutate({
      pathParams: {
        organizationId,
        userId: user.userId,
      },
      body: {
        type: 'emailChange',
        newEmail: email,
      },
    });

  const changeEmailScene = (
    <ChangeEmailScene
      onClose={onClose}
      onSubmit={(email) => {
        submitChangeEmail(email);
        setSceneLoading();
      }}
      ssoDomains={ssoDomains}
      user={user}
    />
  );

  return (
    <Dialog
      PaperProps={{ sx: { minWidth: '600px' } }}
      open={isOpen}
      onClose={onClose}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {scene === 1 && changeEmailScene}
      {scene === 2 && loadingScene}
    </Dialog>
  );
}

function ChangeEmailScene({
  user,
  ssoDomains,
  onSubmit,
  onClose,
}: {
  ssoDomains: string[];
  user: UserSummary;
  onSubmit: (email: string) => void;
  onClose: () => void;
}) {
  const ssoDomain = ssoDomains[0];
  const ssoDomainError = !ssoDomain
    ? 'A organização não tem domínio de sso cadastrado'
    : undefined;

  const [email, setEmail] = useState<string>();
  const [error, setError] = useState<string | undefined>(ssoDomainError);

  const validateEmail = (email: string) => {
    if (!email) {
      setError('');
      return false;
    }
    const valid = ssoDomains.some((domain) => email.endsWith(domain));
    if (!valid) {
      setError(`O novo email deve ter domínio ${ssoDomains.join(', ou ')}`);
      return false;
    } else {
      setError(undefined);
      return true;
    }
  };

  return (
    <>
      <DialogTitle sx={{ pt: 5, px: 4, pb: 2 }}>
        <Typography fontSize="24px" color="textPrimary" fontWeight={700}>
          Solicitar troca para email corporativo
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pb: 3 }}>
          <Typography variant="body2" color="textPrimary">
            Informe o email corporativo de <strong>{user.name}</strong>. Vamos
            enviar um email para <strong>{user.email}</strong> pedindo a
            confirmação da mudança para o email corporativo.
          </Typography>
        </Box>
        <Box sx={{ pb: 3 }}>
          <Alert
            severity="warning"
            icon={<IconAlertTriangle className="medium warning" />}
          >
            O novo email de acesso passa a ser válido após a confirmação do
            usuário.
          </Alert>
        </Box>
        <Box>
          <TextField
            placeholder={`ex.: nome@${ssoDomain}`}
            variant="outlined"
            fullWidth={true}
            error={!!error}
            helperText={error}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          size="large"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClose();
          }}
        >
          Cancelar
        </Button>
        <Button
          color="primaryAlt"
          size="large"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (validateEmail(email)) {
              onSubmit(email);
            }
          }}
        >
          Enviar
        </Button>
      </DialogActions>
    </>
  );
}
