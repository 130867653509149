const JobTitleErrorCodes = {
  JBT00001:
    '"occupationCode" must be set if job title can be used by "br:clt" contracts',
  JBT00002: '"cboDoCargoDeConfianca" must be set if "cargoDeConfianca" is true',
  JBT00003:
    '"cboDoCargoDeConfianca" and "descricaoDoCargoDeConfianca" must not be set if "cargoDeConfianca" is false',
  JBT00004: 'Invalid "CBO" code',
  JBT00005: '"name" is already being used by another job title',
  JBT00006: 'Cannot remove occupation code from job title',
} as const;

export default JobTitleErrorCodes;
