import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Dialog, Paper, Typography } from '@mui/material';

import { PageContainer } from '../../../modules/components/page/PageContainer';

import { CreateUsersDialog } from './CreateUsersDialog';
import { PendingAccessContractsTable } from './PendingAccessContractsTable';

type PathProps = {
  organizationId: string;
};
type QueryProps = {
  filtered?: 'true' | 'false';
};

export function PendingAccessContracts() {
  const { organizationId } = useParams<PathProps>();
  const { filtered: filteredParam } = useQueryParams<QueryProps>();
  const filtered = filteredParam === 'true';
  const navigate = useNavigate();
  const cancel = () => navigate('/users-management');

  const [selectedContracts, setSelectedContracts] = useState<Set<string>>(
    new Set(),
  );

  // in order for react to figure out that the state changed,
  // another set instance must be returned
  const addContract = (contractId: string) =>
    setSelectedContracts((contracts) => {
      return new Set([...contracts, contractId]);
    });
  const removeContract = (contractId: string) => {
    setSelectedContracts((contracts) => {
      const newContracts = new Set(contracts);
      newContracts.delete(contractId);
      return newContracts;
    });
  };
  const addMultipleContracts = (contractIds: Iterable<string>) =>
    setSelectedContracts((contracts) => {
      return new Set([...contracts, ...contractIds]);
    });
  const removeMultipleContracts = (contractIds: Iterable<string>) =>
    setSelectedContracts((contracts) => {
      const newContracts = new Set(contracts);
      for (const contractId of contractIds) {
        newContracts.delete(contractId);
      }
      return newContracts;
    });

  return (
    <Dialog
      fullScreen={true}
      open={true}
      PaperProps={{ sx: { borderRadius: 0 } }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        sx={{ width: '100%', height: '48px', pl: 2 }}
      >
        <Button
          variant="text"
          color="inherit"
          size="large"
          onClick={cancel}
          sx={{ py: 1 }}
        >
          Cancelar
        </Button>
      </Box>
      <PageContainer toolbar={false} width="100%">
        <PendingAccessContractsTable
          organizationId={organizationId}
          filtered={filtered}
          contractSelection={{
            selectedContracts,
            addContract: addContract,
            addMultipleContracts: addMultipleContracts,
            removeContract: removeContract,
            removeMultipleContracts: removeMultipleContracts,
          }}
        />
        <PageFooter
          organizationId={organizationId}
          filtered={filtered}
          selectedContracts={selectedContracts}
        />
      </PageContainer>
    </Dialog>
  );
}

type PageFooterProps = {
  organizationId: string;
  filtered: boolean;
  selectedContracts: Set<string>;
};
function PageFooter({
  organizationId,
  filtered,
  selectedContracts,
}: PageFooterProps) {
  const canCreateUsers = selectedContracts.size > 0;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [clickedOnce, setClickedOnce] = useState<boolean>(false);
  const closeDialog = () => setDialogOpen(false);
  const openDialog = () => {
    setClickedOnce(true);
    if (canCreateUsers) {
      setDialogOpen(true);
    }
  };

  return (
    <>
      {/* bottom bar */}
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: '100%',
          background: 'rgba(247, 247, 247, 0.90)',
          backdropFilter: 'blur(2px)',
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ px: 3, py: 1 }}
          gap={3}
        >
          {clickedOnce && !canCreateUsers && (
            <Typography color="error" variant="caption">
              Nenhum contrato foi selecionado
            </Typography>
          )}
          {filtered ? (
            <Button color="primaryAlt" size="large" onClick={openDialog}>
              Liberar selecionados ({selectedContracts.size})
            </Button>
          ) : (
            <Button color="primaryAlt" size="large" onClick={openDialog}>
              Liberar acessos
            </Button>
          )}
        </Box>
      </Paper>

      {/* dialog */}
      <CreateUsersDialog
        organizationId={organizationId}
        onClose={closeDialog}
        isOpen={dialogOpen}
        filters={filtered ? { contractIds: selectedContracts } : {}}
      />
    </>
  );
}

const useQueryParams = <T extends Record<string, string>>(): T => {
  const { search } = useLocation();
  return React.useMemo(() => {
    const acc: Record<string, string> = {};
    const params = new URLSearchParams(search).entries();
    for (const [name, value] of params) {
      acc[name] = value;
    }
    return acc as T;
  }, [search]);
};
