import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import MoveToInboxOutlinedIcon from '@mui/icons-material/MoveToInboxOutlined';
import { Box, Divider, Skeleton, Typography } from '@mui/material';

import {
  CompanyEntry,
  ContractBRCltEntry,
  ContractEntry,
  OrganizationEntry,
  PayrollPayslipEntry,
  fetchViewPayrollPayslip,
  useGetCompanyEntry,
  useGetContract,
  useGetOrganizationEntry,
  useGetSentPayslip,
} from '@octopus/api';
import { getWorkerId } from '@octopus/contract-types';
import { formatDateTimeBR } from '@octopus/formatters';

import { BackButton } from '../../../../../modules/components/BackButton';
import { PageAlert } from '../../../../../modules/components/PageAlert';
import Payslip from '../../../../../modules/components/payrolls/Payslip';
import { PayslipActionsMenu } from '../../../../../modules/components/payslips/ActionsMenu';
import { BankInfo } from '../../../../../modules/components/payslips/BankInfo';
import { CalculationBreakdown } from '../../../../../modules/components/payslips/CalculationBreakdown';
import { CalculationSummary } from '../../../../../modules/components/payslips/CalculationSummary';
import { EmployeeInfo } from '../../../../../modules/components/payslips/EmployeeInfo';
import { EmployerInfo } from '../../../../../modules/components/payslips/EmployerInfo';
import { DataFetching } from '../../../../../modules/dataFetching';

export type PayslipReceiptProps = {
  organizationId: string;
};

type PayslipReceiptData = {
  organization: OrganizationEntry;
  company: CompanyEntry;
  payslip: PayrollPayslipEntry;
  contract: ContractEntry;
};

export function PayslipReceipt({ organizationId }: PayslipReceiptProps) {
  const viewPayslip = useRef<boolean>(false);
  const { companyId, contractId, payrollId } = useParams();

  const organizationQuery = useGetOrganizationEntry(
    {
      pathParams: { organizationId },
    },
    {
      enabled: !!organizationId,
    },
  );
  const companyQuery = useGetCompanyEntry(
    {
      pathParams: { organizationId, companyId },
    },
    {
      enabled: !!organizationId && !!companyId,
    },
  );
  const payslipQuery = useGetSentPayslip(
    {
      pathParams: {
        organizationId,
        contractId,
        payrollId,
      },
    },
    {
      enabled: !!organizationId && !!contractId && !!payrollId,
    },
  );
  const contractQuery = useGetContract(
    {
      pathParams: { organizationId, contractId },
      queryParams: { effectiveDate: payslipQuery?.data?.date },
    },
    {
      enabled: !!organizationId && !!contractId && !!payslipQuery.data?.date,
    },
  );
  const fetchResult = {
    results: {
      organization: organizationQuery,
      company: companyQuery,
      payslip: payslipQuery,
      contract: contractQuery,
    },
  };

  useEffect(() => {
    if (viewPayslip.current) {
      return;
    }
    fetchViewPayrollPayslip({
      pathParams: {
        organizationId,
        companyId,
        contractId,
        payrollId,
      },
    });
    viewPayslip.current = true;
  }, []);

  return (
    <DataFetching<PayslipReceiptData>
      fetchResult={fetchResult}
      Data={({ data }) => {
        const { organization, company, payslip, contract } = data;
        if (contract.contractType !== 'br:clt') {
          return (
            <PageAlert
              message={'Tipo de contrato inválido'}
              severity={'error'}
              showRetryMessage={false}
            />
          );
        }
        return (
          <Page
            organization={organization}
            company={company}
            payslip={payslip}
            contract={contract.br}
            workerId={getWorkerId(contract)}
          />
        );
      }}
      Loading={() => {
        return <PageSkeleton />;
      }}
    />
  );
}

function RPAReceipt({ payslip }: { payslip: PayrollPayslipEntry }) {
  return (
    <Box
      display="flex"
      maxWidth="664px"
      width="100%"
      flexDirection="column"
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          gap: 2,
        },
      })}
    >
      <Payslip
        payslip={payslip}
        actionMenu={() => <PayslipActionsMenu payslip={payslip} />}
        receipt={true}
      />
      <SentOn payslip={payslip} />
    </Box>
  );
}

function Page({
  payslip,
  company,
  organization,
  contract,
  workerId,
}: {
  payslip: PayrollPayslipEntry;
  contract: ContractBRCltEntry;
  organization: OrganizationEntry;
  company: CompanyEntry;
  workerId: string;
}) {
  return (
    <>
      <BackButton destination="/receipts" />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor="background.paper"
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            mx: 8,
          },
          [theme.breakpoints.up('md')]: {
            my: 7,
          },
          [theme.breakpoints.down('md')]: {
            paddingBottom: 12,
          },
        })}
        data-testid="payslip-receipt-page"
      >
        {payslip.type === 'rpa' ? (
          <RPAReceipt payslip={payslip} />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            maxWidth="664px"
            width="100%"
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                gap: 2,
              },
            })}
          >
            <Box
              display="flex"
              sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                },
              })}
            >
              <EmployeeInfo
                payment={payslip}
                contract={contract}
                workerId={workerId}
              />
              <Box
                sx={(theme) => ({
                  [theme.breakpoints.up('md')]: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    justifyContent: 'space-between',
                  },
                })}
              >
                <PayslipActionsMenu payslip={payslip} />
                <BankInfo contract={contract} />
              </Box>
            </Box>
            <Box
              py={3}
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              <Divider />
            </Box>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  mt: 3,
                },
              })}
            >
              <CalculationBreakdown calculationOutputs={payslip.outputs} />
            </Box>
            <CalculationSummary
              calculationOutputs={payslip.outputs}
              payslipType={payslip.type}
            />
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  pt: 1.5,
                  pb: 2.5,
                  px: 2.5,
                },
                [theme.breakpoints.up('md')]: {
                  py: 2,
                },
              })}
            >
              <Divider />
            </Box>
            <EmployerInfo organization={organization} company={company} />
            <SentOn payslip={payslip} />
          </Box>
        )}
      </Box>
    </>
  );
}

function PageSkeleton() {
  return (
    <>
      <BackButton />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor="background.paper"
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            mx: 8,
          },
          [theme.breakpoints.up('md')]: {
            my: 7,
          },
          [theme.breakpoints.down('md')]: {
            paddingBottom: 12,
            mx: 2.5,
          },
        })}
      >
        <Box
          display="flex"
          flexDirection="column"
          maxWidth="664px"
          width="100%"
        >
          <Box
            display="flex"
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                justifyContent: 'space-between',
              },
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            })}
          >
            <Skeleton
              variant="rounded"
              sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                  height: '115px',
                  width: '100%',
                },
                [theme.breakpoints.down('md')]: {
                  height: '136px',
                  mt: 2,
                  mb: 3,
                },
              })}
            />
            <Skeleton
              variant="rounded"
              sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
                [theme.breakpoints.down('md')]: {
                  height: '36px',
                  px: 2,
                  py: 1.5,
                  my: 1,
                },
              })}
            />
          </Box>
          <Box
            py={3}
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            })}
          >
            <Divider />
          </Box>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                mt: 3,
              },
            })}
          >
            <Skeleton variant="rounded" height="600px" />
          </Box>
          <Box pt={3}>
            <Skeleton variant="rounded" height="60px" />
          </Box>
          <Skeleton
            variant="rounded"
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
              [theme.breakpoints.down('md')]: {
                height: '160px',
                mt: 3,
                mb: 1,
              },
            })}
          />
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                py: 1.5,
                px: 2.5,
              },
              [theme.breakpoints.up('md')]: {
                py: 2,
              },
            })}
          >
            <Divider />
          </Box>
          <Skeleton
            variant="rounded"
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
              [theme.breakpoints.down('md')]: {
                height: '104px',
                my: 1,
              },
            })}
          />
          <Skeleton
            variant="rounded"
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                height: '64px',
              },
              [theme.breakpoints.down('md')]: {
                height: '24px',
                py: 1,
                my: 1,
              },
            })}
          />
        </Box>
      </Box>
    </>
  );
}

function SentOn({ payslip }: { payslip: PayrollPayslipEntry }) {
  if (!payslip?.metadata?.sentAt) {
    return null;
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={0.5}
      mx={2.5}
      my={1}
      px={1.5}
      py={1.5}
      borderRadius={1}
      bgcolor="background.default"
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      })}
    >
      <MoveToInboxOutlinedIcon
        sx={(theme) => ({
          height: '12px',
          width: '12px',
          color: theme.palette.strokes.heavy,
        })}
      />
      <Typography variant="caption" color="text.secondary" fontWeight="500">
        Documento recebido dia {formatDateTimeBR(payslip.metadata.sentAt)}.
      </Typography>
    </Box>
  );
}
