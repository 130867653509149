import { useEffect, useRef, useState } from 'react';

import { IconUserMinus } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';

import { MoreVert } from '@mui/icons-material';
import {
  Box,
  IconButton,
  MenuItem,
  Popover,
  Skeleton,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import {
  PayrollSummary,
  fetchDownloadTemplateAttachments,
  fetchSearchAllPayrolls,
  useArchivePayroll,
} from '@octopus/api';
import { formatDateBR, formatDateTimeBR } from '@octopus/formatters';
import {
  DataGrid,
  DataGridToolbar,
  FilterOptions,
  FilteringProps,
  GridColDef,
  PaginationProps,
  SearchProps,
  SortingProps,
  makeDateRangeFilter,
  makeElementListFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { SwitchCompany } from '../../modules/components/companies/SwitchCompany';
import { PageContainer } from '../../modules/components/page/PageContainer';
import { PageTabs } from '../../modules/components/page/PageTabs';
import { PageTitle } from '../../modules/components/page/PageTitle';
import TabLabel from '../../modules/components/TabLabel';
import { companyColumn } from '../../modules/components/table/columns/CompanyColumns';
import UserAvatar from '../../modules/components/UserAvatar';
import { DataFetching } from '../../modules/dataFetching';
import {
  formatTerminationNoticeType,
  formatTerminationStartedBy,
  formatTerminationType,
} from '../../modules/formatters';
import { useSnackbar } from '../../modules/hooks/useSnackbar';
import {
  downloadFile,
  getActiveElementFilters,
  getActiveRangeFilters,
} from '../../utils';
import { useFFlags } from '../fflags';

import { ExplodedDate } from './local-components/ExplodedDate';
import { TerminationTypeBadge } from './local-components/TerminationTypeBadge';

export type TerminationsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

const getTerminationColumns = ({
  currentTabName,
  onPayrollArchived,
}: {
  currentTabName: keyof typeof tabPropsByName;
  onPayrollArchived: (payrollId: string) => void;
}): GridColDef<PayrollSummary>[] => {
  const columns: GridColDef<PayrollSummary>[] = [
    {
      field: 'workerData.name',
      headerName: 'Colaborador(a)',
      valueGetter: (params) => params.row.workerData.name,
      renderCell: (params) => {
        return (
          <UserAvatar
            name={params.formattedValue}
            avatarProps={{
              ml: 0,
              mr: 2.25,
            }}
            sx={{
              '--UserAvatar-name-max-width': '12.5em',
            }}
          />
        );
      },
    },
    companyColumn,
    {
      field: 'termination.startedBy',
      headerName: 'Iniciado por',
      sortable: false,
      valueGetter: (params) =>
        formatTerminationStartedBy(params.row.termination?.startedBy),
    },
    {
      field: 'termination.noticeDate',
      headerName: 'Data de aviso',
      valueGetter: (params) => formatDateBR(params.row.termination?.noticeDate),
      renderCell: (params) => {
        return <ExplodedDate ddmmyyyy={params.formattedValue} />;
      },
    },
    {
      field: 'termination.noticeIndemnityType',
      headerName: 'Rescisão',
      sortable: false,
      valueGetter: (params) =>
        formatTerminationNoticeType(
          params.row.termination?.noticeIndemnityType,
        ),
    },
    {
      field: 'type',
      headerName: 'Tipo de folha',
      sortable: false,
      valueGetter: (params) => formatTerminationType(params.row.type),
      renderCell: ({ value }) => {
        return <TerminationTypeBadge type={value} />;
      },
    },
    {
      field: 'termination.terminationDate',
      headerName: 'Data de desligamento',
      valueGetter: (params) =>
        formatDateBR(params.row.termination?.terminationDate),
      renderCell: (params) => {
        return <ExplodedDate ddmmyyyy={params.formattedValue} />;
      },
    },
    {
      field: 'paymentDate',
      headerName: 'Aprovar até',
      sortable: false,
      valueGetter: (params) => {
        if (params?.row?.paymentDate) {
          return formatDateTimeBR(params.row.paymentDate);
        }

        return '';
      },
    },
    currentTabName !== 'archived' && {
      field: 'actions',
      headerName: '',
      sortable: false,
      renderCell: (params) => (
        <PayrollRowActionMenu
          payrollId={params.row.payrollId}
          organizationId={params.row.organizationId}
          companyId={params.row.companyId}
          workerName={params.row.workerData.name}
          onPayrollArchived={onPayrollArchived}
          currentTabName={currentTabName}
        />
      ),
      width: 48,
    },
  ];

  return columns.filter(Boolean) as GridColDef<PayrollSummary>[];
};

function getStatusFilter(tabName: keyof typeof tabPropsByName) {
  switch (tabName) {
    case 'open':
      return ['open'];
    case 'approved':
      return ['approved'];
    case 'closed':
      return ['closed', 'reconciled'];
    case 'archived':
      return ['archived'];
  }
}

function getFetchHook(
  refetchCount: number,
  organizationId: string | undefined,
  companyId: string | undefined,
  tabName: keyof typeof tabPropsByName,
  searchProps: SearchProps,
  filteringProps: FilteringProps,
  paginationProps: PaginationProps,
  sortingProps: SortingProps,
) {
  return () => {
    const elementFilters = getActiveElementFilters(filteringProps);
    const rangeFilters = getActiveRangeFilters(filteringProps);

    return useQuery({
      queryKey: [
        refetchCount,
        organizationId,
        companyId,
        paginationProps,
        sortingProps,
        tabName,
        searchProps,
        rangeFilters,
        elementFilters,
        tabName,
      ],
      queryFn: () =>
        fetchSearchAllPayrolls({
          pathParams: {
            organizationId: organizationId,
            companyId: companyId,
          },
          body: {
            query: searchProps.searchTerm,
            pagination: {
              size: paginationProps.rowsPerPage,
              page: paginationProps.page,
            },
            ...(sortingProps.field
              ? {
                  sorting: [
                    {
                      field: sortingProps.field,
                      order: sortingProps.order,
                    },
                  ],
                }
              : {}),
            filtering: {
              elements: {
                type: ['termination', 'complementaryTermination'],
                ...elementFilters,
                status: getStatusFilter(tabName),
              },
              ...(rangeFilters && { ranges: rangeFilters }),
            },
            counting: {
              filtered: {
                byProp: {
                  status: ['open', 'approved', 'closed', 'archived'],
                },
              },
            },
          },
        }),
      enabled: !!organizationId && !!companyId,
    });
  };
}

const tabPropsByName = {
  open: {
    color: 'warning',
    label: 'Prévias',
  },
  approved: {
    color: 'primary',
    label: 'Aprovadas',
  },
  closed: {
    color: 'success',
    label: 'Fechadas',
  },
  archived: {
    color: 'error',
    label: 'Excluídas',
  },
};

function TerminationsListPage({
  organizationId,
  companyId,
}: TerminationsProps) {
  const [currentTabName, setCurrentTabName] =
    useState<keyof typeof tabPropsByName>('open');

  const filters = useFilters();
  const { filteringProps, searchProps, sortingProps, paginationProps } =
    useDataGrid({
      filters,
      key: `terminations-${organizationId}`,
    });

  const [isArchivedItemsVisible, setIsArchivedItemsVisible] = useState(false);
  const [rerenderCount, setRerenderCount] = useState(0);
  const archivedPayrollsRef = useRef(new Set());

  const fetcher = getFetchHook(
    rerenderCount,
    organizationId,
    companyId,
    currentTabName,
    searchProps,
    filteringProps,
    paginationProps,
    sortingProps,
  );

  const onPayrollArchived = (payrollId: string) => {
    setRerenderCount((count) => count + 1);
    archivedPayrollsRef.current.add(payrollId);
  };

  const onChangeArchivedItemsToggle = () => {
    setIsArchivedItemsVisible((isVisible) => !isVisible);
    if (!isArchivedItemsVisible) {
      setCurrentTabName('archived');
    } else {
      setCurrentTabName('open');
    }
  };

  return (
    <PageContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        alignItems="center"
        gap={2}
      >
        <PageTitle
          dataTestId="termination-header"
          icon={IconUserMinus}
          title="Rescisão"
        />

        <SwitchCompany />
      </Box>

      <DataGridToolbar
        filters={filters}
        searchProps={searchProps}
        filteringProps={filteringProps}
        searchPlaceholder="Procurar..."
      />

      <PageTabs>
        <Tabs
          value={currentTabName}
          onChange={(_, newTab) => setCurrentTabName(newTab)}
          textColor="inherit"
          TabIndicatorProps={{
            sx: {
              backgroundColor: `${tabPropsByName[currentTabName].color}.main`,
            },
          }}
        >
          {Object.entries(tabPropsByName)
            .filter(
              ([tabName]) => tabName !== 'archived' || isArchivedItemsVisible,
            )
            .map(([tabName, tabProps]) => (
              <Tab
                key={tabName}
                value={tabName}
                label={
                  <TabLabel
                    isSelected={tabName === currentTabName}
                    {...tabProps}
                  />
                }
              />
            ))}
        </Tabs>
        <PageActionMenu
          isArchivedItemsVisible={isArchivedItemsVisible}
          onChangeArchivedItemsToggle={onChangeArchivedItemsToggle}
        />
      </PageTabs>

      <DataFetching
        useHook={fetcher}
        Loading={() => <Skeleton variant="rounded" height={400} width="100%" />}
        Data={({ data: list }) => {
          if (!list) {
            return null;
          }
          const rows = Object.values(list.data || []);
          const visibleRows = rows.filter(
            (row) => !archivedPayrollsRef.current.has(row.payrollId),
          );

          const totalRowCount = list.total - (rows.length - visibleRows.length);

          return (
            <DataGrid
              sortingProps={sortingProps}
              paginationProps={paginationProps}
              totalRowCount={totalRowCount}
              rows={visibleRows}
              getRowId={(row) => row.payrollId}
              getRowSx={() => getRowSxForStatus(currentTabName)}
              getHeadSx={() => getHeadSxForStatus(currentTabName)}
              getRowLink={(row) => {
                return `/terminations/${row.payrollId}`;
              }}
              columns={getTerminationColumns({
                currentTabName,
                onPayrollArchived,
              })}
            />
          );
        }}
      />
    </PageContainer>
  );
}

function getRowSxForStatus(status: keyof typeof tabPropsByName) {
  switch (status) {
    case 'approved':
    case 'open':
      return {
        '--PayrollRowActionMenu-opacity': '0',
        'td:nth-last-of-type(1), th:nth-last-of-type(1)': {
          pl: 0,
          pr: 1,
          width: '1em',
        },
        ':hover': {
          '--PayrollRowActionMenu-opacity': '1',
        },
      };
    default:
      return {};
  }
}

function getHeadSxForStatus(status: keyof typeof tabPropsByName) {
  switch (status) {
    case 'approved':
    case 'open':
      return {
        'th:nth-last-of-type(1)': {
          pl: 0,
          pr: 1,
        },
      };
    default:
      return {};
  }
}

function useFilters(): FilterOptions {
  return [
    makeElementListFilter({
      label: 'Iniciado por',
      propertyToFilter: 'termination.startedBy',
      elements: ['employee', 'employer', 'both', 'other'],
      labels: {
        employee: 'Colaborador',
        employer: 'Empresa',
        both: 'Ambos',
        other: 'Outro',
      },
      sortElements: false,
      disableSearch: true,
    }),
    makeDateRangeFilter({
      label: 'Data de aviso',
      propertyToFilter: 'termination.noticeDate',
    }),
    makeElementListFilter({
      label: 'Rescisão',
      propertyToFilter: 'termination.noticeIndemnityType',
      elements: ['indemnified', 'deducted', 'exempt', 'worked', 'other'],
      labels: {
        indemnified: 'Indenizado',
        deducted: 'Deduzido',
        exempt: 'Dispensado',
        worked: 'Trabalhado',
        other: 'Outro',
      },
      sortElements: false,
      disableSearch: true,
    }),
    makeElementListFilter({
      label: 'Tipo de folha',
      propertyToFilter: 'type',
      elements: ['termination', 'complementaryTermination'],
      labels: {
        termination: 'Regular',
        complementaryTermination: 'Complementar',
      },
      sortElements: false,
      disableSearch: true,
    }),
    makeDateRangeFilter({
      label: 'Data de desligamento',
      propertyToFilter: 'termination.terminationDate',
    }),
  ];
}

function PageActionMenu({
  isArchivedItemsVisible,
  onChangeArchivedItemsToggle,
}: {
  isArchivedItemsVisible: boolean;
  onChangeArchivedItemsToggle: () => void;
}) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  return (
    <Box display="flex" justifyContent="flex-end">
      <IconButton
        size="small"
        onClick={(event) => {
          event.preventDefault();
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        sx={{
          borderRadius: '8px',
          padding: '4px',
        }}
      >
        <MoreVert
          fontSize="inherit"
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        elevation={1}
      >
        <Box p={0.5} display={'flex'} alignItems={'center'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            py={1}
            px={1.5}
            gap={2}
            alignItems={'center'}
          >
            <Typography fontSize={'14px'} py={1} variant={'body1'}>
              Mostrar excluídas
            </Typography>
          </Box>
          <Box px={1}>
            <Switch
              checked={isArchivedItemsVisible}
              onChange={onChangeArchivedItemsToggle}
            />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}

function PayrollRowActionMenu({
  payrollId,
  organizationId,
  companyId,
  workerName,
  onPayrollArchived,
  currentTabName,
}: {
  payrollId: string;
  organizationId: string;
  companyId: string;
  workerName: string;
  onPayrollArchived: (payrollId: string) => unknown;
  currentTabName: keyof typeof tabPropsByName;
}) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  const { mutate, isLoading, isSuccess } = useArchivePayroll();
  const { fflags } = useFFlags();
  const isKitDemissionalEnabled = fflags.kitDemissionalEnabled.enabled;
  const { showSnackbar } = useSnackbar();

  const onClickArchiveButton = () => {
    mutate({
      pathParams: {
        organizationId,
        companyId,
        payrollId,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      onPayrollArchived(payrollId);
    }
  }, [payrollId, isSuccess, onPayrollArchived]);

  return (
    <Box display="flex" justifyContent="flex-end">
      <IconButton
        size="small"
        onClick={(event) => {
          event.preventDefault();
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        sx={{
          borderRadius: '8px',
          padding: '4px',
          opacity: open ? 1 : 'var(--PayrollRowActionMenu-opacity)',
        }}
      >
        <MoreVert
          fontSize="inherit"
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => !isLoading && setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        elevation={1}
        sx={{
          fontSize: '14px',
          transform: 'translateY(-5px) translateX(5px)',
          '.MuiPaper-root': {
            padding: '4px',
            minWidth: '9.9215em',
          },
        }}
      >
        {isKitDemissionalEnabled && currentTabName !== 'open' && (
          <MenuItem
            onClick={() => {
              const downloadTerminationKit = async () => {
                showSnackbar({
                  isOpen: true,
                  variant: 'default',
                  Message: 'Documento gerado, iniciando download',
                  hasCloseAction: false,
                });
                fetchDownloadTemplateAttachments({
                  pathParams: {
                    organizationId,
                    templateId: payrollId,
                  },
                  body: {
                    workerName: workerName,
                  },
                })
                  .then((response) => {
                    downloadFile(response.downloadUrl);
                  })
                  .catch((error) => {
                    console.error('Erro ao baixar kit demissional:', error);
                    showSnackbar({
                      isOpen: true,
                      variant: 'error',
                      Message: 'Erro ao baixar kit demissional',
                      hasCloseAction: true,
                    });
                  });
              };
              setOpen(false);
              downloadTerminationKit();
            }}
            sx={{
              p: 0,
              borderRadius: '4px',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                my: '8px',
                ml: '12px',
                mr: '32px',
              }}
            >
              Baixar kit demissional
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          color="secondary"
          onClick={onClickArchiveButton}
          disabled={isLoading}
          sx={{
            p: 0,
            borderRadius: '4px',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              my: '8px',
              ml: '12px',
              mr: '32px',
            }}
          >
            Excluir
          </Typography>
        </MenuItem>
      </Popover>
    </Box>
  );
}

export default TerminationsListPage;
