import React, { useState } from 'react';

import dayjs from 'dayjs';

import { CalendarToday } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Slider,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import {
  VacationsAccrualPeriodEntry,
  VacationsConfigurationEntry,
} from '@octopus/api';
import {
  DaysBeforeDSRRuleConfiguration,
  DaysBeforeStartRuleConfiguration,
} from '@octopus/vacations-types';

import { DatePickerDialog } from '../../../../../modules/components/vacation-scheduler/dialogs';
import { requestVacationObject } from '../utils/types';

export default function DurationAndDatePickerStep({
  accrualPeriod,
  requestVacationMSV,
  request,
  setRequest,
  vacationsConfiguration,
  setIsLoading,
  simulateVacationsSchedule,
}: {
  accrualPeriod: VacationsAccrualPeriodEntry;
  requestVacationMSV: any;
  request: requestVacationObject;
  setRequest: React.Dispatch<React.SetStateAction<requestVacationObject>>;
  vacationsConfiguration: VacationsConfigurationEntry;
  setIsLoading: React.Dispatch<
    React.SetStateAction<{ show: boolean; title: string }>
  >;
  simulateVacationsSchedule: (
    request: requestVacationObject,
  ) => Promise<undefined>;
}) {
  const theme = useTheme();
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const { minimum, maximum } = vacationsConfiguration.rules
    .daysBeforeStart as DaysBeforeStartRuleConfiguration;

  const { days: daysBeforeDsr } = vacationsConfiguration.rules
    .daysBeforeDSR as DaysBeforeDSRRuleConfiguration;

  const minDate = dayjs(accrualPeriod.concessionPeriod.startDate).isBefore(
    dayjs().add(minimum, 'day'),
  )
    ? dayjs().add(minimum, 'day')
    : dayjs(accrualPeriod.concessionPeriod.startDate);

  const maxDate = dayjs(accrualPeriod.concessionPeriod.endDate).isBefore(
    dayjs().add(maximum, 'day'),
  )
    ? dayjs(accrualPeriod.concessionPeriod.endDate).subtract(
        accrualPeriod.daysAvailable,
        'day',
      )
    : dayjs().add(maximum, 'day');

  const minDuration = 5;
  const maxDuration =
    accrualPeriod.daysAvailable - (request.sellVacation ? 10 : 0);
  const disableWeekends = (date: dayjs.Dayjs) => {
    if (daysBeforeDsr < 2) {
      return [0, 6].includes(date.day());
    }
    return [0, 5, 6].includes(date.day());
  };

  return (
    <Container
      date-testid={'container'}
      sx={{
        pt: 3,
        pb: 2,
        flexDirection: 'column',
        display: 'inline-flex',
        justifyContent: 'center',
        px: 2,
        gap: '32px',
        height: '100%',
        bgcolor: theme.palette.background.secondary,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          justifyContent: 'center',
          flexGrow: 10,
          alignContent: 'center',
          pb: 20,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            alignSelf: 'stretch',
          }}
        >
          <Typography variant="body1" fontSize={14} fontWeight={500}>
            {'Qual a data de início das férias?'}
          </Typography>
          <TextField
            value={request.date ? dayjs(request.date).format('DD/MM/YYYY') : ''}
            placeholder="dd/mm/aaaa"
            onClick={() => setDatePickerOpen(true)}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarToday />
                </InputAdornment>
              ),
            }}
          />
          <DatePickerDialog
            open={datePickerOpen}
            setOpen={setDatePickerOpen}
            value={request.date ? dayjs(request.date) : null}
            onChange={(date) => setRequest({ ...request, date: date })}
            minDate={minDate}
            maxDate={maxDate}
            disableWeekends={disableWeekends}
            accrualPeriod={accrualPeriod}
            minAntecipation={minimum}
            daysBeforeDSR={daysBeforeDsr}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Typography variant="body1" fontSize={14} fontWeight={500}>
            {'Quantos dias?'}
          </Typography>
          <Box
            sx={{
              px: 1,
            }}
          >
            <Slider
              aria-label="Duração das férias"
              getAriaValueText={(value) => `${value}`}
              value={request.duration ?? maxDuration}
              onChange={(_, newValue) =>
                setRequest({ ...request, duration: newValue as number })
              }
              min={minDuration}
              max={maxDuration}
              step={1}
              marks={[
                { value: minDuration, label: minDuration },
                { value: maxDuration, label: maxDuration },
              ]}
              valueLabelDisplay="on"
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, alignContent: 'flex-end' }}>
        <Button
          color="primaryAlt"
          sx={{ width: '100%', py: 1, height: '48px', gap: '8px' }}
          onClick={() => {
            const updatedRequest = { ...request };
            if (request.duration === undefined) {
              setRequest({ ...request, duration: maxDuration });
              updatedRequest.duration = maxDuration;
            }
            if (request.date === undefined) {
              return;
            }
            setIsLoading({ show: true, title: 'Validando sua solicitação' });
            simulateVacationsSchedule(updatedRequest)
              .then(() => {
                setRequest({
                  ...updatedRequest,
                  result: {
                    ok: true,
                  },
                });
                requestVacationMSV.goForward();
                setIsLoading({ show: false, title: '' });
              })
              .catch((error) => {
                requestVacationMSV.goForward();
                setRequest({
                  ...updatedRequest,
                  result: {
                    errorRuleCodes: Object.keys(error.stack.details),
                    ok: false,
                  },
                });
                console.log(JSON.stringify(updatedRequest));
                setIsLoading({ show: false, title: '' });
              })
              .finally(() => setIsLoading({ show: false, title: '' }));
          }}
        >
          Avançar
        </Button>
      </Box>
    </Container>
  );
}
