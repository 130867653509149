import { useMemo } from 'react';

import { Typography } from '@mui/material';

import TimesheetFileSuccessIcon from '../../../../../../../../assets/fileSuccess.svg';
import { TimesheetEntry } from '../types';

import { TimesheetValidationActions } from './TimesheetValidationActions';
import { TimesheetValidationContainer } from './TimesheetValidationContainer';

export function TimesheetValidationSuccess({
  entries,
  onConfirm,
  onCancel,
}: {
  entries: TimesheetEntry[];
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const entriesCount = useMemo(() => entries.length, [entries]);
  const contractsCount = useMemo(
    () => new Set(entries.map((entry) => entry.contract.contractId)).size,
    [entries],
  );

  return (
    <TimesheetValidationContainer
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
      }}
    >
      <TimesheetValidationActions
        onCancel={onCancel}
        onConfirm={onConfirm}
        cancelButtonText="Cancelar"
        confirmButtonText="Inserir"
        showConfirmButton
        icon={
          <img
            src={TimesheetFileSuccessIcon}
            height={88}
            width={88}
            alt="Arquivo com lançamentos válidos"
          />
        }
      >
        <Typography variant="body1" align="center">
          {entriesCount > 1 ? (
            <>
              Foram identificados <b>{entriesCount} lançamentos</b> para
            </>
          ) : (
            <>
              Foi identificado <b>{entriesCount} lançamento</b> para
            </>
          )}{' '}
          <b>
            {contractsCount > 1
              ? `${contractsCount} pessoas`
              : `${contractsCount} pessoa`}
            .
          </b>
        </Typography>
        <Typography variant="body1" align="center">
          Deseja {entriesCount > 1 ? 'inseri-los' : 'inserir'} agora?
        </Typography>
      </TimesheetValidationActions>
    </TimesheetValidationContainer>
  );
}
