import ExcelJS from 'exceljs';

import { workbookColors } from '../styles';
import { CellStyle, CellValue } from '../types';

const DEFAULT_CELL_STYLE: CellStyle = {
  font: 'Proxima Nova',
  size: 10,
  bold: false,
  color: workbookColors.black,
  horizontalAlignment: 'left',
  verticalAlignment: 'middle',
  wrap: false,
  borders: false,
  borderColor: workbookColors.black,
  fill: false,
  fillColor: workbookColors.lightGrey,
};

export function setCell(
  worksheet: ExcelJS.Worksheet,
  rowNum: number,
  columnNum: number,
  value: CellValue,
  style?: CellStyle,
) {
  const {
    font,
    size,
    bold,
    color,
    horizontalAlignment,
    verticalAlignment,
    wrap,
    borders,
    borderColor,
    fill,
    fillColor,
    numberFormat,
  } = { ...DEFAULT_CELL_STYLE, ...style } as Required<CellStyle>;
  const row = worksheet.getRow(rowNum);
  if (!row.height || row.height < size * 1.5) {
    row.height = size * 1.5;
  }
  const cell = row.getCell(columnNum);
  cell.value = value;
  cell.style = {
    numFmt: numberFormat,
    font: {
      name: font,
      size: size,
      bold: bold,
      color: {
        argb: color,
      },
    },
    alignment: {
      horizontal: horizontalAlignment,
      vertical: verticalAlignment,
      wrapText: wrap,
    },
    ...(borders
      ? {
          border: {
            top: { style: 'thin', color: { argb: borderColor } },
            left: { style: 'thin', color: { argb: borderColor } },
            bottom: { style: 'thin', color: { argb: borderColor } },
            right: { style: 'thin', color: { argb: borderColor } },
          },
        }
      : {}),
    ...(fill
      ? {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: fillColor },
          },
        }
      : {}),
  };
}
