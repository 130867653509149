import ExcelJS from 'exceljs';

import { autoResizeColumns, setAutoFilter, setCell } from '../operations';
import { workbookStyles } from '../styles';
import { TableSectionProps } from '../types';

export function writeTableSection({
  worksheet,
  startRow,
  startColumn,
  props: { headers, rows, showHeaders = true },
}: {
  worksheet: ExcelJS.Worksheet;
  startRow: number;
  startColumn: number;
  props: TableSectionProps;
}): number {
  headers
    .sort((a, b) => a.order - b.order)
    .forEach(({ id, label, formatter, numberFormat }, headerIndex) => {
      const colNum = headerIndex + startColumn;

      if (showHeaders) {
        setCell(worksheet, startRow, colNum, label, workbookStyles.tableHeader);
      }

      rows.forEach((row, rowIndex) => {
        const rowNum = rowIndex + startRow + (showHeaders ? 1 : 0);
        const value = formatter ? formatter(row[id]) : row[id];
        setCell(worksheet, rowNum, colNum, value, {
          ...workbookStyles.tableValue,
          numberFormat,
        });
      });
    });
  const endRow = startRow + rows.length;
  const range = {
    from: {
      row: startRow,
      column: startColumn,
    },
    to: {
      row: endRow,
      column: startColumn + headers.length - 1,
    },
  };
  autoResizeColumns(worksheet, range);

  if (showHeaders) {
    worksheet.getRow(startRow).height = 26;
    setAutoFilter(worksheet, range);
  }

  return endRow;
}
