import { AwsRum } from 'aws-rum-web';

let rumInstance: AwsRum | undefined = undefined;

function init(rumAppId: string, identityPoolId: string) {
  rumInstance = new AwsRum(rumAppId, '1.0.0', 'us-east-1', {
    sessionSampleRate: 1,
    identityPoolId: identityPoolId,
    endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
    telemetries: [
      'errors',
      'performance',
      [
        'http',
        {
          recordAllRequests: false,
          urlsToRecord: [/\.8arm\.io/],
          addXRayTraceIdHeader: [/\.8arm\.io/],
        },
      ],
    ],
    allowCookies: true,
    enableXRay: true,
  });
}

function addUserIdMetadata(userId: string) {
  if (!rumInstance) {
    return;
  }
  rumInstance.addSessionAttributes({ userId });
}

function removeUserIdMetadata() {
  if (!rumInstance) {
    return;
  }
  rumInstance.addSessionAttributes({});
}

function recordError(error: unknown) {
  if (!rumInstance) {
    return;
  }
  rumInstance.recordError(error);
}

export const rum = {
  init,
  addUserIdMetadata,
  removeUserIdMetadata,
  recordError,
};
