export const INFINITE_CACHE_TIME = Infinity;
export const INFINITE_STALE_TIME = Infinity;

export const appContextQueryConfig = {
  cacheTime: INFINITE_CACHE_TIME,
  staleTime: INFINITE_STALE_TIME,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};
