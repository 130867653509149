import React, { useContext, useMemo, useState } from 'react';

import { IconCirclePlus } from '@tabler/icons-react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  ContractBRCltEntryGestao,
  ContractBRCltEntryTrabalho,
  ContractBRCltEntryVinculo,
  CostCenterEntry,
  CostCenterSummary,
  DepartmentEntry,
  DepartmentSummary,
  JobTitleEntry,
  JobTitleSummary,
} from '@octopus/api';
import { CBOs } from '@octopus/esocial/mapper';
import { formatCBO } from '@octopus/formatters';

import { AppContext } from '../../../../../app/context';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import {
  pollCostCenter,
  pollDepartment,
  pollJobTitle,
} from '../../../../pollers';
import { CreateCostCenterDrawer } from '../../../costCenters/CreateCostCenterDrawer';
import { CreateDepartmentDrawer } from '../../../departments/CreateDepartmentDrawer';
import { CreateJobTitleDrawer } from '../../../jobTitles/CreateJobTitleDrawer';
import { Record, RecordEntry } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type InformacoesProfissionaisRecordData = {
  organizationId: string;
  workerId: string;
  vinculo: ContractBRCltEntryVinculo;
  trabalho: ContractBRCltEntryTrabalho;
  gestao: ContractBRCltEntryGestao | undefined;
};

export type InformacoesProfissionaisRecordProps =
  BaseRecordProps<InformacoesProfissionaisRecordData> & {
    companyId: string;
  };

export function InformacoesProfissionaisRecord(
  props: InformacoesProfissionaisRecordProps,
) {
  const { organizationId, workerId, vinculo, trabalho, gestao } = props.data;
  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);
  const { appContext, refetchCompanyData } = useContext(AppContext);
  const [isCreateJobDrawerOpen, setIsCreateJobDrawerOpen] = useState(false);
  const [isCreateCostCenterDrawerOpen, setIsCreateCostCenterDrawerOpen] =
    useState(false);
  const [isCreateDepartmentDrawerOpen, setIsCreateDepartmentDrawerOpen] =
    useState(false);
  const { showSnackbar } = useSnackbar();

  const setFormField = (
    key: keyof InformacoesProfissionaisRecordData,
    value: string,
  ) =>
    updateData((data) => ({
      ...data,
      [key]: value,
    }));

  const setFormGroupField = <
    G extends keyof Omit<
      InformacoesProfissionaisRecordData,
      'workerId' | 'organizationId' | 'companyId'
    >,
  >(
    group: G,
    key: keyof InformacoesProfissionaisRecordData[G],
    value: string,
  ) =>
    updateData((data) => ({
      ...data,
      [group]: {
        ...data[group],
        [key]: value,
      },
    }));

  const [costCenters, setCostCenters] = useState<
    (CostCenterSummary | CostCenterEntry)[] | undefined
  >(appContext?.company?.costCenters?.map((cc) => cc.summary));
  const costCenter = costCenters?.find(
    ({ costCenterId }) => gestao?.costCenterId === costCenterId,
  );

  const [departments, setDepartments] = useState<
    (DepartmentSummary | DepartmentEntry)[]
  >(appContext?.company?.departments?.map((d) => d.summary));
  const department = departments?.find(
    ({ departmentId }) => trabalho.departmentId === departmentId,
  );

  const [jobTitles, setJobTitles] = useState<
    (JobTitleSummary | JobTitleEntry)[] | undefined
  >(appContext?.company?.jobTitles?.map((jbt) => jbt.summary));
  const jobTitle = jobTitles?.find(
    ({ jobTitleId }) => trabalho.jobTitleId === jobTitleId,
  );
  const maxJobTitleCode = useMemo(
    () =>
      Math.max(
        ...(appContext?.company?.jobTitles?.map((jbt) => jbt.summary.code) ?? [
          0,
        ]),
      ),
    [appContext?.company?.jobTitles?.length],
  );
  const cboCargo = CBOs.getByCode(jobTitle?.occupationCode);
  const editCargo = jobTitles?.find(
    ({ jobTitleId }) => formData.trabalho?.jobTitleId === jobTitleId,
  );
  const editCBO = CBOs.getByCode(editCargo?.occupationCode);
  return (
    <Record title="Informações profissionais" edit={editRecordProps}>
      <RecordEntry
        label="Matrícula na empresa"
        edit={{
          type: 'text',
          editing,
          value: formData.workerId,
          onChange: (value) => setFormField('workerId', value),
          hasError: hasError('workerId'),
        }}
      >
        {workerId}
      </RecordEntry>

      <RecordEntry
        label="Matrícula no eSocial"
        edit={{
          type: 'text',
          editing,
          value: formData.vinculo.matricula,
          disabled: true,
          onChange: () => undefined,
          hasError: hasError('br/vinculo/matricula'),
        }}
      >
        {vinculo.matricula}
      </RecordEntry>

      <RecordEntry
        label="Cargo"
        isLoading={!jobTitle}
        edit={{
          type: 'options',
          disabled: !jobTitles,
          editing,
          value: formData.trabalho.jobTitleId,
          options: jobTitles
            ?.filter(
              ({ jobTitleId, active }) =>
                active || jobTitleId === jobTitle?.jobTitleId,
            )
            ?.filter(({ occupationCode }) => !!occupationCode)
            ?.map(({ jobTitleId, name }) => ({
              value: jobTitleId,
              label: name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
          onChange: (value) =>
            setFormGroupField('trabalho', 'jobTitleId', value as string),
          hasError: hasError('br/trabalho/jobTitleId'),
          action: {
            Icon: <IconCirclePlus size={16} color="blue" />,
            label: 'Criar novo cargo',
            onClick: () => {
              setIsCreateJobDrawerOpen(true);
            },
          },
        }}
      >
        {jobTitle?.name}
      </RecordEntry>

      <RecordEntry
        label="CBO do cargo"
        isLoading={!jobTitle}
        edit={{
          editing,
          type: 'text',
          value:
            formatCBO(editCargo?.occupationCode) +
            (editCBO ? ` - ${editCBO}` : ''),
          disabled: true,
          onChange: () => {
            //noop
          },
        }}
      >
        {formatCBO(jobTitle?.occupationCode) +
          (cboCargo ? ` - ${cboCargo}` : '')}
      </RecordEntry>

      <RecordEntry
        label="Função"
        edit={{
          editing,
          type: 'options',
          options: [
            { label: 'N/A', value: null },
            ...CBOs.codes()
              .sort()
              .map((code) => ({
                label: `${formatCBO(code as string)} - ${CBOs.getByCode(code)}`,
                value: code,
              })),
          ],
          value: formData.trabalho.CBOFuncao ?? null,
          onChange: (code) => {
            updateData((data) => ({
              ...data,
              trabalho: {
                ...data.trabalho,
                CBOFuncao: code as string,
                nmFuncao: code ? CBOs.getByCode(code) : null,
              },
            }));
          },
        }}
      >
        {trabalho.CBOFuncao
          ? `${formatCBO(trabalho.CBOFuncao as string)} - ${CBOs.getByCode(
              trabalho.CBOFuncao,
            )}`
          : undefined}
      </RecordEntry>

      <RecordEntry
        label="Departamento"
        isLoading={!departments}
        edit={{
          type: 'options',
          editing,
          value: formData.trabalho.departmentId,
          options: [
            { label: 'N/A', value: null },
            ...(departments
              ?.filter(
                ({ active, departmentId }) =>
                  active || departmentId === trabalho?.departmentId,
              )
              ?.map(({ departmentId, name }) => ({
                label: name,
                value: departmentId,
              }))
              ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []),
          ],
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              trabalho: {
                ...data.trabalho,
                departmentId: value,
              },
            })),
          hasError: hasError('br/trabalho/departmentId'),
          action: {
            Icon: <IconCirclePlus size={16} color="blue" />,
            label: 'Criar novo departamento',
            onClick: () => {
              setIsCreateDepartmentDrawerOpen(true);
            },
          },
        }}
      >
        {!trabalho?.departmentId || !department ? undefined : department.name}
      </RecordEntry>

      <RecordEntry
        label="Centro de custo"
        isLoading={!costCenters}
        edit={{
          type: 'options',
          editing,
          value: formData.gestao?.costCenterId,
          options: [
            { label: 'N/A', value: null },
            ...(costCenters
              ?.filter(
                ({ active, costCenterId }) =>
                  active || costCenterId === gestao?.costCenterId,
              )
              ?.map(({ costCenterId, name }) => ({
                label: name,
                value: costCenterId,
              }))
              ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []),
          ],
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              gestao: {
                ...data.gestao,
                costCenterId: value as string,
                nomeCentroCusto:
                  costCenters?.find(
                    ({ costCenterId }) => costCenterId === value,
                  )?.name ?? '',
                codigoCentroCusto:
                  costCenters?.find(
                    ({ costCenterId }) => costCenterId === value,
                  )?.code ?? '',
              },
            })),
          hasError: hasError('br/gestao/costCenterId'),
          action: {
            Icon: <IconCirclePlus size={16} color="blue" />,
            label: 'Criar novo centro de custo',
            onClick: () => {
              setIsCreateCostCenterDrawerOpen(true);
            },
          },
        }}
      >
        {!gestao?.costCenterId || !costCenter ? undefined : costCenter.name}
      </RecordEntry>

      <RecordEntry
        label="Email corporativo"
        edit={{
          type: 'text',
          editing,
          value: formData.vinculo.emailCorp,
          onChange: (value) => setFormGroupField('vinculo', 'emailCorp', value),
          hasError: hasError('br/vinculo/emailCorp'),
        }}
      >
        {vinculo.emailCorp}
      </RecordEntry>
      {isCreateJobDrawerOpen && (
        <CreateJobTitleDrawer
          organizationId={organizationId}
          companies={appContext?.company?.companies?.map((cmp) => cmp.summary)}
          maxCode={maxJobTitleCode}
          open={isCreateJobDrawerOpen}
          onClose={() => setIsCreateJobDrawerOpen(false)}
          onSuccess={async (jobTitle) => {
            setJobTitles((jbts) => [...jbts, jobTitle]);
            setFormGroupField('trabalho', 'jobTitleId', jobTitle.jobTitleId);
            setIsCreateJobDrawerOpen(false);
            showSnackbar({
              isOpen: true,
              variant: 'default',
              Message: 'Cargo criado com sucesso',
              StartAdornment: <CheckCircleIcon />,
            });
            await pollJobTitle({
              organizationId,
              jobTitleId: jobTitle.jobTitleId,
            });
            await refetchCompanyData();
          }}
          requireContractType={'br:clt'}
          requireCompany={props.companyId}
        />
      )}
      {isCreateCostCenterDrawerOpen && (
        <CreateCostCenterDrawer
          organizationId={organizationId}
          companies={appContext?.company?.companies?.map((cmp) => cmp.summary)}
          open={isCreateCostCenterDrawerOpen}
          onClose={() => setIsCreateCostCenterDrawerOpen(false)}
          onSuccess={async (costCenter) => {
            setCostCenters((ccts) => [...ccts, costCenter]);
            updateData((data) => ({
              ...data,
              gestao: {
                ...data.gestao,
                costCenterId: costCenter.costCenterId,
                nomeCentroCusto: costCenter.name,
                codigoCentroCusto: costCenter.code,
              },
            }));
            setIsCreateCostCenterDrawerOpen(false);
            showSnackbar({
              isOpen: true,
              variant: 'default',
              Message: 'Centro de custo criado com sucesso',
              StartAdornment: <CheckCircleIcon />,
            });
            await pollCostCenter({
              organizationId,
              costCenterId: costCenter.costCenterId,
            });
            await refetchCompanyData();
          }}
          requireCompany={props.companyId}
        />
      )}
      {isCreateDepartmentDrawerOpen && (
        <CreateDepartmentDrawer
          organizationId={organizationId}
          companies={appContext?.company?.companies?.map((cmp) => cmp.summary)}
          open={isCreateDepartmentDrawerOpen}
          onClose={() => setIsCreateDepartmentDrawerOpen(false)}
          onSuccess={async (department) => {
            setDepartments((dpts) => [...dpts, department]);
            updateData((data) => ({
              ...data,
              trabalho: {
                ...data.trabalho,
                departmentId: department.departmentId,
              },
            }));
            setIsCreateDepartmentDrawerOpen(false);
            showSnackbar({
              isOpen: true,
              variant: 'default',
              Message: 'Departamento criado com sucesso',
              StartAdornment: <CheckCircleIcon />,
            });
            await pollDepartment({
              organizationId,
              departmentId: department.departmentId,
            });
            await refetchCompanyData();
          }}
          requireCompany={props.companyId}
        />
      )}
    </Record>
  );
}
