import { useState } from 'react';

export const getContextFromLocalStorage = <T>(key: string): T | undefined => {
  try {
    if (!localStorage) {
      return undefined;
    }

    const context = localStorage.getItem(key);

    if (!context) {
      return undefined;
    }

    return JSON.parse(context);
  } catch (_) {
    return undefined;
  }
};

export const clearContextFromLocalStorage = (key: string): void => {
  if (!localStorage) {
    return;
  }

  localStorage.removeItem(key);
};

export const clearAllLocalStorage = (): void => {
  if (!localStorage) {
    return;
  }

  localStorage.clear();
};

export const setContextOnLocalStorage = <T>(key: string, context: T): void => {
  if (!localStorage) {
    return;
  }

  localStorage.setItem(key, JSON.stringify(context));
};

export const useLocalStorage = <T>(key: string) => {
  const [localStorageContext, setContext] = useState<T | undefined>(
    getContextFromLocalStorage(key),
  );

  const setLocalStorageContext = (context: T) => {
    setContext(context);
    setContextOnLocalStorage(key, context);
  };

  const clearLocalStorageContext = () => {
    clearContextFromLocalStorage(key);
    setContext(undefined);
  };

  return {
    localStorageContext,
    setLocalStorageContext,
    clearLocalStorageContext,
  };
};
