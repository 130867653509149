import { useMemo } from 'react';

import { Box, Theme, Typography } from '@mui/material';

import { OctopusLoading } from '@octopus/ui/design-system';

type TimesheetLoadingFeedbackProps = {
  isLoading: boolean;
  text?: string;
  showDashedBorder?: boolean;
};

export function TimesheetLoading({
  text,
  isLoading,
  showDashedBorder = true,
}: TimesheetLoadingFeedbackProps) {
  const borderProps = useMemo(() => {
    return showDashedBorder
      ? {
          border: (theme: Theme) => `1px dashed ${theme.palette.strokes.light}`,
          borderRadius: 1,
        }
      : {};
  }, [showDashedBorder]);

  if (!isLoading) {
    return null;
  }

  return (
    <Box
      data-testid="timesheet-loading"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        ...borderProps,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ maxWidth: (theme) => theme.spacing(15) }}>
          <OctopusLoading />
        </Box>
        <Typography variant="h4">{text}</Typography>
      </Box>
    </Box>
  );
}
