import { AdmissionDraftInputFormStepEnum } from '@octopus/api';
import { WorkerCategory } from '@octopus/contract-types';
import { IFormDefinition } from '@octopus/libs/forms';

import { TFFlags } from '../../../fflags';

export type AdminFormKey =
  | 'workerCategory'
  | 'company'
  | 'legalEntity'
  | 'admissionType'
  | 'user_name'
  | 'user_email'
  | 'emailCorp'
  | 'workerId'
  | 'matricula'
  | 'cargo'
  | 'cargoConfianca'
  | 'centro_de_custo'
  | 'departmentId'
  | 'admissionDate'
  | 'tpRegTrab'
  | 'tpAdmissao'
  | 'tpRegPrev'
  | 'codCateg'
  | 'tpRegJor'
  | 'horNoturno'
  | 'indAdmissao'
  | 'nrProcTrab'
  | 'tpJornada'
  | 'qtdHrsSem'
  | 'tmpParc'
  | 'descJorn'
  | 'tpContr'
  | 'duracaoContr'
  | 'cnpjSindCategProf'
  | 'salary'
  | 'unidadeBaseCalculo';

export type WorkerFormKey =
  | 'nome'
  | 'nomeSocial'
  | 'hasNomeSocial'
  | 'cpf'
  | 'nomeMae'
  | 'sexo'
  | 'racaCor'
  | 'grauInstr'
  | 'estadoCivil'
  | 'dtNascto'
  | 'paisNascto'
  | 'nacionalidade'
  | 'grauInstr'
  | 'tpLograd'
  | 'dscLograd'
  | 'nrLograd'
  | 'complemento'
  | 'bairro'
  | 'cep'
  | 'uf'
  | 'countryCode'
  | 'codMunic'
  | 'fonePrinc'
  | 'pessoaComDeficiencia'
  | 'dependentes'
  | 'chavePix'
  | 'tipoPix'
  | 'codigoBanco'
  | 'agencia'
  | 'conta'
  | 'profilePictureId';

export type EstagioKey =
  | 'areaAtuacao'
  | 'nrApol'
  | 'natEstagio'
  | 'nivEstagio'
  | 'cnpjAgntInteg'
  | 'cnpjInstEnsino'
  | 'dtPrevTerm';

export type PJKey =
  | 'periodicidadePagamento'
  | 'dataInicio'
  | 'dataAssinatura'
  | 'renovacao'
  | 'razaoSocial'
  | 'cnpjEmpresa'
  | 'cnae'
  | 'enquadramentoTributario'
  | 'empresa';

export type JovemAprendizKey =
  | 'cnpjEntQual'
  | 'nmEntQual'
  | 'razaoSocialEntQual';

export type TSVKey =
  | 'dtOpcFGTS';

export type CustomFieldsKey = `customFields_${string}`;
export type CustomDocumentType = `customFields_document_${string}`;
export type FieldSetKey = `${string}Fieldset`;

export type FormKey =
  | AdminFormKey
  | WorkerFormKey
  | EstagioKey
  | PJKey
  | JovemAprendizKey
  | TSVKey
  | CustomFieldsKey
  | FieldSetKey;
export type AdmissionFormState = { [key in FormKey]?: any };

export type AdmissionIds = {
  organizationId: string;
  draftId: string;
};

export type getCustomComponentType = ({
  updateDisabled,
  formState,
  stepDefinition,
  formId,
  onSubmitCallback,
  goToNextStep,
  goToPreviousStep,
  onSubmit,
  isLoading,
  organizationId,
  draftId,
  submitLabel,
}: {
  updateDisabled: boolean;
  formState: AdmissionFormState;
  stepDefinition: FormStepDefinition;
  formId?: string;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  onSubmit: ({ extraData }: { extraData?: Record<string, any> }) => void;
  onSubmitCallback: (newFormState: AdmissionFormState) => void;
  organizationId: string;
  draftId: string;
  isLoading: boolean;
  submitLabel: string;
}) => React.ReactElement;

export type FormStepDefinition = {
  definition: IFormDefinition;
  options: FormStepOptions;
};

export type FormStepOptions = {
  getCustomComponent?: getCustomComponentType;
  id: AdmissionDraftInputFormStepEnum;
  title: string;
  hideLeftMenu?: boolean;
  hideTitle?: boolean;
  completed: boolean;
  started?: boolean;
  reviewed: boolean;
  disclaimer?: string;
  dependentId?: string;
};

export type AdmissionFormSteps = Partial<
  Record<AdmissionDraftInputFormStepEnum, FormStepDefinition>
>;

export type SelectOption = {
  value: string;
  selected: boolean;
  label: string;
  disabled?: boolean;
};

export const getEnabledWorkerCategories = (fflags: TFFlags) => {
  const enabled = new Set(['clt:geral', 'clt:estagiario', 'clt:jovemAprendiz']);

  if (fflags.pjAdmissionEnabled.enabled) {
    enabled.add('pj');
  }
  if (fflags.tsvAdmissionEnabled.enabled) {
    enabled.add('clt:tsv');
  }

  return enabled;
};

export const ENABLED_COUNTRIES = new Set(['105']);

export const getEnabledCodigoCategoria = ({
  workerCategory,
}: {
  workerCategory: WorkerCategory;
}): Set<string> => {
  const enabled = new Set<string>();
  switch (workerCategory) {
    case 'clt:geral': {
      enabled.add('101');
      break;
    }
    case 'clt:tsv': {
      enabled.add('721');
      enabled.add('722');
      enabled.add('723');
      break;
    }
    case 'clt:estagiario': {
      enabled.add('901');
      break;
    }
    case 'clt:jovemAprendiz': {
      enabled.add('103');
      break;
    }
  }

  return enabled;
};
export const ENABLED_TIPO_REGIME_TRABALHO = new Set(['1']);
export const ENABLED_UNIDADE_SALARIO_FIXO = new Set(['5']);
