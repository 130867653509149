import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

type ConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
};

export function ConfirmDialog({
  open,
  onClose,
  onConfirm,
  title,
  description,
  cancelButtonText = 'Cancelar',
  confirmButtonText = 'Descartar',
}: ConfirmDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <Box sx={{ minWidth: 600 }}>
        <DialogTitle
          id="confirm-dialog-title"
          variant="h2"
          sx={{
            pt: 5,
            pb: 2,
            px: 4,
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent
          sx={{
            px: 4,
          }}
        >
          <Typography id="confirm-dialog-description" variant="body2">
            {description}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            p: 1.5,
          }}
        >
          <Button
            onClick={onClose}
            color="secondary"
            size="medium"
            sx={{ height: 40, fontWeight: 'medium' }}
          >
            {cancelButtonText}
          </Button>
          <Button
            onClick={onConfirm}
            color="primaryAlt"
            size="medium"
            sx={{ height: 40, fontWeight: 'medium' }}
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
