import { Cancel } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import {
  ApproveAllPayrollsError,
  PayrollTypes,
  useApproveAllPayrolls,
} from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

import { PeriodFormat } from '../../../../modules/types';

export function ApprovalConfirmationModal({
  onApprove,
  organizationId,
  companyId,
  period,
  type,
  close,
}: {
  onApprove?: () => void;
  organizationId: string;
  companyId: string;
  period: PeriodFormat;
  type: PayrollTypes;
  close: () => void;
}) {
  const { mutate, isLoading, isError, isSuccess, error } =
    useApproveAllPayrolls();
  const approvePeriod = () => {
    mutate(
      {
        pathParams: {
          organizationId: organizationId,
          companyId: companyId,
          periodId: period,
          payrollType: type,
        },
      },
      {
        onSuccess: () => {
          onApprove?.();
        },
      },
    );
  };

  if (isLoading) {
    return <LoadingScene />;
  }

  if (isError) {
    return <ErrorScene error={error} close={close} />;
  }

  if (isSuccess) {
    return null;
  }

  return (
    <InitialScene
      approvePeriod={approvePeriod}
      showWarning={type === 'monthly'}
      close={close}
    />
  );
}

function ErrorScene({
  error,
  close,
}: {
  error: ApproveAllPayrollsError;
  close: () => void;
}) {
  return (
    <>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={1.8} pb={3}>
          <Cancel fontSize="huge" color="error" />
          <Typography variant="h1">Erro na aprovação</Typography>
        </Box>
        <Typography variant="body1">
          Não foi possível aprovar a folha, certifique-se de que não existem
          pendências e tente novamente.
        </Typography>
        {error && <pre>{JSON.stringify(error.payload, null, 2)}</pre>}
      </DialogContent>

      <DialogActions>
        <Button size="large" sx={{ minWidth: '140px' }} onClick={close}>
          Ok
        </Button>
      </DialogActions>
    </>
  );
}

function LoadingScene() {
  return (
    <DialogContent>
      <Box
        py={10}
        px={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box width="100px">
          <OctopusLoading />
        </Box>
        <Box pt={2}>
          <Typography variant="h4" textAlign="center">
            Aprovando folha, aguarde alguns segundos...
          </Typography>
        </Box>
      </Box>
    </DialogContent>
  );
}

function InitialScene({
  approvePeriod,
  showWarning,
  close,
}: {
  approvePeriod: () => void;
  showWarning: boolean;
  close: () => void;
}) {
  return (
    <>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          alignItems="flex-start"
          mb={0}
        >
          <Box gap={3}>
            <Typography variant="h1" fontWeight={700}>
              Aprovação da Folha de Pagamentos
            </Typography>

            <Box gap={3} mt={3}>
              <Typography fontSize="16px" fontWeight={700}>
                Ao confirmar, o que acontece em seguida:
              </Typography>

              <Box display="flex" flexDirection="column">
                <Box
                  component="ul"
                  pl={2}
                  gap={2}
                  display="flex"
                  flexDirection="column"
                >
                  <li>
                    <Typography
                      fontWeight={500}
                      variant="body1"
                      fontSize="16px"
                    >
                      Você está aprovando mais de uma folha
                    </Typography>
                    <Typography
                      fontWeight={500}
                      variant="caption"
                      fontSize="12px"
                    >
                      Ao confirmar, você está aprovando todos os locais de
                      serviço simultaneamente.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      fontWeight={500}
                      variant="body1"
                      fontSize="16px"
                    >
                      Geraçao de Holerites
                    </Typography>
                    <Typography
                      fontWeight={500}
                      variant="caption"
                      fontSize="12px"
                    >
                      Envio dos holerites é feito no próximo passo, na própria
                      página da folha.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      fontWeight={500}
                      variant="body1"
                      fontSize="16px"
                    >
                      Criação de Arquivo de Pagamentos
                    </Typography>
                    <Typography
                      fontWeight={500}
                      variant="caption"
                      fontSize="12px"
                    >
                      Acesse o app de Pagamentos para fazer o download do
                      arquivo.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      fontWeight={500}
                      variant="body1"
                      fontSize="16px"
                    >
                      Envio para o eSocial
                    </Typography>
                    <Typography
                      fontWeight={500}
                      variant="caption"
                      fontSize="12px"
                    >
                      Dados da folha serão enviados ao eSocial.
                    </Typography>
                  </li>
                </Box>
              </Box>

              {showWarning && (
                <Box
                  display="flex"
                  sx={{ backgroundColor: 'background.warning' }}
                  px={3}
                  py={2}
                  gap={2}
                  height="48px"
                  width="490px"
                  borderRadius={1}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <WarningIcon color="warning" height="16px" width="16px" />
                  </Box>

                  <Typography
                    fontWeight={500}
                    variant="caption"
                    fontSize="12px"
                  >
                    Certifique-se de que foram feitos os lançamentos de todos os
                    eventos do mês, como admissões, afastamentos, férias,
                    desligamentos, etc. Após aprovação, novos eventos não serão
                    aceitos neste mês.
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.primary',
            width: '140px',
            height: '40px',
            px: 4,
            ':hover': {
              backgroundColor: 'strokes.light',
            },
          }}
          size="large"
          onClick={() => {
            close();
          }}
          data-testid="cancel-approve-payrolls-button"
        >
          Cancelar
        </Button>
        <Button
          color="primaryAlt"
          variant="contained"
          size="large"
          sx={{
            width: '140px',
            height: '40px',
          }}
          onClick={approvePeriod}
          data-testid="confirm-approve-payrolls-button"
        >
          Confirmar
        </Button>
      </DialogActions>
    </>
  );
}
