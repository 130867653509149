import { cpf } from 'cpf-cnpj-validator';

import {
  AdmissionDraftContractBRCltEntry,
  AdmissionDraftEntry,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftUpdateInput,
  BRPaises,
  ContractBRCltEntryPessoa,
  ContractBRCltEstadoCivil,
  ContractBRCltGrauInstrucao,
  ContractBRCltRacaCor,
  ContractBRCltSexo,
} from '@octopus/api';
import { banksList } from '@octopus/contract-types';
import { formatCPF } from '@octopus/formatters';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import {
  PixKeyType,
  parsePixKey,
} from '../../../../../../modules/payrolls/parser';
import { AdmissionFormState } from '../../types';

const getFormStateFromEntry = ({
  admissionDraftEntry,
}: {
  admissionDraftEntry: AdmissionDraftEntry;
}): AdmissionFormState => {
  const { pagamento } =
    admissionDraftEntry.br as AdmissionDraftContractBRCltEntry;
  const pessoa = admissionDraftEntry.person?.br
    ?.pessoa as ContractBRCltEntryPessoa;
  return {
    tipoPix: pagamento?.tipoPix || parsePixKey(pagamento?.chavePix),
    chavePix: pagamento?.chavePix,
    codigoBanco: pagamento?.codigoBanco,
    agencia: pagamento?.agencia,
    conta: pagamento?.conta,
    nome: pessoa?.nmTrab || admissionDraftEntry.userData?.name,
    nomeSocial: pessoa?.nmSoc,
    cpf: formatCPF(pessoa?.cpfTrab),
    nomeMae: pessoa?.nmMae,
    sexo: pessoa?.sexo,
    racaCor: pessoa?.racaCor,
    grauInstr: pessoa?.grauInstr,
    estadoCivil: pessoa?.estCiv,
  };
};

const getUpdateInputFromFormState = ({
  formState,
  currentStepName,
  body,
}: {
  formState: AdmissionFormState;
  currentStepName: AdmissionDraftInputFormStepEnum;
  body: AdmissionDraftUpdateInput;
}): AdmissionDraftUpdateInput => {
  switch (currentStepName) {
    case admissionDraftFormSteps.dados_bancarios: {
      const nomeBanco = formState.codigoBanco
        ? banksList[formState.codigoBanco]
        : null;

      const [tipoPix, chavePix] =
        Object.entries(formState.tipoPix ?? {})?.[0] || [];
      const hasChavePix = tipoPix && tipoPix !== 'chave_pix_none';

      body.br = {
        pagamento: {
          ...(hasChavePix && { tipoPix: tipoPix as PixKeyType }),
          ...(hasChavePix && chavePix && { chavePix: chavePix as string }),
          ...(formState.agencia && { agencia: formState.agencia }),
          ...(formState.codigoBanco && { codigoBanco: formState.codigoBanco }),
          ...(formState.conta && { conta: formState.conta }),
          ...(nomeBanco && { nomeBanco }),
        },
      };
      break;
    }
    case admissionDraftFormSteps.dados_pessoais: {
      const nmSoc = formState.hasNomeSocial?.nomeSocial;
      const nmSocHidden = formState.hasNomeSocial?.nomeSocialHidden;

      body.person = {
        countryCode: 'BR',
        br: {
          pessoa: {
            nmTrab: formState.nome,
            ...(nmSoc && { nmSoc }),
            ...(nmSocHidden && { nmSoc: null }),
            ...(formState.nomeMae && { nmMae: formState.nomeMae }),
            cpfTrab: cpf.strip(formState.cpf),
            sexo: formState.sexo as ContractBRCltSexo,
            racaCor: Number(formState.racaCor) as ContractBRCltRacaCor,
            grauInstr: Number(
              formState.grauInstr ?? '1',
            ) as ContractBRCltGrauInstrucao,
            estCiv: Number(formState.estadoCivil) as ContractBRCltEstadoCivil,
          },
          nascimento: {
            dtNascto: formState.dtNascto,
            paisNac: Number(formState.nacionalidade) as BRPaises,
            paisNascto: Number(formState.paisNascto) as BRPaises,
          },
        },
      };
      break;
    }
    default:
      return null;
  }

  return body;
};

export const cltMapper = { getUpdateInputFromFormState, getFormStateFromEntry };
