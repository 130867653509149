import ExcelJS from 'exceljs';

import { workbookColors } from '../styles';

export function setDivider(
  worksheet: ExcelJS.Worksheet,
  rowNum: number,
  startCol: number,
  endCol: number,
  color: string = workbookColors.darkGrey,
) {
  const row = worksheet.getRow(rowNum);
  for (let i = startCol; i <= endCol; i++) {
    const cell = row.getCell(i);
    cell.style = {
      ...cell.style,
      border: {
        bottom: {
          style: 'thin',
          color: { argb: color },
        },
      },
    };
  }
}
