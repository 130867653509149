import { Box } from '@mui/material';

import { WorkerCategory, isBRCltContract } from '@octopus/contract-types';
import { getWorkerCategory } from '@octopus/esocial/contracts';

import {
  ContatoDeEmergenciaRecord,
  EnderecoEContatoRecord,
  EstruturaOrganizacionalRecord,
  PagamentoRecord,
  clt,
} from '../../../modules/components/people/br';
import { CustomFieldsRecord } from '../../../modules/components/people/CustomFieldsRecord';
import { RecordGroup } from '../../../modules/components/Record';

import { ContractDetailsTitle } from './ContractDetailsTitle';
import { useContractEventsHistory } from './history';
import { ContractDetailsProps } from './types';
import { useContractEdit } from './useContractEdit';

export function CltContractDetails({
  isEditable,
  refs,
  contractQuery,
  historyEnabled,
  showTitle = true,
  customFieldsQuery,
  contractDocuments,
  refetchDocuments,
}: ContractDetailsProps) {
  const { isLoading, data: contractInfo } = contractQuery;

  const { HistoryDrawer, actionsMenu, updateHistory } =
    useContractEventsHistory({
      contractQuery,
      historyEnabled,
      projectionComponent: CltContractDetails,
      customFieldsQuery,
      refetchContract: contractQuery.refetch,
    });

  const contractEdit = useContractEdit({
    contractQuery,
    isEditable,
    afterUpdate: updateHistory,
  });

  if (
    (!isLoading && (!contractInfo || !contractInfo.br)) ||
    !isBRCltContract(contractInfo) ||
    !contractEdit
  ) {
    return null;
  }

  const {
    UpdateDialog,
    updateRecordProps,
    internalTransferRecordProps,
    companyTransferRecordProps,
  } = contractEdit;

  const {
    organizationId,
    companyId,
    legalEntityId,
    workerId,
    br,
    contractId,
    orgStructure,
    customFields,
  } = contractInfo;
  const {
    pessoa,
    nascimento,
    endereco,
    contato,
    contatoDeEmergencia,
    deficiencia,
    dependentes,
    situacao,
    vinculo,
    trabalho,
    gestao,
    regime,
    jornada,
    duracao,
    estagio,
    aprendiz,
    sucessao,
    observacao,
    remuneracao,
    pagamento,
  } = br;
  const workerCategory: WorkerCategory = getWorkerCategory(
    contractInfo.contractType,
    trabalho,
  );

  const dependentDocuments = contractDocuments?.filter(
    (document) => document.dependentId != null,
  );

  return (
    <>
      <Box display="flex" flexDirection="column" gap={4}>
        {showTitle ? (
          <ContractDetailsTitle
            title="Dados do colaborador(a)"
            right={actionsMenu}
          />
        ) : null}
        <Box ref={refs?.personalDetailsRef} data-testid="person-details">
          <RecordGroup title="Dados Pessoais">
            <clt.InformacoesPessoaisRecord
              data={{
                pessoa,
                nascimento,
              }}
              edit={updateRecordProps}
            />
            <EnderecoEContatoRecord
              data={{
                endereco,
                contato,
              }}
              edit={updateRecordProps}
            />
            <ContatoDeEmergenciaRecord
              data={{
                contatoDeEmergencia,
              }}
              edit={updateRecordProps}
            />
            <clt.DeficienciaRecord
              data={{
                deficiencia,
              }}
              edit={updateRecordProps}
            />
            <clt.DependentesRecord
              data={{
                dependentes,
              }}
              organizationId={organizationId}
              contractId={contractId}
              edit={updateRecordProps}
              dependentDocuments={dependentDocuments}
              refetchDocuments={refetchDocuments}
            />
          </RecordGroup>
        </Box>
        <Box ref={refs?.contractDetailsRef} data-testid="contract-details">
          <RecordGroup title="Dados Contratuais">
            <clt.EmpregadorRecord
              internalTransferRecordProps={{
                data: {
                  organizationId,
                  companyId,
                  legalEntityId,
                  workerId,
                },
                edit: internalTransferRecordProps,
              }}
              companyTransferRecordProps={{
                data: {
                  organizationId,
                  companyId,
                  legalEntityId,
                  contractId,
                  workerId,
                  matricula: vinculo.matricula,
                },
                edit: companyTransferRecordProps,
              }}
            />
            <clt.InformacoesProfissionaisRecord
              companyId={companyId}
              data={{
                organizationId,
                workerId,
                vinculo,
                trabalho,
                gestao,
              }}
              edit={{
                ...updateRecordProps,
                onSave: (data, onSuccess, onError) => {
                  const {
                    update: { organizationId: _, workerId, ...changes },
                  } = data;
                  updateRecordProps.onSave(
                    { update: changes, workerId },
                    onSuccess,
                    onError,
                  );
                },
              }}
            />
            <EstruturaOrganizacionalRecord
              data={{
                orgStructure,
              }}
              parameters={{
                organizationId,
                contractId,
              }}
              edit={contractEdit.changeManagerRecordProps}
            />
            <clt.AdmissaoERegimeDeTrabalhoRecord
              data={{
                vinculo,
                trabalho,
                regime,
                workerCategory,
              }}
              edit={updateRecordProps}
            />
            {jornada ? (
              <clt.JornadaDeTrabalho
                data={{
                  jornada,
                }}
                edit={updateRecordProps}
              />
            ) : undefined}
            {workerCategory !== 'clt:autonomo' ? (
              <clt.DetalhesDoContrato
                data={{ situacao, duracao, regime }}
                edit={updateRecordProps}
              />
            ) : undefined}
            {workerCategory !== 'clt:autonomo' &&
            workerCategory !== 'clt:estagiario' ? (
              <clt.SindicatoRecord data={{ regime }} edit={updateRecordProps} />
            ) : undefined}
            <clt.EstagioRecord
              data={{
                trabalho,
                estagio,
              }}
              edit={updateRecordProps}
            />
            <clt.JovemAprendizRecord
              data={{
                trabalho,
                aprendiz,
              }}
              edit={updateRecordProps}
            />
            <clt.SucessaoDeVinculoTrabalhistaRecord sucessao={sucessao} />
            <clt.ObservacoesRecord
              data={{ observacao }}
              edit={updateRecordProps}
            />
          </RecordGroup>
        </Box>
        {!customFieldsQuery.isLoading && customFieldsQuery.data?.length > 0 && (
          <Box
            ref={refs?.customFieldsDetailsRef}
            data-testid="custom-fields-details"
          >
            <CustomFieldsRecord
              data={{ customFields }}
              edit={{
                ...updateRecordProps,
                onSave: (data, onSuccess, onError) => {
                  const {
                    update: { customFields },
                  } = data;
                  updateRecordProps.onSave(
                    { update: {}, customFields },
                    onSuccess,
                    onError,
                  );
                },
              }}
              fieldDefs={customFieldsQuery.data}
            />
          </Box>
        )}
        <Box ref={refs?.paymentDetailsRef} data-testid="payment-details">
          <RecordGroup title="Remuneração">
            {workerCategory !== 'clt:autonomo' ? (
              <clt.RemuneracaoRecord
                data={{ remuneracao }}
                edit={updateRecordProps}
              />
            ) : undefined}
            <PagamentoRecord data={{ pagamento }} edit={updateRecordProps} />
          </RecordGroup>
        </Box>
      </Box>
      {UpdateDialog}
      {HistoryDrawer}
    </>
  );
}
