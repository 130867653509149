import { useRef } from 'react';

import { ChronoUnit, LocalDate } from '@js-joda/core';
import { ICellRendererParams } from 'ag-grid-community';

import { Box, Typography } from '@mui/material';

import { PayrollInputsPayloadTypes } from '@octopus/api';
import { formatDecimal, formatInteger, formatMoney } from '@octopus/formatters';

export type InputValueCellProps<T> = ICellRendererParams<T> & {
  inputId: string;
  payloadType: PayrollInputsPayloadTypes;
  hideInfoPoppers: () => void;
  hasBeenEdited: (
    data: T,
    inputId: string,
    value: string | undefined,
  ) => boolean;
  hasValidationErrors: (data: T, inputId: string) => boolean;
};

export function InputCellValue<T>(props: InputValueCellProps<T>) {
  const {
    payloadType,
    value,
    data,
    inputId,
    hideInfoPoppers,
    hasBeenEdited,
    hasValidationErrors,
  } = props;
  const hideRef = useRef<boolean>(false);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
      height="100%"
      boxSizing="border-box"
      px={1.5}
      py={0.5}
      onMouseEnter={() => {
        hideRef.current = true;
        setTimeout(() => {
          if (hideRef.current) {
            hideInfoPoppers();
          }
        }, 100);
      }}
      onMouseLeave={() => {
        hideRef.current = false;
      }}
      {...(hasBeenEdited(data, inputId, value)
        ? {
            bgcolor: 'background.warning',
            border: '1px solid #FADBBC',
          }
        : {})}
      {...(hasValidationErrors(data, inputId)
        ? {
            bgcolor: 'background.error',
            border: '1px solid rgba(236, 58, 58, 0.5)',
          }
        : {})}
    >
      {renderCellValue(payloadType, value)}
    </Box>
  );
}

function renderCellValue(
  payloadType: PayrollInputsPayloadTypes,
  value: string | undefined,
) {
  switch (payloadType) {
    case 'currency':
      return <CurrencyValue value={value} />;
    case 'hours':
      return <HoursValue value={value} />;
    case 'days':
      return <DaysValue value={value} />;
    case 'percentage':
      return <PercentageValue value={value} />;
    case 'date':
      return <DateValue value={value} />;
    case 'dateRange':
      return <DateRangeValue value={value} />;
    case 'number':
      return <NumberValue value={value} />;
    default:
      return (
        <Typography variant="body2" color="text.primary" fontWeight="500">
          {value}
        </Typography>
      );
  }
}

function CurrencyValue({ value }: { value: string | undefined }) {
  if (!value) {
    return (
      <Typography variant="body2" color="strokes.heavy">
        R$ 0,00
      </Typography>
    );
  }
  return (
    <Box display="flex">
      <Typography variant="body2" color="strokes.heavy" fontWeight="500">
        R${' '}
        <Box component="span" color="text.primary">
          {formatMoney(value, { withCurrency: false })}
        </Box>
      </Typography>
    </Box>
  );
}

function HoursValue({ value }: { value: string | undefined }) {
  if (!value) {
    return (
      <Typography variant="body2" color="strokes.heavy">
        0 h
      </Typography>
    );
  }
  const [hours, minutes] = value.split(':');
  return (
    <Box display="flex">
      <Typography variant="body2" color="strokes.heavy" fontWeight="500">
        <Box component="span" color="text.primary">
          {formatInteger(hours)}
        </Box>{' '}
        h
        {minutes && minutes !== '00' && (
          <>
            {' '}
            <Box component="span" color="text.primary">
              {formatInteger(minutes, { minimumIntegerDigits: 2 })}
            </Box>{' '}
            m
          </>
        )}
      </Typography>
    </Box>
  );
}

function DaysValue({ value }: { value: string | undefined }) {
  if (!value) {
    return (
      <Typography variant="body2" color="strokes.heavy">
        0 d
      </Typography>
    );
  }
  return (
    <Box display="flex">
      <Typography variant="body2" color="strokes.heavy" fontWeight="500">
        <Box component="span" color="text.primary">
          {value.split(',').length}
        </Box>{' '}
        d
      </Typography>
    </Box>
  );
}

function PercentageValue({ value }: { value: string | undefined }) {
  if (!value) {
    return (
      <Typography variant="body2" color="strokes.heavy">
        0 %
      </Typography>
    );
  }
  return (
    <Box display="flex">
      <Typography variant="body2" color="strokes.heavy" fontWeight="500">
        <Box component="span" color="text.primary">
          {formatDecimal(Number(value) * 100)}
        </Box>{' '}
        %
      </Typography>
    </Box>
  );
}

function DateValue({ value }: { value: string | undefined }) {
  if (!value) {
    return (
      <Typography variant="body2" color="strokes.heavy">
        dd/mm/aaaa
      </Typography>
    );
  }
  const [year, month, day] = value.split('-');
  return (
    <Box display="flex">
      <Typography variant="body2" color="strokes.heavy" fontWeight="500">
        <Box component="span" color="text.primary">
          {day}
        </Box>
        /
        <Box component="span" color="text.primary">
          {month}
        </Box>
        /
        <Box component="span" color="text.primary">
          {year}
        </Box>
      </Typography>
    </Box>
  );
}

function DateRangeValue({ value }: { value: string | undefined }) {
  if (!value) {
    return (
      <Typography variant="body2" color="strokes.heavy">
        0 d
      </Typography>
    );
  }
  const [start, end] = value.split('/');
  return (
    <Box display="flex">
      <Typography variant="body2" color="strokes.heavy" fontWeight="500">
        <Box component="span" color="text.primary">
          {LocalDate.parse(start).until(LocalDate.parse(end), ChronoUnit.DAYS)}
        </Box>{' '}
        d
      </Typography>
    </Box>
  );
}

function NumberValue({ value }: { value: string | undefined }) {
  if (!value) {
    return (
      <Typography variant="body2" color="strokes.heavy">
        0
      </Typography>
    );
  }
  return (
    <Box display="flex">
      <Typography variant="body2" color="strokes.heavy" fontWeight="500">
        <Box component="span" color="text.primary">
          {formatDecimal(value, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 6,
          })}
        </Box>
      </Typography>
    </Box>
  );
}
