import React, { useEffect, useMemo } from 'react';

import { Tab, Tabs } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

import { PageTabs } from './page/PageTabs';
import TabLabel from './TabLabel';

export type ContentTabDef<T> = {
  label: string;
  value: T;
  hidden?: boolean;
  count?: number;
  color?: string;
  variant?: Variant;
};

export type ContentTabsProps<T> = {
  selected: T;
  setSelected: (val: T) => void;
  tabs: ContentTabDef<T>[];
  tabProps?: {
    color?: string;
    variant?: Variant;
  };
};

export function ContentTabs<T>({
  selected,
  setSelected,
  tabs,
  tabProps,
}: ContentTabsProps<T>) {
  const visibleTabs = useMemo(() => tabs.filter((tab) => !tab.hidden), [tabs]);

  useEffect(() => {
    if (visibleTabs.find((tab) => tab.value === selected) === undefined) {
      setSelected(visibleTabs[0]?.value);
    }
  }, [visibleTabs, selected, setSelected]);

  if (visibleTabs.length === 0) {
    return null;
  }
  return (
    <PageTabs>
      <Tabs
        value={selected}
        onChange={(_, newTab) => setSelected(newTab)}
        textColor="inherit"
        TabIndicatorProps={{}}
        data-testid="content-tabs"
      >
        {visibleTabs.map((tab, index) => (
          <Tab
            key={index}
            value={tab.value}
            icon={
              <TabLabel
                isSelected={tab.value === selected}
                label={tab.label}
                count={tab.count}
                color={tab.color || tabProps?.color}
                variant={tab.variant || tabProps?.variant}
              />
            }
            data-testid={`content-tab-${tab.value}`}
          />
        ))}
      </Tabs>
    </PageTabs>
  );
}
