import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { YearMonth } from '@js-joda/core';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';

import { useCloseProvisionsPeriod, useGetProvisionsPeriod } from '@octopus/api';
import { formatPeriodDate } from '@octopus/formatters';

function ProvisionsPage({ organizationId }: { organizationId: string }) {
  const { period } = useParams<{
    period: string;
  }>();
  const navigate = useNavigate();

  // Add state for expanded sections
  const [expandedStates, setExpandedStates] = useState<{
    [key: string]: boolean;
  }>({});

  const { isSuccess, isError, error, isLoading, mutate } =
    useCloseProvisionsPeriod();

  // Add query for provisions data
  const provisionsQuery = useGetProvisionsPeriod({
    pathParams: { organizationId, periodId: period },
  });

  // Helper function to generate list of periods
  const generatePeriodsList = (currentPeriod: string) => {
    const current = YearMonth.parse(currentPeriod);
    const periods = [];

    // Generate 3 months before
    for (let i = 3; i > 0; i--) {
      periods.push(current.minusMonths(i).toString());
    }

    // Add current month
    periods.push(currentPeriod);

    // Generate 3 months after
    for (let i = 1; i <= 3; i++) {
      periods.push(current.plusMonths(i).toString());
    }

    return periods;
  };

  // Helper function to get state label and color
  const getStateInfo = (
    state: string,
  ): { label: string; color: 'error' | 'info' | 'success' | 'warning' } => {
    const stateMap = {
      open: { label: 'Em Aberto', color: 'warning' },
      calculating: { label: 'Calculando', color: 'info' },
      closed: { label: 'Fechado', color: 'success' },
      unknown: { label: 'Desconhecido', color: 'error' },
    } as const;
    return stateMap[state as keyof typeof stateMap] || stateMap.unknown;
  };

  // Helper function to toggle expanded state
  const toggleExpanded = (state: string) => {
    setExpandedStates((prev) => ({
      ...prev,
      [state]: !prev[state],
    }));
  };

  return (
    <Box sx={{ height: '100vh', display: 'flex' }}>
      {/* Sidebar - Always visible */}
      <Box
        sx={{
          width: '100px',
          p: 2,
          borderRight: '1px solid',
          borderColor: 'divider',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Períodos
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            width: '100%',
            height: '100%',
          }}
        >
          {generatePeriodsList(period).map((p) => (
            <Button
              key={p}
              variant={p === period ? 'contained' : 'outlined'}
              component={Link}
              to={`/provisions/${p}`}
              onClick={(e) => {
                if (e.ctrlKey || e.metaKey || e.button === 1) {
                  return;
                }
                e.preventDefault();
                navigate(`/provisions/${p}`);
              }}
            >
              {p.substring(0, 4)}/{p.substring(5, 7)}
            </Button>
          ))}
        </Box>
      </Box>

      {/* Main Content */}
      <Box px={8} py={4} sx={{ flex: 1, overflow: 'auto' }}>
        <Typography variant="h1">
          Provisões {formatPeriodDate(period)}
        </Typography>

        {/* Alerts */}
        <Box py={2}>
          {isError && <Alert severity="error">{JSON.stringify(error)}</Alert>}
          {isSuccess && (
            <Alert severity="success">
              Provisões serão fechadas nos próximos segundos!
            </Alert>
          )}
        </Box>

        {/* Status Overview */}
        <Box py={2}>
          {provisionsQuery.isLoading ? (
            <Typography>Carregando estados das provisões...</Typography>
          ) : provisionsQuery.isError ? (
            (provisionsQuery.error as unknown as { statusCode: number })
              .statusCode === 404 ? (
              <Alert severity="info">
                Nenhuma provisão foi criada para este período ainda.
              </Alert>
            ) : (
              <Alert severity="error">
                Erro ao carregar estados das provisões
              </Alert>
            )
          ) : (
            <Box>
              <Typography variant="h2" gutterBottom>
                Status das Provisões
              </Typography>
              <List>
                {Object.entries(
                  provisionsQuery.data?.workerProvisionsByState || {},
                ).map(([state, contractIds]) => {
                  const stateInfo = getStateInfo(state);
                  return (
                    <Box key={state} sx={{ mb: 2 }}>
                      <ListItem
                        disablePadding
                        secondaryAction={
                          <Chip
                            label={contractIds.length}
                            color={stateInfo.color}
                            variant="filled"
                            sx={{
                              borderRadius: '50%',
                              minWidth: '32px',
                              height: '32px',
                            }}
                          />
                        }
                      >
                        <ListItemButton onClick={() => toggleExpanded(state)}>
                          <Typography variant="h6">
                            {stateInfo.label}
                            {expandedStates[state] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                      <Collapse in={expandedStates[state]} timeout="auto">
                        <List component="div" disablePadding>
                          {contractIds.map((contractId) => (
                            <ListItem key={contractId} sx={{ pl: 4 }}>
                              <ListItemButton
                                component={Link}
                                to={`/provisions/${period}/${contractId}`}
                              >
                                <Typography>{contractId}</Typography>
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </Box>
                  );
                })}
              </List>
            </Box>
          )}
        </Box>

        {/* Close Provisions Button */}
        <Box py={2}>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            onClick={() => {
              mutate(
                {
                  pathParams: { organizationId, periodId: period },
                },
                {
                  onSuccess: () => {
                    // Refresh the provisions data after successful close
                    provisionsQuery.refetch();
                  },
                },
              );
            }}
          >
            Fechar todas as provisões do período
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}

export default ProvisionsPage;
