import { RefObject } from 'react';

import { Box, Divider } from '@mui/material';

import {
  ContractBRCltAddress,
  ContractBRCltForeignAddress,
  ContractBRPjEntryContato,
  ContractBRPjEntryDependente,
  ContractBRPjEntryEndereco,
  ContractBRPjEntryNascimento,
  ContractBRPjEntryPessoa,
  ContractEntry,
} from '@octopus/api';
import { isBRPjContract } from '@octopus/contract-types';
import {
  Municipios,
  Nacionalidades,
  Paises,
  Sexo,
  TipoLogradouro,
} from '@octopus/esocial/mapper';
import {
  formatCEP,
  formatCPF,
  formatDateBR,
  formatPhoneBR,
} from '@octopus/formatters';

import {
  Record,
  RecordEntry,
  RecordEntryGroup,
  RecordGroup,
} from '../../modules/components/Record';
import { QueryResult } from '../../modules/types';

export type PjPersonalDetailsProps = {
  contractQuery: QueryResult<ContractEntry>;
  showExpandIcon: boolean;
  personalDetailsRef?: RefObject<Element>;
};

export function PjPersonalDetails({
  showExpandIcon,
  personalDetailsRef,
  contractQuery,
}: PjPersonalDetailsProps) {
  const { isLoading, data: contract } = contractQuery;
  if (!isLoading && (!contract || !contract.br)) {
    return null;
  }
  if (!isBRPjContract(contract)) {
    return null;
  }
  const { pessoa, nascimento, endereco, contato, dependentes } =
    contract?.br || {};
  return (
    <Box ref={personalDetailsRef} data-testid="person-details">
      <RecordGroup showExpandIcon={showExpandIcon} title="Dados Pessoais">
        <InformacoesPessoais pessoa={pessoa} isLoading={isLoading} />
        <Nascimento nascimento={nascimento} isLoading={isLoading} />
        <Endereco endereco={endereco} isLoading={isLoading} />
        <Contato contato={contato} isLoading={isLoading} />
        <Dependentes dependentes={dependentes} isLoading={isLoading} />
      </RecordGroup>
    </Box>
  );
}

function InformacoesPessoais({
  pessoa,
  isLoading,
}: {
  pessoa: Partial<ContractBRPjEntryPessoa> | undefined;
  isLoading: boolean;
}) {
  const { nmTrab, nmSoc, cpfTrab } = pessoa ?? {};
  return (
    <Record title="Informações pessoais">
      <RecordEntry label="Nome completo" isLoading={isLoading}>
        {nmTrab}
      </RecordEntry>
      <RecordEntry label="Nome social" isLoading={isLoading}>
        {nmSoc}
      </RecordEntry>
      <RecordEntry label="CPF" isLoading={isLoading}>
        {formatCPF(cpfTrab)}
      </RecordEntry>
    </Record>
  );
}

function Nascimento({
  nascimento,
  isLoading,
}: {
  nascimento: Partial<ContractBRPjEntryNascimento> | undefined;
  isLoading: boolean;
}) {
  const { dtNascto, paisNascto, paisNac } = nascimento || {};
  return (
    <Record title="Nascimento">
      <RecordEntry label="Data de nascimento" isLoading={isLoading}>
        {formatDateBR(dtNascto)}
      </RecordEntry>
      <RecordEntry label="País de nascimento" isLoading={isLoading}>
        {Paises.getByCode(paisNascto)}
      </RecordEntry>
      <RecordEntry label="Nacionalidade" isLoading={isLoading}>
        {Nacionalidades.getByCode(paisNac)}
      </RecordEntry>
    </Record>
  );
}

function Endereco({
  endereco,
  isLoading,
}: {
  endereco: ContractBRPjEntryEndereco | undefined;
  isLoading: boolean;
}) {
  if (!endereco || endereco.tipo === 'brasil') {
    return (
      <EnderecoBrasil
        endereco={endereco as ContractBRCltAddress}
        isLoading={isLoading}
      />
    );
  } else {
    return <EnderecoExterior endereco={endereco} />;
  }
}

function Contato({
  contato,
  isLoading,
}: {
  contato: ContractBRPjEntryContato | undefined;
  isLoading: boolean;
}) {
  if (!isLoading && !contato) {
    return null;
  }
  const { fonePrinc, emailPrinc } = contato ?? {};
  if (!isLoading && !fonePrinc && !emailPrinc) {
    return null;
  }
  return (
    <Record title="Contato">
      <RecordEntry label="Telefone" isLoading={isLoading}>
        {formatPhoneBR(fonePrinc)}
      </RecordEntry>
      <RecordEntry label="E-mail" isLoading={isLoading}>
        {emailPrinc}
      </RecordEntry>
    </Record>
  );
}

function Dependentes({
  dependentes,
}: {
  dependentes: ContractBRPjEntryDependente[] | undefined;
  isLoading: boolean;
}) {
  if (!dependentes || dependentes.length === 0) {
    return null;
  }
  return (
    <Record title="Dependentes">
      {dependentes.map(
        ({ nmDep, dtNascto, cpfDep, sexoDep, descricao }, index) => (
          <RecordEntryGroup key={cpfDep} title={nmDep}>
            {index !== 0 && <Divider />}
            <RecordEntry label="Parentesco">{descricao}</RecordEntry>
            <RecordEntry label="Data de nascimento">
              {formatDateBR(dtNascto)}
            </RecordEntry>
            <RecordEntry label="CPF">{formatCPF(cpfDep)}</RecordEntry>
            <RecordEntry label="Sexo">{Sexo.getByCode(sexoDep)}</RecordEntry>
          </RecordEntryGroup>
        ),
      )}
    </Record>
  );
}

function EnderecoBrasil({
  endereco,
  isLoading,
}: {
  endereco: ContractBRCltAddress | undefined;
  isLoading: boolean;
}) {
  const {
    cep,
    tpLograd,
    dscLograd,
    nrLograd,
    complemento,
    bairro,
    uf,
    codMunic,
  } = endereco ?? {};
  const tipoLogradouro = TipoLogradouro.getByCode(tpLograd);
  const logradouro = tipoLogradouro
    ? `${tipoLogradouro} ${dscLograd}`
    : dscLograd;
  return (
    <Record title="Endereço">
      <RecordEntry label="País" isLoading={isLoading}>
        Brasil
      </RecordEntry>
      <RecordEntry label="CEP" isLoading={isLoading}>
        {formatCEP(cep)}
      </RecordEntry>
      <RecordEntry label="Logradouro" isLoading={isLoading}>
        {logradouro}
      </RecordEntry>
      <RecordEntry label="Número" isLoading={isLoading}>
        {nrLograd}
      </RecordEntry>
      <RecordEntry label="Complemento" isLoading={isLoading}>
        {complemento}
      </RecordEntry>
      <RecordEntry label="Bairro" isLoading={isLoading}>
        {bairro}
      </RecordEntry>
      <RecordEntry label="Unidade Federal" isLoading={isLoading}>
        {uf}
      </RecordEntry>
      <RecordEntry label="Cidade / Município" isLoading={isLoading}>
        {Municipios.getByCode(codMunic)}
      </RecordEntry>
    </Record>
  );
}

function EnderecoExterior({
  endereco,
}: {
  endereco: ContractBRCltForeignAddress | undefined;
}) {
  const {
    paisResid,
    dscLograd,
    nrLograd,
    complemento,
    bairro,
    codPostal,
    nmCid,
  } = endereco;
  return (
    <Record title="Endereço">
      <RecordEntry label="País">{Paises.getByCode(paisResid)}</RecordEntry>
      <RecordEntry label="Código Postal">{codPostal}</RecordEntry>
      <RecordEntry label="Logradouro">{dscLograd}</RecordEntry>
      <RecordEntry label="Número">{nrLograd}</RecordEntry>
      <RecordEntry label="Complemento">{complemento}</RecordEntry>
      <RecordEntry label="Bairro">{bairro}</RecordEntry>
      <RecordEntry label="Cidade / Município">{nmCid}</RecordEntry>
    </Record>
  );
}
