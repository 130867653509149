import React, { useEffect, useState } from 'react';

import { Box, Skeleton } from '@mui/material';

import {
  ContractList,
  ContractSummary,
  SearchInput,
  useSearchAllContracts,
} from '@octopus/api';
import { contractStatuses } from '@octopus/contract-types';
import {
  DataGrid,
  DataGridProps,
  DataGridToolbar,
  GridColDef,
  GridValueGetterParams,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { ErrorAlert } from '../../ErrorAlert';
import { departmentColumn } from '../../table/columns/CompanyColumns';
import UserAvatar from '../../UserAvatar';

export type JobTitleContractsProps = {
  organizationId: string;
  jobTitleId: string;
  contractsCount: number;
};

export function JobTitleContracts({
  organizationId,
  jobTitleId,
  contractsCount,
}: JobTitleContractsProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const { mutate, isLoading, isError, data } = useSearchAllContracts();
  const dataGridProps = useDataGrid({
    key: `job-title-contracts-${organizationId}`,
    sorting: { field: 'name', order: 'asc' },
  });

  useEffect(() => {
    if (organizationId === undefined || jobTitleId === undefined) {
      return;
    }
    mutate({
      pathParams: {
        organizationId,
      },
      body: {
        filtering: {
          elements: {
            title: [jobTitleId],
            status: [{ not: contractStatuses.terminated }],
          },
        },
        sorting: [
          {
            field: dataGridProps.sortingProps.field,
            order: dataGridProps.sortingProps.order,
          },
        ],
        pagination: {
          page: dataGridProps.paginationProps.page,
          size: dataGridProps.paginationProps.rowsPerPage,
        },
        ...(searchTerm.length > 0 && {
          query: searchTerm,
        }),
      } as SearchInput,
    });
  }, [
    organizationId,
    jobTitleId,
    searchTerm,
    dataGridProps.sortingProps.field,
    dataGridProps.sortingProps.order,
    dataGridProps.paginationProps.page,
    dataGridProps.paginationProps.rowsPerPage,
  ]);

  return (
    <Box display="flex" height="100%" flexDirection="column" gap={1}>
      <DataGridToolbar
        searchPlaceholder="Procurar"
        hideFilters={true}
        filters={[]}
        searchProps={{ searchTerm, setSearchTerm }}
        filteringProps={dataGridProps.filteringProps}
        totals={
          isLoading || !data
            ? undefined
            : {
                current: data.total,
                all: contractsCount,
              }
        }
      />
      <ContractsTable
        isLoading={isLoading}
        isError={isError}
        data={data}
        dataGridProps={dataGridProps}
      />
    </Box>
  );
}

function ContractsTable({
  isLoading,
  isError,
  data,
  dataGridProps,
}: {
  isLoading: boolean;
  isError: boolean;
  data: ContractList | undefined;
  dataGridProps: DataGridProps;
}) {
  const { paginationProps, sortingProps } = dataGridProps;
  if (isError) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <ErrorAlert
          message="Falha ao carregar contratos, por favor tente novamente mais tarde.
          Se o problema persistir, entre em contato com o suporte da Tako."
        />
      </Box>
    );
  }

  if (isLoading || !data) {
    return (
      <Skeleton variant="rounded" width="100%" height="100%" sx={{ mt: 1 }} />
    );
  }

  return (
    <Box mb={5}>
      <DataGrid
        sortingProps={sortingProps}
        paginationProps={paginationProps}
        totalRowCount={data.total || 0}
        getRowId={(row) => {
          return `${row.contractId}-${row.personId}`;
        }}
        rows={data.data}
        columns={COLUMNS}
        onRowClick={(params) =>
          window.open(`/people/${params.row.contractId}`, '_blank')
        }
      />
    </Box>
  );
}

const COLUMNS: GridColDef<ContractSummary>[] = [
  {
    field: 'name',
    headerName: 'Nome',
    renderHeader: (params) => {
      return <Box ml={2}>{params.field}</Box>;
    },
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.name;
    },
    renderCell: ({ value, row }) => {
      return (
        <UserAvatar
          name={value}
          pictureUrl={row.pictureUrl}
          expandNameOnHover={true}
          sx={{
            '--UserAvatar-name-max-width': '12.5em',
          }}
        />
      );
    },
  },
  departmentColumn(),
];
