import { useContext } from 'react';

import { AppContext } from '../../app/context';
import { DepartmentContext } from '../types';

export function useDepartment(
  departmentId: string | undefined,
): DepartmentContext | undefined {
  const { appContext } = useContext(AppContext);
  if (!departmentId) {
    return undefined;
  }
  return appContext?.company?.departments?.find(
    (department) => department.id === departmentId,
  );
}
