import React, { ReactNode } from 'react';

import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Box, Divider, IconButton, Typography } from '@mui/material';

import { formatDateTimeBR } from '@octopus/formatters';

export type EventTimelineProps<T> = {
  events: T[];
  renderEventContent: (
    event: T,
    index: number,
    allEvents: T[],
  ) => ReactNode | null;
};

export function EventsTimeline<T>({
  events,
  renderEventContent,
}: EventTimelineProps<T>) {
  const timelineItems: ReactNode[] = events
    .map(renderEventContent)
    .filter((item) => !!item);

  if (timelineItems.length === 0) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
        py={5}
      >
        <Typography variant="caption">
          Nenhum evento de histórico encontrado.
        </Typography>
      </Box>
    );
  }

  return (
    <Timeline
      position="right"
      sx={{
        p: 0,
        mt: 0,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {timelineItems.map((item, i) => {
        return (
          <Box key={i}>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  variant={i === 0 ? 'filled' : 'outlined'}
                  sx={{
                    width: '4px',
                    height: '4px',
                    padding: 0,
                    ...(i === 0
                      ? {
                          bgcolor: 'primary.main',
                          borderColor: 'primary.main',
                        }
                      : {
                          borderColor: 'primary.lighter',
                        }),
                  }}
                />
                {i < timelineItems.length - 1 && (
                  <TimelineConnector
                    sx={{
                      bgcolor: 'primary.lighter',
                    }}
                  />
                )}
              </TimelineSeparator>
              <TimelineContent>{item}</TimelineContent>
            </TimelineItem>
          </Box>
        );
      })}
    </Timeline>
  );
}

export type TimelineEventItemChangeUpdate = {
  label: string;
  from: string | null;
  to: string | null;
};

export type TimelineEventItemChangeCreate = {
  label: string;
  value: string;
};

export type TimelineEventItemProps = {
  label: string;
  timestamp: string;
  author: string;
  changes?: (TimelineEventItemChangeCreate | TimelineEventItemChangeUpdate)[];
};

export function TimelineEventItem({
  label,
  timestamp,
  author,
  changes = [],
}: TimelineEventItemProps) {
  const [expanded, setExpanded] = React.useState(false);

  const CreateChangeComponent = ({
    change: { label, value },
  }: {
    change: TimelineEventItemChangeCreate;
  }) => (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Typography variant="caption" color="text.secondary" fontWeight="600">
        {label}
      </Typography>
      <Box display="flex" gap={2}>
        <Typography
          variant="caption"
          color="text.placeholder"
          fontWeight="400"
          width="40px"
        >
          como
        </Typography>
        <Typography variant="caption" fontWeight="700">
          {value ?? '--'}
        </Typography>
      </Box>
    </Box>
  );

  const UpdateChangeComponent = ({
    change: { label, from, to },
  }: {
    change: TimelineEventItemChangeUpdate;
  }) => (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Typography variant="caption" color="text.secondary" fontWeight="600">
        {label}
      </Typography>
      <Box display="flex" gap={2}>
        <Typography
          variant="caption"
          color="text.placeholder"
          fontWeight="400"
          width="40px"
        >
          de
        </Typography>
        <Typography variant="caption" color="text.placeholder">
          {from ?? '--'}
        </Typography>
      </Box>
      <Box display="flex" gap={2}>
        <Typography
          variant="caption"
          color="text.placeholder"
          fontWeight="400"
          width="40px"
        >
          para
        </Typography>
        <Typography variant="caption" fontWeight="700">
          {to ?? '--'}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" justifyContent="space-between" gap={1}>
        <Box display="flex" flexDirection="column">
          <Typography variant="body2">
            <Typography
              variant="body2"
              component="span"
              display="inline"
              fontWeight="700"
            >
              {label}
            </Typography>{' '}
            - {formatDateTimeBR(timestamp)}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Por{' '}
            <Typography
              variant="caption"
              color="text.secondary"
              component="span"
              display="inline"
              fontWeight="700"
            >
              {author}
            </Typography>
          </Typography>
        </Box>
        {changes.length > 0 && (
          <IconButton
            size="small"
            onClick={() => setExpanded((expanded) => !expanded)}
          >
            {expanded ? (
              <IconChevronUp size={16} />
            ) : (
              <IconChevronDown size={16} />
            )}
          </IconButton>
        )}
      </Box>
      {changes.length > 0 && expanded && (
        <Box display="flex" flexDirection="column" gap={1} py={2}>
          {changes.map((change, i) => {
            const content =
              'value' in change ? (
                <CreateChangeComponent change={change} key={i} />
              ) : (
                <UpdateChangeComponent change={change} key={i} />
              );
            return (
              <React.Fragment key={i}>
                <Box
                  display="flex"
                  flexDirection="column"
                  px={2}
                  py={1}
                  gap={1}
                >
                  {content}
                </Box>
                {i < changes.length - 1 && <Divider sx={{ width: '100%' }} />}
              </React.Fragment>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
