import { PeriodGroupsConfigType } from './types';

export function getEmptyMessageForStatus(state: PeriodGroupsConfigType) {
  switch (state) {
    case 'open':
      return 'Não existem folhas abertas no momento.';
    case 'approved':
      return 'Não existem folhas aprovadas no momento.';
    case 'closed':
      return 'Não existem folhas fechadas no momento.';
    default:
      return 'Não existem folhas no momento.';
  }
}

export function getRowSxForStatus(state: PeriodGroupsConfigType) {
  switch (state) {
    case 'approved':
    case 'closed':
      return {
        'td:nth-last-of-type(1)': {
          '*': {
            opacity: 0,
          },
        },
        ':hover': {
          'td:nth-last-of-type(1)': {
            '*': {
              opacity: 1,
            },
          },
        },
      };
    default:
      return {};
  }
}
