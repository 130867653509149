import React from 'react';

import { LoadingButton } from '@mui/lab';

import ProvisionDownloadModal from './ProvisionDownloadModal';

interface ProvisionDownloadProps {
  organizationId: string;
  setError: (error: boolean) => void;
}

export default function ProvisionDownloadButton({
  organizationId,
  setError,
}: ProvisionDownloadProps) {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const label = 'Saldo de Provisões';

  return (
    <div>
      <LoadingButton
        onClick={handleClickOpen}
        loading={loading}
        variant="contained"
      >
        {label}
      </LoadingButton>

      <ProvisionDownloadModal
        organizationId={organizationId}
        label={label}
        states={{
          loading: { state: loading, setState: setLoading },
          error: { state: false, setState: setError },
          open: { state: open, setState: setOpen },
        }}
      />
    </div>
  );
}
