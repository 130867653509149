import React from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Drawer, Typography } from '@mui/material';

import {
  CompanySummary,
  JobTitleEntry,
  JobTitleInput,
  usePostJobTitle,
} from '@octopus/api';
import { AppError } from '@octopus/i18n';

import { mapApiErrors } from '../../../utils';

import { JobTitleForm, sanitizeJobTitleInput } from './JobTitleForm';

export type CreateJobTitleDrawerProps = {
  organizationId: string;
  companies: CompanySummary[] | undefined;
  maxCode: number | undefined;
  open: boolean;
  onSuccess: (entry: JobTitleEntry) => void;
  onClose: () => void;
  requireContractType?: string;
  requireCompany?: string;
};

export function CreateJobTitleDrawer({
  organizationId,
  companies,
  maxCode = 0,
  open,
  onSuccess,
  onClose,
  requireContractType,
  requireCompany,
}: CreateJobTitleDrawerProps) {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      data-testid="create-job-title-drawer"
    >
      <CreateJobTitleContent
        organizationId={organizationId}
        companies={companies}
        maxCode={maxCode}
        onSuccess={onSuccess}
        onClose={onClose}
        requireContractType={requireContractType}
        requireCompany={requireCompany}
      />
    </Drawer>
  );
}

function CreateJobTitleContent({
  organizationId,
  companies,
  maxCode,
  onSuccess,
  onClose,
  requireContractType,
  requireCompany,
}: {
  organizationId: string;
  companies: CompanySummary[] | undefined;
  maxCode: number;
  onSuccess: (entry: JobTitleEntry) => void;
  onClose: () => void;
  requireContractType?: string;
  requireCompany?: string;
}) {
  const [failure, setFailure] = React.useState<string | undefined>(undefined);
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [jobTitleInput, setJobTitleInput] = React.useState<JobTitleInput>({
    code: maxCode + 1,
    name: '',
    contractTypes: ['br:clt', 'br:pj'],
    enabledForCompanies: companies?.map(({ companyId }) => companyId) ?? [],
    occupationCode: '',
    description: '',
    br: {
      cargoDeConfianca: false,
    },
  });

  const { mutate, isLoading } = usePostJobTitle();

  const handleSubmit = () => {
    if (isLoading) {
      return;
    }
    setFailure(undefined);
    setErrors({});
    mutate(
      {
        pathParams: {
          organizationId,
        },
        body: sanitizeJobTitleInput(jobTitleInput, companies),
      },
      {
        onSuccess,
        onError: (err: unknown) => {
          const error = err as AppError;
          if (error.statusCode === 409) {
            setErrors({
              name: 'Já existe um cargo com este nome.',
            });
          } else if (error.statusCode === 400) {
            setErrors(mapApiErrors(error as unknown as AppError));
          } else {
            setFailure(
              'Falha ao criar novo cargo, por favor tente novamente mais tarde.\n' +
                'Se o problema persistir, entre em contato com o suporte da Tako.',
            );
          }
        },
      },
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      pt={5}
      pb={2}
      px={5}
      height="100%"
      width="700px"
      boxSizing="border-box"
      gap={3}
      sx={{
        overflowY: 'overlay',
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight="700">
          Novo cargo
        </Typography>
      </Box>
      <JobTitleForm
        companies={companies}
        form={jobTitleInput}
        setForm={setJobTitleInput}
        failure={failure}
        errors={errors}
        clearError={(key) => {
          setErrors((current) => {
            const { [key]: _, ...rest } = current;
            return rest;
          });
        }}
        disabled={isLoading}
        requireCompany={requireCompany}
        requireContractType={requireContractType}
      />
      <Box
        py={1}
        px={3}
        gap={3}
        boxSizing="border-box"
        display="flex"
        alignItems="center"
        justifyContent="end"
        sx={(theme) => ({
          background: 'rgba(247, 247, 247, 0.90)',
          borderTop: `1px solid ${theme.palette.strokes.light}`,
          backdropFilter: 'blur(4px)',
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: '700px',
        })}
      >
        <Box display="flex" gap={1}>
          <Button
            color="secondary"
            size="large"
            onClick={onClose}
            sx={{ width: '120px' }}
          >
            Cancelar
          </Button>
          <LoadingButton
            color="primaryAlt"
            size="large"
            variant="contained"
            sx={{ width: '120px' }}
            onClick={handleSubmit}
            loading={isLoading}
            data-testid="create-job-title-submit-button"
          >
            Criar
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}
