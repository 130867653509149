import { useCallback, useState } from 'react';

import { TimesheetFileDefinition } from './steps/types';
import {
  getTimesheetFileDefinitionByCompany,
  getTimesheetFileDefinitionsByType,
} from './steps/utils';

const DEFAULT_TIMESHEET_TYPE: TimesheetFileDefinition['type'] =
  'pontotel' as const;

type TimesheetFileDefinitionHook = {
  organizationId: string;
  companyId: string;
};

type TimesheetFileDefinitionResult = {
  fileDefinition: TimesheetFileDefinition | null;
  isLoading: boolean;
  loadTimesheetFileDefinition: () => Promise<TimesheetFileDefinition | null>;
};

export function useTimesheetFileDefinition({
  organizationId,
  companyId,
}: TimesheetFileDefinitionHook): TimesheetFileDefinitionResult {
  const [fileDefinition, setFileDefinition] =
    useState<TimesheetFileDefinition | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadTimesheetFileDefinition =
    useCallback(async (): Promise<TimesheetFileDefinition | null> => {
      try {
        console.time('getTimesheetFileDefinition');

        setIsLoading(true);

        let definitions = await getTimesheetFileDefinitionByCompany(
          organizationId,
          companyId,
        );

        if (!definitions) {
          definitions = getTimesheetFileDefinitionsByType(
            DEFAULT_TIMESHEET_TYPE,
          );
          console.warn(
            'No definitions found for company, using default definitions',
          );
        }

        setFileDefinition(definitions);
        return definitions;
      } catch (error) {
        console.error(error);
        return null;
      } finally {
        setIsLoading(false);
        console.timeEnd('getTimesheetFileDefinition');
      }
    }, [organizationId, companyId]);

  return {
    fileDefinition,
    isLoading,
    loadTimesheetFileDefinition,
  };
}
