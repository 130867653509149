import { FilePreviewImage } from './FilePreviewImage';
import { FilePreviewList } from './FilePreviewList';

const FilePreviewType = {
  none: 'none',
  image: 'image',
  list: 'list',
} as const;

export type FilePreviewType =
  (typeof FilePreviewType)[keyof typeof FilePreviewType];

export function FilePreviewComponent({
  file,
  setFile,
  showDeleteButton,
  filePreviewType,
  isLoading,
  showDeleteConfirmation = true,
  deleteConfirmationMessage,
}: {
  file?: File;
  setFile: (file?: File) => void;
  showDeleteButton: boolean;
  filePreviewType: FilePreviewType;
  isLoading: boolean;
  showDeleteConfirmation?: boolean;
  deleteConfirmationMessage?: string;
}) {
  if (!file) {
    return undefined;
  }

  switch (filePreviewType) {
    case 'image':
      return (
        <FilePreviewImage
          file={file}
          isLoading={isLoading}
          onConfirm={() => setFile(null)}
          showDeleteButton={showDeleteButton}
          showDeleteConfirmation={showDeleteConfirmation}
          deleteConfirmationMessage={deleteConfirmationMessage}
        />
      );
    case 'list':
      return (
        <FilePreviewList
          file={file}
          isLoading={isLoading}
          onConfirm={() => setFile(null)}
          showDeleteButton={showDeleteButton}
          showDeleteConfirmation={showDeleteConfirmation}
          deleteConfirmationMessage={deleteConfirmationMessage}
        />
      );
    default:
      return null;
  }
}
