import { useState } from 'react';

import { Icon } from '@tabler/icons-react';

import { Avatar, Box, Typography, useTheme } from '@mui/material';

export type OrganizationAvaterProps = {
  name: string;
  logo?: string;
  size?: string;
  allowRectangularImage?: boolean;
  AvatarTextProps?: {
    fontSize?: number;
  };
  onClick?: () => void;
  AvatarHoverIcon?: Icon;
};

export function OrganizationAvatar({
  name,
  logo,
  size = '32px',
  allowRectangularImage = false,
  AvatarTextProps,
  onClick,
  AvatarHoverIcon,
}: OrganizationAvaterProps) {
  const [avatarHover, setAvatarHover] = useState(false);
  const theme = useTheme();
  const orgLetter = name ? name.charAt(0).toUpperCase() : 'O';
  const width = allowRectangularImage ? undefined : size;
  const fontSize = AvatarTextProps?.fontSize
    ? AvatarTextProps?.fontSize
    : theme.typography.caption.fontSize;
  if (logo) {
    return (
      <Box
        onClick={onClick}
        onMouseEnter={() => setAvatarHover(true)}
        onMouseLeave={() => setAvatarHover(false)}
        minWidth={width}
        maxWidth={width}
        height={size}
        border="1px solid rgba(237, 237, 237, 1)"
        overflow="hidden"
        borderRadius={1}
        sx={{
          ...(onClick && { cursor: 'pointer' }),
        }}
      >
        {onClick && AvatarHoverIcon && avatarHover && (
          <Box
            height={size}
            minWidth={width}
            maxWidth={width}
            zIndex={25}
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="rgba(97, 97, 97, 0.4)"
            borderRadius={1}
          >
            <AvatarHoverIcon size={25} color="#FFFFFF" stroke={2.5} />
          </Box>
        )}
        <img src={logo} width="100%" height="100%" alt={orgLetter} />
      </Box>
    );
  }
  return (
    <Box
      onClick={onClick}
      onMouseEnter={() => setAvatarHover(true)}
      onMouseLeave={() => setAvatarHover(false)}
      minWidth={width}
      maxWidth={width}
      height={size}
      border="1px solid rgba(237, 237, 237, 1)"
      overflow="hidden"
      borderRadius={1}
      sx={{
        ...(onClick && { cursor: 'pointer' }),
      }}
    >
      {onClick && AvatarHoverIcon && avatarHover && (
        <Box
          height={size}
          minWidth={width}
          maxWidth={width}
          zIndex={25}
          position="absolute"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="rgba(97, 97, 97, 0.4)"
          borderRadius={1}
        >
          <AvatarHoverIcon size={25} color="#FFFFFF" stroke={2.5} />
        </Box>
      )}
      <Avatar
        sx={{
          width: width,
          height: size,
          border: '1px solid rgba(237, 237, 237, 1)',
          ...(onClick && { cursor: 'pointer' }),
        }}
        variant="rounded"
      >
        <Typography variant="caption" sx={{ fontWeight: 'bold', fontSize }}>
          {orgLetter}
        </Typography>
      </Avatar>
    </Box>
  );
}
