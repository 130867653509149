import { ChangeEvent, useEffect, useState } from 'react';

import { maskitoDateOptionsGenerator } from '@maskito/kit';
import { useMaskito } from '@maskito/react';
import { CustomCellEditorProps } from 'ag-grid-react';

import { Box, TextField } from '@mui/material';

import { PayrollInputsPayloadTypes } from '@octopus/api';
import { formatDecimal, formatMoney } from '@octopus/formatters';

import {
  getInputPropsForPayloadType,
  getOptionsForPayloadType,
  parseValueForPayloadType,
} from './utils';

const inputStyles = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: 0,
    '&:hover': {
      boxShadow: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(37, 37, 45, 0.10)',
      },
    },
    '&.Mui-focused': {
      boxShadow: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(37, 37, 45, 0.10)',
        borderRadius: 0,
      },
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '0px solid rgba(37, 37, 45, 0.10)',
    borderRadius: 0,
  },
};

export type InputCellEditorProps = CustomCellEditorProps<unknown, string> & {
  payloadType: PayrollInputsPayloadTypes;
};

export function InputCellEditor(props: InputCellEditorProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
      height="100%"
      boxSizing="border-box"
      overflow="hidden"
      borderRadius={0}
    >
      {renderCellEditor(props.payloadType, props)}
    </Box>
  );
}

function renderCellEditor(
  payloadType: PayrollInputsPayloadTypes,
  props: InputCellEditorProps,
) {
  switch (payloadType) {
    case 'currency':
      return <CurrencyEditor {...props} />;
    case 'number':
      return <NumberEditor {...props} />;
    case 'hours':
      return <HoursEditor {...props} />;
    case 'percentage':
      return <PercentageEditor {...props} />;
    case 'date':
      return <DateEditor {...props} />;
    default:
      return (
        <TextField
          fullWidth
          value={props.value}
          autoFocus
          onChange={({ target: { value } }) => props.onValueChange(value)}
          sx={inputStyles}
        />
      );
  }
}

function CurrencyEditor(props: InputCellEditorProps) {
  const options = getOptionsForPayloadType(props.payloadType);
  const maskito = useMaskito({
    options: options,
  });
  const [value, setValue] = useState(() => {
    if ((options.mask as RegExp).test(props.eventKey)) {
      return props.eventKey;
    }
    if (!props.initialValue) {
      return '';
    }
    return formatMoney(props.initialValue, { withCurrency: false });
  });
  useEffect(
    () =>
      props.onValueChange(parseValueForPayloadType(value, props.payloadType)),
    [value],
  );
  return (
    <TextField
      fullWidth
      value={value}
      autoFocus
      onInput={(event: ChangeEvent<HTMLInputElement>) =>
        setValue(event.target.value)
      }
      InputProps={getInputPropsForPayloadType(props.payloadType)}
      ref={maskito}
      sx={{
        ...inputStyles,
        '& .MuiOutlinedInput-root': {
          ...inputStyles['& .MuiOutlinedInput-root'],
          padding: '0px 0px 0px 12px',
        },
      }}
    />
  );
}

function NumberEditor(props: InputCellEditorProps) {
  const options = getOptionsForPayloadType(props.payloadType);
  const maskito = useMaskito({
    options: options,
  });
  const [value, setValue] = useState(() => {
    if ((options.mask as RegExp).test(props.eventKey)) {
      return props.eventKey;
    }
    if (!props.initialValue) {
      return '';
    }
    return formatMoney(props.initialValue, { withCurrency: false });
  });
  useEffect(
    () =>
      props.onValueChange(parseValueForPayloadType(value, props.payloadType)),
    [value],
  );
  return (
    <TextField
      fullWidth
      value={value}
      autoFocus
      onInput={(event: ChangeEvent<HTMLInputElement>) =>
        setValue(event.target.value)
      }
      InputProps={getInputPropsForPayloadType(props.payloadType)}
      ref={maskito}
      sx={inputStyles}
    />
  );
}

function HoursEditor(props: InputCellEditorProps) {
  const options = getOptionsForPayloadType(props.payloadType);
  const maskito = useMaskito({
    options: options,
  });
  const [value, setValue] = useState(() => {
    if ((options.mask as RegExp).test(props.eventKey)) {
      return props.eventKey;
    }
    if (!props.initialValue) {
      return '';
    }
    const [hours, minutes] = props.initialValue.split(':');
    return `${hours.padStart(2, '0')}:${minutes?.padEnd(2, '0') ?? '00'}`;
  });
  useEffect(() => {
    props.onValueChange(parseValueForPayloadType(value, props.payloadType));
  }, [value]);
  return (
    <TextField
      fullWidth
      value={value}
      autoFocus
      onInput={(event: ChangeEvent<HTMLInputElement>) =>
        setValue(event.target.value)
      }
      InputProps={getInputPropsForPayloadType(props.payloadType)}
      ref={maskito}
      sx={inputStyles}
    />
  );
}

function PercentageEditor(props: InputCellEditorProps) {
  const options = getOptionsForPayloadType(props.payloadType);
  const maskito = useMaskito({
    options: options,
  });
  const [value, setValue] = useState(() => {
    if ((options.mask as RegExp).test(props.eventKey)) {
      return props.eventKey;
    }
    if (!props.initialValue) {
      return '';
    }
    return formatDecimal(Number(props.initialValue) * 100);
  });
  useEffect(
    () =>
      props.onValueChange(parseValueForPayloadType(value, props.payloadType)),
    [value],
  );
  return (
    <TextField
      fullWidth={false}
      value={value}
      autoFocus
      onInput={(event: ChangeEvent<HTMLInputElement>) =>
        setValue(event.target.value)
      }
      InputProps={getInputPropsForPayloadType(props.payloadType)}
      ref={maskito}
      sx={inputStyles}
    />
  );
}

function DateEditor(props: InputCellEditorProps) {
  const [value, setValue] = useState(() => {
    if (props.eventKey && /^\d$/.test(props.eventKey)) {
      return props.eventKey;
    }
    if (!props.initialValue) {
      return '';
    }
    const [year, month, day] = props.initialValue.split('-');
    if (year && month && day) {
      return `${day}/${month}/${year}`;
    }
    return props.initialValue;
  });

  const maskitoOptions = maskitoDateOptionsGenerator({
    mode: 'dd/mm/yyyy',
    separator: '/',
    max: new Date(2100, 11, 31),
  });

  const maskito = useMaskito({
    options: maskitoOptions,
  });

  useEffect(() => {
    props.onValueChange(parseValueForPayloadType(value, props.payloadType));
  }, [value]);

  return (
    <TextField
      value={value}
      autoFocus
      placeholder="dd/mm/aaaa"
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        setValue(event.target.value)
      }
      onInput={(event: ChangeEvent<HTMLInputElement>) =>
        setValue(event.target.value)
      }
      inputProps={{
        ref: maskito,
        maxLength: 10,
      }}
      sx={inputStyles}
    />
  );
}
