import { useMemo, useState } from 'react';

import { Submission } from '@conform-to/react';

import { Box } from '@mui/material';

import { Dependent } from '../new/dependentes/form/types';
import { NewAdmissionDependentForm } from '../new/dependentes/NewAdmissionDependentForm';
import { AdmissionFormState, FormStepDefinition } from '../new/form/types';

type Props = {
  formState: AdmissionFormState;
  stepDefinition: FormStepDefinition;
  onSubmitCallback: (newFormState: AdmissionFormState) => void;
  organizationId: string;
  draftId: string;
  formId?: string;
  dependentId: string;
};

export const AdmissionReviewDependent = ({
  formState,
  stepDefinition,
  onSubmitCallback,
  organizationId,
  draftId,
  formId,
  dependentId,
}: Props) => {
  const onDependenteSubmit = (
    _event: React.FormEvent<HTMLFormElement>,
    formData: Submission<any, string[], any>,
  ) => {
    const currentDependent = formData.payload as Dependent;

    const depList = dependentes || [];

    depList[currentDependentIndex] = {
      ...currentDependent,
      id: dependentes[currentDependentIndex].id,
    };

    const newFormState = {
      ...formState,
      dependentes: [...depList],
    };

    setDependentes(depList);
    onSubmitCallback(newFormState);
  };

  const [dependentes, setDependentes] = useState<Dependent[]>(
    formState?.dependentes,
  );

  const currentDependentIndex = useMemo<number>(
    () => dependentes?.findIndex((dep: Dependent) => dep.id === dependentId),
    [dependentes, dependentId],
  );

  if (!formState || !stepDefinition) {
    return null;
  }

  const currentDependent = formState.dependentes?.[currentDependentIndex];

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          height: `calc(100svh - ${theme.spacing(38)})`,
          overflow: 'scroll',
          '::-webkit-scrollbar': { display: 'none' },
        },
      })}
    >
      <Box sx={{ mt: 2 }}>
        <NewAdmissionDependentForm
          disabled={false}
          isLoading={false}
          formState={formState}
          stepDefinition={stepDefinition}
          currentDependente={currentDependent}
          onSubmit={onDependenteSubmit}
          organizationId={organizationId}
          draftId={draftId}
          formId={`${formId}_${currentDependentIndex}`}
          hideFileComponent={true}
          setCurrentDependent={() => {
            /** only for file updates, not needed here */
          }}
          setForm={() => {
            /** only for new admission, not needed here */
          }}
        />
      </Box>
    </Box>
  );
};
