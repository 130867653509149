import { Box, Typography } from '@mui/material';

import { PixKeyType, pixKeyLabels } from '../../../payrolls/parser';

export function PixChip({ type }: { type: PixKeyType | undefined }) {
  if (!type) {
    return null;
  }

  return (
    <Box
      component="span"
      sx={{
        backgroundColor: '#F7F7F8',
        display: 'inline-block',
        borderRadius: 0.5,
        px: 1,
        py: 0.5,
        ml: 1,
      }}
    >
      <Typography
        variant="caption"
        sx={{
          fontWeight: '500',
          color: '#616161',
        }}
      >
        {pixKeyLabels[type]}
      </Typography>
    </Box>
  );
}
