import React from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  Autocomplete,
  Box,
  ClickAwayListener,
  Divider,
  ListItemButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

export type VirtualizedAutocompleteActionProps = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  Icon?: React.ReactElement;
};

export type VirtualizedAutocompleteProps<T> = {
  value: T;
  onChange: (value: T) => void;
  options: {
    label: string;
    value: T;
  }[];
  disabled?: boolean;
  hasError?: boolean;
  errorMsg?: string;
  nullable?: boolean;
  nullLabel?: string;
  placeholder?: string;
  action?: VirtualizedAutocompleteActionProps;
};

export default function VirtualizedAutocomplete<T>({
  value,
  onChange,
  options,
  disabled,
  hasError,
  errorMsg,
  nullable,
  nullLabel = 'Não selecionado',
  placeholder,
  action,
}: VirtualizedAutocompleteProps<T>) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(hasError);

  const selectedOption =
    options?.find((option) => option.value === value) ?? null;

  const listboxProps: any = {};

  if (action) {
    listboxProps['action'] = {
      ...action,
      onClick: () => {
        setOpen(false);
        setTimeout(() => {
          action.onClick();
        });
      },
    };
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Autocomplete
        value={selectedOption}
        onChange={(_: React.SyntheticEvent, newValue) => {
          setError(false);
          setOpen(false);
          onChange(newValue.value);
        }}
        options={
          nullable ? [{ value: null, label: nullLabel }, ...options] : options
        }
        fullWidth
        renderInput={(params) => (
          <TextField
            placeholder={placeholder}
            disabled={disabled}
            error={error}
            helperText={error ? (errorMsg ?? 'Valor inválido') : ''}
            inputProps={{
              ...(error
                ? {
                    endAdornment: <ErrorOutlineOutlinedIcon color="error" />,
                  }
                : {}),
            }}
            {...params}
          />
        )}
        renderOption={(props, option, state) =>
          [props, option, state.index] as React.ReactNode
        }
        ListboxComponent={VirtualizedListbox}
        ListboxProps={listboxProps}
        onOpen={() => setOpen(true)}
        open={open}
      />
    </ClickAwayListener>
  );
}

const VirtualizedListbox = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement> & {
    action?: VirtualizedAutocompleteActionProps;
  }
>(function ListboxComponent(props, ref) {
  const { children, action, ...other } = props;
  const itemData = children as React.ReactElement<unknown>[];

  const padding = 16;
  const itemCount = itemData.length;
  const itemSize = 36;
  const height = Math.min(8, itemCount) * itemSize + padding;

  return (
    <div ref={ref}>
      {action && (
        <Box display="flex" flexDirection="column" gap={1} mt={1} mb={0.5}>
          <ListItemButton onClick={action.onClick} disabled={action.disabled}>
            <Box display="flex" alignItems="center" gap={1.5}>
              {action.Icon !== undefined && action.Icon}
              <Typography variant="body2" color="primary.main">
                {action.label}
              </Typography>
            </Box>
          </ListItemButton>
          <Divider />
        </Box>
      )}
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          className="test"
          itemData={itemData}
          height={height}
          width="100%"
          outerElementType={OuterElementType}
          itemSize={itemSize}
          overscanCount={15}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);

  return <div ref={ref} {...props} {...outerProps} />;
});

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + 8,
  };

  const { key, ...optionProps } = dataSet[0];
  return (
    <MenuItem
      key={key}
      value={dataSet[1].value}
      {...optionProps}
      style={inlineStyle}
      sx={{
        overflowX: 'hidden',
      }}
    >
      {dataSet[1].label}
    </MenuItem>
  );
}
