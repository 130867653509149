import React, { ReactNode } from 'react';

import { useQuery } from '@tanstack/react-query';

import { Box, Skeleton } from '@mui/material';

import {
  CompanySummary,
  CostCenterInput,
  HistoryActivateEvent,
  HistoryCreateEvent,
  HistoryDeactivateEvent,
  HistoryEvent,
  HistoryUpdateEvent,
  fetchListCostCenterHistoryEvents,
} from '@octopus/api';
import { flattenObject } from '@octopus/commons';

import { QueryResult } from '../../../types';
import { ErrorAlert } from '../../ErrorAlert';
import {
  EventsTimeline,
  TimelineEventItem,
  TimelineEventItemChangeCreate,
  TimelineEventItemChangeUpdate,
} from '../../EventsTimeline';

export type CostCenterHistoryProps = {
  organizationId: string;
  costCenterId: string;
  companies: CompanySummary[] | undefined;
};

export function CostCenterHistory({
  organizationId,
  costCenterId,
  companies,
}: CostCenterHistoryProps) {
  const { isLoading, data, isError } = useRetrieveAllHistoryEvents(
    organizationId,
    costCenterId,
  );

  if (isError) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <ErrorAlert
          message="Falha ao carregar histórico de centro de custo, por favor tente novamente mais tarde.
          Se o problema persistir, entre em contato com o suporte da Tako."
        />
      </Box>
    );
  }

  if (isLoading || !data) {
    return (
      <Skeleton variant="rounded" width="100%" height="100%" sx={{ mt: 1 }} />
    );
  }

  return (
    <Box p={2}>
      <EventsTimeline
        events={data}
        renderEventContent={(event, index, allEvents) =>
          renderCostCenterHistoryEvent(event, index, allEvents, companies)
        }
      />
    </Box>
  );
}

function useRetrieveAllHistoryEvents(
  organizationId: string,
  costCenterId: string,
): QueryResult<HistoryEvent[]> {
  return useQuery({
    queryKey: [
      'fetchListCostCenterHistoryEvents',
      organizationId,
      costCenterId,
    ],
    queryFn: async () => {
      let data: HistoryEvent[] = [];
      let token: string | undefined = undefined;
      do {
        const { events, nextToken } = await fetchListCostCenterHistoryEvents({
          pathParams: {
            organizationId,
            costCenterId,
          },
          ...(token && {
            queryParams: {
              startToken: token,
            },
          }),
        });
        data = [...data, ...events];
        token = nextToken;
      } while (token !== undefined);
      return data;
    },
    enabled: !!organizationId && !!costCenterId,
  });
}

function renderCostCenterHistoryEvent(
  event: HistoryEvent,
  index: number,
  allEvents: HistoryEvent[],
  companies: CompanySummary[] | undefined,
): ReactNode | null {
  switch (event.type) {
    case 'create':
      return <CreateEvent event={event} companies={companies} />;
    case 'update':
      return (
        <UpdateEvent
          event={event}
          index={index}
          allEvents={allEvents}
          companies={companies}
        />
      );
    case 'activate':
      return <ActivateEvent event={event} />;
    case 'deactivate':
      return <DeactivateEvent event={event} />;
    default:
      return null;
  }
}

function CreateEvent({
  event,
  companies,
}: {
  event: HistoryCreateEvent;
  companies: CompanySummary[] | undefined;
}) {
  const { author, timestamp, payload } = event;

  const costCenterInput = payload as CostCenterInput;
  const changes: TimelineEventItemChangeCreate[] = [
    {
      label: 'Código',
      value: `${costCenterInput.code}`,
    },
    {
      label: 'Nome',
      value: costCenterInput.name,
    },
    ...(companies?.length > 1
      ? [
          {
            label: 'Quais empresas podem usar este centro de custo',
            value: (() => {
              if (
                !costCenterInput.enabledForCompanies ||
                costCenterInput.enabledForCompanies.length === companies?.length
              ) {
                return 'Todas';
              }
              return costCenterInput.enabledForCompanies
                .map(
                  (company) =>
                    companies?.find(({ companyId }) => companyId === company)
                      ?.name ?? company,
                )
                .join(', ');
            })(),
          },
        ]
      : []),
    {
      label: 'Tipo do centro de custo',
      value:
        costCenterInput?.costCenterType === 'expense' ? 'Despesa' : 'Custo',
    },
    ...(costCenterInput.description
      ? [
          {
            label: 'Descrição',
            value: costCenterInput.description,
          },
        ]
      : []),
  ];

  return (
    <TimelineEventItem
      label="Centro de custo criado em"
      timestamp={timestamp}
      author={author}
      changes={changes}
    />
  );
}

function UpdateEvent({
  event,
  index,
  allEvents,
  companies,
}: {
  event: HistoryUpdateEvent;
  index: number;
  allEvents: HistoryEvent[];
  companies: CompanySummary[] | undefined;
}) {
  const { author, timestamp, payload } = event;

  const current = flattenObject(payload);
  const previous = projectCostCenterToIndex(index, allEvents);

  function prepareChange(
    label: string,
    key: string,
    formatter: (val: string | string[] | null) => string | null = (val) =>
      val as string | null,
  ): TimelineEventItemChangeUpdate[] {
    if (current[key] === undefined) {
      return [];
    }
    return [
      {
        label,
        from: formatter(previous[key]),
        to: formatter(current[key]),
      },
    ];
  }

  const changes: TimelineEventItemChangeUpdate[] = [
    ...prepareChange('Código', 'code'),
    ...prepareChange('Nome', 'name'),
    ...(companies?.length > 1
      ? prepareChange(
          'Quais empresas podem usar este centro de custo',
          'enabledForCompanies',
          (val) => {
            const enabledForCompanies = val as string[] | null;
            if (!val || val.length === companies?.length) {
              return 'Todas';
            }
            return enabledForCompanies
              .map(
                (company) =>
                  companies?.find(({ companyId }) => companyId === company)
                    ?.name ?? company,
              )
              .join(', ');
          },
        )
      : []),
    ...prepareChange('Tipo do centro de custo', 'costCenterType', (val) =>
      val === 'expense' ? 'Despesa' : 'Custo',
    ),
    ...prepareChange('Descrição', 'description'),
  ];

  return (
    <TimelineEventItem
      label="Centro de custo atualizado em"
      timestamp={timestamp}
      author={author}
      changes={changes}
    />
  );
}

function ActivateEvent({ event }: { event: HistoryActivateEvent }) {
  const { author, timestamp } = event;
  return (
    <TimelineEventItem
      label="Centro de custo ativado em"
      timestamp={timestamp}
      author={author}
    />
  );
}

function DeactivateEvent({ event }: { event: HistoryDeactivateEvent }) {
  const { author, timestamp } = event;
  return (
    <TimelineEventItem
      label="Centro de custo desativado em"
      timestamp={timestamp}
      author={author}
    />
  );
}

function projectCostCenterToIndex(
  index: number,
  allEvents: HistoryEvent[],
): Record<string, string | string[] | null> {
  const eventsToApply = allEvents.slice(index + 1);
  return eventsToApply.reverse().reduce(
    (projection, event) => {
      if ('payload' in event) {
        return {
          ...projection,
          ...flattenObject(event['payload'] ?? {}),
        };
      }
      return projection;
    },
    {} as Record<string, string | string[] | null>,
  );
}
