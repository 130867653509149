import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import { WorkerCategory, workerCategoryLabels } from '@octopus/contract-types';
import { UI_TYPE, nonEmptyString } from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { CompanyContext } from '../../../../../modules/types';
import { TFFlags } from '../../../../fflags';
import {
  getOptionsFromCompanyContext,
  getOptionsFromList,
} from '../inputUtils';
import {
  AdmissionFormState,
  FormStepDefinition,
  getEnabledWorkerCategories,
} from '../types';

import { getCompanySchema } from './companySchema';

export const modalidadeWorkerCategoryLabels = {
  ...workerCategoryLabels,
  'clt:jovemAprendiz': 'Aprendiz - Contratação direta',
} as Record<WorkerCategory, string>;

const getFieldDefinition = ({
  companyContext,
  formState,
  fflags,
}: {
  companyContext: CompanyContext;
  formState: AdmissionFormState;
  fflags: TFFlags;
}) => {
  const { workerCategory, admissionType, company } = formState;

  const ENABLED_WORKER_CATEGORIES = getEnabledWorkerCategories(fflags);

  const fields = [
    {
      label: 'Qual a modalidade de contratação',
      type: workerCategory != null ? z.string().optional() : nonEmptyString,
      name: 'workerCategory',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione a modalidade',
      disabled: workerCategory != null,
      options: getOptionsFromList({
        list: Object.entries(modalidadeWorkerCategoryLabels).reduce(
          (acc, [key, value]) => ({ ...acc, [key]: value }),
          {},
        ),
        currentValue: workerCategory,
        enabledKeys: ENABLED_WORKER_CATEGORIES,
      }),
    },
    {
      label: 'Qual será a empresa empregadora',
      type: getCompanySchema(companyContext?.companies, companyContext),
      name: 'company',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione a empresa',
      fieldsOptions: getOptionsFromCompanyContext({
        baseContext: companyContext?.companies,
        currentValue: company?.companyId,
        selectIfOnlyOne: true,
      }).map((companyOption) => {
        const legalEntities = companyContext?.legalEntities.filter(
          (le) => le.summary.companyId === companyOption.value,
        );

        const currentValue =
          legalEntities.find(
            (le) => le.summary.legalEntityId === company?.legalEntity,
          )?.id ?? '';

        return {
          ...companyOption,
          fields: [
            {
              label: '',
              type: z.string().optional(),
              name: `companyId_${companyOption.value}`,
              uiType: UI_TYPE.TEXT,
              value: companyOption.value,
              hidden: true,
            },
            {
              label: 'Qual o local de serviço',
              type: z.string().optional(),
              name: 'legalEntity',
              uiType: UI_TYPE.SELECT,
              placeholder: 'Selecione o local de serviço',
              options: getOptionsFromCompanyContext({
                baseContext: legalEntities,
                currentValue,
                selectIfOnlyOne: true,
              }),
            },
          ],
        };
      }),
    },
    {
      label: 'Tipo de admissão',
      type: admissionType != null ? z.string().optional() : nonEmptyString,
      name: 'admissionType',
      disabled: admissionType != null,
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione o tipo de admissão',
      options: [
        {
          value: 'admin_fills',
          selected: admissionType != null && admissionType === 'admin_fills',
          label: 'Preencher os dados',
        },
        {
          value: 'send_invite',
          selected: admissionType === 'send_invite',
          label: 'Envio de convite',
        },
      ],
    },
  ];
  return fields;
};
export const getModalidadeContratoFields = ({
  companyContext,
  formState,
  modalidadeFormSteps,
  fflags,
}: {
  companyContext: CompanyContext;
  formState: AdmissionFormState;
  fflags: TFFlags;
  modalidadeFormSteps: AdmissionDraftInputFormStep;
}): FormStepDefinition => ({
  definition: getFieldDefinition({
    companyContext,
    formState,
    fflags,
  }),
  options: {
    id: admissionDraftFormSteps.modalidade_contrato,
    title: 'Modalidade e local de serviço',
    completed: modalidadeFormSteps?.completed,
    reviewed: modalidadeFormSteps?.reviewed,
    hideLeftMenu: formState.company?.legalEntity == null,
  },
});
