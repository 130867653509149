import { useState } from 'react';

import { MutationKey, useMutation } from '@tanstack/react-query';

export type PollingOptions = {
  interval: number;
  maxAttempts: number;
};
export const pollUntil = async (
  options: PollingOptions,
  action: () => Promise<boolean>,
): Promise<boolean> => {
  const limit = options.maxAttempts || Number.POSITIVE_INFINITY; // 0 === try forever
  const lastAttempt = limit - 1;
  for (let i = 0; i < limit; i++) {
    const stop = await action();
    if (stop) return true;
    if (i !== lastAttempt) await wait(options.interval);
  }
  // will stop polling without error to stop progress bar
  return false;
};

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const usePollMutation = <Res = unknown, Err = unknown>({
  mutationFn,
  mutationKey,
}: {
  mutationFn: () => Promise<Res>;
  mutationKey: MutationKey;
}) => {
  // for some bewildering unintuitive reason the `isLoading` from the mutation always returns false
  // regardless of any async operations executed after the first fetch (even hardcoded timeouts)
  // so we need to manually set the loading state
  const [isLoading, setIsLoading] = useState(false);

  const mutation = useMutation<Res, Err, void>({
    mutationKey,
    mutationFn: async () => {
      setIsLoading(true);
      return mutationFn().finally(() => {
        // clear loading state regardless of error or success
        setIsLoading(false);
      });
    },
  });

  // we need to set the loading state to `true` once the mutation is called
  const mutate = () => {
    setIsLoading(true);
    mutation.mutate();
  };

  return { ...mutation, mutate, isLoading };
};
