import React, { useRef, useState } from 'react';

import { IconAlertTriangleFilled } from '@tabler/icons-react';

import { Checkbox, Popper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColumnHeaderParams } from '@mui/x-data-grid';

import { PendingAccessContractSummary, SearchInput } from '@octopus/api';
import { isSubsetOf } from '@octopus/commons';
import { workerCategory, workerCategoryLabels } from '@octopus/contract-types';
import {
  FilterOptions,
  GridColDef,
  GridValueGetterParams,
  makeElementListFilter,
} from '@octopus/ui/data-grid';

import WorkerCategoryTag from '../../../../modules/components/contracts/WorkerCategoryTag';
import UserAvatar from '../../../../modules/components/UserAvatar';
import { useDepartment } from '../../../../modules/hooks/useDepartment';
import { IAppContext } from '../../../../modules/types';
import { UserTextTooltip } from '../tooltip';

import { PendingAccessContractSummaryLinkCard } from './card';

const searchFilters = (appContext: IAppContext): FilterOptions => [
  makeElementListFilter({
    label: 'Empresa',
    propertyToFilter: 'companyId',
    elements: appContext.company?.companies?.map(({ id }) => id),
    labels: appContext.company?.companies?.reduce(
      (acc, { id, name }) => Object.assign(acc, { [id]: name }),
      {},
    ),
  }),
  makeElementListFilter({
    label: 'Modalidade',
    propertyToFilter: 'workerCategory',
    elements: Object.values(workerCategory),
    labels: Object.values(workerCategory).reduce(
      (acc, category) =>
        Object.assign(acc, {
          [category]: workerCategoryLabels[category],
        }),
      {},
    ),
  }),
];

function SelectRow({
  contract,
  checked,
  onSelect,
}: {
  contract: PendingAccessContractSummary;
  checked: boolean;
  onSelect: (contract: PendingAccessContractSummary, selected: boolean) => void;
}) {
  const disabled = !contract.email;

  const checkbox = (
    <Checkbox
      checked={checked && !disabled}
      disabled={disabled}
      onChange={(e) => {
        onSelect(contract, e.target.checked);
      }}
    />
  );

  return disabled ? (
    <UserTextTooltip
      title="Não é possível selecionar contratos com pendências"
      placement="top"
    >
      <Box>{checkbox}</Box>
    </UserTextTooltip>
  ) : (
    checkbox
  );
}

function ContractNameColumn({
  appContext,
  contract,
}: {
  contract: PendingAccessContractSummary;
  appContext: IAppContext;
}) {
  const [cardOpen, setCardOpen] = useState<boolean>(false);
  const department = useDepartment(contract.departmentId);
  const popoverAnchor = useRef(null);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        gap={0.5}
        pl={0.5}
      >
        <UserAvatar
          zIndex={0}
          name={contract.name}
          showFullName={false}
          pictureUrl={contract.pictureUrl}
          expandNameOnHover={false}
          avatarProps={{ ml: 0 }}
        />
        <Box display="flex" flexDirection="column">
          <Typography
            color="textPrimary"
            variant="body2"
            sx={{
              '&:hover': { textDecoration: 'underline', cursor: 'pointer' },
            }}
            ref={popoverAnchor}
            onMouseEnter={() => setCardOpen(true)}
            onMouseLeave={() => setCardOpen(false)}
          >
            {contract.name}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            {department?.name}
          </Typography>
        </Box>
      </Box>
      <Popper
        open={cardOpen}
        anchorEl={popoverAnchor.current}
        placement="right-start"
        sx={{ zIndex: 1300 }}
        onMouseEnter={() => setCardOpen(true)}
        onMouseLeave={() => setCardOpen(false)}
      >
        <Box
          sx={{
            p: 1,
            bgcolor: 'background.paper',
            boxShadow: 1,
            borderRadius: 1.5,
          }}
        >
          <PendingAccessContractSummaryLinkCard
            contract={contract}
            appContext={appContext}
          />
        </Box>
      </Popper>
    </>
  );
}

const getBaseColumns = (appContext: IAppContext): GridColDef[] => [
  {
    field: 'name.keyword',
    headerName: 'Pessoa',
    sortable: true,
    flex: 1,

    valueGetter: (params: GridValueGetterParams) => (
      <ContractNameColumn
        contract={params.row as PendingAccessContractSummary}
        appContext={appContext}
      />
    ),
  },
  {
    field: 'workerId',
    headerName: 'Matrícula',
    sortable: true,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.workerId,
  },
  {
    field: 'workerCategory',
    headerName: 'Modalidade',
    flex: 1,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => (
      <WorkerCategoryTag workerCategory={params.row.workerCategory} />
    ),
  },
  {
    field: 'companyId',
    headerName: 'Empresa',
    flex: 1,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) =>
      appContext.company?.companies?.find(
        ({ id }) => id === params.row.companyId,
      )?.name,
  },
  {
    field: 'email',
    headerName: 'Email corporativo',
    flex: 1,
    sortable: true,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.email ? (
        params.row.email
      ) : (
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <Typography
            variant="body2"
            sx={(theme) => ({
              color: theme.palette.strokes.heavy,
            })}
          >
            Não informado
          </Typography>
          <UserTextTooltip
            title="Contrato sem email corporativo"
            placement="top"
          >
            <IconAlertTriangleFilled className="small error" />
          </UserTextTooltip>
        </Box>
      ),
  },
];

type UseColumnsHookProps = {
  appContext: IAppContext;
  contractSelection?: {
    currentTab: PendingAccessContractTabKey;
    selectedContracts: Set<string>;
    pageSelectableContracts: Set<string>;
    selectContract: (
      contract: PendingAccessContractSummary,
      selected: boolean,
    ) => void;
    selectAllInPage: (selected: boolean) => void;
  };
};
type UseColumnsHook = {
  columns: GridColDef[];
};
function useColumns({
  appContext,
  contractSelection,
}: UseColumnsHookProps): UseColumnsHook {
  const columns = getBaseColumns(appContext);

  if (!contractSelection) {
    return { columns };
  }

  const checkboxColumn: GridColDef = {
    field: 'checkbox',
    headerName: '',
    sortable: false,
    flex: 1,
    width: 0,
    renderHeader: (_params: GridColumnHeaderParams) => {
      const disabled = contractSelection.pageSelectableContracts.size <= 0;
      const tooltip = disabled
        ? 'Não é possível selecionar contratos com pendências'
        : 'Selecionar todos nesta página';

      return (
        <UserTextTooltip title={tooltip} placement="top">
          <Box>
            <Checkbox
              disabled={disabled}
              checked={
                contractSelection.currentTab === 'valid' &&
                isSubsetOf(
                  contractSelection.pageSelectableContracts,
                  contractSelection.selectedContracts,
                )
              }
              onChange={(e) =>
                contractSelection.selectAllInPage(e.target.checked)
              }
            />
          </Box>
        </UserTextTooltip>
      );
    },
    valueGetter: (params: GridValueGetterParams) => (
      <SelectRow
        contract={params.row as PendingAccessContractSummary}
        checked={contractSelection.selectedContracts.has(params.row.contractId)}
        onSelect={contractSelection.selectContract}
      />
    ),
  };

  return {
    columns: [checkboxColumn, ...columns],
  };
}

export type PendingAccessContractTab = {
  key: 'valid' | 'invalid';
  color: 'primary' | 'secondary' | 'info' | 'warning' | 'error';
  label: string;
  filtering: SearchInput['filtering'];
};
export type PendingAccessContractTabKey = PendingAccessContractTab['key'];

const tabs: Record<PendingAccessContractTab['key'], PendingAccessContractTab> =
  {
    valid: {
      key: 'valid',
      color: 'primary',
      label: 'Contratos válidos',
      filtering: {
        elements: {
          email: [{ empty: false }],
        },
      },
    },
    invalid: {
      key: 'invalid',
      color: 'error',
      label: 'Contratos com pendências',
      filtering: {
        elements: {
          email: [{ empty: true }],
        },
      },
    },
  };

export const PendingAccessContractsTableComponents = {
  searchFilters,
  useColumns,
  tabs,
};
