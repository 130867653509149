import React, { useEffect, useState } from 'react';

import { Box, Skeleton } from '@mui/material';

import {
  CompanySummary,
  DepartmentEntry,
  useGetDepartment,
} from '@octopus/api';

import { ErrorAlert } from '../../ErrorAlert';

import { DepartmentData } from './DepartmentData';
import { DepartmentEdit } from './DepartmentEdit';

export type DepartmentDetailsProps = {
  organizationId: string;
  departmentId: string;
  companies: CompanySummary[] | undefined;
  onEdit: (updatedEntry: DepartmentEntry) => void;
  onArchive: (departmentId: string) => void;
  onRestore: (departmentId: string) => void;
};

export function Department({
  organizationId,
  departmentId,
  companies,
  onEdit,
  onArchive,
  onRestore,
}: DepartmentDetailsProps) {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsEditing(false);
  }, [organizationId, departmentId]);

  const { isLoading, isError, data, remove } = useGetDepartment(
    {
      pathParams: {
        organizationId,
        departmentId,
      },
    },
    {
      enabled: !!organizationId && !!departmentId,
    },
  );

  if (isLoading) {
    return <Skeleton variant="rounded" width="100%" height="100%" />;
  }

  if (isError) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <ErrorAlert
          message="Falha ao carregar departamento, por favor tente novamente mais tarde.
          Se o problema persistir, entre em contato com o suporte da Tako."
        />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      {!isEditing ? (
        <DepartmentData
          data={data}
          companies={companies}
          startEditing={() => setIsEditing(true)}
        />
      ) : (
        <DepartmentEdit
          department={data}
          companies={companies}
          onEdit={(department) => {
            onEdit(department);
            remove();
          }}
          onArchive={(departmentId) => {
            onArchive(departmentId);
            remove();
          }}
          onRestore={(departmentId) => {
            onRestore(departmentId);
            remove();
          }}
          stopEditing={() => setIsEditing(false)}
        />
      )}
    </Box>
  );
}
