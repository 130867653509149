import React from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Drawer, Typography } from '@mui/material';

import {
  CompanySummary,
  DepartmentEntry,
  DepartmentInput,
  usePostDepartment,
} from '@octopus/api';
import { AppError } from '@octopus/i18n';

import { mapApiErrors } from '../../../utils';

import { DepartmentForm, sanitizeDepartmentInput } from './DepartmentForm';

export type CreateDepartmentDrawerProps = {
  organizationId: string;
  companies: CompanySummary[] | undefined;
  open: boolean;
  onSuccess: (entry: DepartmentEntry) => void;
  onClose: () => void;
  requireCompany?: string;
};

export function CreateDepartmentDrawer({
  organizationId,
  companies,
  open,
  onSuccess,
  onClose,
  requireCompany,
}: CreateDepartmentDrawerProps) {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      data-testid="create-department-drawer"
    >
      <CreateDepartmentContent
        organizationId={organizationId}
        companies={companies}
        onSuccess={onSuccess}
        onClose={onClose}
        requireCompany={requireCompany}
      />
    </Drawer>
  );
}

function CreateDepartmentContent({
  organizationId,
  companies,
  onSuccess,
  onClose,
  requireCompany,
}: {
  organizationId: string;
  companies: CompanySummary[] | undefined;
  onSuccess: (entry: DepartmentEntry) => void;
  onClose: () => void;
  requireCompany?: string;
}) {
  const [failure, setFailure] = React.useState<string | undefined>(undefined);
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [departmentInput, setDepartmentInput] = React.useState<DepartmentInput>(
    {
      code: '',
      name: '',
      enabledForCompanies: companies?.map(({ companyId }) => companyId) ?? [],
      description: '',
    },
  );

  const { mutate, isLoading } = usePostDepartment();

  const handleSubmit = () => {
    if (isLoading) {
      return;
    }
    setFailure(undefined);
    setErrors({});
    mutate(
      {
        pathParams: {
          organizationId,
        },
        body: sanitizeDepartmentInput(departmentInput, companies),
      },
      {
        onSuccess,
        onError: (err: unknown) => {
          const error = err as AppError;
          if (error.statusCode === 409) {
            setErrors({
              name: 'Já existe um departamento com este nome.',
            });
          } else if (error.statusCode === 400) {
            setErrors(mapApiErrors(error as unknown as AppError));
          } else {
            setFailure(
              'Falha ao criar novo departamento, por favor tente novamente mais tarde.\n' +
                'Se o problema persistir, entre em contato com o suporte da Tako.',
            );
          }
        },
      },
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      pt={5}
      pb={2}
      px={5}
      height="100%"
      width="700px"
      boxSizing="border-box"
      gap={3}
      sx={{
        overflowY: 'overlay',
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight="700">
          Novo departamento
        </Typography>
      </Box>
      <DepartmentForm
        companies={companies}
        form={departmentInput}
        setForm={setDepartmentInput}
        failure={failure}
        errors={errors}
        clearError={(key) => {
          setErrors((current) => {
            const { [key]: _, ...rest } = current;
            return rest;
          });
        }}
        disabled={isLoading}
        requireCompany={requireCompany}
      />
      <Box
        py={1}
        px={3}
        gap={3}
        boxSizing="border-box"
        display="flex"
        alignItems="center"
        justifyContent="end"
        sx={(theme) => ({
          background: 'rgba(247, 247, 247, 0.90)',
          borderTop: `1px solid ${theme.palette.strokes.light}`,
          backdropFilter: 'blur(4px)',
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: '700px',
        })}
      >
        <Box display="flex" gap={1}>
          <Button
            color="secondary"
            size="large"
            onClick={onClose}
            sx={{ width: '120px' }}
          >
            Cancelar
          </Button>
          <LoadingButton
            color="primaryAlt"
            size="large"
            variant="contained"
            sx={{ width: '120px' }}
            onClick={handleSubmit}
            loading={isLoading}
            data-testid="create-department-submit-button"
          >
            Criar
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}
