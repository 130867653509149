import {
  Dialog,
  DialogContent,
  DialogTitle,
  Skeleton,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

import { OctopusLoading } from '@octopus/ui/design-system';

type DialogContentLoadingProps = {
  message: string;
};
export function DialogContentLoading({ message }: DialogContentLoadingProps) {
  return (
    <DialogContent>
      <Box
        py={10}
        px={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box width="100px">
          <OctopusLoading />
        </Box>
        <Box pt={2}>
          <Typography variant="h4" textAlign="center">
            {message}
          </Typography>
        </Box>
      </Box>
    </DialogContent>
  );
}

type DialogFullLoadingProps = {
  isOpen: boolean;
  close: () => void;
};
export function DialogFullLoading({ isOpen, close }: DialogFullLoadingProps) {
  return (
    <Dialog
      PaperProps={{ sx: { minWidth: '600px' } }}
      open={isOpen}
      onClose={close}
    >
      <DialogTitle>
        <Skeleton variant="rounded" height={50} />
      </DialogTitle>
      <DialogContent>
        <Skeleton variant="rounded" height={200} />
      </DialogContent>
    </Dialog>
  );
}
