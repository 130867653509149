import { MaskitoMask } from '@maskito/core';
import { z } from 'zod';

import {
  collectionTypes,
  primitiveTypes,
} from './formFieldDefinitionValueType';
import { UI_TYPE } from './UI_TYPE';
import { IUiType } from './uiType';

export const AUTO_COMPLETE_OPTION_VALUE = Symbol.for('autocomplete');

export type ISerializedFormDefinition = ISerializedFormEntryDefinition[];
export type IFormDefinition = IFormDefinitionEntry[];

export type ISerializedFormEntryDefinition = z.infer<
  typeof serializedFormDefinitionEntrySchema
>;
export type IFormDefinitionEntry = z.infer<typeof formDefinitionEntrySchema>;

export type ISerializedFieldDefinition = z.infer<typeof serializedFieldSchema>;
export type IFieldAnyDefinition = z.infer<typeof fieldSchema>;

export type IFieldSingleValueWithoutOptionsDefinition = z.infer<
  typeof withoutOptionsSingleValueFieldSchema
>;

export type IFieldSingleValueWithOptionsDefinition = z.infer<
  typeof withOptionsSingleValueFieldSchema
>;

export type IFieldCollectionValueWithOptionsDefinition = z.infer<
  typeof withOptionsCollectionValueFieldSchema
>;
export type IFieldCollectionValueWithoutOptionsDefinition = z.infer<
  typeof withoutOptionsCollectionValueFieldSchema
>;

export type ISerializedFieldNamedDefinition = Pick<
  z.infer<typeof serializedFieldSchema>,
  'name'
>;
export type IFieldNamedDefinition = Pick<z.infer<typeof fieldSchema>, 'name'>;

export type ISerializedFieldTypedDefinition = Pick<
  z.infer<typeof serializedFieldSchema>,
  'type'
>;
export type IFieldTypedDefinition = Pick<z.infer<typeof fieldSchema>, 'type'>;

export type ISerializedFieldValueOptionDefinition = z.infer<
  typeof valueOptionSchema
>;
export type IFieldValueOptionDefinition = z.infer<typeof valueOptionSchema>;

export type ISerializedFieldsetDefinition = z.infer<
  typeof baseSerializedFieldsetSchema
> & {
  fields: ISerializedFormEntryDefinition[];
};
export type IFieldsetDefinition = z.infer<typeof baseFieldsetSchema> & {
  fields: IFormDefinitionEntry[];
};

export type ISerializedFieldsetBranchingDefinition = z.infer<
  typeof baseSerializedFieldsetBranchingSchema
> & {
  fieldsOptions: ISerializedFieldsetBranchingOptionDefinition[];
};
export type IFieldsetBranchingDefinition = z.infer<
  typeof baseFieldsetBranchingSchema
> & {
  fieldsOptions: IFieldsetBranchingOptionDefinition[];
};

export type ISerializedFieldsetBranchingOptionDefinition = z.infer<
  typeof serializedFieldsetBranchingOptionSchema
>;
export type IFieldsetBranchingOptionDefinition = z.infer<
  typeof fieldsetBranchingOptionSchema
>;

type IJson = { [key: string]: IJsonChild };
type IJsonChild =
  | z.infer<typeof jsonLiteralValueSchema>
  | { [key: string]: IJsonChild }
  | IJsonChild[];

const jsonLiteralValueSchema = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.null(),
]);
const childJsonSchema: z.ZodType<IJsonChild> = z.lazy(() =>
  z.union([
    jsonLiteralValueSchema,
    z.array(childJsonSchema),
    z.record(childJsonSchema),
  ]),
);
const jsonSchema: z.ZodType<IJson> = z.record(childJsonSchema);

function lazyFormEntryDefSchema() {
  return z.array(formDefinitionEntrySchema);
}

function lazySerializedFormEntryDefSchema() {
  return z.array(serializedFormDefinitionEntrySchema);
}

const valueOptionSchema = z.object({
  label: z.string(),
  subLabel: z.string().optional(),
  name: z.string().optional(),
  groupLabel: z.string().optional(),
  value: z.union([
    z.string(),
    z.number(),
    z.array(z.string()),
    z.array(z.number()),
    z.null(),
    z.symbol(),
    z.boolean(),
  ]),
  selected: z.boolean().optional(),
  required: z.boolean().optional(),
  disabled: z.boolean().optional(),
});

const serializedTypeShape = {
  type: jsonSchema,
};

const singleTypeShape = {
  type: primitiveTypes,
};

const collectionTypeShape = {
  type: collectionTypes,
};

const withOptionsShape = {
  options: z.array(valueOptionSchema),
};

/**
 * for now it only checks `string | Array<string | RegExp>`
 * not all possibilities for `MaskitoMask` (ignores `((elementState: ElementState) => MaskitoMaskExpression)`).
 * since we only use the aforementioned types, it works.
 */
const maskito = z.custom<MaskitoMask>(
  (val) =>
    typeof val === 'string' ||
    (Array.isArray(val) &&
      val.every((v) => v instanceof RegExp || typeof v === 'string')),
);

const baseFieldShape = {
  disabled: z.boolean().optional(),
  label: z.string(),
  subLabel: z.string().optional(),
  name: z.string(),
  mask: z.string().optional().or(maskito.optional()),
  value: z.union([z.string(), z.number(), z.array(z.string())]).optional(),
  placeholder: z.string().optional(),
  disclaimerFromSelection: z
    .function()
    .args(z.string())
    .returns(z.string())
    .optional(),
  info: z.string().optional(),
  hint: z
    .object({ title: z.any().optional(), body: z.string().optional() })
    .optional(),
  hidden: z.boolean().optional(),
  errors: z.array(z.string()).optional(),
  sx: z.object({}).optional(),
  uiType: z
    .enum([...Object.values(UI_TYPE)] as unknown as readonly [
      IUiType,
      ...IUiType[],
    ])
    .optional(),
  extraProps: z.object({}).optional(),
};

const withoutOptionsSingleValueFieldSchema = z
  .object({
    ...baseFieldShape,
    ...singleTypeShape,
  })
  .strict();

const withOptionsSingleValueFieldSchema = z
  .object({
    ...baseFieldShape,
    ...singleTypeShape,
    ...withOptionsShape,
  })
  .strict();

const withoutOptionsCollectionValueFieldSchema = z
  .object({
    ...baseFieldShape,
    ...collectionTypeShape,
  })
  .strict();

const withOptionsCollectionValueFieldSchema = z
  .object({
    ...baseFieldShape,
    ...collectionTypeShape,
    ...withOptionsShape,
  })
  .strict();

const fieldSchemas = [
  withoutOptionsSingleValueFieldSchema,
  withoutOptionsCollectionValueFieldSchema,
  withOptionsSingleValueFieldSchema,
  withOptionsCollectionValueFieldSchema,
] as const;

const serializedFieldSchemas = [
  z
    .object({
      ...baseFieldShape,
      ...serializedTypeShape,
    })
    .strict(),
  z
    .object({
      ...baseFieldShape,
      ...serializedTypeShape,
      ...withOptionsShape,
    })
    .strict(),
] as const;

const fieldSchema = z.union(fieldSchemas);
const serializedFieldSchema = z.union(serializedFieldSchemas);

const baseFieldsetSchema = z.object({
  label: z.string(),
  name: z.string().optional(),
  fieldsetInline: z.boolean().optional(),
});

const fieldsetSchema: z.ZodType<IFieldsetDefinition> =
  baseFieldsetSchema.extend({
    fields: z.lazy(() => formDefinitionEntrySchema.array()),
  }) as z.ZodType<IFieldsetDefinition>;

const baseSerializedFieldsetSchema = z.object({
  label: z.string(),
  name: z.string().optional(),
});

const serializedFieldsetSchema: z.ZodType<ISerializedFieldsetDefinition> =
  baseSerializedFieldsetSchema.extend({
    fields: z.lazy(lazySerializedFormEntryDefSchema),
  }) as z.ZodType<ISerializedFieldsetDefinition>;

const fieldsetBranchingOptionSchema = z.object({
  label: z.string(),
  fields: z.lazy(lazyFormEntryDefSchema),
  selected: z.boolean().optional(),
});

const serializedFieldsetBranchingOptionSchema = z.object({
  label: z.string(),
  fields: z.lazy(lazySerializedFormEntryDefSchema),
  selected: z.boolean().optional(),
});

const baseFieldsetBranchingSchema = z.object({
  label: z.string(),
  value: z.union([z.string(), z.number(), z.array(z.string())]).optional(),
  placeholder: z.string().optional(),
  info: z.string().optional(),
  hint: z
    .object({ title: z.any().optional(), body: z.string().optional() })
    .optional(),
  errors: z.array(z.string()).optional(),
  uiType: z.enum([...Object.values(UI_TYPE)] as unknown as [
    IUiType,
    ...IUiType[],
  ]),
  name: z.string().optional(),
  type: z.union([primitiveTypes.optional(), z.any()]),
});

const fieldsetBranchingSchema: z.ZodType<IFieldsetBranchingDefinition> =
  baseFieldsetBranchingSchema.extend({
    fieldsOptions: z.array(fieldsetBranchingOptionSchema),
  }) as z.ZodType<IFieldsetBranchingDefinition>;

const baseSerializedFieldsetBranchingSchema = z.object({
  label: z.string(),
  value: z.union([z.string(), z.array(z.string())]).optional(),
  placeholder: z.string().optional(),
  errors: z.array(z.string()).optional(),
  uiType: z.enum([...Object.values(UI_TYPE)] as unknown as [
    IUiType,
    ...IUiType[],
  ]),
  name: z.string().optional(),
});

const serializedFieldsetBranchingSchema: z.ZodType<ISerializedFieldsetBranchingDefinition> =
  baseSerializedFieldsetBranchingSchema.extend({
    fieldsOptions: z.array(serializedFieldsetBranchingOptionSchema),
  }) as z.ZodType<ISerializedFieldsetBranchingDefinition>;

export const formDefinitionEntrySchema = z.union([
  fieldsetSchema,
  fieldsetBranchingSchema,
  ...fieldSchemas,
]);

export const serializedFormDefinitionEntrySchema = z.union([
  serializedFieldsetSchema,
  serializedFieldsetBranchingSchema,
  ...serializedFieldSchemas,
]);

export function isSerializedFieldset(
  arg: unknown,
): arg is ISerializedFieldsetDefinition {
  const parseResult = serializedFieldsetSchema.safeParse(arg);
  // console.log(`=== isSerializedFieldset`, parseResult)
  return parseResult.success;
}

export function isFieldset(arg: unknown): arg is IFieldsetDefinition {
  const parseResult = fieldsetSchema.safeParse(arg);
  // console.log(`=== isFieldset`, parseResult)
  return parseResult.success;
}

export function isSerializedField(
  arg: unknown,
): arg is ISerializedFieldDefinition {
  const parseResult = serializedFieldSchema.safeParse(arg);
  // console.log(`=== isSerializedField`, parseResult)
  return parseResult.success;
}

export function isField(arg: unknown): arg is IFieldAnyDefinition {
  const parseResult = fieldSchema.safeParse(arg);
  // console.log(`=== isField`, parseResult)
  return parseResult.success;
}

export function isFieldSingleWithoutOptions(
  arg: unknown,
): arg is IFieldSingleValueWithoutOptionsDefinition {
  const parseResult = withoutOptionsSingleValueFieldSchema.safeParse(arg);
  //console.log(`=== isFieldSingleWithoutOptions`, parseResult)
  return parseResult.success;
}

export function isFieldSingleWithOptions(
  arg: unknown,
): arg is IFieldSingleValueWithOptionsDefinition {
  const parseResult = withOptionsSingleValueFieldSchema.safeParse(arg);
  // console.log(`=== isFieldSingleWithOptions`, parseResult)
  return parseResult.success;
}

export function isFieldSingleWithAutoCompleteOption(
  arg: IFieldSingleValueWithOptionsDefinition,
): arg is IFieldSingleValueWithOptionsDefinition & {
  options: Array<
    | IFieldValueOptionDefinition
    | {
        value: typeof AUTO_COMPLETE_OPTION_VALUE;
      }
  >;
} {
  const parseResult = withOptionsSingleValueFieldSchema.safeParse(arg);
  // console.log(`=== isFieldSingleWithAutoCompleteOption`, parseResult)

  if (!parseResult.success) return false;

  const hasAutoCompleteOption = parseResult.data.options.reduce(
    (hasAutoCompleteOption, option) => {
      return (
        hasAutoCompleteOption || option.value === AUTO_COMPLETE_OPTION_VALUE
      );
    },
    false,
  );

  return hasAutoCompleteOption;
}

export function isFieldCollectionWithoutOptions(
  arg: unknown,
): arg is IFieldCollectionValueWithoutOptionsDefinition {
  const parseResult = withoutOptionsCollectionValueFieldSchema.safeParse(arg);
  // console.log(`=== isFieldCollectionWithoutOptions`, parseResult)
  return parseResult.success;
}

export function isFieldCollectionWithOptions(
  arg: unknown,
): arg is IFieldCollectionValueWithOptionsDefinition {
  const parseResult = withOptionsCollectionValueFieldSchema.safeParse(arg);
  // console.log(`=== isFieldCollectionWithOptions`, parseResult)
  return parseResult.success;
}

export function isSerializedFieldsetBranching(
  arg: unknown,
): arg is ISerializedFieldsetBranchingDefinition {
  const parseResult = serializedFieldsetBranchingSchema.safeParse(arg);
  // console.log(`=== isSerializedFieldsetBranching`, parseResult)
  return parseResult.success;
}

export function isFieldsetBranching(
  arg: unknown,
): arg is IFieldsetBranchingDefinition {
  const parseResult = fieldsetBranchingSchema.safeParse(arg);
  // console.log(`=== isFieldsetBranching`, parseResult.success || parseResult)
  return parseResult.success;
}

export function isTypedFieldDefinition(
  arg: unknown,
): arg is IFieldTypedDefinition {
  const parseResult = fieldSchema.safeParse(arg);
  // console.log(`=== isTypedFieldDefinition`, parseResult)
  return parseResult.success;
}

export function isNamedFieldDefinition(
  arg: unknown,
): arg is IFieldNamedDefinition {
  return !!arg && typeof arg === 'object' && 'name' in arg;
}
