import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IconCircleCheck, IconMailX } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';

import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';

import {
  AcceptUserOptInInviteError,
  GetUserOptInInviteError,
  fetchAcceptUserOptInInvite,
  fetchGetUserOptInInvite,
} from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

import { logoutUser } from '../../../../modules/login';
import {
  PollingOptions,
  pollUntil,
  usePollMutation,
} from '../../components/polling';

export type UserAcceptInviteProps = {
  readonly userId: string;
};
export function UserAcceptInvite({ userId }: UserAcceptInviteProps) {
  const { inviteId, organizationId } = useParams<{
    inviteId: string;
    organizationId: string;
  }>();

  const { mutate, isError, isLoading, error } = useAcceptInviteWithPolling({
    organizationId,
    inviteId,
    userId,
    options: {
      interval: 1000,
      maxAttempts: 10,
    },
  });

  // hack to prevent double request
  const accept = debounce(mutate);

  useEffect(() => {
    if (isLoading) return undefined;

    accept();
    return () => accept.cancel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, inviteId, userId]);

  return (
    <Box
      sx={{ py: 5, px: 4, height: 'calc(100vh - 80px)' }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {isLoading && <Loading />}
      {!isLoading && (
        <ResponseAlert isError={isError} error={getErrorMessage(error)} />
      )}
    </Box>
  );
}

const getErrorMessage = <T extends { payload: string }>(
  error: T | undefined,
): string | undefined => {
  if (!error) return undefined;
  if ('message' in error && typeof error.message === 'string') {
    return error.message;
  }
  return error.payload;
};

function Loading() {
  return (
    <Box>
      <Box sx={{ width: '100px' }} m="auto">
        <OctopusLoading />
      </Box>
      <Typography variant="h4" textAlign="center">
        Aceitando troca de email...
      </Typography>
    </Box>
  );
}

const ALERT_SUCCESS = {
  icon: <IconCircleCheck className="success" size={80} />,
  title: 'Email alterado',
  message: (
    <>
      Seu email de acesso foi trocado com sucesso. A partir de agora você deve
      acessar a Tako através do botão <strong>Entrar com Google.</strong>
    </>
  ),
};
const DEFAULT_ALERT_ERROR_MESSAGE =
  'O prazo para confirmar a troca do seu e-mail expirou. Você pode solicitar um novo link entrando em contato com o suporte da sua empresa.';
const ALERT_ERROR = (error: string) => ({
  icon: <IconMailX className="error" size={80} />,
  title: 'Este link não está mais disponível',
  message: error || DEFAULT_ALERT_ERROR_MESSAGE,
});

function ResponseAlert({
  isError,
  error,
}: {
  isError: boolean;
  error: string;
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { icon, title, message } = isError ? ALERT_ERROR(error) : ALERT_SUCCESS;

  const redirectLogin = () =>
    logoutUser(queryClient)
      .then(() => {
        navigate('/login/sso');
      })
      .then(() => console.log('logout'));

  return (
    <Box display="flex" justifyContent="center">
      <Box sx={{ width: '50%' }}>
        <Box display="flex" justifyContent="center" sx={{ pb: 2 }}>
          {icon}
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="center">
            <Typography variant="h2">{title}</Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography variant="body1">{message}</Typography>
          </Box>
        </Box>
        {!isError && (
          <Box display="flex" justifyContent="center" sx={{ pt: 4 }}>
            <Button color="primaryAlt" size="large" onClick={redirectLogin}>
              <Box sx={{ px: 4 }}>Continuar</Box>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}

const useAcceptInviteWithPolling = (req: {
  organizationId: string;
  userId: string;
  inviteId: string;
  options: PollingOptions;
}) => {
  const mutation = usePollMutation<
    unknown,
    AcceptUserOptInInviteError | GetUserOptInInviteError
  >({
    mutationKey: [req.organizationId, req.userId, req.inviteId],
    mutationFn: () => fetchAcceptInvite(req),
  });
  return mutation;
};

const fetchAcceptInvite = ({
  organizationId,
  userId,
  inviteId,
  options,
}: {
  organizationId: string;
  userId: string;
  inviteId: string;
  options: PollingOptions;
}) =>
  fetchAcceptUserOptInInvite(
    {
      pathParams: { organizationId, userId, inviteId },
    },
    AbortSignal.timeout(10_000),
  ).then(() =>
    // poll until invite is complete
    pollUntil(options, () =>
      fetchGetUserOptInInvite(
        {
          pathParams: { organizationId, userId, inviteId },
        },
        AbortSignal.timeout(4_000),
      )
        .then((invite) => invite.status === 'completed')
        .catch((error) =>
          error.name === 'AbortError' || error.name === 'TimeoutError'
            ? false
            : Promise.reject(error),
        ),
    ),
  );
