import { Box, Typography } from '@mui/material';

export const PageTitle = ({
  title,
  icon,
  subTitle,
  dataTestId,
}: {
  title: string | React.ElementType;
  dataTestId?: string;
  icon?: React.ElementType;
  subTitle?: string;
}) => {
  const Icon = icon;
  const Title =
    typeof title === 'string'
      ? () => <Typography variant="h1">{title}</Typography>
      : title;
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      data-testid={dataTestId}
      mb={5}
      gap={2}
    >
      {icon && <Icon width={40} height={40} />}
      <Title />
      {subTitle && (
        <Typography
          sx={{ alignSelf: 'end' }}
          mb={0.5}
          variant="h3"
          color="strokes.heavy"
        >
          {subTitle}
        </Typography>
      )}
    </Box>
  );
};
