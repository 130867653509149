import ExcelJS from 'exceljs';

import { setCell, setDivider, setKeyValue } from '../operations';
import { workbookStyles } from '../styles';
import { DetailsSectionProps } from '../types';

export function writeDetailsSection({
  worksheet,
  startRow,
  startColumn,
  props: { details },
}: {
  worksheet: ExcelJS.Worksheet;
  startRow: number;
  startColumn: number;
  props: DetailsSectionProps;
}): number {
  setCell(
    worksheet,
    startRow,
    startColumn,
    'Detalhamento',
    workbookStyles.subtitle,
  );
  let row = startRow + 1;
  for (const { label, value, breakLine, divider } of details) {
    setKeyValue(worksheet, row, startColumn, {
      key: label,
      value,
    });
    if (divider) {
      setDivider(worksheet, row, startColumn, startColumn + 2);
    }
    if (breakLine) {
      row++;
    }
    row++;
  }
  return row + 1;
}
