import { IconSend } from '@tabler/icons-react';

import { Box, Button, Tooltip } from '@mui/material';

import { PayrollPeriodSummary } from '@octopus/api';
import { payslipStatuses } from '@octopus/payroll-types';

import { useSendPayslips } from '../../../modules/components/payslips/SendPayslips';
import { hasGeneratedAllPayslips } from '../../../utils/statusIndexUtils';

import { InfoTags } from './Status';

export const ReceiptColumn = (props: { row: PayrollPeriodSummary }) => {
  const { sendPayslipsProps, sendPayslips, SendPayslipsComponent } =
    useSendPayslips({
      organizationId: props.row.organizationId,
      companyId: props.row.companyId,
    });

  const hasAlreadySentAll =
    props.row.totalizers?.counts?.payslips?.byStatus?.[payslipStatuses.sent] >
    0;
  if (hasAlreadySentAll) {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <InfoTags text="Enviados" variant="info" />
      </Box>
    );
  }

  const isScheduled = !!props.row.payslipScheduledSendAt;
  if (isScheduled) {
    return <InfoTags text="Envio programado" variant="default" />;
  }

  const isGeneratingPayslips = !hasGeneratedAllPayslips(props.row.totalizers);
  const button = (
    <Button
      disabled={isGeneratingPayslips}
      color="secondary"
      startIcon={<IconSend size={16} />}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        sendPayslips({
          periodId: props.row.period,
          payrollType: props.row.type,
        });
      }}
    >
      Enviar
    </Button>
  );

  return (
    <Box display="flex" alignItems="center">
      {isGeneratingPayslips ? (
        <Tooltip title="Os recibos ainda estão sendo gerados. Tente novamente dentro de alguns instantes.">
          <span>{button}</span>
        </Tooltip>
      ) : (
        button
      )}
      <SendPayslipsComponent {...sendPayslipsProps} />
    </Box>
  );
};
