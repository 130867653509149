import React, { useContext, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Skeleton, Typography } from '@mui/material';

import {
  CompanyList,
  CostCenterEntry,
  CostCenterList,
  CostCenterSummary,
  SearchInput,
  fetchSearchAllCostCenters,
  useSearchAllContracts,
} from '@octopus/api';
import {
  DataGrid,
  DataGridProps,
  DataGridToolbar,
  GridColDef,
  GridValueGetterParams,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { ContentTabs } from '../../modules/components/ContentTabs';
import { CostCenterDetailsDrawer } from '../../modules/components/costCenters/CostCenterDetailsDrawer';
import { CreateCostCenterDrawer } from '../../modules/components/costCenters/CreateCostCenterDrawer';
import { ExpandableTypography } from '../../modules/components/ExpandableTypography';
import { ArrowNavigation } from '../../modules/components/Navigator';
import { PageContainer } from '../../modules/components/page/PageContainer';
import { PageTitle } from '../../modules/components/page/PageTitle';
import { SnackbarType } from '../../modules/hooks/snackbarContext';
import { useSnackbar } from '../../modules/hooks/useSnackbar';
import { pollCostCenter } from '../../modules/pollers';
import { QueryResult } from '../../modules/types';
import { AppContext } from '../context';

export type CostCentersContentProps = {
  organizationId: string;
  companiesQuery: QueryResult<CompanyList>;
};

const costCentersStatuses = {
  active: 'active',
  deactivated: 'deactivated',
} as const;

type CostCenterStatus = keyof typeof costCentersStatuses;

export function CostCentersContent({
  organizationId,
  companiesQuery,
}: CostCentersContentProps) {
  const { refetchCompanyData } = useContext(AppContext);
  const [searchTerm, setSearchTerm] = useState('');

  const [showCostCenterCreation, setShowCostCenterCreation] = useState(false);
  const [isLoadingNewData, setIsLoadingNewData] = useState(false);

  const [selectedCostCenter, setSelectedCostCenter] = useState<
    CostCenterSummary | undefined
  >();

  const [costCentersStatus, setCostCentersStatus] = useState<CostCenterStatus>(
    costCentersStatuses.active,
  );

  const prepareSearchInput = (datagridProps: DataGridProps, active: boolean) =>
    ({
      pagination: {
        page: datagridProps.paginationProps.page,
        size: datagridProps.paginationProps.rowsPerPage,
      },
      filtering: {
        elements: {
          active: [`${active}`],
        },
      },
      sorting: [
        {
          field: datagridProps.sortingProps.field,
          order: datagridProps.sortingProps.order,
        },
      ],
      ...(searchTerm.length > 0 && {
        query: searchTerm,
      }),
    }) as SearchInput;

  const activeCostCentersDatagridProps = useDataGrid({
    key: `active-cost-centers-${organizationId}`,
    sorting: {
      field: 'name',
      order: 'asc',
    },
  });
  const activeCostCentersQuery = useQuery({
    queryKey: [
      'fetchSearchAllCostCenters',
      organizationId,
      activeCostCentersDatagridProps.paginationProps.page,
      activeCostCentersDatagridProps.paginationProps.rowsPerPage,
      activeCostCentersDatagridProps.sortingProps.field,
      activeCostCentersDatagridProps.sortingProps.order,
      true,
      searchTerm,
    ],
    queryFn: ({ signal }) => {
      return fetchSearchAllCostCenters(
        {
          pathParams: {
            organizationId,
          },
          body: prepareSearchInput(activeCostCentersDatagridProps, true),
        },
        signal,
      );
    },
    enabled: !!organizationId,
  });

  const inactiveCostCentersDatagridProps = useDataGrid({
    key: `inactive-cost-centers-${organizationId}`,
    sorting: {
      field: 'name',
      order: 'asc',
    },
  });
  const inactiveCostCentersQuery = useQuery({
    queryKey: [
      'fetchSearchAllCostCenters',
      organizationId,
      inactiveCostCentersDatagridProps.paginationProps.page,
      inactiveCostCentersDatagridProps.paginationProps.rowsPerPage,
      inactiveCostCentersDatagridProps.sortingProps.field,
      inactiveCostCentersDatagridProps.sortingProps.order,
      false,
      searchTerm,
    ],
    queryFn: ({ signal }) => {
      return fetchSearchAllCostCenters(
        {
          pathParams: {
            organizationId,
          },
          body: prepareSearchInput(inactiveCostCentersDatagridProps, false),
        },
        signal,
      );
    },
    enabled: !!organizationId,
  });

  const datagridProps =
    costCentersStatus === costCentersStatuses.active
      ? activeCostCentersDatagridProps
      : inactiveCostCentersDatagridProps;
  const costCentersQuery =
    costCentersStatus === costCentersStatuses.active
      ? activeCostCentersQuery
      : inactiveCostCentersQuery;

  const refetch = async () => {
    await Promise.all([
      activeCostCentersQuery.refetch(),
      inactiveCostCentersQuery.refetch(),
      refetchCompanyData(),
    ]);
  };

  const contractsQuery = useSearchAllContracts();
  const costCenterIds =
    costCentersQuery?.data?.data?.map(({ costCenterId }) => costCenterId) ?? [];
  const fetchContractsByCostCenter = () => {
    if (organizationId && costCenterIds.length > 0) {
      contractsQuery.mutate({
        pathParams: {
          organizationId,
        },
        body: {
          pagination: {
            size: 1,
          },
          filtering: {
            elements: {
              status: [{ not: 'terminated' }],
            },
          },
          counting: {
            filtered: {
              byProp: {
                costCenterId: costCenterIds,
              },
            },
          },
        },
      });
    }
  };

  const costCenterIdsString = costCenterIds.join('/');
  useEffect(() => {
    fetchContractsByCostCenter();
  }, [organizationId, costCenterIdsString]);

  const contractsByCostCenter =
    contractsQuery?.data?.metadata?.filtered?.counters?.byProp?.costCenterId;

  return (
    <PageContainer toolbar={false}>
      <PageTitle
        title="Centros de Custo"
        dataTestId="cost-centers-page-title"
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mb={2}
        mt={1}
      >
        <DataGridToolbar
          searchPlaceholder="Procurar"
          hideFilters={true}
          filters={[]}
          searchProps={{ searchTerm, setSearchTerm }}
          filteringProps={datagridProps.filteringProps}
        />
        <Button
          size="large"
          color="primaryAlt"
          onClick={() => setShowCostCenterCreation(true)}
          data-testid="cost-center-create-button"
        >
          Novo centro de custo
        </Button>
      </Box>
      <ContentTabs
        selected={costCentersStatus}
        setSelected={setCostCentersStatus}
        tabs={[
          {
            label: 'Ativos',
            value: costCentersStatuses.active,
            count: activeCostCentersQuery.data?.total,
          },
          {
            label: 'Desativados',
            value: costCentersStatuses.deactivated,
            hidden: inactiveCostCentersQuery.data?.total === 0,
            count: inactiveCostCentersQuery.data?.total,
          },
        ]}
      />
      <Table
        datagridProps={datagridProps}
        isLoading={costCentersQuery.isLoading || isLoadingNewData}
        CostCentersQuery={costCentersQuery}
        contractsByCostCenter={contractsByCostCenter}
        onRowClick={(row) => {
          setSelectedCostCenter(row);
        }}
      />
      <Creation
        organizationId={organizationId}
        companies={companiesQuery.data}
        open={showCostCenterCreation}
        onClose={() => setShowCostCenterCreation(false)}
        setLoading={setIsLoadingNewData}
        refetch={refetch}
      />
      <Details
        organizationId={organizationId}
        companies={companiesQuery.data}
        selectedCostCenter={selectedCostCenter}
        setSelectedCostCenter={setSelectedCostCenter}
        setLoading={setIsLoadingNewData}
        costCenters={costCentersQuery.data}
        refetch={refetch}
        contractsByCostCenter={contractsByCostCenter}
        refetchContractsByCostCenter={fetchContractsByCostCenter}
      />
    </PageContainer>
  );
}

function Table({
  datagridProps,
  isLoading,
  CostCentersQuery,
  contractsByCostCenter,
  onRowClick,
}: {
  datagridProps: DataGridProps;
  isLoading: boolean;
  CostCentersQuery: QueryResult<CostCenterList>;
  contractsByCostCenter: Record<string, number> | undefined;
  onRowClick: (row: CostCenterSummary) => void;
}) {
  if (isLoading) {
    return (
      <Box display="flex" flexDirection="column" gap="8px" pt={1}>
        <Skeleton
          variant="rounded"
          height={45 + datagridProps.paginationProps.rowsPerPage * 58}
          width="100%"
        />
      </Box>
    );
  }

  const columns: GridColDef<CostCenterSummary>[] = [
    {
      field: 'code',
      headerName: 'Código',
      sortable: true,
      valueGetter: (params: GridValueGetterParams<CostCenterSummary>) => {
        return params.row.code;
      },
      renderCell: ({ value }) => {
        return <Typography variant="body2">{value}</Typography>;
      },
    },
    {
      field: 'name',
      headerName: 'Centro de Custo',
      sortable: true,
      valueGetter: (params: GridValueGetterParams<CostCenterSummary>) => {
        return params.row.name;
      },
      renderCell: ({ value }) => {
        return (
          <ExpandableTypography
            expandTextOnHover
            variant="body2"
            sx={{ '--ExpandableTypography-max-width': '20em' }}
          >
            {value}
          </ExpandableTypography>
        );
      },
    },
    {
      field: 'costCenterCount',
      headerName: 'Contratos',
      sortable: false,
      valueGetter: (params: GridValueGetterParams<CostCenterSummary>) => {
        return params.row.costCenterId;
      },
      renderCell: ({ value }) => {
        if (!contractsByCostCenter) {
          return <Skeleton variant="text" width="50px" />;
        }
        return (
          <Typography variant="body2">
            {contractsByCostCenter[value] ?? 0}
          </Typography>
        );
      },
    },
  ];

  return (
    <DataGrid
      getRowSx={() => ({
        height: '56px',
      })}
      sortingProps={datagridProps.sortingProps}
      paginationProps={datagridProps.paginationProps}
      totalRowCount={CostCentersQuery.data.total || 0}
      getRowId={(row) => row.costCenterId}
      rows={CostCentersQuery.data.data}
      columns={columns}
      onRowClick={({ row }) => onRowClick(row)}
    />
  );
}

function Creation({
  organizationId,
  companies,
  open,
  onClose,
  setLoading,
  refetch,
}: {
  organizationId: string;
  companies: CompanyList | undefined;
  open: boolean;
  onClose: () => void;
  setLoading: (loading: boolean) => void;
  refetch: () => Promise<void>;
}) {
  const { showSnackbar } = useSnackbar();

  const onSuccess = async ({ costCenterId }: CostCenterEntry) =>
    handleUpdatesToList({
      organizationId,
      costCenterId,
      setLoading,
      closeDrawer: onClose,
      showSnackbar,
      snackbarMessage: 'Centro de custo criado',
      refetch,
    });

  return (
    <CreateCostCenterDrawer
      organizationId={organizationId}
      companies={companies?.data}
      open={open}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );
}

function Details({
  organizationId,
  companies,
  selectedCostCenter,
  setSelectedCostCenter,
  setLoading,
  costCenters,
  refetch,
  contractsByCostCenter,
  refetchContractsByCostCenter,
}: {
  organizationId: string;
  companies: CompanyList | undefined;
  selectedCostCenter: CostCenterSummary | undefined;
  setSelectedCostCenter: (costCenter: CostCenterSummary | undefined) => void;
  setLoading: (loading: boolean) => void;
  costCenters: CostCenterList | undefined;
  refetch: () => Promise<void>;
  contractsByCostCenter: Record<string, number> | undefined;
  refetchContractsByCostCenter: () => void;
}) {
  const { showSnackbar } = useSnackbar();
  const [detailsNavigation, setDetailsNavigation] = useState<ArrowNavigation>({
    canGoForward: false,
    canGoBackwards: false,
    goForward: undefined,
    goBackwards: undefined,
  });

  useEffect(() => {
    if (selectedCostCenter !== undefined) {
      const currentIndex = costCenters?.data?.findIndex(
        ({ costCenterId }) => selectedCostCenter.costCenterId === costCenterId,
      );
      if (currentIndex !== undefined) {
        setDetailsNavigation({
          canGoForward: currentIndex < costCenters?.data?.length - 1,
          canGoBackwards: currentIndex > 0,
          goForward: () =>
            setSelectedCostCenter(costCenters?.data[currentIndex + 1]),
          goBackwards: () =>
            setSelectedCostCenter(costCenters?.data[currentIndex - 1]),
        });
        return;
      }
    }
    setDetailsNavigation({
      canGoForward: false,
      canGoBackwards: false,
      goForward: undefined,
      goBackwards: undefined,
    });
  }, [selectedCostCenter]);

  const handleUpdates = async (
    costCenterId: string,
    message: string,
    filters: Record<string, string[]>,
  ) =>
    handleUpdatesToList({
      organizationId,
      costCenterId,
      setLoading,
      closeDrawer: () => setSelectedCostCenter(undefined),
      showSnackbar,
      snackbarMessage: message,
      filters,
      refetch,
    });

  const onEdit = (costCenter: CostCenterEntry) =>
    handleUpdates(costCenter.costCenterId, 'Centro de custo atualizado', {
      version: [`${costCenter.version}`],
    });

  const onRestore = (costCenterId: string) =>
    handleUpdates(costCenterId, 'Centro de custo reativado', {
      costCenterId: [costCenterId],
      active: ['true'],
    });

  const onArchive = (costCenterId: string) =>
    handleUpdates(costCenterId, 'Centro de custo desativado', {
      costCenterId: [costCenterId],
      active: ['false'],
    });

  return (
    <CostCenterDetailsDrawer
      organizationId={organizationId}
      companies={companies?.data}
      costCenterSummary={selectedCostCenter}
      contractsCount={contractsByCostCenter?.[selectedCostCenter?.costCenterId]}
      navigation={detailsNavigation}
      open={selectedCostCenter !== undefined}
      onEdit={onEdit}
      onRestore={onRestore}
      onArchive={onArchive}
      onContractsAdded={() => {
        refetchContractsByCostCenter();
      }}
      onContractsMoved={() => {
        refetchContractsByCostCenter();
        showSnackbar({
          isOpen: true,
          variant: 'default',
          Message: 'Movimentação realizada',
          StartAdornment: <CheckCircleIcon />,
        });
      }}
      onClose={() => setSelectedCostCenter(undefined)}
    />
  );
}

async function handleUpdatesToList({
  organizationId,
  costCenterId,
  setLoading,
  closeDrawer,
  showSnackbar,
  snackbarMessage,
  filters,
  refetch,
}: {
  organizationId: string;
  costCenterId: string;
  setLoading: (loading: boolean) => void;
  closeDrawer: () => void;
  showSnackbar: (snackbar: SnackbarType) => void;
  snackbarMessage: string;
  filters?: Record<string, string[]>;
  refetch: () => Promise<unknown>;
}) {
  setLoading(true);
  closeDrawer();
  showSnackbar({
    isOpen: true,
    variant: 'default',
    Message: snackbarMessage,
    StartAdornment: <CheckCircleIcon />,
    hasCloseAction: true,
  });
  await pollCostCenter({
    organizationId,
    costCenterId,
    extraFilters: filters,
  });
  setTimeout(() => {
    refetch().finally(() => setLoading(false));
  }, 200);
}
