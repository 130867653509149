import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/system';

/**
 * Regular tooltip but with customized padding for texts withing the user management app.
 */
export const UserTextTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(({ theme: _ }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '4px 8px 4px 8px',
  },
}));
