import { useEffect, useState } from 'react';

import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { getOptionsFromList } from '../../../admission/new/form/inputUtils';
import { fieldsWithConfigs } from '../../../admission/utils/fields';

type AdmissionField = {
  fieldName: string;
  section: string; //todo leticia: change to step enum
  hidden?: boolean;
  required?: boolean;
  allowedValues?: string[];
};

type AdmissionPreferencesType = {
  fields?: Record<string, AdmissionField>;
};

const emptyCell = (
  <TableCell>
    <Typography variant="body1"> - </Typography>
  </TableCell>
);

function AdmissionPreferences({
  admissionPreferences,
  setAdmissionPreferences,
}: {
  admissionPreferences: AdmissionPreferencesType | null;
  setAdmissionPreferences: (
    admissionPreferences: AdmissionPreferencesType,
  ) => void;
}) {
  const [fields, setFields] = useState<Record<string, AdmissionField>>({});
  useEffect(() => {
    setFields(admissionPreferences?.fields ?? {});
  }, [admissionPreferences]);

  return (
    <Accordion
      className="borderless"
      defaultExpanded={true}
      sx={{
        bgcolor: '#F7F7F8',
        borderRadius: 1,
        height: '100%',
        width: '100%',
        border: 'none',
        padding: 0,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />} sx={{ width: '100%' }}>
        <Typography variant="h4">Campos do formulário</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0 }}>
        {Object.entries(fieldsWithConfigs).map(([section, fieldConfigs]) => {
          return (
            <Box key={section} display="flex" flexDirection="column" gap={2} mt={3}>
              <Divider sx={{ mt: 0, mb: 3 }} />
              <Typography variant="h4">{section}</Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Campo</TableCell>
                      <TableCell>Tipo</TableCell>
                      <TableCell>Visível</TableCell>
                      <TableCell>Obrigatório</TableCell>
                      <TableCell>Opções permitidas</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(fieldConfigs).map(([fieldName, field]) => {
                      const fieldPreference = fields[fieldName] || {
                        fieldName,
                        section,
                      };

                      const options =
                        field.type === 'select'
                          ? getOptionsFromList({
                              list: field.options,
                              currentValue: '',
                            })
                          : null;

                      const selectValues =
                        field.type === 'select'
                          ? (fieldPreference?.allowedValues ??
                            options?.map((o) => o.value))
                          : null;

                      return (
                        <TableRow key={fieldName}>
                          <TableCell>
                            <Typography variant="body1">
                              {field.title}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              {field.type}
                            </Typography>
                          </TableCell>
                          {field.type !== 'document' ? (
                            <TableCell>
                              <Checkbox
                                checked={!fieldPreference?.hidden}
                                onChange={(e) => {
                                  const newFields = {
                                    ...fields,
                                    [fieldName]: {
                                      ...fieldPreference,
                                      hidden: !e.target.checked,
                                    },
                                  };
                                  setFields(newFields);
                                  setAdmissionPreferences({
                                    ...admissionPreferences,
                                    fields: newFields,
                                  });
                                }}
                              />
                            </TableCell>
                          ) : (
                            emptyCell
                          )}
                          <TableCell>
                            <Checkbox
                              disabled={fieldPreference?.hidden}
                              checked={fieldPreference?.required === true}
                              onChange={(e) => {
                                const newFields = {
                                  ...fields,
                                  [fieldName]: {
                                    ...fieldPreference,
                                    required: e.target.checked,
                                  },
                                };
                                setFields(newFields);
                                setAdmissionPreferences({
                                  ...admissionPreferences,
                                  fields: newFields,
                                });
                              }}
                            />
                          </TableCell>
                          {field.type === 'select' ? (
                            <TableCell>
                              <Select
                                disabled={fieldPreference?.hidden}
                                multiple
                                value={selectValues}
                                renderValue={(value) =>
                                  !value ||
                                  value.length === 0 ||
                                  value.length === options?.length
                                    ? 'Todas'
                                    : value?.length === 1
                                      ? value[0]
                                      : `${value.length} opções selecionadas`
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  let allowedValues = [] as string[];

                                  if (
                                    typeof value === 'string'
                                      ? value === 'removeAll'
                                      : value.find((i) => i === 'removeAll')
                                  ) {
                                    e.stopPropagation();
                                  } else if (
                                    typeof value === 'string'
                                      ? value === 'selectAll'
                                      : value.find((i) => i === 'selectAll')
                                  ) {
                                    e.stopPropagation();
                                    allowedValues =
                                      options?.map((o) => o.value) ?? [];
                                  } else {
                                    allowedValues = (
                                      typeof value === 'string'
                                        ? [value]
                                        : value
                                    ) as string[];
                                  }

                                  const newFields = {
                                    ...fields,
                                    [fieldName]: {
                                      ...fieldPreference,
                                      allowedValues,
                                    },
                                  };
                                  setFields(newFields);
                                  setAdmissionPreferences({
                                    ...admissionPreferences,
                                    fields: newFields,
                                  });
                                }}
                              >
                                <MenuItem key="removeAll" value="removeAll">
                                  <ListItemText primary="Limpar seleção" />
                                </MenuItem>
                                <MenuItem key="selectAll" value="selectAll">
                                  <ListItemText primary="Selecionar todas" />
                                </MenuItem>
                                {options?.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    selected={
                                      selectValues?.length ===
                                        options?.length ||
                                      selectValues?.includes(option.value)
                                    }
                                  >
                                    <Checkbox
                                      checked={
                                        selectValues?.length ===
                                          options?.length ||
                                        selectValues?.includes(option.value)
                                      }
                                    />
                                    <ListItemText primary={option.label} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                          ) : (
                            emptyCell
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}

export { AdmissionPreferences, AdmissionPreferencesType };
