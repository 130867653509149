import { useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  IconBeach,
  IconBell,
  IconBuildingSkyscraper,
  IconPaperclip,
  IconReceipt,
  IconReceipt2,
  IconReportAnalytics,
  IconReportMoney,
  IconSettings,
  IconShieldChevron,
  IconSitemap,
  IconUser,
  IconUserMinus,
  IconUserPlus,
  IconUsersGroup,
} from '@tabler/icons-react';

import { Cancel } from '@mui/icons-material';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

import { MembershipTypes } from '@octopus/api';
import {
  ContractTypes,
  WorkerCategory,
  contractTypes,
} from '@octopus/contract-types';


import { PaymentRequestIcon } from '../modules/components/pj/icon';
import { MembershipContext } from '../modules/types';
import { membershipEquals } from '../utils';
import { getRUMInfo } from '../utils/getRUMInfo';
import { rum } from '../utils/rum';

import ChartsOfAccountsPage from './accounting/chartsOfAccounts/page';
import EntriesMappingsRulesPage from './accounting/entriesMappingsRules/page';
import LayoutContabil from './accounting/layoutContabil/page';
import RegrasReconciliacao from './accounting/regras-reconciliacao/page';
import { AdmissionDraftEditPage } from './admission/edit/page';
import { AdmissionDraftInputPage } from './admission/new/page';
import AdmissionListPage from './admission/page';
import { AdmissionDraftReviewPage } from './admission/review/page';
import CreateCertificate from './companies/certificates/page';
import CreateCompaniesPreferences from './companies/preferences/page';
import { AppContext } from './context';
import { ContractorCreatePaymentRequestPage } from './contractor/payment-requests/[payment-request]/create';
import { ContractorPaymentRequestPage } from './contractor/payment-requests/[payment-request]/page';
import { ContractorPaymentRequestListPage } from './contractor/payment-requests/page';
import { features, useFFlags, useResolveFFlags } from './fflags';
import { SignupSuccess } from './login/signup/success/page';
import { MembershipSelectorPage } from './memberships/selector/page';
import { AppTitles, Navbar, SidebarApp } from './navigation';
import Organizations from './organizations/page';
import { CreatePaymentRequestPage } from './payment-requests/[payment-request]/create';
import { PaymentRequestPage } from './payment-requests/[payment-request]/page';
import { PaymentRequestListPage } from './payment-requests/page';
import { PayslipPage } from './payrolls/[period]/[contractId]/[type]/payslip/page';
import { PayrollInputsSubmission } from './payrolls/[period]/[type]/inputs/[submissionId]/page';
import { PayrollInputs } from './payrolls/[period]/[type]/inputs/page';
import PayrollPage from './payrolls/[period]/[type]/page';
import Payrolls from './payrolls/page';
import EditSingleRpaPage from './payrolls/rpa/editSingleRpaPage';
import { RPAInputsSubmission } from './payrolls/rpa/inputs/[submissionId]/page';
import RPALaunchPage from './payrolls/rpa/launchRpaPage';
import RPAPage from './payrolls/rpaPage';
import People from './people/page';
import Person from './people/person/page';
import PersonalData from './personalData/page';
import WorkerProvisionsPage from './provisions/[period]/[contractId]/page';
import ProvisionsPage from './provisions/[period]/page';
import { PayslipReceipt } from './receipts/[companyId]/[contractId]/[payrollId]/page';
import { Receipts } from './receipts/page';
import { ReportFormPage, ReportsPage } from './reports';
import Spreadsheets from './spreadsheets/page';
import { TemplatesManagerPage } from './templates/manager/page';
import TerminationReceiptPage from './terminations/[id]/page';
import TerminationsListPage from './terminations/page';
import UserInfo from './UserInfo';
import { UserAcceptInvite } from './users/invites/[inviteId]/page';
import { UsersManagementPage } from './users/page';
import { PendingAccessContracts } from './users/pending-access/page';
import { VacationInputPage } from './vacations/[contractId]/new/page';
import VacationReceiptPage from './vacations/[contractId]/receipt/page';
import VacationRequestDetailsPage from './vacations/[contractId]/request/details/page';
import VacationRequestPage from './vacations/[contractId]/request/page';
import VacationSchedulerPage from './vacations/[contractId]/scheduler/page';
import { VacationsConfiguration } from './vacations/configuration/page';
import { VacationsGroup } from './vacations/group';
import { NewVacationsGroup } from './vacations/group/New';
import { VacationsGroupSchedules } from './vacations/group/Schedule';
import {
  EditVacationsNotification,
  NewVacationsNotification,
  VacationsNotifications,
} from './vacations/notifications';
import VacationsPage from './vacations/page';
import { VacationsRedirect } from './vacations/VacationsRedirect';

export function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const logout = () => navigate('/login/logout');
  const { FFlags } = useFFlags();
  const {
    isLoading,
    isError,
    error,
    appContext,
    membershipOptions,
    changeMembership,
  } = useContext(AppContext);

  useResolveFFlags();

  if (isLoading) {
    return <HomeLoading />;
  }
  if (
    isError ||
    !appContext?.user ||
    !appContext?.membership ||
    !membershipOptions
  ) {
    return <HomeError error={error} />;
  }

  const { email, personId, userId } = appContext.user;
  const { organizationId, membershipType } = appContext.membership;
  const companyId = appContext.currentCompany?.id;
  const { contractId, contractType, isManager, workerCategory } =
    appContext.contract ?? {};
  const { draftId } = appContext.admission ?? {};

  const apps = mountAppList({
    membershipType,
    personId,
    draftId,
    contractType,
    contractId,
    isManager,
    workerCategory,
  });

  const baseRoute = getBaseRoute(membershipType, draftId);

  const setMembership = (membership: MembershipContext) => {
    if (!membershipEquals(appContext?.membership, membership)) {
      const navigateTo = (() => {
        const parent = location.pathname.split('/')?.[1] ?? '';
        return getBaseRoute(membership?.membershipType, draftId, parent);
      })();
      changeMembership(membership);
      navigate(navigateTo);
    }
  };

  return (
    <Box height="100%">
      <ErrorBoundary FallbackComponent={PageErrorFallback}>
        <Navbar
          username={email}
          apps={apps}
          membership={appContext.membership}
          membershipOptions={membershipOptions}
          changeMembership={setMembership}
          logout={logout}
        />
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              marginLeft: 7,
            },
            height: '100%',
          })}
          height={'100%'}
        >
          <Routes>
            <Route
              path="/payrolls"
              element={
                <Payrolls
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/payrolls/:period/:type"
              element={
                <PayrollPage
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/payrolls/:period/:type/inputs"
              element={
                <PayrollInputs
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/payrolls/:period/:type/inputs/:submissionId"
              element={
                <PayrollInputsSubmission
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/payrolls/:payrollId/payslips"
              element={
                <PayslipPage
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/autonomos"
              element={
                <>
                  <FFlags hasAccessToAutonomos>
                    <RPAPage
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                  <FFlags hasAccessToAutonomos={false}>
                    <Payrolls
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/autonomos/:period/rpa"
              element={
                <>
                  <FFlags canEditRpas>
                    <RPALaunchPage
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                  <FFlags canEditRpas={false}>
                    <Payrolls
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/autonomos/rpa/:payrollId"
              element={
                <>
                  <FFlags canEditRpas>
                    <EditSingleRpaPage
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                  <FFlags canEditRpas={false}>
                    <Payrolls
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/autonomos/:period/rpa/:submissionId"
              element={
                <>
                  <FFlags canEditRpas>
                    <RPAInputsSubmission
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                  <FFlags canEditRpas={false}>
                    <Payrolls
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/vacations-group"
              element={<VacationsGroup organizationId={organizationId} />}
            />
            <Route
              path="/vacations-group/:groupId"
              element={
                <VacationsGroupSchedules organizationId={organizationId} />
              }
            />
            <Route
              path="/vacations-group/new"
              element={<NewVacationsGroup organizationId={organizationId} />}
            />

            <Route
              path="/vacations"
              element={
                <VacationsRedirect
                  membership={appContext.membership}
                  contractId={contractId}
                  isManager={isManager}
                />
              }
            />

            <Route
              path="/vacations/:role"
              element={
                <>
                  <FFlags managerVacationsApprovalEnabled>
                    <VacationsPage
                      organizationId={organizationId}
                      contractId={contractId}
                      isFFEnabled
                    />
                  </FFlags>
                  <FFlags managerVacationsApprovalEnabled={false}>
                    <VacationsPage
                      organizationId={organizationId}
                      contractId={contractId}
                      isFFEnabled={false}
                    />
                  </FFlags>
                </>
              }
            />

            <Route
              path="/vacations/configuration"
              element={
                <VacationsConfiguration organizationId={organizationId} />
              }
            />

            <Route
              path="/vacations/notifications"
              element={
                <VacationsNotifications organizationId={organizationId} />
              }
            />

            <Route
              path="/vacations/notifications/new/:ruleId"
              element={
                <NewVacationsNotification organizationId={organizationId} />
              }
            />

            <Route
              path="/vacations/notifications/:resourceType/:ruleId"
              element={
                <EditVacationsNotification organizationId={organizationId} />
              }
            />

            <Route
              path="/vacations/:contractId/scheduler"
              element={
                <VacationSchedulerPage organizationId={organizationId} />
              }
            />
            <Route
              path="/vacations/:contractId/request"
              element={<VacationRequestPage organizationId={organizationId} />}
            />
            <Route
              path="/vacations/:contractId/new"
              element={
                <VacationInputPage
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/vacations/:contractId/receipt"
              element={<VacationReceiptPage organizationId={organizationId} />}
            />
            <Route
              path="/vacations/:contractId/requestDetails/:sequence"
              element={
                <VacationRequestDetailsPage organizationId={organizationId} />
              }
            />
            <Route
              path="/admissions"
              element={<AdmissionListPage organizationId={organizationId} />}
            />
            <Route
              path="/admissions/new"
              element={
                <AdmissionDraftInputPage
                  organizationId={organizationId}
                  companyContext={appContext?.company}
                  organizationContext={appContext?.organization}
                  membershipProps={{
                    membershipType: membershipType,
                    membership: appContext.membership,
                    membershipOptions: membershipOptions,
                  }}
                  reviewMode={false}
                  finishReview={false}
                />
              }
            />
            <Route
              path="/admissions/new/:draftId"
              element={
                <AdmissionDraftEditPage
                  organizationId={organizationId}
                  companyContext={appContext?.company}
                  organizationContext={appContext?.organization}
                  membershipProps={{
                    membershipType: membershipType,
                    membership: appContext.membership,
                    membershipOptions: membershipOptions,
                  }}
                />
              }
            />
            <Route
              path="/admissions/review/:draftId"
              element={
                <AdmissionDraftReviewPage
                  organizationId={organizationId}
                  companyContext={appContext?.company}
                  organizationContext={appContext?.organization}
                />
              }
            />
            <Route
              path="/admissions/signup-success"
              element={
                <SignupSuccess
                  organizationId={organizationId}
                  userId={userId}
                />
              }
            />
            <Route
              path="/terminations"
              element={
                <TerminationsListPage
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/terminations/:payrollId"
              element={
                <TerminationReceiptPage
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/people"
              element={<People organizationId={organizationId} />}
            />
            <Route
              path="/people/:contractId"
              element={<Person organizationId={organizationId} />}
            />
            <Route
              path="/personal-data"
              element={
                <PersonalData
                  organizationId={organizationId}
                  contractId={contractId}
                  draftId={draftId}
                />
              }
            />
            <Route
              path="/receipts"
              element={
                <Receipts
                  organizationId={organizationId}
                  contractId={contractId}
                />
              }
            />
            <Route
              path="/receipts/:companyId/:contractId/:payrollId"
              element={<PayslipReceipt organizationId={organizationId} />}
            />
            <Route path="/" element={<Navigate to={baseRoute} replace />} />
            <Route
              path="/organizations"
              element={
                <>
                  <FFlags canAccessOrganizationManagement>
                    <Organizations organizationId={organizationId} />
                  </FFlags>
                  <FFlags canAccessOrganizationManagement={false}>
                    <Navigate to={{ pathname: baseRoute }} />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/companies/certificates"
              element={<CreateCertificate organizationId={organizationId} />}
            />
            <Route
              path="/companies/preferences"
              element={
                <CreateCompaniesPreferences organizationId={organizationId} />
              }
            />
            <Route
              path="/spreadsheets"
              element={
                <Spreadsheets
                  organizationId={organizationId ?? ''}
                  companyId={companyId ?? ''}
                />
              }
            />
            <Route
              path="/reports"
              element={<ReportsPage organizationId={organizationId ?? ''} />}
            />
            <Route
              path="/reports/:reportId"
              element={<ReportFormPage organizationId={organizationId} />}
            />
            <Route
              path="/payment-requests"
              element={
                <>
                  <FFlags isPjEnabledForOrganization>
                    <PaymentRequestListPage organizationId={organizationId} />
                  </FFlags>
                  <FFlags isPjEnabledForOrganization={false}>
                    <Navigate to={{ pathname: baseRoute }} />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/payment-requests/new"
              element={
                <>
                  <FFlags isPjEnabledForOrganization>
                    <CreatePaymentRequestPage
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                  <FFlags isPjEnabledForOrganization={false}>
                    <Navigate to={{ pathname: baseRoute }} />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/payment-requests/:paymentRequestId"
              element={
                <>
                  <FFlags isPjEnabledForOrganization>
                    <PaymentRequestPage
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                  <FFlags isPjEnabledForOrganization={false}>
                    <Navigate to={{ pathname: baseRoute }} />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/accounting/charts-of-accounts"
              element={<ChartsOfAccountsPage organizationId={organizationId} />}
            />
            <Route
              path="/accounting/entries-mappings-rules/:period"
              element={
                <EntriesMappingsRulesPage organizationId={organizationId} />
              }
            />
            <Route
              path="/accounting/layout-contabil"
              element={<LayoutContabil organizationId={organizationId} />}
            />
            <Route
              path="/accounting/regras-reconciliacao"
              element={<RegrasReconciliacao organizationId={organizationId} />}
            />

            <Route
              path="/provisions/:period"
              element={<ProvisionsPage organizationId={organizationId} />}
            />
            <Route
              path="/provisions/:period/:contractId"
              element={<WorkerProvisionsPage organizationId={organizationId} />}
            />
            {contractId && (
              <>
                <Route
                  path="/contractor/payment-requests"
                  element={
                    <>
                      <FFlags isPjEnabledForOrganization>
                        <ContractorPaymentRequestListPage
                          organizationId={organizationId}
                          companyId={companyId}
                          contractId={contractId}
                        />
                      </FFlags>
                      <FFlags isPjEnabledForOrganization={false}>
                        <Navigate to={{ pathname: baseRoute }} />
                      </FFlags>
                    </>
                  }
                />
                <Route
                  path="/contractor/payment-requests/new"
                  element={
                    <>
                      <FFlags isPjEnabledForOrganization>
                        <ContractorCreatePaymentRequestPage
                          organizationId={organizationId}
                          companyId={companyId}
                          contractId={contractId}
                        />
                      </FFlags>
                      <FFlags isPjEnabledForOrganization={false}>
                        <Navigate to={{ pathname: baseRoute }} />
                      </FFlags>
                    </>
                  }
                />
                <Route
                  path="/contractor/payment-requests/:paymentRequestId"
                  element={
                    <>
                      <FFlags isPjEnabledForOrganization>
                        <ContractorPaymentRequestPage
                          organizationId={organizationId}
                          companyId={companyId}
                          contractId={contractId}
                        />
                      </FFlags>
                      <FFlags isPjEnabledForOrganization={false}>
                        <Navigate to={{ pathname: baseRoute }} />
                      </FFlags>
                    </>
                  }
                />
              </>
            )}
            <Route
              path="/memberships/selector"
              element={
                <MembershipSelectorPage
                  membership={appContext.membership}
                  membershipOptions={membershipOptions}
                  setMembershipContext={changeMembership}
                  logout={logout}
                />
              }
            />
            <Route
              path="/users-management"
              element={<UsersManagementPage organizationId={organizationId} />}
            />
            <Route
              path="/users-management/organizations/:organizationId/invites/:inviteId"
              element={<UserAcceptInvite userId={userId} />}
            />
            <Route
              path="/users-management/pending-access/:organizationId"
              element={<PendingAccessContracts />}
            />
            <Route
              path="/templates-manager"
              element={<TemplatesManagerPage organizationId={organizationId} />}
            />
          </Routes>
        </Box>
      </ErrorBoundary>
      <UserInfo
        organizationId={organizationId}
        companyId={companyId}
        userData={appContext.user}
      />
    </Box>
  );
}

function getBaseRoute(
  membershipType: MembershipTypes,
  draftId: string,
  parent?: string,
) {
  switch (membershipType) {
    case 'owner':
    case 'tako:support':
      return parent ? `/${parent}` : '/payrolls';
    case 'internal':
      return draftId != null ? `/admissions/new/${draftId}` : '/personal-data';
    case 'external':
      return '/personal-data';
  }
}

function mountAppList({
  membershipType,
  personId,
  draftId,
  contractType,
  contractId,
  isManager,
  workerCategory,
}: {
  membershipType: MembershipTypes;
  personId: string;
  draftId?: string;
  contractType?: ContractTypes;
  contractId?: string;
  isManager?: boolean;
  workerCategory?: WorkerCategory;
}): SidebarApp[] {
  const pjApps: SidebarApp[] = [];
  const cltApps: SidebarApp[] = [];
  const workerApps: SidebarApp[] = [];

  if (
    membershipType === 'internal' &&
    contractType === contractTypes.brClt &&
    workerCategory !== 'clt:autonomo'
  ) {
    cltApps.push({
      title: AppTitles.VACATIONS,
      path: `/vacations`,
      Icon: IconBeach,
      children: [
        ...(isManager
          ? [
              {
                title: AppTitles.LEADERS,
                path: '/vacations/manager',
                Icon: IconSitemap,
                fflag: features.managerVacationsApprovalEnabled,
              },
            ]
          : []),
        {
          title: AppTitles.ME,
          path: `/vacations/${contractId}/scheduler`,
          Icon: IconUser,
        },
      ],
    });
  }

  if (contractType === contractTypes.brClt) {
    cltApps.push({
      title: AppTitles.PAYSLIPS_AND_RECEIPTS,
      path: '/receipts',
      Icon: IconReceipt,
    });
  }

  if (membershipType === 'internal' && contractType === contractTypes.brPj) {
    pjApps.push({
      title: AppTitles.PAYMENT_REQUESTS,
      path: '/contractor/payment-requests',
      Icon: PaymentRequestIcon('tako-custom-icon'),
      fflag: features.isPjEnabledForOrganization,
    });

    if (isManager) {
      pjApps.push({
        title: AppTitles.VACATIONS,
        path: `/vacations`,
        Icon: IconBeach,
        fflag: features.managerVacationsApprovalEnabled,
        children: [
          {
            title: AppTitles.LEADERS,
            path: '/vacations/manager',
            Icon: IconSitemap,
            fflag: features.managerVacationsApprovalEnabled,
          },
        ],
      });
    }
  }

  if (membershipType === 'owner' || membershipType === 'tako:support') {
    pjApps.push({
      title: AppTitles.PAYMENT_REQUESTS,
      path: '/payment-requests',
      Icon: PaymentRequestIcon('tako-custom-icon'),
      fflag: features.isPjEnabledForOrganization,
    });
  }

  if (contractId) {
    workerApps.push({
      title: AppTitles.MY_DATA,
      path: '/personal-data',
      Icon: IconUser,
    });
  }

  if (draftId) {
    workerApps.push({
      title: AppTitles.MY_ADMISSION,
      path: `/admissions/new/${draftId}`,
      Icon: IconUser,
    });
  }

  if (membershipType === 'internal' && personId) {
    return [...workerApps, ...pjApps, ...cltApps];
  }

  if (membershipType === 'owner' || membershipType === 'tako:support') {
    cltApps.push(
      {
        title: AppTitles.PAYROLLS,
        path: '/payrolls',
        Icon: IconReportMoney,
        fflag: features.isCltEnabledForOrganization,
      },
      {
        title: AppTitles.VACATIONS,
        path: '/vacations',
        Icon: IconBeach,
        fflag: features.isCltEnabledForOrganization,
        children: [
          {
            title: AppTitles.ORGANIZATION,
            path: '/vacations/admin',
            Icon: IconBuildingSkyscraper,
          },
          ...(isManager
            ? [
                {
                  title: AppTitles.LEADERS,
                  path: '/vacations/manager',
                  Icon: IconSitemap,
                  fflag: features.managerVacationsApprovalEnabled,
                },
              ]
            : []),
          ...(contractId
            ? [
                {
                  title: AppTitles.ME,
                  path: `/vacations/${contractId}/scheduler`,
                  Icon: IconUser,
                },
              ]
            : []),
          ...(membershipType === 'tako:support'
            ? [
                {
                  title: AppTitles.CONFIG,
                  path: '/vacations/configuration',
                  Icon: IconSettings,
                },
                {
                  title: AppTitles.NOTIFICATIONS,
                  path: '/vacations/notifications',
                  Icon: IconBell,
                },
              ]
            : []),
        ],
      },
      {
        title: AppTitles.ADMISSIONS,
        path: '/admissions',
        Icon: IconUserPlus,
      },
      {
        title: AppTitles.TERMINATIONS,
        path: '/terminations',
        Icon: IconUserMinus,
        fflag: features.isCltEnabledForOrganization,
      },
      {
        title: AppTitles.RPA_PAYMENTS,
        path: '/autonomos',
        Icon: IconReceipt2,
        fflag: features.hasAccessToAutonomos,
      },
    );

    const supportOnlyApps = [];

    if (membershipType === 'tako:support') {
      supportOnlyApps.push({
        title: AppTitles.TEMPLATES,
        path: '/templates-manager',
        Icon: IconPaperclip,
      });
    }

    return [
      {
        title: AppTitles.ORGANIZATION,
        path: '/organizations',
        Icon: IconBuildingSkyscraper,
        fflag: features.canAccessOrganizationManagement,
      },
      {
        title: AppTitles.PEOPLE,
        path: '/people',
        Icon: IconUsersGroup,
      },
      ...cltApps,
      ...pjApps,
      {
        title: AppTitles.REPORTS,
        path: '/reports',
        Icon: IconReportAnalytics,
      },
      ...workerApps,
      {
        title: AppTitles.SECURITY,
        path: '/users-management',
        Icon: IconShieldChevron,
      },
      ...supportOnlyApps,
    ];
  }
  return [];
}

function HomeLoading() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <CircularProgress />
    </Box>
  );
}

function HomeError({ error }: { error: unknown }) {
  const { sessionId } = getRUMInfo() ?? {};
  return (
    <Box
      position="fixed"
      top="50%"
      left="50%"
      sx={{
        transform: 'translate(-50%, -50%)',
      }}
      minWidth="280px"
    >
      <Box display="flex" alignItems="center" gap={1.8} pb={3}>
        <Cancel fontSize="huge" color="error" />
        <Typography variant="h1">Erro ao carregar organizações.</Typography>
      </Box>
      <Typography variant="body1">
        Não foi possível carregar suas organizações. Tente novamente mais tarde.
      </Typography>
      {sessionId && (
        <Typography variant="body1" fontWeight={700} mt={2}>
          SessionId: {sessionId}
        </Typography>
      )}
      {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
    </Box>
  );
}

function PageErrorFallback({ error }: { error: Error }) {
  rum.recordError(error);
  console.error(`Failed to render page\n`, error);
  const { sessionId } = getRUMInfo() ?? {};
  return (
    <Box
      position="fixed"
      top="50%"
      left="50%"
      sx={{
        transform: 'translate(-50%, -50%)',
      }}
      minWidth="280px"
    >
      <Box display="flex" alignItems="center" gap={1.8} pb={3}>
        <Cancel fontSize="huge" color="error" />
        <Typography variant="h1">Erro ao carregar a página.</Typography>
      </Box>
      <Typography variant="body1">
        Não foi possível carregar essa página. Tente novamente mais tarde.
      </Typography>
      <Typography variant="body1">
        Se o erro persistir, entre em contato com o suporte da Tako.
      </Typography>
      {sessionId && (
        <Typography variant="body1" fontWeight={700} mt={2}>
          SessionId: {sessionId}
        </Typography>
      )}
      <Box pt={3} display="flex" justifyContent="flex-end">
        <Button
          color="primaryAlt"
          size="large"
          onClick={() => window.location.replace('/')}
        >
          Voltar
        </Button>
      </Box>
    </Box>
  );
}
