export const workbookColors = {
  black: 'FF000000',
  darkGrey: 'FF666666',
  grey: 'FFCCCCCC',
  lightGrey: 'FFF3F3F3',
} as const;

export const workbookStyles = {
  title: {
    size: 14,
    bold: true,
  },
  subtitle: {
    size: 11,
    bold: true,
  },
  captionKey: {
    color: workbookColors.darkGrey,
  },
  captionValue: {
    color: workbookColors.darkGrey,
    horizontalAlignment: 'right',
  },
  bodyKey: {},
  bodyValue: {
    horizontalAlignment: 'right',
  },
  tableHeader: {
    bold: true,
    borders: true,
    fill: true,
    wrap: true,
  },
  tableValue: {
    borders: true,
    color: workbookColors.darkGrey,
  },
} as const;

export const numberFormats = {
  percentage: '0.00%',
  br: {
    currency: 'R$ #,##0.00;(R$ #,##0.00)',
    decimal: '##0,00',
  },
  decimal: '#,##0.00',
} as const;
