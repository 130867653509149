import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { Box, CircularProgress } from '@mui/material';

import { logoutUser } from '../../../modules/login';
import { rum } from '../../../utils/rum';

export function Logout() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    rum.removeUserIdMetadata();
    logoutUser(queryClient).then(() => {
      navigate('/login');
    });
  }, []);

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      data-testid="logout-title"
    >
      <CircularProgress />
    </Box>
  );
}
