import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import {
  GetAllPayrollPayslipPdfPathParams,
  GetPayrollPayslipPdfPathParams,
} from '@octopus/api';

import { useSnackbar } from '../hooks/useSnackbar';

import { downloadAllPayslipPdf, downloadPayslipPdf } from './payslips';

export const useDownloadPayslips = () => {
  const { showSnackbar } = useSnackbar();
  const onBeginDownload = () => {
    showSnackbar({
      isOpen: true,
      autoHideDuration: 15000,
      hasCloseAction: true,
      StartAdornment: <FileDownloadOutlinedIcon />,
      Message: 'Seu download será iniciado em instantes',
    });
  };

  const onSuccessDownload = () => {
    showSnackbar({
      isOpen: true,
      autoHideDuration: 15000,
      hasCloseAction: true,
      StartAdornment: <CheckCircle />,
      Message: 'O download foi finalizado com sucesso',
    });
  };

  const onErrorDownload = () => {
    showSnackbar({
      isOpen: true,
      autoHideDuration: 15000,
      StartAdornment: <Cancel />,
      hasCloseAction: true,
      Message: 'Houve um problema no seu download, por favor tente novamente',
    });
  };

  const downloadAllPayslip = (payroll: GetAllPayrollPayslipPdfPathParams) => {
    onBeginDownload();
    downloadAllPayslipPdf(payroll)
      .then(() => onSuccessDownload())
      .catch(() => onErrorDownload());
  };

  const downloadSinglePayslip = (payroll: GetPayrollPayslipPdfPathParams) => {
    onBeginDownload();
    downloadPayslipPdf(payroll)
      .then(() => onSuccessDownload())
      .catch(() => onErrorDownload());
  };

  return { downloadAllPayslip, downloadSinglePayslip };
};
