import { Navigate } from 'react-router-dom';

import { membershipTypes } from '@octopus/vacations-types';

import { MembershipContext } from '../../modules/types';

export const VacationsRedirect = (props: {
  membership: MembershipContext;
  isManager: boolean;
  contractId?: string;
}) => {
  const { membership, isManager, contractId } = props;
  if (contractId && membership.membershipType === membershipTypes.internal) {
    if (isManager) {
      return <Navigate to="/vacations/manager" />;
    }

    return <Navigate to={`/vacations/${contractId}/scheduler`} />;
  }

  if (
    membership.membershipType === membershipTypes.owner ||
    membership.membershipType === membershipTypes['tako:support']
  ) {
    return <Navigate to="/vacations/admin" />;
  }

  return <Navigate to="/" />;
};
