import { DateTimeFormatter, YearMonth } from '@js-joda/core';
import { cnpj, cpf } from 'cpf-cnpj-validator';

import { PayrollTypes } from '@octopus/api';
import { Mapper as ContractMapper } from '@octopus/contract-types';
import { Mapper as EsocialMapper } from '@octopus/esocial/mapper';
import { encargosPatronaisElementIds } from '@octopus/payroll-engine/public-types/brazil';

import { CellValue } from './types';

export function capitalize(value: CellValue) {
  if (!value || typeof value !== 'string') {
    return value;
  }
  return value
    .toLowerCase()
    .replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
}

export function upperCase(value: CellValue) {
  if (!value || typeof value !== 'string') {
    return value;
  }
  return value.toUpperCase();
}

export function onlyLettersAndSpacesUpperCased(value: CellValue) {
  if (!value || typeof value !== 'string') {
    return value;
  }

  return value
    .normalize('NFD') // Decompose characters with accents into base letter + accent
    .replace(/[\u0300-\u036f]/g, '') // Remove accent marks
    .replace(/[^a-zA-Z\s]/g, '')
    .trim()
    .toUpperCase();
}

export function padStart(size: number, character: string) {
  return (value: CellValue) => {
    if (!value || typeof value !== 'string') {
      return value;
    }
    return value.padStart(size, character || ' ');
  };
}

export function prefixWithLeadingZeros(prefix: string, size: number) {
  return (value: CellValue) => {
    if (!value || typeof value !== 'string') {
      return value;
    }
    return `${prefix}${value.padStart(size, '0')}`;
  };
}

export function formatAccountNumber(value: CellValue) {
  if (!value || typeof value !== 'string') {
    return value;
  }

  const pieces = value.split('-');
  if (pieces.length > 1) {
    return pieces[0] || value;
  }

  return value.substring(0, value.length - 1);
}

export function formatAccountNumberCheckDigits(value: CellValue) {
  if (!value || typeof value !== 'string') {
    return value;
  }

  const pieces = value.split('-');
  if (pieces.length > 1) {
    return pieces[pieces.length - 1] || value.slice(-1);
  }

  return value.substring(value.length - 1);
}

export function formatCPF(value: CellValue) {
  if (!value || typeof value !== 'string') {
    return value;
  }
  return cpf.format(value);
}

export function formatCNPJ(value: CellValue) {
  if (!value || typeof value !== 'string') {
    return value;
  }
  return cnpj.format(value);
}

export function formatCEP(value: CellValue) {
  if (!value || typeof value !== 'string') {
    return value;
  }
  if (value.length !== 8) {
    return value;
  }
  return `${value.slice(0, 5)}-${value.slice(5)}`;
}

export function formatPhoneBR(value: CellValue) {
  if (!value || typeof value !== 'string') {
    return value;
  }
  const result =
    value.length === 11
      ? value.match(/(\d{2})(\d{5})(\d{4})/)
      : value.match(/(\d{2})(\d{4})(\d{4})/);
  if (!result) {
    return value;
  }
  return `(${result[1]}) ${result[2]}-${result[3]}`;
}

export function formatBooleanBR(value: CellValue) {
  if (value === undefined) {
    return value;
  }
  if (typeof value === 'boolean') {
    return value ? 'Sim' : 'Não';
  }
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true' ? 'Sim' : 'Não';
  }
  return value;
}

export function formatBooleanInitialBR(value: CellValue) {
  if (value === undefined || typeof value !== 'string') {
    return value;
  }
  return value === 'S' ? 'Sim' : 'Não';
}

export function formatMoneyBR(num: CellValue) {
  if (!num || (typeof num !== 'string' && typeof num !== 'number')) {
    num = '0';
  }
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formatter.format(+num).replace('-', '- ').replace(' ', ' ');
}

export function formatMonthBR(month: number | undefined) {
  if (!month) {
    return undefined;
  }
  const formatter = new Intl.DateTimeFormat('pt-BR', { month: 'long' });
  const date = new Date(2000, month - 1, 1);
  return capitalize(formatter.format(date));
}

export function formatDatePeriodBR(value: CellValue): CellValue {
  if (!value || typeof value !== 'string') {
    return value;
  }

  return YearMonth.parse(value as string).format(
    DateTimeFormatter.ofPattern('MM/yyyy'),
  );
}

export function formatNumber(value: CellValue): CellValue {
  if (!value || typeof value !== 'string') {
    return value;
  }
  const number = Number(value);
  if (Number.isNaN(number)) {
    return value;
  }
  return number;
}

export function formatNumberInvertingSign(value: CellValue): CellValue {
  const num = formatNumber(value);
  if (typeof num === 'number') {
    return num === 0 ? 0 : -num;
  }
  return num;
}

export function formatMoney(value: CellValue): CellValue {
  if (!value || typeof value !== 'string') {
    return 0;
  }
  const number = Number(value);
  if (Number.isNaN(number)) {
    return 0;
  }
  return number;
}

export function formatBrPayrollElementType(value: CellValue) {
  if (!value || typeof value !== 'string') {
    return value;
  }
  switch (value) {
    case 'earnings':
      return 'Provento';
    case 'deductions':
      return 'Dedução';
    default:
      return value;
  }
}

export function formatBrPayrollTaxSource(elementId: CellValue) {
  if (!elementId || typeof elementId !== 'string') {
    return elementId;
  }

  if (encargosPatronaisElementIds.includes(elementId)) {
    return 'Empresa';
  }
  return 'Pessoa';
}

export function formatPayrollElementValue(
  value: CellValue,
  type: 'earnings' | 'deductions',
) {
  const num = formatNumber(value);
  if (type === 'deductions' && typeof num === 'number') {
    return -num;
  }
  return num;
}

export function formatPayrollDeduction(value: CellValue) {
  return formatPayrollElementValue(value, 'deductions');
}

export function formatBrTerminationReason(value: CellValue) {
  if (!value || typeof value !== 'string') {
    return value;
  }
  const terminationTypeString: Record<string, string> = {
    pedidoDeDemissao: 'Pedido de demissão',
    comJustaCausa: 'Com justa causa',
    semJustaCausa: 'Sem justa causa',
    mutuoAcordo: 'Mútuo acordo',
    falecimento: 'Falecimento',
    extincaoDeContratoDeExperiencia: 'Extinção de contrato de experiência',
    rescisaoAntecipadaDoContratoDeExperienciaPeloEmpregador:
      'Rescisão Antecipada do Contrato de Experiência pelo Empregador',
    rescisaoAntecipadaDoContratoDeExperienciaPeloEmpregado:
      'Rescisão Antecipada do Contrato de Experiência pelo Empregado',
  };
  return terminationTypeString[value] ?? value;
}

export function formatBrComplementaryTerminationEventType(value: CellValue) {
  if (!value || typeof value !== 'string') {
    return value;
  }
  const terminationTypeString: Record<string, string> = {
    A: 'Acordo Coletivo de Trabalho',
    B: 'Legislação federal, estadual, municipal ou distrital',
    C: 'Convenção Coletiva de Trabalho',
    D: 'Sentença normativa - Dissídio',
    E: 'Conversão de licença saúde em acidente de trabalho',
    F: 'Outras verbas de natureza salarial ou não salarial devidas após o desligamento',
    G: 'Antecipação de diferenças de acordo, convenção ou dissídio coletivo',
    H: 'Declaração de base de cálculo de FGTS anterior ao início do FGTS Digital',
    I: 'Sentença judicial (exceto reclamatória trabalhista)',
    J: 'Parcelas complementares conhecidas após o fechamento da folha',
  };
  return terminationTypeString[value] ?? value;
}

export function formatBrCodCateg(value: CellValue): CellValue {
  if (!value || (typeof value !== 'string' && typeof value !== 'number')) {
    return value;
  }
  return (
    {
      '101': 'Empregado',
      '721': 'Contribuinte',
      '722': 'Contribuinte',
      '901': 'Estagiário',
      '103': 'Aprendiz',
      '9999': 'Prestadores de serviço (PJ)',
    }[value] ?? value
  );
}

export function formatBrPayrollType(value: CellValue): CellValue {
  if (!value || typeof value !== 'string') {
    return value;
  }
  const map: { [key in PayrollTypes | 'all']: string } = {
    monthly: 'Mensal',
    thirteenthFirst: 'Primeira Parcela 13o',
    thirteenthSecond: 'Segunda Parcela 13o',
    termination: 'Rescisão',
    vacations: 'Férias',
    advance: 'Adiantamento',
    complementary: 'Complementar',
    complementaryTermination: 'Rescisão Complementar',
    provisions: 'Provisões',
    rpa: 'RPA',
    all: 'Todas as Folhas',
  };
  return map[value as PayrollTypes] ?? value;
}

export function formatBrTerminationIndemnityType(value: CellValue): CellValue {
  if (!value || typeof value !== 'string') {
    return value;
  }
  return (
    {
      indemnified: 'Indenizado',
      deducted: 'Descontado',
      exempt: 'Dispensado',
      worked: 'Trabalhado',
      other: 'Outro',
    }[value] ?? value
  );
}

export function map(
  mapper: EsocialMapper | ContractMapper,
): (value: CellValue) => CellValue {
  return (value) => {
    if (!value || (typeof value !== 'string' && typeof value !== 'number')) {
      return value;
    }
    return mapper.getByCode(value as string | number);
  };
}
