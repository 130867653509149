import {
  AdmissionDraftContractBRPjEntry,
  AdmissionDraftContractBRPjUpdate,
  AdmissionDraftEntry,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftUpdateInput,
} from '@octopus/api';
import {
  admissionDraftFormSteps,
  getAdmissionCostCenterId,
  getAdmissionDate,
  getAdmissionDepartmentId,
  getAdmissionJobTitleId,
} from '@octopus/onboarding-types';

import { AdmissionFormState } from '../../types';

const getFormStateFromEntry = ({
  admissionDraftEntry,
}: {
  admissionDraftEntry: AdmissionDraftEntry;
}): AdmissionFormState => {
  if (!admissionDraftEntry) {
    return {};
  }

  const companyId = admissionDraftEntry?.companyId;
  const { contrato, pagamento, prestador, emailCorp } =
    (admissionDraftEntry.br as AdmissionDraftContractBRPjEntry) || {};

  return {
    company: {
      companyId,
      legalEntity: admissionDraftEntry?.legalEntityId,
    },
    matricula: prestador?.posicao,
    emailCorp: emailCorp,
    dataInicio: contrato?.inicio,
    dataAssinatura: contrato?.dataAssinatura,
    renovacao: contrato?.renovacao,

    cargo: getAdmissionJobTitleId(admissionDraftEntry),
    departmentId: getAdmissionDepartmentId(admissionDraftEntry),
    centro_de_custo: getAdmissionCostCenterId(admissionDraftEntry),
    admissionDate: getAdmissionDate(admissionDraftEntry),

    salary: pagamento?.honorarios,
    periodicidadePagamento: pagamento?.periodicidade,
  };
};

const getUpdateInputFromFormState = ({
  formState,
  currentStepName,
  body,
}: {
  formState: AdmissionFormState;
  currentStepName: AdmissionDraftInputFormStepEnum;
  body: AdmissionDraftUpdateInput;
}): AdmissionDraftUpdateInput => {
  switch (currentStepName) {
    case admissionDraftFormSteps.profissional: {
      if (formState.workerId) {
        body.workerId = formState.workerId;
      }

      body.br = {
        ...(formState.emailCorp
          ? { emailCorp: formState.emailCorp }
          : undefined),
        prestador: {
          gestao: {
            costCenterId: formState.centro_de_custo,
          },
          posicao: {
            departmentId: formState.departmentId,
            jobTitleId: formState.cargo,
          },
        },
      } as AdmissionDraftContractBRPjUpdate;
      break;
    }
    case admissionDraftFormSteps.pj_info: {
      body.br = {
        contrato: {
          ...(formState.renovacao
            ? { renovacao: formState.renovacao }
            : undefined),
          ...(formState.dataAssinatura
            ? { dataAssinatura: formState.dataAssinatura }
            : undefined),
          inicio: formState.dataInicio,
        },
      } as AdmissionDraftContractBRPjUpdate;
      break;
    }
    case admissionDraftFormSteps.remuneracao: {
      body.br = {
        pagamento: {
          honorarios: parseFloat(formState.salary.replace(',', '.') ?? '0'),
          periodicidade: formState.periodicidadePagamento,
        },
      } as AdmissionDraftContractBRPjUpdate;
      break;
    }

    default:
      return null;
  }

  return body;
};

export const pjMapper = { getUpdateInputFromFormState, getFormStateFromEntry };
