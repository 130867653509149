import React, { useState } from 'react';

import { IconFile, IconTrash } from '@tabler/icons-react';

import { Box, Button, Tooltip, Typography } from '@mui/material';

import { DeleteFileConfirmationDialog } from '../dialog';

export function FilePreviewList({
  file,
  onConfirm,
  isLoading,
  showDeleteButton = true,
  showDeleteConfirmation = true,
  deleteConfirmationMessage,
}: {
  file?: File;
  onConfirm: (file?: File) => void;
  isLoading: boolean;
  showDeleteButton: boolean;
  showDeleteConfirmation?: boolean;
  deleteConfirmationMessage?: string;
}) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  if (!file) {
    return undefined;
  }

  const handleDelete = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    if (showDeleteConfirmation) {
      setShowDeleteDialog(true);
    } else {
      onConfirm(file);
    }
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        background: theme.palette.background.secondary,
        borderRadius: 1,
        gap: 1.5,
        position: 'relative',
      })}
    >
      <IconFile width={20} height={20} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 0.5,
        }}
      >
        <Typography variant="caption">{file?.name}</Typography>
      </Box>
      {showDeleteButton && (
        <Tooltip title="Remover documento">
          <Button
            variant="text"
            disabled={isLoading}
            sx={{
              p: 0,
              marginLeft: 'auto',
            }}
            onClick={handleDelete}
            data-testid={`delete-file-from-list`}
          >
            <IconTrash
              width={20}
              height={20}
              style={{
                padding: 8,
                color: isLoading ? '#eeeeee' : '#d32f2f',
              }}
            />
          </Button>
        </Tooltip>
      )}
      {showDeleteConfirmation && (
        <DeleteFileConfirmationDialog
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          onConfirm={() => onConfirm(file)}
          description={deleteConfirmationMessage}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
}
