import React from 'react';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { VacationsConfigurationList } from '@octopus/api';
import {
  CreateScheduleRule,
  scheduleErrorMessages,
} from '@octopus/vacations-types';

import { LABEL_MAP } from './fields';

type Props = {
  config: VacationsConfigurationList['rules'];
  onChange: (updated: VacationsConfigurationList['rules']) => void;
};

const MONTH_OPTIONS = [
  { value: 'january', label: 'Janeiro' },
  { value: 'february', label: 'Fevereiro' },
  { value: 'march', label: 'Março' },
  { value: 'april', label: 'Abril' },
  { value: 'may', label: 'Maio' },
  { value: 'june', label: 'Junho' },
  { value: 'july', label: 'Julho' },
  { value: 'august', label: 'Agosto' },
  { value: 'september', label: 'Setembro' },
  { value: 'october', label: 'Outubro' },
  { value: 'november', label: 'Novembro' },
  { value: 'december', label: 'Dezembro' },
];

export function RuleByMembershipConfiguration({ config, onChange }: Props) {
  const handleFieldChange = (
    membershipType: string,
    ruleKey: string,
    fieldName: string,
    value: any,
  ) => {
    const newRules = { ...config };
    if (!newRules[membershipType]) return;
    const membershipRules = { ...newRules[membershipType] };
    if (!membershipRules[ruleKey]) return;

    membershipRules[ruleKey] = {
      ...membershipRules[ruleKey],
      [fieldName]: value,
    };
    newRules[membershipType] = membershipRules;
    onChange({ ...newRules });
  };

  const membershipEntries = Object.entries(config);

  return (
    <Box gap={2} display={'flex'} flexDirection={'column'}>
      {membershipEntries.map(([membershipType, rules]) => {
        if (membershipType === 'tako:support') {
          return null;
        }

        return (
          <Box key={membershipType}>
            <Accordion
              className="borderless"
              defaultExpanded={false}
              sx={{
                bgcolor: '#F8F8F8',
                borderRadius: 1,
                border: 'none',
                height: '100%',
                px: 2,
                py: 1,
                width: '100%',
                boxSizing: 'border-box',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Box display="flex">
                  <Typography variant="h3" mb={2}>
                    Regras - {LABEL_MAP[membershipType]}
                  </Typography>
                </Box>
              </AccordionSummary>

              <AccordionDetails>
                <TableContainer>
                  <Table sx={{ border: '1px solid #EDEDED', borderRadius: 1 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Regra</TableCell>
                        <TableCell>Configuração</TableCell>
                        <TableCell>Parâmetros</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(rules)
                        .sort(([a], [b]) =>
                          scheduleErrorMessages[
                            a as CreateScheduleRule
                          ]?.summary.localeCompare(
                            scheduleErrorMessages[b as CreateScheduleRule]
                              ?.summary,
                          ),
                        )
                        .map(([ruleKey, rule]) => {
                          const { id, enabled, ...otherFields } = rule;
                          const ruleMessages =
                            scheduleErrorMessages[id as CreateScheduleRule];
                          const ruleDescription =
                            ruleMessages?.description || id;
                          const ruleSummary = ruleMessages?.summary || id;

                          return (
                            <TableRow key={ruleKey}>
                              <TableCell width={'40%'}>
                                <Tooltip title={ruleDescription}>
                                  <Typography
                                    variant="body2"
                                    sx={{ cursor: 'help' }}
                                  >
                                    {ruleSummary}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                              <TableCell width={'10%'}>
                                <Checkbox
                                  size="small"
                                  checked={enabled}
                                  onChange={(e) =>
                                    handleFieldChange(
                                      membershipType,
                                      ruleKey,
                                      'enabled',
                                      e.target.checked,
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell width={'50%'}>
                                {enabled &&
                                  Object.entries(otherFields).map(
                                    ([fieldName, fieldValue]) => {
                                      if (
                                        fieldName === 'id' ||
                                        fieldName === 'enabled'
                                      )
                                        return null;

                                      if (
                                        fieldName === 'monthsAllowedToRequest'
                                      ) {
                                        return (
                                          <FormControl
                                            key={fieldName}
                                            sx={{ minWidth: 200, mr: 2 }}
                                          >
                                            <FormHelperText>
                                              Meses Permitidos
                                            </FormHelperText>
                                            <Select
                                              multiple
                                              value={fieldValue ?? []}
                                              onChange={(e) =>
                                                handleFieldChange(
                                                  membershipType,
                                                  ruleKey,
                                                  fieldName,
                                                  e.target.value,
                                                )
                                              }
                                              renderValue={(selected) =>
                                                `${selected.length} meses selecionados`
                                              }
                                            >
                                              {MONTH_OPTIONS.map((month) => (
                                                <MenuItem
                                                  key={month.value}
                                                  value={month.value}
                                                >
                                                  {month.label}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        );
                                      }

                                      const isNumber =
                                        typeof fieldValue === 'number';

                                      return (
                                        <TextField
                                          key={fieldName}
                                          label={LABEL_MAP[fieldName]}
                                          type={isNumber ? 'number' : 'text'}
                                          size="small"
                                          value={fieldValue ?? ''}
                                          onChange={(e) =>
                                            handleFieldChange(
                                              membershipType,
                                              ruleKey,
                                              fieldName,
                                              isNumber
                                                ? Number(e.target.value)
                                                : e.target.value,
                                            )
                                          }
                                          sx={{ mr: 2, minWidth: 120 }}
                                        />
                                      );
                                    },
                                  )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      })}
    </Box>
  );
}
