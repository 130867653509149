import AdmissionErrorCodes from './admission';
import CommonErrorCodes from './common';
import ContractErrorCodes from './contracts';
import CostCenterErrorCodes from './costCenters';
import DepartmentErrorCodes from './departments';
import JobTitleErrorCodes from './jobTitles';
import LegalEntityErrorCodes from './legalEntity';
import PayrollErrorCodes from './payroll';
import StateMachineErrorCodes from './stateMachine';

export const PortugueseErrorCodes = {
  ...AdmissionErrorCodes,
  ...CommonErrorCodes,
  ...ContractErrorCodes,
  ...LegalEntityErrorCodes,
  ...PayrollErrorCodes,
  ...JobTitleErrorCodes,
  ...CostCenterErrorCodes,
  ...DepartmentErrorCodes,
  ...StateMachineErrorCodes,
};
