import { useState } from 'react';

import dayjs from 'dayjs';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { formatPeriodDate } from '@octopus/formatters';

type TwoStepPeriodDialogProps = {
  title: string;
  onConfirm: (periodId: string) => void;
  onClose: () => void;
  confirmationContent: React.ReactNode;
  periodSelectionHint?: string;
  periodWarning?: string;
  periodRequirements?: string;
};

export function TwoStepPeriodDialog({
  title,
  onConfirm,
  onClose,
  confirmationContent,
  periodSelectionHint = 'Selecione o período',
  periodWarning,
  periodRequirements,
}: TwoStepPeriodDialogProps) {
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);
  const [step, setStep] = useState<'select_period' | 'confirm'>(
    'select_period',
  );
  const [showError, setShowError] = useState(false);

  if (step === 'select_period') {
    return (
      <Box width={'600px'}>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            gap={4}
            alignItems="flex-start"
            mb={0}
          >
            <Box gap={3} width={'100%'}>
              <Typography variant="h5" fontSize="24px" fontWeight={700}>
                {title}
              </Typography>

              <Box gap={3} mt={1} width={'100%'}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  width={'100%'}
                >
                  <Typography variant="subtitle1" fontSize="12px">
                    {periodSelectionHint}
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="pt-br"
                  >
                    <DatePicker
                      value={selectedDate}
                      onChange={(newValue) => {
                        setSelectedDate(newValue);
                        setShowError(false);
                      }}
                      format="MM/YYYY"
                      views={['year', 'month']}
                      openTo="month"
                      disableHighlightToday={true}
                      slotProps={{
                        textField: {
                          placeholder: 'Selecione o mês',
                          error: showError,
                        },
                      }}
                      sx={{
                        width: '100%',
                      }}
                    />
                  </LocalizationProvider>

                  {(periodWarning || periodRequirements) && (
                    <Box display="flex" flexDirection="column">
                      <Box
                        component="ul"
                        pl={2}
                        gap={2}
                        display="flex"
                        flexDirection="column"
                      >
                        {periodWarning && (
                          <li>
                            <Typography
                              fontWeight={500}
                              variant="subtitle2"
                              fontSize="12px"
                            >
                              {periodWarning}
                            </Typography>
                          </li>
                        )}
                        {periodRequirements && (
                          <Typography
                            fontWeight={500}
                            variant="subtitle1"
                            fontSize="12px"
                          >
                            {periodRequirements}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'background.paper',
              color: 'text.primary',
              width: '140px',
              height: '40px',
              px: 4,
              ':hover': {
                backgroundColor: 'strokes.light',
              },
              borderTop: '1px solid',
              borderTopColor: 'strokes.light',
            }}
            size="large"
            onClick={onClose}
          >
            <Typography variant="subtitle2">Cancelar</Typography>
          </Button>
          <Button
            color="primaryAlt"
            variant="contained"
            size="large"
            sx={{
              width: '140px',
              height: '40px',
            }}
            onClick={() => {
              if (!selectedDate) {
                setShowError(true);
                return;
              }
              try {
                setStep('confirm');
              } catch (error) {
                console.error(error);
              }
            }}
          >
            <Typography variant="subtitle2" color="white">
              Continuar
            </Typography>
          </Button>
        </DialogActions>
      </Box>
    );
  }

  return (
    <Box width={'600px'}>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          alignItems="flex-start"
          mb={0}
        >
          <Box gap={3}>
            <Typography variant="h5" fontSize="24px" fontWeight={700}>
              {title}
            </Typography>

            <Box gap={2} mt={1}>
              <Typography fontSize="12px" fontWeight={500} variant="subtitle1">
                Período selecionado:{' '}
                {formatPeriodDate(selectedDate?.format('YYYY-MM') || '')}
              </Typography>

              {confirmationContent}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.primary',
            width: '140px',
            height: '40px',
            px: 4,
            ':hover': {
              backgroundColor: 'strokes.light',
            },
            borderTop: '1px solid',
            borderTopColor: 'strokes.light',
          }}
          size="large"
          onClick={onClose}
        >
          <Typography variant="subtitle2">Cancelar</Typography>
        </Button>
        <Button
          color="primaryAlt"
          variant="contained"
          size="large"
          sx={{
            width: '140px',
            height: '40px',
          }}
          onClick={() => {
            if (!selectedDate) return;
            try {
              onConfirm(selectedDate.format('YYYY-MM'));
            } catch (error) {
              console.error(error);
            }
          }}
        >
          <Typography variant="subtitle2" color="white">
            Confirmar
          </Typography>
        </Button>
      </DialogActions>
    </Box>
  );
}
