import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import {
  EstadoCivil,
  Nacionalidades,
  Paises,
  RacaCor,
  Sexo,
} from '@octopus/esocial/mapper';
import {
  IFormDefinition,
  NEW_UI_TYPE,
  UI_TYPE,
  cpfSchema,
  nonEmptyString,
  validHiringBirthDateSchema,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { getOptionsFromMapper } from '../inputUtils';
import {
  AdmissionFormState,
  ENABLED_COUNTRIES,
  FormStepDefinition,
} from '../types';

import { nomeSocialSchema } from './dadosPessoaisSchema';

const getFieldDefinition = ({
  formState,
  isPj,
}: {
  formState: AdmissionFormState;
  isPj: boolean;
}): IFormDefinition => {
  const {
    nome,
    nomeSocial,
    cpf,
    nomeMae,
    sexo,
    racaCor,
    estadoCivil,
    dtNascto,
    nacionalidade,
    paisNascto,
  } = formState;

  return [
    {
      label: 'Nome completo',
      type: nonEmptyString,
      name: 'nome',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Ex: Maria de Lourdes',
      value: nome || '',
    },
    {
      label: 'Possui nome social',
      type: nomeSocialSchema,
      name: 'hasNomeSocial',
      uiType: UI_TYPE.SELECT,
      fieldsOptions: [
        {
          label: 'Não',
          selected: nomeSocial == null,
          fields: [
            {
              label: '',
              type: z.string().optional(),
              hidden: true,
              name: 'nomeSocialHidden',
              uiType: UI_TYPE.TEXT,
              value: 'hidden',
            },
          ],
        },
        {
          label: 'Sim',
          selected: nomeSocial !== null,
          fields: [
            {
              label: 'Nome social',
              type: z.string().optional(),
              name: 'nomeSocial',
              uiType: UI_TYPE.TEXT,
              placeholder: 'Ex: Maria de Lourdes',
              value: nomeSocial ?? '',
              disclaimerFromSelection: () =>
                'O nome social é a designação pela qual a pessoa se identifica e é socialmente reconhecida. O nome social não deve ser confundido com apelidos, nomes comerciais, religiosos, ou titulações profissionais.\n\nSe você não utiliza um nome social, deixe essa opção desmarcada.',
            },
          ],
        },
      ],
    },
    {
      label: 'CPF',
      type: cpfSchema,
      name: 'cpf',
      uiType: UI_TYPE.TEXT,
      placeholder: '000.000.000-00',
      value: cpf || '',
      mask: 'cpf',
    },
    ...(isPj
      ? []
      : [
          {
            label: 'Nome da mãe',
            type: nonEmptyString,
            name: 'nomeMae',
            uiType: UI_TYPE.TEXT,
            placeholder: 'Ex: Joana de Lourdes',
            value: nomeMae || '',
          },
          {
            label: 'Sexo',
            type: nonEmptyString,
            name: 'sexo',
            uiType: UI_TYPE.SELECT,
            info: 'Essa é uma informação requerida pelo eSocial.',
            options: getOptionsFromMapper({ mapper: Sexo, currentValue: sexo }),
          },
          {
            label: 'Identidade racial',
            type: z.number().int(),
            name: 'racaCor',
            uiType: UI_TYPE.SELECT,
            info: 'Essa é uma informação requerida pelo eSocial.',
            options: getOptionsFromMapper({
              mapper: RacaCor,
              currentValue: racaCor,
            }),
          },
          {
            label: 'Estado civil',
            type: z.number().int(),
            name: 'estadoCivil',
            uiType: UI_TYPE.SELECT,
            placeholder: 'Selecione',
            options: getOptionsFromMapper({
              mapper: EstadoCivil,
              currentValue: estadoCivil,
            }),
          },
        ]),

    {
      label: 'Data de nascimento',
      type: validHiringBirthDateSchema,
      name: 'dtNascto',
      uiType: UI_TYPE.DATE_PICKER,
      placeholder: '00/00/0000',
      value: dtNascto || '',
    },
    {
      label: 'País de nascimento',
      type: nonEmptyString,
      name: 'paisNascto',
      uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
      placeholder: 'Digite para buscar o país',
      options: getOptionsFromMapper({
        mapper: Paises,
        currentValue: paisNascto,
      }),
    },
    {
      label: 'Nacionalidade',
      type: nonEmptyString,
      name: 'nacionalidade',
      uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
      placeholder: 'Digite para buscar a nacionalidade',
      options: getOptionsFromMapper({
        mapper: Nacionalidades,
        currentValue: nacionalidade,
        enabledKeys: ENABLED_COUNTRIES,
      }),
    },
  ].filter((item) => item);
};

export const getDadosPessoaisFields = ({
  formState,
  dadosPessoaisFormSteps,
  isPj,
}: {
  formState: AdmissionFormState;
  dadosPessoaisFormSteps: AdmissionDraftInputFormStep;
  isPj: boolean;
}): FormStepDefinition => ({
  definition: getFieldDefinition({
    formState,
    isPj,
  }),
  options: {
    id: admissionDraftFormSteps.dados_pessoais,
    title: 'Dados pessoais',
    completed: dadosPessoaisFormSteps?.completed,
    reviewed: dadosPessoaisFormSteps?.reviewed,
  },
});
