import { useEffect, useState } from 'react';

import { UseQueryResult } from '@tanstack/react-query';

import { MembershipSummary } from '@octopus/api';

import { membershipEquals } from '../../utils/membershipEquals';
import { MembershipContext, UserContext } from '../types';

import { useLocalStorage } from './useLocalStorage';
import { useSessionStorage } from './useSessionStorage';

export const KEY = 'membership';
type MembershipStorageContext = {
  userId: string;
  membership: MembershipContext;
};

export const useMembershipWithStorage = (
  query: UseQueryResult<MembershipSummary[]>,
  user: UserContext,
) => {
  const [currentMembership, setCurrentMembership] = useState<
    MembershipContext | undefined
  >(undefined);

  const { localStorageContext, setLocalStorageContext } =
    useLocalStorage<MembershipStorageContext>(KEY);
  const { sessionStorageContext, setSessionStorageContext } =
    useSessionStorage<MembershipStorageContext>(KEY);

  const isSameUser = localStorageContext?.userId === user?.userId;
  const isSameMembership =
    localStorageContext?.membership &&
    query?.data?.some((option: MembershipSummary) =>
      membershipEquals(option, localStorageContext.membership),
    );

  const shouldSetMembershipContext = !query.isLoading && query.data;
  const shouldSetFromSessionStorage =
    sessionStorageContext && isSameUser && isSameMembership;
  const shouldSetFromLocalStorage =
    localStorageContext && !sessionStorageContext;

  const setMembershipFromAvailableSources = () => {
    if (shouldSetFromSessionStorage) {
      setCurrentMembership(sessionStorageContext.membership);
    } else if (shouldSetFromLocalStorage) {
      setCurrentMembership(localStorageContext.membership);
    } else {
      const newMembership = {
        userId: user?.userId,
        membership: toMembershipContext(query?.data?.[0]),
      };
      setCurrentMembership(newMembership.membership);
      setSessionStorageContext(newMembership);
      setLocalStorageContext(newMembership);
    }
  };

  useEffect(() => {
    if (shouldSetMembershipContext) {
      setMembershipFromAvailableSources();
    }
  }, [shouldSetMembershipContext]);

  const changeMembership = (newMembership: MembershipContext) => {
    setCurrentMembership(newMembership);
    setSessionStorageContext({
      userId: user?.userId,
      membership: newMembership,
    });
    setLocalStorageContext({
      userId: user?.userId,
      membership: newMembership,
    });
  };

  return {
    currentMembership,
    changeMembership,
  };
};

const toMembershipContext = (membership: MembershipSummary) =>
  membership
    ? {
        organizationId: membership?.organizationId,
        membershipType: membership?.type,
      }
    : undefined;
