import ExcelJS from 'exceljs';

import { workbookStyles } from '../styles';
import { CellStyle, CellValue } from '../types';

import { setCell } from './setCell';

export type SetKeyValueProps = {
  key: CellValue;
  value: CellValue;
  keyStyle?: CellStyle;
  valueStyle?: CellStyle;
  spacing?: number;
};

export function setKeyValue(
  worksheet: ExcelJS.Worksheet,
  rowNum: number,
  colNum: number,
  {
    key,
    value,
    keyStyle = workbookStyles.captionKey,
    valueStyle = workbookStyles.captionValue,
    spacing = 1,
  }: SetKeyValueProps,
) {
  setCell(worksheet, rowNum, colNum, key, keyStyle);
  setCell(worksheet, rowNum, colNum + spacing + 1, value, valueStyle);
}
