import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import {
  CompanyList,
  useGetAllCompanies,
  useGetOrganizationEntry,
  useSearchAllContracts,
} from '@octopus/api';

import { BackButton } from '../../modules/components/BackButton';
import { PageAlert } from '../../modules/components/PageAlert';
import { QueryResult } from '../../modules/types';
import { useFFlags } from '../fflags';

import { CostCentersContent } from './CostCentersContent';
import { DepartmentsContent } from './DepartmentsContent';
import { JobTitlesContent } from './JobTitlesContent';
import { OrganizationSidePanel } from './OrganizationSidePanel';
import { PageContent, pageContents } from './types';

export type OrganizationsProps = {
  organizationId: string;
};

export default function Organizations({ organizationId }: OrganizationsProps) {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [pageContent, setPageContent] = useState<PageContent>(
    (hash?.replace('#', '') as PageContent) || pageContents.jobTitles,
  );

  useEffect(() => {
    navigate(`#${pageContent}`);
  }, [pageContent]);

  const organizationQuery = useGetOrganizationEntry(
    {
      pathParams: {
        organizationId: organizationId ?? '',
      },
    },
    {
      enabled: !!organizationId,
    },
  );

  const companiesQuery = useGetAllCompanies({
    pathParams: {
      organizationId,
    },
    queryParams: {
      size: '100',
    },
  });

  const contractsQuery = useSearchAllContracts();

  useEffect(() => {
    if (organizationId) {
      contractsQuery.mutate({
        pathParams: {
          organizationId,
        },
        body: {
          pagination: {
            size: 1,
          },
          filtering: {
            elements: {
              status: [{ not: 'terminated' }],
            },
          },
        },
      });
    }
  }, [organizationId]);

  if (!organizationId) {
    return <Navigate to="/" replace />;
  }

  if (organizationQuery.isError) {
    console.error(
      `Failed to load organization: ${JSON.stringify(organizationQuery.error)}`,
    );
    return (
      <>
        <BackButton destination="/" />
        <PageAlert
          message="Erro ao carregar dados da organização"
          severity="error"
          showRetryMessage={true}
        />
      </>
    );
  }

  return (
    <Box display="flex">
      <OrganizationSidePanel
        organizationId={organizationId}
        organizationQuery={organizationQuery}
        contractsQuery={contractsQuery}
        pageContent={pageContent}
        setPageContent={setPageContent}
      />
      <Page
        organizationId={organizationId}
        companiesQuery={companiesQuery}
        pageContent={pageContent}
      />
    </Box>
  );
}

function Page({
  organizationId,
  companiesQuery,
  pageContent,
}: {
  organizationId: string;
  companiesQuery: QueryResult<CompanyList>;
  pageContent: PageContent;
}) {
  const { FFlags } = useFFlags();

  const content = (() => {
    switch (pageContent) {
      case pageContents.jobTitles:
        return (
          <Box data-testid="job-titles-content">
            <JobTitlesContent
              organizationId={organizationId}
              companiesQuery={companiesQuery}
            />
          </Box>
        );
      case 'costCenters':
        return (
          <FFlags canAccessCostCenterManagement>
            <Box data-testid="cost-centers-content">
              <CostCentersContent
                organizationId={organizationId}
                companiesQuery={companiesQuery}
              />
            </Box>
          </FFlags>
        );
      case 'departments':
        return (
          <FFlags canAccessDepartmentManagement>
            <Box data-testid="departments-content">
              <DepartmentsContent
                organizationId={organizationId}
                companiesQuery={companiesQuery}
              />
            </Box>
          </FFlags>
        );
    }
  })();

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      alignItems="center"
      width="100%"
      py={15}
      mx={10}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        minWidth="740px"
        gap={2}
      >
        {content}
      </Box>
    </Box>
  );
}
