import { Box, CircularProgress, Container, Typography } from '@mui/material';

import { useGetAllCompanies } from '@octopus/api';

import { BackButton } from '../../../modules/components/BackButton';

import { OrgPreferences } from './OrgPreferences';
import { Preferences } from './Preferences';

function Page({ organizationId }: { organizationId: string | undefined }) {
  const { data, isLoading } = useGetAllCompanies(
    {
      pathParams: {
        organizationId: organizationId || '',
      },
    },
    {
      enabled: !!organizationId,
    },
  );

  if (!organizationId) {
    return (
      <Container maxWidth="md">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          width="100%"
        >
          <Typography variant="h1">Please select an organization</Typography>
        </Box>
      </Container>
    );
  }

  if (isLoading || !data) {
    return (
      <Container maxWidth="md">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={4}
          >
            <Typography variant="h3">Carregando configurações...</Typography>
            <CircularProgress />
          </Box>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <BackButton />
      <Box py={8} display="flex" flexDirection="column" gap={4}>
        <Preferences organizationId={organizationId} companies={data} />
        <OrgPreferences organizationId={organizationId} />
      </Box>
    </Container>
  );
}

export default Page;
