import { useCallback, useState } from 'react';

import { PayrollInputsPayload } from '@octopus/api';

import { EmployeesState } from '../useSubmissionState';

import {
  TimesheetEntry,
  TimesheetFileDefinition,
  TimesheetStepError,
  TimesheetStepErrorCode,
  TimesheetStepErrorType,
  TimesheetStepWarning,
} from './steps/types';
import {
  extractValidatedTimesheetEntries,
  getEntities,
  getEntitiesMapped,
  parseCsvFile,
  parseFileLines,
  validateFileFormat,
} from './steps/utils';

type TimesheetValidationResult = {
  isValidating: boolean;
  processTimesheetValidation: (params: {
    organizationId: string;
    companyId: string;
    period: string;
    employees: EmployeesState;
    fileDefinition: TimesheetFileDefinition;
    file: File | null;
    payrollInputs: PayrollInputsPayload;
  }) => Promise<{
    entries: TimesheetEntry[];
    errors: TimesheetStepError[];
    warnings: TimesheetStepWarning[];
  }>;
};

export function useTimesheetValidation(): TimesheetValidationResult {
  const [isValidating, setIsValidating] = useState(false);

  const processTimesheetValidation: TimesheetValidationResult['processTimesheetValidation'] =
    useCallback(
      async ({
        organizationId,
        companyId,
        period,
        employees,
        fileDefinition,
        file,
        payrollInputs,
      }) => {
        try {
          console.time('processTimesheetValidation');

          setIsValidating(true);

          if (!file) {
            return {
              entries: [],
              warnings: [],
              errors: [
                {
                  type: TimesheetStepErrorType.VALIDATION,
                  code: TimesheetStepErrorCode.NO_DATA,
                  expected: [],
                  line: 0,
                  value: [],
                  columns: [],
                },
              ],
            };
          }

          const lines = await parseCsvFile(file, fileDefinition);

          const {
            warnings,
            errors,
            lines: validLines,
          } = validateFileFormat(lines, fileDefinition);

          if (errors.length) {
            return { entries: [], warnings: [], errors };
          }

          const records = parseFileLines(validLines, fileDefinition);

          const entities = await getEntities(
            organizationId,
            companyId,
            employees.data,
          );

          const mapping = await getEntitiesMapped(organizationId, {
            records,
            employeesData: employees.data,
            contracts: entities.contracts,
            legalEntities: entities.legalEntities,
            payrollInputs,
          });

          const result = await extractValidatedTimesheetEntries(
            mapping,
            period,
          );

          return {
            entries: result.entries,
            warnings: [...warnings, ...result.warnings],
            errors: [],
          };
        } catch (error) {
          console.error(error);

          return {
            entries: [],
            warnings: [],
            errors: [
              {
                type: TimesheetStepErrorType.VALIDATION,
                code: TimesheetStepErrorCode.UNKNOWN_ERROR,
                line: 0,
                value: error?.toString(),
                columns: [],
                expected: [],
              },
            ],
          };
        } finally {
          setIsValidating(false);
          console.timeEnd('processTimesheetValidation');
        }
      },
      [],
    );

  return {
    processTimesheetValidation,
    isValidating,
  };
}
