import { BiMap } from '@rimbu/core';

import {
  MunicipiosByEstado,
  getEstadoByMunicipioCode,
} from './estadoMunicipio';
import { Mapper } from './mapper';

export class MunicipiosMapper extends Mapper {
  constructor() {
    super(BiMap.empty());
  }

  override getByCode(code: number | string | undefined): string | undefined {
    if (code === undefined) {
      return undefined;
    }
    const uf = getEstadoByMunicipioCode(code as number);
    if (uf === undefined || MunicipiosByEstado[uf] === undefined) {
      return undefined;
    }
    return MunicipiosByEstado[uf].getByCode(code);
  }

  /**
   * @deprecated This function is not stable as cities in different states can have the same name!
   *
   * You should use MunicipiosByEstado and select the correct mapper for the desired UF instead.
   */
  override findByName(name: string): string | number | undefined {
    console.warn(
      'MunicipiosMapper.findByName is not stable, as cities in different states can have the same name. Use MunicipiosByEstado[<uf>].findByName instead.',
    );
    for (const estadoMapper of Object.values(MunicipiosByEstado)) {
      const municipio = estadoMapper.findByName(name);
      if (municipio !== undefined) {
        return municipio;
      }
    }
    return undefined;
  }

  override values(): string[] {
    const values: string[] = [];
    for (const estadoMapper of Object.values(MunicipiosByEstado)) {
      values.push(...estadoMapper.values());
    }
    return values;
  }

  /**
   * @deprecated This function is not stable as cities in different states can have the same name!
   *
   * You should use MunicipiosByEstado and select the correct mapper for the desired UF instead.
   */
  override codes(): (string | number)[] {
    console.warn(
      'MunicipiosMapper.codes is not stable, as cities in different states can have the same name, causing codes for those cities to be overwritten. Use MunicipiosByEstado[<uf>].code instead.',
    );
    const keys: (string | number)[] = [];
    for (const estadoMapper of Object.values(MunicipiosByEstado)) {
      keys.push(...estadoMapper.codes());
    }
    return keys;
  }

  /**
   * @deprecated This function is not stable as cities in different states can have the same name!
   *
   * You should use MunicipiosByEstado and select the correct mapper for the desired UF instead.
   */
  override entries(): (readonly [string | number, string])[] {
    console.warn(
      'MunicipiosMapper.entries is not stable, as cities in different states can have the same name, causing entries for those cities to be overwritten. Use MunicipiosByEstado[<uf>].entries instead.',
    );
    const entries: (readonly [string | number, string])[] = [];
    for (const estadoMapper of Object.values(MunicipiosByEstado)) {
      entries.push(...estadoMapper.entries());
    }
    return entries;
  }
}

export const Municipios = new MunicipiosMapper();
