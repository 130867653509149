import { useState } from 'react';

import { Cancel, CheckCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import { fetchApproveAllPayrolls } from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

import { TwoStepPeriodDialog } from '../../../../modules/components/payrolls/TwoStepPeriodDialog';
import { useSnackbar } from '../../../../modules/hooks/useSnackbar';

export function PeriodApprovalConfirmationModal({
  organizationId,
  companyId,
  close,
}: {
  organizationId: string;
  companyId: string;
  close: () => void;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const showSnackbar = useSnackbar().showSnackbar;

  const handleConfirm = (periodId: string) => {
    setIsLoading(true);
    fetchApproveAllPayrolls({
      pathParams: {
        organizationId,
        companyId,
        periodId,
        payrollType: 'rpa',
      },
    })
      .then(() => {
        showSnackbar({
          isOpen: true,
          Message: 'Pagamentos aprovados com sucesso',
          StartAdornment: <CheckCircle />,
          autoHideDuration: 5000,
          hasCloseAction: true,
        });
        close();
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <LoadingScene />;
  }

  if (isError) {
    return <ErrorScene close={close} />;
  }

  const confirmationContent = (
    <Box gap={2} mt={2}>
      <Typography fontSize="16px" fontWeight={500}>
        Ao confirmar, o que acontece em seguida:
      </Typography>

      <Box display="flex" flexDirection="column">
        <Box
          component="ul"
          pl={2}
          gap={2}
          display="flex"
          flexDirection="column"
        >
          <li>
            <Typography
              fontWeight={500}
              variant="body1"
              fontSize="16px"
            >
              Geração de recibos para a competência selecionada
            </Typography>
            <Typography
              fontWeight={500}
              variant="caption"
              fontSize="12px"
            >
              Os recibos serão gerados e ficaram disponíveis para envio
            </Typography>
          </li>
        </Box>
      </Box>
    </Box>
  );

  return (
    <TwoStepPeriodDialog
      title="Aprovar pagamentos"
      onConfirm={handleConfirm}
      onClose={close}
      confirmationContent={confirmationContent}
      periodSelectionHint="Deseja aprovar os pagamentos referentes a qual competência?"
    />
  );
}

function ErrorScene({ close }: { close: () => void }) {
  return (
    <>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={1.8} pb={3}>
          <Cancel fontSize="huge" color="error" />
          <Typography variant="h1">Erro ao aprovar pagamentos</Typography>
        </Box>
        <Typography variant="body1">
          Não foi possível aprovar os pagamentos, tente novamente. Se persistir
          o erro, entre em contato com o suporte.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button size="large" sx={{ minWidth: '140px' }} onClick={close}>
          Ok
        </Button>
      </DialogActions>
    </>
  );
}

function LoadingScene() {
  return (
    <DialogContent>
      <Box
        py={10}
        px={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box width="100px">
          <OctopusLoading />
        </Box>
        <Box pt={2}>
          <Typography variant="h4" textAlign="center">
            Aprovando pagamentos, aguarde alguns segundos...
          </Typography>
        </Box>
      </Box>
    </DialogContent>
  );
}
